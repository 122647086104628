import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import GativanBlueLogo from "../../assets/logo/GativanBlueLogo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import PortraitIcon from "@mui/icons-material/Portrait";
import LogoutIcon from "@mui/icons-material/Logout";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Drawer, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdminLogout } from "../../action";
import { getUserProfile } from "../../services/userProfileServices/UserProfileServices";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "fixed",
    width: `calc(100% - ${240}px)`,
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      lineHeight: "0px",
    },
  },

  searchbarContainer: {
    width: "50%",
    padding: "0px 0rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      padding: "0px",
    },
  },
  searchBoxWrapper: {
    display: "flex",
  },

  search: {
    position: "relative",
    border: "none",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#E5E5E5",
    color: "black",
    marginLeft: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "350px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
  },

  addButtonsWrapper: {
    display: "flex",
    border: "none !important",
    background: "none !important",
    boxShadow: "none",
    "&:disabled": {
      // cursor: "not-allowed",
      // opacity: 0.4,
      display: "none",
    },
  },
  addButtons: {
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "14px !important",
    },
  },
  addLeadsButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#122164",
    padding: "10px 20px",
    fontWeight: 400,
    fontSize: "1rem",
    color: "white",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "& span": {
      fontFamily: "AvenirDemi",
    },
    "&:hover": {
      backgroundColor: "#4a5aa3",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 0px !important",
      borderRadius: "5px",
      fontSize: "1rem !important",
      padding: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.3999rem 0.3rem !important",
      borderRadius: "100px",
      borderRadius: "50px",
      fontSize: "1rem !important",
      display: "flex",
      justifyContent: "center",
      width: "37px",
      height: "37px",
      alignItems: "center",
      "& .addIcon": {
        fontSize: "1.2rem !important",
        color: "#fff",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.3999rem 0.3rem !important",
      borderRadius: "100px",
      borderRadius: "50px",
      fontSize: "1rem !important",
      display: "flex",
      justifyContent: "center",
      width: "35px",
      height: "35px",
      alignItems: "center",
      "& .addIcon": {
        fontSize: "1.2rem !important",
        color: "#fff",
      },
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "10px !important",
      marginLeft: "0.5rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 5px !important",
      height: "35px",
      fontSize: "14px",
      "& .addIcon": {
        fontSize: "1.2rem !important",
        color: "#fff",
      },
    },
    "&:disabled": {
      // opacity: 0.4,
      // cursor: "not-allowed",
      display: "none",
    },
  },
  mobileButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  addGarageButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#122164",
    padding: "10px 20px",
    fontWeight: 400,
    fontSize: "1rem",
    color: "white",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    whiteSpace: "nowrap",
    marginLeft: "1.5rem",
    "& span": {
      fontFamily: "AvenirDemi",
    },
    "&:hover": {
      backgroundColor: "#4a5aa3",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 0px !important",
      borderRadius: "5px",
      fontSize: "1rem !important",
      padding: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.3999rem 0.3rem !important",
      borderRadius: "100px",
      borderRadius: "50px",
      fontSize: "1rem !important",
      display: "flex",
      justifyContent: "center",
      width: "37px",
      height: "37px",
      alignItems: "center",
      marginLeft: "0.5rem",
      "& .addIcon": {
        fontSize: "1.2rem !important",
        color: "#fff",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.3999rem 0.3rem !important",
      borderRadius: "100px",
      borderRadius: "50px",
      fontSize: "1rem !important",
      display: "flex",
      justifyContent: "center",
      width: "35px",
      height: "35px",
      alignItems: "center",
      marginLeft: "0rem",
      "& .addIcon": {
        fontSize: "1.2rem !important",
        color: "#fff",
      },
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "10px !important",
      marginLeft: "0.5rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 5px !important",
      height: "35px",
      fontSize: "14px",
      "& .addIcon": {
        fontSize: "1.2rem !important",
        color: "#fff",
      },
    },
    "&:disabled": {
      // opacity: 0.4,
      // cursor: "not-allowed",
      display: "none",
    },
  },
  searchButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#122164",
    padding: "10px 20px",
    fontWeight: 400,
    fontSize: "1rem",
    color: "white",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    whiteSpace: "nowrap",
    fontFamily: "AvenirDemi",
    height: "35px",
    "&:hover": {
      backgroundColor: "#4a5aa3",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      fontWeight: 100,
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "15px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  searchIconMobile: {},
  mobileSearchIcon: {
    fontSize: "2.4rem",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "20px",
    [theme.breakpoints.up("md")]: {
      width: "280px",
    },
    [theme.breakpoints.down("md")]: {
      width: "170px",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      margin: "0px 0.5rem",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  profileContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  gativanLogoWrapper: {
    margin: "0px 1rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  notificationIcon: {
    color: "black",
    fontSize: "2rem",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  profileIcon: {
    cursor: "pointer",
    width: 40,
    height: 40,
    fontSize: "1.1rem",
    backgroundColor: "#122164",
    [theme.breakpoints.down("md")]: {
      width: 37,
      height: 37,
      fontSize: "1.1rem",
      width: 37,
      height: 37,
    },
    [theme.breakpoints.down("xs")]: {
      width: 35,
      height: 35,
      fontSize: "1rem",
    },
  },
  mobileView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    alignItems: "center",
  },

  tabletView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: `calc(100% - ${240}px)`,
    alignItems: "center",
  },
  menuIcon: {
    fontSize: "3rem",
  },
  drawerStyle: {
    height: "100vh",
    backgroundColor: "#e4e5e7",
    display: "flex",
    justifyContent: "center",
    padding: "0px 2rem",
  },
  userDetails: {
    width: "100%",
    padding: "0px 1rem",
    marginTop: "1rem",
  },
  adminRole: {
    fontSize: "15px",
    wordBreak: "breal-word",
  },
  userIconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  userDetailWrapper: {
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   "& Box": {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   },
    // },
    // marginBottom: "1rem",
  },
  buttonsWrapper: {
    margin: "2px",
    width: "100%",
    backgroundColor: "#fff",
    padding: "20px 10px",
    borderRadius: "5px",
  },
  userIcon: {
    color: "black",
    fontSize: "2.6rem !important",
    cursor: "pointer",
    width: "76px",
    height: "76px",
  },
  adminConsoleButton: {
    // backgroundColor: "#122164",
    color: "#122164",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#122164",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  viewMyProfileButton: {
    // backgroundColor: "#7B8BD4",
    color: "#7B8BD4",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#7B8BD4",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  logoutButton: {
    // backgroundColor: "#707070",
    color: "#dd4242",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#dd4242",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gativanLogo: {
    backgroundColor: "#122164",
    cursor: "pointer",
    width: "100px",
    height: "50px",
  },
}));

const Navbar = ({ drawerWidth, isLoggedOut }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [isUserProfile, setUserProfile] = useState("");
  const [currentUserDetails, setCurrentUserDetails] = useState({});

  const userDrawer = isSmallMobile ? 300 : 420;

  const dispatch = useDispatch();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleSearch = (e) => {};
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const pathname = window.location.pathname;

  useEffect(() => {
    setUserProfile(pathname);
  }, [pathname]);

  const logoutOnTokenExpired = () => {
    const loginDetail = {
      isLogged: false,
      jwtToken: "",
      role: "",
    };

    localStorage.setItem("loginDetails", JSON.stringify(loginDetail));
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const isLogged = getLoginDetailsLS?.isLogged;
    const jwtToken = getLoginDetailsLS?.jwtToken;
    const role = getLoginDetailsLS?.role;
    dispatch(
      AdminLogout({
        isLogged,
        jwtToken,
        role,
      })
    );
    navigate("/");
    sessionStorage.setItem("isAdminConsole", false);
  };

  const localData = JSON.parse(localStorage.getItem("loginDetails"));
  const loggedUserDetails = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData.jwtToken}`,
      },
    };
    try {
      const response = await getUserProfile(config);
      const data = await response.data;
      setCurrentUserDetails(data.result);
    } catch (error) {
      console.log("error", error);
      if ([0, 403, 401].includes(error.response.status)) {
        logoutOnTokenExpired();
      }
    }
  };

  useEffect(() => {
    loggedUserDetails();
  }, []);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  const userData = useSelector((state) => state?.ViewProfile);

  const {
    handleDrawerToggle,
    handleOpenLeads,
    handleOpenGarage,
    // viewAdminConsole,
  } = commonData;

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  // Clear Cache

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("Complete Cache Cleared");
  };

  // ----handelLogout ------
  const handelLogout = () => {
    const loginDetail = {
      isLogged: false,
      jwtToken: "",
      role: "",
    };

    localStorage.setItem("loginDetails", JSON.stringify(loginDetail));
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const isLogged = getLoginDetailsLS?.isLogged;
    const jwtToken = getLoginDetailsLS?.jwtToken;
    const role = getLoginDetailsLS?.role;
    dispatch(
      AdminLogout({
        isLogged,
        jwtToken,
        role,
      })
    );
    clearCacheData();
    sessionStorage.setItem("isAdminConsole", false);
    navigate("/");
    isLoggedOut();
    // handleClose();
  };

  // User Profile Drawer
  const [state, setState] = React.useState({
    right: false,
  });
  // viewAdminConsole
  const toggleDrawer = (anchor, open, url, clickedButton) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    navigate(url);
    if (clickedButton && clickedButton == "adminConsoleButton") {
      // viewAdminConsole(true);
      sessionStorage.setItem("isAdminConsole", true);
    } else {
      sessionStorage.setItem("isAdminConsole", false);
      // viewAdminConsole(false);
    }
  };

  const [userRole, setUserRole] = useState("");
  // const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
  // const isLogged = getLoginDetailsLS?.isLogged;
  // const role = getLoginDetailsLS?.role;

  const [isAddLeadButton, setisAddLeadButton] = useState(false);
  const [isAddGarageButton, setisAddGarageButton] = useState(false);

  useEffect(() => {
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const isLogged = getLoginDetailsLS?.isLogged;
    const role = getLoginDetailsLS?.role.replace(" ", "").toLowerCase();

    const leads = [
      "superadmin",
      "admin",
      "serviceadvisor",
      "leadowner",
      "engineer",
    ].includes(role);

    const garage = [
      "superadmin",
      "admin",
      "serviceadvisor",
      "engineer",
    ].includes(role);

    setisAddLeadButton(!leads);
    setisAddGarageButton(!garage);

    // setIsLogged(isLogged);
    setUserRole(role);
  }, [window.location.href]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        content: '""',
      },
    },
  }));

  const userProfileDrawer = (anchor) => (
    <Box
      // sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      width={userDrawer}
      role="User Profile"
      className={classes.drawerStyle}
    >
      <Box>
        <Box
          style={{
            position: "absolute",
            top: 0,
            right: "10px",
            margin: "0.2rem 0px",
          }}
        >
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(anchor, false)}
          />
        </Box>
        <Grid container spacing={2} className={classes.userDetails}>
          <Grid item xs={12} sm={3} className={classes.userIconWrapper}>
            {/* <PortraitIcon className={classes.userIcon} /> */}
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt="Remy Sharp"
                variant="rounded"
                sx={{ width: "76px", height: "76px" }}
                className={classes.userIcon}
              >
                {`${
                  currentUserDetails?.firstName &&
                  currentUserDetails?.firstName[0]?.toUpperCase()
                }${
                  currentUserDetails?.lastName &&
                  currentUserDetails?.lastName[0]?.toUpperCase()
                }`}
              </Avatar>
            </StyledBadge>
            {/* <Box sx={{ width: "140px", textAlign: "center" }}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="picture"
                  name="picture"
                  type="file"
                  onChange={handleImage}
                />
                <label htmlFor="picture">
                  <Avatar className={classes.imageAvatar} variant="rounded">
                    {userDetails?.picture ? (
                      <img
                        src={URL.createObjectURL(userDetails?.picture)}
                        width="99%"
                        height="99%"
                      />
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            width: "100%",
                            color: "#fff",
                            transform: "translate(-50%,-50%)",
                            textAlign: "center",
                          }}
                        >
                          <AddIcon />
                          <Typography>Upload Photo</Typography>
                        </Box>
                        <PersonIcon style={{ fontSize: "100px" }} />
                      </Box>
                    )}
                  </Avatar>
                </label>
                {userDetails?.picture && (
                  <Typography>{userDetails?.picture?.name}</Typography>
                )}
              </Box> */}
          </Grid>
          <Grid item xs={12} sm={9} className={classes.userDetailWrapper}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">{`${currentUserDetails?.firstName} ${currentUserDetails?.lastName}`}</Typography>
              <Typography
                sx={{
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  wordBreak: "break-word",
                  lineHeight: 2.2,
                }}
              >
                {currentUserDetails?.email}
              </Typography>
              <Typography>Id : {currentUserDetails?.id}</Typography>

              {/* <Typography className={classes.adminRole}>
                <span>UserId : </span>
                <span>{currentUserDetails?.userName}</span>
              </Typography> */}

              {/* <Typography className={classes.adminRole}>
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {currentUserDetails?.role} :{" "}
                </span>
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {currentUserDetails?.userName}
                </span>
              </Typography> */}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "1rem",
          }}
        >
          <Typography className={classes.adminRole}>
            <span style={{ fontSize: "0.9rem" }}>
              {/* {currentUserDetails?.role} :{" "} */}
              Super Admin:
            </span>
            <span style={{ fontSize: "1rem", fontWeight: "600" }}>
              {/* {currentUserDetails?.userName} */}
              viraj.kalyani@kalyanistudio.com
            </span>
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          className={classes.buttonsWrapper}
          style={{ width: userDrawer - 10 }}
        >
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 1 }}>
            <Button
              // variant="contained"
              className={classes.viewMyProfileButton}
              onClick={toggleDrawer(
                anchor,
                false,
                "/myAccount",
                // viewAdminConsole,
                "myAccountButton"
              )}
            >
              <PermIdentityIcon />{" "}
              <span style={{ paddingLeft: "0.5rem" }}>My Account</span>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 3, sm: 2 }}>
            <Button
              // variant="contained"
              className={classes.logoutButton}
              color="error"
              onClick={handelLogout}
            >
              <LogoutIcon />{" "}
              <span style={{ paddingLeft: "0.5rem" }}>logout </span>
            </Button>
          </Grid>
          {(userRole.toLowerCase() == "superadmin" ||
            userRole.toLowerCase() == "admin") && (
            <Grid item xs={12} sm={6} order={{ xs: 2, sm: 3 }}>
              <Button
                // variant="contained"
                className={classes.adminConsoleButton}
                color="success"
                sx={{ backgroundColor: "#122164" }}
                onClick={toggleDrawer(
                  anchor,
                  false,
                  "/adminConsole",
                  // viewAdminConsole,
                  "adminConsoleButton"
                )}
              >
                <SupervisorAccountIcon />
                <span style={{ paddingLeft: "0.5rem" }}> Admin Console </span>
              </Button>
            </Grid>
          )}
          {/* <Grid item xs={6}>
            <Button
              variant="contained"
              color="default"
              onClick={toggleDrawer(
                anchor,
                false,
                "/userProfile/list/signUp"
              )}
            >
              Sign Up
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
//aditya
  return (
    <Box sx={{ mb: "3.2rem" }}>
      {/* {userData?.viewProfile ? ( */}
      {isUserProfile.includes("myAccount") ||
      isUserProfile.includes("adminConsole") ? (
        <AppBar
          position=""
          className={classes.appBar}
          style={{
            backgroundColor: "white",
            color: "black",
            width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
            position: "fixed",
            zIndex: 999,
            overflow: "auto",
          }}
          sx={{ padding: isSmallMobile ? "0px" : "auto" }}
        >
          <Toolbar
            sx={{
              padding: isSmallMobile ? "0px" : "auto",
              width: isSmallMobile ? "96%" : "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: isTablet ? "68%" : "100%",
              }}
            >
              <Box>
                <Typography className={classes.title} variant="h6" noWrap>
                  <img
                    style={{ width: "100%", height: "10vw" }}
                    src={GativanBlueLogo}
                    alt={GativanBlueLogo}
                    // width={130}
                    // height={50}
                    onClick={() => navigate("/")}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  className={classes.profileIcon}
                  onClick={toggleDrawer("right", true)}
                >
                  {`${
                    currentUserDetails?.firstName &&
                    currentUserDetails?.firstName[0]
                  }${
                    currentUserDetails?.lastName &&
                    currentUserDetails?.lastName[0]
                  }`}
                </Avatar>

                {isMobile && (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon className={classes.menuIcon} />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Toolbar>
          <Drawer
            // width={500}
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {userProfileDrawer("right")}
          </Drawer>
        </AppBar>
      ) : (
        //aditya
        <AppBar
          size="small"
          position=""
          className={classes.appBar}
          style={{
            backgroundColor: "white",
            color: "black",
            width: isMobile ? "100%" : `calc(100% - ${drawerWidth}px)`,
            position: "fixed",
            zIndex: 999,
            overflow: "auto",
            padding: "0px 0.5rem",
            boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.15)"
          }}
          // sx={{ padding: isSmallMobile ? "0px" : "auto" }}
        >
          <Toolbar
            style={{
              padding: isSmallMobile ? "0px" : "0px",
              width: isSmallMobile ? "96%" : "100%",
              minHeight: "52px",
            }}
          >
            {isSmallMobile && (
              <Box className={classes.mobileView}>
                <div>
                  <Typography className={classes.title} variant="h6" noWrap>
                    <img
                      style={{ width: "100%", height: "10vw" }}
                      src={GativanBlueLogo}
                      alt={GativanBlueLogo}
                      // width={110}
                      // height={37}
                      onClick={() => navigate("/")}
                    />
                  </Typography>
                </div>
                {/* <div className={classes.searchIconMobile}>
                  <SearchIcon className={classes.mobileSearchIcon} />
                </div> */}
                <button
                  className={classes.addButtonsWrapper}
                  onClick={handleOpenLeads}
                  disabled={isAddLeadButton}
                >
                  <span className={classes.addLeadsButton}>
                    L<AddIcon className="addIcon" />
                  </span>
                </button>
                <button
                  className={classes.addButtonsWrapper}
                  onClick={handleOpenGarage}
                  disabled={isAddGarageButton}
                >
                  <span className={classes.addGarageButton}>
                    G<AddIcon className="addIcon" />
                  </span>
                </button>
                <div>
                  <Avatar
                    className={classes.profileIcon}
                    onClick={toggleDrawer("right", true)}
                  >
                    {`${
                      currentUserDetails?.firstName &&
                      currentUserDetails?.firstName[0]
                    }${
                      currentUserDetails?.lastName &&
                      currentUserDetails?.lastName[0]
                    }`}
                  </Avatar>
                </div>
              </Box>
            )}

            {isTablet && (
              <Box
                className={classes.tabletView}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <div>
                  <Typography className={classes.title} variant="h6" noWrap>
                    <img
                      src={GativanBlueLogo}
                      alt={GativanBlueLogo}
                      width={130}
                      height={50}
                      onClick={() => navigate("/")}
                    />
                  </Typography>
                </div> */}

                <div className={classes.searchIconMobile} sx={{ width: "60%" }}>
                  {/* <SearchIcon className={classes.mobileSearchIcon} /> */}
                  <div className={classes.searchBoxWrapper}>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                      />
                    </div>
                    <button className={classes.searchButton}>Search</button>
                  </div>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "40%",
                  }}
                >
                  <button
                    className={classes.addButtonsWrapper}
                    onClick={handleOpenLeads}
                    disabled={isAddLeadButton}
                  >
                    <span className={classes.addLeadsButton}>
                      L<AddIcon className="addIcon" />
                    </span>
                  </button>
                  <button
                    className={classes.addButtonsWrapper}
                    onClick={handleOpenGarage}
                    disabled={isAddGarageButton}
                  >
                    <span className={classes.addGarageButton}>
                      G<AddIcon className="addIcon" />
                    </span>
                  </button>
                  <div>
                    <Avatar
                      className={classes.profileIcon}
                      onClick={toggleDrawer("right", true)}
                    >
                      {`${
                        currentUserDetails?.firstName &&
                        currentUserDetails?.firstName[0]
                      }${
                        currentUserDetails?.lastName &&
                        currentUserDetails?.lastName[0]
                      }`}
                    </Avatar>
                  </div>
                </Box>
              </Box>
            )}

            <div className={classes.searchbarContainer}>
              <div className={classes.searchBoxWrapper}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleSearch}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
                <button className={classes.searchButton}>Search</button>
              </div>
            </div>
            <div className={classes.grow} />
            <Box
              style={{
                display: "flex",
                height: isSmallMobile ? "40px" : "auto",
              }}
            >
              {!isMobile && (
                <button
                  variant="contained"
                  className={classes.addLeadsButton}
                  onClick={handleOpenLeads}
                  disabled={isAddLeadButton}
                >
                  <span>Add Leads</span>
                  <AddIcon className="addIcon" />
                </button>
              )}

              {!isMobile && (
                <button
                  className={classes.addGarageButton}
                  onClick={handleOpenGarage}
                  disabled={isAddGarageButton}
                  style={{ "&:hover": { color: "red" } }}
                >
                  <div className={classes.mobileButtons}>
                    <span>Add Garage</span> <AddIcon className="addIcon" />
                  </div>
                </button>
              )}
            </Box>
            <div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon className={classes.notificationIcon} />
                  </Badge>
                </IconButton>
              </div>
            </div>
            {!isMobile && (
              <Avatar
                className={classes.profileIcon}
                onClick={toggleDrawer("right", true)}
              >
                {`${
                  currentUserDetails?.firstName &&
                  currentUserDetails?.firstName[0]
                }${
                  currentUserDetails?.lastName &&
                  currentUserDetails?.lastName[0]
                }`}
              </Avatar>
            )}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Toolbar>
          <Drawer
            // width={500}
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            {userProfileDrawer("right")}
          </Drawer>
        </AppBar>
      )}
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

export default Navbar;
