import React from "react";
import { Typography } from "@mui/material";

const Privacy = () => {
  return (
    <div style={{ padding: "3rem" }}>
      {" "}
      <Typography variant="h4" className="titles">Privacy</Typography>
    </div>
  );
};

export default Privacy;
