import React, { useState, useEffect, useContext, useMemo } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CommonContextAPI } from "../../../App";
import moment from "moment";
import { getServicesSales } from "../../../services/dashboard/DashboardServices";
import LoaderSpinner from "../../loading/LoaderSpinner";
import { Box, Typography } from "@mui/material";
import carImage from './car.png';
import bikeImage from './bike.png';

export default function SarviceSales() {
  const [viewMore, setviewMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const { filteredDate, defaultDate } = commonData;

  const [servicesSales, setServicesSales] = useState([]);
  const [sortedServicesSales, setSortedServicesSales] = useState([]);

  // GET SERVICES SALES
  const getSericesSales = async (filteredDate) => {
    const { jwtToken } = JSON.parse(localStorage.getItem("loginDetails"));

    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    try {
      const response = await getServicesSales(config);
      const data = await response.data;
      const result = data?.result?.sort((a, b) => b.sales - a.sales);
      setServicesSales(result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  // DISPLAY 5 RECORDS ON FIRST RENDER
  useMemo(() => {
    const services = servicesSales?.slice(0, 5);
    setSortedServicesSales(services);
  }, [servicesSales]);

  // CALL SALES SERVICE API ON FILTERED DATE CHANGE OR FIRST TIME ON RENDER
  useEffect(() => {
    if (filteredDate || defaultDate) {
      setServicesSales([]);
      getSericesSales(filteredDate);
      setIsLoading(true);
    }
    console.log("filteredDate", filteredDate);
  }, [filteredDate, defaultDate]);

  // TOGGLE BETWEEN VIEW MORE AND VIEW LESS
  const handelViewMore = () => {
    if (servicesSales?.length > 0) {
      setviewMore((prev) => !prev);
      if (!viewMore) {
        setSortedServicesSales(servicesSales);
      } else {
        const services = servicesSales?.slice(0, 5);
        setSortedServicesSales(services);
      }
    } else {
      alert("No record to show!");
    }
  };

  const getDataUsingFilters = (e) => {
    const value = e.target.value;

    const currentDate = moment();

    const week = [
      {
        startDate: currentDate.clone().startOf("week").format("YYYY-MM-DD"),
        endDate: currentDate.clone().endOf("week").format("YYYY-MM-DD"),
      },
    ];

    const month = [
      {
        startDate: currentDate.clone().startOf("month").format("YYYY-MM-DD"),
        endDate: currentDate.clone().endOf("month").format("YYYY-MM-DD"),
      },
    ];

    const year = [
      {
        startDate: currentDate.clone().startOf("year").format("YYYY-MM-DD"),
        endDate: currentDate.clone().endOf("year").format("YYYY-MM-DD"),
      },
    ];

    let date;
    switch (value) {
      case "Today":
        date = [
          {
            startDate: new Date(),
            endDate: new Date(),
          },
        ];
        break;

      case "Week":
        date = week;
        break;

      case "Month":
        date = month;
        break;
      case "Year":
        date = year;
        break;

      default:
        date = filteredDate;
    }

    setviewMore(false);
    setIsLoading(true);
    getSericesSales(date);
  };

  return (
    <div className="serviceSale-section">
      <div className="sale-header">
        <h6 className="sale-title">Service Sales</h6>
        <div className="">
          <select className="selectionButton" onChange={getDataUsingFilters}>
            <option value="">Filter</option>
            <option value="Today">Today</option>
            <option value="Week">Week</option>
            <option value="Month">Month</option>
            <option value="Year">Year</option>
          </select>
        </div>
      </div>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <div className="sele-service-section" >
          <div style={{ height: "374px", overflow: "auto" }}>
            {sortedServicesSales?.length > 0 ? (
              sortedServicesSales?.map((item) => {
                const {
                  serviceId,
                  serviceName,
                  sales,
                  orderCount,
                  vehicleType: { vehicleType },
                } = item;
                return (
                  <div className="sale-servicecard" key={serviceId}>
                   <div className="service-image">
  <img
    src={vehicleType === "Car" ? carImage : bikeImage}
    alt={vehicleType}
    style={{ width: "100%", height: "100%" }}
  />
</div>

                    <div>
                      <h4 className="sale-service-name">
                        {vehicleType} {serviceName}
                      </h4>
                      <div className="orders-price-section">
                        <p>
                          {orderCount > 120
                            ? orderCount + "+ Orders"
                            : orderCount}
                        </p>
                        <p style={{
    color: '#122164',
    fontFamily: 'AvenirRegular',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginRight: '12px'
}} className="ordervalue">
    {" "}
    <CurrencyRupeeIcon className="rupeeIcon" />
    {sales}
</p>

                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Box>
                <Typography variant="h6">Services Sale not found.</Typography>
              </Box>
            )}
          </div>
          {sortedServicesSales?.length > 0 && (
            <div className="view-more-section">
              {viewMore ? (
                <h6 onClick={handelViewMore}>
                  View Less <ExpandLessIcon className="ExpandIcon" />
                </h6>
              ) : (
                <h6 onClick={handelViewMore}>
                  View More
                  <ExpandMoreIcon className="ExpandIcon" />
                </h6>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
