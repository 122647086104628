import React, { createContext, useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../../modules/leads/Leads.css";
import ModuleHeader from "../headers/ModuleHeader";
import { makeStyles } from "@material-ui/core/styles";
import "./Table.css";
import { useSelector } from "react-redux";
import { DataGridStyle } from "../tables/DataGridStyles";
import { CommonContextAPI } from "../../App";
import { height } from "@mui/system";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "100%",
    height: "100%",
    // [theme.breakpoints.down("xs")]: {
    //   height: "1300px",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   height: "100%",
    // },
  },
  dataGridWrapper: {
    [theme.breakpoints.down("xs")]: {
      height: "68vh",
    },
    [theme.breakpoints.down("md")]: {
      // height: "70vh",
      height: "70vh !important",
    },
    [theme.breakpoints.up("md")]: {
      height: "78vh !important",
      // height: "80vh !important",
    },
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Table = ({
  tableWidth,
  heading,
  downloadXLS,
  initialState,
  classes,
  rows,
  columns,
  pageSize,
  rowsPerPageOptions,
  disableSelectionOnClick,
  onCellEditCommit,
  isCellEditable,
  onCellClick,
  onStateChange,
  total,
  loading,
  checkBox,
  setTableView,
  customStyle,
  components,
  getDate,
  handleOpenDatePicker,
  filteredDate,
  filterModel,
  onFilterModelChange,
  filterDateRenge,
  applyFilter,
  setFilteredDate,
  getRowId,
  checkboxSelection,
  onPageChange,
  getRowClassName,
  sortModel,
  isCollapsibleTable,
}) => {
  const [smallDevice, setSmallDevice] = useState(false);

  const localClasses = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptop = useMediaQuery(theme.breakpoints.up("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

  const pathname = window.location.pathname;
  const isList = pathname == "/adminConsole/users";

  const windowWidth = window.innerWidth;

  const getSelectedDate = (date) => {
    getDate(date);
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const { appliedFilters, setAppliedFilters } = commonData;

  useEffect(() => {
    if (windowWidth < 1378) setSmallDevice(true);
  }, [windowWidth]);

  return (
    <>
      {" "}
      <Box sx={{ display: "flex" }} className={localClasses.tableWrapper}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        ></Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: isMobile ? 2 : 4,
            width: { sm: "100%" },
          }}
        >
          {/* {!isList && <Toolbar className={localClasses.toolbar} />} */}
          <Box sx={{ margin: isMobile ? "0.5rem 0px" : "1rem 0px" }}>
            <ModuleHeader
              heading={heading}
              downloadXLS={downloadXLS}
              setTableView={setTableView}
              getSelectedDate={getSelectedDate}
              handleOpenDatePicker={handleOpenDatePicker}
              filteredDate={filteredDate}
              filterDateRenge={filterDateRenge}
              setFilteredDate={setFilteredDate}
            />
          </Box>
          {/* {!isList && <Toolbar />} */}
          <Box
            style={{
              ...DataGridStyle.dataGridConatiner,
              marginTop: "30px",
            }}
            className={localClasses.dataGridWrapper}
            sx={{
              width: tableWidth || "100%",
              height: "80vh",
              marginTop: "0px",
              position: "relative",
              "&:hover": {
                cursor: "pointer",
              },
              "& .super-app-theme--header": {
                backgroundColor: "#122164",
                color: "#fff",
                textAlign: "center !important",
                "& .MuiSvgIcon-fontSizeSmall": {
                  color: "#fff !important",
                  visibility: "visible !important",
                  display: "block !important",
                },
                "& .css-1durks0 .super-app-theme--header .MuiSvgIcon-fontSizeSmall":
                  {
                    color: "#fff !important",
                    visibility: "visible !important",
                    display: "block !important",
                  },
              },
              "& .MuiMenuItem-root": {
                backgroundColor: "black !important",
                color: "red !important",
              },
              "& .MuiPaper-root": {
                backgroundColor: "red !important",
              },
              "& .MuiMenu-list": {
                backgroundColor: "#123456 !important",
              },
              "& .css-r11z79-MuiDataGrid-root .MuiDataGrid-cell": {
                border:
                  window.location.pathname == "/servicinggrossprofit" &&
                  "none !important",
                outline:
                  window.location.pathname == "/servicinggrossprofit" &&
                  "none !important",
              },
            }}
          >
            {applyFilter ? (
              <DataGrid
                sx={customStyle}
                getCellClassName={(params) => {
                  return "Rows";
                }}
                // initialState={initialState && initialState}
                classes={classes && classes}
                onCellEditCommit={onCellEditCommit && onCellEditCommit}
                rows={rows && rows}
                columns={columns && columns}
                getRowHeight={() => (smallDevice ? 35 : 50)}
                pageSize={pageSize && pageSize}
                rowsPerPageOptions={rowsPerPageOptions && rowsPerPageOptions}
                disableSelectionOnClick={
                  disableSelectionOnClick && disableSelectionOnClick
                }
                isCellEditable={isCellEditable && isCellEditable}
                onCellClick={onCellClick && onCellClick}
                onStateChange={onStateChange && onStateChange}
                loading={loading}
                checkboxSelection={checkBox && checkBox}
                components={components && components}
                filterModel={{
                  items: filterModel,
                }}
                // filterModel={filterModel}
                onFilterModelChange={onFilterModelChange && onFilterModelChange}
                filterDateRenge={filterDateRenge && filterDateRenge}
                getRowId={getRowId && getRowId}
                localeText={{
                  toolbarExport: isMobile ? "" : "Export",
                }}
                onPageChange={onPageChange}
                getRowClassName={getRowClassName && getRowClassName}
                sortModel={sortModel && sortModel}
              />
            ) : (
              <DataGrid
                sx={customStyle}
                getCellClassName={(params) => {
                  if (params.field === "status") {
                    switch (params.value) {
                      case "Open":
                        return "open";

                      case "Close":
                        return "close";

                      case "In Progress":
                        return "inProgress";

                      case "Solved":
                        return "solved";

                      case "Not Solved":
                        return "notSolved";
                    }
                  }

                  if (params.field === "customerOwner") {
                    switch (params.value) {
                      case "Ganesh":
                        return "customerOwner1";

                      case "Test1":
                        return "customerOwner2";

                      case "Test2":
                        return "customerOwner3";

                      case "Test3":
                        return "customerOwner4";
                    }
                  }

                  if (params.field === "orderStatus") {
                    switch (params.value) {
                      case "Not Started":
                        return "notstarted";

                      case "Test1":
                        return "customerOwner2";

                      case "Test2":
                        return "customerOwner3";

                      case "Test3":
                        return "customerOwner4";
                    }
                  }
                }}
                initialState={initialState && initialState}
                classes={classes && classes}
                rows={rows && rows}
                columns={columns && columns}
                getRowHeight={() => (smallDevice ? 35 : 50)}
                pageSize={pageSize && pageSize}
                rowsPerPageOptions={rowsPerPageOptions && rowsPerPageOptions}
                disableSelectionOnClick={
                  disableSelectionOnClick && disableSelectionOnClick
                }
                onCellEditCommit={onCellEditCommit && onCellEditCommit}
                isCellEditable={isCellEditable && isCellEditable}
                onCellClick={onCellClick && onCellClick}
                onStateChange={onStateChange && onStateChange}
                loading={loading}
                checkboxSelection={checkBox && checkBox}
                components={components && components}
                // filterModel={{
                //   items: appliedFilter
                // }}
                filterModel={appliedFilters}
                onFilterModelChange={(newVal) => setAppliedFilters(newVal)}
                filterDateRenge={filterDateRenge && filterDateRenge}
                getRowId={getRowId && getRowId}
                onPageChange={onPageChange && onPageChange}
                localeText={{
                  toolbarExport: isMobile ? "" : "Export",
                }}
                getRowClassName={getRowClassName && getRowClassName}
                sortModel={sortModel && sortModel}
              />
            )}
            <div>{total}</div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Table;
