import React, { useMemo, useState, useEffect, useContext } from "react";
import DashboardComponent from "../../components/dashboard/DashboardComponent";
import moment from "moment";
import { useSelector } from "react-redux";
import { getServicingGrossProfitReports } from "../../services/grossProfitService/GrossProfitServices";

import { getYear } from "date-fns";
import {
  ConstructionOutlined,
  CoPresentOutlined,
  Hearing,
} from "@mui/icons-material";
import { CommonContextAPI } from "../../App";

const GrossProfitDashboard = () => {
  const [dayFilters, setDayFilters] = useState();
  const [actulLeads, setActulLeads] = useState([]);

  const [ordersCount, setOrdersCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [revenue, setRevenue] = useState(0);

  const [YearToggle, setYearToggle] = useState(1);
  const [YearsToggle, setYearsToggle] = useState(0);
  const [sales, setSales] = useState([]);
  const [salesTemp, setSalesTemp] = useState([]);
  const [salesDataKey, setSalesDataKey] = useState("month");
  const [salesMonthllyData, setSalesMonthllyData] = useState([]);
  const [salesDaillyData, setSalesDaillyData] = useState([]);
  const [saleByVehicleType, setSaleByVehicleType] = useState([]);
  const [salesTempYearly, setSalesTempYearly] = useState([]);
  const [saleByGarage, setGarageSale] = useState([]);
  const [locationWiseSale, setLocationWiseSale] = useState([]);
  const [serviceWiseSale, setServiceWiseSale] = useState([]);
  const [grossProfitReportList, setGrossProfitReportList] = useState([]);
  const [grossProfitCount, setGrossProfitCount] = useState(null);
  const [grossMarginSum, setGrossMarginSum] = useState(null);
  const [grossMargin, setGrossMargin] = useState(null);
  const [filteredGrossProfit, setFilteredGrossProfit] = useState([]);

  // CONSTANT VALUES
  const { MONTHLY_TARGET } = useSelector((state) => state?.AppConstants);

  // Get gross profit list to calculate gross margin
  const grossProfitReports = async () => {
    try {
      const response = await getServicingGrossProfitReports();
      const data = await response.data;
      setGrossProfitReportList(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const { handleOpenDatePicker, filteredDate, appliedDate } = commonData;

  useEffect(() => {
    filteredGrossProfit?.map((grossProfit) => {
      setGrossMarginSum((prev) => prev + grossProfit?.grossMargin);
    });
    setGrossProfitCount(filteredGrossProfit?.length);
  }, [filteredGrossProfit]);

  useEffect(() => {
    setGrossMargin((grossMarginSum / grossProfitCount).toFixed(2));
  }, [grossMarginSum]);

  // API Call
  useMemo(() => {
    grossProfitReports();
  }, []);

  useMemo(() => {
    if (filteredDate == null) {
      grossProfitReports();
    }
  }, [filteredDate]);

  useEffect(() => {
    setYearToggle(1);
    // filteredDate &&
    // setGrossProfitReportList([]);
    setFilteredGrossProfit([]);
    setGrossProfitCount(null);
    setGrossMarginSum(null);
    setGrossMargin(null);
    grossProfitReportList?.map((grossProfit) => {
      if (filteredDate != null) {
        if (grossProfit?.order?.completionDate != null) {
          if (
            moment(grossProfit?.order?.completionDate).format("YYYY-MM-DD") >=
              moment(filteredDate[0] && filteredDate[0]?.startDate).format(
                "YYYY-MM-DD"
              ) &&
            moment(grossProfit?.order?.completionDate).format("YYYY-MM-DD") <=
              moment(filteredDate[0] && filteredDate[0]?.endDate).format(
                "YYYY-MM-DD"
              )
          ) {
            setFilteredGrossProfit((prev) => [...prev, grossProfit]);
          }
        }
      } else {
        setFilteredGrossProfit((prev) => [...prev, grossProfit]);
      }
    });
  }, [, grossProfitReportList, filteredDate && filteredDate]);

  const handleDayFilterChange = (event) => {
    setDayFilters(event.target.value);
  };
  //=============Y

  // =========================Carousel=======================//

  // ==================Latest Order Review Carosel=========//

  // =======================Gross Profit Array=============================//
  const grossProfitByMonth = [
    {
      name: "Walk-in",
      id: 1,
    },
    {
      name: "Calls",
      id: 2,
    },
    {
      name: "Gativan Garage",
      id: 3,
    },
    {
      name: "Web",
      id: 4,
    },
    {
      name: "Wati",
      id: 5,
    },
  ];

  // ==============================Service Sales Array===============================//
  const saleByLocationWise = [
    {
      id: 1,
      serviceimg: "/Assets/services/Car Service.png",
      location: "Kharadi",
      orders: "120+Orders",
      sale: "2.8L",
    },
    {
      id: 2,
      serviceimg: "/Assets/services/Car Service.png",
      location: "Mundwa",
      orders: "120+Orders",
      sale: "2.6L",
    },
    {
      id: 3,
      serviceimg: "/Assets/services/Car Service.png",
      location: "Pune Station",
      orders: "120+Orders",
      sale: "0.8L",
    },
    {
      id: 4,
      serviceimg: "/Assets/services/Car Service.png",
      location: "Katraj",
      orders: "120+Orders",
      sale: "0.8L",
    },
    {
      id: 5,
      serviceimg: "/Assets/services/Car Service.png",
      location: "Wagholi",
      orders: "120+Orders",
      sale: "0.8L",
    },
  ];

  // Service Wise Sale
  const serviceWiseGrossProfit = [
    {
      id: 1,
      serviceimg: "/Assets/services/Car Service.png",
      service: "Pro Service",
      orders: "120+Orders",
      sale: "2.8L",
    },
    {
      id: 2,
      serviceimg: "/Assets/services/Car Service.png",
      service: "Basic Service",
      orders: "120+Orders",
      sale: "2.6L",
    },
    {
      id: 3,
      serviceimg: "/Assets/services/Car Service.png",
      service: "Painting & Denting",
      orders: "120+Orders",
      sale: "0.8L",
    },
    {
      id: 4,
      serviceimg: "/Assets/services/Car Service.png",
      service: "Pro Service",
      orders: "120+Orders",
      sale: "0.8L",
    },
    {
      id: 5,
      serviceimg: "/Assets/services/Car Service.png",
      service: "Pro Service",
      orders: "120+Orders",
      sale: "0.8L",
    },
  ];

  //==================year-month-week===============================//
  const dayFilter = [
    {
      id: 1,
      name: "Today ",
    },
    {
      id: 2,
      name: "last day",
    },
    {
      id: 3,
      name: "last month",
    },
  ];

  // =============================Graph-Overview Sales array Start=======================//

  const monthlyGrossProfiteData = [
    {
      name: "Apr 2021",
      sale: 0,
    },
    {
      name: "May 2021",
      sale: 0,
    },
    {
      name: "Jun 2021",
      sale: 940,
    },
    {
      name: "Jul 2021",
      sale: 16661,
    },
    {
      name: "Aug 2021",
      sale: 29560,
    },
    {
      name: "Sep 2021",
      sale: 12821,
    },
    {
      name: "Oct 2021",
      sale: 20567,
    },
    {
      name: "Nov 2021",
      sale: 14627,
    },
    {
      name: "Dec 2021",
      sale: 17576,
    },
    {
      name: "Jan 2022",
      sale: 16943,
    },
    {
      name: "Feb 2022",
      sale: 13020,
    },
    {
      name: "Mar 2022",
      sale: 17045,
    },
    {
      name: "Apr 2022",
      sale: 15118,
    },
    {
      name: "May 2022",
      sale: 24813,
    },
    {
      name: "Jun 2022",
      sale: 34062,
    },
    {
      name: "Jul 2022",
      sale: 72373,
    },
    {
      name: "Aug 2022",
      sale: 20056,
    },
    {
      name: "Sep 2022",
      sale: 57110,
    },
    {
      name: "Oct 2022",
      sale: 52267,
    },
    {
      name: "Nov 2022",
      sale: 71841,
    },
  ];

  // =============================Graph-Overview Sales array End =======================//
  const getUniqueYearsFromDate = () => {
    let getyears = filteredGrossProfit?.map((grossProfit) => {
      return grossProfit?.order?.completionDate?.split("-")[0];
    });
    const years = [...new Set(getyears)];
    return years.reverse().filter((year) => year != undefined);
  };
  // Handle yearly sale

  const handleSalesYears = () => {
    setSales([]);

    let yearllySalesTotal = null;

    if (filteredDate != null) {
      const years = getUniqueYearsFromDate();
      let start = moment(filteredDate[0] && filteredDate[0]?.startDate).format(
        "YYYY-MM-DD"
      );
      let end = moment(filteredDate[0] && filteredDate[0]?.endDate).format(
        "YYYY-MM-DD"
      );
      yearllySalesTotal = Object.values(
        salesTemp.reduce((acc, { year, grossProfit }) => {
          year = year?.split("-")[0];

          for (let i = start?.split("-")[0]; i <= end?.split("-")[0]; i++) {
            i = String(i);

            if (year == i) {
              acc[i] = acc[i] || {
                year: i,
                grossProfit: 0,
              };

              acc[i].grossProfit = String(
                Number(acc[i].grossProfit) + Number(grossProfit)
              );
            } else {
              acc[i] = acc[i] || {
                year: i,
                grossProfit: 0,
              };
            }
          }
          return acc;
        }, {})
      );
    } else {
      yearllySalesTotal = Object.values(
        salesTemp.reduce((acc, { year, grossProfit }) => {
          year = year?.split("-")[0];
          acc[year] = acc[year] || {
            year,
            grossProfit: 0,
          };

          acc[year].grossProfit = String(
            Number(acc[year].grossProfit) + Number(grossProfit)
          );
          return acc;
        }, {})
      );
    }

    yearllySalesTotal = yearllySalesTotal?.map((year) => {
      return {
        month: year?.year,
        grossProfit: year?.grossProfit,
      };
    });

    setSales(yearllySalesTotal);

    console.log("yearllySalesTotal", yearllySalesTotal);

    setSalesDataKey("year");
  };

  const generatedMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Handle monthly sale

  const handleSalesMonths = () => {
    setSales([]);

    let customIndexes = [];
    const filteredYear = getUniqueYearsFromDate();

    let curYear = new Date().getFullYear();

    let curMonth = new Date().getMonth();

    if (filteredDate != null) {
      let start = moment(filteredDate[0] && filteredDate[0]?.startDate).format(
        "YYYY-MM-DD"
      );
      let end = moment(filteredDate[0] && filteredDate[0]?.endDate).format(
        "YYYY-MM-DD"
      );

      for (let u = start?.split("-")[0]; u <= end?.split("-")[0]; u++) {
        if (u == start?.split("-")[0] && u == end?.split("-")[0]) {
          for (
            let i = Number(start?.split("-")[1]);
            i <= Number(end?.split("-")[1]);
            i++
          ) {
            let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;
            customIndexes[`${u} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${u}`,
            };
          }
        } else if (u == start?.split("-")[0]) {
          for (let i = Number(start?.split("-")[1]); i <= 12; i++) {
            let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;
            customIndexes[`${u} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${u}`,
            };
          }
        } else if (u == end?.split("-")[0]) {
          for (let i = 1; i <= Number(end?.split("-")[1]); i++) {
            let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;
            customIndexes[`${u} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${u}`,
            };
          }
        } else {
          for (let i = 1; i <= 12; i++) {
            let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;
            customIndexes[`${u} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${u}`,
            };
          }
        }
      }
    } else {
      filteredYear?.map((u, i) => {
        if (u == curYear) {
          curYear = curYear;
          curMonth = curMonth;
          for (let i = 1; i <= curMonth + 1; i++) {
            let mo = i < 10 ? `0${i}` : i;
            customIndexes[`${curYear} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${curYear}`,
            };
          }
        } else if (u == 2021) {
          for (let i = 6; i <= 12; i++) {
            let mo = i < 10 ? `0${i}` : i;
            customIndexes[`${u} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${u}`,
            };
          }
        } else {
          for (let i = 1; i <= 12; i++) {
            let mo = i < 10 ? `0${i}` : i;
            customIndexes[`${u} ${mo}`] = {
              grossProfit: 0,
              month: `${generatedMonths[i - 1]} ${u}`,
            };
          }
        }
      });
    }

    let monthllySalesTotal = Object.values(
      salesMonthllyData.reduce((acc, { year, grossProfit }) => {
        let month = `${year?.split("-")[0]} ${year?.split("-")[1]}`;
        let yInd = `${year?.split("-")[0]}`;
        let mInd = `${year?.split("-")[1]}`;
        customIndexes[month] = customIndexes[month] || {
          month: `${generatedMonths[mInd]} ${filteredYear[yInd]}`,
          grossProfit: 0,
        };
        customIndexes[month].grossProfit = String(
          Number(customIndexes[month].grossProfit) + Number(grossProfit)
        );
        // customIndexes[month].year = year;
        return customIndexes;
      }, {})
    );

    monthllySalesTotal = monthllySalesTotal.sort(
      (a, b) => Number(a.month.split(" ")[1]) - Number(b.month.split(" ")[1])
    );
    console.log("monthllySalesTotal", monthllySalesTotal);
    monthllySalesTotal && setSales(monthllySalesTotal);
    setSalesDataKey("month");
  };

  // Get months between two dates
  function getNumberOfMonths(startDate, endDate) {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var months = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        months.push({ month: displayMonth, year: i });
      }
    }
    return months;
  }

  // get number of weeks
  const getNumberOfWeeks = () => {
    let weeks = [];
    let year = new Date().getFullYear();
    let month = new Date().getMonth();

    let numberOfMonths;

    if (filteredDate !== null) {
      const startDate = filteredDate[0] && filteredDate[0]?.startDate;
      const endDate = filteredDate[0] && filteredDate[0]?.endDate;

      const startYear = new Date(startDate).getFullYear();
      const lastYear = new Date(endDate).getFullYear();
      const startMonth = new Date(startDate).getMonth();
      const lastMonth = new Date(endDate).getMonth();

      numberOfMonths = getNumberOfMonths(
        `${startYear}-${startMonth + 1}`,
        `${lastYear}-${lastMonth + 1}`
      );
    } else {
      // numberOfMonths = getNumberOfMonths(`${year}-${month + 2 - 3}`, `${year}-${month + 1}`);
      numberOfMonths = getNumberOfMonths(
        `${2021}-${6 + 1}`,
        `${year}-${month + 1}`
      );
    }

    let weekNumber = 0;
    numberOfMonths &&
      numberOfMonths?.map((res) => {
        const curMonth = new Date().getMonth() + 1;
        const curDate = new Date().getDate();

        if (res.month == curMonth) {
          const today = new Date(res.year, res.month);
          const fst = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          ).getDate();
          const lst = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          ).getDate();

          for (let i = fst; i <= curDate; i += 7) {
            weekNumber += 1;
            if (i < 22) {
              weeks.push({
                ws: i,
                we: i + 6,
                weekOf: `${i} - ${i + 6}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
            } else {
              weeks.push({
                ws: i,
                we: lst,
                weekOf: `${i} - ${lst}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
              break;
            }
          }
        } else {
          const today = new Date(res.year, res.month);
          const fst = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          ).getDate();
          const lst = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          ).getDate();
          for (let i = fst; i <= lst; i += 7) {
            weekNumber += 1;
            if (i < 22) {
              weeks.push({
                ws: i,
                we: i + 6,
                weekOf: `${i} - ${i + 6}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
            } else {
              weeks.push({
                ws: i,
                we: lst,
                weekOf: `${i} - ${lst}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
              break;
            }
          }
        }
      });

    return filteredDate !== null ? weeks : weeks.splice(weeks.length - 12);
  };

  // Hanldle weekly sale.

  const handleSalesWeeks = () => {
    setSales([]);
    const weeks = getNumberOfWeeks();

    let result = weeks?.map((week) => {
      let total = [];
      salesTemp.reduce((acc, { year, grossProfit }) => {
        if (
          new Date(year) >= new Date(week.year, week.month - 1, week.ws) &&
          new Date(year) <= new Date(week.year, week.month - 1, week.we)
        ) {
          total[week.weekNumber] = total[week.weekNumber] || {
            grossProfit: 0,
            weekNumber: week.weekNumber,
            weeks: `${week.year}-${week.month}-${week.ws} / ${week.year}-${week.month}-${week.we}`,
          };
          total[week.weekNumber].grossProfit += Number(grossProfit);
          total[week.weekNumber].weekNumber = week.weekNumber;
          total[
            week.weekNumber
          ].weeks = `${week.year}-${week.month}-${week.ws} / ${week.year}-${week.month}-${week.we}`;
        } else {
          if (
            new Date(week.year, week.month - 1, week.ws) <=
            new Date(week.year, week.month - 1, week.we)
          ) {
            total[week.weekNumber] = total[week.weekNumber] || {
              grossProfit: 0,
              weekNumber: week.weekNumber,
              weeks: `${week.year}-${week.month}-${week.ws} / ${week.year}-${week.month}-${week.we}`,
            };
          }
        }
      }, {});
      return total.filter((t) => t !== undefined);
    });
    let flattenArray = [].concat(...result);

    flattenArray = flattenArray?.map((week) => {
      return {
        month: week?.weeks,
        grossProfit: week?.grossProfit,
      };
    });

    setSalesDataKey("weeks");
    flattenArray && setSales(flattenArray);
  };

  // Handle daily sale

  const handleSalesDays = () => {
    setSales([]);
    function getDaysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let customIndexes = [];
    const filteredYear = getUniqueYearsFromDate();

    let curYear = new Date().getFullYear();
    let curMonth = new Date().getMonth();

    if (filteredDate != null) {
      let start = moment(filteredDate[0] && filteredDate[0]?.startDate).format(
        "YYYY-MM-DD"
      );
      let end = moment(filteredDate[0] && filteredDate[0]?.endDate).format(
        "YYYY-MM-DD"
      );

      for (let i = start?.split("-")[0]; i <= end?.split("-")[0]; i++) {
        if (start?.split("-")[0] == i && end?.split("-")[0] == i) {
          for (let j = start?.split("-")[1]; j <= end?.split("-")[1]; j++) {
            let mo = j < 10 && j.toString.length == 1 ? `0${j}` : j;
            if (start?.split("-")[1] == end?.split("-")[1]) {
              const startDate = start?.split("-")[2];
              const lastDate = end?.split("-")[2];
              for (let j = startDate; j <= lastDate; j++) {
                let day = j < 10 && j.toString.length == 1 ? `0${j}` : j;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            } else if (start?.split("-")[1] == j && end?.split("-")[1] != j) {
              const startDate = start?.split("-")[2];
              const daysInMonth = new Date(
                start?.split("-")[0],
                start?.split("-")[1],
                0
              ).getDate();

              for (let j = startDate; j <= daysInMonth; j++) {
                let day = j < 10 && j.toString.length == 1 ? `0${j}` : j;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            } else if (end?.split("-")[1] == j && start?.split("-")[1] != j) {
              const lastDate = end?.split("-")[2];
              const startDate = new Date(
                end?.split("-")[0],
                end?.split("-")[1],
                1
              ).getDate();

              for (let k = startDate; k <= lastDate; k++) {
                let day = k < 10 && k.toString.length == 1 ? `0${k}` : k;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            } else {
              const daysInMonth = new Date(i, mo, 0).getDate();

              for (let l = 1; l <= daysInMonth; l++) {
                let day = l < 10 && l.toString.length == 1 ? `0${l}` : l;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            }
          }
        } else if (start?.split("-")[0] == i && end?.split("-")[0] != i) {
          for (let j = start?.split("-")[1]; j <= 12; j++) {
            let mo = j < 10 && j.toString.length == 1 ? `0${j}` : j;

            if (start?.split("-")[1] == j) {
              const startDate = start?.split("-")[2];
              const daysInMonth = new Date(
                start?.split("-")[0],
                start?.split("-")[1],
                0
              ).getDate();

              for (let j = startDate; j <= daysInMonth; j++) {
                let day = j < 10 && j.toString.length == 1 ? `0${j}` : j;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            } else {
              const daysInMonth = new Date(i, mo, 0).getDate();

              for (let l = 1; l <= daysInMonth; l++) {
                let day = l < 10 && l.toString.length == 1 ? `0${l}` : l;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            }
          }
        } else if (end?.split("-")[0] == i && start?.split("-")[0] != i) {
          for (let j = 1; j <= end?.split("-")[1]; j++) {
            let mo = j < 10 && j.toString.length == 1 ? `0${j}` : j;

            if (end?.split("-")[1] == j) {
              const lastDate = end?.split("-")[2];
              for (let j = 1; j <= lastDate; j++) {
                let day = j < 10 && j.toString.length == 1 ? `0${j}` : j;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            } else {
              const daysInMonth = new Date(i, mo, 0).getDate();
              for (let l = 1; l <= daysInMonth; l++) {
                let day = l < 10 && l.toString.length == 1 ? `0${l}` : l;
                let date = `${i}-${mo}-${day}`;
                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            }
          }
        } else {
          for (let j = 1; j <= 12; j++) {
            let mo = j < 10 && j.toString.length == 1 ? `0${j}` : j;
            const daysInMonth = new Date(i, mo, 0).getDate();
            for (let j = 1; j <= daysInMonth; j++) {
              let day = j < 10 && j.toString.length == 1 ? `0${j}` : j;
              let date = `${i}-${mo}-${day}`;
              customIndexes[date] = {
                date: date,
                grossProfit: 0,
              };
            }
          }
        }
      }
    } else {
      filteredYear?.map((u) => {
        if (u == curYear) {
          curYear = curYear;
          curMonth = curMonth;
          let getDate = new Date().getDate();

          for (let i = 1; i <= curMonth + 1; i++) {
            let daysInMonth = getDaysInMonth(i, u);
            let mo = i < 10 ? `0${i}` : i;
            if (i == curMonth + 1) {
              for (let j = 1; j <= getDate; j++) {
                let day = j < 10 ? `0${j}` : j;
                let date = `${u}-${mo}-${day}`;

                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            } else {
              for (let j = 1; j <= daysInMonth; j++) {
                let day = j < 10 ? `0${j}` : j;
                let date = `${u}-${mo}-${day}`;

                customIndexes[date] = {
                  date: date,
                  grossProfit: 0,
                };
              }
            }
          }
        } else if (u == 2021) {
          for (let i = 6; i <= 12; i++) {
            let daysInMonth = getDaysInMonth(i, u);
            let mo = i < 10 ? `0${i}` : i;

            for (let j = 1; j <= daysInMonth; j++) {
              let day = j < 10 ? `0${j}` : j;
              let date = `${u}-${mo}-${day}`;

              customIndexes[date] = {
                date: date,
                grossProfit: 0,
              };
            }
          }
        } else {
          for (let i = 1; i <= 12; i++) {
            let daysInMonth = getDaysInMonth(i, u);
            let mo = i < 10 ? `0${i}` : i;

            for (let j = 1; j <= daysInMonth; j++) {
              let day = j < 10 ? `0${j}` : j;
              let date = `${u}-${mo}-${day}`;

              customIndexes[date] = {
                date: date,
                grossProfit: 0,
              };
            }
          }
        }
      });
    }
    let dailySalesTotal = Object.values(
      salesDaillyData.reduce((acc, { date, grossProfit }) => {
        customIndexes[date] = customIndexes[date] || {
          date: date,
          grossProfit: 0,
        };
        customIndexes[date].grossProfit = String(
          Number(customIndexes[date].grossProfit) + Number(grossProfit)
        );

        return customIndexes;
      }, {})
    );

    let filteredData = dailySalesTotal.sort(
      (a, b) => Number(new Date(a.date)) - Number(new Date(b.date))
    );

    filteredData = filteredData?.map((data) => {
      return {
        month: data?.date,
        grossProfit: data?.grossProfit,
      };
    });

    filteredData && setSales(filteredData);
    setSalesDataKey("date");
  };

  // STATE'S SETS ON filteredGrossProfit CHANGE
  useMemo(() => {
    setSalesTemp([]);
    setSalesDaillyData([]);
    setSalesMonthllyData([]);

    filteredGrossProfit.map((grossProfit) => {
      setSalesTemp((prev) => [
        ...prev,
        {
          year: grossProfit?.order?.completionDate,
          grossProfit: grossProfit?.grossProfit,
        },
      ]);

      setSalesDaillyData((prev) => [
        ...prev,
        {
          date: grossProfit?.order?.completionDate,
          grossProfit: grossProfit?.grossProfit,
        },
      ]);

      setSalesDataKey("month");
      setSalesMonthllyData((prev) => [
        ...prev,
        {
          year: grossProfit?.order?.completionDate,
          grossProfit: grossProfit?.grossProfit,
        },
      ]);
    });
  }, [filteredGrossProfit, filteredDate]);

  // Init daily total.
  useEffect(() => {
    // call daily sale total function.
    handleSalesMonths();
  }, [salesDaillyData]);

  // ======================SALES GRAPH END===============================

  // ======================LOCATION WISE SALE START===============================

  const [isViewMoreServiceSale, setIsViewMoreServiceSale] = useState(false);

  const viewMoreServiceSale = () => {
    setIsViewMoreServiceSale(!isViewMoreServiceSale);
  };

  // const viewMoreServiceWiseSale = () => {
  //   serviceWiseGrossProfit(
  //       isViewMoreServiceSale ? serviceWiseGrossProfit :  serviceWiseGrossProfit.slice(0, 5)
  //     )
  // }

  const viewMoreServiceWiseSale = () => {
    setLocationWiseSale(
      isViewMoreServiceSale
        ? saleByLocationWise
        : saleByLocationWise.slice(0, 5)
    );
  };

  useEffect(() => {
    viewMoreServiceWiseSale();
  }, [isViewMoreServiceSale]);

  // ======================LOCATION WISE SALE END===============================

  // ======================SERVICE WISE SALE START===============================

  useEffect(() => {
    setServiceWiseSale(serviceWiseGrossProfit);
  }, []);
  // ======================SERVICE WISE SALE END===============================

  // ======================GARAGE SALE START===============================

  const [isViewMoreGarageSale, setIsViewMoreGarageSale] = useState(false);

  const viewMoreGarageSale = () => {
    setIsViewMoreGarageSale(!isViewMoreGarageSale);
  };

  //============================ GOAL OVERVIEW START =====================================

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }
  let numberOfMonths = null;

  let goalOverviewValues = [
    // { name: "Actual", value: Math.round(sale) },
    // { name: "Goal", value: Math.round(100 - sale) },

    { name: "Actual", value: Math.round(grossMargin) },
    { name: "Goal", value: Math.round(100 - Math.round(grossMargin)) },
  ];
  const COLORS = ["#7B8BD4", "#122164", "#FFBB28", "#FF8042"];
  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  //============================ GOAL OVERVIEW END =======================================
  // Pass data to dashboard component
  const props = {
    filteredDate,
    handleOpenDatePicker,
    handleDayFilterChange,
    comparison: {
      compareSections: null,
      compareSectionsView: null,
    },
    salesGraph: {
      salesHeading: "Monthly Gross Profit",
      // sales: monthlyGrossProfiteData,
      sales: sales,
      salesDataKey,
    },
    graphFilters: {
      setYearToggle,
      YearToggle,
      setYearsToggle,
      YearsToggle,
    },
    graphFilterFunctions: {
      handleSalesYears,
      handleSalesMonths,
      handleSalesWeeks,
      handleSalesDays,
    },
    // latestOrdersReview: {
    //   latestReviewHeading: null,
    //   latestReview: null,
    //   latestOrdersReviewCarouselView: null,
    // },
    goalOverview: {
      goalHeading: "Gross Profit (%)",
      goalOverviewValues,
      COLORS,
    },
    saleByVehicleType: {
      saleByVehicleType,
      saleByVehicleTypeHeading: "Period Wise",
      subHeading: "Gross Profit",
    },
    saleByServiceType: {
      serviceSalesHeading: "Service Wise",
      subHeading: "Gross Profit",
      saleByServiceType: serviceWiseSale,
    },
    saleByGarage: {
      saleByGarage: locationWiseSale,
      saleByGarageHeading: "Location Wise",
      subHeading: "Gross Profit",
    },
    dayFilter,
    grossProfit: {
      grossProfitHeading: "Gross Profit",
      subHeading: "Garage",
      grossProfitData: grossProfitByMonth,
    },
    renderCustomizedLabel,
    viewMoreGarageSale,
    isViewMoreGarageSale,
    isViewMoreServiceSale,
    viewMoreServiceSale,
  };

  return (
    <>
      <DashboardComponent propsData={props} />
    </>
  );
};

export default GrossProfitDashboard;
