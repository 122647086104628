import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getGarageReceivables } from "../../services/garageServices/GarageServices";
import { GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import { useNavigate } from "react-router-dom";
import PaginationTable from "../../components/tables/Pagination";
import { Button } from "@mui/material";
import { CommonContextAPI } from "../../App";
import moment from "moment";
import Table from "../../components/tables/Table";
import "./Payout.css";

const CustomToolbar = () => {
  const { clearAppliedFilters } = useContext(CommonContextAPI);
  return (
    <div className="table-toolbar">
      <GridToolbarQuickFilter />
      <div
        className="table-toolbar-buttons"
        sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}
      >
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className="table_toolbar_clrBtn"
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

function GarageReceivable() {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);

  const [totalReceivables, setTotalReceivables] = useState(0);
  const [filteredTotalReceivables, setFilteredTotalReceivables] = useState();
  const [garageReceivables, setGarageReceivables] = useState([]);
  const [bindGarageReceivables, setBindGarageReceivables] = useState([]);

  const Navigate = useNavigate();

  // --- Cinfigretion--------
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;
  const isAdmins = ["admin", "superadmin"].includes(
    loginData?.role?.replaceAll(" ", "").toLowerCase()
  );
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // COMMMON STATE'S OR DATA USED FROM REDUX
  const propsData = React.useContext(CommonContextAPI);
  const filterDate = useSelector((state) => state?.DateRange_Reducer);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    setFilteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    handleAlertOpen,
    colors,
    defaultDate,
  } = propsData;

  // --------------calculate footer -------------------

  useEffect(() => {
    const receivablesTotal = bindGarageReceivables
      ?.map((i) => i.totalReceivables)
      .reduce((a, b) => Number(a) + Number(b), 0);
    setTotalReceivables(receivablesTotal);
  }, [bindGarageReceivables]);

  const getReceivablesData = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${loginData?.jwtToken}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    try {
      // const request = await getGaragePayout(from, to, "level=1", config);
      const request = await getGarageReceivables(config);
      const response = await request.data;
      setBindGarageReceivables([]);
      setGarageReceivables(response.result);
      if (request?.status == 204) {
        setGarageReceivables([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        handleAlertOpen("Authorization Failed!", colors.error);
      }
    }
  };

  useEffect(() => {
    (filteredDate || defaultDate) && getReceivablesData();
  }, [, filteredDate, defaultDate]);

  useEffect(() => {
    garageReceivables?.map((receivable) => {
      const orderObj = {
        orderId: receivable?.orderId,
        customerName: `${receivable?.order?.user?.first_name} ${receivable?.order?.user?.last_name}`,
        completedDate: receivable?.order?.completionDate,
        brand: receivable?.order?.vehicle?.brand?.brand,
        modelName: receivable?.order?.vehicle?.model?.model,
        variantName: receivable?.order?.vehicle?.variant?.variantName,
        vehicleType: receivable?.order?.vehicle?.vehicleType?.vehicleType,
        garage: receivable?.order?.garage?.garageName,
        basicprice: receivable?.basicprice?.toFixed(2),
        partsBasicPrice: receivable?.partsBasicPrice?.toFixed(2),
        labourBasicPrice: receivable?.labourBasicPrice?.toFixed(2),
        totalDiscount: receivable?.totalDiscount,
        discountGst: receivable?.discountGst?.toFixed(2),
        invoicePrice: receivable?.invoicePrice?.toFixed(2),
        taxableAmount: receivable?.taxableAmount?.toFixed(2),
        partsBasicReceivables: receivable?.partsBasicReceivables?.toFixed(2),
        labourBasicReceivables: receivable?.labourBasicReceivables?.toFixed(2),
        receivablesPercentageOnParts: receivable?.receivablesPercentageOnParts,
        receivablesPercentageOnLabour:
          receivable?.receivablesPercentageOnLabour,
        totalBasicReceivables: receivable?.totalBasicReceivables?.toFixed(2),
        gstReceivablesOnParts: receivable?.gstReceivablesOnParts?.toFixed(2),
        gstReceivablesOnLabour: receivable?.gstReceivablesOnLabour?.toFixed(2),
        totalGstReceivables: receivable?.totalGstReceivables?.toFixed(2),
        totalReceivables: receivable?.totalReceivables?.toFixed(2),
      };

      setBindGarageReceivables((prev) => [...prev, orderObj]);
    });
  }, [garageReceivables]);

  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
    },
    {
      field: "completedDate",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Vehicle Name",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "modelName",
      headerName: "Vehicle Model",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "variantName",
      headerName: "Vehicle Variant",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "garage",
      headerName: "Garage",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "taxableAmount",
      headerName: "Taxable Amount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "partsBasicPrice",
      headerName: "Parts Basic Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "labourBasicPrice",
      headerName: "Labour Basic Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "totalDiscount",
      headerName: "Total Discount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "discountGst",
      headerName: "Discount GST",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "invoicePrice",
      headerName: "Invoice Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "partsBasicReceivables",
      headerName: "Parts Basic Receivables",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "labourBasicReceivables",
      headerName: "Labour Basic Receivables",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "receivablesPercentageOnParts",
      headerName: "Receivables Percentage On Parts",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "receivablesPercentageOnLabour",
      headerName: "Receivables Percentage On Labour",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "totalBasicReceivables",
      headerName: "Total Basic Receivables",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "gstReceivablesOnParts",
      headerName: "GST Receivables On Parts",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "gstReceivablesOnLabour",
      headerName: "GST Receivables On Labour",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "totalGstReceivables",
      headerName: "Total GST Receivables",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "totalReceivables",
      headerName: "Total Receivables",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
  ];

  garageReceivables?.map((data) => {
    if (data?.payout?.garageBankAccount != null) {
    }
  });

  // Date filter model
  useEffect(() => {
    filteredDate && filterDateRenge && filterDateRenge("completedDate");
  }, [, filteredDate && filteredDate]);

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Total Receivables :{" "}
              <span className="grid-table-footer-right-value">
                {filteredTotalReceivables
                  ? Math.round(filteredTotalReceivables)
                  : Math.round(totalReceivables)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  return (
    <Table
      heading="Garage Receivables"
      sx={DataGridStyle}
      rows={bindGarageReceivables}
      columns={columns}
      pageSize={50}
      rowsPerPageOptions={[100]}
      getCellClassName={(param) => "Rows"}
      initialState={{
        sorting: {
          sortModel: [{ field: "completedDate", sort: "desc" }],
        },
      }}
      onStateChange={(state) => {
        const visibleRows = state.filter.visibleRowsLookup;
        let visibleItems = [];
        for (const [orderId, value] of Object.entries(visibleRows)) {
          if (value === true) {
            visibleItems.push(orderId);
          }
        }
        const res = bindGarageReceivables.filter((item) =>
          visibleItems.includes(item.orderId)
        );
        const receivablesTotal = res
          .map((i) => i.totalReceivables)
          .reduce((a, b) => Number(a) + Number(b), 0);
        setFilteredTotalReceivables(receivablesTotal);
      }}
      components={{
        Footer: FooterSection,
        Toolbar: CustomToolbar,
      }}
      handleOpenDatePicker={handleOpenDatePicker}
      getRowHeight={() => 60}
      filterModel={filterModel && filterModel}
      filterDateRenge={filterDateRenge && filterDateRenge}
      filteredDate={filteredDate}
      getRowId={(row) => row?.orderId}
      loading={loading}
    />
  );
}

export default GarageReceivable;
