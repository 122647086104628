import React, { useState, useEffect, useMemo, useContext, memo } from "react";
import Table from "../../components/tables/Table";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Grid, Box, TextField, Typography, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  getFranchiseGrossProfit,
  updateFranchiseGrossProfit,
} from "../../services/grossProfitService/GrossProfitServices";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const FranchiseGrossProfit = () => {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [bp, setBp] = useState(0);
  const [tp, setTp] = useState(0);
  const [ksMp, setksMp] = useState(0);
  const [gp, setGp] = useState(0);
  const [gprofit, setGProfit] = useState(0);
  const [gm, setGm] = useState(0);
  const [loading, setLoading] = useState(true);

  const [basicPrice, setBasicPrice] = useState(0);
  const [price, setTotalPrice] = useState(0);
  const [
    gativanMaterialCostPriceWithoutTax,
    setGativanMaterialCostPriceWithoutTax,
  ] = useState(0);
  const [garagePayout, setGaragePayout] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [ordersObject, setOrdersObject] = useState([]);

  const [franchiseGrossProfit, setFranchiseGrossProfit] = useState([]);
  const [franchiseGrossProfitCount, setFranchiseGrossProfitCount] = useState(0);
  const [
    filteredFranchiseGrossProfitCount,
    setFilteredFranchiseGrossProfitCount,
  ] = useState(0);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    defaultDate,
    handleAlertOpen,
    colors,
  } = commonData;

  useEffect(() => {
    setBp(0);
    setTp(0);
    setksMp(0);
    setGp(0);
    setGProfit(0);
    setGm(0);

    // let sum = 0;

    ordersObject.map((item, index) => {
      // sum += item.grossProfit;
      setBp((prev) => prev + item.basicPrice);
      setTp((prev) => prev + item.price);
      setksMp((prev) => prev + item.gativanMaterialCostPriceWithoutTax);
      setGp((prev) => prev + item.garagePayout);
      setGProfit((prev) => prev + item.grossProfit);
      setGm((prev) => prev + (item.grossMargin ? item.grossMargin : 0));
    });
  }, [ordersObject]);

  const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));

  const getFranchiseGrossProfitReport = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${loginDetails?.jwtToken}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    };

    try {
      const response = await getFranchiseGrossProfit(config);
      const data = await response.data;
      setFranchiseGrossProfit(data.result);
      setFranchiseGrossProfitCount(data.result?.length);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useMemo(() => {
    setOrdersObject([]);
    franchiseGrossProfit?.map((grossProfit) => {
      const temp = {
        id: grossProfit?.id,
        franchiseId: grossProfit?.franchiseId,
        garageName: grossProfit?.garage?.garageName,
        gpSource: grossProfit?.gpSource?.gpSource,
        mobileNumber: grossProfit?.garage?.mobileNumber,
        gstn: grossProfit?.garage?.gstn,
        pan: grossProfit?.garage?.pan,
        entityType: grossProfit?.garage?.entityType,
        garageType: grossProfit?.garage?.garageType == 1 ? "Bike" : "Car",
        price: grossProfit?.price,
        basicPrice: grossProfit?.basicPrice,
        brandingPrice: grossProfit?.brandingPrice,
        grossProfit: grossProfit?.grossProfit,
        grossMargin: grossProfit?.grossMargin,
        discountBearedPercentage: grossProfit?.garage?.discountBearedPercentage,
        partsPayoutPercentage: grossProfit?.garage?.partsPayoutPercentage,
        labourPayoutPercentage: grossProfit?.garage?.labourPayoutPercentage,
        createdOn: moment(grossProfit?.createdOn)?.format("DD-MM-YYYY"),
        dateOfPayment: moment(grossProfit?.dateOfPayment)?.format("DD-MM-YYYY"),
        createdDateTime:
          grossProfit?.createdOn &&
          new Date(`${grossProfit?.dateOfPayment} ${grossProfit?.createdTime}`),
      };
      setOrdersObject((prev) => [...prev, temp]);
    });
  }, [franchiseGrossProfit]);

  useEffect(() => {
    getFranchiseGrossProfitReport();
  }, [, filteredDate, defaultDate]);

  // const

  const updateFranchiseGrossProfitDetails = async (
    grossProfitDetails,
    message
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${loginDetails?.jwtToken}`,
      },
    };
    try {
      const response = await updateFranchiseGrossProfit(
        grossProfitDetails,
        config
      );
      const data = await response.data;
      handleAlertOpen(message, colors.success);
      getFranchiseGrossProfitReport();
    } catch (error) {
      console.log(error);
      handleAlertOpen(error?.response?.data?.message, colors.error);
    }
  };

  const updateBrandingCost = (params) => {
    const gp = franchiseGrossProfit?.find((report) => report.id == params.id);

    const value = params.value;

    if (params?.field == "brandingPrice") {
      let putObject = {
        id: gp?.id,
        brandingPrice: Number(value),
      };
      const msg = "Branding Price Updated Successfully!";
      updateFranchiseGrossProfitDetails(putObject, msg);
    }
  };

  const columns = [
    {
      field: "franchiseId",
      headerName: "Franchise ID",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "garageType",
      headerName: "Garage Type",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "gpSource",
      headerName: "GP Source",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "dateOfPayment",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "createdDateTime",
      headerName: "Created Date Time",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },

    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "gstn",
      headerName: "GSTN",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "pan",
      headerName: "PAN",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "entityType",
      headerName: "Entity Type",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "basicPrice",
      headerName: "Basic Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "brandingPrice",
      headerName: "Branding Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      editable: true,
      flex: 1,
    },
    {
      field: "grossProfit",
      headerName: "Gross Profit",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "grossMargin",
      headerName: "Gross Margin",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "discountBearedPercentage",
      headerName: "Discount Beared Percentage",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "partsPayoutPercentage",
      headerName: "Parts Payout Percentage",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "labourPayoutPercentage",
      headerName: "Labour Payout Percentage",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
  ];

  // const [filter, setFilter] = useState([]);
  // const getFilteredData = (data) => {
  //   setFilter(data);
  // };

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("completedDate");
  }, [filteredDate && filteredDate]);

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Basic Price :{" "}
              <span className="grid-table-footer-right-value">
                {basicPrice ? basicPrice?.toFixed(2) : bp?.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Price :{" "}
              <span className="grid-table-footer-right-value">
                {price ? price.toFixed(2) : tp?.toFixed(2)}
              </span>
            </h5>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Gross Profit :{" "}
              <span className="grid-table-footer-right-value">
                {grossProfit ? grossProfit.toFixed(2) : gprofit.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Gross Margin :{" "}
              <span className="grid-table-footer-right-value">
                {grossMargin
                  ? (
                      grossMargin /
                      (filteredFranchiseGrossProfitCount
                        ? filteredFranchiseGrossProfitCount
                        : franchiseGrossProfitCount)
                    )?.toFixed(2)
                  : gm &&
                    (
                      gm /
                      (filteredFranchiseGrossProfitCount > 0
                        ? filteredFranchiseGrossProfitCount
                        : franchiseGrossProfitCount)
                    )?.toFixed(2)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  return (
    <div>
      <Table
        customStyle={DataGridStyle}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              createdDateTime: false,
            },
          },
        }}
        heading="Franchise Gross Profit"
        rows={ordersObject && ordersObject}
        columns={columns}
        pageSize={50}
        onCellEditCommit={(params) => {
          if (params.field == "brandingPrice") {
            updateBrandingCost(params);
          }
        }}
        rowsPerPageOptions={[100]}
        loading={loading}
        onStateChange={(state) => {
          let visibleItems = [];
          const visibleRows = state.filter.visibleRowsLookup;
          for (const [orderId, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(orderId);
            }
          }
          const res = ordersObject.filter((item) =>
            visibleItems.includes(item.orderId)
          );

          // l stands for local
          const lbp = res
            .map((item) => item.basicPrice)
            .reduce((a, b) => a + b, 0);

          const ltp = res
            .map((item) => item.price && Number(item.price))
            .reduce((a, b) => a + b, 0);

          const lksMp = res
            .map((item) => item.gativanMaterialCostPriceWithoutTax)
            .reduce((a, b) => a + b, 0);

          const lgp = res
            .map((item) => item.garagePayout)
            .reduce((a, b) => a + b, 0);

          const lgprofit = res
            .map((item) => item.grossProfit)
            .reduce((a, b) => a + b, 0);

          const lgm = res
            .map((item) => item.grossMargin)
            .reduce((a, b) => a + b, 0);
          setFilteredFranchiseGrossProfitCount(res.length);
          setBasicPrice(lbp > 0 && lbp);
          setTotalPrice(ltp > 0 && ltp);
          setGativanMaterialCostPriceWithoutTax(lksMp > 0 && lksMp);
          setGaragePayout(lgp > 0 && lgp);
          setGrossProfit(lgprofit > 0 && lgprofit);
          setGrossMargin(lgm > 0 && lgm);
        }}
        filterModel={filterModel && filterModel}
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.id}
      />
    </div>
  );
};

export default React.memo(FranchiseGrossProfit);
