import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import httpCommon from "../../http-common";

import fontURL1 from "../../assets/fonts/AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../assets/fonts/AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../assets/fonts/AvenirNextLTPro-Demi.otf";
import { getOrderById } from "../../services/Services";
import { useParams } from "react-router-dom";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

const TermsAndConditionCars = [
  {
    title: "Service Warranty",
    body: [
      "One month/1000kms unconditional warranty across Periodic Services like Car Service, AC Service, etc.",
    ],
  },
  {
    title: "Paint Warranty",
    body: [
      "Gativan offers a Paint Warranty of up to 2 years. This Warranty is also applicable in case of defective Detailing Service (Coating, PPF etc.).",
    ],
  },
  {
    title: "Fitments Warranty ",
    body: [
      "If the fitments such as Brakes, Horns, Lights and Windshields are found to be defective, we will replace them free of cost.",
    ],
  },
  {
    title: "Manufacturing Warranty ",
    body: [
      "We pass on the Manufacturer's Warranty on all Spares Parts to our Customers.",
    ],
  },
  {
    title: "Terms and Conditions",
    body: [
      "Any issues faced post service/spare part Installation done at any workshop outside Gativan will not be covered.",
      "Scratches, Dents or Paint damage caused post service will not be covered.",
      "Short-circuit or damage due to aftermarket electrical fittings will not be covered.",
      "Vehicles will be driven by Gativan Garage employees at the owner's risk and responsibility for testing and or inspection purposes.",
      " is not responsible for loss or damage to vehicle (s)/aggregate(s) or article (s) left in vehicle arising from fire, theft or any accident or any other cause beyond.",
    ],
  },
];
const TermsAndConditionBikes = [
  {
    title:
      "During first start of bike/cold start every morning start it with kick as your bike was stand still.",
  },
  {
    title:
      "Ride daily to keep the engine working and battery from draining out.",
  },
  {
    title: "Service warranty will be only valid for 7 days after delivery.",
  },
  {
    title:
      "Please change your engine oil after 2-3 months or within 2000-3000 kms whichever comes soon.",
  },
  {
    title: "Only the jobs mentioned in the job card will be done.",
  },
  {
    title:
      "Do take all the material from car before giving for servicing. No complaints regarding missing parts will be entertained.",
  },
];
// Create styles

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#122164",
    padding: "2% 2%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  jobcardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "6px",
  },
  jobcardSubHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    margin: "10px 0px",
    padding: "0px 1.5%",
  },
  heading: {
    color: "white",
    marginRight: "0%",
    fontSize: "26px",
    fontFamily: "AvenirBold",
  },
  subHeading: {
    color: "white",
    marginRight: "0%",
    fontSize: "20px",
    fontFamily: "AvenirRegular",
  },
  logo: {
    // width: "162px",
    // height: "36px",
    width: "140px",
    marginRight: "0%",
  },
  subLogo: {
    height: "100%",
    width: "30px",
    marginRight: "0%",
  },
  subLogoImage: {
    width: "25px",
    height: "25px",
  },
  userInfoSection: {
    height: "160px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    padding: "2%",
    position: "relative",
  },
  label: {
    fontSize: "13px",
    fontFamily: "AvenirRegular",
    // marginTop: "5px",
    lineHeight: "10px",
    color: "#121212",
    fontWeight: 200,
  },
  labelManufacture: {
    fontSize: "13px",
    fontFamily: "AvenirRegular",
    // marginTop: "5px",
    color: "#121212",
    fontWeight: 200,
    lineHeight: "10px",
  },
  fields: {
    color: "#6A7086",
    fontSize: "13px",
    fontFamily: "AvenirRegular",
    // marginTop: "5px",
    fontWeight: 200,
    lineHeight: "10px",
  },

  servicesContainer: {
    backgroundColor: "white",
    padding: "2%",
    // flexGrow: 1,
    position: "relative",
  },
  packageName: {
    fontSize: "14px",
    fontFamily: "AvenirBold",
    color: "#122164",
    marginLeft: "-5px",
  },
  packageNameUnderline: {
    width: "100px",
    height: "1px",
    backgroundColor: "black",
  },
  serviceActivity: {
    padding: "8px 0px 5px",
    margin: "0px 8px",
  },
  serviceActivityList: {
    // height: "25px",
    color: "#6a7086",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "3px",
    marginBottom: "3px",
  },
  activityName: {
    width: "85%",
    fontSize: "12px",
    fontFamily: "AvenirRegular",
  },

  srNo: {
    color: "#5b5757",
    fontSize: "13px",
    fontFamily: "AvenirDemi",
    marginRight: "5px",
  },

  check_Image: {
    height: "15px",
    width: "15px",
  },
  subtotalSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
    margin: "0px 8px",
  },

  totalContainer: {
    // marginTop: "10px !important",
    width: "100%",
    padding: "5px 0",
    position: "absolute",
    bottom: 0,
    display: "flex !important",
    justifyContent: "center !important",
    flexDirection: "column",
    // marginLeft: "4% !important",
  },

  totalSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
  },

  subtotalNonCataSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "5px 0px",
  },
  subtotalHeading: {
    fontSize: "13px",
    fontFamily: "AvenirBold",
  },
  totalHeadingLabel: {
    fontSize: "20px",
    fontFamily: "AvenirBold",
  },

  priceSection: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "center",
  },
  rupee_Image: {
    width: 15,
    height: 15,
    fontFamily: "AvenirBold",
  },
  subtotalPrice: {
    fontSize: 16,
    fontFamily: "AvenirRegular",
    color: "#6A7086",
  },

  calculationTopBorder: {
    width: "104%",
    // width: "100%",
    height: "1px",
    backgroundColor: "gray",
    // marginLeft: "-2%",
    marginTop: "2px",
    marginBottom: "5px",
  },
  discount: {
    fontSize: "16px",
    fontFamily: "AvenirRegular",
    color: "#6A7086",
  },
  discountHeading: {
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    color: "gray",
  },
  discountTopBorder: {
    width: "104%",
    height: "1px",
    backgroundColor: "gray",
    margin: "5px 0px",
  },
  totalPrice: {
    fontSize: 16,
    fontFamily: "AvenirRegular",
    color: "#6A7086",
  },
  termsAndCondSection: {
    // backgroundColor:'white',
    margin: "10px 0px",
  },
  termsAndConHeading: {
    color: "white",
    fontSize: "14px",
    fontFamily: "AvenirBold",
  },
  termsTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
    fontFamily: "AvenirRegular",
  },
  termsBody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginLeft: 10,
    marginBottom: 5,
    marginRight: 5,
    fontSize: "11px",
    fontFamily: "AvenirRegular",
    // marginBottom: "3px",
  },

  bullet: {
    width: 5,
    height: 5,
    backgroundColor: "#000",
    borderRadius: 50,
  },
  subBullet: {
    marginTop: 5,
    width: 4,
    height: 4,
    backgroundColor: "#000",
    borderRadius: 50,
  },
});

export default function MyDocument({
  isLeadCard,
  leadData,
  leadServices,
  heading,
}) {
  const [OrderData, setOrderData] = React.useState();
  const [allServices, setAllServices] = React.useState([]);
  const [allNonCata, setAllCata] = React.useState([]);
  const [TermsAndCondition, setTermsAndCondition] = useState([]);

  const baseURL = httpCommon.defaults.baseURL;

  const OrderId = localStorage.getItem("orderId");

  // let OrderId='O-CABN-2212-00054'

  const firstName = OrderData?.user?.first_name;
  const lastName = OrderData?.user?.last_name;
  const mobile = isLeadCard
    ? leadData?.mobileNumber
    : OrderData?.user?.mobile_number;

  const vehicleType = OrderData?.vehicle?.vehicleType?.vehicleType;
  const brand = OrderData?.vehicle?.brand?.brand;
  const model = OrderData?.vehicle?.model?.model;
  const modelImage = isLeadCard
    ? leadData?.model?.imageUrl
    : OrderData?.vehicle?.model?.imageUrl;
  const variant = OrderData?.vehicle?.variant?.variantName;
  const vehicleNumber = isLeadCard
    ? leadData?.vehicleNumber
    : OrderData?.vehicle?.vehicleNumber;

  const yearOfManufacture = OrderData?.vehicle?.yearOfManufacture;

  const housNumber = OrderData?.address?.houseNumber;
  const area = OrderData?.address?.area;
  const city = OrderData?.address?.city;
  const address = isLeadCard
    ? leadData?.city?.city
    : housNumber + ", " + area + ", " + city;

  const customerName = isLeadCard
    ? leadData?.leadName
    : firstName + " " + lastName;
  const vehicle = isLeadCard
    ? `${leadData?.brand?.brand} ${leadData?.model?.model} ${leadData?.variant?.variantName}`
    : brand + " " + model + " " + variant;
  const TotalPrice = OrderData?.totalPrice;
  const discount = OrderData?.discount;

  const ServivesTotal = TotalPrice + discount;

  const NonCatalogues = OrderData?.nonCatalogueJobs;
  const Services = OrderData?.services;

  useMemo(() => {
    let temp = [];
    Services &&
      Services.map((i) => {
        const Obj = {
          service: i.service,
          price: i.displayPrice,
          activity: i.activities,
        };
        temp.push(Obj);
      });
    setAllServices([...temp]);
  }, [Services]);

  useMemo(() => {
    if (leadServices?.allServices?.length > 0) {
      // setAllServices([]);
      let temp = [];
      leadServices?.allServices?.map((i) => {
        const Obj = {
          service: i.service,
          price: i.displayPrice,
          activity: i.activities,
        };
        temp.push(Obj);
      });
      setAllServices([...temp]);
    }
  }, [leadServices]);

  useMemo(() => {
    let temp = [];
    NonCatalogues &&
      NonCatalogues.map((i) => {
        const countPrice = parseInt(i.quantity) * parseInt(i.price);
        const Obj = {
          service: i.name,
          price: i.price,
          quantity: i.quantity,
        };
        temp.push(Obj);
      });
    setAllCata([...temp]);
  }, [NonCatalogues]);

  const getOrderDetails = async () => {
    const request = await getOrderById(OrderId);
    const response = await request.data;
    setOrderData(response.result);
  };
  useEffect(() => {
    if (!isLeadCard) {
      getOrderDetails();
    }
  }, [, isLeadCard]);
  useEffect(() => {
    const vehicle = vehicleType?.toLowerCase();
    if (vehicle === "car") {
      setTermsAndCondition(TermsAndConditionCars);
    } else {
      setTermsAndCondition(TermsAndConditionBikes);
    }
  }, [vehicleType]);

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          <View style={styles.jobcardHeader}>
            <Text style={styles.heading}> {heading || "Job Card"} </Text>
            <View style={styles.logo}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/logos/GativanFullLogoWhite.png"
                }
              />
            </View>
          </View>

          <View style={styles.userInfoSection}>
            <View style={{ width: "50%" }}>
              <Text style={styles.label}>
                {customerName || "Customer Name: ---"}
              </Text>
              <Text style={styles.fields}>{vehicle || "Vehicle: ---"}</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={styles.fields}>
                  {vehicleNumber ? vehicleNumber : "Vehicle Number"}
                </Text>
                {!isLeadCard && (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "60%",
                      height: "100%",
                    }}
                  >
                    <Text style={styles.labelManufacture}>
                      Year Of Manufacture
                    </Text>
                    <Text style={styles.fields}>
                      {yearOfManufacture ? yearOfManufacture : "- - - - - "}
                    </Text>
                  </View>
                )}
              </View>

              <Text style={styles.label}>Customer Contact</Text>
              <Text style={styles.fields}>{mobile || "---"}</Text>
              <Text style={styles.label}>Customer Address</Text>
              <Text style={styles.fields}>{address || "---"} </Text>
            </View>
            <View
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
              className="vehicle-image"
            >
              <Image
                style={{ height: "100%" }}
                src={baseURL + modelImage}
                // src="https://5.imimg.com/data5/JF/VZ/HA/SELLER-10578870/yamaha-yzf-r15-v-3-0-155-cc-racing-blue-motorcycle-500x500.jpg"
              />
            </View>
          </View>

          <View style={styles.jobcardSubHeader}>
            <Text style={styles.subHeading}> Jobs </Text>
            <View style={styles.subLogo}>
              <Image
                style={styles.subLogoImage}
                src={process.env.PUBLIC_URL + "/Assets/logos/logo.png"}
              />
            </View>
          </View>

          <View
            style={{
              ...styles.servicesContainer,
              flexGrow: 1,
            }}
          >
            <View
              style={{
                marginBottom: !isLeadCard
                  ? discount > 0
                    ? "100px"
                    : "15px"
                  : "auto",
              }}
            >
              {allServices &&
                allServices.map((item, index) => {
                  const { service, price, activity } = item;

                  return (
                    <View
                      // style={{ marginTop: "7px", marginBottom: "7px" }}
                      style={{ marginBottom: "15px" }}
                      key={index}
                    >
                      <Text style={styles.packageName}> {service}</Text>
                      <Text style={styles.packageNameUnderline}></Text>
                      <View style={styles.serviceActivity}>
                        {activity &&
                          activity.map((list, index) => {
                            let SirNo = index + 1;
                            return (
                              <View
                                style={styles.serviceActivityList}
                                key={index}
                                wrap={false}
                              >
                                {/* <View> */}
                                <Text style={styles.activityName}>
                                  <Text style={styles.srNo}>{SirNo} </Text>
                                  {list.activity}
                                  {/* {`${SirNo} ${list.activity}`} */}
                                </Text>
                                {/* </View> */}
                                {/* <View> */}
                                <Text>
                                  <Image
                                    style={styles.check_Image}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/Assets/check.png"
                                    }
                                  />
                                </Text>
                                {/* </View> */}
                              </View>
                            );
                          })}
                      </View>

                      <View style={styles.subtotalSection}>
                        <Text style={styles.subtotalHeading}>Subtotal: </Text>
                        <View style={styles.priceSection}>
                          <Image
                            style={styles.rupee_Image}
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <Text style={styles.subtotalPrice}>{price}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}

              {allNonCata &&
                allNonCata.map((item, index) => {
                  const { service, price, quantity } = item;

                  return (
                    <View
                      style={{ marginTop: "7px", marginBottom: "7px" }}
                      key={index}
                      wrap={false}
                    >
                      <Text style={styles.packageName}>
                        {" "}
                        {service} ({quantity})
                      </Text>
                      <Text style={styles.packageNameUnderline}></Text>
                      <View style={styles.subtotalNonCataSection}>
                        <Text style={styles.subtotalHeading}>Subtotal: </Text>
                        <View style={styles.priceSection}>
                          <Image
                            style={styles.rupee_Image}
                            src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                          />
                          <Text style={styles.subtotalPrice}>{price}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
            </View>

            <View
              // style={{ ...styles.totalContainer, marginTop: "150px" }}
              style={styles.totalContainer}
              wrap={false}
            >
              <View style={styles.calculationTopBorder}></View>
              <View
                style={{ ...styles.subtotalSection, padding: "2% 0% 2% 2%" }}
              >
                <Text style={styles.totalHeadingLabel}>
                  {discount > 0 ? "" : "Total:"}
                </Text>
                <View style={{ ...styles.priceSection, marginRight: "-2%" }}>
                  <Image
                    style={styles.rupee_Image}
                    src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                  />
                  <Text
                    // style={
                    //   discount > 0
                    //     ? styles.subtotalPrice
                    //     : styles.subtotalHeading
                    // }
                    style={styles.totalPrice}
                  >
                    {isLeadCard ? leadServices?.cardTotal : ServivesTotal}
                  </Text>
                </View>
              </View>

              {discount > 0 && (
                <View>
                  <View
                    style={{ ...styles.subtotalSection, paddingLeft: "1%" }}
                  >
                    <Text style={styles.discountHeading}> Discount </Text>
                    <View
                      style={{ ...styles.priceSection, marginRight: "-2%" }}
                    >
                      {/* <Image
                  style={styles.rupee_Image}
                  src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                /> */}
                      <Text style={styles.discount}> - {discount}</Text>
                    </View>
                  </View>

                  <View style={styles.discountTopBorder}></View>

                  <View
                    style={{
                      ...styles.subtotalSection,
                      padding: "3% 0% 3% 1%",
                    }}
                  >
                    <Text style={styles.totalHeadingLabel}> Total: </Text>
                    <View
                      style={{ ...styles.priceSection, marginRight: "-2%" }}
                    >
                      <Image
                        style={styles.rupee_Image}
                        src={process.env.PUBLIC_URL + "/Assets/rupee.png"}
                      />
                      <Text style={styles.totalPrice}>{TotalPrice}</Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>

          {!isLeadCard && (
            <View
              style={styles.termsAndCondSection}
              className="termsAndConditionContainer"
              wrap={false}
            >
              <Text style={styles.termsAndConHeading}>Terms & Conditions</Text>
              <View style={{ padding: "2%", backgroundColor: "white" }}>
                {TermsAndCondition.map((item, ind) => (
                  <View key={ind}>
                    <View style={styles.termsTitle}>
                      <Text style={styles.bullet}></Text>
                      <Text style={{ marginLeft: 5, marginTop: 2 }}>
                        {item.title}
                      </Text>
                    </View>
                    {item.body?.map((list, ind) => (
                      <View style={styles.termsBody} key={ind}>
                        <Text style={styles.subBullet}></Text>
                        <Text style={{ marginLeft: 5, marginTop: 2 }}>
                          {list}
                        </Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
}
