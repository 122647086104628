import React, { useState, useEffect, useMemo, useContext } from "react";

import { useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import {
  getBankAccountByGarageId,
  getGaragePayout,
  getGaragePayoutStatus,
  updateGaragePayout,
} from "../../services/garageServices/GarageServices";
import { GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import { useNavigate } from "react-router-dom";
import PaginationTable from "../../components/tables/Pagination";
import { Button } from "@mui/material";
import { CommonContextAPI } from "../../App";
import moment from "moment";
import Table from "../../components/tables/Table";
import "./Payout.css";

const CustomToolbar = () => {
  const { clearAppliedFilters } = useContext(CommonContextAPI);
  return (
    <div className="table-toolbar">
      <GridToolbarQuickFilter />
      <div
        className="table-toolbar-buttons"
        sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}
      >
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className="table_toolbar_clrBtn"
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

function GaragePayout() {
  const theme = useTheme();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [basicPrice, setBasicPrice] = useState(0);
  const [filterBasicPrice, setFilterBasicPrice] = useState();
  const [KSMaterialPrice, setKSMaterialPrice] = useState(0);
  const [filterKSMP, setFilterKSMP] = useState();
  const [garagePayout, setGaragePayout] = useState(0);
  const [filterGaragePayout, setFilterGaragePayout] = useState();

  const [payoutData, setpayoutData] = useState([]);
  const [bindPayouts, setBindPayouts] = useState([]);

  const [payoutStatus, setPayoutStatus] = useState([]);

  const [payoutFilter, setPayoutFilter] = useState();

  const Navigate = useNavigate();

  // --- Cinfigretion--------
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;
  const isAdmins = ["admin", "superadmin"].includes(
    loginData?.role?.replaceAll(" ", "").toLowerCase()
  );
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // COMMMON STATE'S OR DATA USED FROM REDUX
  const propsData = React.useContext(CommonContextAPI);
  const filterDate = useSelector((state) => state?.DateRange_Reducer);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    setFilteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    handleAlertOpen,
    colors,
    defaultDate,
  } = propsData;

  const [filter, setFilter] = useState([]);

  useEffect(() => {}, [bindPayouts]);

  // --------------calculate footer -------------------

  useEffect(() => {
    const payout = bindPayouts?.map((i) => i.netPayout);
    const payoutPrice = payout?.reduce((a, b) => a + b, 0);
    setGaragePayout(payoutPrice);
    const basickPrice = bindPayouts
      ?.map((i) => i.basicPrice)
      .reduce((a, b) => a + b, 0);
    setBasicPrice(basickPrice);

    const ksPrice = bindPayouts
      ?.map((i) => i.ksMaterialCostBasic)
      .reduce((a, b) => a + b, 0);

    setKSMaterialPrice(ksPrice);
  }, [bindPayouts]);

  useEffect(() => {
    payoutData.map((items) => {
      const {
        orderDetail,
        payout,
        basicPrice,
        nonCatalogueServicesPrice,
        orderType,
      } = items;

      const orderObj = {
        // orderId: orderDetail?.order?.orderId,
        orderId: payout?.orderId,
        customerName: `${orderDetail?.order?.userFirstName} ${orderDetail?.order?.userLastName}`,
        bookingMode: orderDetail?.order?.mode,
        brand: orderDetail?.order?.brand,
        modelName: orderDetail?.order?.model,
        variantName: orderDetail?.order?.variant,
        completedDate: orderDetail?.order?.completionDate,
        vehicleType: orderDetail?.order?.vehicleType,
        garage: orderDetail?.order?.garageName,
        vehicleNumber: orderDetail?.order?.vehicleNumber,
        basicPayout: Math.round(payout?.basicPayout),
        gstPayout: Math.round(payout?.payoutTotalGst),
        razorpayDeduction: Math.round(payout?.razorpayDeduction),
        totalPrice: Math.round(orderDetail?.order?.totalPrice),
        totalPayout: Math.round(payout?.totalPayout),
        tds: Math.round(payout?.tds),
        basicPrice: Math.round(payout?.basicPrice),
        ksMaterialCostBasic: Math.round(payout?.ksMaterialCostBasic),
        netPayout: Math.round(payout?.netPayout),
        approvalStatus: payout?.garagePayoutStatus?.status,
        ncJobPrice: nonCatalogueServicesPrice,
        payoutPercentage: payout?.garage?.payoutPercentage,
        orderType: payout?.orderType?.type,
      };

      setBindPayouts((prev) => [...prev, orderObj]);
    });
  }, [payoutData]);

  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
    {
      field: "completedDate",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "orderType",
      headerName: "Order Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Vehicle Name",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "modelName",
      headerName: "Vehicle Model",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "variantName",
      headerName: "Vehicle Variant",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },

    {
      field: "ncJobPrice",
      headerName: "NC Jobs Price",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },

    {
      field: "garage",
      headerName: "Garage",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "basicPrice",
      headerName: "Basic Price",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "ksMaterialCostBasic",
      headerName: "KS Material Price",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
      editable: isAdmins ? true : false,
    },
    {
      field: "payoutPercentage",
      headerName: "Payout Percentage",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "basicPayout",
      headerName: "Basic Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "gstPayout",
      headerName: "GST Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "razorpayDeduction",
      headerName: "Razorpay Deduction",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
  
    {
      field: "totalPayout",
      headerName: "Total Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tds",
      headerName: "TDS Deduction",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },

    {
      field: "netPayout",
      headerName: "Net Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "approvalStatus",
      headerName: "Approval Status",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: [...payoutStatus.map((i) => i.status)],
      renderCell: (params) => {
        const value = params.formattedValue;
        const status = params.row.approvalStatus;
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 15%",
              width: "100%",
              height: "80%",
              color: "white",
              backgroundColor:
                status == "Hold"
                  ? "#707070"
                  : status == "Pending"
                  ? "#D0342C"
                  : status == "Approved"
                  ? "#FFAF02"
                  : status == "Complete"
                  ? "#51AE3A"
                  : "",
            }}
          >
            <span>{params.row.approvalStatus}</span>
            <ExpandMoreIcon />
          </div>
        );
      },
      flex: 1,
    },
  ];

  payoutData?.map((data) => {
    if (data?.payout?.garageBankAccount != null) {
      // console.log("data", data?.payout?.garageBankAccount);
    }
  });

  const getBankAccount = async (garageId) => {
    try {
      const request = await getBankAccountByGarageId(garageId, config);
      const response = await request.data;
      const result = await response.result;
      const data = result && result[0];
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const UpdatePayout = async (Obj, statusUpdated) => {
    try {
      const request = await updateGaragePayout(Obj, config);
      const response = await request.data;
      if (response.statusCode === 200) {
        handleAlertOpen(response.message, colors.success);

        if (statusUpdated == "Approved") {
          // setTimeout(() => {
          //   Navigate("/approvedpayout");
          // }, 1000);
        } else {
          getGaragePayoutData();
        }
      }
    } catch (err) {
      getGaragePayoutStatusList();

      setTimeout(() => {
        getGaragePayoutData();
      }, 1000);

      handleAlertOpen(err.message, colors.error);
      console.log(err);
    }
  };

  const updatePayoutStatus = async (params) => {
    const field = params.field;
    const orderId = params.id;
    const status = params.value;
    const Status = payoutStatus.find((i) => i.status === status);
    const payoutId = payoutData.find((i) => i.payout.orderId === orderId);

    const defaultPaymentMethod = await getBankAccount(
      payoutId?.payout?.garageId
    );

    // ksMaterial Price Update condition

    if (field === "ksMaterialCostBasic") {
      let updateObj = {
        id: payoutId.payout.id,
        payoutStatus: payoutId.payout.payoutStatus,
        otherMethod: false,
        ksMaterialCostBasic: params.value,
        // ksMaterialCost: params.value,
      };
      UpdatePayout(updateObj);
    } else if (field === "approvalStatus") {
      let updateObj = {
        id: payoutId.payout.id,
        payoutStatus: Status.id,
        otherMethod: false,
      };
      if (params.value === "Approved") {
        let updateObj = {
          id: payoutId.payout.id,
          payoutStatus: Status.id,
          bankAccount: defaultPaymentMethod?.id,
        };

        if (updateObj?.bankAccount == undefined) {
          handleAlertOpen(
            "Bank Account is not added for this Garage.",
            colors.error
          );
          setTimeout(() => {
            getGaragePayoutData();
          }, 500);
          return;
        }
        const statusUpdated = "Approved";
        UpdatePayout(updateObj, statusUpdated);
      } else if (params.value === "Hold") {
        let updateObj = {
          id: payoutId.payout.id,
          payoutStatus: Status.id,
          otherMethod: false,
        };
        UpdatePayout(updateObj);
      } else if (params.value === "Pending") {
        let updateObj = {
          id: payoutId.payout.id,
          payoutStatus: Status.id,
          otherMethod: false,
        };
        UpdatePayout(updateObj);
      } else {
        return false;
      }
    }
    // ----- remove
    const sendObj = {
      id: payoutId.payout.id,
      payoutStatus: Status.id,
      garageId: payoutId.payout.garageId,
    };
    setPaymentMethod(sendObj);
  };

  // Date filter model
  useEffect(() => {
    filteredDate && filterDateRenge && filterDateRenge("completedDate");
  }, [, filteredDate && filteredDate]);

  const getGaragePayoutData = async () => {
    let from;
    let to;

    if (filteredDate) {
      from = moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      to = moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      from =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      to = defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }

    try {
      const request = await getGaragePayout(from, to, "level=0", config);
      const response = await request.data;
      setBindPayouts([]);
      setpayoutData(response.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        handleAlertOpen("Authorization Failed!", colors.error);
      }
    }
  };

  useEffect(() => {
    (filteredDate || defaultDate) && getGaragePayoutData();
  }, [, filteredDate, defaultDate]);

  const getGaragePayoutStatusList = async () => {
    const req = await getGaragePayoutStatus(config);
    const res = await req.data;
    const result = res.result.filter(
      (i) =>
        i.status === "Pending" ||
        i.status === "Approved" ||
        i.status === "Hold" ||
        i.status === "Complete"
    );
    // const result = res.result?.filter((status) => status.status != "Complete");
    setPayoutStatus(result);
  };

  useEffect(() => {
    getGaragePayoutStatusList();
  }, []);

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Basic Price :{" "}
              <span className="grid-table-footer-right-value">
                {filterBasicPrice
                  ? Math.round(filterBasicPrice)
                  : Math.round(basicPrice)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              KS Material Price :{" "}
              <span className="grid-table-footer-right-value">
                {filterKSMP ? filterKSMP : KSMaterialPrice}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Garage Payout :{" "}
              <span className="grid-table-footer-right-value">
                {filterGaragePayout
                  ? Math.round(filterGaragePayout)
                  : Math.round(garagePayout)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  return (
    <Table
      heading="Garage Payout"
      sx={DataGridStyle}
      rows={bindPayouts}
      columns={columns}
      pageSize={50}
      rowsPerPageOptions={[100]}
      getCellClassName={(param) => "Rows"}
      onCellEditCommit={(params) => {
        updatePayoutStatus(params);
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "completedDate", sort: "desc" }],
        },
      }}
      onStateChange={(state) => {
        const visibleRows = state.filter.visibleRowsLookup;
        let visibleItems = [];
        for (const [orderId, value] of Object.entries(visibleRows)) {
          if (value === true) {
            visibleItems.push(orderId);
          }
        }
        const res = bindPayouts.filter((item) =>
          visibleItems.includes(item.orderId)
        );

        const Payout = res.map((i) => i.netPayout).reduce((a, b) => a + b, 0);
        setFilterGaragePayout(Payout);
        const basic = res.map((i) => i.basicPrice).reduce((a, b) => a + b, 0);
        setFilterBasicPrice(basic);
        const KsPrice = res
          .map((i) => i.ksMaterialCostBasic)
          .reduce((a, b) => a + b, 0);
        setFilterKSMP(KsPrice);
      }}
      components={{
        Footer: FooterSection,
        Toolbar: CustomToolbar,
      }}
      onCellClick={(data) => {
        // Navigate(`/orders/orderdetails/${data.row.orderId}/job-card`)
      }}
      handleOpenDatePicker={handleOpenDatePicker}
      getRowHeight={() => 60}
      filterModel={filterModel && filterModel}
      filterDateRenge={filterDateRenge && filterDateRenge}
      filteredDate={filteredDate}
      getRowId={(row) => row?.orderId}
      loading={loading}
    />
  );
}

export default GaragePayout;
