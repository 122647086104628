import testHttp from "../../http-common";

//get vehicle type
export const getVehicleType = () => {
  return testHttp.get("/vehicleType");
};
//get brands
export const getBrands = (vehicleType) => {
  return testHttp.get(`/brand/${vehicleType}`);
};
//get model
export const getModels = (vehicleType, brandId) => {
  return testHttp.get(`/models/${vehicleType}/${brandId}`);
};

//get variant
export const getVariants = (modelId) => {
  return testHttp.get(`/variant/${modelId}`);
};

//get variant
export const getLeads = (config) => {
  return testHttp.get("/leads", config);
};

//get lead by id
export const getLeadById = (leadId) => {
  return testHttp.get(`/leads/leadbyid/${leadId}`);
};

//get lead status
export const getLeadStatus = () => {
  return testHttp.get("/leadStatus/");
};

//get lead status
export const getLeadChannels = () => {
  return testHttp.get("/leadChannels/");
};

//get lead Owners
export const getLeadOwners = () => {
  return testHttp.get("/user/leadOwners");
};

//Update Lead
export const updateLead = (updateLead) => {
  return testHttp.put("/leads", updateLead);
};

//Update Lead
export const updateLeadStatus = (leadId, leadStatusId, params) => {
  return testHttp.put(
    // `/leads/convertLead/${leadId}/${leadStatusId}?arrivalDate=${params.arrivalDate}&arrivalTime=${params.arrivalTime}`
    `/leads/convertLead/${leadId}/${leadStatusId}`,
    params
  );
};

//Add Lead
export const saveLead = (customerDetails) => {
  return testHttp.post("/leads/save", customerDetails);
};

// //get Service Categories
// export const getServiceCategories = (vehicleTypeId) => {
//   return testHttp.get(`/serviceCategory/${vehicleTypeId}`);
// };

// //get Service Sub Categories
// export const getServiceSubCategories = (serviceCategoryId) => {
//   return testHttp.get(`/serviceSubCategory/${serviceCategoryId}`);
// };

// //get Service Details
// export const getServiceDetails = (serviceCategoryId) => {
//   return testHttp.get(`/serviceSubCategory/${serviceCategoryId}`);
// };

// //get Service Prices
// export const getServicePrices = (serviceCategoryId, modelId, variantid) => {
//   return testHttp.get(`/serviceSubCategory/${serviceCategoryId}/${modelId}/${variantid}`);
// };

// update lead user detials
export const updateUserName = (userDetails) => {
  return testHttp.put("/user", userDetails);
};

export const getAllArrivalModes = () => {
  return testHttp.get("/arrivalModes");
};

export const getAllFollowUpReasons = () => {
  return testHttp.get("/followUpReasons");
};

export const searchLeads = (query, cancelRequest) => {
  return testHttp.get(`/leads/search?query=${query}`, cancelRequest);
};

export const delteService = (serviceId, leadId) => {
  return testHttp.delete(`/leadService/${serviceId}/${leadId}`);
};
