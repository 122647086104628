import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

const borderColor = "#6a7086";

const styles = StyleSheet.create({
  container: {
    borderTopColor: borderColor,
    borderTopWidth: 1,
    height: "20px",
    flexDirection: "row",
  },
  total: {
    width: "28%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    height: "100%",
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    justifyContent: "center",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  price: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  discount: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  taxableAmount: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },

  gstSection: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    flexDirection: "row",
  },
  gst_rate: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  gst_amount: {
    width: "100%",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  totalPrice: {
    width: "13%",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
});

export default function TableFooter({ footerData }) {
  return (
    <View style={styles.container}>
      <View style={styles.total}>
        <Text>Total</Text>
      </View>
      <View style={styles.qty}>
        <Text>{footerData?.totalQuantity}</Text>
      </View>
      <View style={styles.price}>
        <Text>{footerData?.totalPrice}</Text>
      </View>
      <View style={styles.discount}>
        <Text>{footerData?.totalDiscount}</Text>
      </View>
      <View style={styles.taxableAmount}>
        <Text>{footerData?.totalTaxableAmount}</Text>
      </View>
      <View style={styles.gstSection}>
        {/* <View style={styles.gst_rate}> */}
          {/* <Text>{footerData?.totalCGSTRate}</Text> */}
        {/* </View> */}
        <View style={styles.gst_amount}>
          <Text>{footerData?.totalCGST}</Text>
        </View>
      </View>

      <View style={styles.gstSection}>
        {/* <View style={styles.gst_rate}> */}
          {/* <Text>{footerData?.totalSGSTRate}</Text> */}
        {/* </View> */}
        <View style={styles.gst_amount}>
          <Text>{footerData?.totalSGST}</Text>
        </View>
      </View>
      <View style={styles.totalPrice}>
        <Text>{footerData?.FinalTotal}</Text>
      </View>
    </View>
  );
}
