import React, { useContext, useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GativanFullLogoWhite from "../../assets/logo/GativanFullLogoWhite.png";
import DescriptionIcon from "@mui/icons-material/Description";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import ShareIcon from "@mui/icons-material/Share";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { hasChildren } from "./Utils";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentIcon from "@mui/icons-material/Payment";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SellIcon from "@mui/icons-material/Sell";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import PeopleIcon from "@mui/icons-material/People";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from "@mui/icons-material/Person";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import SecurityIcon from "@mui/icons-material/Security";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewProfile } from "../../action";
import { getUserProfile } from "../../services/userProfileServices/UserProfileServices";
import { useMemo } from "react";
import "./SideNavbar.css";
import { CommonContextAPI } from "../../App";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      overflow: "auto",
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      height: "auto",
    },
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  navbarBrand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerContainer: {
    backgroundColor: "#122164",
    color: "#fff",
    overflow: "hidden",
    height: "100vh",
  },
  navItems: {
    height: "93.4vh",
    overflow: "auto",
  },
  sideNavbarLink: {
    textDecoration: "none",
    color: "#fff",
    display: "flex",
    // flexWrap: "wrap",
    alignItems: "center",
    margin: "0.3rem 0px",
  },
  nestedSideNavbarLink: {
    textDecoration: "none",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    margin: "0px 0px 0px 0rem",
  },
  icons: {
    color: "#fff",
    fontSize: "2rem !important",
  },
  gativanLogo: {
    backgroundColor: "#122164",
    cursor: "pointer",
  },
  customMuiListItemIcon: {
    "& .MuiListItemIcon-root": {
      display: "none",
    },
  },
  MuiButtonBase: {
    "& .MuiButtonBase-root": {
      padding: 0,
    },
  },
  activeLink: {
    "& .MuiTypography-root": {
      color: "#fff !important",
    },
  },
  inactiveLink: {
    "& .MuiTypography-root": {
      color: "#707070 !important",
    },
  },
}));

const SideNavbar = ({ handleDrawerToggle, mobileOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUserProfile, setUserProfile] = useState("");
  const isWindowUndefined = window;
  const [currentUserDetails, setCurrentUserDetails] = useState({});

  const localData = JSON.parse(localStorage.getItem("loginDetails"));
  const commonData = useContext(CommonContextAPI);
  const { currentOrders } = commonData;

  // const loggedUserDetails = async () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localData.jwtToken}`,
  //     },
  //   };
  //   try {
  //     const response = await getUserProfile(config);
  //     const data = await response.data;
  //     setCurrentUserDetails(data.result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    setCurrentUserDetails(localData);
    // loggedUserDetails();
  }, [, localData]);

  const pathname = window.location.pathname;

  useEffect(() => {
    setUserProfile(pathname);
  }, [pathname]);

  const [profileNav, setProfileNav] = useState([]);

  const menu = [
    {
      icon: <DashboardIcon className={classes.icons} />,
      title: "Dashboard",
      to: "/",
    },
    {
      icon: <YoutubeSearchedForIcon className={classes.icons} />,
      title: "Leads",
      to: "/leads",
    },
    {
      icon: <DescriptionIcon className={classes.icons} />,
      title: "Orders",
      // to: "/orders/general",
      to: `/orders/${currentOrders?.toLowerCase()}`,
    },
    {
      icon: <SellIcon className={classes.icons} />,
      title: "Sales",
      items: [
        {
          title: "Sales Report 1",
          to: "/salesreport1",
        },
        {
          title: "Servicing Sales Report",
          to: "/servicingsalesreport",
        },
        {
          title: "Franchise Sales Report",
          to: "/franchisesalesreport",
        },
        {
          title: "Sales Report 2",
          to: "/salesreport2",
        },
      ],
    },
    {
      icon: <LibraryBooksIcon className={classes.icons} />,
      title: "Catalogue",
      to: "/catalogue",
    },
    {
      icon: <ContactPhoneIcon className={classes.icons} />,
      title: "Customer Service",
      to: "/customerservice",
    },
    {
      icon: <MonetizationOnIcon className={classes.icons} />,
      title: "Gross Profits",
      items: [
        {
          title: "Gross Profit",
          to: "/grossprofit",
        },
        {
          title: "Servicing Gross Profit",
          to: "/servicinggrossprofit",
        },
        {
          title: "Franchise Gross Profit",
          to: "/franchisegrossprofits",
        },
        {
          title: "Garage Receivables GP",
          to: "/garagereceivablesgrossprofits",
        },
        {
          title: "GP Dashboard",
          to: "/gpdashboard",
        },
      ],
    },
    {
      icon: <PaymentIcon className={classes.icons} />,
      title: "Garages",
      items: [
        {
          title: "Garage Database",
          to: "/garagedatabase",
        },
        {
          title: "Garage Franchise",
          to: "/garagefranchise",
        },
        {
          title: "Garage Receivables",
          to: "/garagereceivables",
        },
        {
          title: "Garage Payout",
          to: "/garagepayout",
        },
        {
          title: "Approved Payout",
          to: "/approvedpayout",
        },
        {
          title: "Complete Payout",
          to: "/completepayout",
        },
      ],
    },
    {
      icon: <PaymentIcon className={classes.icons} />,
      title: "Insurance",
      items: [
        {
          title: "Companies",
          to: "/insurance_companies",
        },
        {
          title: "Insurance Types",
          to: "/insurancetypes",
        },
        {
          title: "Service Type",
          to: "/servicetype",
        },
        {
          title: "Policy Type",
          to: "/policytype",
        },
        {
          title: "Brokers",
          to: "/brokers",
        },
      ],
    },
    {
      icon: <ShoppingCartCheckoutIcon className={classes.icons} />,
      title: "Inventory",
      items: [
        {
          title: "Dashboard",
          to: "/inventorydashboard",
        },
        {
          title: "Material  Library",
          to: "/materiallibrary",
        },
        {
          title: "Material  Brands",
          to: "/materialbrands",
        },
        {
          title: "Product In",
          to: "/productin",
        },
        {
          title: "Product Out",
          to: "/productout",
        },
        {
          title: "Vendors",
          to: "/vendors",
        },
        {
          title: "Balance Summery",
          to: "/balancesummary",
        },
        // {
        //   title: "Ks Material",
        //   to: "/ksmaterials",
        // },
      ],
    },
    {
      icon: <ShareIcon className={classes.icons} />,
      title: "Referal",
      to: "/referal",
    },
    // {
    //   icon: <SearchIcon className={classes.icons} />,
    //   title: "ABP",
    //   to: "/abp",
    // },
  ];

  const myAccountMenus = [
    {
      icon: <PersonIcon className={classes.icons} />,
      title: "Profile",
      to: "/myAccount/profile",
    },
    {
      icon: <SecurityIcon className={classes.icons} />,
      title: "Security",
      to: "/myAccount/security",
    },
    {
      icon: <HowToRegIcon className={classes.icons} />,
      title: "Multifactor Auth",
      to: "/myAccount/multifactorAuth",
    },
    {
      icon: <SettingsIcon className={classes.icons} />,
      title: "Settings",
      to: "/myAccount/settings",
    },
    {
      icon: <ContactPhoneIcon className={classes.icons} />,
      title: "Sessions",
      to: "/myAccount/sessions",
    },
    {
      icon: <GroupsIcon className={classes.icons} />,
      title: "Groups",
      to: "/myAccount/groups",
    },
    {
      icon: <PrivacyTipIcon className={classes.icons} />,
      title: "Privacy",
      to: "/myAccount/privacy",
    },
    {
      icon: <ContactPhoneIcon className={classes.icons} />,
      title: "Compliance",
      to: "/myAccount/compliance",
    },
  ];

  const adminConsoleMenus = [
    {
      icon: <DashboardIcon className={classes.icons} />,
      title: "Dashboard",
      to: "/adminConsole/dashboard",
    },
    {
      icon: <PeopleIcon className={classes.icons} />,
      title: "Users",
      to: "/adminConsole/users",
    },
  ];
  useEffect(() => {
    setProfileNav(myAccountMenus);
  }, []);

  const closeUserProfile = () => {
    navigate("/");
  };

  const [isAdminConsole, setIsAdminConsole] = useState(false);

  useEffect(() => {
    const adminConsole = sessionStorage.getItem("isAdminConsole");
    setIsAdminConsole(adminConsole);
  }, [window.location.href]);

  useEffect(() => {
    if (JSON.parse(isAdminConsole) == true) {
      setProfileNav(adminConsoleMenus);
    } else {
      setProfileNav(myAccountMenus);
    }
  }, [isAdminConsole]);
  const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));

  const [currentUserRole, setCurrentUserRole] = useState("");
  useEffect(() => {
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const role = getLoginDetailsLS?.role;
    setCurrentUserRole(role.replace(" ", "").toLowerCase());
  }, [, window.location.href]);

  const drawer = (
    <div className={classes.drawerContainer}>
      <div
        className={classes.toolbar}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.navbarBrand}>
          {!isMobile ? (
            <img
              src={GativanFullLogoWhite}
              alt={GativanFullLogoWhite}
              className={classes.gativanLogo}
              style={{ width: drawerWidth - 50, marginRight: "5px" }}
              onClick={() => {
                navigate("/");
                isMobile && handleDrawerToggle();
                sessionStorage.setItem("isAdminConsole", false);
                // userData?.viewProfile && closeUserProfile();
                closeUserProfile();
              }}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={GativanFullLogoWhite}
                alt={GativanFullLogoWhite}
                className={classes.gativanLogo}
                // style={{ width: drawerWidth - 100 }}
                style={{ width: drawerWidth - 100 }}
                onClick={() => {
                  navigate("/");
                  isMobile && handleDrawerToggle();
                  sessionStorage.setItem("isAdminConsole", false);
                  // userData?.viewProfile && closeUserProfile();
                  closeUserProfile();
                }}
              />
              <ArrowForwardIcon
                onClick={handleDrawerToggle}
                sx={{ fontSize: "2rem !important", color: "#fff" }}
              />
            </Box>
          )}
        </div>
      </div>
      <Divider />
      <div className={classes.navItems}>
        {/* {userData?.viewProfile */}
        {isUserProfile.includes("myAccount")
          ? profileNav.map((item, key) => (
              <MenuItem
                key={key}
                item={item}
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
                pathname={pathname}
              />
            ))
          : isUserProfile.includes("adminConsole")
          ? profileNav.map((item, key) =>
              ["superadmin", "admin", "serviceadvisor", "engineer"].includes(
                currentUserRole
              ) ? (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                />
              ) : (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                  disableLinks={{
                    opacity: item.title == "Orders" ? 1 : 0.4,
                    pointerEvents: item.title == "Orders" ? "" : "none",
                  }}
                />
              )
            )
          : menu.map((item, key) =>
              ["superadmin", "admin"].includes(currentUserRole) ? (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                />
              ) : ["serviceadvisor", "engineer"].includes(currentUserRole) ? (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                  disableLinks={{
                    display: !["garagepayout"].includes(item.title)
                      ? ""
                      : "none",
                  }}
                />
              ) : ["gativanexpert"].includes(currentUserRole) ? (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                  disableLinks={{
                    // opacity: ["Orders", "Catalogue"].includes(item.title)
                    //   ? 1
                    //   : 0.4,
                    // pointerEvents: ["Orders", "Catalogue"].includes(item.title)
                    //   ? ""
                    //   : "none",
                    display: ["Orders", "Catalogue"].includes(item.title)
                      ? ""
                      : "none",
                  }}
                />
              ) : ["leadowner"].includes(currentUserRole) ? (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                  disableLinks={{
                    // opacity: [
                    //   "Leads",
                    //   "Orders",
                    //   "Catalogue",
                    //   "Customer Service",
                    // ].includes(item.title)
                    //   ? 1
                    //   : 0.4,
                    // pointerEvents: [
                    //   "Leads",
                    //   "Orders",
                    //   "Catalogue",
                    //   "Customer Service",
                    // ].includes(item.title)
                    //   ? ""
                    //   : "none",
                    display: [
                      "Leads",
                      "Orders",
                      "Catalogue",
                      "Customer Service",
                    ].includes(item.title)
                      ? ""
                      : "none",
                  }}
                />
              ) : (
                <MenuItem
                  key={key}
                  item={item}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                  pathname={pathname}
                  disableLinks={{
                    opacity: 0.4,
                    pointerEvents: "none",
                  }}
                />
              )
            )}
      </div>
    </div>
  );

  const container =
    isWindowUndefined !== undefined
      ? () => isWindowUndefined.document.body
      : undefined;
  return (
    <div>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "left" : "right"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

const MenuItem = ({
  item,
  handleDrawerToggle,
  mobileOpen,
  disableLinks,
  pathname,
}) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={item}
      handleDrawerToggle={handleDrawerToggle}
      mobileOpen={mobileOpen}
      disableLinks={disableLinks}
      pathname={pathname}
    />
  );
};

const SingleLevel = ({
  item,
  handleDrawerToggle,
  mobileOpen,
  disableLinks,
  pathname,
}) => {
  const classes = useStyles();

  const activeLink =
    pathname == item?.to || pathname == `${item?.to}/` ? true : false;

  return (
    <ListItem
      button
      style={{
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
      }}
    >
      <Link
        to={item.to}
        className={classes.sideNavbarLink}
        onClick={mobileOpen && handleDrawerToggle}
        style={{
          width: "100%",
          padding: "8px",
          backgroundColor: activeLink ? "#000" : "",
          ...disableLinks,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} className="nav-links" />
      </Link>
    </ListItem>
  );
};

const MultiLevel = ({
  item,
  handleDrawerToggle,
  mobileOpen,
  disableLinks,
  pathname,
}) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const [nestedTitle, setNestedTitle] = useState("");
  const classes = useStyles();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useMemo(() => {
    if (
      children?.find(
        (item) => pathname == item?.to || pathname == `${item?.to}/`
      )
    ) {
      setNestedTitle(item?.title);
    } else {
      setNestedTitle(null);
    }
  }, [pathname]);

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={handleClick}
        style={{
          backgroundColor: nestedTitle == item.title ? "#000" : "",
          paddingLeft: "5px",
          margin: "0px 1rem",
          width: "94%",
          ...disableLinks,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} className="nav-links" />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <List
            component="div"
            disablePadding
            style={{
              padding: "0px",
              margin: "0px",
              width: "67%",
            }}
          >
            {children.map((child, key) => {
              const activeLink =
                pathname == child?.to || pathname == `${child?.to}/`
                  ? true
                  : false;
              return (
                <Link
                  key={key}
                  to={child.to}
                  className={`${classes.nestedSideNavbarLink} ${
                    classes.customMuiListItemIcon
                  } ${classes.MuiButtonBase} ${
                    activeLink ? classes.activeLink : classes.inactiveLink
                  }`}
                  onClick={mobileOpen && handleDrawerToggle}
                  style={{
                    height: "45px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "0px",
                    ...disableLinks,
                  }}
                >
                  <MenuItem key={key} item={child} />
                </Link>
              );
            })}
          </List>
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

export default SideNavbar;
