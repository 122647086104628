import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import "../../modules/leads/AddLead.css";
import { CommonContextAPI } from "../../App";
import LoaderSpinner from "../../components/loading/LoaderSpinner";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { ArrivalModeContext } from "./LeadDetails";
import { getGarages } from "../../services/Services";
import "./GaragesModal.css";

import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const garages = [
  {
    id: 1,
    garageName: "Garage 1",
  },
  {
    id: 2,
    garageName: "Garage 2",
  },
  {
    id: 3,
    garageName: "Garage 3",
  },
  {
    id: 4,
    garageName: "Garage 4",
  },
];

const GaragesModal = ({ propsData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [garageList, setGarageList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [q, setQ] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const {
    openArrivalModal,
    closeArrivalModal,
    openLocationModal,
    closeLocationModal,
    openReasonsModal,
    closeReasonsModal,
    openGaragesModal,
    closeGaragesModal,
  } = propsData;

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);
  const arrivalModeContextData = useContext(ArrivalModeContext);
  const {
    setArrivalModeDetails,
    arrivalModeDetails,
    leadStatusDetails,
    setLeadStatusDetails,
    openModalOf,
  } = arrivalModeContextData;

  const userSelection = (field, value) => {
    if (openModalOf == "status") {
      setLeadStatusDetails({
        ...leadStatusDetails,
        [field]: value?.garageName,
      });
    } else {
      setArrivalModeDetails({
        ...arrivalModeDetails,
        [field]: value?.garageName,
      });
    }
    closeGaragesModal();
  };

  const getAllGarages = async () => {
    try {
      const response = await getGarages();
      const data = await response.data;
      setGarageList(data?.result);
      setFilteredList(data?.result);
      setIsLoading(false);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors?.error);
    }
  };

  // Search in below columns
  const [searchParams] = useState(["garageName"]);
  useEffect(() => {
    let data = garageList?.filter((garage) => {
      return searchParams.some((newItem) => {
        return (
          garage[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });

    if (q == "") {
      setFilteredList(garageList);
    } else {
      setFilteredList(data);
    }
  }, [q]);

  useEffect(() => {
    getAllGarages();
  }, []);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: isMobile ? "80%" : 615,
    height: "auto",
    maxHeight: "90%",
    border: "none !important",
    // overflow: "auto !important",
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeGaragesModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" className="leadsTitle">
            Select Garage
          </Typography>

          <Box>
            <Box>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                id="location"
                margin="dense"
                name="location"
                placeholder="Search..."
                value={q}
                onChange={(e) => setQ(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ cursor: "pointer" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => setQ("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            <Box
              style={{
                position: "absolute",
                top: 1,
                right: "10px",
                margin: "0.2rem 0px",
              }}
            >
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={closeGaragesModal}
              />
            </Box>
            {isLoading ? (
                <LoaderSpinner />
            ) : (
              <Box
                sx={{
                  marginTop: "4px",
                  height: garageList?.length <= 4 ? "100%" : "600px",
                  overflow: "auto !important",
                }}
              >
                {filteredList?.map((garage) => {
                  return (
                    <Box sx={{ marginTop: "1rem" }}>
                      <Card
                        sx={{
                          minWidth: 275,
                          "& .MuiCardContent-root": {
                            padding: "5px 10px",
                            marginBottom: "0px",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <CardHeader
                          onClick={() => userSelection("garage", garage)}
                          sx={{
                            cursor: "pointer",
                            "& .MuiTypography-root": {
                              fontSize: "1rem !important",
                            },
                          }}
                          action={
                            <IconButton aria-label="settings">
                              <Circle
                                sx={{
                                  fontSize: "1rem !important",
                                  color: "green",
                                }}
                              />
                            </IconButton>
                          }
                          title={garage?.garageName}
                        />
                        {/* <Divider /> */}
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 16 }}
                            color="#000"
                            bottomottom
                          >
                            <Grid container spacing={2}>
                              <Grid item sx={6}>
                                <Button variant="outlined">
                                  Assigned Count - 1
                                </Button>
                              </Grid>
                              <Grid item sx={6}>
                                <Button variant="outlined">
                                  Assigned Count - 1
                                </Button>
                              </Grid>
                            </Grid>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default GaragesModal;
