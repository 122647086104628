import http from "../http-common";

export const getSalesReport = (config) => {
  // return http.get("/orders/salesReport1?from=2023-04-01&to=2023-04-30", config);
  return http.get("/salesReport/all", config);
};


export const getServicingSalesReport = (config) => {
  return http.get("/salesReport/servicing", config);
};


export const getFranchiseSalesReport = (config) => {
  return http.get("/salesReport/franchise", config);
};
