export const CompleteOrderStatusWATI = (
  customerName,
  vehicleType,
  mobile_number
) => { 
  let option = {
    method: "POST",
    headers: {
      "Content-Type": "text/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
    },
    body: JSON.stringify({
      receivers: [
        {
          customParams: [
            { name: "name", value: customerName ? customerName : null },
            { name: "vehicle_type", value: vehicleType },
          ],
          whatsappNumber: "+91" + mobile_number,
        },
      ],
      broadcast_name: "Gativan",
      template_name: "order_status_complete_oct22",
    }),
  };
  const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
  fetch(url, option).then((response) => response.json())
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
};

export const DeliveredOrderStatusWATI = (
  customerName,
  vehicleType,
  mobile_number
) => {
  let option = {
    method: "POST",
    headers: {
      "Content-Type": "text/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
    },
    body: JSON.stringify({
      receivers: [
        {
          customParams: [
            { name: "name", value: customerName },
            { name: "vehicle_type", value: vehicleType },
          ],
          whatsappNumber: "+91" + mobile_number,
        },
      ],
      broadcast_name: "Gativan",
      template_name: "order_status_delivered_oct22",
    }),
  };
  const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
  fetch(url, option).then((response) => response.json())
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
};

export const InspectionOrderStatusWATI = (
  customerName,
  vehicleType,
  mobile_number,
  vehicle
) => {
  // console.log(customerName, vehicleType, mobile_number, vehicle);
  let option = {
    method: "POST",
    headers: {
      "Content-Type": "text/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
    },
    body: JSON.stringify({
      receivers: [
        {
          customParams: [
            { name: "name", value: customerName },
            { name: "vehicle_type", value: vehicleType },
            { name: "vehicle", value: vehicle },
            { name: "phone", value: mobile_number },
          ],
          whatsappNumber: "+91" + mobile_number,
        },
      ],
      broadcast_name: "Gativan",
      template_name: "order_status_inspection_0ct22",
    }),
  };
  const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
  fetch(url, option).then((response) => response.json())
  .then((response) => console.log(response))
  .catch((err) => console.error(err));
};

export const ApprovedOrderStatusWATI = (
  customerName,
  vehicleType,
  mobile_number,
  vehicle,
  services
) => {
  // console.log(customerName, vehicleType, mobile_number, vehicle, services);
  let option = {
    method: "POST",
    headers: {
      "Content-Type": "text/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
    },
    body: JSON.stringify({
      receivers: [
        {
          customParams: [
            { name: "name", value: customerName },
            { name: "vehicle_type", value: vehicleType },
            { name: "vehicle", value: vehicle },
            { name: "service", value: services },
          ],
          whatsappNumber: "+91" + mobile_number,
        },
      ],
      broadcast_name: "Gativan",
      template_name: "order_status_approved_oct22",
    }),
  };
  const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
  fetch(url, option).then((response) => response.json())
  .then((response) => console.log(response))
  .catch((err) => console.error(err));
};

export const CustomerFeedback = (customerName,vehicleType,mobile_number)=>{
 
  let option = {
    method: "POST",
    headers: {
      "Content-Type": "text/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
    },
    body: JSON.stringify({
      receivers: [
        {
          customParams: [
            { name: "name", value:customerName }, 
            { name: "vehicle_type", value: vehicleType }, 
          ],
          whatsappNumber: "+91" + mobile_number,
        },
      ],
      broadcast_name: "Gativan",
      template_name: "feedback_template_1",
    }),
  };

  const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
  fetch(url, option).then((response) => response.json())
  .then((response) => console.log(response))
  .catch((err) => console.error(err));
}


