import React, { useEffect, useMemo, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

import convertor from "number-to-words";
import fontURL1 from "../../../assets/fonts/AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../../assets/fonts/AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../../assets/fonts/AvenirNextLTPro-Demi.otf";
import TableFooter from "./TableFooter";
import Tableheader from "./Tableheader";
import TableRow from "./TableRow";
import { getCustomerInvoice } from "../../../services/orderServices/OrderServices";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

const borderColor = "#6a7086";
const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundColor: "#122164",
    padding: "4% 2% 3%",
  },
  jobcardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 5px",
  },
  invoiceId: {
    // color: "white",
    marginRight: "0%",
    fontSize: "14px",
    fontFamily: "AvenirBold",
    position: "relative",
    top: "5px",
  },
  invoiceIdBox: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  heading: {
    // color: "white",
    marginRight: "0%",
    fontSize: 20,
    fontFamily: "AvenirBold",
    position: "relative",
    top: "5px",
    marginLeft: "-5px",
  },
  invoiceDetailsHeading: {
    fontSize: 10,
    fontFamily: "AvenirDemi",
    // color: "white",
    fontWeight: "400",
  },
  logo: {
    width: "120px",
    marginRight: "0%",
  },
  customerName: {
    fontSize: "10px",
    fontFamily: "AvenirDemi",
  },
  fields: {
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    color: "#6a7086",
    // fontWeight: "400",
    marginBottom: "2px",
  },
  label: {
    fontWeight: "bold",
    fontSize: "10px",
    marginTop: "5px",
    fontFamily: "AvenirDemi",
    marginBottom: "2px",
  },

  // --------------------------------

  container: {
    borderColor: borderColor,
    borderTopWidth: "0.5px",
    borderBottomWidth: "0.5px",
    borderLeftWidth: "1px",
    borderRightWidth: "1px",

    height: "20px",
    flexDirection: "row",
  },
  total: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    textAlign: "center",
    height: "100%",
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    justifyContent: "center",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  price: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  discount: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  taxableAmount: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },

  gstSection: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  totalPrice: {
    width: "9%",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
});

export default function GarageInvoice({ OrderId }) {
  const [invoiceDetails, setInvoiceDetails] = useState();

  const selectedItem = JSON.parse(localStorage.getItem("selectedItem"));

  const data = {
    invoiceId: "I-CAPN-2303-00002",
    orderId: "O-CAPN-2303-00001",
    customerName: "Test null",
    vehicleBrand: "39",
    vehicleModel: "554",
    vehicleNumber: "MH 12 CF 8898",
    mobileNumber: "1234567899",
    customerAddress: "null , null",
    orderDate: "2023-03-01",
    customerGst: null,
    taxableAmount: 7900.8906,
    cgst: 928.05457,
    sgst: 928.05457,
    totalGst: 1856.1091,
    discount: 100,
    totalAmount: 9657,
    items: [
      {
        id: 25,
        invoiceId: "I-CAPN-2303-00002",
        service: "brake",
        qty: 2,
        price: 1271,
        discount: null,
        taxableAmount: 2543.8596,
        gstRate: 14,
        gstAmount: 178.07019,
        total: 2900,
        hsn: null,
      },
      {
        id: 26,
        invoiceId: "I-CAPN-2303-00002",
        service: "Battery Replacement - Exide",
        qty: 1,
        price: 6857,
        discount: null,
        taxableAmount: 5357.0312,
        gstRate: 28,
        gstAmount: 749.9844,
        total: 7857,
        hsn: "85071000",
      },
    ],
    invoiceDate: "2023-03-06",
  };

  // const {
  //   invoiceId,
  //   invoiceDate,
  //   discount,
  //   customerName,
  //   customerGst,
  //   customerAddress,
  //   cgst,
  //   items,
  //   mobileNumber,
  //   orderDate,
  //   orderId,
  //   sgst,
  //   taxableAmount,
  //   totalAmount,
  //   totalGst,
  //   vehicleBrand,
  //   vehicleModel,
  //   vehicleNumber,
  // } = invoiceDetails;

  // console.log(invoiceDetails"O-CAPN-2304-00026")

  const customerInvoiceReport = async () => {
    try {
      const response = await getCustomerInvoice(OrderId);
      const data = await response.data;
      setInvoiceDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    customerInvoiceReport();
  }, []);
  const quantity = invoiceDetails?.items
    ?.map((i) => i.qty)
    .reduce((a, b) => a + b, 0);
  const price = invoiceDetails?.items
    ?.map((i) => i.price)
    .reduce((a, b) => a + b, 0);
  const discount = invoiceDetails?.items
    ?.map((i) => i.discount)
    .reduce((a, b) => a + b, 0);
  const taxableAmount = invoiceDetails?.items
    ?.map((i) => i.taxableAmount)
    .reduce((a, b) => a + b, 0);
  const total = invoiceDetails?.items
    ?.map((i) => i.total)
    .reduce((a, b) => a + b, 0);
  return (
    // <PDFViewer style={{ width: "100%", height: "100vh" }}>
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View>
          <View style={styles.jobcardHeader}>
            <View style={styles.invoiceIdBox}>
              <Text style={styles.heading}> Tax Invoice </Text>
              <Text style={styles.invoiceId}>
                Invoice No. : {invoiceDetails?.invoiceId}
              </Text>
            </View>
            <View style={styles.logo}>
              <Image
                src={
                  process.env.PUBLIC_URL +
                  // "/Assets/logos/GativanFullLogoWhite.png"
                  "/Assets/logos/GativanFullLogoBlue.png"
                }
              />
            </View>
          </View>
          <View
            style={{
              margin: "0px 5px",
            }}
          >
            <Text style={styles.invoiceDetailsHeading}>
              Original for Recipient
            </Text>
          </View>
          <View
            style={{
              margin: "10px 5px 0px",
              backgroundColor: "white",
              height: "220px",
              width: "98%",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              border: `1px solid ${borderColor}`,
            }}
          >
            <View
              style={{
                //   backgroundColor: "red",
                height: "100%",
                display: "flex",
                width: "50%",
              }}
            >
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ width: "60%", flexWrap: "wrap" }}>
                  <Text style={styles.label}>
                    KStudio Solutions Private Limited
                  </Text>
                  {/* <Text style={styles.label}> {selectedItem?.orderId}</Text> */}
                  <Text style={styles.label}>Customer Contact</Text>
                  <Text style={styles.fields}>+91 8263090692</Text>

                  <Text style={styles.label}>Customer Address</Text>
                  <Text style={styles.fields}>
                    World Trade Center, Tower 2, Office No. 802, Kharadi,
                    Pune-411014. Maharashtra, India
                  </Text>
                </View>
                <View>
                  <Text style={styles.label}>Invoice Date :</Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.invoiceDate}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></View>
            </View>
            <View
              style={{
                height: "100%",
                width: "50%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View style={{ display: "flex", width: "50%" }}>
                <View style={{ height: "50%" }}>
                  <Text style={styles.label}>Billing Address : </Text>
                  <Text style={styles.fields}>
                    Office No 802 Tower 2 World Trade center, Kharadi Pune
                  </Text>
                  <Text style={styles.label}>GST No:</Text>
                  <Text style={styles.fields}> 27AAGCK1784J1Z6 </Text>
                </View>
                <View
                  style={{
                    height: "50%",
                    borderTop: "1px solid gray",
                    paddingTop: "5px",
                  }}
                >
                  <Text style={styles.label}>Shipping Address:</Text>
                  <Text style={styles.fields}>
                    Office No 802 Tower 2 World Trade center, Kharadi Pune
                  </Text>
                  <Text style={styles.label}>GST No:</Text>
                  <Text style={styles.fields}>27AAGCK1784J1Z6</Text>
                </View>
              </View>
              <View
                style={{
                  borderLeft: "1px solid gray",
                  width: "50%",
                  marginLeft: "15px",
                  paddingLeft: "10px",
                }}
              >
                <Text style={styles.label}>Sold By:</Text>
                <Text style={styles.fields}>KS Garage</Text>

                <Text style={styles.label}>PAN No:</Text>
                <Text style={styles.fields}>AAGCK1784J</Text>
                <Text style={styles.label}>GSTIN No:</Text>
                <Text style={styles.fields}>27AAGCK1784J1Z6</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              backgroundColor: "white",
              padding: "5px",
              marginTop: "15px",
            }}
          >
            <View
              style={{
                borderColor: borderColor,
                borderTopWidth: "0.5px",
                borderBottomWidth: "0.5px",
              }}
            >
              <Tableheader />
              <TableRow data={invoiceDetails?.items} />
              {/* <TableFooter items={invoiceDetails?.items} /> */}

              <View style={styles.container} wrap={false}>
                <View style={styles.total}>
                  <Text>Total</Text>
                </View>
                <View style={styles.qty}>
                  <Text>{quantity}</Text>
                </View>
                <View style={styles.price}>
                  <Text>{price}</Text>
                </View>
                <View style={styles.discount}>
                  <Text>{discount}</Text>
                </View>
                <View style={styles.taxableAmount}>
                  <Text>{invoiceDetails?.taxableAmount.toFixed(2)}</Text>
                </View>
                <View style={styles.gstSection}>
                  <Text>{invoiceDetails?.totalGst / 2}</Text>
                </View>

                <View style={styles.gstSection}>
                  <Text>{invoiceDetails?.totalGst / 2}</Text>
                </View>
                <View style={styles.totalPrice}>
                  <Text>{invoiceDetails?.totalAmount.toFixed(2)}</Text>
                </View>
              </View>
            </View>
            <View wrap={false}>
              <View
                style={{
                  marginTop: "10px",
                  position: "relative",
                  width: "49%",
                  left: "51%",
                  padding: "0px 5px",
                  borderBottom: "1px solid grey",
                }}
                wrap={false}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginBottom: "3px",
                  }}
                >
                  <Text>Total Amount before Tax :</Text>
                  <Text>{invoiceDetails?.taxableAmount.toFixed(2)}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginBottom: "3px",
                  }}
                >
                  <Text>Add .CGST :</Text>
                  <Text>{invoiceDetails?.cgst.toFixed(2)}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginBottom: "5px",
                  }}
                >
                  <Text>Add .SGST :</Text>
                  <Text>{invoiceDetails?.sgst.toFixed(2)}</Text>
                </View>
              </View>

              <View
                style={{
                  position: "relative",
                  width: "49%",
                  left: "51%",
                  padding: "0px 5px",
                  backgroundColor: "rgba(235, 235, 235, 0.79)",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Text>Total Amount : GST :</Text>
                  <Text>{invoiceDetails?.totalGst.toFixed(2)}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Text>Total Amount After Tax :</Text>
                  <Text>{invoiceDetails?.totalAmount.toFixed(2)}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    fontSize: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Text style={{ width: "50%" }}>
                    {invoiceDetails &&
                      convertor.toWords(invoiceDetails?.totalAmount)}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                borderTop: "1px solid grey",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
              wrap={false}
            >
              <View style={{ width: "49%" }}>
                <Text style={{ fontSize: "8px", marginTop: "5px" }}>
                  Ceritified that the particulars given above are true and
                  correct for Kalyani Studio Pvt. Ltd.
                </Text>

                <Text
                  style={{
                    fontSize: "8px",
                    marginTop: "15px",
                    marginBottom: "10px",
                  }}
                >
                  Authorised Signatory
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    /* </PDFViewer> */
  );
}
