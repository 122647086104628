import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { CommonContextAPI } from "../../App";
import { InputAdornment, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ArrivalModeContext } from "./LeadDetails";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import moment from "moment";
import "../../modules/leads/AddLead.css";
import "./ArrivalModeModal.css";
import { MobileTimePicker } from "@mui/x-date-pickers";
import {
  getAllArrivalModes,
  getAllFollowUpReasons,
} from "../../services/leadServices/LeadServices";
import { getDeadOrderReasons } from "../../services/orderServices/OrderServices";

const ArrivalModeModal = ({ propsData, mode, viewDetails }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [allFollowUpReasons, setAllFollowUpReasons] = useState([]);
  const [deadOrderReasonsList, setDeadOrderReasonsList] = useState([]);
  //   const [pickupDetails, setPickupDetails] = useState({});
  const [arrivalModes, setArrivalModes] = useState([]);

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);
  const arrivalModeContextData = useContext(ArrivalModeContext);
  const {
    setArrivalModeDetails,
    arrivalModeDetails,
    leadStatusDetails,
    setLeadStatusDetails,
    openModalOf,
  } = arrivalModeContextData;

  console.log(
    "arrivalModeDetails",
    typeof arrivalModeDetails.arrivalTime == "object"
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name == "time") {
    //   setArrivalModeDetails({
    //     ...arrivalModeDetails,
    //     [name]: moment(value, ["hh:mm A"]),
    //   });
    //   return;
    // }

    if (openModalOf == "status") {
      setLeadStatusDetails({ ...leadStatusDetails, [name]: value });
    } else {
      setArrivalModeDetails({ ...arrivalModeDetails, [name]: value });
    }
  };

  // Get all Arrival Modes

  const getArrivalModes = async () => {
    try {
      const response = await getAllArrivalModes();
      const data = await response.data;
      setArrivalModes(data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArrivalModes();
  }, []);

  const [arrivalDateTime, setArrivalDateTime] = useState({
    arrivalDate: "",
    arrivalTime: "",
  });

  const handleDateTime = (field, value) => {
    console.log(field, value);
    if (field == "arrivalDate") {
      setArrivalDateTime({
        ...arrivalDateTime,
        [field]: value?.$d,
      });

      if (openModalOf == "status") {
        setLeadStatusDetails({
          ...leadStatusDetails,
          // [field]: value?.$d,
          [field]: dayjs(moment(value?.$d).format("YYYY-MM-DD")),
        });
      } else {
        setArrivalModeDetails({
          ...arrivalModeDetails,
          [field]: dayjs(moment(value?.$d).format("YYYY-MM-DD")),
          // [field]: moment(value?.$d).format("YYYY-MM-DD"),
        });
      }
    } else {
      setArrivalDateTime({
        ...arrivalDateTime,
        [field]: value?.$d,
      });

      if (openModalOf == "status") {
        setLeadStatusDetails({
          ...leadStatusDetails,
          // [field]: value?.$d,
          [field]: dayjs(moment(value?.$d).format()),
        });
      } else {
        setArrivalModeDetails({
          ...arrivalModeDetails,
          [field]: dayjs(moment(value?.$d).format()),
        });
      }
    }
  };

  const {
    openArrivalModal,
    closeArrivalModal,
    openLocationModal,
    closeLocationModal,
    openReasonsModal,
    closeReasonsModal,
    openGaragesModal,
    closeGaragesModal,
    selectedMode,
    closeArrivalModeDetails,
  } = propsData;

  const saveModeDetails = () => {
    viewDetails ? closeArrivalModeDetails() : closeArrivalModal();
  };

  const discardModeDetails = () => {
    if (openModalOf == "status") {
      setLeadStatusDetails({
        arrivalDate: "",
        arrivalTime: "",
        location: "",
        garage: "",
        reason: "",
        ArrivalModeRemark: "",
      });
    } else {
      setArrivalModeDetails({
        arrivalDate: "",
        arrivalTime: "",
        location: "",
        garage: "",
        ArrivalModeRemark: "",
      });
    }
  };

  const followUpReasonsList = async () => {
    try {
      const response = await getAllFollowUpReasons();
      const data = await response.data;
      setAllFollowUpReasons(data.result);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors.error);
    }
  };

  const getDeadReasons = async () => {
    try {
      const response = await getDeadOrderReasons();
      const data = await response.data;
      setDeadOrderReasonsList(data.result);
    } catch (error) {
      console.log(error);
      // handleAlertOpen(error?.response?.data?.message, colors.error);
      // handleAlertOpen(error?.message, colors.error);
    }
  };

  useEffect(() => {
    followUpReasonsList();
    // getDeadReasons();
  }, []);

  const userSelection = (fieldName, name, id) => {
    const obj = {
      name,
      id,
    };

    if (openModalOf == "status") {
      setLeadStatusDetails({ ...leadStatusDetails, [fieldName]: obj.name });
    } else {
      setArrivalModeDetails({
        ...arrivalModeDetails,
        [fieldName]: obj,
      });
    }
  };

  const discardFollowDetails = () => {
    setArrivalModeDetails({
      arrivalDate: "",
      arrivalTime: "",
      jobCardValue: "",
      leadType: "",
      reason: "",
    });
  };

  const saveFollowUpDetails = () => {
    viewDetails ? closeArrivalModeDetails() : closeArrivalModal();
  };

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: isMobile ? "80%" : 615,
    height: "auto",
    maxHeight: "100%",
    border: "none !important",
    overflow: "auto !important",
  };

  // console.log("arrivalModeDetails", arrivalModeDetails);

  return (
    <div>
      <Modal
        open={true}
        onClose={viewDetails ? closeArrivalModeDetails : closeArrivalModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {mode == "Follow Up" ? (
            <>
              <h2 className="leadsTitle align-center">{mode}</h2>
              <Divider />
              <Box
                style={{
                  position: "absolute",
                  top: 1,
                  right: "10px",
                  margin: "0.2rem 0px",
                }}
              >
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={
                    viewDetails ? closeArrivalModeDetails : closeArrivalModal
                  }
                />
              </Box>
              <Grid container spacing={1} className="gridRow">
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker"]}
                      sx={{
                        minWidth: 210,
                      }}
                    >
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#aeaeae !important",
                          },
                        }}
                        className="date-picker"
                        id="arrivalDate"
                        name="arrivalDate"
                        onChange={(value) =>
                          handleDateTime("arrivalDate", value)
                        }
                        value={
                          viewDetails
                            ? leadStatusDetails?.arrivalDate &&
                              dayjs(leadStatusDetails?.arrivalDate)
                            : leadStatusDetails?.arrivalDate
                        }
                        format="DD-MM-YYYY"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["MobileTimePicker", "MobileTimePicker"]}
                      sx={{ minWidth: 210 }}
                    >
                      <MobileTimePicker
                        open={openTimePicker}
                        onOpen={() => setOpenTimePicker(true)}
                        onClose={() => setOpenTimePicker(false)}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#aeaeae !important",
                          },
                        }}
                        id="arrivalTime"
                        name="arrivalTime"
                        views={["hours", "minutes"]}
                        onChange={(value) =>
                          handleDateTime("arrivalTime", value)
                        }
                        // value={
                        //   viewDetails
                        //     ? dayjs(leadStatusDetails?.arrivalTime)
                        //     : leadStatusDetails?.arrivalTime
                        // }

                        value={
                          viewDetails
                            ? leadStatusDetails.arrivalDate &&
                              dayjs(
                                dayjs(leadStatusDetails.arrivalDate).format(
                                  "YYYY-MM-DD"
                                ) +
                                  dayjs(leadStatusDetails.arrivalTime).format(
                                    " HH:mm:ss"
                                  )
                              )
                            : leadStatusDetails?.arrivalTime
                        }
                        format="HH:mm:ss"
                      />
                      <Box
                        sx={{
                          position: "absolute !important",
                          top: "21%",
                          right: "42px",
                        }}
                        onClick={() => setOpenTimePicker(true)}
                      >
                        <AccessTimeIcon className="icons" />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Box
                className="radio-group-box"
                sx={{ "& .MuiFormControl-root": { width: "100% !important" } }}
              >
                <FormControl>
                  <FormLabel className="sub-heading" id="jobCardValue">
                    Job Card Value
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="jobCardValue"
                    name="jobCardValue"
                    className="radio-group"
                    onChange={handleChange}
                    value={
                      openModalOf == "status"
                        ? leadStatusDetails?.jobCardValue
                        : arrivalModeDetails?.jobCardValue
                    }
                  >
                    <FormControlLabel
                      value="high"
                      control={<Radio />}
                      label="High"
                    />
                    <FormControlLabel
                      value="medium"
                      control={<Radio />}
                      label="Medium"
                    />
                    <FormControlLabel
                      value="low"
                      control={<Radio />}
                      label="Low"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                className="radio-group-box"
                sx={{ "& .MuiFormControl-root": { width: "100% !important" } }}
              >
                <FormControl>
                  <FormLabel className="sub-heading" id="leadType">
                    Lead Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="leadType"
                    name="leadType"
                    className="radio-group"
                    onChange={handleChange}
                    value={
                      openModalOf == "status"
                        ? leadStatusDetails?.leadType
                        : arrivalModeDetails?.leadType
                    }
                  >
                    <FormControlLabel
                      value="hot"
                      control={<Radio />}
                      label="Hot"
                    />
                    <FormControlLabel
                      value="cold"
                      control={<Radio />}
                      label="Cold"
                    />
                  </RadioGroup>
                </FormControl>

                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      // size="small"
                      label="Reason"
                      fullWidth
                      select
                      variant="outlined"
                      id="reason"
                      margin="dense"
                      name="reason"
                      value={
                        openModalOf == "status"
                          ? leadStatusDetails?.reason
                          : arrivalModeDetails?.reason
                      }
                    >
                      {allFollowUpReasons.map((reason) => (
                        <MenuItem
                          key={reason.id}
                          value={reason.reason}
                          onClick={() =>
                            userSelection("reason", reason.reason, reason.id)
                          }
                        >
                          {reason.reason}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={1} sx={{ marginTop: "10px" }}>
                <Grid item xs={12} sx={{ display: "flex" }}>
                  <div className="buttonGroup">
                    <Button
                      variant="contained"
                      size="small"
                      className="buttons"
                      onClick={discardFollowDetails}
                    >
                      Discard
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="buttons"
                      style={{ marginLeft: "1rem" }}
                      type="submit"
                      onClick={saveFollowUpDetails}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <h2 className="leadsTitle">
                {/* {arrivalModeDetails?.arrivalMode || mode} */}
                {viewDetails ? "Arrival Mode Details" : selectedMode || mode}
              </h2>
              <Divider />
              <Box
                style={{
                  position: "absolute",
                  top: 1,
                  right: "10px",
                  margin: "0.2rem 0px",
                }}
              >
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={
                    viewDetails ? closeArrivalModeDetails : closeArrivalModal
                  }
                />
              </Box>
              {viewDetails && (
                <Grid container spacing={1} className="gridRow">
                  <Grid item xs={4}>
                    <Typography className="lables">Mode</Typography>
                  </Grid>
                  {/* <Grid item xs={8}>
                    <Box
                      className="buttonGroup"
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {arrivalModes?.map((mode) => [
                        <>
                          <Button
                            variant="contained"
                            size="small"
                            className="buttons"
                            onClick={() =>
                              userSelection("arrivalMode", mode?.mode, mode?.id)
                            }
                            sx={{
                              backgroundColor:
                                arrivalModeDetails?.arrivalMode?.id == mode?.id
                                  ? "#122164 !important"
                                  : "#7B8BD4 !important",
                            }}
                          >
                            {mode?.mode}
                          </Button>
                        </>,
                      ])}
                    </Box>
                  </Grid> */}
                  <Grid item xs={8}>
                    <ul
                      className="donate-now"
                      style={{
                        marginTop: 0,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      {arrivalModes &&
                        arrivalModes.map((mode, index) => {
                          return (
                            <Box key={index}>
                              <li>
                                <input
                                  type="radio"
                                  id={mode.id}
                                  name="arrivalMode"
                                  value={mode?.id || ""}
                                  defaultChecked={
                                    arrivalModeDetails?.arrivalMode?.mode ==
                                    mode?.mode
                                      ? true
                                      : false
                                  }
                                  // defaultChecked={index == 1 ? true : false}
                                  onClick={() =>
                                    userSelection(
                                      "arrivalMode",
                                      mode.id,
                                      mode.mode
                                    )
                                  }
                                  onChange={(e) => console.log(e.target.value)}
                                />
                                <label htmlFor={mode?.mode}>{mode?.mode}</label>
                              </li>
                            </Box>
                          );
                        })}
                    </ul>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={1} className="gridRow">
                <Grid item xs={4}>
                  <Typography className="lables">Date</Typography>
                </Grid>
                <Grid item xs={8} sx={{ position: "relative" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker", "DatePicker"]}
                      // sx={{
                      //   minWidth: 210,
                      // }}
                    >
                      <DatePicker
                        sx={{
                          width: "100%",
                          minWidth: "150px !important",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#aeaeae !important",
                          },
                        }}
                        className="date-picker"
                        id="arrivalDate"
                        name="arrivalDate"
                        onChange={(value) =>
                          handleDateTime("arrivalDate", value)
                        }
                        value={
                          viewDetails
                            ? arrivalModeDetails?.arrivalDate &&
                              dayjs(arrivalModeDetails?.arrivalDate)
                            : arrivalModeDetails?.arrivalDate
                        }
                        format="DD-MM-YYYY"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="gridRow">
                <Grid item xs={4}>
                  <Typography className="lables">Time</Typography>
                </Grid>
                <Grid item xs={8}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        "MobileTimePicker",
                        "MobileTimePicker",
                        // "MobileTimePicker",
                      ]}
                      sx={{ position: "relative !important" }}
                    >
                      <MobileTimePicker
                        open={openTimePicker}
                        onOpen={() => setOpenTimePicker(true)}
                        onClose={() => setOpenTimePicker(false)}
                        className="hello"
                        sx={{
                          width: "100%",
                          minWidth: "150px !important",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#aeaeae !important",
                          },
                        }}
                        id="arrivalTime"
                        name="arrivalTime"
                        // label={"Arrival Time "}
                        views={["hours", "minutes"]}
                        onChange={(value) =>
                          handleDateTime("arrivalTime", value)
                        }
                        value={
                          viewDetails
                            ? arrivalModeDetails.arrivalDate &&
                              dayjs(
                                `${dayjs(arrivalModeDetails.arrivalDate).format(
                                  "YYYY-MM-DD"
                                )} ${
                                  typeof arrivalModeDetails.arrivalTime == "object"
                                    ? dayjs(arrivalModeDetails.arrivalTime).format(
                                      " HH:mm:ss"
                                    )
                                    : arrivalModeDetails.arrivalTime
                                }`
                              //   dayjs(arrivalModeDetails.arrivalDate).format(
                              //     "YYYY-MM-DD"
                              //   ) +
                              //     dayjs(arrivalModeDetails.arrivalTime).format(
                              //       " HH:mm:ss"
                              //     )
                              )
                            : arrivalModeDetails?.arrivalTime
                        }
                        format="HH:mm:ss"
                      />
                      <Box
                        sx={{
                          position: "absolute !important",
                          // top: viewDetails ? "34%" : "28%",
                          top: isMobile ? "0%" : isDesktop ? "32%" : "5%",
                          right: "10px",
                        }}
                        onClick={() => setOpenTimePicker(true)}
                      >
                        <AccessTimeIcon className="icons" />
                      </Box>
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container className="gridRow" spacing={1}>
                <Grid item xs={4}>
                  <Typography className="lables">Location</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    size="small"
                    label="Location"
                    fullWidth
                    variant="outlined"
                    id="location"
                    margin="dense"
                    name="location"
                    value={
                      openModalOf == "status"
                        ? leadStatusDetails?.location
                        : arrivalModeDetails?.location
                    }
                    onClick={openLocationModal}
                    disabled={
                      openModalOf == "status"
                        ? leadStatusDetails?.location
                        : arrivalModeDetails?.location
                        ? false
                        : true
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ExpandMoreIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className="gridRow" spacing={1}>
                <Grid item xs={4}>
                  <Typography className="lables">Garage</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    size="small"
                    label="Garage"
                    fullWidth
                    variant="outlined"
                    id="garage"
                    margin="dense"
                    name="garage"
                    value={
                      openModalOf == "status"
                        ? leadStatusDetails?.garage
                        : arrivalModeDetails?.garage
                    }
                    onClick={openGaragesModal}
                    disabled={
                      openModalOf == "status"
                        ? leadStatusDetails?.garage
                        : arrivalModeDetails?.garage
                        ? false
                        : true
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ExpandMoreIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container className="gridRow" spacing={1}>
                <Grid item xs={4}>
                  <Typography className="lables">Remarks</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    required
                    multiline
                    minRows={4}
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Remarks"
                    id="ArrivalModeRemark"
                    style={{ marginTop: 20 }}
                    name="ArrivalModeRemark"
                    onChange={handleChange}
                    value={
                      openModalOf == "status"
                        ? leadStatusDetails?.ArrivalModeRemark
                        : arrivalModeDetails?.ArrivalModeRemark
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={2} sm={4}></Grid>
                <Grid item xs={10} sm={6} sx={{ display: "flex" }}>
                  <div className="buttonGroup">
                    <Button
                      variant="contained"
                      size="small"
                      className="buttons"
                      onClick={discardModeDetails}
                    >
                      Discard
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="buttons"
                      style={{ marginLeft: "1rem" }}
                      type="submit"
                      onClick={saveModeDetails}
                    >
                      Confirm
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ArrivalModeModal;
