import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AddIcon from "@material-ui/icons/Add";

import DeleteIcon from "@mui/icons-material/Delete";
import "./InsControlPanel.css";
import { width } from "@mui/system";
import InsModal from "./InsModal";
import {
  deleteService,
  getAllServiceTypes,
  getCatagoriesByVehicle,
  getServices,
} from "../../../services/Services";
import { useParams } from "react-router-dom";
import { deleteNonCatalogueEstimate } from "../../../services/orderServices/OrderServices";
import { jobCardContext } from "../OrderDetails";
import { CommonContextAPI } from "../../../App";
import "./InsControlPanel.css";
import LoaderSpinner from "../../../components/loading/LoaderSpinner";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function InsControlPanel({ isServiceAdvisor }) {
  const [open, setOpen] = React.useState(false);
  const [insuranseInfo, setInsuranseInfo] = useState();
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [nonCatalogue, setNonCatalogue] = useState();
  const [discountField, setDiscountField] = useState();
  const [serviceType, setServiceType] = useState("Service Type");
  const [activeBtn, setActiveBtn] = useState("Garage");
  const [updateNewNonCata, setUpdateNewNonCata] = useState([]);
  const [serviceTypes, setserviceTypes] = useState([]);

  const [allData, setAllData] = useState([]);
  // const [insuranceDetails, setInsuranceDetails] = useState();
  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);
  const context = useContext(jobCardContext);

  // get order id from params
  const { type, orderId } = useParams();

  const {
    OrderInfo,
    nonCatalogueEstimate,
    setNonCatalogueEstimate,
    setEstimateDiscount,
    estimateDiscount,
    setInsuranceDetails,
    insuranceDetails,
    setUpdateObj,
    updateObj,
    deleteInsuranceServices,
    setDeleteInsuranceServices,
    servicesList,
    setServicesList,
  } = context;

  const [serviceCategories, setServiceCategories] = useState([]);
  const [servicePackages, setServicePackages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedService, setSelectedService] = useState({});

  const [isCategory, setIsCategory] = useState(false); //handle selection of categories inside the packages
  const [isService, setIsService] = useState(false); //handle selection of Services inside the packages

  // Close dropdowns

  const closeDropdowns = () => {
    setIsCategory(false);
    setIsService(false);
    setSelectedCategory({});
    setSelectedService({});
  };

  // Add services

  const getCategories = async (vehicleType) => {
    try {
      const request = await getCatagoriesByVehicle(vehicleType);
      const response = await request.data;
      setServiceCategories(response.result);
    } catch (error) {
      handleAlertOpen(error?.message, colors?.error);
    }
  };
  useEffect(() => {
    vehicleDetails?.vehicleType?.id &&
      getCategories(vehicleDetails?.vehicleType?.id);
  }, [vehicleDetails]);

  const [isServicesLoading, setIsServicesLoading] = useState(false);

  useMemo(() => {
    selectedCategory?.categoryId &&
      (async () => {
        try {
          const request = await getServices(
            selectedCategory?.categoryId,
            vehicleDetails?.model?.modelId,
            vehicleDetails?.variant?.id
          );
          const response = await request.data;
          setServicePackages(response.result);
          setIsServicesLoading(false);
        } catch (error) {
          handleAlertOpen(error?.message, colors?.error);
        }
      })();
  }, [vehicleDetails, selectedCategory?.categoryId]);

  const handleSelection = () => {
    !isCategory && !isService && setIsCategory(true);
    setSelectedCategory({});
  };

  const handleAddServices = (name, value) => {
    if (name == "category") {
      setIsCategory(false);
      setIsService(true);
      setSelectedCategory(value);
      setIsServicesLoading(true);
    }

    if (name == "services") {
      setSelectedService(value);
      setIsService(false);
    }
  };

  // Add Handle service
  const [newAddedServices, setNewAddedServices] = useState([]);

  const addService = () => {
    if (Object.keys(selectedService).length == 0) {
      handleAlertOpen("Please select service!", colors.error);
      setSelectedCategory({});
      setSelectedService({});
      setIsCategory(false);
      setIsService(false);
      return;
    }

    let isExists = servicesList?.find(
      (service) => service?.serviceId == selectedService?.serviceId
    );

    if (isExists) {
      handleAlertOpen(
        `${selectedService?.service} already exists!`,
        colors.error
      );
      return;
    }
    setServicesList([...servicesList, selectedService]);
    setNewAddedServices([...newAddedServices, selectedService]);
    setSelectedCategory({});
    setSelectedService({});
  };

  useMemo(() => {
    setUpdateObj({
      ...updateObj,
      servicesList: newAddedServices,
    });
  }, [newAddedServices]);

  // Delete Services

  const deleteServices = async (bookingId, serviceId) => {
    if (bookingId) {
      let result = servicesList?.filter(
        (service) => service?.bookingId != bookingId
      );
      setServicesList(result);
    } else {
      let result = servicesList?.filter(
        (service) => service?.serviceId != serviceId
      );

      setServicesList(result);

      let removeNew = newAddedServices?.filter(
        (service) => service?.serviceId != serviceId
      );

      setNewAddedServices(removeNew);
    }

    // console.log("Booking id", bookingId);

    // try {
    //   const request = await deleteService(bookingId);
    //   const response = await request.data;
    //   window.location.reload();
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // }

    setDeleteInsuranceServices([...deleteInsuranceServices, bookingId]);
  };
  useMemo(() => {
    estimateDiscount &&
      setUpdateObj({ ...updateObj, discount: estimateDiscount });
  }, [estimateDiscount]);
  useMemo(() => {
    setUpdateObj({ ...updateObj, nonCatalogueJobs: updateNewNonCata });
  }, [updateNewNonCata]);

  useEffect(() => {
    setDiscountField(estimateDiscount);
  }, [estimateDiscount]);

  useMemo(() => {
    insuranseInfo?.nonCatalogueJobs &&
      setAllData([...allData, ...insuranseInfo?.nonCatalogueJobs]);
    setActiveBtn(
      insuranceDetails?.codeOfInsuranceId == 2 ? "Gativan" : "Garage"
    );
  }, [insuranseInfo?.nonCatalogueJobs]);

  const handleNonCatalogues = (event) => {
    setNonCatalogue({
      ...nonCatalogue,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddJobs = () => {
    if (
      nonCatalogue?.name &&
      nonCatalogue?.hsn &&
      nonCatalogue?.gst &&
      nonCatalogue?.quantity &&
      nonCatalogue?.price &&
      nonCatalogue?.serviceTypeId
    ) {
      let price = nonCatalogue.price;
      let quntity = nonCatalogue.quantity;

      setAllData([...allData, { ...nonCatalogue, price: price * quntity }]);
      setUpdateNewNonCata([
        ...updateNewNonCata,
        { ...nonCatalogue, price: price * quntity },
      ]);
      setNonCatalogueEstimate([
        ...nonCatalogueEstimate,
        { ...nonCatalogue, price: price * quntity },
      ]);

      setNonCatalogue({
        name: "",
        hsn: "",
        gst: "",
        quantity: "",
        price: "",
      });
      setServiceType("Service Type");
    } else {
      handleAlertOpen("Please Enter NC Job Details!", colors.error);
    }
  };

  const RemoveSavedService = async (id) => {
    try {
      const request = await deleteNonCatalogueEstimate(id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveNonCata = (index, id) => {
    // let temp = nonCatalogueEstimate;

    id && RemoveSavedService(id);
    let temp = allData;

    temp.splice(index, 1);
    setNonCatalogueEstimate([...temp]);
  };

  const handleDiscount = (e) => {
    setDiscountField(e.target.value);
    // setEstimateDiscount(e.target.value);
  };

  const handleServiceType = (event) => {
    setServiceType(event.target.value);
    // setNonCatalogue({
    //   ...nonCatalogue,
    //   [event.target.name]: event.target.value,
    // });
  };

  const handleAddDiscount = (event) => {
    setEstimateDiscount(discountField);
  };

  // get services types of non catalogues
  const getServicesTyeps = async () => {
    try {
      const request = await getAllServiceTypes();
      const response = await request.data;
      setserviceTypes(response.result);
    } catch (error) {}
  };

  useEffect(() => {
    getServicesTyeps();
    setInsuranseInfo(OrderInfo);
    setVehicleDetails(OrderInfo?.vehicle);
  }, [OrderInfo]);

  return (
    <Box className="icp-container" onClick={closeDropdowns}>
      {open && (
        <InsModal
          open={open}
          setOpen={setOpen}
          activeBtn={activeBtn}
          setActiveBtn={setActiveBtn}
        />
      )}

      <Box
        pb={3}
        sx={{ borderBottom: "2px solid #DBDBDB", ...isServiceAdvisor }}
      >
        <Typography variant="h6">Insurance Details</Typography>
        <Box className="icp-box icp-input-box">
          <Typography>
            {" "}
            {insuranceDetails?.codeOfInsuranceId === 1
              ? "Garage"
              : "Gativan"}{" "}
          </Typography>
        </Box>

        <Box>
          <Button
            className="icp-button-box"
            variant="contained"
            sx={{ backgroundColor: "#7B8BD4 !important" }}
            onClick={() => setOpen(true)}
          >
            Details
          </Button>
        </Box>
      </Box>

      <Box className="insurance-package-container" sx={isServiceAdvisor}>
        <Typography variant="h6">
          {OrderInfo?.vehicle?.vehicleType?.vehicleType} Packages
        </Typography>
        <Box onClick={(e) => e.stopPropagation()}>
          <Box className="insurance-select-packaages-container" mt={3}>
            <Box className="select-packages">
              <Box className="selectCategoryLabel">
                <Typography className="selectButton" onClick={handleSelection}>
                  {selectedCategory?.category || selectedService?.service ? (
                    `${selectedCategory?.category} ${
                      selectedService?.service || ""
                    }`
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Add Service</span>
                      <ArrowDropDownIcon />
                    </Box>
                  )}
                </Typography>
              </Box>
              <Box className="mainMenu">
                {isCategory &&
                  serviceCategories.map((item) => {
                    const { categoryId, category, imageUrl } = item;
                    return (
                      <Typography
                        className="mainMenuLink"
                        onClick={() => handleAddServices("category", item)}
                      >
                        {category}
                      </Typography>
                    );
                  })}
              </Box>
              <Box className="subMenu">
                {isServicesLoading ? (
                    <LoaderSpinner />
                ) : (
                  isService &&
                  servicePackages.map((item) => {
                    const { service, categoryId, subCategory } = item;
                    return (
                      <Box>
                        {service &&
                          service.map((ele) => {
                            const { bookingId, service } = ele;

                            return (
                              <Typography
                                className="subMenuLink"
                                onClick={() =>
                                  handleAddServices("services", ele)
                                }
                              >
                                {service}
                              </Typography>
                            );
                          })}
                      </Box>
                    );
                  })
                )}
              </Box>
            </Box>

            <Box className="add-button-box">
              <Button
                className="insurance-add-service-button"
                onClick={addService}
              >
                + Add
              </Button>
            </Box>
          </Box>
          {servicesList?.length > 0 ? (
            <>
              {servicesList?.map((item, index) => {
                const { bookingId, serviceId, service } = item;
                return (
                  <Box className="insurance-added-services" key={index}>
                    <Typography className="insurance-service">
                      <span>{service}</span>
                      <DeleteIcon
                        onClick={() => deleteServices(bookingId, serviceId)}
                        className="delete-icon"
                      />
                    </Typography>
                  </Box>
                );
              })}
            </>
          ) : (
            <Typography>No Service</Typography>
          )}
        </Box>
      </Box>

      <Box
        pt={3}
        pb={3}
        sx={{ borderBottom: "2px solid #DBDBDB", ...isServiceAdvisor }}
      >
        <Typography variant="h6">Non - Catalogue Jobs</Typography>

        <Box
          mt={3}
          sx={{
            display: "grid",
            gap: "8px",
            gridTemplateColumns:
              "repeat(auto-fit, minmax(100px, 1fr))!important",
          }}
        >
          <Input
            className="icp-box icp-input-fileds-box"
            placeholder="Enter Job Name"
            disableUnderline
            value={nonCatalogue?.name}
            name="name"
            onChange={handleNonCatalogues}
          />
          <Input
            className="icp-box icp-input-fileds-box"
            placeholder="HSN Code"
            disableUnderline
            value={nonCatalogue?.hsn}
            name="hsn"
            onChange={handleNonCatalogues}
          />
          <Input
            className="icp-box icp-input-fileds-box"
            placeholder="GST Rate"
            disableUnderline
            value={nonCatalogue?.gst}
            name="gst"
            onChange={handleNonCatalogues}
          />
          <Input
            className="icp-box icp-input-fileds-box"
            placeholder="Quantity"
            disableUnderline
            value={nonCatalogue?.quantity}
            name="quantity"
            onChange={handleNonCatalogues}
          />
          <Input
            className="icp-box icp-input-fileds-box"
            placeholder="Enter Amount"
            disableUnderline
            value={nonCatalogue?.price}
            name="price"
            onChange={handleNonCatalogues}
          />
        </Box>
        <Box mt={1}>
          <Select
            value={serviceType}
            className="icp-box icp-input-fileds-box"
            style={{ width: "25%" }}
            name="serviceType"
            onChange={(event) => setServiceType(event.target.value)}
          >
            <MenuItem disabled value="Service Type">
              Service Type
            </MenuItem>
            {serviceTypes?.map((service) => (
              <MenuItem
                key={service?.id}
                value={service.type}
                onClick={() => {
                  setNonCatalogue({
                    ...nonCatalogue,
                    serviceTypeId: service?.id,
                    serviceType: service?.type,
                  });
                }}
              >
                {service.type}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Button
            className="icp-button-box"
            mt={2}
            variant="contained"
            sx={{ backgroundColor: "#122164 !important" }}
            onClick={handleAddJobs}
          >
            Add Jobs
          </Button>
        </Box>

        {nonCatalogueEstimate &&
          nonCatalogueEstimate?.map((item, index) => {
            const { id, name } = item;
            return (
              <Box className="showAddedServices" key={index}>
                <Typography className="addedService">
                  {name}
                  <DeleteIcon
                    onClick={() => handleRemoveNonCata(index, id)}
                    className="delete-btn"
                  />
                </Typography>
              </Box>
            );
          })}
      </Box>

      <Box pt={3} pb={3} sx={{ borderBottom: "2px solid #DBDBDB" }}>
        <Typography variant="h6">Add Discount</Typography>
        <Box mt={3}>
          <Input
            className="icp-box icp-input-fileds-box"
            placeholder="Enter Discount"
            disableUnderline
            value={discountField}
            onChange={handleDiscount}
          />
        </Box>

        <Box>
          <Button
            className="icp-button-box"
            mt={2}
            variant="contained"
            sx={{ backgroundColor: "#122164 !important" }}
            onClick={handleAddDiscount}
          >
            Add Discount
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
