import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/tables/Table";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import AddMaterial from "./AddMaterial";
import { getMaterialLib } from "../../services/inventoryServices/InventoryServices";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "brand",
    headerName: "Brand",
    headerClassName: "super-app-theme--header",
    minWidth: 166,
    flex: 1,
  },
  {
    field: "grade",
    headerName: "Grade",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "hsn",
    headerName: "HSN",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "super-app-theme--header",
    minWidth: 166,
    flex: 1,
  },
  {
    field: "gst",
    headerName: "GST",
    headerClassName: "super-app-theme--header",
    filterable: true,
    editable: true,
    minWidth: 160,
    flex: 1,
  },
];

// { downloadXLS, handleOpenDatePicker, filteredDate }

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const MaterialLibrary = () => {
  const theme = useTheme();

  const [materialList, setMaterialList] = useState([]);
  const [materialObj, setMaterialObj] = useState([]);

  const [isMaterialAdded, setIsMaterialAdded] = useState(false);

  const [filter, setFilter] = useState([]);
  const getFilteredData = (data) => {
    setFilter(data);
  };

  const updateMaterialPrice = (value) => {
    console.log("Updated Value", value);
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const getAllProductsList = async () => {
    try {
      const response = await getMaterialLib();
      const data = await response.data;
      setMaterialList(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // materialList?.map((material) => {
    //   setMaterialObj(prev => [...prev, {...material, brand: material?.brand?.brand}]);
    // })
    setMaterialObj([]);

    materialList.map((material) => {
      // console.log(material);
      setMaterialObj((prev) => [
        ...prev,
        { ...material, brand: material?.brand?.brand },
      ]);
    });
  }, [materialList]);

  useEffect(() => {
    getAllProductsList();
    setIsMaterialAdded(false);
  }, [, isMaterialAdded]);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    openAddMaterialLibModel,
    handleOpenAddMaterialModel,
    handleCloseAddMaterialModel,
  } = commonData;

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <div>
        <Table
          customStyle={DataGridStyle}
          rows={materialObj}
          columns={columns}
          heading="Material Library"
          pageSize={50}
          onCellEditCommit={(param) => updateMaterialPrice(param?.value)}
          rowsPerPageOptions={[100]}
          onStateChange={(state) => {
            const visibleRows = state.filter.visibleRowsLookup;
            let visibleItems = [];
            for (const [id, value] of Object.entries(visibleRows)) {
              if (value === true) {
                visibleItems.push(Number(id));
              }
            }
            const res = materialObj.filter((item) =>
              visibleItems.includes(item.id)
            );
          }}
          downloadXLS={() =>
            downloadXLS(filter.length > 0 ? filter : materialObj)
          }
          components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
          handleOpenDatePicker={handleOpenDatePicker}
          filteredDate={filteredDate}
        />
      </div>
      <Box>
        {openAddMaterialLibModel && (
          <AddMaterial setIsMaterialAdded={setIsMaterialAdded} />
        )}
      </Box>
    </>
  );
};

export default MaterialLibrary;
