import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AddIcon from "@mui/icons-material/Add";
import { Toolbar } from "@mui/material";
import ChangePassword from "./ChangePassword";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.3rem",
    },
  },
  pageTitle: {
    fontSize: "2.5rem",
    marginBottom: "1rem",
    fontFamily: "AvenirBold !important",
  },
  toolbar: {
    height: "3rem !important",
    minHeight: "2rem !important",
  },
  cardHeader: {
    boxShadow: "0 0 1px black",
    padding: "1rem 3rem",
    backgroundColor: "#f6f6f6",
  },
  userDetailsWrapper: {
    display: "flex",
    alignItems: "center",
  },

  gridRow: {
    margin: "1rem 0 4rem",
  },

  changePasswordWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& Button": {
      color: "#fff !important",
      backgroundColor: "#10bc83",
      "&:hover": {
        backgroundColor: "#10bc83",
      },
    },
  },
  passwordHeading: {
    lineHeight: 2,
  },
  changePasswordIcon: {
    fontSize: "5rem",
  },
  changePasswordAvetar: {
    width: "100px",
    height: "100px",
  },
  changePasswordText: {
    lineHeight: 2.5,
  },

  ipAddressWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& Button": {
      color: "#fff !important",
      backgroundColor: "#10bc83",
      "&:hover": {
        backgroundColor: "#10bc83",
      },
    },
  },
  ipAddressHeading: {
    lineHeight: 2,
  },
  ipAddressIcon: {
    fontSize: "5rem",
  },
  ipAddressAvetar: {
    width: "100px",
    height: "100px",
  },
  ipAddressText: {
    lineHeight: 2.5,
  },
  ipAddressIconBox: {
    marginBottom: "1rem",
  },

  newPasswordWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& Button": {
      color: "#fff !important",
      backgroundColor: "#10bc83",
      "&:hover": {
        backgroundColor: "#10bc83",
      },
    },
  },
  newPasswordHeading: {
    lineHeight: 2,
  },
  newPasswordIcon: {
    fontSize: "5rem",
  },
  newPasswordAvetar: {
    width: "100px",
    height: "100px",
  },
  newPasswordText: {
    width: "50%",
    textAlign: "center",
    margin: "1rem 0",
  },

  changePasswordWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& Button": {
      color: "#fff !important",
      backgroundColor: "#10bc83",
      "&:hover": {
        backgroundColor: "#10bc83",
      },
    },
  },
  passwordHeading: {
    lineHeight: 2,
  },
  changePasswordIcon: {
    fontSize: "5rem",
  },
  changePasswordAvetar: {
    width: "100px",
    height: "100px",
  },
  changePasswordText: {
    lineHeight: 2.5,
  },
}));

const Security = () => {
  const classes = useStyles();
  const [isChangePassword, setisChangePassword] = useState(false);

  const closeChangePasswordModel = () => {
    setisChangePassword(false);
  };
  return (
    <div className={classes.container}>
      <Typography variant="h4" className={`titles ${classes.pageTitle}`}>
        Security
      </Typography>
      <Box className={classes.changePasswordContainer}>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            title={
              <Typography
                variant="h5"
                className={`heading ${classes.passwordHeading}`}
              >
                Password
              </Typography>
            }
            subheader={
              "Change your account password often to prevent unauthorized access to your account."
            }
            className={classes.cardHeader}
          />
          <CardContent className={classes.changePasswordWrapper}>
            <Box>
              <Avatar className={classes.changePasswordAvetar}>
                <VerifiedUserIcon className={classes.changePasswordIcon} />
              </Avatar>
            </Box>
            <Typography className={classes.changePasswordText}>
              Last modified 5 months ago
            </Typography>
            <Button
              size="small"
              variant="default"
              color="primary"
              onClick={() => setisChangePassword(true)}
            >
              Change Password
            </Button>
          </CardContent>
        </Card>
      </Box>
      {/* <Toolbar className={classes.toolbar} />
      <Box className={classes.ipAddressContainer}>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            title={
              <Typography variant="h5" className={classes.ipAddressHeading}>
                Allowed IP Address
              </Typography>
            }
            subheader={
              "Restrict access to your account by adding a range of trusted IP addresses."
            }
            className={classes.cardHeader}
          />
          <CardContent className={classes.ipAddressWrapper}>
            <Box className={classes.ipAddressIconBox}>
              <Avatar className={classes.ipAddressAvetar}>
                <VerifiedUserIcon className={classes.ipAddressIcon} />
              </Avatar>
            </Box>
            <Button size="small" variant="default" color="primary">
              Add Allowed IP Address
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Toolbar className={classes.toolbar} />
      <Box className={classes.newPasswordContainer}>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            title={
              <Typography variant="h5" className={classes.newPasswordHeading}>
                Application-Specific Passwords
              </Typography>
            }
            subheader={
              "Allow third-party applications, like email clients, to access your account with unique passwords instead of using your account password."
            }
            className={classes.cardHeader}
          />
          <CardContent className={classes.newPasswordWrapper}>
            <Box>
              <Avatar className={classes.newPasswordAvetar}>
                <VerifiedUserIcon className={classes.newPasswordIcon} />
              </Avatar>
            </Box>
            <Typography className={classes.newPasswordText}>
              Use application-specific passwords instead of your account
              password to access your Zoho account from third-party applications
            </Typography>
            <Button size="small" variant="default" color="primary">
              Generate New Password
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Toolbar className={classes.toolbar} /> */}
      {isChangePassword && (
        <ChangePassword
          isChangePassword={isChangePassword}
          closeChangePasswordModel={closeChangePasswordModel}
        />
      )}
    </div>
  );
};

export default Security;
