import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import "./Invoice.css";
import convertor from "number-to-words";
import { getCustomerInvoice } from "../../../services/orderServices/OrderServices";
import { jobCardContext } from "../OrderDetails";
import { useParams } from "react-router-dom";
import http from "../../../http-common";

const termsandconditions = [
  "Customer has understood the price, fees, all applicable taxes and charging method of the above items and has made the payment thereafter.",
  " Customer declares that the repairs/servicing have been rendered by the dealer as per prior indication and to the satisfaction of the customer.",
  "Gativan has offered salvage and replaced parts to customer. Unclaimed salvage will be disposed of without any liability and without any further information to the customer.",
  "Customer declares that the vehicle is delivered to him by the dealer in the same condition as received from him, along with Gativans authorized repair's / servicing, and all the belongings of the customer have been found intact.",
  " All disputes shall be subject to exclusive jurisdiction of the courts in the city where this dealer operates.",
  "Warranty of the goods is offered to the extent covered under the warranty policy of the manufacturer.",
  "Details of invoice should be quoted on all correspondences, advises, challans etc. relating to the invoice.",
  "Customer agrees to receive Call/SMS/E-mail/WhatsApp from Gativan related to Services, promotions & surveys.",
];

export default function Invoice({ saRemark }) {
  const [orderData, setOredrData] = useState([]);
  const [invoiceObj, setInvoiceObj] = useState();
  const [services, setServices] = useState([]);

  const { orderId } = useParams();

  const baseURL = http.defaults.baseURL;

  const {
    updateObj,
    setUpdateObj,
    updateUserName,
    setUpdateUserName,
    // orderId,
    OrderInfo,
  } = useContext(jobCardContext);

  let totalTaxableAmount = 0;
  let totalCGST = 0;
  let totalSGST = 0;
  let totalDiscount = 0;
  let FinalTotal = 0;
  let totalPrice = 0;
  let totalQuantity = 0;

  const Data = [
    {
      service: "Bike Restart Service",
      HSNCode: 144,
      quntity: 2,
      price: 150,
      Discount: 50,
    },
    {
      service: "car basic Service",
      HSNCode: 184,
      quntity: 1,
      price: 12150,
      Discount: 150,
    },
  ];

  useMemo(() => {
    const area = OrderInfo?.address?.area;
    const landmark = OrderInfo?.address?.landmark;
    const city = OrderInfo?.address?.city;

    setInvoiceObj({
      customerName: `${OrderInfo?.user?.first_name} ${OrderInfo?.user?.last_name}`,
      mobileNumber: OrderInfo?.user?.mobile_number,
      vehicleDetails: `${OrderInfo?.vehicle?.brand?.brand} ${OrderInfo?.vehicle?.model?.model}`,
      vehicleImage: OrderInfo?.vehicle?.model?.imageUrl,
      vehicleNumber: OrderInfo?.vehicle?.vehicleNumber,
      address: `${
        typeof landmark !== "undefined" && landmark !== null ? landmark : ""
      }
      ${typeof area !== "undefined" && area !== null ? area : ""}
      ${typeof city !== "undefined" && city !== null ? landmark : ""}`,
      orderDate: OrderInfo?.serviceBookingDate,
    });
    setServices(OrderInfo?.services);
  }, [OrderInfo]);

  const [invoiceDetails, setInvoiceDetails] = useState([]);

  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const customerInvoiceReport = async () => {
    const config = {
      header: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
    };

    try {
      const response = await getCustomerInvoice(orderId);
      const data = await response.data;
      setInvoiceDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const orderStatus = OrderInfo?.orderStatus?.status;
  useEffect(() => {
    customerInvoiceReport();
  }, [orderStatus]);

  const ksDetails = {
    companyName: "KStudio Solutions Private Limited",
    address: "Office No 802 Tower 2 World Trade center Kharadi Pune",
    gstn: "27AAGCK1784J1Z6",
    panNo: "AAGCK1784J",
  };

  return (
    <Box className="invoiceContaine">
      <Box className="invoice-header">
        <Box className="invoice-headeBox">
          <Typography className="invoice-heading">Tax Invoice</Typography>
          <Typography className="invoice-number">
            Invoice No. :{" "}
            {invoiceDetails?.invoiceId != undefined
              ? invoiceDetails?.invoiceId
              : "------"}
          </Typography>
        </Box>
        {/* <Typography className="header-heading">GST Invoice</Typography> */}
        <Box className="head-logo">
          <Box className="Gativanlogo">
            <img
              src={
                process.env.PUBLIC_URL +
                "/Assets/logos/GativanFullLogoWhite.png"
              }
              alt=""
            />
          </Box>
        </Box>
      </Box>
      <Typography className="recipientHeading">
        Original for Recipient
        {/* {saRemark} */}
      </Typography>

      <Box className="userAndCompanyDetails">
        <Box className="leftSide-headSection-wrapper">
          <Typography className="user-invoice-label">
            Details of Receiver
          </Typography>

          <Box className="leftSide-headSection">
            <Box className="userInfo">
              <Typography className="user-invoice-label">
                {invoiceDetails?.customerName !== undefined
                  ? invoiceDetails?.customerName
                  : "------"}
              </Typography>
              <Typography className="user-invoice-field">
                {invoiceObj?.vehicleDetails}
              </Typography>
              <Typography className="user-invoice-field">
                {invoiceDetails?.vehicleNumber}
              </Typography>
              <Typography className="user-invoice-label">Mobile</Typography>
              <Typography className="user-invoice-field">
                {invoiceDetails?.mobileNumber}
              </Typography>
            </Box>
            <Box className="Dates">
              <Typography className="user-invoice-label">
                Order Date:
              </Typography>
              <Typography className="user-invoice-field">
                {invoiceDetails?.orderDate}
              </Typography>

              <Typography className="user-invoice-label">
                Invoice Date:
              </Typography>
              <Typography className="user-invoice-field">
                {invoiceDetails?.invoiceDate}
              </Typography>
            </Box>
            <Box className="billDetails">
              <Box className="billDetails-topSection">
                <Typography className="user-invoice-label">
                  Billing Address :{" "}
                </Typography>
                <Typography className="user-invoice-field">
                  {invoiceDetails?.customerAddress?.split(",")[0]?.trim() !==
                  "null"
                    ? invoiceDetails?.customerAddress
                    : "----"}
                </Typography>
                <Typography className="user-invoice-label">GSTIN :</Typography>
                <Typography className="user-invoice-field">
                  {updateUserName?.gstn}
                </Typography>
              </Box>

              <Box className="billDetails-bottomSection">
                <Typography className="user-invoice-label">
                  Shipping Address:
                </Typography>
                <Typography className="user-invoice-field">
                  {invoiceDetails?.customerAddress?.split(",")[0]?.trim() !==
                  "null"
                    ? invoiceDetails?.customerAddress
                    : "----"}
                </Typography>
                <Typography className="user-invoice-label">GSTIN :</Typography>
                <Typography className="user-invoice-field">
                  {updateUserName?.gstn}
                </Typography>
              </Box>
            </Box>

            <Box className="vehicleImage">
              <Box>
                {/* <img src={baseURL + invoiceDetails?.vehicleImage} alt="" /> */}
                <img
                  className="user-invoice-vehicle-image"
                  src={baseURL + invoiceObj?.vehicleImage}
                  alt=""
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="rightSide-headSection">
          <Box className="companyDetails">
            <Typography className="user-invoice-label">Sold By</Typography>
            <Typography className="user-invoice-field">
              {ksDetails.companyName}
            </Typography>

            <Typography className="user-invoice-field">
              {ksDetails?.address}
            </Typography>
            <Typography className="user-invoice-label">PAN No:</Typography>
            <Typography className="user-invoice-field">
              {ksDetails?.panNo}
            </Typography>
            <Typography className="user-invoice-label">GSTIN:</Typography>
            <Typography className="user-invoice-field">
              {ksDetails?.gstn}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="user-invoice-main-container">
        <Box className="user-invoice-table-section">
          <table className="user-invoice-table">
            <thead>
              <tr className="user-invoice-table-header">
                <th rowspan="2" scope="col" width="150px">
                  Description
                </th>
                <th rowspan="2">HSN Code</th>
                <th rowspan="2">Qty.</th>
                <th rowspan="2">Unit Price</th>
                <th rowspan="2">Discount</th>
                <th rowspan="2">Taxable Value</th>
                <th colSpan="2">CGST</th>
                <th colSpan="2">SGST</th>
                <th rowspan="2">Amount with tax</th>
              </tr>
              <tr>
                <th>Rate</th>
                <th>Amount</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceDetails?.items?.map((item) => {
                const {
                  discount,
                  gstAmount,
                  gstRate,
                  hsn,
                  id,
                  invoiceId,
                  price,
                  qty,
                  service,
                  taxableAmount,
                  total,
                } = item;
                let CGST = gstAmount.toFixed(2);
                let SGST = gstAmount.toFixed(2);
                totalTaxableAmount += taxableAmount;
                totalCGST += Number(CGST);
                totalSGST += Number(SGST);
                FinalTotal += total;
                totalDiscount += discount;
                totalPrice += price;
                totalQuantity += qty;
                return (
                  <tr>
                    <td style={{ textAlign: "start" }}>{service}</td>
                    <td>{hsn}</td>
                    <td>{qty}</td>
                    <td>{price}</td>
                    <td>{discount}</td>
                    <td>{taxableAmount.toFixed(2)}</td>
                    <td>{Math.round(gstRate / 2).toFixed(2)}</td>
                    <td>{CGST}</td>
                    <td>{Math.round(gstRate / 2).toFixed(2)}</td>
                    <td>{SGST}</td>
                    <td>{total.toFixed(2)}</td>
                  </tr>
                );
              })}
              <tr className="tablefooter">
                <td colSpan="2">Total</td>
                <td>{totalQuantity}</td>
                <td>{totalPrice.toFixed(2)}</td>
                <td>{totalDiscount}</td>
                <td>{totalTaxableAmount.toFixed(2)}</td>
                <td colSpan="2">{totalCGST.toFixed(2)}</td>
                <td colSpan="2">{totalSGST.toFixed(2)}</td>
                <td>{FinalTotal.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box className="user-invoice-calculation-section">
          <Box className="finalCalculation">
            <Box sx={{ padding: "0px 10px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="user-invoice-calculation-label">
                  Total Amount before Tax :{" "}
                </Typography>
                <Typography className="user-invoice-calculation-filed">
                  {totalTaxableAmount.toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="user-invoice-calculation-label">
                  Add .CGST :{" "}
                </Typography>
                <Typography className="user-invoice-calculation-filed">
                  {totalCGST.toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="user-invoice-calculation-label">
                  Add .SGST :{" "}
                </Typography>
                <Typography className="user-invoice-calculation-filed">
                  {totalSGST.toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Box className="bottomBorder1"></Box>

            <Box
              sx={{
                padding: "0px 10px",
                backgroundColor: "rgba(235, 235, 235, 0.789)",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="user-invoice-totalAmount-label">
                  Total Amount : GST :{" "}
                </Typography>
                <Typography className="user-invoice-totalAmount-filed">
                  {(totalCGST + totalSGST).toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="user-invoice-totalAmount-label">
                  Total Amount After Tax :{" "}
                </Typography>
                <Typography className="user-invoice-totalAmount-filed">
                  {FinalTotal.toFixed(2)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="user-invoice-totalAmount-label"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "12px",
                    fontWeight: "600",
                    marginTop: "5px",
                    textTransform: "capitalize",
                  }}
                >
                  {convertor.toWords(FinalTotal)} Rupees.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box className="bottomBorder2"></Box>

          <Box className="sa-remarks-wrapper">
            <Typography className="sa-remarks-label">SA Remarks:</Typography>
            <Typography className="sa-remarks">{saRemark}</Typography>
          </Box>

          <Box className="bottomBorder2"></Box>
          <Box display="flex" marginTop="10px">
            {/* <Box sx={{ width: "50%" }}></Box> */}
            <Box sx={{ width: "100%" }}>
              <Typography
                // sx={{
                //   fontSize: "12px",
                //   fontWeight: "250",
                //   padding: "0px 0.4rem",
                // }}
                className="termsnconditions"
              >
                Terms & Conditions:
              </Typography>
              <ol className="tnc-list">
                {termsandconditions?.map((item, index) => {
                  return <li className="tnc-list-items" key={index}>{item}</li>;
                })}
              </ol>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
