import { Box, Button } from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DataGridStyle } from "../../../components/tables/DataGridStyles";
import {
  getAllInsuranceCompanies,
  getInsuranceCompaniesById,
  postInsuranceCompany,
  updateInsuranceCompany,
} from "../../../services/Services";
import { useSelector } from "react-redux";
import PaginationTable from "../../../components/tables/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../../components/headers/ModuleHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteConfigModal from "../DeleteConfigModal";
import Form from "../../../components/forms/Form";
import { CommonContextAPI } from "../../../App";
import { AdminLogin } from "../../../reducer/LoginReducer";

const CustomToolbar = () => {
  const propsData = useSelector((state) => state?.CommonData);
  const { clearAppliedFilters } = propsData;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px",
      }}
    >
      <GridToolbarQuickFilter />
      <div sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          // className={classes.clearButton}
          style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

const renderForm = [
  {
    label: "name",
    fields: [
      {
        label: "Company Name",
        field: "TextField",
        name: "name",
        id: "name",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "gstn",
    fields: [
      {
        label: "GST Number",
        field: "TextField",
        name: "gstn",
        id: "gstn",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "Pan No",
    fields: [
      {
        label: "Pan No",
        field: "TextField",
        name: "pan",
        id: "pan",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "Shop Number",
    fields: [
      {
        label: "Shop Number",
        field: "TextField",
        name: "shopNumber",
        id: "shopNumber",
        type: "text",
        grids: 6,
      },
    ],
  },
  {
    label: "Landmark",
    fields: [
      {
        label: "Landmark",
        field: "TextField",
        name: "landmark",
        id: "landmark",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "Area",
    fields: [
      {
        label: "Area",
        field: "TextField",
        name: "area",
        id: "area",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "City",
    fields: [
      {
        label: "City",
        field: "TextField",
        name: "city",
        id: "city",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "State",
    fields: [
      {
        label: "State",
        field: "TextField",
        name: "state",
        id: "state",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "Pin Code",
    fields: [
      {
        label: "Pin Code",
        field: "TextField",
        name: "pincode",
        id: "pincode",
        type: "text",
        grids: 12,
      },
    ],
  },
  {
    label: "Country",
    fields: [
      {
        label: "Country",
        field: "TextField",
        name: "country",
        id: "country",
        type: "text",
        grids: 12,
      },
    ],
  },
];
export default function AllCompanies() {
  const [isRemoveConfigModal, setIsRemoveConfigModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isEditCompany, setIsEditCompany] = useState(false);
  const [AllCompanies, setAllCompanies] = useState([]);
  const [data, setData] = useState([]);
  const [updateObj, setUpdateObj] = useState();

  const Navigate = useNavigate();

  const commonData = useContext(CommonContextAPI);
  const { addCompany, setAddCompany } = commonData;

  const AdminRole = useSelector((state) => state.AdminLogin.role);

  useEffect(() => {
    if (
      AdminRole.toLowerCase() == "super admin" ||
      AdminRole.toLowerCase() == "admin"
    ) {
      setIsAdmin(false);
    }
  }, [AdminRole]);
 
  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  useMemo(() => {
    const result = AllCompanies?.map((i) => {
      return {
        ...i,
        shopNumber: i?.shopNumber ? i?.shopNumber?.split(",").join(" ") : " ",
        landmark: i?.landmark ? i?.landmark?.split(",").join(" ") : " ",

        area: i?.area ? i?.area?.split(",").join(" ") : " ",
      };
    });
    setData(result);
  }, [AllCompanies]);

  const getAllCompanies = async () => {
    try {
      const request = await getAllInsuranceCompanies();
      const response = await request.data;
      setAllCompanies(response.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Compony Name",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },

    {
      field: "gstn",
      headerName: "GST Number",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "pan",
      headerName: "Pan Number",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "shopNumber",
      headerName: "Shop Number",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "landmark",
      headerName: "Landmark",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "area",
      headerName: "Area",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      // "superadmin", "admin"
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      editable: true,
      hide:isAdmin,
      Width: "10px",
      minWidth: 10,
      // flex: 1,
      renderCell: (params) => {
        let name = params?.row?.name?.split(" ").join("_");
        return (
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              getCompanyInfo(params?.id);
              setIsEditCompany(true);
            }}
          >
            <EditIcon />
          </Box>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      headerClassName: "super-app-theme--header",
      editable: true,
      hide:isAdmin,
      minWidth: 10,
      renderCell: (par) => {
        return (
          <DeleteForeverIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              setIsRemoveConfigModal(true);
            }}
          />
        );
      },
    },
  ];
  // ----Edit company-----------
  const getCompanyInfo = async (id) => {
    try {
      const request = await getInsuranceCompaniesById(id);
      const response = await request.data;
      setUpdateObj(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    try {
      const request = await updateInsuranceCompany(updateObj);
      setIsEditCompany(false);
    } catch (error) {
      console.log(error);
    }
  };

  // ---- Add new Company -------------------

  const handleAddCompany = async () => {
    try {
      const request = await postInsuranceCompany(updateObj);
      setAddCompany(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box className="comp-container">
      <ModuleHeader heading="Insurance Companies" />
      <Box className="data-grid-container">
        {isRemoveConfigModal && (
          <DeleteConfigModal
            isRemoveConfigModal={isRemoveConfigModal}
            setIsRemoveConfigModal={setIsRemoveConfigModal}
          />
        )}
        {addCompany && (
          <Form
            heading="Edit Company"
            renderForm={renderForm}
            closeModal={() => setAddCompany(false)}
            state={updateObj}
            setState={setUpdateObj}
            submit={handleAddCompany}
          />
        )}
        {isEditCompany && (
          <Form
            heading="Edit Company"
            closeModal={() => setIsEditCompany(false)}
            renderForm={renderForm}
            state={updateObj}
            setState={setUpdateObj}
            submit={handleUpdate}
            isEdit={true}
          />
        )}
        <DataGrid
          columns={columns}
          rows={data}
          sx={DataGridStyle}
          components={{
            Footer: FooterSection,
            Toolbar: CustomToolbar,
          }}
          pageSize={50}
        />
      </Box>
    </Box>
  );
}
