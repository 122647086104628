import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import moment from "moment";

import { useSelector } from "react-redux";
import { CommonContextAPI } from "../../../App";
import { getOrdersalses } from "../../../services/dashboard/DashboardServices";
import LoaderSpinner from "../../loading/LoaderSpinner";
Chart.register(CategoryScale);

const oredrSalesOptions = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      anchor: "end",
      align: "top",
      offset: 5,
    },
    title: {
      display: true,
      text: "Sales",
      font: {
        size: 20,
      },
      padding: {
        bottom: 20,
      },
      // color: "#000",
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        minTicksLimit: 0,
        maxTicksLimit: 10,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        minTicksLimit: 0,
        maxTicksLimit: 5,
      },
    },
  },
  layout: {
    padding: {
      top: 20,
    },
  },
};

export default function SalesChart({ filterButtons }) {
  const [orderSales, setOrderSales] = useState([]);
  const [salesGroup, setSalesGroup] = useState("week");
  const { activeButton, inactiveButton } = filterButtons;
  const [isLoading, setIsLoading] = useState(true);

  const filterDate = useSelector((state) => state?.DateRange_Reducer);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  const { filteredDate, defaultDate } = commonData;

  const searchData = useSelector((state) => state.LeadsReducer);

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const oredrSalesData = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    labels: orderSales?.map((i) => {
      let date = new Date(i.date);
      let prefixes = ["W1 ", "W2 ", "W3 ", "W4 ", "W5 "];
      let prefixesDeci = [1, 2, 3, 4, 5];

      if (salesGroup === "week") {
        let wee;
        if (orderSales.length <= 5) {
          wee =
            moment(i.date).format("MMM") +
            " " +
            prefixes[Math.floor(date.getDate() / 7)];
        } else if (orderSales.length <= 10) {
          wee =
            prefixes[Math.floor(date.getDate() / 7)] +
            " " +
            moment(i.date).format("MMM");
        } else {
          wee =
            prefixes[Math.floor(date.getDate() / 7)] +
            " " +
            moment(i.date).format("MMM YY");
        }
        return wee;
      } else if (salesGroup === "month") {
        let mon;
        if (orderSales.length <= 12) {
          mon = moment(i.date).format("MMM YYYY");
        } else {
          mon = moment(i.date).format("MMM YY");
        }
        return mon;
      } else if (salesGroup === "year") {
        return moment(i.date).format("YYYY");
      }
    }),
    datasets: [
      {
        //   label: "false",
        // data: [33, 53, 85, 200, 44, 65],
        data: orderSales?.map((i) => i.amount),
        fill: false,
        PointerEvent: 1,
        // borderWidth: 1.5,
        backgroundColor: "#7B8BD4",
        // borderColor: "#7B8BD4",
        borderRadius: 10,
      },
    ],
  };
  const getOrderSalesInfo = async (salesGroup) => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        from: startDate,
        to: endDate,
        groupBy: salesGroup,
      },
    };
    try {
      // const request = await getOrdersalses(filterDate, salesGroup, config);
      const request = await getOrdersalses(config);
      const response = await request.data;
      setOrderSales(response.result);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (filteredDate || defaultDate) {
      getOrderSalesInfo(salesGroup);
      setIsLoading(true);
    }
  }, [filteredDate, defaultDate, salesGroup]);
  return (
    <div className="salesSection">
      <div className="sales-header">
        <div className="sales-title-section">
          <p>Overview</p>
          <h6>Sales</h6>
        </div>
        <div className="filter-section">
          <button
            style={salesGroup === "year" ? activeButton : inactiveButton}
            onClick={(e) => {
              setSalesGroup("year");
              // handelMainFilterButtons(e);
            }}
          >
            Year
          </button>
          <button
            style={salesGroup === "month" ? activeButton : inactiveButton}
            onClick={(e) => {
              setSalesGroup("month");
              // handelMainFilterButtons(e);
            }}
          >
            Month
          </button>
          <button
            style={salesGroup === "week" ? activeButton : inactiveButton}
            onClick={(e) => {
              setSalesGroup("week");
              // handelMainFilterButtons(e);
            }}
          >
            Week
          </button>
        </div>
      </div>
      {isLoading ? (
          <LoaderSpinner />
      ) : (
        <div className="chartContainer">
          <Bar
            data={oredrSalesData}
            plugins={[ChartDataLabels]}
            options={oredrSalesOptions}
          />
        </div>
      )}
    </div>
  );
}
