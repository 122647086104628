export const DataGridStyle = {
  dataGridConatiner: {
    height: "90%",
    marginTop: "30px",
    backgroundColor: "white",
    borderRadius: "6px",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    color: "white",
    fontFamily: "AvenirDemi",
    fontSize: "16px",
    // fontWeight: '900 !important',
  },
  ".MuiDataGrid-cellContent": {
    fontFamily: "AvenirReguler",
  },
  ".MuiDataGrid-sortIcon": {
    opacity: 1,
    color: "white",
  },
  ".MuiDataGrid-menuIconButton": {
    opacity: 1,
    color: "white",
  },
  //   rows styling
  "& .Rows": {
    fontFamily: "AvenirRegular !important",
    fontSize: "16px",
    fontWeight: "400",
  },
  "& .super-app-theme--header": {
    backgroundColor: "#122164",
  },

  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
    {
      backgroundColor: "#122164",
      textAlign: "center !important",
      padding: "1rem 0.6rem !important",
      "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
};
