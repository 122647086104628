import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Profile from "./Profile";

const MyAccount = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname == "/myAccount") navigate("/myAccount/profile");
  }, [, window.location.href]);

  return (
    <Box>
      <Outlet />
    </Box>
  );
};

export default MyAccount;
