import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  Button,
  Fade,
  CircularProgress,
  ButtonBase,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MenuIcon from "@material-ui/icons/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SortIcon from "@mui/icons-material/Sort";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  getVehicleType,
  getBrands,
  getModels,
  getVariants,
} from "../../services/leadServices/LeadServices";
import AddKeywordsForm from "./AddKeywordsForm";
import "./Catalogue.css";

import {
  getServiceCategories,
  getServiceSubCategories,
  getServicePrices,
  getServicesByCategoryId,
} from "../../services/catalogueServices/CatalogueServices";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Divider } from "@mui/material";
import httpCommon from "../../http-common";
import VehicleModal from "./VehicleModal";
import LoaderSpinner from "../../components/loading/LoaderSpinner";
import CircleIcon from "@mui/icons-material/Circle";
import AddCustomServices from "./AddCustomServices";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    padding: "0px 1rem",
  },
  gridContainer: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      // marginTop: "3rem",
      height: "auto",
    },
  },
  searchbarContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
  },
  inputInput: {
    fontSize: "1.2rem",
    padding: "0px 5px",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
    height: "auto",
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "1rem !important",
    },
    [theme.breakpoints.up("md")]: {
      height: "93vh",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  fixedFilterBox: {
    // padding: "4.6rem 1rem 0px",
    backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      width: "17%",
      height: "auto",
      overflow: "auto",
      // padding: "1rem, 0px !important",
      padding: "4.6rem 0.1rem 0px",
    },
    [theme.breakpoints.up("lg")]: {
      position: "fixed",
      width: "15%",
      height: "auto",
      overflow: "auto",
      // padding: "1rem, 0px !important",
      padding: "4.6rem 0rem 0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "2.6rem 1rem 0px",
      margin: "0px 1rem",
    },
  },
  filterBox: {
    padding: "0px 0.1rem",
    [theme.breakpoints.up("lg")]: {
      padding: "0px 1rem",
    },
  },
  categoryList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.only("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  searchButton: {
    width: "100%",
    backgroundColor: "#122164",
    padding: "7px 10px",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      margin: "0.5rem 0px",
    },
  },
  filterButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0.5rem 0px",
    },
  },
  mainContainer: {
    marginTop: "2.5rem",
    [theme.breakpoints.up("md")]: {
      // borderLeft: "1px solid grey",
      minHeight: "90vh",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0rem !important",
      marginTop: "0.5rem",
    },
  },
  searchBoxWrapper: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: "#E5E5E5",
    border: "2px solid #ccc",
    color: "black",
    // boxShadow: "0px 0px 5px gray",
    marginLeft: 0,
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "350px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
    },
  },
  vehicleType: {
    margin: "1rem 0px",
  },
  vehicleTypesWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  serviceButtons: {
    margin: "0.1rem 0px",
    color: "#fff",
    fontSize: "0.9rem",
    color: "#000",
    boxShadow: "none",
    display: "flex !important",
    alignItems: "flex-start !important",
    flexDirection: "row !important",
    justifyContent: "flex-start !important",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem 0px",
      fontSize: "0.8rem",
    },
    [theme.breakpoints.only("md")]: {
      width: "90%",
      fontSize: "0.766rem",
      margin: "0px",
      padding: "10px 4px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "95%",
      fontSize: "0.766rem",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  filters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  filterItem: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0.5rem 0px",
    },
  },
  selectDropdown: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      paddingTop: "0.7rem !important",
      paddingLeft: "0.5rem !important",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
  },
  icons: {
    margin: "0px 0.5rem",
  },
  expandIcon: {
    fontSize: "2rem",
  },
  services: {
    width: "100%",
    padding: "2rem 1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0rem",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  categoryTitle: {
    fontSize: "1.5rem",
    margin: "0.5rem 0px",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "1rem",
      justifyContent: "space-around",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginBottom: "1rem",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      marginBottom: "1rem",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  servicePricessWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  serviceHeading: {
    marginLeft: "0.5rem",
    fontSize: "1.2rem",
    wordWrap: "break-word !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      wordWrap: "break-word !important",
      display: "inline-block",
    },
  },
  serviceRate: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginLeft: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  image: {
    width: "80px",
    height: "80px",
    padding: "0.3rem",
    borderRadius: "50px",
    boxShadow: "0px 0px 3px black",
    [theme.breakpoints.only("sm")]: {
      width: "70px",
      height: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  },
  AccordionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      // minHeight: "40px",
      // height: "40px",
    },
  },
  box: {
    "& .$MuiAccordionSummary-root": {
      margin: "1.2rem 0px 0px",
      padding: "0.8rem 0.4rem 0px",
      // width: "100%",
    },
  },
  checkIcon: {
    color: "#51ae3a",
    marginRight: "0.5rem",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  serviceDetails: {
    padding: "1rem 0px",
    [theme.breakpoints.up("sm")]: {
      // width: "70%",
    },
  },
  "& li": {
    fontSize: "1rem",
  },
  serviceActivities: {
    [theme.breakpoints.up("sm")]: {
      // width: "70%",
    },
  },
  radioButton: {
    color: "#122164 !important",
  },
}));

function Catalogue() {
  const classes = useStyles();
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.only("md"));
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [servicesCategories, setServicesCategories] = useState([]);
  const [servicesSubCategories, setServicesSubCategories] = useState([]);
  const [servicesPrices, setServicesPrices] = useState([]);
  const [filterData, setFilterData] = useState({});

  const [loading, setLoading] = React.useState(true);
  const [openAddKeyword, setOpenAddKeyword] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [hasServices, setHasServices] = useState(true);

  const [vehicleTypeId, setVehicleTypeId] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [modelId, setModelId] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [activeButton, setActiveButton] = useState("");
  const [showServices, setShowServices] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [isCustomServiceAdded, setIsCustomServiceAdded] = useState(false);
  const [isOpenAddCustomServiceModal, setIsOpenAddCustomServiceModal] =
    useState(false);
  const [isAdminLogged, setIsAdminLogged] = useState(false);

  const [isShowServices, setIsShowServices] = useState(false);

  const [openVehicleModal, setOpenVehicleModel] = useState(false);
  const [applyFilters, setApplyFilters] = useState({
    filter: "",
    isApplied: false,
  });

  const openCustomServiceModal = () => {
    setIsOpenAddCustomServiceModal(true);
  };

  const closeCustomServiceModal = () => {
    setIsOpenAddCustomServiceModal(false);
  };

  const hanldeOpenVehicleModal = () => {
    setOpenVehicleModel(true);
    setVehicleDetails({
      ...vehicleDetails,
      vehicleTypeId: null,
      categoryId: null,
    });
  };

  const hanldeCloseVehicleModal = () => {
    setOpenVehicleModel(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));

  useEffect(() => {
    const adminRoles = ["admin", "superadmin"];
    if (
      adminRoles.includes(
        loginDetails?.role?.replaceAll(" ", "")?.toLowerCase()
      )
    ) {
      setIsAdminLogged(true);
    } else {
      setIsAdminLogged(false);
    }
  }, [, loginDetails]);

  const handleClick = (fieldName, id, name) => {
    switch (fieldName) {
      case "vehicleType":
        setVehicleTypeId(id);
        break;
      case "vehicleBrand":
        setBrandId(id);
        break;
      case "vehicleModel":
        setModelId(id);
        break;
      case "vehicleVariant":
        setVariantId(id);
        break;

      case "category":
        setCategoryId(id);
        break;
    }

    setFilterData(
      fieldName == "vehicleBrand"
        ? {
            ...filterData,
            [fieldName]: { id: id, name: name },
            vehicleModel: null,
            vehicleVariant: null,
            category: null,
          }
        : fieldName == "vehicleModel"
        ? {
            ...filterData,
            [fieldName]: { id: id, name: name },
            vehicleVariant: null,
            category: null,
          }
        : fieldName == "vehicleVariant"
        ? {
            ...filterData,
            [fieldName]: { id: id, name: name },
            category: null,
          }
        : {
            ...filterData,
            [fieldName]: { id: id, name: name },
          }
    );
    setOpenAddKeyword(false);
    setActiveButton(name);
  };

  const URL = httpCommon.defaults.baseURL;

  // Get Vehicle Types
  const getVehicleTypes = async () => {
    try {
      const response = await getVehicleType();
      const data = await response.data;
      setVehicleTypes(data.result);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // Get Vehicle Brands
  const getVehicleBrands = async () => {
    try {
      const response = await getBrands(vehicleTypeId);
      const data = await response.data;
      // setVehicleBrands(data.result);

      const sortedBrands = data.result?.sort((a, b) =>
        a.brand > b.brand ? 1 : b.brand > a.brand ? -1 : 0
      );
      setVehicleBrands(sortedBrands);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // Get Vehicle Models

  const getVehicleModels = async () => {
    try {
      const response = await getModels(vehicleTypeId, brandId);
      const data = await response.data;
      setVehicleModels(data.result);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // Get Vehicle Models

  const getVehicleVariants = async () => {
    try {
      const response = await getVariants(modelId);
      const data = await response.data;
      setVehicleVariants(data.result);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // Get Service Datails
  const serviceCategoryId = filterData.category && filterData.category.id;

  // Get Service Categories
  const serviceCategories = async (vehicleTypeId) => {
    try {
      const response = await getServiceCategories(vehicleTypeId);
      const data = await response.data;
      setServicesCategories(data.result);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // Services by Category Id

  const servicesByCategory = async () => {
    try {
      const response = await getServicesByCategoryId(categoryId);
      const data = await response.data;
      setServicesPrices(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle service category
  const [prev, setPrev] = useState("");

  const userSelection = (fieldName, data) => {
    setVehicleDetails({
      ...vehicleDetails,
      categoryId: data?.categoryId,
      category: data?.category,
    });
    setPrev(data?.category);
    if (activeButton !== data?.category) {
      setServicesPrices([]);
      setIsShowServices(true);
      setActiveButton(data?.category);
    } else {
      setIsShowServices(!isShowServices);
      prev == data?.category
        ? setActiveButton(data?.category)
        : setActiveButton("");
    }
  };

  useEffect(() => {
    setCategoryId(servicesCategories[0]?.categoryId);
    !isMobile && setActiveButton(servicesCategories[0]?.category);
  }, [servicesCategories]);

  useEffect(() => {
    vehicleBrands?.length > 0 &&
    vehicleModels?.length > 0 &&
    vehicleVariants?.length > 0 &&
    categoryId
      ? servicePrices(categoryId, modelId, variantId)
      : categoryId && servicesByCategory();
  }, [categoryId]);

  // Get Service Sub Categories

  const serviceSubCategories = async () => {
    try {
      const response = await getServiceSubCategories(serviceCategoryId);
      const data = await response.data;
      setServicesSubCategories(data.result);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // Get Service Prices

  const servicePrices = async (categoryId, modelId, variantId) => {
    try {
      const response = await getServicePrices(categoryId, modelId, variantId);
      const data = await response.data;
      setServicesPrices(data.result);
      setFilteredService(data.result);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };

  // call Vehicle Types
  useEffect(() => {
    setVehicleTypeId(1);
    getVehicleTypes();
  }, []);

  // call Vehicle Brands
  useEffect(() => {
    setVehicleModels([]);
    setVehicleVariants([]);
    vehicleTypeId && getVehicleBrands();
  }, [vehicleTypeId]);

  // call Vehicle Models
  useMemo(() => {
    setVehicleModels([]);
    setVehicleVariants([]);
    brandId && getVehicleModels();
  }, [brandId]);

  // call Vehicle variant
  useMemo(() => {
    setVehicleVariants([]);
    modelId && getVehicleVariants();
  }, [modelId]);

  useEffect(() => {
    vehicleTypeId && serviceCategories(vehicleTypeId);
  }, [, vehicleTypeId]);

  const onSearch = () => {
    setServicesCategories([]);
    setServicesPrices([]);

    filterData?.vehicleBrand &&
      filterData?.vehicleModel &&
      filterData?.vehicleVariant &&
      vehicleTypeId &&
      serviceCategories(vehicleTypeId);
    servicePrices(categoryId, modelId, variantId);
  };

  // Get service on Mobile

  useEffect(() => {
    vehicleDetails?.vehicleTypeId &&
      serviceCategories(vehicleDetails?.vehicleTypeId);
  }, [vehicleDetails?.variantId]);

  useEffect(() => {
    const { vehicleTypeid, brandId, modelId, variantId, categoryId, category } =
      vehicleDetails;
    categoryId && servicePrices(categoryId, modelId, variantId);
  }, [vehicleDetails?.categoryId]);

  // Search keyword
  const openAddKeywordsForm = (service, serviceId) => {
    setSelectedService({});
    setOpenAddKeyword(true);
    setSelectedService({ service: service, serviceId: serviceId });
  };

  //------------------------------------------- Apply Filters ------------------------------------------
  const [currentSort, setCurrentSort] = useState();
  const [sortServiceBy, setSortServiceBy] = useState({});
  const [filterServiceByCategory, setFilterServiceByCategory] = useState({});
  const [filterServices, setFilteredService] = useState([]);

  const sortingArr = [
    {
      id: 1,
      sort: "Price Low to High",
    },
    {
      id: 2,
      sort: "Price High to Low",
    },
    {
      id: 3,
      sort: "Descount",
    },
    {
      id: 4,
      sort: "Customer Retings",
    },
  ];

  const hanldeSortItem = (item) => {
    setCurrentSort(item?.id);
    setSortServiceBy(item);
  };

  const handleFilterItem = (item) => {
    setActiveButton(item?.category);
    setFilterServiceByCategory(item);
  };

  const getFilteredData = () => {
    setApplyFilters({
      ...applyFilters,
      isApplied: false,
    });
  };

  useEffect(() => {
    if (applyFilters?.filter == "sort") {
      if (Object.keys(sortServiceBy).length != 0) {
        console.log("sortServiceBy", sortServiceBy);
      }
    } else {
      if (Object.keys(filterServiceByCategory).length != 0) {
        console.log("filterServiceByCategory", filterServiceByCategory);
      }
    }
  }, [applyFilters]);

  const handleOpenApplyFilters = (filter) => {
    setCurrentSort();
    setActiveButton();
    setSortServiceBy({});
    setFilterServiceByCategory({});
    setApplyFilters({
      filter: filter,
      isApplied: true,
    });
  };

  const handleCloseApplyFilters = () => {
    setCurrentSort();
    setActiveButton();
    setSortServiceBy({});
    setFilterServiceByCategory({});
    setApplyFilters({
      filter: "",
      isApplied: false,
    });
  };
  return (
    <Box className={classes.container}>
      {isOpenAddCustomServiceModal && (
        <AddCustomServices
          setIsOpenAddCustomServiceModal={setIsOpenAddCustomServiceModal}
          closeCustomServiceModal={closeCustomServiceModal}
        />
      )}
      {!isMobile ? (
        <Grid container spacing={2} classes={classes.gridContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={2}
            className={classes.filtersContainer}
          >
            <Box className={classes.fixedFilterBox}>
              <Box
                className={classes.filterBox}
                style={{
                  height: isMobile ? "auto" : "88vh",
                  overflow: "auto",
                }}
              >
                <Box className={classes.searchbarContainer}>
                  <div className={classes.searchBoxWrapper}>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </div>
                  </div>
                </Box>
                <Box className={classes.vehicleType}>
                  <h2>Vehicle Type</h2>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="vehicleType"
                      name="vehicleType"
                      value={filterData.vehicleType}
                      className={classes.vehicleTypesWrapper}
                      defaultValue="Car"
                      // onChange={handleChange}
                    >
                      {vehicleTypes &&
                        vehicleTypes.map((type, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              value={type && type.vehicleType}
                              control={
                                <Radio
                                  className={classes.radioButton}
                                  onClick={() =>
                                    handleClick(
                                      "vehicleType",
                                      type && type.id,
                                      type && type.vehicleType
                                    )
                                  }
                                />
                              }
                              label={type && type.vehicleType}
                            />
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                </Box>
                {!smallPhone && (
                  <Box className={classes.categories}>
                    <h2 className={classes.categoryTitle}>
                      Service Categories
                    </h2>
                    <div className={classes.categoryList}>
                      {servicesCategories.length > 0 ? (
                        servicesCategories.map((service) => {
                          return (
                            <Button
                              key={service.id}
                              variant="contained"
                              name="category"
                              className={classes.serviceButtons}
                              size="small"
                              onClick={() =>
                                handleClick(
                                  "category",
                                  service.categoryId,
                                  service.category
                                )
                              }
                              style={{
                                backgroundColor:
                                  service.category != ""
                                    ? service.category === activeButton
                                      ? "#122164"
                                      : isTablet
                                      ? "#7B8BD4"
                                      : "#fff"
                                    : "#122164",
                                color:
                                  service.category === activeButton
                                    ? "#fff"
                                    : "#000",
                              }}
                            >
                              {service.category}
                            </Button>
                          );
                        })
                      ) : (
                        <Typography>
                          Select Vehicle to see Categories
                        </Typography>
                      )}
                    </div>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={10}
            className={classes.mainContainer}
          >
            {!openAddKeyword && (
              <Box sx={{ padding: "1rem" }}>
                <Box className={classes.filters}>
                  <Grid
                    container
                    spacing={2}
                    className={classes.filterContainer}
                  >
                    <Grid item xs={12} sm={10}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item xs={12} sm={4}>
                          <TextField
                            size="small"
                            label="Brand"
                            fullWidth
                            select
                            variant="outlined"
                            className={classes.selectDropdown}
                            id="brand"
                            margin="dense"
                            name="vehicleBrand"
                            value={
                              filterData.vehicleBrand &&
                              filterData.vehicleBrand.name
                            }
                          >
                            {vehicleBrands.map((brand) => (
                              <MenuItem
                                key={brand.brand_Id}
                                value={brand.brand_Id}
                                onClick={() =>
                                  handleClick(
                                    "vehicleBrand",
                                    brand.brand_Id,
                                    brand.brand
                                  )
                                }
                              >
                                {brand.brand}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            size="small"
                            label="Model"
                            fullWidth
                            select
                            variant="outlined"
                            className={classes.selectDropdown}
                            id="brand"
                            margin="dense"
                            name="vehicleModel"
                            value={
                              filterData.vehicleModel != null &&
                              filterData.vehicleModel.name
                            }
                          >
                            {vehicleModels.map((model) => (
                              <MenuItem
                                key={model.model_id}
                                value={model.model}
                                onClick={() =>
                                  handleClick(
                                    "vehicleModel",
                                    model.model_id,
                                    model.model
                                  )
                                }
                              >
                                {model.model}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            size="small"
                            label="Varaints"
                            fullWidth
                            select
                            variant="outlined"
                            className={classes.selectDropdown}
                            id="vehicleVariant"
                            margin="dense"
                            name="vehicleVariant"
                            value={
                              filterData.vehicleVariant != null &&
                              filterData.vehicleVariant.name
                            }
                          >
                            {vehicleVariants.map((variant) => (
                              <MenuItem
                                key={variant.variantId}
                                value={variant.variantName}
                                onClick={() =>
                                  handleClick(
                                    "vehicleVariant",
                                    variant.variantId,
                                    variant.variantName
                                  )
                                }
                              >
                                {variant.variantName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {smallPhone && (
                          <Grid item xs={12} sm={2}>
                            <Button
                              variant="contained"
                              className={classes.searchButton}
                              onClick={onSearch}
                            >
                              Search
                            </Button>
                          </Grid>
                        )}
                        {smallPhone && (
                          <Grid item xs={12}>
                            <Box className={classes.categories}>
                              <h2 className={classes.categoryTitle}>
                                Service Categories
                              </h2>
                              <div className={classes.categoryList}>
                                {servicesCategories.length > 0 ? (
                                  servicesCategories.map((service) => {
                                    return (
                                      <Button
                                        key={service.categoryId}
                                        variant="contained"
                                        name="category"
                                        className={classes.serviceButtons}
                                        size="small"
                                        onClick={() => {
                                          handleClick(
                                            "category",
                                            service.categoryId,
                                            service.category
                                          );
                                        }}
                                        style={{
                                          backgroundColor:
                                            service.category === activeButton
                                              ? "#122164"
                                              : "",
                                          color:
                                            service.category === activeButton
                                              ? "#fff"
                                              : "",
                                        }}
                                      >
                                        {service.category}
                                      </Button>
                                    );
                                  })
                                ) : (
                                  <Typography>
                                    Select Vehicle to see Categories
                                  </Typography>
                                )}
                              </div>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {!smallPhone && (
                      <Grid item xs={12} sm={2}>
                        <Button
                          variant="contained"
                          className={classes.searchButton}
                          onClick={onSearch}
                        >
                          Search
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            )}
            {openAddKeyword ? (
              <>
                <AddKeywordsForm
                  setOpenAddKeyword={setOpenAddKeyword}
                  selectedService={selectedService}
                />
              </>
            ) : (
              <Box className={classes.services}>
                {servicesPrices?.length > 0 ? (
                  servicesPrices?.map((service) => {
                    return (
                      service.service.length > 0 && (
                        <Box>
                          {/* <Typography
                            variant="h5"
                            sx={{ mt: 1 }}
                            className="heading"
                          >
                            {service?.subCategory}
                          </Typography> */}

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "0.6rem",
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{ mt: 1 }}
                              className="heading"
                            >
                              {service?.subCategory}
                            </Typography>
                            {isAdminLogged && (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#122164",
                                  "&:hover": {
                                    backgroundColor: "#122164",
                                  },
                                  height: "37px",
                                }}
                                onClick={openCustomServiceModal}
                              >
                                Add Custom Service
                              </Button>
                            )}
                          </Box>

                          <Box>
                            {service?.service?.map((service, index) => {
                              return (
                                <Accordion
                                  m={2}
                                  pt={3}
                                  className={classes.box}
                                  // key={service?.serviceId}
                                  key={index}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        className={classes.expandIcon}
                                      />
                                    }
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                  >
                                    <div className={classes.AccordionHeader}>
                                      <div className={classes.imageWrapper}>
                                        <img
                                          src={URL + service?.imagePath}
                                          alt="img"
                                          className={classes.image}
                                        />
                                        <Typography
                                          className={classes.serviceHeading}
                                        >
                                          {service?.service}
                                        </Typography>
                                      </div>
                                      <Box
                                        className={
                                          classes.servicePricessWrapper
                                        }
                                      >
                                        <Box sx={{ display: "flex" }}>
                                          {!isMobile && !isLaptop ? (
                                            <Button
                                              variant="contained"
                                              sx={{
                                                marginRight: "1rem",
                                                backgroundColor: "#122164",
                                                "&:hover": {
                                                  backgroundColor: "#122164",
                                                },
                                              }}
                                              onClick={() =>
                                                openAddKeywordsForm(
                                                  service.service,
                                                  service.serviceId
                                                )
                                              }
                                            >
                                              Add Keywords
                                            </Button>
                                          ) : (
                                            <Box>
                                              <Button
                                                variant="contained"
                                                sx={{
                                                  marginRight: "1rem",
                                                  backgroundColor: "#122164",
                                                  "&:hover": {
                                                    backgroundColor: "#122164",
                                                  },
                                                }}
                                                onClick={() =>
                                                  openAddKeywordsForm(
                                                    service.service,
                                                    service.serviceId
                                                  )
                                                }
                                              >
                                                Keywords
                                              </Button>
                                            </Box>
                                          )}
                                        </Box>
                                        <Typography
                                          className={classes.serviceRate}
                                        >
                                          <span
                                            style={{
                                              fontSize: "1rem",
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            Rs.
                                            {service.actualPrice
                                              ? service.actualPrice
                                              : 0}
                                          </span>{" "}
                                          /{" "}
                                          <span style={{ fontSize: "1.2rem" }}>
                                            Rs.
                                            {service.displayPrice
                                              ? service.displayPrice
                                              : 0}
                                          </span>
                                        </Typography>
                                      </Box>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    className={classes.accordionDetails}
                                  >
                                    <Divider />
                                    <Grid
                                      container
                                      spacing={2}
                                      className={classes.serviceDetails}
                                    >
                                      {service?.detailsList &&
                                        service?.detailsList.map(
                                          (details, index) => {
                                            return (
                                              <Grid
                                                item
                                                sm={6}
                                                key={index}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <CheckCircleIcon
                                                  sx={{
                                                    color: "#122164",
                                                    marginRight: "0.5rem",
                                                  }}
                                                />
                                                <Typography>
                                                  {details.detail}
                                                </Typography>
                                              </Grid>
                                            );
                                          }
                                        )}
                                    </Grid>
                                    <Grid
                                      container
                                      spacing={2}
                                      className={classes.serviceActivities}
                                    >
                                      {service.activities &&
                                        service.activities.map(
                                          (activity, index) => {
                                            return (
                                              <Grid
                                                key={index}
                                                item
                                                sm={6}
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <CheckCircleIcon
                                                  sx={{
                                                    color: "#51ae3a",
                                                    marginRight: "0.5rem",
                                                  }}
                                                />
                                                <Typography>
                                                  {activity.activity}
                                                </Typography>
                                              </Grid>
                                            );
                                          }
                                        )}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })}
                          </Box>
                        </Box>
                      )
                    );
                  })
                ) : loading ? (
                  <Box sx={{ height: 40 }}>
                    <Fade
                      in={loading}
                      style={{
                        transitionDelay: loading ? "800ms" : "0ms",
                      }}
                      unmountOnExit
                    >
                      <CircularProgress />
                    </Fade>
                  </Box>
                ) : (
                  <>
                    <h5>No services 2</h5>
                    {setLoading(true)}
                  </>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box>
          {openVehicleModal && (
            <VehicleModal
              hanldeCloseVehicleModal={hanldeCloseVehicleModal}
              setShowServices={setShowServices}
              vehicleDetails={vehicleDetails}
              setVehicleDetails={setVehicleDetails}
            />
          )}

          <Box className="select-vehicle-wrapper">
            {!applyFilters?.isApplied ? (
              <>
                <Box
                  className="select-vehicle"
                  onClick={hanldeOpenVehicleModal}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Box className="image-box">
                        <img
                          className="image"
                          src={
                            showServices
                              ? URL + vehicleDetails?.image
                              : "https://imgd.aeplcdn.com/370x208/n/cw/ec/40087/thar-exterior-right-front-three-quarter-11.jpeg?q=75"
                          }
                        />
                      </Box>
                    </Box>
                    <Box sx={{ marginLeft: "1rem" }}>
                      {showServices ? (
                        <Typography>
                          {vehicleDetails?.brand}
                          {/* {vehicleDetails?.model} */}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: "1.1rem" }}>
                          Click here to select vehicle
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {showServices && (
                    <Box>
                      <BorderColorIcon onClick={hanldeOpenVehicleModal} />
                    </Box>
                  )}
                </Box>

                {openAddKeyword ? (
                  <>
                    <AddKeywordsForm
                      setOpenAddKeyword={setOpenAddKeyword}
                      selectedService={selectedService}
                    />
                  </>
                ) : (
                  showServices && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        maxHeight: "70vh",
                        overflow: "auto",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      <Box className={classes.categories}>
                        <h2 className={classes.categoryTitle}>
                          Service Categories
                        </h2>
                        <div className={classes.categoryList}>
                          {servicesCategories.length > 0 ? (
                            servicesCategories.map((service) => {
                              return (
                                <>
                                  <Button
                                    key={service.categoryId}
                                    variant="contained"
                                    name="category"
                                    className={classes.serviceButtons}
                                    size="small"
                                    onClick={() => {
                                      userSelection("category", service);
                                    }}
                                    style={{
                                      backgroundColor:
                                        service.category === activeButton
                                          ? "#122164"
                                          : "#fff",
                                      color:
                                        service.category === activeButton
                                          ? "#fff"
                                          : "#000",
                                    }}
                                  >
                                    {service.category === activeButton ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <span>{service.category}</span>{" "}
                                        <KeyboardArrowUpIcon />
                                        {/* <>
                                          {isShowServices ? (
                                            <KeyboardArrowUpIcon />
                                          ) : (
                                            <KeyboardArrowDownIcon />
                                          )}
                                        </> */}
                                      </Box>
                                    ) : (
                                      service.category
                                    )}
                                  </Button>

                                  {service.category === activeButton &&
                                    vehicleDetails?.categoryId &&
                                    isShowServices && (
                                      <Box
                                        className={classes.services}
                                        sx={{
                                          maxHeight: "auto",
                                          overflow: "auto",
                                        }}
                                      >
                                        {servicesPrices?.length > 0 ? (
                                          servicesPrices?.map((service) => {
                                            return (
                                              service.service.length > 0 && (
                                                <Box>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      marginTop: "0.6rem",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography
                                                      variant="h5"
                                                      sx={{ mt: 1 }}
                                                      className="category-heading"
                                                    >
                                                      {service?.subCategory}
                                                    </Typography>
                                                    <Box>
                                                      {/* <AddCircleIcon
                                                        // className="add-keyword-icon"
                                                        onClick={
                                                          openCustomServiceModal
                                                        }
                                                      /> */}
                                                      {isAdminLogged && (
                                                        <Button
                                                          variant="contained"
                                                          sx={{
                                                            backgroundColor:
                                                              "#122164",
                                                            "&:hover": {
                                                              backgroundColor:
                                                                "#122164",
                                                            },
                                                          }}
                                                          onClick={
                                                            openCustomServiceModal
                                                          }
                                                        >
                                                          Add Service
                                                        </Button>
                                                      )}
                                                    </Box>
                                                  </Box>
                                                  <Box>
                                                    {service?.service?.map(
                                                      (service, index) => {
                                                        return (
                                                          <Accordion
                                                            m={2}
                                                            pt={3}
                                                            className={
                                                              classes.box
                                                            }
                                                            // key={service?.serviceId}
                                                            key={index}
                                                          >
                                                            <AccordionSummary
                                                              expandIcon={
                                                                <ExpandMoreIcon
                                                                  className={
                                                                    classes.expandIcon
                                                                  }
                                                                />
                                                              }
                                                              aria-controls="panel2a-content"
                                                              id="panel2a-header"
                                                              sx={{
                                                                position:
                                                                  "relative",
                                                              }}
                                                            >
                                                              <Box className="add-keyword-box">
                                                                <AddCircleIcon
                                                                  className="add-keyword-icon"
                                                                  onClick={() =>
                                                                    openAddKeywordsForm(
                                                                      service.service,
                                                                      service.serviceId
                                                                    )
                                                                  }
                                                                />
                                                              </Box>
                                                              <Box
                                                                className={
                                                                  classes.AccordionHeader
                                                                }
                                                              >
                                                                <div
                                                                  className={
                                                                    classes.imageWrapper
                                                                  }
                                                                >
                                                                  <img
                                                                    src={
                                                                      URL +
                                                                      service?.imagePath
                                                                    }
                                                                    alt="img"
                                                                    className={
                                                                      classes.image
                                                                    }
                                                                  />
                                                                  <Typography
                                                                    className={
                                                                      classes.serviceHeading
                                                                    }
                                                                    sx={{
                                                                      wordWrap:
                                                                        "break-word",
                                                                    }}
                                                                  >
                                                                    {
                                                                      service?.service
                                                                    }
                                                                  </Typography>
                                                                </div>
                                                                <Typography
                                                                  className={
                                                                    classes.serviceRate
                                                                  }
                                                                >
                                                                  <span className="display-price">
                                                                    Rs.
                                                                    {service.actualPrice
                                                                      ? service.actualPrice
                                                                      : 0}
                                                                  </span>{" "}
                                                                  /{" "}
                                                                  <span className="actual-price">
                                                                    Rs.
                                                                    {service.displayPrice
                                                                      ? service.displayPrice
                                                                      : 0}
                                                                  </span>
                                                                </Typography>
                                                              </Box>
                                                            </AccordionSummary>
                                                            <AccordionDetails
                                                              className={
                                                                classes.accordionDetails
                                                              }
                                                            >
                                                              <Divider />
                                                              <Grid
                                                                container
                                                                spacing={2}
                                                                className={
                                                                  classes.serviceDetails
                                                                }
                                                              >
                                                                {service?.detailsList &&
                                                                  service?.detailsList.map(
                                                                    (
                                                                      details,
                                                                      index
                                                                    ) => {
                                                                      return (
                                                                        <Grid
                                                                          item
                                                                          sm={6}
                                                                          key={
                                                                            index
                                                                          }
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            flexDirection:
                                                                              "row",
                                                                            alignItems:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <CircleIcon
                                                                            sx={{
                                                                              color:
                                                                                "#707070",
                                                                              marginRight:
                                                                                "0.5rem",
                                                                              fontSize:
                                                                                "1rem !important",
                                                                            }}
                                                                          />
                                                                          <Typography>
                                                                            {
                                                                              details.detail
                                                                            }
                                                                          </Typography>
                                                                        </Grid>
                                                                      );
                                                                    }
                                                                  )}
                                                              </Grid>
                                                              <Grid
                                                                container
                                                                spacing={2}
                                                                className={
                                                                  classes.serviceActivities
                                                                }
                                                              >
                                                                {service.activities &&
                                                                  service.activities.map(
                                                                    (
                                                                      activity,
                                                                      index
                                                                    ) => {
                                                                      return (
                                                                        <Grid
                                                                          key={
                                                                            index
                                                                          }
                                                                          item
                                                                          sm={6}
                                                                          style={{
                                                                            display:
                                                                              "flex",
                                                                            flexDirection:
                                                                              "row",
                                                                            alignItems:
                                                                              "center",
                                                                          }}
                                                                        >
                                                                          <CircleIcon
                                                                            sx={{
                                                                              color:
                                                                                "#000",
                                                                              marginRight:
                                                                                "0.5rem",
                                                                              fontSize:
                                                                                "1rem !important",
                                                                            }}
                                                                          />
                                                                          <Typography>
                                                                            {
                                                                              activity.activity
                                                                            }
                                                                          </Typography>
                                                                        </Grid>
                                                                      );
                                                                    }
                                                                  )}
                                                              </Grid>
                                                            </AccordionDetails>
                                                          </Accordion>
                                                        );
                                                      }
                                                    )}
                                                  </Box>
                                                </Box>
                                              )
                                            );
                                          })
                                        ) : loading ? (
                                            <LoaderSpinner />
                                        ) : (
                                          <>
                                            <h5>No services</h5>
                                          </>
                                        )}
                                      </Box>
                                    )}
                                </>
                              );
                            })
                          ) : loading ? (
                           
                              <LoaderSpinner />
                          ) : (
                            <>
                              <Typography>
                                Select Vehicle to see Categories
                              </Typography>
                            </>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  )
                )}
                <Box className="footer-section">
                  <Box className="sort-box">
                    <Button
                      variant="contained"
                      fullWidth
                      className="footer-section-buttons"
                      onClick={() => handleOpenApplyFilters("sort")}
                    >
                      <SortIcon />
                      Sort
                    </Button>
                  </Box>
                  <Box className="filter-box">
                    <Button
                      variant="contained"
                      fullWidth
                      className="footer-section-buttons"
                      onClick={() => handleOpenApplyFilters("filter")}
                    >
                      <ArrowDropDownIcon />
                      Filter
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <Box className="filters-wrapper">
                <Box>
                  {applyFilters?.filter == "sort" ? (
                    <Box className="sort-section">
                      <Typography variant="h6">Sort Section</Typography>
                      <Divider />
                      <div className={classes.categoryList}>
                        {sortingArr.map((sort) => {
                          return (
                            <Button
                              key={sort.id}
                              variant="contained"
                              name="category"
                              className={classes.serviceButtons}
                              size="small"
                              // onClick={() =>
                              //   handleClick(
                              //     "category",
                              //     service.categoryId,
                              //     service.category
                              //   )
                              // }

                              onClick={() => hanldeSortItem(sort)}
                              style={{
                                backgroundColor:
                                  sort.sort != ""
                                    ? sort?.id == currentSort
                                      ? "#122164"
                                      : isTablet
                                      ? "#7B8BD4"
                                      : "#fff"
                                    : "#122164",
                                color:
                                  sort?.id == currentSort ? "#fff" : "#000",
                              }}
                            >
                              {sort?.sort}
                            </Button>
                          );
                        })}
                      </div>
                    </Box>
                  ) : (
                    <Box className="catalogue-filter-section">
                      <Box className="search-box">
                        <Box className="search-box-wrapper">
                          <div className="search">
                            <div className="search-icon">
                              <SearchIcon />
                            </div>
                            <InputBase
                              placeholder="Search…"
                              classes={{
                                root: "input-root",
                                input: "input-input",
                              }}
                              inputProps={{ "aria-label": "search" }}
                              // value={query}
                              // onChange={handleSearch}
                            />
                          </div>
                          {/* <button className="search-button">Search</button> */}
                        </Box>
                      </Box>

                      <Typography variant="h6">Filter Section</Typography>

                      <div className={classes.categoryList}>
                        {servicesCategories.length > 0 ? (
                          servicesCategories.map((service) => {
                            return (
                              <Button
                                key={service.id}
                                variant="contained"
                                name="category"
                                className={classes.serviceButtons}
                                size="small"
                                onClick={() => handleFilterItem(service)}
                                style={{
                                  backgroundColor:
                                    service.category != ""
                                      ? service.category === activeButton
                                        ? "#122164"
                                        : isTablet
                                        ? "#7B8BD4"
                                        : "#fff"
                                      : "#122164",
                                  color:
                                    service.category === activeButton
                                      ? "#fff"
                                      : "#000",
                                }}
                              >
                                {service.category}
                              </Button>
                            );
                          })
                        ) : (
                          <Typography>
                            Select Vehicle to see Categories
                          </Typography>
                        )}
                      </div>
                    </Box>
                  )}
                </Box>
                <Box className="filter-actions">
                  <Button
                    variant="contained"
                    fullWidth
                    className="filter-action-buttons"
                    onClick={handleCloseApplyFilters}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    className="filter-action-buttons"
                    onClick={getFilteredData}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Catalogue;
