import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DataGridStyle } from "../../../components/tables/DataGridStyles";
import {
  getBrokersList,
  getBrokerById,
  postBroker,
  updateBroker,
  deleteBrokerInsuranceCompany,
  getAllInsuranceCompanies,
} from "../../../services/Services";
import { useSelector } from "react-redux";
import PaginationTable from "../../../components/tables/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../../components/headers/ModuleHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteConfigModal from "../DeleteConfigModal";
import Form from "../../../components/forms/Form";
import { CommonContextAPI } from "../../../App";
import { AdminLogin } from "../../../reducer/LoginReducer";
import Table from "../../../components/tables/Table";

const CustomToolbar = () => {
  const propsData = useSelector((state) => state?.CommonData);
  const { clearAppliedFilters } = propsData;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px",
      }}
    >
      <GridToolbarQuickFilter />
      <div sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          // className={classes.clearButton}
          style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default function Brokers() {
  const [isRemoveConfigModal, setIsRemoveConfigModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [isEditBroker, setIsEditBroker] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [brokersList, setBrokersList] = useState([]);
  const [brokerDetails, setBrokerDetails] = useState({});
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [companiesList, setCompaniesList] = useState([]);
  const [newAddedCompaniesList, setNewAddedCompaniesList] = useState([]);
  const [fetchAgain, setFetchAgain] = useState(false);

  const [removedCompanies, setRemovedCompanies] = useState([]);

  const Navigate = useNavigate();

  const commonData = useContext(CommonContextAPI);
  const {
    handleOpenDatePicker,
    filteredDate,
    setFilteredDate,
    filterModel,
    filterDateRenge,
    getDate,
    applyFilter,
    addBroker,
    setAddBroker,
    handleAlertOpen,
    colors,
  } = commonData;

  const AdminRole = useSelector((state) => state.AdminLogin.role);

  useEffect(() => {
    if (
      AdminRole.toLowerCase() == "super admin" ||
      AdminRole.toLowerCase() == "admin"
    ) {
      setIsAdmin(false);
    }
  }, [AdminRole]);

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const getBrokers = async () => {
    try {
      const request = await getBrokersList();
      const response = await request.data;
      setBrokers(response.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const temp = brokers?.map((broker) => {
      return {
        ...broker,
        partnered: Boolean(broker?.partnered) ? "Yes" : "No",
      };
    });

    setBrokersList(temp);
  }, [brokers]);

  const getCompanies = async () => {
    try {
      const response = await getAllInsuranceCompanies();
      const data = await response.data;
      setInsuranceCompanies(data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBrokers();
  }, []);

  // Delete brokers insurance company

  const deleteBrokersCompany = async (companyId) => {
    console.log("removedCompanies", removedCompanies);
    try {
      const response = await deleteBrokerInsuranceCompany(
        brokerDetails?.id,
        companyId
      );

      console.log("response", response);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "broker",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },

    {
      field: "area",
      headerName: "Area of Sale",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile No.",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "insuranceCompanies",
      headerName: "Insurance Company",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "commissionPercentage",
      headerName: "Commission",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "partnered",
      headerName: "Partnered",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      //   editable: true,
      //   type: "singleSelect",
      //   valueOptions: ["Yes", "No"],
      flex: 1,
    },
    {
      // "superadmin", "admin"
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      editable: true,
      hide: isAdmin,
      Width: "10px",
      minWidth: 10,
      // flex: 1,
      renderCell: (params) => {
        let name = params?.row?.name?.split(" ").join("_");
        return (
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              brokerById(params?.id);
              setIsEditBroker(true);
            }}
          >
            <EditIcon />
          </Box>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      headerClassName: "super-app-theme--header",
      editable: true,
      hide: isAdmin,
      minWidth: 10,
      renderCell: (par) => {
        return (
          <DeleteForeverIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              setIsRemoveConfigModal(true);
            }}
          />
        );
      },
    },
  ];
  // ----Edit company-----------
  const brokerById = async (id) => {
    try {
      const request = await getBrokerById(id);
      const response = await request.data;
      setBrokerDetails(response.result);
      response?.result?.insuranceCompanies &&
        setCompaniesList(response?.result?.insuranceCompanies);
    } catch (error) {
      console.log(error);
    }
  };

  const updateBrokerDetails = async () => {
    const updateDetails = {
      ...brokerDetails,
      insuranceCompanies: newAddedCompaniesList?.map((company) => {
        return { id: company?.id };
      }),
    };

    removedCompanies?.map((company) => {
      deleteBrokersCompany(company);
    });

    try {
      const response = await updateBroker(updateDetails);
      const data = await response.data;
      handleAlertOpen(data?.message, colors.success);

      setCompaniesList([]);
      setNewAddedCompaniesList([]);

      setIsEditBroker(false);
      setFetchAgain(true);
    } catch (error) {
      console.log(error);
    }
  };

  // ---- Add new Company -------------------

  const addNewBroker = async () => {
    const addDetails = {
      ...brokerDetails,
      insuranceCompanies: newAddedCompaniesList?.map((company) => {
        return { id: company?.id };
      }),
    };

    try {
      const response = await postBroker(addDetails);
      const data = await response.data;
      handleAlertOpen(data?.message, colors.success);
      setCompaniesList([]);
      setNewAddedCompaniesList([]);
      setAddBroker(false);
      setFetchAgain(true);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors.error);
      console.log(error);
    }
  };

  const userSelection = (field, value) => {
    if (field == "insuranceCompanies") {
      setCompany({ id: value?.id, name: value?.name });
    }

    if (field == "pertnered") {
      setBrokerDetails({ ...brokerDetails, partnered: value?.name });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "insuranceCompanies") {
      const temp = insuranceCompanies?.filter(
        (company) => company?.id == Number(value)
      );

      let obj = temp[0];
      setCompany({ id: obj?.id, name: obj?.name });
    } else {
      setBrokerDetails({ ...brokerDetails, [name]: value });
    }
  };

  const partnered = [
    {
      id: 1,
      name: "true",
    },
    {
      id: 2,
      name: "false",
    },
  ];

  useEffect(() => {
    fetchAgain && getBrokers();
  }, [fetchAgain]);

  const renderCompanies = () => {
    return (
      <TextField
        required
        size="small"
        label="Insurance Companies"
        fullWidth
        select
        variant="outlined"
        id="insuranceCompanies"
        margin="dense"
        name="insuranceCompanies"
        // value={}
        // defaultValue={company?.name}
        defaultValue="Select"
      >
        {insuranceCompanies &&
          insuranceCompanies?.map((company) => (
            <MenuItem
              key={company.id}
              value={company.id}
              onClick={() => userSelection("insuranceCompanies", company)}
            >
              {company.name}
            </MenuItem>
          ))}
      </TextField>
    );
  };

  //   const renderCompanies = () => {
  //     return (
  //       <select
  //         name="insuranceCompanies"
  //         onChange={handleChange}
  //         className="selectDropdown"
  //       >
  //         {/* <option value={company && company?.name}>
  //             {company && company?.name}
  //           </option> */}
  //         {/* <option value=""></option> */}
  //         {insuranceCompanies &&
  //           insuranceCompanies?.map((company, index) => {
  //             return (
  //               <>
  //                 <option value={company?.id} key={company?.id}>
  //                   {company?.name}
  //                 </option>
  //               </>
  //             );
  //           })}
  //       </select>
  //     );
  //   };

  const renderPartnered = () => {
    return (
      <select
        name="partnered"
        onChange={handleChange}
        className="selectDropdown"
      >
        <option
          value={
            addBroker
              ? ""
              : String(brokerDetails?.partnered)
              ? String(brokerDetails?.partnered)
              : ""
          }
        >
          {addBroker
            ? ""
            : String(brokerDetails?.partnered)
            ? String(brokerDetails?.partnered)
            : ""}
        </option>
        {partnered &&
          partnered?.map((item, index) => {
            return (
              <option value={item?.name} key={index}>
                {item?.name}
              </option>
            );
          })}
      </select>
    );
  };

  const removeCompany = (id) => {
    let remove = companiesList.filter((p) => Number(p.id) != Number(id));
    let removeNew = newAddedCompaniesList.filter(
      (p) => Number(p.id) != Number(id)
    );
    setRemovedCompanies([...removedCompanies, id]);
    setCompaniesList(remove);
    setNewAddedCompaniesList(removeNew);
  };

  const addCompany = () => {
    if (
      companiesList?.find((item) => item?.id == company?.id) ||
      newAddedCompaniesList?.find((item) => item?.id == company?.id)
    ) {
      handleAlertOpen("Company aleady exists!", colors.error);
    } else if (company.name) {
      setCompaniesList([...companiesList, company]);
      setNewAddedCompaniesList([...newAddedCompaniesList, company]);

      setCompany({});
      setCompany({
        id: "",
        name: "",
      });
    } else {
      handleAlertOpen("Please select company!", colors.error);
    }
  };

  // useEffect(() => {
  //   setBrokerDetails({
  //     ...brokerDetails,
  //     insuranceCompanies: newAddedCompaniesList,
  //   });
  // }, [newAddedCompaniesList]);

  const companies = () => {
    return (
      <>
        {companiesList?.length > 0 ? (
          <>
            <Grid
              container
              className="gridRow"
              spacing={1}
              style={{ width: "100%", padding: "0.5rem 0" }}
            >
              <Grid item xs={2}>
                <Typography className="lables">Sr. No.</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography className="lables">Company</Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Divider />
            <>
              {companiesList?.map((company, i) => {
                return (
                  <>
                    <Grid
                      container
                      className="gridRow"
                      spacing={1}
                      style={{ width: "100%", padding: "0.5rem 0" }}
                    >
                      <Grid item xs={2}>
                        <Typography className="lables">{i + 1}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography className="lables">
                          {company?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <DeleteForeverIcon
                          sx={{
                            cursor: "pointer",
                            "&:hover": { color: "red" },
                          }}
                          onClick={() => removeCompany(company?.id)}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                );
              })}
            </>
          </>
        ) : (
          <Typography>No Company</Typography>
        )}
      </>
    );
  };

  const renderForm = [
    {
      label: "Broker Name",
      fields: [
        {
          label: "Broker Name",
          field: "TextField",
          name: "broker",
          id: "broker",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Area",
      fields: [
        {
          label: "Area Of Sale",
          field: "TextField",
          name: "area",
          id: "area",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Mobile No.",
      fields: [
        {
          label: "Mobile No.",
          field: "TextField",
          name: "mobile",
          id: "mobile",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Insurance Company",
      fields: [
        {
          label: "Insurance Company",
          field: "Dropdown",
          name: "insuranceCompanies",
          id: "insuranceCompanies",
          type: "text",
          render: renderCompanies,
          grids: 12,
        },
      ],
    },
    {
      label: "Add Company",
      button: true,
      buttonStyle: {
        backgroundColor: "#122164",
      },
      delete: removeCompany,
      add: addCompany,
      style: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "0.3rem 0",
      },
    },
    {
      label: "",
      renderTable: true,
      render: companies,
    },

    {
      label: "Commission",
      fields: [
        {
          label: "Commission",
          field: "TextField",
          name: "commissionPercentage",
          id: "commissionPercentage",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Partnered",
      fields: [
        {
          label: "Partnered",
          field: "Dropdown",
          name: "partnered",
          id: "partnered",
          type: "text",
          render: renderPartnered,
          grids: 12,
        },
      ],
    },
  ];

  useEffect(() => {
    isEditBroker && getCompanies();

    if (!isEditBroker) {
      setBrokerDetails({});
      setCompany({});
    }
  }, [isEditBroker]);

  useEffect(() => {
    setCompany({});
    setCompaniesList([]);
    setNewAddedCompaniesList([]);
    setBrokerDetails({});
    addBroker && getCompanies();
    if (!addBroker) {
      setBrokerDetails({});
    } else {
      setInsuranceCompanies([]);
      setNewAddedCompaniesList([]);
    }
  }, [addBroker]);
  return (
    <Box>
      {isRemoveConfigModal && (
        <DeleteConfigModal
          isRemoveConfigModal={isRemoveConfigModal}
          setIsRemoveConfigModal={setIsRemoveConfigModal}
        />
      )}
      {addBroker && (
        <Form
          heading="Add Broker"
          renderForm={renderForm}
          closeModal={() => setAddBroker(false)}
          state={brokerDetails}
          setState={setBrokerDetails}
          submit={addNewBroker}
        />
      )}
      {isEditBroker && (
        <Form
          heading="Edit Broker"
          closeModal={() => setIsEditBroker(false)}
          renderForm={renderForm}
          state={brokerDetails}
          setState={setBrokerDetails}
          submit={updateBrokerDetails}
          isEdit={true}
        />
      )}
      <Table
        customStyle={DataGridStyle}
        heading="Brokers List"
        columns={columns}
        rows={brokersList}
        onCellEditCommit={(params) => {
          // console.log(params);
        }}
        components={{
          Footer: FooterSection,
          Toolbar: CustomToolbar,
        }}
        pageSize={50}
        handleOpenDatePicker={handleOpenDatePicker}
        filterModel={filterModel && filterModel}
        filteredDate={filteredDate}
        setFilteredDate={setFilteredDate}
        filterDateRenge={filterDateRenge}
        getDate={getDate}
        applyFilter={applyFilter && applyFilter}
        getRowId={(row) => row?.id}
      />
    </Box>
  );
}
