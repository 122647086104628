import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/tables/Table";
import {
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import AddMaterial from "./AddMaterial";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "hsn",
    headerName: "HSN",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "hsnItemName",
    headerName: "HSN Item Name",
    headerClassName: "super-app-theme--header",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "super-app-theme--header",
    minWidth: 166,
    flex: 1,
  },
  {
    field: "brand",
    headerName: "Brand",
    headerClassName: "super-app-theme--header",
    minWidth: 166,
    flex: 1,
  },
  {
    field: "grade",
    headerName: "Grade",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "materialPrice",
    headerName: "Material Price",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    editable: true,
    flex: 1,
  },
  {
    field: "cgstRate",
    headerName: "CGST Rate",
    headerClassName: "super-app-theme--header",
    filterable: true,
    editable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    field: "cgstAmount",
    headerName: "CGST Amount",
    headerClassName: "super-app-theme--header",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "sgstRate",
    headerName: "SGST Rate",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "sgstAmount",
    headerName: "SGST Amount",
    headerClassName: "super-app-theme--header",
    minWidth: 160,
    flex: 1,
  },
  {
    field: "totalAmount",
    headerName: "Total Amount (Inc.Taxes)",
    headerClassName: "super-app-theme--header",
    minWidth: 180,
    flex: 1,
  },
];

let rows = [
  {
    id: 1,
    hsn: "10022323",
    hsnItemName: "DDD Brake Liners",
    description: "Some Description",
    brand: "AKS",
    grade: "NA",
    materialPrice: 0,
    cgstRate: 2.5,
    cgstAmount: 2.0,
    sgstRate: 2.5,
    sgstAmount: 0,
    totalAmount: 0,
    address: "Kharadi, Pune",
  },
  {
    id: 2,
    hsn: "10022323",
    hsnItemName: "AAA Brake Liners",
    description: "Some Description",
    brand: "AKS",
    grade: "NA",
    materialPrice: 0,
    cgstRate: 3.5,
    cgstAmount: 2.9,
    sgstRate: 2.5,
    sgstAmount: 0,
    totalAmount: 0,
  },
  {
    id: 3,
    hsn: "10022323",
    hsnItemName: "BBB Brake Liners",
    description: "Some Description",
    brand: "AKS",
    grade: "NA",
    materialPrice: 0,
    cgstRate: 4,
    cgstAmount: 1,
    sgstRate: 2.5,
    sgstAmount: 0,
    totalAmount: 0,
  },
  {
    id: 4,
    hsn: "10022323",
    hsnItemName: "CC Brake Liners",
    description: "Some Description",
    brand: "AKS",
    grade: "NA",
    materialPrice: 0,
    cgstRate: 2.1,
    cgstAmount: 0.7,
    sgstRate: 2.5,
    sgstAmount: 0,
    totalAmount: 0,
  },
  {
    id: 5,
    hsn: "10022323",
    hsnItemName: "CCCCCLiners",
    description: "Some Description",
    brand: "AKS",
    grade: "NA",
    materialPrice: 0,
    cgstRate: 10.1,
    cgstAmount: 0.2,
    sgstRate: 2.8,
    sgstAmount: 0,
    totalAmount: 0,
  },
];
// { downloadXLS, handleOpenDatePicker, filteredDate }

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const SalesReport2 = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [rowsData, setRowsData] = useState([]);

  const [ctR, setCtR] = useState(0);
  const [ctA, setCtA] = useState(0);
  const [stR, setStR] = useState(0);
  const [stA, setStA] = useState(0);

  const [ctRate, setCtRate] = useState(0);
  const [ctAmount, setCtAmount] = useState(0);
  const [stRate, setStRate] = useState(0);
  const [stAmount, setStAmount] = useState(0);

  useEffect(() => {
    setRowsData(rows);
    rowsData.map((item) => {
      setCtR((prev) => prev + item.ctRate);
      setCtA((prev) => prev + item.ctAmount);
      setStR((prev) => prev + item.stRate);
      setStA((prev) => prev + item.stAmount);
    });
  }, []);

  const [filter, setFilter] = useState([]);
  const getFilteredData = (data) => {
    setFilter(data);
  };

  const updateMaterialPrice = (value) => {
    // console.log("Updated Value", value);
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    openAddMaterialModel,
    handleOpenAddMaterialModel,
    handleCloseAddMaterialModel,
  } = commonData;

  const totals = (
    <Box className={classes.totalWrapper}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <Box className={classes.gridColumnOdd}>
            <span>CT Rate :</span>
            <span className={classes.totalCount}>
              {ctRate && ctRate.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className={classes.gridColumnEven}>
            <span>CT Amount :</span>
            <span className={classes.totalCount}>
              {ctAmount && ctAmount.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className={classes.gridColumnOdd}>
            <span> ST Rate :</span>
            <span className={classes.totalCount}>
              {stRate && stRate.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box className={classes.gridColumnEven}>
            <span> ST Amount :</span>
            <span className={classes.totalCount}>
              {stAmount && stAmount.toFixed(2)}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  function FooterSection() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              CT Rate :{" "}
              <span className="grid-table-footer-right-value">
                {/* {filterBasicPrice
                  ? filterBasicPrice?.toFixed(2)
                  : basicPrice?.toFixed(2)} */}
                {ctRate}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              CT Amount :{" "}
              <span className="grid-table-footer-right-value">
                {ctAmount && ctAmount.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              ST Rate :{" "}
              <span className="grid-table-footer-right-value">
                {stRate && stRate.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              ST Amount :{" "}
              <span className="grid-table-footer-right-value">
                {stAmount && stAmount.toFixed(2)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <div>
        <Table
          customStyle={DataGridStyle}
          rows={rowsData}
          columns={columns}
          heading="Sales Report 2"
          pageSize={50}
          onCellEditCommit={(param) => updateMaterialPrice(param?.value)}
          rowsPerPageOptions={[100]}
          onStateChange={(state) => {
            const visibleRows = state.filter.visibleRowsLookup;
            let visibleItems = [];
            for (const [id, value] of Object.entries(visibleRows)) {
              if (value === true) {
                visibleItems.push(Number(id));
              }
            }
            const res = rowsData.filter((item) =>
              visibleItems.includes(item.id)
            );
            const ctrate = res
              .map((item) => item.ctRate)
              .reduce((a, b) => a + b, 0);
            const ctamount = res
              .map((item) => item.ctAmount)
              .reduce((a, b) => a + b, 0);
            const strate = res
              .map((item) => item.stRate)
              .reduce((a, b) => a + b, 0);
            const stamount = res
              .map((item) => item.stAmount)
              .reduce((a, b) => a + b, 0);
            setFilter(res);
            setCtRate(ctrate > 0 ? ctrate : ctR);
            setCtAmount(ctamount > 0 ? ctamount : ctA);
            setStRate(strate > 0 ? strate : stR);
            setStAmount(stamount > 0 ? stamount : stA);
          }}
          // total={totals}
          downloadXLS={() => downloadXLS(filter.length > 0 ? filter : rowsData)}
          components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
          handleOpenDatePicker={handleOpenDatePicker}
          filteredDate={filteredDate}
        />
      </div>
      <Box>
        {openAddMaterialModel && <AddMaterial setRowsData={setRowsData} />}
      </Box>
    </>
  );
};

export default SalesReport2;
