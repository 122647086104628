import { combineReducers } from "redux";
import { AddInspectionData } from "./Inspection";
import { AdminLogin } from "./LoginReducer";
import { GetOrders, OrdersReducer } from "./OrdersReducer";
import { AppConstants } from "./ConstantsReducer";
import { CommonData } from "./CommonData";
import { TableComponentData } from "./TableReducer";
import { ViewProfile } from "./UserProfile";
import { DateRange_Reducer } from "./SearchContent";
import { LeadsReducer, LeadCardReducer } from "./LeadsReducer";
import { ABPReducer } from "./ABP";

const rootReducer = combineReducers({
  AddInspectionData,
  AdminLogin,
  GetOrders,
  AppConstants,
  CommonData,
  TableComponentData,
  ViewProfile,
  DateRange_Reducer,
  LeadsReducer,
  OrdersReducer,
  LeadCardReducer,
  ABPReducer,
});

export default rootReducer;
