import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
// import Dashboard from "./dashboard/Dashboard";
// import Dashboard from "../modules/dashboardOld/Dashboard";
import Dashboard from "../modules/dashboard/MainDashboard";
import Leads from "../modules/leads/Leads";
import Orders from "../modules/orders/Orders";
import LeadsPanel from "../modules/leads/LeadDetails";
import InspectionReport from "../modules/orders/inspectionReport/InspectionReport";
import OrderDetails from "../modules/orders/OrderDetails";
import DatePicker from "./dateFilter/DatePicker";
import SalesReport1 from "../modules/sales/SalesReport1";
import SalesReport2 from "../modules/sales/SalesReport2";
import ServicingSalesReport from "../modules/sales/ServicingSalesReport";
import FranchiseSalesReport from "../modules/sales/FranchiseSalesReport";
import ServicingGrossProfit from "../modules/grossProfite/ServicingGrossProfit";
import GpDashboard from "../modules/grossProfite/GrossProfitDashboard";
import GaragePayout from "../modules/garages/GaragePayout";
import GarageApprovedPayout from "../modules/garages/ApprovedPayout";
import GarageDatabase from "../modules/garages/GarageDatabase";
import Catalogue from "../modules/catalogue/Catalogue";
import CustomerService from "../modules/customerService/CustomerServiceListView";
import Referal from "../modules/referal/Referal";
import InventoryDashboard from "../modules/inventory/InventoryDashboard";
import MaterialLibrary from "../modules/inventory/MaterialLibrary";
import MaterialBrands from "../modules/inventory/MaterialBrands";
import ProductIn from "../modules/inventory/ProductIn";
import ProductOut from "../modules/inventory/ProductOut";
import Vendors from "../modules/inventory/Vendors";
import BalanceSummary from "../modules/inventory/BalanceSummary";
import { makeStyles } from "@material-ui/core/styles";
import MainHeader from "./headers/MainHeader";
import { Box } from "@material-ui/core";
import SideDrawer from "./headers/SideNavbar";
import AddLeads from "../modules/leads/AddLead";
import AddGarage from "../modules/garages/AddGarage";
import AddUser from "../modules/userProfile/myAccount/AddUser";
import EditUser from "../modules/userProfile/myAccount/EditUser";
// import SearchKeywords from "./keywords/SearchKeywords";
import NotFound404 from "./NotFound404";
import EditGarageInfo from "../modules/garages/EditGarageInfo";

import Profile from "../modules/userProfile/myAccount/Profile";
import Security from "../modules/userProfile/myAccount/Security";
import Users from "../modules/userProfile/adminConsole/Users";
import MultifactorAuth from "../modules/userProfile/myAccount/MultifactorAuth";
import Settings from "../modules/userProfile/myAccount/Settings";
import Sessions from "../modules/userProfile/myAccount/Sessions";
import Groups from "../modules/userProfile/myAccount/Groups";
import Privacy from "../modules/userProfile/myAccount/Privacy";
import Compliance from "../modules/userProfile/myAccount/Compliance";
import AdminConsoleDashboard from "../modules/userProfile/adminConsole/AdminConsoleDashboard";

import { useDispatch, useSelector } from "react-redux";
import { GetSearchedLeads, ViewProfile } from "../action";
import { Button, Toolbar, Typography } from "@mui/material";
import MyAccount from "../modules/userProfile/myAccount/MyAccount";
import AdminConsole from "../modules/userProfile/adminConsole/AdminConsole";
import SignUp from "../modules/userProfile/myAccount/SignUp";
import Sharejobcard from "./pdf/ShareJobCard";

import { getUserProfile } from "../services/userProfileServices/UserProfileServices";
import moment from "moment-timezone";
import CompletePayout from "../modules/garages/CompletePayout";
import GarageInvoice from "../modules/garages/garageInvoice/GarageInvoice";

import AlertMessage from "./popMessages/AlertMessage";
import { CommonContextAPI } from "../App";
import MonthPicker from "./dateFilter/MonthPicker";
import AllCompanies from "../modules/insurance/companies/componies";
import AllInsuranceType from "../modules/insurance/insuranceTypes/AllInsuranceType";
import AllServiceTypes from "../modules/insurance/serviceType/AllServiceTypes";
import AllPolicyTypes from "../modules/insurance/policytype/AllPolicyTypes";
import GarageReceivables from "../modules/garages/GarageReceivables";
import OrderHistory from "../modules/orders/OrderHistory";
import Brokers from "../modules/insurance/broker/Brokers";
import GarageFranchise from "../modules/garages/GarageFranchise";
import FranchiseGrossProfit from "../modules/grossProfite/FranchiseGrossProfit";
import GarageReceivableGrossProfit from "../modules/grossProfite/GarageReceivableGrossProfit";
import GrossProfit from "../modules/grossProfite/GrossProfit";
import ABP from "../modules/abp/ABP";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appContainer: {
    width: "100% !important",
    overflow: "hidden",
  },
  mainWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  sidebarContainer: {
    height: "auto",
    overflow: "auto",
    "@media (min-width:540px) and (max-width:780px)": {
      width: "none !important",
    },
    "@media (min-width:768px) and (max-width:1024px)": {
      width: `${drawerWidth}px !important`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "none",
    },
    [theme.breakpoints.up("md")]: {
      width: `${drawerWidth}px`,
    },
  },
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
    "@media (min-width:540px) and (max-width:780px)": {
      width: "100% !important",
    },
    "@media (min-width:768px) and (max-width:1024px)": {
      width: `calc(100% - ${drawerWidth}px) !important`,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  profileContainer: {
    // padding: "3rem",
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function Routing() {
  const [sharePdf, setsharePdf] = useState(false);
  const classes = useStyles();

  const [isLeadAdded, setIsLeadAdded] = useState(false);
  const [isGarageAdded, setIsGarageAdded] = useState(false);
  const [isUserProfile, setUserProfile] = useState("");

  const [isPayoutModules, setIsPayoutModules] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    openAddLeads,
    openAddGarage,
    handleDrawerToggle,
    mobileOpen,
    openDatePicker,
    openAddUserModel,
    // isAdminConsole,
    // currentUserRole,
    openAlert,
    setDefaultDate,
    filteredDate,
    defaultDate,
    openMonthPicker,
    handleOpenMonthPicker,
    handleCloseMonthPicker,
    isTokenExpired,
    setCurrentOrders,
    setOrderType,
  } = commonData;

  useEffect(() => {
    let pathname = window.location.pathname;
    setUserProfile(pathname);

    if (!pathname?.includes("/orders")) {
      localStorage.setItem("currentOrders", "");
    }

    let current = localStorage.getItem("currentOrders");

    if (current != "") {
      switch (current) {
        case "general":
          setCurrentOrders("General");
          setOrderType(1);
          break;

        case "insurance":
          setCurrentOrders("Insurance");
          setOrderType(2);
          break;

        default:
          return false;
      }
    }
  }, [, window.location.href]);

  // ********************************* SET DEFAULT DATE START************************************

  const date = new Date();
  const start = new Date(date.getFullYear(), date.getMonth() - 2, 1);
  // const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const end = new Date();
  const [state, setState] = useState();

  useEffect(() => {
    setState([
      {
        startDate: start,
        endDate: end,
        key: "selection",
      },
    ]);
  }, [, isUserProfile]);

  useEffect(() => {
    // isPayoutModules && setDefaultDate(state);
    setDefaultDate(state);
  }, [, isPayoutModules, state, isUserProfile]);

  const [searchValue, setSearchValue] = useState("");

  // ********************************* SET DEFAULT DATE END************************************

  useEffect(() => {
    const isPayouts = [
      "/garagepayout",
      "/approvedpayout",
      "/completepayout",
      "/garagereceivables",
    ].includes(isUserProfile);
    setIsPayoutModules(isPayouts);
  }, [isUserProfile]);

  const closeUserProfile = () => {};

  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const isLogged = getLoginDetailsLS?.isLogged;
    isTokenExpired(getLoginDetailsLS?.jwtToken);
    setIsLogged(isLogged);
  }, [window.location.href]);

  const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));

  // ---------------------------------
  // Reload page on focus loose
  // var blurred = false;
  // window.onblur = () => {
  //   blurred = true;
  // };

  // window.onfocus = () => {
  //   blurred && window.location.reload();
  // };
  // ---------------------------------

  // const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
  // const isLogged = getLoginDetailsLS?.isLogged;

  const CurrentURL = window.location.href;

  useEffect(() => {
    let URLResult = CurrentURL.includes("jobcard-pdf");
    URLResult && setsharePdf(true);

    // dispatch(GetSearchedLeads(""));
  }, [CurrentURL]);

  const isLoggedOut = () => {
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const isLogged = getLoginDetailsLS?.isLogged;
    if (isLogged == false) {
      window.location.reload();
    }
  };

  useEffect(() => {
    // const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    // const isLogged = getLoginDetailsLS?.isLogged;
    // if (isLogged == false) {
    //   window.location.reload();
    // }
    isLoggedOut();
  }, [window.location.href]);

  const [isAdminConsole, setIsAdminConsole] = useState();
  const [currentUserRole, setCurrentUserRole] = useState("");

  useEffect(() => {
    const adminConsole = sessionStorage.getItem("isAdminConsole");
    setIsAdminConsole(JSON.parse(adminConsole));

    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const role = getLoginDetailsLS?.role;
    setCurrentUserRole(role.replace(" ", "").toLowerCase());
  }, [, window.location.href]);

  const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLogged && currentUserRole != "") {
      setLoading(false);
    }
  }, [, isLogged, currentUserRole]);
  return (
    <>
      {sharePdf ? (
        <Routes>
          <Route path="jobcard-pdf/:orderId" element={<Sharejobcard />} />
        </Routes>
      ) : (
        <div
          style={{
            height: "100vh",
            overflow: "auto",
            overflowY: "auto",
            scrollbarGutter: "stable",
          }}
        >
          <Box
            className={classes.mainWrapper}
            sx={{ backgroundColor: "#E9E9E9" }}
          >
            <div className={classes.sidebarContainer}>
              <SideDrawer
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
              />
            </div>
            <div className={classes.mainContainer}>
              <MainHeader drawerWidth={drawerWidth} isLoggedOut={isLoggedOut} />
              {/* <Toolbar className={classes.toolbar} sx={{ padding: "2rem" }} /> */}

              {isUserProfile.includes("myAccount") ? (
                <div className={classes.profileContainer}>
                  {/* Main routes */}
                  <Routes>
                    <>
                      <Route path="/myAccount" element={<MyAccount />}>
                        <Route path="profile" element={<Profile />} />
                        <Route path="security" element={<Security />} />
                        <Route
                          path="multifactorAuth"
                          element={<MultifactorAuth />}
                        />
                        <Route path="settings" element={<Settings />} />
                        <Route
                          path="sessions"
                          element={<Sessions isGarageAdded={Sessions} />}
                        />
                        <Route path="groups" element={<Groups />} />
                        <Route path="privacy" element={<Privacy />} />
                        <Route path="compliance" element={<Compliance />} />

                        {!loading && (
                          <Route path="*" element={<NotFound404 />} />
                        )}
                      </Route>
                    </>
                  </Routes>
                </div>
              ) : isUserProfile.includes("adminConsole") && isAdminConsole ? (
                <div className={classes.profileContainer}>
                  {/* Main routes */}
                  <Routes>
                    <>
                      <Route path="/adminConsole" element={<AdminConsole />}>
                        <Route
                          path="dashboard"
                          element={<AdminConsoleDashboard />}
                        />

                        <Route path="users" element={<Users />} />
                        <Route
                          path="users/editUser/:userId"
                          element={<EditUser />}
                        />
                        <Route path="users/addUser" element={<AddUser />} />
                        <Route path="users/signUp" element={<SignUp />} />

                        {!loading && (
                          <Route path="*" element={<NotFound404 />} />
                        )}
                      </Route>
                    </>
                  </Routes>
                </div>
              ) : (
                <div>
                  {/* Main routes */}
                  <Routes>
                    {["superadmin", "admin"].includes(currentUserRole) &&
                    isLogged ? (
                      <>
                        <Route path="/" element={<Dashboard />} />
                        <Route
                          path="/leads"
                          element={<Leads isLeadAdded={isLeadAdded} />}
                        />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/orders/:orderType" element={<Orders />} />
                        <Route
                          path="/orders/history/:mobileNumber"
                          element={<OrderHistory />}
                        />
                        <Route
                          path="/garagedatabase"
                          element={
                            <GarageDatabase isGarageAdded={isGarageAdded} />
                          }
                        />
                        <Route
                          path="/garagefranchise"
                          element={
                            <GarageFranchise isGarageAdded={isGarageAdded} />
                          }
                        />
                        <Route
                          path="/editgarageinfo"
                          element={<EditGarageInfo />}
                        />
                        <Route
                          path="/garagepayout"
                          element={<GaragePayout />}
                        />

                        <Route
                          path="/approvedpayout"
                          element={<GarageApprovedPayout />}
                        />
                        <Route
                          path="/completepayout"
                          element={<CompletePayout />}
                        />
                        <Route
                          path="/garagereceivables"
                          element={<GarageReceivables />}
                        />
                        <Route
                          path="/garageinvoice"
                          element={<GarageInvoice />}
                        />
                        <Route
                          path="/salesreport1"
                          element={<SalesReport1 />}
                        />
                        <Route
                          path="/servicingsalesreport"
                          element={<ServicingSalesReport />}
                        />
                        <Route
                          path="/franchisesalesreport"
                          element={<FranchiseSalesReport />}
                        />
                        <Route
                          path="/salesreport2"
                          element={<SalesReport2 />}
                        />
                        <Route
                          path="/inventorydashboard"
                          element={<InventoryDashboard />}
                        />
                        <Route
                          path="/materiallibrary"
                          element={<MaterialLibrary />}
                        />
                        <Route
                          path="/materialbrands"
                          element={<MaterialBrands />}
                        />
                        <Route path="/productin" element={<ProductIn />} />
                        <Route path="/productout" element={<ProductOut />} />
                        <Route path="/vendors" element={<Vendors />} />
                        <Route
                          path="/balancesummary"
                          element={<BalanceSummary />}
                        />
                        <Route path="/gpdashboard" element={<GpDashboard />} />
                        <Route
                          path="/servicinggrossprofit"
                          element={<ServicingGrossProfit />}
                        />
                        <Route path="/grossprofit" element={<GrossProfit />} />
                        <Route
                          path="/franchisegrossprofits"
                          element={<FranchiseGrossProfit />}
                        />
                        <Route
                          path="/garagereceivablesgrossprofits"
                          element={<GarageReceivableGrossProfit />}
                        />
                        <Route path="/catalogue" element={<Catalogue />} />
                        <Route
                          path="/customerService"
                          element={<CustomerService />}
                        />
                        <Route path="/referal" element={<Referal />} />

                        {/* Sub routes */}
                        <Route
                          path="/leads/leadspanel/:leadId/:currentPage"
                          element={<LeadsPanel />}
                        />
                        <Route
                          path="/inspectionreport"
                          element={<InspectionReport />}
                        />
                        <Route
                          path="/orders/orderdetails/:type/:orderId/:name"
                          element={<OrderDetails />}
                        />
                        {/*   show all componies here */}
                        <Route
                          path="/insurance_companies"
                          element={<AllCompanies />}
                        />
                        <Route
                          path="/insurancetypes"
                          element={<AllInsuranceType />}
                        />
                        <Route
                          path="/servicetype"
                          element={<AllServiceTypes />}
                        />

                        <Route
                          path="/policytype"
                          element={<AllPolicyTypes />}
                        />

                        <Route path="/brokers" element={<Brokers />} />

                        <Route path="/abp" element={<ABP />} />

                        {!loading && (
                          <Route path="*" element={<NotFound404 />} />
                        )}
                      </>
                    ) : ["engineer", "serviceadvisor"].includes(
                        currentUserRole
                      ) && isLogged ? (
                      <>
                        <Route path="/" element={<Dashboard />} />
                        <Route
                          path="/leads"
                          element={<Leads isLeadAdded={isLeadAdded} />}
                        />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/orders/:orderType" element={<Orders />} />
                        <Route
                          path="/orders/history/:mobileNumber"
                          element={<OrderHistory />}
                        />
                        <Route
                          path="/garagedatabase"
                          element={
                            <GarageDatabase isGarageAdded={isGarageAdded} />
                          }
                        />
                        <Route
                          path="/garagefranchise"
                          element={
                            <GarageFranchise isGarageAdded={isGarageAdded} />
                          }
                        />
                        <Route
                          path="/garagepayout"
                          element={<GaragePayout />}
                        />

                        <Route
                          path="/approvedpayout"
                          element={<GarageApprovedPayout />}
                        />
                        <Route
                          path="/completepayout"
                          element={<CompletePayout />}
                        />
                        <Route
                          path="/garagereceivables"
                          element={<GarageReceivables />}
                        />
                        <Route
                          path="/garageinvoice"
                          element={<GarageInvoice />}
                        />
                        <Route
                          path="/salesreport1"
                          element={<SalesReport1 />}
                        />
                        <Route
                          path="/servicingsalesreport"
                          element={<ServicingSalesReport />}
                        />
                        <Route
                          path="/franchisesalesreport"
                          element={<FranchiseSalesReport />}
                        />
                        <Route
                          path="/salesreport2"
                          element={<SalesReport2 />}
                        />
                        <Route
                          path="/inventorydashboard"
                          element={<InventoryDashboard />}
                        />
                        <Route
                          path="/materiallibrary"
                          element={<MaterialLibrary />}
                        />
                        <Route
                          path="/materialbrands"
                          element={<MaterialBrands />}
                        />
                        <Route path="/productin" element={<ProductIn />} />
                        <Route path="/productout" element={<ProductOut />} />
                        <Route path="/vendors" element={<Vendors />} />
                        <Route
                          path="/balancesummary"
                          element={<BalanceSummary />}
                        />
                        <Route path="/gpdashboard" element={<GpDashboard />} />
                        <Route
                          path="/servicinggrossprofit"
                          element={<ServicingGrossProfit />}
                        />
                        <Route path="/grossprofit" element={<GrossProfit />} />
                        <Route
                          path="/franchisegrossprofits"
                          element={<FranchiseGrossProfit />}
                        />
                        <Route
                          path="/garagereceivablesgrossprofits"
                          element={<GarageReceivableGrossProfit />}
                        />
                        <Route path="/catalogue" element={<Catalogue />} />
                        <Route
                          path="/customerService"
                          element={<CustomerService />}
                        />
                        <Route path="/referal" element={<Referal />} />

                        {/* Sub routes */}
                        <Route
                          path="/leads/leadspanel/:leadId/:currentPage"
                          element={<LeadsPanel />}
                        />
                        <Route
                          path="/inspectionreport"
                          element={<InspectionReport />}
                        />
                        <Route
                          path="/orders/orderdetails/:type/:orderId/:name"
                          element={<OrderDetails />}
                        />
                        {/*   show all componies here */}
                        <Route
                          path="/insurance_companies"
                          element={<AllCompanies />}
                        />
                        <Route
                          path="/insurancetypes"
                          element={<AllInsuranceType />}
                        />
                        <Route
                          path="/servicetype"
                          element={<AllServiceTypes />}
                        />

                        <Route
                          path="/policytype"
                          element={<AllPolicyTypes />}
                        />
                        <Route path="/brokers" element={<Brokers />} />

                        {/*   show all componies here */}
                        <Route
                          path="/insurance_companies"
                          element={<AllCompanies />}
                        />
                        <Route
                          path="/insurancetypes"
                          element={<AllInsuranceType />}
                        />
                        <Route
                          path="/servicetype"
                          element={<AllServiceTypes />}
                        />

                        <Route
                          path="/policytype"
                          element={<AllPolicyTypes />}
                        />
                        <Route path="/brokers" element={<Brokers />} />

                        <Route path="/abp" element={<ABP />} />

                        {!loading && (
                          <Route path="*" element={<NotFound404 />} />
                        )}
                      </>
                    ) : ["leadowner"].includes(currentUserRole) && isLogged ? (
                      <>
                        <Route
                          path="/leads"
                          element={<Leads isLeadAdded={isLeadAdded} />}
                        />
                        <Route
                          path="/leads/leadspanel/:leadId/:currentPage"
                          element={<LeadsPanel />}
                        />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/orders/:orderType" element={<Orders />} />
                        <Route
                          path="/orders/history/:mobileNumber"
                          element={<OrderHistory />}
                        />
                        <Route
                          path="/orders/orderdetails/:type/:orderId/:name"
                          element={<OrderDetails />}
                        />
                        <Route path="/catalogue" element={<Catalogue />} />
                        <Route
                          path="/customerService"
                          element={<CustomerService />}
                        />
                        <Route
                          path="/"
                          element={<Navigate to="/leads" replaced />}
                        />
                        {/*   show all componies here */}
                        <Route
                          path="/insurance_companies"
                          element={<AllCompanies />}
                        />
                        <Route
                          path="/insurancetypes"
                          element={<AllInsuranceType />}
                        />
                        <Route
                          path="/servicetype"
                          element={<AllServiceTypes />}
                        />

                        <Route
                          path="/policytype"
                          element={<AllPolicyTypes />}
                        />
                        <Route path="/brokers" element={<Brokers />} />

                        <Route path="/abp" element={<ABP />} />
                      </>
                    ) : ["gativanexpert"].includes(currentUserRole) &&
                      isLogged ? (
                      <>
                        <Route path="/catalogue" element={<Catalogue />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/orders/:orderType" element={<Orders />} />
                        <Route
                          path="/orders/history/:mobileNumber"
                          element={<OrderHistory />}
                        />
                        <Route
                          path="/orders/orderdetails/:type/:orderId/:name"
                          element={<OrderDetails />}
                        />
                        <Route
                          path="/"
                          element={<Navigate to="/orders" replaced />}
                        />
                        {/*   show all componies here */}
                        <Route
                          path="/insurance_companies"
                          element={<AllCompanies />}
                        />
                        <Route
                          path="/insurancetypes"
                          element={<AllInsuranceType />}
                        />
                        <Route
                          path="/servicetype"
                          element={<AllServiceTypes />}
                        />

                        <Route
                          path="/policytype"
                          element={<AllPolicyTypes />}
                        />
                        <Route path="/brokers" element={<Brokers />} />

                        <Route path="/abp" element={<ABP />} />
                        {!loading && (
                          <Route path="*" element={<NotFound404 />} />
                        )}
                      </>
                    ) : (
                      <>
                        {!loading && (
                          <Route path="*" element={<NotFound404 />} />
                        )}
                      </>
                    )}
                  </Routes>
                </div>
              )}
            </div>
          </Box>

          {openDatePicker && <DatePicker />}
          {openAddLeads && <AddLeads setIsLeadAdded={setIsLeadAdded} />}
          {openAddGarage && <AddGarage setIsGarageAdded={setIsGarageAdded} />}
          {openAlert && <AlertMessage />}
          {openMonthPicker && (
            <MonthPicker
              openMonthPicker={openMonthPicker}
              handleOpenMonthPicker={handleOpenMonthPicker}
              handleCloseMonthPicker={handleCloseMonthPicker}
              // selectMonth={selectMonth}
              // setSelectMonth={setSelectMonth}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Routing;
