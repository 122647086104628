import React, { useState } from "react";
import XlsExport from "xlsexport";
import jwtDecode from "jwt-decode";
import { Link, useLocation } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import moment from "moment";
import SyncIcon from "@mui/icons-material/Sync";

const useCommonDataHook = () => {
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openAddLeads, setOpenAddLeads] = React.useState(false);
  const [openAddGarage, setOpenAddGarage] = React.useState(false);
  const [openAddMaterialModel, setOpenAddMaterialModel] = useState(false);
  const [openAddMaterialLibModel, setOpenAddMaterialLibModel] = useState(false);
  const [openAddCustomerIssues, setOpenAddCustomerIssues] = useState(false);
  const [openAddMaterialBrandModel, setOpenAddMaterialBrandModel] =
    useState(false);

  const [openMonthPicker, setOpenMonthPicker] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();

  const [filteredDate, setFilteredDate] = useState(null);
  const [filterModel, setFilterModel] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({ items: [] });
  const [downloadCmsList, setDownloadCmsList] = useState([]);

  const [openAddUserModel, setOpenAddUserModel] = useState(false);

  const [isOpenAddProductModel, setIsOpenAddProductModel] = useState(false);
  const [isOpenAddVendorModel, setIsOpenAddVendorModel] = useState(false);
  const [customerInvoiceDetails, setCustomerInvoiceDetails] = useState({});
  const [isDownloadCMSReport, setIsDownloadCMSReport] = useState(false);

  const handleOpenDatePicker = () => setOpenDatePicker(true);
  const handleCloseDatePicker = () => setOpenDatePicker(false);
  const handleOpenLeads = () => setOpenAddLeads(true);
  const handleCloseLeads = () => setOpenAddLeads(false);
  const handleOpenGarage = () => setOpenAddGarage(true);
  const handleCloseGarage = () => setOpenAddGarage(false);

  const handleOpenAddMaterialModel = () => setOpenAddMaterialModel(true);
  const handleCloseAddMaterialModel = () => setOpenAddMaterialModel(false);

  const handleOpenAddProductModel = () => setIsOpenAddProductModel(true);
  const handleCloseAddProductModel = () => setIsOpenAddProductModel(false);

  const handleOpenAddVendorModel = () => setIsOpenAddVendorModel(true);
  const handleCloseAddVendorModel = () => setIsOpenAddVendorModel(false);

  const handleOpenAddMaterialLibModel = () => setOpenAddMaterialLibModel(true);
  const handleCloseAddMaterialLibModel = () =>
    setOpenAddMaterialLibModel(false);

  const downloadCMS = () => {
    setIsDownloadCMSReport(true);
  };

  const handleOpenAddMaterialBrandModel = () =>
    setOpenAddMaterialBrandModel(true);
  const handleCloseAddMaterialBrandModel = () =>
    setOpenAddMaterialBrandModel(false);

  const handleOpenAddCustomerIssue = () => setOpenAddCustomerIssues(true);

  const handleCloseAddCustomerIssue = () => setOpenAddCustomerIssues(false);

  const handleOpenAddUserModel = () => setOpenAddUserModel(true);
  const handleCloseAddUserModel = () => setOpenAddUserModel(false);

  const handleOpenMonthPicker = () => setOpenMonthPicker(true);
  const handleCloseMonthPicker = () => setOpenMonthPicker(false);

  const [currentOrders, setCurrentOrders] = useState("General");
  const [addCompany, setAddCompany] = useState(false);
  const [addInsuranceType, setAddInsuranceType] = useState(false);
  const [addServiceType, setAddServiceType] = useState(false);
  const [addPolicyType, setAddPolicyType] = useState(false);
  const [addBroker, setAddBroker] = useState(false);
  const [addFranchise, setAddFranchise] = useState(false);

  const handleOpenAddFranchise = () => setAddFranchise(true);
  const handleCloseAddFranchise = () => setAddFranchise(false);

  // ********************************* SET DEFAULT DATE START************************************

  const date = new Date();
  const start = new Date(date.getFullYear(), date.getMonth() - 2, 1);
  // const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const end = new Date();
  const [defaultDate, setDefaultDate] = useState([
    {
      startDate: start,
      endDate: end,
      key: "selection",
    },
  ]);

  const isTokenExpired = (token) => {
    if (jwtDecode(token).exp < Date.now() / 1000) {
      const loginDetail = {
        isLogged: false,
        jwtToken: "",
        role: "",
      };

      localStorage.setItem("loginDetails", JSON.stringify(loginDetail));
      window.location.reload();
    }
  };

  // ********************************* SET DEFAULT DATE END************************************

  // const [currentUserRole, setCurrentUserRole] = useState("");

  // useEffect(() => {
  //   const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
  //   const role = getLoginDetailsLS?.role;
  //   setCurrentUserRole(role.replace(" ", "").toLowerCase());
  // }, [, window.location.href]);

  // const viewAdminConsole = (result) => setIsAdminConsole(result);

  // const handleCloseAddUserModel = () => setOpenAddUserModel(false);

  // Download table's data into XLSX file
  const downloadXLS = (downloadList, file) => {
    // console.log("downloadList", downloadList);

    // const downloadData = downloadList.map((data) => {
    //   return {
    //     ...data,
    //     nonCatalogueJobs: data?.nonCatalogueJobs?.join(","),
    //     service: data?.service?.join(","),
    //   };
    // });

    // console.log("downloadList", downloadData);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let fileName = file || "Records";

    const ws = XLSX.utils.json_to_sheet(downloadList);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);

    // const xls = new XlsExport([...downloadList], "Title");
    // xls.exportToXLS("Records.xls");
    if (!file) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  // *************************FILTER DATE IN RANGE START*************************************

  const SUBMIT_FILTER_STROKE_TIME = 500;

  function InputNumberInterval(props) {
    const { item, applyValue, focusElementRef = null } = props;

    const filterTimeout = React.useRef();
    const [filterValueState, setFilterValueState] = React.useState(
      item.value ?? ""
    );

    const [applying, setIsApplying] = React.useState(false);

    React.useEffect(() => {
      return () => {
        clearTimeout(filterTimeout.current);
      };
    }, []);

    React.useEffect(() => {
      const itemValue = item.value ?? [undefined, undefined];
      setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (startDate, endDate) => {
      clearTimeout(filterTimeout.current);
      setFilterValueState([startDate, endDate]);

      setIsApplying(true);
      filterTimeout.current = setTimeout(() => {
        setIsApplying(false);
        applyValue({ ...item, value: [startDate, endDate] });
      }, SUBMIT_FILTER_STROKE_TIME);
    };

    const handleUpperFilterChange = (event) => {
      const newUpperBound = event.target.value;
      updateFilterValue(filterValueState[0], newUpperBound);
    };
    const handleLowerFilterChange = (event) => {
      const startDateValue = event.target.value;
      updateFilterValue(startDateValue, filterValueState[1]);
    };

    return (
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "end",
          height: 48,
          pl: "20px",
        }}
      >
        <TextField
          name="lower-bound-input"
          placeholder="From"
          // label="From"
          variant="standard"
          value={filterValueState[0]}
          onChange={handleLowerFilterChange}
          type="date"
          inputRef={focusElementRef}
          sx={{ mr: 2 }}
        />
        <TextField
          name="upper-bound-input"
          fullWidth
          placeholder="To"
          // label="To"
          variant="standard"
          value={filterValueState[1]}
          onChange={handleUpperFilterChange}
          type="date"
          InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
          sx={{ width: "150px" }}
        />
      </Box>
    );
  }

  const filterDateInRange = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
  ];

  // *********************************FILTER DATE IN RANGE END************************************
  // Filter data in renge by date
  const filterDateRenge = (column) => {
    setFilterModel([
      {
        columnField: column,
        operatorValue: "between",
        value: [
          moment(filteredDate && filteredDate[0] && filteredDate[0]?.startDate)
            ?.format()
            ?.split("T")[0],
          moment(filteredDate && filteredDate[0] && filteredDate[0]?.endDate)
            ?.format()
            ?.split("T")[0],
        ],
        // linkOperator: GridLinkOperator.Or,
      },
    ]);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clearAppliedFilters = () => {
    setFilteredDate(null);
    setFilterModel([]);
    setApplyFilter(false);
    setAppliedFilters({ items: [] });
    setSelectedMonth();
    // window.location.reload();
  };

  // useEffect(() => {
  //   const date = {
  //     start: moment(filteredDate && filteredDate[0]?.startDate).format(
  //       "YYYY-MM-DD"
  //     ),
  //     end: moment(filteredDate && filteredDate[0]?.endDate).format(
  //       "YYYY-MM-DD"
  //     ),
  //   };

  //   setAppliedDate(date);
  // }, [filteredDate]);

  const storeDataInCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));

    if ("caches" in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };

  const getCachedData = async (cacheName, url) => {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    if (!cachedResponse || !cachedResponse.ok) {
      return false;
    }
    // console.log("22222222", await cachedResponse.json());
    return await cachedResponse.json();
  };

  // Error Handling alert messages.

  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const colors = {
    error: "red",
    success: "green",
  };

  const handleAlertOpen = (message, color) => {
    setOpenAlert(true);
    setErrorMessage({
      message: message,
      color: color,
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setErrorMessage("");
  };
  const [orderType, setOrderType] = useState(null);
  const getOrdersByOrderType = (orderTypeId) => {
    setOrderType(orderTypeId);
    // setOrderType((prev) => !prev);
  };

  // ------------------------- TOGGLE APPROVED ORDERS AND GARAGES CMS REPORT -----------------------------------------
  const [ordersGaragesToggle, setOrdersGaragesToggle] = useState("orders");
  const approvedToggle = (type) => {
    setOrdersGaragesToggle(type);
  };

  const shareCommonData = {
    downloadXLS: downloadXLS,
    handleOpenDatePicker: handleOpenDatePicker,
    filteredDate: filteredDate,
    filterDateRenge: filterDateRenge,
    filterDateInRange: filterDateInRange,
    filterModel: filterModel,
    setFilterModel: setFilterModel,
    applyFilter: applyFilter,
    setFilteredDate: setFilteredDate,
    openDatePicker: openDatePicker,
    handleCloseDatePicker: handleCloseDatePicker,
    setApplyFilter: setApplyFilter,
    handleDrawerToggle: handleDrawerToggle,
    handleOpenLeads: handleOpenLeads,
    handleOpenGarage: handleOpenGarage,
    handleCloseLeads: handleCloseLeads,
    handleCloseGarage: handleCloseGarage,
    handleDrawerToggle: handleDrawerToggle,
    openAddLeads: openAddLeads,
    openAddGarage: openAddGarage,
    mobileOpen: mobileOpen,
    openAddMaterialModel: openAddMaterialModel,
    handleOpenAddMaterialModel: handleOpenAddMaterialModel,
    handleCloseAddMaterialModel: handleCloseAddMaterialModel,
    isOpenAddProductModel: isOpenAddProductModel,
    handleOpenAddProductModel: handleOpenAddProductModel,
    handleCloseAddProductModel: handleCloseAddProductModel,
    isOpenAddVendorModel: isOpenAddVendorModel,
    handleOpenAddVendorModel: handleOpenAddVendorModel,
    handleCloseAddVendorModel: handleCloseAddVendorModel,
    openAddMaterialLibModel: openAddMaterialLibModel,
    handleOpenAddMaterialLibModel: handleOpenAddMaterialLibModel,
    handleCloseAddMaterialLibModel: handleCloseAddMaterialLibModel,
    openAddMaterialBrandModel: openAddMaterialBrandModel,
    handleOpenAddMaterialBrandModel: handleOpenAddMaterialBrandModel,
    handleCloseAddMaterialBrandModel: handleCloseAddMaterialBrandModel,
    openAddUserModel: openAddUserModel,
    handleOpenAddUserModel: handleOpenAddUserModel,
    handleCloseAddUserModel: handleCloseAddUserModel,
    // viewAdminConsole: viewAdminConsole,
    // isAdminConsole: isAdminConsole,
    // currentUserRole: currentUserRole,
    clearAppliedFilters: clearAppliedFilters,
    setAppliedFilters: setAppliedFilters,
    appliedFilters: appliedFilters,
    storeDataInCache: storeDataInCache,
    getCachedData: getCachedData,
    customerInvoiceDetails: customerInvoiceDetails,
    setCustomerInvoiceDetails: setCustomerInvoiceDetails,
    openAlert: openAlert,
    handleAlertOpen: handleAlertOpen,
    handleAlertClose: handleAlertClose,
    errorMessage: errorMessage,
    colors: colors,
    downloadCmsList: downloadCmsList,
    setDownloadCmsList: setDownloadCmsList,
    isDownloadCMSReport: isDownloadCMSReport,
    downloadCMS: downloadCMS,
    setIsDownloadCMSReport: setIsDownloadCMSReport,
    getOrdersByOrderType: getOrdersByOrderType,
    orderType: orderType,
    setOrderType: setOrderType,
    currentOrders: currentOrders,
    setCurrentOrders: setCurrentOrders,
    defaultDate: defaultDate,
    setDefaultDate: setDefaultDate,
    handleOpenMonthPicker: handleOpenMonthPicker,
    handleCloseMonthPicker: handleCloseMonthPicker,
    openMonthPicker: openMonthPicker,
    selectedMonth: selectedMonth,
    setSelectedMonth: setSelectedMonth,
    approvedToggle: approvedToggle,
    ordersGaragesToggle: ordersGaragesToggle,
    addCompany,
    setAddCompany,
    addInsuranceType,
    setAddInsuranceType,
    addServiceType,
    setAddServiceType,
    addPolicyType,
    setAddPolicyType,
    addBroker,
    setAddBroker,
    addFranchise,
    handleOpenAddFranchise,
    handleCloseAddFranchise,
    isTokenExpired: isTokenExpired,
    openAddCustomerIssues: openAddCustomerIssues,
    handleOpenAddCustomerIssue: handleOpenAddCustomerIssue,
    handleCloseAddCustomerIssue: handleCloseAddCustomerIssue,
  };

  return {
    shareCommonData,
  };
};

export default useCommonDataHook;
