import React from "react";
import { Typography } from "@mui/material";

const Groups = () => {
  return (
    <div style={{ padding: "3rem" }}>
      {" "}
      <Typography variant="h4" className="titles">Groups</Typography>
    </div>
  );
};

export default Groups;
