import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  getVehicleType,
  getBrands,
  getModels,
  getVariants,
} from "../../services/leadServices/LeadServices";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  saveLead,
  getLeadChannels,
} from "../../services/leadServices/LeadServices";
import "./AddIssue.css";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";
import {
  getIssueResolution,
  getIssueStatuses,
  getIssues,
  getRootCause,
  postNewIssue,
} from "../../services/customerServices/CustomerServices";
import { getCustomerAdvisor, getOrderById } from "../../services/Services";

const AddIssue = ({ setIsIssueAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [issue, setIssues] = React.useState({});
  const [issuesList, setIssuesList] = useState([]);
  const [issueStatuses, setIssueStatuses] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [rootCauses, setRootCauses] = useState([]);
  const [serviceAdvisor, setServiceAdvisor] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);

  const [vehicleTypeId, setVehicleTypeId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [modelId, setModelId] = useState("");
  const [variantId, setVariantId] = useState("");
  const [initVehicleType, setInitVehicleType] = useState("");

  const [issueDetails, setIssueDetails] = useState({});
  const [temp, setTemp] = useState({});

  // GET CONTEXT COMMON DATA
  const { handleCloseAddCustomerIssue, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const orderById = async (orderId) => {
    try {
      const response = await getOrderById(orderId);
      const data = await response.data;
      const result = data.result;

      const obj = {
        firstName: result?.user?.first_name,
        lastName: result?.user?.last_name,
        mobileNumber: result?.user?.mobile_number,
        vehicleType: result?.vehicle?.vehicleType,
        brand: result?.vehicle?.brand,
        model: result?.vehicle?.model,
        variant: result?.vehicle?.variant,
      };

      setVehicleTypeId(obj?.vehicleType?.id);
      setBrandId(obj?.brand?.brandId);
      setModelId(obj?.model?.modelId);
      setVariantId(obj?.variant?.id);

      setInitVehicleType(obj?.vehicleType?.id);
      setTemp(obj);

      setIssueDetails({
        ...issueDetails,
        firstName: result?.user?.first_name,
        lastName: result?.user?.last_name,
        mobileNumber: result?.user?.mobile_number,
        vehicleType: result?.vehicle?.vehicleType,
        brand: result?.vehicle?.brand,
        model: result?.vehicle?.model,
        variant: result?.vehicle?.variant,
      });

      // setOrderInfo(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const userSelection = (fieldName, name, id) => {
    if (fieldName == "vehicleType") {
      setVehicleTypeId(id);
    }

    const obj = {
      name,
      id,
    };
    setIssueDetails({ ...issueDetails, [fieldName]: obj });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "brand") {
      const brandObj = vehicleBrands[value];
      vehicleBrands.find((brand) => {
        if (brand.brand_Id == brandObj.brand_Id) {
          setBrandId(brandObj.brand_Id);
          setIssueDetails({
            ...issueDetails,
            [name]: {
              brandId: brandObj?.brand_Id,
              brand: brandObj?.brand,
              image_url: brandObj?.image_url,
            },
          });
        }
      });
    }

    if (name == "model") {
      const modelObj = vehicleModels[value];
      vehicleModels.find((model) => {
        if (model.model_id == modelObj.model_id) {
          setModelId(modelObj.model_id);
          setIssueDetails({
            ...issueDetails,
            [name]: {
              modelId: modelObj?.model_id,
              model: modelObj?.model,
              image_url: modelObj?.image_url,
            },
          });
        }
      });
    }

    if (name == "variant") {
      const variantObj = vehicleVariants[value];

      vehicleVariants.find((variant) => {
        if (variant.variantId == variantObj.variantId) {
          setIssueDetails({
            ...issueDetails,
            [name]: {
              id: variantObj?.variantId,
              variantName: variantObj?.variantName,
              image_url: variantObj?.image_url,
            },
          });
        }
      });
    }
  };

  const postIssue = async (postObj) => {
    try {
      const response = await postNewIssue(postObj);
      const data = await response.data;
      navigate("/customerservice");
      handleAlertOpen(data?.message, colors?.success);
    } catch (error) {
      console.log(error);
      handleAlertOpen(error?.response?.data?.message, colors?.error);
    }
  };

  useEffect(() => {
    if (issueDetails?.orderId?.length >= 16) {
      orderById(issueDetails?.orderId);
    }
  }, [issueDetails?.orderId]);

  const AddIssue = () => {
    // alert("Hello");

    const postObj = {
      // ...issueDetails,
      mobileNumber: issueDetails?.mobileNumber,
      remark: issueDetails?.remark,
      orderId: issueDetails?.orderId,
      vehicleType: issueDetails?.vehicleType?.id,
      customerName: `${issueDetails?.firstName} ${issueDetails?.lastName}`,
      brandId: issueDetails?.brand?.brandId,
      modelId: issueDetails?.model?.modelId,
      variantId: issueDetails?.variant?.id,
      issue: issueDetails?.issue?.id,
      rootCause: issueDetails?.rootCause?.id,
      resolution: issueDetails?.resolution?.id,
      serviceAdvisor: issueDetails?.serviceAdvisor?.id,
    };

    if (postObj?.brandId && postObj?.modelId && postObj?.variantId) {
      postIssue(postObj);
      handleCloseAddCustomerIssue();
      setIsIssueAdded((prev) => !prev);
    } else {
      handleAlertOpen("Vehicle Details are required.", colors?.error);
    }
  };

  const discardDetails = () => {
    setIssueDetails({
      firstName: "",
      lastName: "",
      orderId: "",
      mobileNumber: "",
      brand: null,
      model: null,
      variant: null,
      issue: null,
      resolution: null,
      rootCause: null,
      issueName: "",
      resolutionName: "",
      rootCauseName: "",
    });
  };

  // Get vehicle types
  const getVehicleTypes = async () => {
    try {
      const response = await getVehicleType();
      const result = await response.data;
      setVehicleTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get Customer issues list

  const getIssuesList = async () => {
    try {
      const response = await getIssues();
      const data = await response.data;
      setIssuesList(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getIssueStatusesList = async () => {
    try {
      const response = await getIssueStatuses();
      const data = await response.data;
      setIssueStatuses(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getResolutionsList = async () => {
    try {
      const response = await getIssueResolution();
      const data = await response.data;
      setResolutions(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getRootCauseList = async () => {
    try {
      const response = await getRootCause();
      const data = await response.data;
      setRootCauses(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceAdvisor = async () => {
    try {
      const request = await getCustomerAdvisor();
      const response = await request.data;
      setServiceAdvisor(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get brands

  const getVehicleBrands = async () => {
    try {
      const response = await getBrands(vehicleTypeId);
      const result = await response.data;
      setVehicleBrands(result.result);
    } catch (error) {}
  };
  // Get modals
  // const brandId = issueDetails.brand && issueDetails.brand.id;
  const getVehicleModels = async () => {
    try {
      const response = await getModels(vehicleTypeId, brandId);
      const result = await response.data;
      setVehicleModels(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // const modelId = issueDetails.model && issueDetails.model.id;
  const getVehicleVariants = async () => {
    try {
      const response = await getVariants(modelId);
      const result = await response.data;
      setVehicleVariants(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(() => {
    getIssuesList();
    getIssueStatusesList();
    getResolutionsList();
    getRootCauseList();
    getServiceAdvisor();
    getVehicleTypes();
  }, []);

  useMemo(() => {
    setVehicleBrands([]);
    setVehicleModels([]);
    setVehicleVariants([]);
    vehicleTypeId && getVehicleBrands();

    if (initVehicleType != vehicleTypeId) {
      // setInitLead(lead);
      setVehicleBrands([]);
      setVehicleModels([]);
      setVehicleVariants([]);
      setIssueDetails({
        ...issueDetails,
        vehicleType: vehicleTypes?.filter(
          (vehicle) => vehicle?.id == vehicleTypeId
        )[0],
        brand: null,
        model: null,
        variant: null,
      });
    } else {
      setIssueDetails({
        ...issueDetails,
        vehicleType: vehicleTypes?.filter(
          (vehicle) => vehicle?.id == vehicleTypeId
        )[0],
        brand: temp?.brand,
        model: temp?.model,
        variant: temp?.variant,
      });
    }

    // setIssueDetails({ ...issueDetails, brand: {}, model: {}, variant: {} });
  }, [vehicleTypeId]);

  useMemo(() => {
    setVehicleModels([]);
    setVehicleVariants([]);

    if (brandId != temp?.brand?.brandId) {
      setIssueDetails({ ...issueDetails, model: {}, variant: {} });
    }

    brandId && getVehicleModels();
  }, [brandId]);

  useMemo(() => {
    if (modelId != temp?.model?.modelId) {
      setIssueDetails({ ...issueDetails, variant: {} });
    }
    setVehicleVariants([]);
    modelId && getVehicleVariants();
  }, [modelId]);

  const renderVehicleTypes = () => {
    return (
      <Grid item sm={8}>
        <ul className="donate-now">
          {vehicleTypes &&
            vehicleTypes.map((types, index) => {
              return (
                <li key={index}>
                  <input
                    type="radio"
                    id={types.vehicleType}
                    name="vehicleType"
                    value={types.id}
                    defaultChecked={
                      issueDetails?.vehicleType &&
                      types?.vehicleType ==
                        issueDetails?.vehicleType?.vehicleType
                        ? true
                        : false
                    }
                    onClick={() =>
                      userSelection("vehicleType", types.vehicleType, types.id)
                    }
                  />
                  <label
                    style={{
                      backgroundColor:
                        types?.vehicleType ==
                        issueDetails?.vehicleType?.vehicleType
                          ? "#7b8bd4"
                          : "",
                    }}
                    for={types.vehicleType}
                  >
                    {types.vehicleType}
                  </label>
                </li>
              );
            })}
        </ul>
      </Grid>
    );
  };

  const renderIssues = () => {
    return (
      <TextField
        required
        size="small"
        label="Issues"
        fullWidth
        select
        variant="outlined"
        id="issue"
        margin="dense"
        name="issue"
        value={issueDetails.issue && issueDetails.issue.issue}
      >
        {issuesList.map((issue) => (
          <MenuItem
            key={issue.id}
            value={issue.id}
            onClick={() => userSelection("issue", issue.issue, issue.id)}
          >
            {issue.issue}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderRootCauses = () => {
    return (
      <TextField
        required
        size="small"
        label="Root Causes"
        fullWidth
        select
        variant="outlined"
        id="rootCause"
        margin="dense"
        name="rootCause"
        value={issueDetails.rootCauses && issueDetails.rootCauses.rootCauses}
      >
        {rootCauses &&
          rootCauses.map((rootCause) => (
            <MenuItem
              key={rootCause.id}
              value={rootCause.id}
              onClick={() =>
                userSelection("rootCause", rootCause.rootCause, rootCause.id)
              }
            >
              {rootCause.rootCause}
            </MenuItem>
          ))}
      </TextField>
    );
  };

  const renderResolutions = () => {
    return (
      <TextField
        required
        size="small"
        label="Resolution"
        fullWidth
        select
        variant="outlined"
        id="resolution"
        margin="dense"
        name="resolution"
        value={issueDetails.resolution && issueDetails.resolution.name}
      >
        {resolutions.map((resolution) => (
          <MenuItem
            key={resolution.id}
            value={resolution.id}
            onClick={() =>
              userSelection("resolution", resolution.resolution, resolution.id)
            }
          >
            {resolution.resolution}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderServiceAdvisors = () => {
    return (
      <TextField
        required
        size="small"
        label="Service Advisor"
        fullWidth
        select
        variant="outlined"
        id="serviceAdvisor"
        margin="dense"
        name="serviceAdvisor"
        value={issueDetails.serviceAdvisor && issueDetails.serviceAdvisor}
      >
        {serviceAdvisor.map((advisor) => (
          <MenuItem
            key={advisor.id}
            value={advisor.id}
            onClick={() =>
              userSelection(
                "serviceAdvisor",
                `${advisor.firstName} ${advisor.lastName}`,
                advisor.id
              )
            }
          >
            {advisor.firstName}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderIssueStatuses = () => {
    return (
      <TextField
        required
        size="small"
        label="Status"
        fullWidth
        select
        variant="outlined"
        id="status"
        margin="dense"
        name="status"
        value={issueDetails.status && issueDetails.status}
      >
        {issueStatuses.map((status) => (
          <MenuItem
            key={status.statusId}
            value={status.statusId}
            onClick={() =>
              userSelection("status", status.status, status.statusId)
            }
          >
            {status.status}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderVehicleBrands = () => {
    return (
      <select
        style={{ marginTop: "10px" }}
        name="brand"
        onChange={handleChange}
        className="selectDropdown"
      >
        <option value={issueDetails?.brand && issueDetails?.brand.brand}>
          {issueDetails?.brand && issueDetails?.brand?.brand}
        </option>
        {vehicleBrands &&
          vehicleBrands.map((brand, index) => {
            return (
              <option value={index} key={brand.brand_Id}>
                {brand.brand}
              </option>
            );
          })}
      </select>
    );
  };

  const renderVehicleModels = () => {
    return (
      <select
        style={{ marginTop: "10px" }}
        name="model"
        onChange={handleChange}
        className="selectDropdown"
      >
        <option value={issueDetails?.model && issueDetails?.model?.model}>
          {issueDetails?.model && issueDetails?.model?.model}
        </option>
        {vehicleModels &&
          vehicleModels.map((model, index) => {
            return (
              <option value={index} key={model.model_id}>
                {model.model}
              </option>
            );
          })}
      </select>
    );
  };

  const renderVehicleVariants = () => {
    return (
      <select
        style={{ marginTop: "10px" }}
        name="variant"
        onChange={handleChange}
        className="selectDropdown"
      >
        <option
          value={issueDetails?.variant && issueDetails?.variant?.variantName}
        >
          {issueDetails?.variant && issueDetails?.variant?.variantName}
        </option>
        {vehicleVariants &&
          vehicleVariants.map((variant, index) => {
            return (
              <option value={index} key={variant.variantId}>
                {variant.variantName}
              </option>
            );
          })}
      </select>
    );
  };

  const renderForm = [
    {
      label: "Customer",
      fields: [
        {
          label: issueDetails?.firstName ? "" : "First Name",
          field: "TextField",
          name: "firstName",
          id: "firstName",
          type: "text",
          grids: 6,
        },
        {
          label: issueDetails?.lastName ? "" : "Last Name",
          field: "TextField",
          name: "lastName",
          id: "lastName",
          type: "text",
          grids: 6,
        },
      ],
    },
    {
      label: "Order Id",
      fields: [
        {
          label: "Order Id",
          field: "TextField",
          name: "orderId",
          id: "orderId",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Mobile Number",
      fields: [
        {
          label: issueDetails?.mobileNumber ? "" : "Mobile Number",
          field: "TextField",
          name: "mobileNumber",
          id: "mobileNumber",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "Vehicle Type",
      fields: [
        {
          label: "Vehicle Type",
          field: "Toggles",
          name: "vehicleType",
          id: "vehicleType",
          data: vehicleTypes,
          properties: { name: "vehicleType", id: "id" },
          type: "text",
          render: renderVehicleTypes,
          grids: 12,
        },
      ],
    },
    {
      label: "Vehicle Name",
      fields: [
        {
          label: "Brand",
          field: "Dropdown",
          name: "brand",
          id: "brand",
          data: vehicleBrands,
          properties: { name: "brand", id: "brand_Id" },
          type: "text",
          render: renderVehicleBrands,
          grids: 6,
        },
        {
          label: "Model",
          field: "Dropdown",
          name: "model",
          id: "model",
          data: vehicleModels,
          properties: { name: "model", id: "model_id" },
          type: "text",
          render: renderVehicleModels,
          grids: 6,
        },
      ],
    },
    {
      label: "Vehicle Variant",
      fields: [
        {
          label: "Variant",
          field: "Dropdown",
          name: "variant",
          id: "variant",
          data: vehicleVariants,
          properties: { name: "variantName", id: "variantId" },
          type: "text",
          render: renderVehicleVariants,
          grids: 6,
        },
      ],
    },
    {
      label: "Issues",
      fields: [
        {
          label: "Issues",
          field: "Dropdown",
          name: "issues",
          id: "issues",
          data: issuesList,
          properties: { name: "issues", id: "id" },
          type: "text",
          render: renderIssues,
          grids: 12,
        },
      ],
    },
    {
      label: "Root Cause",
      fields: [
        {
          label: "Root Cause",
          field: "Dropdown",
          name: "rootCause",
          id: "rootCause",
          data: rootCauses,
          properties: { name: "rootCause", id: "id" },
          type: "text",
          render: renderRootCauses,
          grids: 12,
        },
      ],
    },
    {
      label: "Resolutions",
      fields: [
        {
          label: "Resolutions",
          field: "Dropdown",
          name: "resolutions",
          id: "resolutions",
          data: resolutions,
          properties: { name: "resolutions", id: "id" },
          type: "text",
          render: renderResolutions,
          grids: 12,
        },
      ],
    },
    {
      label: "Service Advisors",
      fields: [
        {
          label: "Service Advisors",
          field: "Dropdown",
          name: "serviceAdvisors",
          id: "serviceAdvisors",
          data: serviceAdvisor,
          properties: { name: "serviceAdvisora", id: "id" },
          type: "text",
          render: renderServiceAdvisors,
          grids: 12,
        },
      ],
    },
    {
      label: "Status",
      fields: [
        {
          label: "Status",
          field: "Dropdown",
          name: "status",
          id: "status",
          data: resolutions,
          properties: { name: "status", id: "statusId" },
          type: "text",
          render: renderIssueStatuses,
          grids: 12,
        },
      ],
    },
    {
      label: "Remark",
      fields: [
        {
          label: "Remark",
          field: "TextField",
          name: "remark",
          id: "remark",
          type: "text",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Issue"
        closeModal={handleCloseAddCustomerIssue}
        isAdded={setIsIssueAdded}
        renderForm={renderForm}
        service={saveLead}
        state={issueDetails}
        setState={setIssueDetails}
        submit={AddIssue}
        discard={discardDetails}
        navigate="/customerservice"
      />
    </div>
  );
};

export default AddIssue;