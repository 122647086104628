import React, { useContext, useEffect, useState } from "react";
import Table from "../../components/tables/Table";
import moment from "moment";
import { Box, Button, ButtonBase, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const BalanceSummary = () => {
  const commonData = useContext(CommonContextAPI);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    applyFilter,
    getSelectedDate,
    setTableView,
    setFilteredDate,
  } = commonData;

  let date = new Date();
  let curYear = moment(date).format("YYYY");
  let curMonth = moment(date).format("MMMM");
  let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  const rows = [
    {
      id: 1,
      productName: "Brake Shoes",
      brand: "AKS",
      grade: "NA",
      quantity: 18,
      cost: 200,
      sale: 300,
      grossProfit: 100,
      profitMargin: 100,
    },
    {
      id: 2,
      productName: "Brake Shoes",
      brand: "AKS",
      grade: "NA",
      quantity: 22,
      cost: 200,
      sale: 300,
      grossProfit: 100,
      profitMargin: 100,
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "Sr.No.",
      headerClassName: "super-app-theme--header",
      minWidth: 60,
      flex: 1,
    },
    {
      field: "productName",
      headerName: "Product Name",
      headerClassName: "super-app-theme--header",
      minWidth: 183,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "super-app-theme--header",
      width: 120,
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Cost",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "sale",
      headerName: "Sale",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "grossProfit",
      headerName: "Gross Profit",
      headerClassName: "super-app-theme--header",
      width: 120,
      flex: 1,
    },
    {
      field: "profitMargin",
      headerName: "Profit Margin",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
  ];

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <Table
      customStyle={DataGridStyle}
      heading="Balance Summary"
      rows={rows}
      columns={[].concat(...columns)}
      pageSize={50}
      rowsPerPageOptions={[100]}
      // downloadXLS={() => downloadXLS(filter.length > 0 ? filter : rows)}
      components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
      handleOpenDatePicker={handleOpenDatePicker}
      filteredDate={filteredDate}
      setFilteredDate={setFilteredDate}
    />
  );
};

export default BalanceSummary;
