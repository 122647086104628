import React, { useEffect, useMemo, useState } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
  Divider,
} from "@react-pdf/renderer";
import convertor from "number-to-words";
import httpCommon from "../../../http-common";
import fontURL1 from "../../../assets/fonts//AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../../assets/fonts//AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../../assets/fonts//AvenirNextLTPro-Demi.otf";
import { getOrderById } from "../../../services/Services";
import { useParams } from "react-router-dom";
import Tableheader from "./Tableheader";
import TableRow from "./TableRow";
import TableFooter from "./TableFooter";

import { getCustomerInvoice } from "../../../services/orderServices/OrderServices";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

// Create styles
const borderColor = "#6a7086";
const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundColor: "#122164",
    padding: "4% 2% 3%",
  },
  jobcardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    // color: "white",
    marginRight: "0%",
    fontSize: 20,
    fontFamily: "AvenirBold",
    position: "relative",
    top: "5px",
  },
  headings: {
    color: "black",
    marginRight: "0%",
    fontSize: 10,
    fontFamily: "AvenirBold",
    position: "relative",
    // top: "5px",
  },
  taxInvoiceHeading: {
    marginRight: "0%",
    fontSize: "18px",
    // color: "white",
    fontFamily: "AvenirBold",
    position: "relative",
    top: "5px",
  },
  invoiceId: {
    // color: "white",
    marginRight: "0%",
    fontSize: "14px",
    fontFamily: "AvenirBold",
    position: "relative",
    top: "5px",
  },
  logo: {
    width: "120px",
    marginRight: "0%",
  },
  invoiceDetailsHeading: {
    fontSize: 10,
    fontFamily: "AvenirDemi",
    // color: "white",
    fontWeight: "400",
  },
  recieverDetailsHeading: {
    fontSize: 12,
    fontFamily: "AvenirDemi",
    fontWeight: "600",
  },
  customerName: {
    fontSize: 10,
    padding: "5px 0px",
    fontFamily: "AvenirDemi",
  },
  fields: {
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    color: "#6a7086",
    fontWeight: "400",
  },
  label: {
    fontWeight: "bold",
    fontSize: "10px",
    marginTop: "5px",
    fontFamily: "AvenirDemi",
    marginBottom: "2px",
  },
  invoiceIdBox: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  // --------------------------------
});

export default function InvoicePDF({ orderId, img, vehicleDetails, saRemark }) {
  const termsandconditions = [
    "Customer has understood the price, fees, all applicable taxes and charging method of the above items and has made the payment thereafter.",
    " Customer declares that the repairs/servicing have been rendered by the dealer as per prior indication and to the satisfaction of the customer.",
    "Gativan has offered salvage and replaced parts to customer. Unclaimed salvage will be disposed of without any liability and without any further information to the customer.",
    "Customer declares that the vehicle is delivered to him by the dealer in the same condition as received from him, along with Gativans authorized repair's / servicing, and all the belongings of the customer have been found intact.",
    " All disputes shall be subject to exclusive jurisdiction of the courts in the city where this dealer operates.",
    "Warranty of the goods is offered to the extent covered under the warranty policy of the manufacturer.",
    "Details of invoice should be quoted on all correspondences, advises, challans etc. relating to the invoice.",
    "Customer agrees to receive Call/SMS/E-mail/WhatsApp from Gativan related to Services, promotions & surveys.",
  ];

  const width = window.innerWidth;
  const baseURL = httpCommon.defaults.baseURL;

  const [invoiceDetails, setInvoiceDetails] = useState([]);

  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const customerInvoiceReport = async () => {
    const config = {
      header: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
    };

    try {
      const response = await getCustomerInvoice(orderId);
      const data = await response.data;
      setInvoiceDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    customerInvoiceReport();
  }, []);

  let totalTaxableAmount = 0;
  let totalCGST = 0;
  let totalSGST = 0;
  let totalCGSTRate = 0;
  let totalSGSTRate = 0;
  let totalDiscount = 0;
  let FinalTotal = 0;
  let totalPrice = 0;
  let totalQuantity = 0;

  invoiceDetails?.items?.map((item) => {
    const {
      discount,
      gstAmount,
      gstRate,
      hsn,
      id,
      invoiceId,
      price,
      qty,
      service,
      taxableAmount,
      total,
    } = item;
    // let CGST = (taxableAmount * (gstRate / 2)) / 100;
    // let SGST = (taxableAmount * (gstRate / 2)) / 100;

    let CGST = gstAmount.toFixed(2);
    let SGST = gstAmount.toFixed(2);

    // let total = CGST + SGST + taxableAmount;

    totalTaxableAmount += taxableAmount;
    totalCGST += Number(CGST);
    totalSGST += Number(SGST);
    totalCGSTRate += gstRate / 2;
    totalSGSTRate += gstRate / 2;
    FinalTotal += total;
    totalDiscount += discount;
    totalPrice += price;
    totalQuantity += qty;
  });

  const footerData = {
    totalTaxableAmount: totalTaxableAmount?.toFixed(2),
    totalCGST: totalCGST?.toFixed(2),
    totalSGST: totalSGST?.toFixed(2),
    totalDiscount: totalDiscount?.toFixed(2),
    FinalTotal: FinalTotal?.toFixed(2),
    totalPrice: totalPrice?.toFixed(2),
    totalQuantity: totalQuantity?.toFixed(2),
    totalCGSTRate,
    totalSGSTRate,
  };

  return (
    // <PDFViewer style={{ width: "100%", height: "100vh" }}>
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View>
          <View
            style={{
              margin: "0px 5px",
            }}
          >
            <View style={styles.jobcardHeader}>
              <View style={styles.invoiceIdBox}>
                <Text style={styles.taxInvoiceHeading}>Tax Invoice</Text>
                <Text style={styles.invoiceId}>
                  Invoice No. : {invoiceDetails?.invoiceId}
                </Text>
              </View>
              <View style={styles.logo}>
                <Image
                  src={
                    process.env.PUBLIC_URL +
                    // "/Assets/logos/GativanFullLogoWhite.png"
                    "/Assets/logos/GativanFullLogoBlue.png"
                  }
                />
              </View>
            </View>
            <View>
              <Text style={styles.invoiceDetailsHeading}>
                Original for Recipient
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              height: "200px",
              backgroundColor: "white",
              padding: "10px",
              margin: "5px 5px 0px",
              border: `1px solid ${borderColor}`,
            }}
          >
            <View style={{ width: "50%" }}>
              <Text style={styles.recieverDetailsHeading}>
                Details of Receiver
              </Text>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ width: "60%", display: "flex" }}>
                  <Text style={styles.customerName}>
                    {invoiceDetails?.customerName !== undefined
                      ? invoiceDetails?.customerName
                      : "------"}
                  </Text>
                  <Text style={styles.fields}>{vehicleDetails}</Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.vehicleNumber}
                  </Text>
                  <Text style={styles.label}>Mobile</Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.mobileNumber}
                  </Text>
                </View>
                <View style={{ width: "40%", display: "flex" }}>
                  <Text style={styles.label}>Order Date : </Text>
                  <Text style={styles.fields}>{invoiceDetails?.orderDate}</Text>

                  <Text style={styles.label}>Invoice Date :</Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.invoiceDate}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Image
                  style={{ width: "120px" }}
                  // src="https://cdn.shopify.com/s/files/1/0069/6821/3631/products/Racing-Blue_56e02258-05eb-448c-a86f-b32892afb298_761x.png?v=1631904323"
                  src={baseURL + img}
                />
              </View>
            </View>
            <View
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "50%",
                  height: "100%",
                }}
              >
                <View style={{ height: "50%" }}>
                  <Text style={styles.label}>Billing Address : </Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.customerAddress?.split(",")[0]?.trim() !==
                    "null"
                      ? invoiceDetails?.customerAddress
                      : "----"}
                  </Text>
                  <Text style={styles.label}>GSTIN:</Text>
                  <Text style={styles.fields}>
                    {" "}
                    {invoiceDetails?.customerGst
                      ? invoiceDetails?.customerGst
                      : "------"}{" "}
                  </Text>
                </View>
                <View
                  style={{
                    height: "50%",
                    borderTop: "1px solid gray",
                    paddingTop: "5px",
                  }}
                >
                  <Text style={styles.label}>Shipping Address:</Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.customerAddress?.split(",")[0]?.trim() !==
                    "null"
                      ? invoiceDetails?.customerAddress
                      : "----"}
                  </Text>
                  <Text style={styles.label}>GSTIN:</Text>
                  <Text style={styles.fields}>
                    {invoiceDetails?.customerGst
                      ? invoiceDetails?.customerGst
                      : "------"}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  borderLeft: "1px solid gray",
                  marginLeft: "10px",
                  paddingLeft: "10px",
                }}
              >
                <Text style={styles.label}>Sold By:</Text>
                <Text style={styles.fields}>
                  KStudio Solutions Private Limited,
                </Text>
                <Text style={styles.fields}>
                  Office No 802 Tower 2 World Trade center, Kharadi Pune
                </Text>
                <Text style={styles.label}>PAN No:</Text>
                <Text style={styles.fields}>AAGCK1J84Z</Text>
                <Text style={styles.label}>GSTIN:</Text>
                <Text style={styles.fields}>27AAGCK1784J1Z6</Text>
              </View>
            </View>
          </View>

          <View
            style={{
              backgroundColor: "white",
              padding: "5px",
              marginTop: "15px",
            }}
          >
            <View
              style={{
                borderColor: borderColor,
                borderWidth: 1,
              }}
            >
              <Tableheader />
              <TableRow services={invoiceDetails?.items} />
              <TableFooter footerData={footerData} />
            </View>
            <View wrap={false}>
              <View
                style={{
                  marginTop: "10px",
                  position: "relative",
                  width: "49%",
                  left: "51%",
                  padding: "5px",
                  borderBottom: "1px solid grey",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginBottom: "3px",
                  }}
                >
                  <Text>Total Amount before Tax :</Text>
                  <Text>{footerData?.totalTaxableAmount}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginBottom: "3px",
                  }}
                >
                  <Text>Add .CGST :</Text>
                  <Text>{footerData?.totalCGST}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginBottom: "5px",
                  }}
                >
                  <Text>Add .SGST :</Text>
                  <Text>{footerData?.totalSGST}</Text>
                </View>
              </View>

              <View
                style={{
                  position: "relative",
                  width: "49%",
                  left: "51%",
                  padding: "0px 5px",
                  backgroundColor: "rgba(235, 235, 235, 0.79)",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Text>Total Amount : GST :</Text>
                  <Text>
                    {(
                      Number(footerData?.totalCGST) +
                      Number(footerData?.totalSGST)
                    )?.toFixed(2)}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontSize: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Text>Total Amount After Tax :</Text>
                  <Text>{footerData?.FinalTotal}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    fontSize: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Text style={{ textTransform: "capitalize" }}>
                    {convertor.toWords(footerData?.FinalTotal)} Rupees.
                  </Text>
                </View>
              </View>
            </View>

            {saRemark && (
              <View
                style={{ borderTop: "1px solid black", marginTop: "6px" }}
                wrap={false}
              >
                <View style={{ margin: "1rem 0px" }}>
                  <Text
                    style={{
                      fontSize: 10,
                      marginTop: "10px",
                      fontFamily: "AvenirDemi",
                    }}
                  >
                    SA Remarks:
                  </Text>
                  <Text
                    style={{
                      fontSize: 9,
                      margin: "2px 0 0 15px",
                      color: "#6a7086",
                      fontFamily: "AvenirDemi",
                    }}
                  >
                   {saRemark}
                  </Text>
                </View>
              </View>
            )}

            <View
              style={{ borderTop: "1px solid black", marginTop: "6px" }}
              wrap={false}
            >
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    paddingTop: "5px",
                    fontFamily: "AvenirDemi",
                  }}
                >
                  Terms & Conditions:
                </Text>
              </View>
              <View style={{ width: "100%" }}>
                {termsandconditions?.map((item, index) => {
                  return (
                    <Text
                      style={{
                        fontSize: 9,
                        marginTop: "1px",
                        color: "#6a7086",
                        fontFamily: "AvenirDemi",
                      }}
                    >
                      {index + 1}. {item}
                    </Text>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
}
