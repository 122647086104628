import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addBrand } from "../../../services/inventoryServices/InventoryServices";
import { MenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@mui/material";
import { changeUserPassword } from "../../../services/userProfileServices/UserProfileServices";
import { useNavigate } from "react-router-dom";
import { AdminLogout } from "../../../action";
import { CommonContextAPI } from "../../../App";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    margin: "0.4rem 0",
  },
  eyeIcon: {
    cursor: "pointer",
  },
  forgotLinkWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.5rem 0",
  },
  forgotLink: {
    cursor: "pointer",
  },
}));

const ChangePassword = ({ closeChangePasswordModel }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [passwordDetails, setPasswordDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  // GET CONTEXT COMMON DATA
  const { handleCloseAddUserModel } = useContext(CommonContextAPI);
  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  // const validatePassword = (pass) => {
  //   var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  //   return re.test(pass);
  // };

  const checkPasswordStrength = (password) => {
    // Initialize variables
    var strength = 0;
    var tips = "";

    // Check password length
    if (password.length < 8) {
      tips += "Password needs to be 8 char long.";
    } else {
      strength += 1;
    }

    // Check for mixed case
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      strength += 1;
    } else {
      tips = "Use both lowercase and uppercase letters. ";
    }

    // Check for numbers
    if (password.match(/\d/)) {
      strength += 1;
    } else {
      tips = "Include at least one number. ";
    }

    // Check for special characters
    if (password.match(/[^a-zA-Z\d]/)) {
      strength += 1;
    } else {
      tips = "Include at least one special character. ";
    }

    // Return results
    if (strength < 2) {
      return {
        msg: "Easy. " + tips,
        level: 1,
      };
    } else if (strength === 2) {
      return {
        msg: "Medium. " + tips,
        level: 2,
      };
    } else if (strength === 3) {
      return {
        msg: "Difficult. " + tips,
        level: 3,
      };
    } else {
      return {
        msg: "Extremely difficult. " + tips,
        level: 4,
      };
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "password") {
      let result = checkPasswordStrength(value);
      setErrors({
        errors,
        ["strength"]: result,
      });
      setPasswordDetails({ ...passwordDetails, [name]: value });
      return;
    }
    setPasswordDetails({ ...passwordDetails, [name]: value });
  };

  const logoutOnPasswordChange = () => {
    const loginDetail = {
      isLogged: false,
      jwtToken: "",
      role: "",
    };

    localStorage.setItem("loginDetails", JSON.stringify(loginDetail));
    const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
    const isLogged = getLoginDetailsLS?.isLogged;
    const jwtToken = getLoginDetailsLS?.jwtToken;
    const role = getLoginDetailsLS?.role;
    dispatch(
      AdminLogout({
        isLogged,
        jwtToken,
        role,
      })
    );
    navigate("/");
    sessionStorage.setItem("isAdminConsole", false);
  };

  const changePass = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData.jwtToken}`,
      },
    };

    const data = {
      oldPassword: passwordDetails?.currentPassword,
      newPassword: passwordDetails?.password,
    };

    try {
      const response = await changeUserPassword(data, config);
      logoutOnPasswordChange();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = () => {
    if (
      passwordDetails?.currentPassword != undefined &&
      passwordDetails?.currentPassword.length > 0
    ) {
      if (
        passwordDetails?.password != undefined &&
        passwordDetails?.password.length > 0
      ) {
        if (passwordDetails?.password === passwordDetails?.confirmPassword) {
          changePass();
          closeChangePasswordModel();
        } else {
          setErrors({
            errors,
            ["confirmPassword"]: "Password and Confirm Password not same.",
          });
        }
      } else {
        setErrors({
          errors,
          ["requiredPassword"]: "Please Enter New Password!.",
        });
      }
    } else {
      setErrors({
        errors,
        ["required"]: "Please Enter Current Password!.",
      });
    }

    // handleCloseAddUserModel();
  };

  const viewPassword = () => {
    setPasswordVisibility((prev) => !prev);
  };

  const handleErrors = (e) => {
    const { name, value } = e.target;

    if (name == "confirmPassword") {
      if (passwordDetails?.password !== passwordDetails?.confirmPassword) {
        setErrors({
          errors,
          [name]: "Password and Confirm Password not same.",
        });
      } else {
        setErrors({
          errors,
          [name]: "",
        });
      }
    }

    if (passwordDetails?.confirmPassword?.length == 0) {
      setErrors({
        errors,
        [name]: "",
      });
    }
  };

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    height: 520,
    border: "none !important",
    overflow: "auto",
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeChangePasswordModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" sx={{ lineHeight: 2 }} className="titles">
            Change Password
          </Typography>

          {/* <Divider /> */}
          <Typography>
            Set a strong password to prevent unauthorized access to your
            account.
          </Typography>
          <Box
            style={{
              position: "absolute",
              top: 0,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeChangePasswordModel}
            />
          </Box>
          <Grid container className="gridRow" spacing={1}>
            <Grid item xs={12}>
              <TextField
                // size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="Current Password"
                id="currentPassword"
                style={{ marginTop: 20 }}
                name="currentPassword"
                onChange={handleChange}
                value={passwordDetails?.currentPassword}
              />{" "}
              {errors.required && (
                <Typography sx={{ color: "red" }}>{errors.required}</Typography>
              )}
              <Box className={classes.forgotLinkWrapper}>
                <Link className={classes.forgotLink}>Forgot Password?</Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container className="gridRow" spacing={1}>
            <Grid item xs={12}>
              <Box sx={{ position: "relative" }}>
                <TextField
                  required
                  label="New Password"
                  name="password"
                  type={passwordVisibility ? "text" : "password"}
                  className={classes.textField}
                  value={passwordDetails.password}
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete="new-password "
                />
                <Box
                  className={classes.viewPasswordBox}
                  sx={{ position: "absolute", top: "30%", right: "0%" }}
                >
                  {passwordVisibility ? (
                    <VisibilityIcon
                      className={classes.eyeIcon}
                      onClick={viewPassword}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className={classes.eyeIcon}
                      onClick={viewPassword}
                    />
                  )}
                </Box>
              </Box>
              {errors.requiredPassword && (
                <Typography sx={{ color: "red" }}>
                  {errors.requiredPassword}
                </Typography>
              )}
              {errors.strength && (
                <Typography
                  sx={{
                    color:
                      errors.strength.level < 2
                        ? "red"
                        : errors.strength.level == 2
                        ? "orange"
                        : errors.strength.level == 3
                        ? "#22dba8"
                        : "green",
                  }}
                >
                  {errors.strength?.msg}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container className="gridRow" spacing={1}>
            <Grid item xs={12}>
              <TextField
                required
                disabled={passwordDetails?.password?.length > 0 ? false : true}
                label={"Confirm Password"}
                name="confirmPassword"
                type="password"
                className={classes.textField}
                value={passwordDetails.confirmPassword}
                onBlur={(e) => handleErrors(e)}
                onChange={handleChange}
                variant="outlined"
                autocomplete="no"
              />
              {errors.confirmPassword && (
                <Typography sx={{ color: "red" }}>
                  {errors.confirmPassword}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "0.5rem" }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#10bc83",
                  color: "#fff",
                  "&:hover": { backgroundColor: "#10bc83" },
                }}
                onClick={changePassword}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ChangePassword;
