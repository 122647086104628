import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getMaterialLib } from "../../services/inventoryServices/InventoryServices";
import { getVendorsList } from "../../services/inventoryServices/InventoryServices";
import { saveInvoice } from "../../services/inventoryServices/InventoryServices";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DatePicker from "react-datepicker";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";
import moment from "moment";

const AddProduct = ({ setIsProductAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [productDetails, setProductDetails] = useState({});
  const [vendorsList, setVendorsList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [product, setProduct] = useState({});
  const [startDate, setStartDate] = useState(new Date());

  // GET CONTEXT COMMON DATA
  const { handleCloseAddProductModel } = useContext(CommonContextAPI);

  // Get all products
  const getAllProductsList = async () => {
    try {
      const response = await getMaterialLib();
      const data = await response.data;
      setMaterialList(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all vendors
  const getAllVendorsList = async () => {
    try {
      const response = await getVendorsList();
      const result = await response.data;
      setVendorsList(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProductsList();
    getAllVendorsList();
  }, []);

  const removeProducts = (id) => {
    let temp = productsList.filter((p) => Number(p.id) != Number(id));
    setProductsList(temp);
  };

  const handleProducts = (e, value) => {
    setProduct({ ...product, ["itemName"]: value?.name, id: value?.id });
  };

  const handleProduct = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: Number(value) });
  };

  useEffect(() => {
    setProduct({
      ...product,
      ["amount"]: Number(product.quantity) * Number(product.price),
    });
  }, [product.price, product.quantity]);

  const addProducts = () => {
    if (
      product.itemName != "" &&
      product.quantity != "" &&
      product.price != "" &&
      product.amount != ""
    ) {
      setProductsList([...productsList, product]);
      setProduct({
        itemName: "",
        quantity: "",
        price: "",
        amount: "",
      });
    }
  };

  const [totalInvoiceValue, setTotalInvoiceValue] = useState("");
  useEffect(() => {
    setTotalInvoiceValue(0);
    const temp = productsList?.map((product) => {
      setTotalInvoiceValue((prev) => Number(prev) + Number(product.amount));
      return {
        productId: Number(product.id),
        quantity: product.quantity,
        taxedAmount: product.amount,
      };
    });

    setProductDetails({ ...productDetails, ["products"]: temp });
  }, [productsList]);

  const [hideLabel, setHideLabel] = useState(false);

  const calender = () => {
    return (
      <Box
        sx={{
          "& .react-datepicker-wrapper": {
            "& input": {
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #b9b6b6",
              fontSize: "16px",
              zIndex: 99999999999,
            },
          },
          "& .react-datepicker__close-icon::after ": {
            backgroundColor: "red",
            color: "#fff",
            fontSize: "1rem",
            padding: "2px 5px",
          },
        }}
      >
        <DatePicker
          style={{
            padding: "10px !important",
            border: "2px solid gray !important",
            "& .react-datepicker__input-container": {
              backgroundColor: "red",
            },
          }}
          onFocus={() => setHideLabel(true)}
          onBlur={() => setHideLabel(false)}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setHideLabel(false);
          }}
          // dateFormat="dd/MM/yyyy"
          isClearable
          placeholderText="I have been cleared!"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </Box>
    );
  };

  const venderItems = () => {
    return productsList?.map((product, i) => {
      return (
        <>
          <Grid
            container
            className="gridRow"
            spacing={1}
            style={{ width: "100%", padding: "0.5rem 0" }}
          >
            <Grid item xs={1}>
              <Typography className="lables">{i + 1}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className="lables">{product?.itemName}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography className="lables">{product?.quantity}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="lables">{product?.price}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="lables">{product?.amount}</Typography>
            </Grid>
            <Grid item xs={1}>
              <DeleteForeverIcon
                sx={{ cursor: "pointer", "&:hover": { color: "red" } }}
                onClick={() => removeProducts(product?.id)}
              />
            </Grid>
          </Grid>
          <Divider />
        </>
      );
    });
  };

  const renderAddItems = () => {
    return (
      <>
        <Grid
          container
          className="gridRow"
          spacing={1}
          style={{ width: "100%" }}
        >
          <Grid item xs={6}>
            <Typography className="lables">Item Name</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="lables">
              {isMobile ? "Qty" : "Quantity"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="lables">
              {isMobile ? "Price" : "Price(Rs)"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="lables">
              {isMobile ? "Amount" : "Amount(Rs)"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className="gridRow"
          spacing={1}
          style={{ width: "100%" }}
        >
          <Grid item xs={6}>
            <Autocomplete
              style={{ marginTop: 20 }}
              size="small"
              freeSolo
              id="itemName"
              name="itemName"
              disableClearable
              // options={materialList.map(
              //   (option) =>
              //     `${option?.name}, ${option?.brand?.brand}, ${option?.grade}, ${option?.id}`
              // )}

              options={materialList}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <li {...props}>{option?.name}</li>
              )}
              value={productDetails.itemName}
              onChange={handleProducts}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="Search Product"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              margin="dense"
              variant="outlined"
              // label="Quantity"
              type="text"
              id="quantity"
              style={{ marginTop: 20 }}
              name="quantity"
              onChange={handleProduct}
              value={product?.quantity}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              margin="dense"
              variant="outlined"
              // label="Purchase Cost"
              type="text"
              id="price"
              style={{ marginTop: 20 }}
              name="price"
              onChange={handleProduct}
              value={product?.price}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              margin="dense"
              variant="outlined"
              // label="Invoice Value"
              type="text"
              id="amount"
              style={{ marginTop: 20 }}
              name="amount"
              onChange={handleProduct}
              value={product?.amount ? product?.amount : ""}
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    setProductDetails({
      ...productDetails,
      invoiceDate: moment(startDate).format("YYYY-MM-DD"),
    });
  }, [startDate]);

  const renderForm = [
    {
      label: "Invoice Number",
      fields: [
        {
          label: "Invoice Number",
          field: "TextField",
          name: "invoiceNumber",
          id: "invoiceNumber",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Date",
      fields: [
        {
          label: "Date",
          field: "Calender",
          name: "date",
          id: "date",
          type: "date",
          grids: 12,
          render: calender,
          props: {
            setHideLabel,
            setStartDate,
            startDate,
          },
        },
      ],
    },
    {
      label: "Vendor",
      fields: [
        {
          label: "Vendor",
          field: "Autocomplete",
          name: "vendor",
          id: "vendor",
          type: "text",
          data: vendorsList,
          properties: { name: "name", id: "id" },
          props: {
            hideLabel,
          },
          postId: "vendorId",
          grids: 12,
        },
      ],
    },
    {
      label: "Divider",
      divider: true,
    },
    {
      addItems: true,
      render: renderAddItems,
    },
    {
      label: "Add Product",
      button: true,
      buttonStyle: {
        backgroundColor: "#122164",
      },
      delete: removeProducts,
      add: addProducts,
      style: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: "0.3rem 0",
      },
    },
    {
      label: "",
      renderTable: true,
      render: venderItems,
    },
    {
      label: "Divider",
      divider: true,
    },
    {
      total: true,
      label: `Total Invoice Value : Rs. ${totalInvoiceValue}`,
      style: {
        width: "100% !important",
        padding: "1rem 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  ];

  return (
    <div>
      <Form
        heading="Add Product"
        closeModal={handleCloseAddProductModel}
        isAdded={setIsProductAdded}
        renderForm={renderForm}
        service={saveInvoice}
        state={productDetails}
        items={product}
      />
    </div>
  );
};

export default AddProduct;
