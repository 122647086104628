import React from "react";
import { Typography } from "@mui/material";

const Compliance = () => {
  return (
    <div style={{ padding: "3rem" }}>
      {" "}
      <Typography variant="h4" className="titles">Compliance</Typography>
    </div>
  );
};

export default Compliance;
