import { makeStyles } from "@material-ui/core";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px !important",
    margin: "0px !important",
  },
  adminActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "1rem  ",
    },
  },
  notificationIcon: {
    fontSize: "2rem",
  },
  helpIcon: {
    fontSize: "2rem",
  },
  homeIcon: {
    fontSize: "2rem",
  },
  profileIcon: {
    fontSize: "4rem",
  },
  subHeadingWrapper: {
    padding: "2rem",
  },
  subHeading: {
    lineHeight: 1.8,
  },
  textField: {
    width: "100%",
    margin: "0.4rem 0",
  },
  userIcon: {
    fontSize: "6rem",
  },

  papper: {
    padding: "20px",
    width: "40vh",
    height: "50vh",
  },
  input: {
    display: "none",
  },
  imageAvatar: {
    color: "#000",
    margin: 10,
    height: "100px",
    width: "100px",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#837c7c",
    width: "140px",
    height: "140px",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));

const SignUp = () => {
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const classes = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "checkbox") {
      setUserDetails({ ...userDetails, [name]: e.target.checked });
    }

    setUserDetails({ ...userDetails, [name]: value });
  };
  return (
    <Box sx={{ padding: "3rem" }}>
      <Box sx={{ width: "50%" }}>
        <form method="POST" sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="First Name"
                name="firstName"
                className={classes.textField}
                value={userDetails.firstName}
                onChange={handleChange}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Email"
                name="email"
                className={classes.textField}
                value={userDetails.email}
                onChange={handleChange}
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Password"
                name="password"
                type="password"
                className={classes.textField}
                value={userDetails.password}
                onChange={handleChange}
                variant="outlined"
                autoComplete="new-password "
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Mobile Number"
                name="mobileNumber"
                type="number"
                className={classes.textField}
                value={userDetails.mobileNumber}
                onChange={handleChange}
                variant="outlined"
                autocomplete="off"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            // onClick={AddNewUser}
          >
            Add
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default SignUp;
