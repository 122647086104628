import { Box, Typography } from "@mui/material";
import React from "react";

const NotFound404 = () => {
  return (
    <Box sx={{ padding: "3rem" }}>
      <Typography variant="h4" sx={{ color: "red" }}>
        Page Not Found 404!
      </Typography>
    </Box>
  );
};

export default NotFound404;
