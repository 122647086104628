import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { getVehicleType } from "../../services/leadServices/LeadServices";
import { Grid } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  editGarage,
  addGarageBankAccountDetails,
  addGarageUpiAccount,
} from "../../services/garageServices/GarageServices";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import "./AddGarage.css";

const useStyles = makeStyles({
  gridRow: {
    display: "flex",
    alignItems: "flex-start",
  },
  garageTitle: {
    fontSize: "2rem",
  },
});

const locations = [
  {
    city: "Pune",
    id: 1,
  },
  {
    city: "Banglore",
    id: 2,
  },
  {
    city: "Mumbai",
    id: 3,
  },
];

const specializedIn = [
  {
    name: "Bike",
    id: 1,
  },
  {
    name: "Car",
    id: 2,
  },
  {
    name: "Bike & Car",
    id: 3,
  },
];

const entityTypes = ["Sole proprietor", "Partnership Firm"];
const payoutBasedOn = ["Invoice Price", "Taxable Amount"];

const EditGarage = ({ setIsGarageEdited, closeEditGarageModel, passProps }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.only("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up("xxl"));
  const classes = useStyles();

  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [garageDetails, setGarageDetails] = useState({});

  useEffect(() => {
    setGarageDetails(passProps);
  }, [passProps]);


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "gstn" || name == "pan") {
      setGarageDetails({ ...garageDetails, [name]: value?.toUpperCase() });
    } else if (name == "username") {
      setGarageDetails({ ...garageDetails, [name]: value });
    } else {
      setGarageDetails({
        ...garageDetails,
        [name]: value
          ?.split(" ")
          .map((ar) => ar.charAt(0).toUpperCase() + ar.slice(1))
          .join(" "),
      });
    }
  };

  const userSelection = (fieldName, name, id) => {
    const obj = {
      name,
      id,
    };
    setGarageDetails({ ...garageDetails, [fieldName]: id });
  };

  const saveGarageBankAccount = async (bankDetails, config) => {
    try {
      const response = await addGarageBankAccountDetails(bankDetails, config);
      console.log("Bank", response);
    } catch (error) {
      console.log(error);
    }
  };

  const saveGarageUpiAccount = async (upiDetails, config) => {
    try {
      const response = await addGarageUpiAccount(upiDetails, config);
      console.log("Bank", response);
    } catch (error) {
      console.log(error);
    }
  };

  const editGarageDetails = async (garageObject, config) => {
    try {
      const response = await editGarage(garageObject, config);
      const data = await response.data;
      closeEditGarageModel();
      navigate("/garageDatabase");
      setIsGarageEdited((prev) => !prev);
    } catch (error) {
      console.log("error", error);
    }
  };

  const saveGarageDetails = async () => {
    const garageObject = {
      area: garageDetails?.area,
      city: garageDetails?.city,
      entityType: garageDetails?.entityType,
      garageName: garageDetails?.garageName,
      garageType: garageDetails?.garageType,
      garageId: garageDetails?.garageId,
      gstn: garageDetails?.gstn,
      landmark: garageDetails?.landmark,
      mobileNumber: garageDetails?.mobileNumber,
      pan: garageDetails?.pan,
      payoutPercentage: Number(garageDetails?.payoutPercentage),
      payoutBasedOn: garageDetails?.payoutBasedOn,
      payoutPercentage: garageDetails?.payoutPercentage,
      pincode: garageDetails?.pincode,
      shopNumber: garageDetails?.shopNumber,
      tehsil: garageDetails?.shopNumber,
      district: garageDetails?.district,
      state: garageDetails?.state,
      country: garageDetails?.country,
      accountHolderName: garageDetails?.accountHolderName,
      accountNumber: garageDetails?.accountNumber,
      ifscCode: garageDetails?.ifscCode,
      upiId: garageDetails?.upiId,
    };

    const localData = JSON.parse(localStorage.getItem("loginDetails"));

    const config = {
      headers: {
        Authorization: `Bearer ${localData.jwtToken}`,
      },
    };

    console.log("garageObject", garageObject);

    // Call Add Garage Bank Account Details
    if (
      garageObject?.accountHolderName &&
      garageObject?.accountHolderName &&
      garageObject?.accountHolderName
    ) {
      const obj = {
        garageId: garageObject?.garageId,
        accountHolderName: garageObject?.accountHolderName,
        accountNumber: garageObject?.accountNumber,
        ifscCode: garageObject?.ifscCode,
      };
      saveGarageBankAccount(obj, config);
    }

    // Call Add Garage UPI Account Details
    if (garageObject?.upiId) {
      const obj = {
        garageId: garageObject?.garageId,
        upiId: garageObject?.upiId,
      };
      saveGarageUpiAccount(obj, config);
    }

    // Call Edit Garage Details
    editGarageDetails(garageObject, config);

    // try {
    //   const response = await editGarage(garageObject, config);
    //   const data = await response.data;
    //   closeEditGarageModel();
    //   navigate("/garageDatabase");
    //   setIsGarageEdited((prev) => !prev);
    // } catch (error) {
    //   console.log("error", error);
    // }
  };

  const discardDetails = () => {
    setGarageDetails({
      garageName: "",
      garageType: "",
      location: "",
      contactNumber: "",
      address: "",
    });
  };

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: 2,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: isSmallMobile
    //   ? "75%"
    //   : isMobile
    //   ? "65%"
    //   : isTablet
    //   ? "65%"
    //   : isLaptop
    //   ? "80"
    //   : isDesktop
    //   ? "95%"
    //   : "65%",
    maxHeight: "100%",
    height: "auto",
    border: "none !important",
    overflow: "auto !important",
  };

  // Get vehicle types
  const getVehicleTypes = async () => {
    try {
      const response = await getVehicleType();
      const result = await response.data;
      setVehicleTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehicleTypes();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={true}
        onClose={closeEditGarageModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 className={classes.garageTitle}>Edit Garage</h2>
          <Divider />
          <Box
            style={{
              position: "absolute",
              top: 0,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeEditGarageModel}
            />
          </Box>
          <Grid container spacing={1} className={classes.gridRow}>
            <Grid item xs={4}>
              <Typography className="lables">Garage Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="Garage Name"
                id="garageName"
                name="garageName"
                onChange={handleChange}
                value={garageDetails.garageName}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Garage Type</Typography>
            </Grid>
            <Grid item sm={8} style={{ marginTop: "-1rem" }}>
              <ul className="donate-now">
                {vehicleTypes &&
                  vehicleTypes.map((types, index) => {
                    return (
                      <li key={index}>
                        <input
                          type="radio"
                          id={types.vehicleType}
                          name="garageType"
                          value={types.id}
                          defaultChecked={
                            garageDetails?.garageType &&
                            types?.id == garageDetails?.garageType
                              ? true
                              : false
                          }
                          onClick={() =>
                            userSelection(
                              "garageType",
                              types.vehicleType,
                              types.id
                            )
                          }
                        />
                        <label for={types.vehicleType}>
                          {types.vehicleType}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Contact Number</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                margin="dense"
                variant="outlined"
                label="+91  Enter 10 digit No."
                id="mobileNumber"
                inputProps={{ maxLength: 10 }}
                name="mobileNumber"
                onChange={handleChange}
                value={garageDetails.mobileNumber}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">GSTN</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="GSTN"
                id="gstn"
                name="gstn"
                onChange={handleChange}
                value={garageDetails.gstn}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">PAN</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="PAN"
                id="pan"
                name="pan"
                onChange={handleChange}
                value={garageDetails.pan}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Entity Type</Typography>
            </Grid>
            <Grid item xs={8}>
              <select
                name="entityType"
                onChange={handleChange}
                className="selectDropdown"
              >
                <option value={garageDetails && garageDetails.entityType}>
                  {garageDetails && garageDetails.entityType}
                </option>
                {entityTypes &&
                  entityTypes.map((entity, index) => {
                    return (
                      <option value={entity} key={index}>
                        {entity}
                      </option>
                    );
                  })}
              </select>
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Payout Percentage</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Payout Percentage"
                    id="payoutPercentage"
                    name="payoutPercentage"
                    onChange={handleChange}
                    value={garageDetails.payoutPercentage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Payout Based On</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <select
                    name="payoutBasedOn"
                    onChange={handleChange}
                    className="selectDropdown"
                  >
                    <option
                      value={garageDetails && garageDetails.payoutBasedOn}
                    >
                      {garageDetails && garageDetails.payoutBasedOn}
                    </option>
                    {payoutBasedOn &&
                      payoutBasedOn.map((payout, index) => {
                        return (
                          <option value={payout} key={index}>
                            {payout}
                          </option>
                        );
                      })}
                  </select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Address</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Shop Number"
                    id="shopNumber"
                    name="shopNumber"
                    onChange={handleChange}
                    value={garageDetails.shopNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Area"
                    id="area"
                    name="area"
                    onChange={handleChange}
                    value={garageDetails.area}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ margin: "0.6rem 0" }}>
                  <select
                    name="city"
                    onChange={handleChange}
                    className="selectDropdown"
                    label="City"
                    style={{ marginTop: "0.6rem" }}
                  >
                    <option value={garageDetails && garageDetails.city}>
                      {garageDetails && garageDetails.city}
                    </option>
                    {locations &&
                      locations.map((location, index) => {
                        return (
                          <option value={location.city} key={location.id}>
                            {location.city}
                          </option>
                        );
                      })}
                  </select>
                  {/* <select
                    name="entityType"
                    onChange={handleChange}
                    className="selectDropdown"
                    sx={{ padding: "0.6rem 0" }}
                  >
                    <option value={garageDetails && garageDetails.entityType}>
                      {garageDetails && garageDetails.entityType}
                    </option>
                    {entityTypes &&
                      entityTypes.map((entity, index) => {
                        return (
                          <option value={entity} key={index}>
                            {entity}
                          </option>
                        );
                      })}
                  </select> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Pin Code"
                    id="pincode"
                    name="pincode"
                    onChange={handleChange}
                    value={garageDetails.pincode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Land Mark"
                    id="landmark"
                    name="landmark"
                    onChange={handleChange}
                    value={garageDetails.landmark}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Bank Account</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Account Holder Name"
                    id="accountHolderName"
                    name="accountHolderName"
                    onChange={handleChange}
                    value={garageDetails.accountHolderName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="Account Number"
                    id="accountNumber"
                    name="accountNumber"
                    onChange={handleChange}
                    value={garageDetails.accountNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="IFSC Code"
                    id="ifscCode"
                    name="ifscCode"
                    onChange={handleChange}
                    value={garageDetails.ifscCode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">UPI Account</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label="UPI ID"
                    id="upiId"
                    name="upiId"
                    onChange={handleChange}
                    value={garageDetails.upiId}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4}></Grid>
            <Grid item xs={8} sm={6}>
              <div className="buttonGroup">
                <Button
                  variant="contained"
                  size="small"
                  className="buttons"
                  onClick={discardDetails}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="buttons"
                  style={{ marginLeft: "1rem" }}
                  onClick={saveGarageDetails}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default EditGarage;
