

import http from '../../http-common'

export const getAllPolicy=()=>{
    return http.get(`/policyTypes`)
}

export const getPolicyById=(id)=>{
    return http.get(`/policyTypes/${id}`)
}

export const postPolicy=(updateObj)=>{
    return http.post(`/policyTypes`,updateObj)
}

export const getAllCodeOfInsurance=()=>{
    return http.get(`/codeOfInsurance`)
}