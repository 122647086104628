import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, InputBase, Modal, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  getBrands,
  getModels,
  getVariants,
  getVehicleType,
} from "../../services/leadServices/LeadServices";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./VehicleModal.css";
import httpCommon from "../../http-common";
import LoaderSpinner from "../../components/loading/LoaderSpinner";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    padding: "0px 1rem",
  },
  gridContainer: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      // marginTop: "3rem",
      height: "auto",
    },
  },
  searchbarContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
  },
  inputInput: {
    fontSize: "1.2rem",
    padding: "0px 5px",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px !important",
    height: "auto",
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "1rem !important",
    },
    [theme.breakpoints.up("md")]: {
      height: "93vh",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  fixedFilterBox: {
    // padding: "4.6rem 1rem 0px",
    backgroundColor: "#fff",
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      width: "17%",
      height: "auto",
      overflow: "auto",
      // padding: "1rem, 0px !important",
      padding: "4.6rem 0.1rem 0px",
    },
    [theme.breakpoints.up("lg")]: {
      position: "fixed",
      width: "15%",
      height: "auto",
      overflow: "auto",
      // padding: "1rem, 0px !important",
      padding: "4.6rem 0rem 0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "2.6rem 1rem 0px",
      margin: "0px 1rem",
    },
  },
  filterBox: {
    padding: "0px 0.1rem",
    [theme.breakpoints.up("lg")]: {
      padding: "0px 1rem",
    },
  },
  categoryList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.only("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  searchButton: {
    width: "100%",
    backgroundColor: "#122164",
    padding: "7px 10px",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      margin: "0.5rem 0px",
    },
  },
  filterButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0.5rem 0px",
    },
  },
  mainContainer: {
    marginTop: "2.5rem",
    [theme.breakpoints.up("md")]: {
      // borderLeft: "1px solid grey",
      minHeight: "90vh",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0rem !important",
      marginTop: "0.5rem",
    },
  },
  searchBoxWrapper: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: "#E5E5E5",
    border: "2px solid #ccc",
    color: "black",
    // boxShadow: "0px 0px 5px gray",
    marginLeft: 0,
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "350px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
    },
  },
  vehicleType: {
    margin: "1rem 0px",
  },
  vehicleTypesWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  serviceButtons: {
    margin: "0.1rem 0px",
    color: "#fff",
    fontSize: "0.9rem",
    color: "#000",
    boxShadow: "none",
    display: "flex !important",
    alignItems: "flex-start !important",
    flexDirection: "row !important",
    justifyContent: "flex-start !important",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem",
      fontSize: "0.8rem",
    },
    [theme.breakpoints.only("md")]: {
      width: "90%",
      fontSize: "0.766rem",
    },
    [theme.breakpoints.only("sm")]: {
      width: "95%",
      fontSize: "0.766rem",
    },
    "&:hover": {
      boxShadow: "none",
    },
  },
  filters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  filterItem: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0.5rem 0px",
    },
  },
  selectDropdown: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      paddingTop: "0.7rem !important",
      paddingLeft: "0.5rem !important",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
  },
  icons: {
    margin: "0px 0.5rem",
  },
  expandIcon: {
    fontSize: "2rem",
  },
  services: {
    width: "100%",
    padding: "2rem 1rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  categoryTitle: {
    fontSize: "1.5rem",
    margin: "0.5rem 0px",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "1rem",
      justifyContent: "space-around",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginBottom: "1rem",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  servicePricessWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  serviceHeading: {
    marginLeft: "0.5rem",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  serviceRate: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      marginLeft: "1rem",
    },
  },
  image: {
    width: "80px",
    height: "80px",
    padding: "0.3rem",
    borderRadius: "50px",
    boxShadow: "0px 0px 3px black",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  },
  AccordionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  box: {
    "& .$MuiAccordionSummary-root": {
      margin: "1rem 0px",
    },
  },
  checkIcon: {
    color: "#51ae3a",
    marginRight: "0.5rem",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  serviceDetails: {
    padding: "1rem 0px",
    [theme.breakpoints.up("sm")]: {
      // width: "70%",
    },
  },
  "& li": {
    fontSize: "1rem",
  },
  serviceActivities: {
    [theme.breakpoints.up("sm")]: {
      // width: "70%",
    },
  },
  radioButton: {
    color: "#122164 !important",
  },
}));

const VehicleModal = ({
  hanldeCloseVehicleModal,
  setShowServices,
  vehicleDetails,
  setVehicleDetails,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);

  const [vehicleTypeId, setVehicleTypeId] = useState(null);
  const [vehicleBrandId, setVehicleBrandId] = useState(null);
  const [vehicleModelId, setVehicleModelId] = useState(null);
  const [vehicleVariantId, setVehicleVariantId] = useState(null);
  const [query, setQuery] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [activeButton, setActiveButton] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [currentList, setCurrentList] = useState("Vehicle Type");

  const userSelection = (fieldName, data) => {
    if (fieldName == "vehicleType") {
      setVehicleTypeId(data?.id);
      setCurrentList("Brand");
      setVehicleDetails({ ...vehicleDetails, vehicleTypeId: data?.id });
    } else if (fieldName == "brands") {
      setVehicleBrandId(data?.brand_Id);
      setCurrentList("Model");
      setFilteredList([]);
      setVehicleDetails({
        ...vehicleDetails,
        brandId: data?.brand_Id,
        brand: data?.brand,
      });
    } else if (fieldName == "models") {
      setVehicleModelId(data?.model_id);
      setCurrentList("Variant");
      setFilteredList([]);
      setVehicleDetails({
        ...vehicleDetails,
        modelId: data?.model_id,
        image: data?.image_url,
        model: data?.model,
      });
    } else if (fieldName == "variants") {
      setVehicleVariantId(data?.variantId);
      setFilteredList([]);
      setCurrentList("Category");
      setVehicleDetails({
        ...vehicleDetails,
        variantId: data?.variantId,
        variant: data?.variantName,
      });
      hanldeCloseVehicleModal();
      setShowServices(true);
    } else {
      return;
    }
  };

  const URL = httpCommon.defaults.baseURL;

  const handleSearch = (e) => {
    const value = e.target.value;
    console.log("value", value);
    setQuery(value);
  };

  const [searchParams] = useState(["brand", "model", "variantName"]);

  useEffect(() => {
    const filter =
      currentList == "Brand"
        ? vehicleBrands
        : currentList == "Model"
        ? vehicleModels
        : vehicleVariants;

    let filteredData = filter?.filter((brand) => {
      return searchParams.some((search) => {
        return (
          brand[search]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(query.toLowerCase()) > -1
        );
      });
    });

    if (query == "") {
      setFilteredList(filter);
    } else {
      setFilteredList(filteredData);
    }
  }, [query]);

  const removeProperty = (...props) => {
    let temp = { ...vehicleDetails };
    props?.map((prop) => {
      delete temp[prop];
    });

    setVehicleDetails(temp);
  };

  const handleBack = () => {
    if (currentList == "Brand") {
      removeProperty("vehicleTypeId");
    } else if (currentList == "Model") {
      setCurrentList("Brand");
      getVehicleBrands(vehicleDetails?.vehicleTypeId);
      removeProperty("brandId", "brand");
    } else if (currentList == "Variant") {
      removeProperty("modelId", "model", "image");
      getVehicleModels(vehicleDetails?.vehicleTypeId, vehicleDetails?.brandId);
      setCurrentList("Model");
    } else {
      return false;
    }
  };

  // Get vehicle types
  const getVehicleTypes = async () => {
    try {
      const response = await getVehicleType();
      const result = await response.data;
      setVehicleTypes(result.result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVehicleBrands = async (vehicleTypeId) => {
    try {
      const response = await getBrands(vehicleTypeId);
      const result = await response.data;
      setVehicleBrands(result.result);
      setFilteredList(result.result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Get modals
  const getVehicleModels = async (vehicleTypeId, vehicleBrandId) => {
    try {
      const response = await getModels(vehicleTypeId, vehicleBrandId);
      const result = await response.data;
      setVehicleModels(result.result);
      setFilteredList(result.result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  //  Get Variants
  const getVehicleVariants = async (vehicleModelId) => {
    try {
      const response = await getVariants(vehicleModelId);
      const result = await response.data;
      setVehicleVariants(result.result);
      setFilteredList(result.result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehicleTypes();
  }, []);

  useMemo(() => {
    vehicleTypeId && getVehicleBrands(vehicleTypeId);
  }, [vehicleTypeId]);

  useMemo(() => {
    vehicleBrandId && getVehicleModels(vehicleTypeId, vehicleBrandId);
  }, [vehicleBrandId]);

  useMemo(() => {
    vehicleModelId && getVehicleVariants(vehicleModelId);
  }, [vehicleModelId]);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    boxShadow: 20,
    padding: "1.5rem 1rem",
    borderRadius: "8px",
    position: "absolute",
    m: 0,
    // height: 600,
    minHeight: 450,
    height: "auto",
    maxHeight: "100%",
    border: "none !important",
    overflow: "auto",
  };
  return (
    <Box>
      <Modal
        open={true}
        onClose={hanldeCloseVehicleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            {!vehicleDetails?.vehicleTypeId && (
              <>
                <Box sx={{ marginBottom: "1rem" }}>
                  <Typography variant="h6">Select Vehicle Type</Typography>
                </Box>
                <ul className="vehicle-type">
                  {!isLoading ? (
                    vehicleTypes &&
                    vehicleTypes.map((types, index) => {
                      return (
                        <li key={index} style={{ width: "100% !important" }}>
                          <input
                            type="radio"
                            id={types.vehicleType}
                            name="vehicleType"
                            value={types.id}
                            onClick={() => userSelection("vehicleType", types)}
                          />
                          <label for={types.vehicleType}>
                            {types.vehicleType}
                          </label>
                        </li>
                      );
                    })
                  ) : (
                      <LoaderSpinner />
                  )}
                </ul>
              </>
            )}
          </Box>
          <>
            {vehicleDetails?.vehicleTypeId && (
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackIcon className="back-icon" onClick={handleBack} />{" "}
                  <Typography variant="h6">Select {currentList}</Typography>
                </Box>
                <Box className="search-box">
                  <Box className="search-box-wrapper">
                    <div className="search">
                      <div className="search-icon">
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: "input-root",
                          input: "input-input",
                        }}
                        inputProps={{ "aria-label": "search" }}
                        value={query}
                        onChange={handleSearch}
                      />
                    </div>
                    {/* <button className="search-button">Search</button> */}
                  </Box>
                </Box>
                <Box sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  <Box className="vehicle-details-box">
                    <Grid container spacing={2}>
                      {currentList == "Brand" ? (
                        <>
                          {" "}
                          {filteredList ? (
                            filteredList?.map((item) => {
                              return (
                                <Grid item xs={4}>
                                  <Box
                                    className="brand-details"
                                    onClick={() =>
                                      userSelection("brands", item)
                                    }
                                  >
                                    <img
                                      className="image"
                                      src={URL + item?.image_url}
                                    />
                                    <Typography className="vehicle-title">
                                      {item?.brand}
                                    </Typography>
                                  </Box>
                                </Grid>
                              );
                            })
                          ) : isLoading ? (
                              <LoaderSpinner />
                          ) : (
                            <Typography variant="h6">
                              Variants not Found!
                            </Typography>
                          )}
                        </>
                      ) : currentList == "Model" ? (
                        <>
                          {" "}
                          {filteredList ? (
                            filteredList?.map((item) => {
                              return (
                                <Grid item xs={4}>
                                  <Box
                                    className="brand-details"
                                    onClick={() =>
                                      userSelection("models", item)
                                    }
                                  >
                                    <img
                                      className="image"
                                      src={URL + item?.image_url}
                                    />
                                    <Typography className="vehicle-title">
                                      {item?.model}
                                    </Typography>
                                  </Box>
                                </Grid>
                              );
                            })
                          ) : isLoading ? (
                              <LoaderSpinner />
                          ) : (
                            <Typography variant="h6">
                              Variants not Found!
                            </Typography>
                          )}
                        </>
                      ) : currentList == "Variant" ? (
                        <>
                          {" "}
                          {filteredList ? (
                            filteredList?.map((item) => {
                              return (
                                <Grid item xs={4}>
                                  <Box
                                    className="brand-details"
                                    onClick={() =>
                                      userSelection("variants", item)
                                    }
                                  >
                                    <img
                                      className="image"
                                      src={URL + item?.imageUrl}
                                    />
                                    <Typography className="vehicle-title">
                                      {item?.variantName}
                                    </Typography>
                                  </Box>
                                </Grid>
                              );
                            })
                          ) : isLoading ? (
                              <LoaderSpinner />
                          ) : (
                            <Typography variant="h6">
                              Variants not Found!
                            </Typography>
                          )}
                        </>
                      ) : (
                        false
                      )}
                    </Grid>
                  </Box>
                </Box>
                {/* <Box className="search-button-box">
                  <Button
                    fullWidth
                    variant="contained"
                    className="search-button"
                  >
                    Search
                  </Button>
                </Box> */}
              </Box>
            )}
          </>
        </Box>
      </Modal>
    </Box>
  );
};

export default VehicleModal;
