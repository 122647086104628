 

 
export const DateRange_Reducer=(state=null, action)=>{

      switch (action.type) {
            case "DateRange":
            return (state=action.payload) ;
                  break;
      
            default:
                    return state
      }

}