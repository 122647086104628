import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getCatagoriesByVehicle, getServices } from "../../services/Services";
import DeleteIcon from "@mui/icons-material/Delete";
import LoaderSpinner from "../../components/loading/LoaderSpinner";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@material-ui/core/styles";
import { CommonContextAPI } from "../../App";
import "./AddLeadPackage.css";
import { useDispatch, useSelector } from "react-redux";
import { StoreLeadCardServices } from "../../action";
import { delteService } from "../../services/leadServices/LeadServices";

const AddLeadPackage = ({ lead }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [serviceCategories, setServiceCategories] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [isCategory, setIsCategory] = useState(false); //handle selection of categories inside the packages
  const [isService, setIsService] = useState(false); //handle selection of Services inside the packages
  const [isServicesLoading, setIsServicesLoading] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const LeadCardReducer = useSelector((state) => state.LeadCardReducer);

  const [servicePackages, setServicePackages] = useState([]);

  const { handleAlertOpen, colors } = useContext(CommonContextAPI);

  useEffect(() => {
    setVehicleDetails({
      ...lead,
    });
  }, [lead]);

  useEffect(() => {
    setServicesList(LeadCardReducer);
    setIsLoading(false);
  }, [LeadCardReducer]);

  const getCategories = async (vehicleType) => {
    try {
      const request = await getCatagoriesByVehicle(vehicleType);
      const response = await request.data;
      setServiceCategories(response.result);
    } catch (error) {
      handleAlertOpen(error?.message, colors?.error);
    }
  };
  useEffect(() => {
    vehicleDetails?.vehicleType?.id &&
      getCategories(vehicleDetails?.vehicleType?.id);
  }, [vehicleDetails]);

  useMemo(() => {
    selectedCategory?.categoryId &&
      (async () => {
        try {
          const request = await getServices(
            selectedCategory?.categoryId,
            vehicleDetails?.model?.modelId,
            vehicleDetails?.variant?.id
          );
          const response = await request.data;
          setServicePackages(response.result);
          setIsServicesLoading(false);
        } catch (error) {
          handleAlertOpen(error?.message, colors?.error);
        }
      })();
  }, [vehicleDetails, selectedCategory?.categoryId]);

  const handleAddServices = (name, value) => {
    if (name == "category") {
      setIsCategory(false);
      setIsService(true);
      setSelectedCategory(value);
      setIsServicesLoading(true);
    }

    if (name == "services") {
      setSelectedService(value);
      setIsService(false);
    }
  };

  const handleSelection = () => {
    if (selectedCategory?.category && selectedService?.service) {
      setSelectedCategory({});
      setSelectedService({});
      setIsCategory(false);
      setIsService(false);
    }

    !isCategory && !isService && setIsCategory(true);
    // setSelectedCategory({});
  };

  // Add Handle service
  const [newAddedServices, setNewAddedServices] = useState([]);

  const addService = () => {
    if (Object.keys(selectedService).length == 0) {
      handleAlertOpen("Please select service!", colors.error);
      setSelectedCategory({});
      setSelectedService({});
      setIsCategory(false);
      setIsService(false);
      return;
    }

    let isExists = servicesList?.allServices?.find(
      (service) => service?.serviceId == selectedService?.serviceId
    );

    if (isExists) {
      handleAlertOpen(
        `${selectedService?.service} already exists!`,
        colors.error
      );
      setSelectedCategory({});
      setSelectedService({});
      setIsCategory(false);
      setIsService(false);
      return;
    }

    dispatch(
      StoreLeadCardServices({
        allServices: [...LeadCardReducer?.allServices, selectedService],
        newServices: [...LeadCardReducer?.newServices, selectedService],
        cardTotal: LeadCardReducer?.cardTotal + selectedService?.displayPrice,
      })
    );

    // setNewAddedServices([...newAddedServices, selectedService]);
    setSelectedCategory({});
    setSelectedService({});
  };

  useMemo(() => {
    //  setUpdateObj({
    //    ...updateObj,
    //    servicesList: newAddedServices,
    //  });
  }, [newAddedServices]);

  // Delete Services

  const deleteServices1 = async () => {
    try {
      const response = await delteService();
      console.log("response", response);
    } catch (error) {
      handleAlertOpen(error?.data?.response?.message, colors?.error);
    }
  };

  const deleteServices = async (bookingId, serviceId) => {
    try {
      const response = await delteService(bookingId || serviceId, lead?.leadId);
      console.log("response", response);
      handleAlertOpen(response?.data?.message, colors?.success);
    } catch (error) {
      handleAlertOpen(error?.data?.response?.message, colors?.error);
    }

    if (bookingId) {
      let result = servicesList?.allServices?.filter(
        (service) => service?.bookingId != bookingId
      );

      let deletedService = servicesList?.allServices?.filter(
        (service) => service?.bookingId == bookingId
      );

      let newResult = servicesList?.newServices?.filter(
        (service) => service?.bookingId != bookingId
      );

      dispatch(
        StoreLeadCardServices({
          allServices: result,
          newServices: newResult,
          cardTotal:
            LeadCardReducer?.cardTotal - deletedService[0]?.displayPrice,
        })
      );

      setServicesList(result);
    } else {
      let result = servicesList?.allServices?.filter(
        (service) => service?.serviceId != serviceId
      );

      let deletedService = servicesList?.allServices?.filter(
        (service) => service?.serviceId == serviceId
      );

      let newResult = servicesList?.newServices?.filter(
        (service) => service?.serviceId != serviceId
      );

      dispatch(
        StoreLeadCardServices({
          allServices: result,
          newServices: newResult,
          cardTotal:
            LeadCardReducer?.cardTotal - deletedService[0]?.displayPrice,
        })
      );

      //   let removeNew = newAddedServices?.filter(
      //     (service) => service?.serviceId != serviceId
      //   );

      //   setNewAddedServices(removeNew);
    }

    // console.log("Booking id", bookingId);

    // try {
    //   const request = await deleteService(bookingId);
    //   const response = await request.data;
    //   window.location.reload();
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // }

    //  setDeleteInsuranceServices([...deleteInsuranceServices, bookingId]);
  };

  const clearServices = () => {
    setSelectedCategory({});
    setSelectedService({});
    setIsCategory(false);
    setIsService(false);
  };

  return (
    <Card className="services-container" onClick={clearServices}>
      <Box className="lead-card-package-container">
        <Typography className="lead-package-heading">
          {vehicleDetails?.vehicleType?.vehicleType} Packages
        </Typography>
        <Box onClick={(e) => e.stopPropagation()}>
          <Box className="lead-card-select-packaages-container" mt={3}>
            <Box className="select-packages">
              <Box className="selectCategoryLabel">
                <Typography className="selectButton" onClick={handleSelection}>
                  {selectedCategory?.category || selectedService?.service ? (
                    `${selectedCategory?.category} ${
                      selectedService?.service || ""
                    }`
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>Add Service</span>
                      <ArrowDropDownIcon />
                    </Box>
                  )}
                </Typography>
              </Box>
              <Box className="mainMenu">
                {isCategory &&
                  serviceCategories.map((item) => {
                    const { categoryId, category, imageUrl } = item;
                    return (
                      <Typography
                        className="mainMenuLink"
                        onClick={() => handleAddServices("category", item)}
                      >
                        {category}
                      </Typography>
                    );
                  })}
              </Box>
              <Box className="subMenu">
                {isServicesLoading ? (
                    <LoaderSpinner />
                ) : (
                  isService &&
                  servicePackages.map((item) => {
                    const { service, categoryId, subCategory } = item;
                    return (
                      <Box>
                        {service &&
                          service.map((ele) => {
                            const { bookingId, service } = ele;

                            return (
                              <Typography
                                className="subMenuLink"
                                onClick={() =>
                                  handleAddServices("services", ele)
                                }
                              >
                                {service}
                              </Typography>
                            );
                          })}
                      </Box>
                    );
                  })
                )}
              </Box>
            </Box>

            <Box className="add-button-box">
              <Button
                className="lead-card-add-service-button"
                onClick={addService}
              >
                <AddIcon /> {!isMobile && "Add"}
              </Button>
            </Box>
          </Box>
          {isLoading ? (
              <LoaderSpinner />
          ) : (
            <Box>
              {servicesList?.allServices?.length > 0 ? (
                <>
                  {servicesList?.allServices?.map((item, index) => {
                    const { bookingId, serviceId, service } = item;
                    return (
                      <Box className="lead-card-added-services" key={index}>
                        <Typography className="lead-card-service">
                          <span>{service}</span>
                          <DeleteIcon
                            onClick={() => deleteServices(bookingId, serviceId)}
                            className="delete-icon"
                          />
                        </Typography>
                      </Box>
                    );
                  })}
                </>
              ) : (
                <Typography>No Service</Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default AddLeadPackage;
