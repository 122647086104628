import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";

import { getVehicleType } from "../../services/leadServices/LeadServices";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { addGarage, garageTypesList } from "../../services/garageServices/GarageServices";
import "./AddGarage.css";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";

const location = [
  {
    name: "Pune",
    id: 1,
  },
  {
    name: "Banglore",
    id: 2,
  },
  {
    name: "Mumbai",
    id: 3,
  },
];

const entityTypes = [{ type: "Sole Proprietor" }, { type: "Partnership Firm" }];
const payoutBasedOn = [
  { basedOn: "Invoice Price" },
  { basedOn: "Taxable Amount" },
];

const AddGarage = ({ setIsGarageAdded }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [garageTypes, setGarageTypes] = useState([]);
  const [garageDetails, setGarageDetails] = useState({});

  // GET CONTEXT COMMON DATA
  const { handleCloseGarage, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const userSelection = (fieldName, name, id) => {
    const obj = {
      name,
      id,
    };
    setGarageDetails({ ...garageDetails, [fieldName]: id });
  };

  // Get vehicle types
  const getGarageTypes = async () => {
    try {
      const response = await garageTypesList();
      const result = await response.data;
      setGarageTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGarageTypes();
  }, []);

  const renderVehicleTypes = () => {
    return (
      <Grid item sm={8}>
        <ul className="donate-now">
          {garageTypes &&
            garageTypes.map((types, index) => {
              return (
                <li key={index}>
                  <input
                    type="radio"
                    id={types.type}
                    name="garageType"
                    value={types.id}
                    onClick={() =>
                      userSelection("garageType", types.type, types.id)
                    }
                  />
                  <label for={types.type}>{types.type}</label>
                </li>
              );
            })}
        </ul>
      </Grid>
    );
  };

  const renderForm = [
    {
      label: "Garage Name",
      fields: [
        {
          label: "Garage Name",
          field: "TextField",
          name: "garageName",
          id: "garageName",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Vehicle Type",
      fields: [
        {
          label: "Vehicle Type",
          field: "Toggles",
          name: "garageType",
          id: "garageType",
          data: garageTypes,
          properties: { name: "type", id: "id" },
          type: "text",
          render: renderVehicleTypes,
          grids: 12,
        },
      ],
    },
    {
      label: "Contact Number",
      fields: [
        {
          label: "Contact Number",
          field: "TextField",
          name: "mobileNumber",
          id: "mobileNumber",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "Username",
      fields: [
        {
          label: "Username",
          field: "TextField",
          name: "username",
          id: "username",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Password",
      fields: [
        {
          label: "Password",
          field: "TextField",
          name: "password",
          id: "password",
          type: "password",
          grids: 12,
        },
      ],
    },
    {
      label: "GSTN",
      fields: [
        {
          label: "GSTN",
          field: "TextField",
          name: "gstn",
          id: "gstn",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "PAN",
      fields: [
        {
          label: "PAN",
          field: "TextField",
          name: "pan",
          id: "pan",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Entity Type",
      fields: [
        {
          label: "Entity Type",
          field: "Dropdown",
          name: "entityType",
          data: entityTypes,
          properties: { name: "type" },
          id: "entityType",
          type: "select",
          sendVal: true,
          grids: 12,
        },
      ],
    },
    {
      label: "Payout Percentage",
      fields: [
        {
          label: "Payout Percentage",
          field: "TextField",
          name: "payoutPercentage",
          id: "payoutPercentage",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "General Discount Bearing %",
      fields: [
        {
          label: "General Discount Bearing",
          field: "TextField",
          name: "discountBearedPercentage",
          id: "discountBearedPercentage",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "Payout % on Parts",
      fields: [
        {
          label: "Payout on Parts",
          field: "TextField",
          name: "partsPayoutPercentage",
          id: "partsPayoutPercentage",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "Payout % on Labour",
      fields: [
        {
          label: "Payout on Labour",
          field: "TextField",
          name: "labourPayoutPercentage",
          id: "labourPayoutPercentage",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "Payout Based On",
      fields: [
        {
          label: "Payout Based On",
          field: "Dropdown",
          name: "payoutBasedOn",
          data: payoutBasedOn,
          properties: { name: "basedOn" },
          id: "payoutBasedOn",
          type: "select",
          sendVal: true,
          grids: 12,
        },
      ],
    },
    {
      label: "Address",
      fields: [
        {
          label: "Shop Number",
          field: "TextField",
          name: "shopNumber",
          id: "shopNumber",
          type: "select",
          grids: 6,
        },
        {
          label: "Area",
          field: "TextField",
          name: "area",
          id: "area",
          type: "select",
          grids: 6,
        },
        {
          label: "City",
          field: "Dropdown",
          name: "city",
          id: "city",
          data: location,
          properties: { name: "name", id: "id" },
          type: "select",
          sendId: true,
          grids: 6,
        },
        {
          label: "Pin Code",
          field: "TextField",
          name: "pincode",
          id: "pincode",
          type: "select",
          grids: 6,
        },
        {
          label: "Land Mark",
          field: "TextField",
          name: "landMark",
          id: "landMark",
          type: "select",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Garage"
        closeModal={handleCloseGarage}
        isAdded={setIsGarageAdded}
        renderForm={renderForm}
        service={addGarage}
        state={garageDetails}
        setState={setGarageDetails}
        navigate="/garageDatabase"
        modalHeight="90%"
      />
    </div>
  );
};

export default AddGarage;
