import { Box, Typography } from "@mui/material";
import React from "react";

const AdminConsoleDashboard = () => {
  return (
    <Box sx={{ padding: "3rem" }}>
      <Typography variant="h4" className="titles">
        Admin Console Dashboard
      </Typography>
    </Box>
  );
};

export default AdminConsoleDashboard;
