import React, { useState, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";
// import "./AddLead.css";
const AddMaterial = ({ setRowsData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [materialDetails, setMaterialDetails] = useState({});

  // GET CONTEXT COMMON DATA
  const { handleCloseAddMaterialModel } = useContext(CommonContextAPI);

  const handleAddMaterial = () => {
    let { materialPrice, gstRate } = materialDetails;
    let cgstRate = gstRate / 100 / 2;
    let sgstRate = gstRate / 100 / 2;
    let cgstAmount = cgstRate * materialPrice;
    let sgstAmount = sgstRate * materialPrice;
    const totalAmount =
      Number(materialPrice) + Number(cgstAmount) + Number(sgstAmount);

    setRowsData((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        cgstRate: cgstRate.toFixed(2),
        sgstRate: sgstRate.toFixed(2),
        cgstAmount: cgstAmount.toFixed(2),
        sgstAmount: sgstAmount.toFixed(2),
        totalAmount: totalAmount.toFixed(2),
        ...materialDetails,
      },
    ]);
    handleCloseAddMaterialModel();
  };

  const renderForm = [
    {
      label: "HSN",
      fields: [
        {
          label: "HSN",
          field: "TextField",
          name: "hsn",
          id: "hsn",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "HSN Item Name",
      fields: [
        {
          label: "HSN Item Name",
          field: "TextField",
          name: "hsnItemName",
          id: "hsnItemName",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Description",
      fields: [
        {
          label: "Description",
          field: "TextField",
          name: "description",
          id: "description",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Brand",
      fields: [
        {
          label: "Brand",
          field: "TextField",
          name: "brand",
          id: "brand",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Grade",
      fields: [
        {
          label: "Grade",
          field: "TextField",
          name: "grade",
          id: "grade",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Material Price",
      fields: [
        {
          label: "Material Price",
          field: "TextField",
          name: "materialPrice",
          id: "materialPrice",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "GST Rate (%)",
      fields: [
        {
          label: "GST Rate (%)",
          field: "TextField",
          name: "gstRate",
          id: "gstRate",
          type: "text",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Material"
        closeModal={handleCloseAddMaterialModel}
        isAdded={setRowsData}
        renderForm={renderForm}
        // service={saveInvoice}
      />
    </div>
  );
};

export default AddMaterial;
