import { Box, Button, Typography } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import {
  getBankAccountByGarageId,
  getUPIByGarageId,
  updateGaragePayout,
} from "../../services/garageServices/GarageServices";
import "./PaymentOptionModal.css";

const ActiveButton = {
  backgroundColor: "#122164",
  color: "white",
};

const DisActiveButton = {
  backgroundColor: "transparent",
  color: "black",
};
export default function PaymentOptionModal({
  setPaymentModal,
  paymentMethod,
  getGaragePayoutData,
}) {
  const [selecteMethod, setSelecteMethod] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [UPI, setUPI] = useState();
  const [Bank, setBank] = useState();

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleOptions = (e) => {
    const Name = e.target.innerText;
    setSelecteMethod(Name);
    switch (Name) {
      case "UPI":
        setSelectedId({ upiId: UPI.id });
        break;
      case "Bank":
        setSelectedId({ bankAccount: Bank.id });
        break;
      case "Cash":
        setSelectedId({ otherMethodDescription: "Cash" });
        break;
    }
  };

  const handleConfirm = async () => {
    let updateObj = {
      id: paymentMethod.id,
      payoutStatus: paymentMethod.payoutStatus,
      otherMethod: true,
      ...selectedId,
      // upiId:2,
      // bankAccount: 2,
    };
    try {
      const request = await updateGaragePayout(updateObj, config);
      const response = await request.data;
      console.log(response);
     await response.statusCode === 200 && getGaragePayoutData();
  } catch (err) {
      console.log(err);
    }
    setPaymentModal(false);
  };

  const getUPIByGarage = async () => {
    try {
      const request = await getUPIByGarageId(paymentMethod.garageId, config);
      const response = await request.data;
      setUPI(...response?.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getBankAccount = async () => {
    try {
      const request = await getBankAccountByGarageId(
        paymentMethod.garageId,
        config
      );
      const response = await request.data;
      setBank(...response?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUPIByGarage();
    getBankAccount();
  }, [paymentMethod]);

  return (
    <Box className="pmOp-container">
      <Box className="pmOp-header">Please select payment method:</Box>
      <Box className="pmOp-buttons">
        <button
          className="pmOp-button"
          onClick={handleOptions}
          style={selecteMethod === "UPI" ? ActiveButton : DisActiveButton}
        >
          UPI
        </button>
        <button
          className="pmOp-button"
          onClick={handleOptions}
          style={selecteMethod === "Bank" ? ActiveButton : DisActiveButton}
        >
          Bank
        </button>
        <button
          className="pmOp-button"
          onClick={handleOptions}
          style={selecteMethod === "Cash" ? ActiveButton : DisActiveButton}
        >
          Cash
        </button>
      </Box>

      <Box className="pmOp-footer-buttons">
        <button
          className="discard-button"
          onClick={() => {
            setPaymentModal(false);
            getGaragePayoutData();
          }}
        >
          Discard
        </button>
        <button className="confirm-button" onClick={handleConfirm}>
          Confirm
        </button>
      </Box>
    </Box>
  );
}
