import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { getVehicleType } from "../../services/leadServices/LeadServices";
import { Grid } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  editGarage,
  addGarageBankAccountDetails,
  addGarageUpiAccount,
  garageTypesList,
} from "../../services/garageServices/GarageServices";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import "./AddGarage.css";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "100%",
  },
  gridRow: {
    display: "flex",
    alignItems: "center",
  },
  garageTitle: {
    fontSize: "2rem",
  },
  listItem: {
    width: "100%",
  },
}));
const entityTypes = ["Sole proprietor", "Partnership Firm"];
const payoutBasedOn = ["Invoice Price", "Taxable Amount"];
const locations = [
  {
    city: "Pune",
    id: 1,
  },
  {
    city: "Banglore",
    id: 2,
  },
  {
    city: "Mumbai",
    id: 3,
  },
];

const BasicInfo = ({ garageId, garageDetails, setGarageDetails }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const [garageTypes, setGarageTypes] = useState([]);
  const [basicDetails, setBasicDetails] = useState({});

  // GET CONTEXT COMMON DATA
  const { handleCloseGarage, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("value", value);

    if (name == "gstn" || name == "pan") {
      setGarageDetails({ ...garageDetails, [name]: value?.toUpperCase() });
    } else if (name == "username") {
      setGarageDetails({ ...garageDetails, [name]: value });
    } else {
      setGarageDetails({
        ...garageDetails,
        [name]: value
          ?.split(" ")
          .map((ar) => ar.charAt(0).toUpperCase() + ar.slice(1))
          .join(" "),
      });
    }
  };

  const userSelection = (fieldName, name, id) => {
    const obj = {
      name,
      id,
    };

    setGarageDetails({ ...garageDetails, [fieldName]: id });
  };

  useEffect(() => {
    // setBasicDetails(garageDetails);
  }, [, garageDetails]);

  const discardDetails = () => {
    setGarageDetails({
      ...garageDetails,
      entityType: "",
      garageName: "",
      garageType: "",
      // garageId: "",
      payoutPercentage: "",
      payoutBasedOn: "",
      franchiseAmount: "",
    });
    // setBasicDetails(garageDetails);
  };

  const saveGarageDetails = async () => {
    const garageObject = {
      area: garageDetails?.area,
      city: garageDetails?.city,
      entityType: garageDetails?.entityType,
      garageName: garageDetails?.garageName,
      garageType: garageDetails?.garageType,
      garageId: garageDetails?.garageId,
      gstn: garageDetails?.gstn,
      landmark: garageDetails?.landmark,
      mobileNumber: garageDetails?.mobileNumber,
      pan: garageDetails?.pan,
      payoutPercentage: Number(garageDetails?.payoutPercentage),
      discountBearedPercentage: Number(garageDetails?.discountBearedPercentage),
      partsPayoutPercentage: Number(garageDetails?.partsPayoutPercentage),
      labourPayoutPercentage: Number(garageDetails?.labourPayoutPercentage),
      payoutBasedOn: garageDetails?.payoutBasedOn,
      franchiseAmount: garageDetails?.franchiseAmount,
      dateOfPayment: garageDetails?.dateOfPayment,
      pincode: garageDetails?.pincode,
      shopNumber: garageDetails?.shopNumber,
      tehsil: garageDetails?.shopNumber,
      district: garageDetails?.district,
      state: garageDetails?.state,
      country: garageDetails?.country,
    };

    const localData = JSON.parse(localStorage.getItem("loginDetails"));

    const config = {
      headers: {
        Authorization: `Bearer ${localData.jwtToken}`,
      },
    };
    try {
      const response = await editGarage(garageObject, config);
      const data = await response.data;
      handleAlertOpen(data?.message, colors?.success);
      navigate("/garageDatabase");
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors?.error);
      console.log("error", error);
    }
  };

  // Get vehicle types
  const getGarageTypes = async () => {
    // const localData = JSON.parse(localStorage.getItem("loginDetails"));

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${localData.jwtToken}`,
    //   },
    // };
    try {
      const response = await garageTypesList();
      const result = await response.data;
      setGarageTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGarageTypes();
    console.log("garageDetails?.garageType", garageDetails?.garageType);
  }, []);

  return (
    <Box className={classes.container}>
      {/* <h2 className={classes.garageTitle}>Edit Garage</h2> */}
      <Grid container spacing={1} className={classes.gridRow}>
        <Grid item xs={4}>
          <Typography className="lables">Garage Name</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            size="small"
            fullWidth
            margin="dense"
            variant="outlined"
            // label="Garage Name"
            label={garageDetails?.garageName ? "" : "Garage Name"}
            id="garageName"
            name="garageName"
            onChange={handleChange}
            value={garageDetails?.garageName}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Garage Type</Typography>
        </Grid>
        <Grid item sm={8} style={{ margin: "0.4rem 0px" }}>
          <ul className="donate-now">
            {garageTypes &&
              garageTypes?.map((types, index) => {
                return (
                  <li key={index}>
                    <input
                      type="radio"
                      id={types?.type}
                      name="garageType"
                      value={types.id}
                      defaultChecked={
                        types?.id == garageDetails?.garageType
                          ? true
                          : false
                      }
                      onClick={() =>
                        userSelection("garageType", types?.type, types?.id)
                      }
                    />
                    <label for={types?.id}>{types?.type}</label>
                  </li>
                );
              })}
          </ul>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Entity Type</Typography>
        </Grid>
        <Grid item xs={8}>
          <select
            name="entityType"
            onChange={handleChange}
            className="selectDropdown"
          >
            <option value={garageDetails && garageDetails?.entityType}>
              {garageDetails && garageDetails?.entityType}
            </option>
            {entityTypes &&
              entityTypes?.map((entity, index) => {
                return (
                  <option value={entity} key={index}>
                    {entity}
                  </option>
                );
              })}
          </select>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Payout Percentage</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                // label="Payout Percentage"
                label={
                  garageDetails?.payoutPercentage ? "" : "Payout Percentage"
                }
                id="payoutPercentage"
                name="payoutPercentage"
                onChange={handleChange}
                value={garageDetails?.payoutPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">General Discount Bearing %</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                // label="Payout Percentage"
                label={
                  garageDetails?.discountBearedPercentage
                    ? ""
                    : "General Discount Bearing"
                }
                id="discountBearedPercentage"
                name="discountBearedPercentage"
                onChange={handleChange}
                value={garageDetails?.discountBearedPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Payout % on Parts</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                // label="Payout Percentage"
                label={
                  garageDetails?.partsPayoutPercentage ? "" : "Payout on Parts"
                }
                id="partsPayoutPercentage"
                name="partsPayoutPercentage"
                onChange={handleChange}
                value={garageDetails?.partsPayoutPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Payout % on Labour</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                // label="Payout Percentage"
                label={
                  garageDetails?.labourPayoutPercentage
                    ? ""
                    : "Payout on Labour"
                }
                id="labourPayoutPercentage"
                name="labourPayoutPercentage"
                onChange={handleChange}
                value={garageDetails?.labourPayoutPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Payout Based On</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <select
                name="payoutBasedOn"
                onChange={handleChange}
                className="selectDropdown"
              >
                <option value={garageDetails && garageDetails?.payoutBasedOn}>
                  {garageDetails && garageDetails?.payoutBasedOn}
                </option>
                {payoutBasedOn &&
                  payoutBasedOn?.map((payout, index) => {
                    return (
                      <option value={payout} key={index}>
                        {payout}
                      </option>
                    );
                  })}
              </select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Franchise Amount</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label={garageDetails?.franchiseAmount ? "" : "Franchise Amount"}
                id="franchiseAmount"
                name="franchiseAmount"
                onChange={handleChange}
                value={garageDetails?.franchiseAmount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {garageDetails?.dateOfPayment ||
        (true && (
          <Grid container className={classes.gridRow} spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">Date Of Payment</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    // label={
                    //   garageDetails?.franchiseAmount ? "" : "Franchise Amount"
                    // }
                    id="dateOfPayment"
                    name="dateOfPayment"
                    onChange={handleChange}
                    value={garageDetails?.dateOfPayment}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4}></Grid>
        <Grid item xs={8} sm={6}>
          <div className="buttonGroup">
            <Button
              variant="contained"
              size="small"
              className="buttons"
              onClick={discardDetails}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              size="small"
              className="buttons"
              style={{ marginLeft: "1rem" }}
              onClick={saveGarageDetails}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicInfo;
