import React from "react";
import { Typography } from "@mui/material";

const Sessions = () => {
  return (
    <div style={{ padding: "3rem" }}>
      <Typography variant="h4" className="titles">Sessions</Typography>
    </div>
  );
};

export default Sessions;
