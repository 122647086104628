import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AddIcon from "@mui/icons-material/Add";
import { Toolbar } from "@mui/material";
import { Outlet } from "react-router";
import EditUser from "./EditUser";
import { getUserProfile } from "../../../services/userProfileServices/UserProfileServices";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: "#E9E9E9",
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.3rem",
    },
  },
  pageTitle: {
    fontSize: "2.5rem",
    marginBottom: "1rem",
    // fontFamily: "AvenirBold !important",
  },
  userDetailsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  userIcon: {
    fontSize: "4rem",
  },
  gridRow: {
    margin: "1rem 0 4rem",
  },
  iconBox: {
    width: "80px",
  },
  userTitle: {
    fontSize: "1.5rem",
    fontWeight: 400,
    wordBreak: "break-word",
  },
  userEmail: {
    wordBreak: "break-word",
  },
  editButtonCol: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "30px !important",
    "& Button": {
      height: "40px",
      width: "100px",
      backgroundColor: "#10bc83",
      "&:hover": {
        backgroundColor: "#10bc83",
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginTop: "1rem",
    },
  },
  userDetailsCols: {
    margin: "5px 0px",
    width: "100%",
  },
  userValues: {
    fontSize: "1.2rem",
    fontWeight: 400,
    lineHeight: 2,
    width: "100%",
    wordWrap: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  userKeys: {
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  addEmailWrapper: {
    display: "flex",
    justifyContent: "center",
    "& Button": {
      color: "#0291FF !important",
    },
  },
  userEmailWrapper: {
    display: "flex",
    alignItems: "center",
  },
  email: {
    fontSize: "1.2rem",
    fontWeight: 400,
    lineHeight: 2,
  },
  emailAvater: {
    width: "50px",
    height: "50px",
    backgroundColor: "#0291FF",
  },

  phoneAvater: {
    width: "50px",
    height: "50px",
    backgroundColor: "#00bf9a",
  },
  emailIcon: {
    fontSize: "2rem",
  },
  primaryEmailIcon: {
    marginLeft: "1rem",
    fontSize: "2rem",
    cursor: "pointer",
    color: "#00bf9a",
  },
  emailHeading: {
    lineHeight: 2,
  },
  addIcon: {
    fontSize: "2rem",
    marginRight: "0.5rem",
  },
  userPhoneNumberWrapper: {
    display: "flex",
    alignItems: "center",
  },
  phoneIcon: {
    fontSize: "2rem",
  },
  phoneHeading: {
    lineHeight: 2,
  },
  phoneNumber: {
    fontSize: "1.2rem",
    fontWeight: 400,
    lineHeight: 2,
  },
  verifyIcon: {
    marginLeft: "100px !important",
    fontSize: "2rem",
    cursor: "pointer",
    color: "#0291FF",
  },
  addPhoneNumberWrapper: {
    display: "flex",
    justifyContent: "center",
    "& Button": {
      color: "#0291FF !important",
    },
  },
  toolbar: {
    height: "3rem !important",
    minHeight: "2rem !important",
  },
  card: {
    minWidth: 275,
    padding: "2rem",
    [theme.breakpoints.down("sm")]: { padding: "0.2rem" },
  },
  cardHeader: {
    boxShadow: "0 0 1px black",
    padding: "1rem 3rem",
    backgroundColor: "#f6f6f6",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [currentUserDetails, setCurrentUserDetails] = useState({});

  const handleEdit = (state) => {
    setIsEditing(state);
  };

  const localData = JSON.parse(localStorage.getItem("loginDetails"));
  const loggedUserDetails = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData.jwtToken}`,
      },
    };
    try {
      const response = await getUserProfile(config);
      const data = await response.data;
      setCurrentUserDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loggedUserDetails();
  }, [, isEditing]);

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h4" className={`titles ${classes.pageTitle}`}>
          Profile
        </Typography>
        <Box className={classes.userDetailsContainer}>
          <Card className={classes.card}>
            <CardContent>
              <Grid
                container
                className={classes.gridRow}
                style={{ marginBottom: isEditing ? "1rem" : "4rem" }}
              >
                <Grid item sm={12} md={6} sx={{ margin: "0px" }}>
                  <Box className={classes.userDetailsWrapper}>
                    <Box className={classes.iconBox}>
                      <AccountCircleIcon className={classes.userIcon} />
                    </Box>
                    <Box>
                      <Typography className={classes.userTitle}>
                        {`${currentUserDetails?.firstName} ${currentUserDetails?.lastName}`}
                      </Typography>
                      <Typography className={classes.userEmail}>
                        {currentUserDetails?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {!isEditing && (
                  <Grid item sm={12} md={6} className={classes.editButtonCol}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={() => handleEdit(true)}
                    >
                      Edit
                    </Button>
                  </Grid>
                )}
              </Grid>
              {isEditing ? (
                <EditUser
                  handleEdit={handleEdit}
                  isEditing={isEditing}
                  uId={currentUserDetails?.id}
                />
              ) : (
                <Grid container spacing={2}>
                  <Grid item sm={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          First Name
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.firstName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          Last Name
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.lastName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          Email
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          Username
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.userName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          Mobile Number
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.mobileNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          Role
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.role}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          PAN Number
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.pancard}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.userDetailsCols}>
                        <Typography className={classes.userKeys}>
                          GST Number
                        </Typography>
                        <Typography className={classes.userValues}>
                          {currentUserDetails?.gstn}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={4}></Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Box>
        {/* <Toolbar className={classes.toolbar} />
        <Box className={classes.emailContainer}>
          <Card sx={{ minWidth: 275 }}>
            <CardHeader
              title={
                <Typography variant="h5" className={classes.emailHeading}>
                  My Email Address
                </Typography>
              }
              subheader={
                "You can use the following email addresses to sign in to your account and also to reset your password if you ever forget it"
              }
              className={classes.cardHeader}
            />
            <CardContent sx={{ padding: "3rem" }}>
              <Box className={classes.userEmailWrapper}>
                <Box className={classes.iconBox}>
                  <Avatar className={classes.emailAvater}>
                    <EmailIcon className={classes.emailIcon} />
                  </Avatar>
                </Box>
                <Box>
                  <Typography className={classes.email}>
                    parmeshwar.ghadge@kalyanistudio.com
                  </Typography>
                  <Typography>5 Month ago</Typography>
                </Box>
                <Box className={classes.iconBox}>
                  <AccountCircleIcon className={classes.primaryEmailIcon} />
                </Box>
              </Box>
            </CardContent>
            <CardActions className={classes.addEmailWrapper}>
              <Button size="small" variant="default" color="primary">
                <AddIcon className={classes.addIcon} />
                Add Email Address
              </Button>
            </CardActions>
          </Card>
        </Box>
        <Toolbar className={classes.toolbar} />
        <Box className={classes.phoneContainer}>
          <Card sx={{ minWidth: 275 }}>
            <CardHeader
              title={
                <Typography variant="h5" className={classes.phoneHeading}>
                  My Mobile Number
                </Typography>
              }
              subheader={
                "View and manage all of the mobile numbers associated with your account."
              }
              className={classes.cardHeader}
            />
            <CardContent sx={{ padding: "3rem" }}>
              <Box className={classes.userPhoneNumberWrapper}>
                <Box className={classes.iconBox}>
                  <Avatar className={classes.phoneAvater}>
                    <CallIcon className={classes.phoneIcon} />
                  </Avatar>
                </Box>
                <Box>
                  <Typography className={classes.phoneNumber}>
                    (+91)7741070509
                  </Typography>
                  <Typography>22 Hours ago</Typography>
                </Box>
                <Box className={classes.iconBox}>
                  <VerifiedUserIcon className={classes.verifyIcon} />
                </Box>
              </Box>
            </CardContent>
            <CardActions className={classes.addPhoneNumberWrapper}>
              <Button size="small" variant="default" color="primary">
                <AddIcon className={classes.addIcon} /> Add Mobile Number
              </Button>
            </CardActions>
          </Card>
        </Box> */}
      </div>
    </>
  );
};

export default Profile;
