import React from "react";
import { Typography } from "@mui/material";

const MultifactorAuth = () => {
  return (
    <div style={{ padding: "3rem" }}>
      <Typography variant="h4" className="titles">Multifactor Authentication</Typography>
    </div>
  );
};

export default MultifactorAuth;
