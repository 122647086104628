import React, { useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles({
  myAlert: {
    backgroundColor: "red",
    color: "white",
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = () => {
  const classes = useStyles();
  // GET CONTEXT COMMON DATA
  const { openAlert, handleAlertClose, errorMessage } =
    useContext(CommonContextAPI);

  const position = {
    vertical: "top",
    horizontal: "center",
  };

  const { vertical, horizontal } = position;

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleAlertClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleAlertClose}
          // severity="success"
          severity={errorMessage?.color == "red" ? "error" : "success"}
          sx={{ width: "100%" }}
          // className={classes.myAlert}
          style={{ backgroundColor: errorMessage?.color, color: "white" }}
        >
          {errorMessage?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AlertMessage;
