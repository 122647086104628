import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import "./DatePicker.css";
import { useSelector } from "react-redux";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  endOfYear,
  addMonths,
  addYears,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";
import { CommonContextAPI } from "../../App";

const DatePicker = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    setFilteredDate,
    openDatePicker,
    handleCloseDatePicker,
    filterDateRenge,
    setApplyFilter,
  } = commonData;

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 860,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    height: isMobile ? "90%" : 600,
    overflow: isMobile ? "auto" : "none",
    border: "none !important",
  };

  const setDate = () => {
    state && setFilteredDate && setFilteredDate(state);
  };

  return (
    <div>
      <Modal
        // open={openDatePicker}
        open={true}
        onClose={handleCloseDatePicker}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box style={{ margin: "1rem" }}>
            <Box
              style={{
                position: "absolute",
                top: 0,
                right: "10px",
                margin: "0.2rem 0px",
              }}
            >
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={handleCloseDatePicker}
              />
            </Box>
            <h3>Date Filter</h3>
          </Box>
          <DateRangePicker
            className="dataRangePicker"
            style={{
              width: "300px",
              height: "100%",
              margin: "0px",
              padding: "0px",
            }}
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            staticRanges={[
              ...defaultStaticRanges,
              {
                label: "Last Year",
                range: () => ({
                  startDate: startOfYear(addYears(new Date(), -1)),
                  endDate: endOfYear(addYears(new Date(), -1)),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "This Year",
                range: () => ({
                  startDate: startOfYear(new Date()),
                  endDate: endOfDay(new Date()),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
              {
                label: "All Time",
                range: () => ({
                  startDate: new Date("2021-6-1"),
                  endDate: endOfDay(new Date()),
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                },
              },
            ]}
          />
          <Button
            variant="contained"
            onClick={() => {
              handleCloseDatePicker();
              setDate();
              filterDateRenge();
              setApplyFilter(true);
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default DatePicker;
