import { Box, Typography, Input } from "@mui/material";
import React, { useContext, useState, useEffect, useMemo } from "react";
import httpCommon from "../../../http-common";
import { jobCardContext } from "../OrderDetails";
import {
  getBrandsByVehicle,
  getModelByVehicleAndBrand,
  getOrderById,
  getVariants,
} from "../../../services/Services";
import "./UserInfoSection.css";
import LoaderSpinner from "../../../components/loading/LoaderSpinner";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

export default function UserInfoSection() {
  const [isLoading, setIsLoading] = useState(true);
  const [Brand, setBrand] = useState(false);
  const [Model, setModel] = useState(false);
  const [Variant, setVariant] = useState(false);

  const [ShowBrands, setShowBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [ShowModel, setShowModel] = useState([]);
  const [selectedModel, setSelectedModel] = useState({
    imagePath: "",
    model: "",
  });
  const [ShowVariant, setShowVariant] = useState([]);
  const [selectedVariant, setselectedVariant] = useState();
  const [updateVehicle, setUpdateVehicle] = useState();

  // let updateAddressObj={
  //   "houseNumber": "#104, Capital O",
  //     "country": "India",
  //     "state": "Punjab",
  //     "district": "Bathinda",
  //     "tehsil": "BTI",
  //     "city": "Bathinda",
  //     "area": "Surya City",
  //     "pincode":151001,
  //     "landmark":"Anil",
  // }

  const [address, setAddress] = useState();
  const [customerGST, setCustomerGST] = useState();

  const baseURL = httpCommon.defaults.baseURL;

  const {
    updateObj,
    setUpdateObj,
    updateUserName,
    setUpdateUserName,
    orderId,
    OrderInfo,
    setIsUpdateUser,
  } = useContext(jobCardContext);

  useMemo(() => {
    setIsLoading(false);
  }, [OrderInfo]);
  const Login = localStorage.getItem("loginDetails");
  const LoginRole = JSON.parse(Login).role;

  const [NewObj, setNewObj] = useState();

  const UserId = OrderInfo?.user?.user_id;
  const vehicleId = OrderInfo?.vehicle?.vehicleType?.id;
  const firstName = OrderInfo?.user?.first_name;
  const lastName = OrderInfo?.user?.last_name;
  const VehicleBrand = OrderInfo?.vehicle?.brand?.brand;
  const brandId = OrderInfo?.vehicle?.brand?.brandId;
  const VehicleModel = OrderInfo?.vehicle?.model.model;
  const modelId = OrderInfo?.vehicle?.model?.modelId;
  const VehicleImage = OrderInfo?.vehicle?.model?.imageUrl;
  const VehicleVariant = OrderInfo?.vehicle?.variant?.variantName;
  const variantId = OrderInfo?.vehicle?.variant?.id;
  const vehicleNumber = OrderInfo?.vehicle?.vehicleNumber;
  const yearOfManufacture = OrderInfo?.vehicle?.yearOfManufacture;
  const mobileNumber = OrderInfo?.user?.mobile_number;
  const defaultCustomerGST = OrderInfo?.user?.gstn;
  const DefaultAddress = OrderInfo?.address;
  useMemo(() => {
    setAddress({
      houseNumber: DefaultAddress?.houseNumber,
      area: DefaultAddress?.area,
      city: DefaultAddress?.city,
    });
  }, [DefaultAddress]);
  useMemo(() => {
    setCustomerGST(defaultCustomerGST);
  }, [defaultCustomerGST]);

  const customerName = firstName + " " + lastName;

  useEffect(() => {
    setUpdateVehicle({
      ...updateVehicle,
      vehicleType: vehicleId,
      brand: brandId,
      model: modelId,
      variant: variantId,
      vehicleNumber: vehicleNumber,
      yearOfManufacture,
    });
  }, [OrderInfo]);

  useMemo(() => {
    selectedVariant &&
      setUpdateObj({ ...updateObj, userVehicle: { ...updateVehicle } });
  }, [selectedVariant]);

  let V = updateVehicle?.vehicleNumber;
  useMemo(() => {
    setUpdateObj({ ...updateObj, userVehicle: { ...updateVehicle } });
  }, [V]);

  useMemo(() => {
    setUpdateObj({ ...updateObj, address });
  }, [address]);

  const handelSelectBrand = () => {
    setBrand(true);
  };

  const handelClickBrand = (brandData) => {
    setSelectedBrand(brandData.brand);
    getModelInfo(brandData.brand_Id); //brand Id send getModel As Props
    setUpdateVehicle({ ...updateVehicle, brand: brandData.brand_Id });
    setBrand(false);
    setModel(true);
    setVariant(false);
  };

  const handelClickModel = (modelData) => {
    setSelectedModel({
      imagePath: modelData.image_url,
      model: modelData.model,
    });
    getVariantInfo(modelData.model_id); //Model id send Gey variant As Props
    setUpdateVehicle({ ...updateVehicle, model: modelData.model_id });
    setBrand(false);
    setModel(false);
    setVariant(true);
  };

  const handelClickVariant = (variantData) => {
    setselectedVariant(variantData.variantName);
    setUpdateVehicle({ ...updateVehicle, variant: variantData.variantId });
    setBrand(false);
    setModel(false);
    setVariant(false);
  };

  const handelVehicleNumber = (e) => {
    setUpdateVehicle({
      ...updateVehicle,
      vehicleNumber: e.target.value,
    });
  };

  const handelYearOfManufacture = (e) => {
    setUpdateObj({
      ...updateObj,
      userVehicle: { ...updateVehicle, yearOfManufacture: e.target.value },
    });
  };

  const handelUserName = (e) => {
    setIsUpdateUser(true);
    let userName = e.target.value;
    const name = userName.split(" ");
    setUpdateUserName({
      ...updateUserName,
      firstName: name[0],
      lastName: name[1],
    });
  };

  useMemo(() => {
    setIsUpdateUser(true);
    setUpdateUserName({
      ...updateUserName,
      gstn: customerGST,
    });
  }, [customerGST]);

  const handelTextarea = (e) => {
    setAddress(e.target.value);
    const height = e.target.scrollHeight;
    const rowHeight = 15;
    const trows = Math.ceil(height / rowHeight) - 1;
  };

  const handelAddress = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const getBrandInfo = async () => {
    try {
      const request = await getBrandsByVehicle(vehicleId);
      const response = await request.data;
      setShowBrands(response.result);
    } catch (error) {
      // console.log(error);
    }
  };
  const getModelInfo = async (brandId) => {
    try {
      const request = await getModelByVehicleAndBrand(vehicleId, brandId);
      const response = await request.data;
      setShowModel(response.result);
    } catch (error) {
      // console.log(error);
    }
  };
  const getVariantInfo = async (modelId) => {
    try {
      const request = await getVariants(modelId);
      const response = await request.data;
      setShowVariant(response.result);
    } catch (error) {
      // console.log(error);
    }
  };
  useMemo(async () => {
    Brand && (await getBrandInfo());
    setSelectedModel({
      imagePath: VehicleImage,
      model: VehicleModel,
    });
  }, [Brand]);
  return (
    <Box className="user-info-container">
      <Box className="user-info-header">
        <Typography className="user-info-heading">Job Card</Typography>
        <Box className="user-info-logo">
          <img
            src={
              process.env.PUBLIC_URL + "/Assets/logos/GativanFullLogoWhite.png"
            }
            alt=""
          />
        </Box>
      </Box>
      <Box className="user-info-body">
        <Box className="user-info-details">
          {firstName === undefined ? (
            <Typography className="user-info-label">{customerName}</Typography>
          ) : (
            <input
              className="user-info-label user-info-input"
              name="customerName"
              onChange={handelUserName}
              defaultValue={customerName && customerName}
              // disableUnderline
            />
          )}

          <Box className="user-info-vehicle" display="flex">
            <Box>
              <Typography
                className="user-info-field"
                onClick={handelSelectBrand}
              >
                {" "}
                {selectedBrand
                  ? selectedBrand
                  : VehicleBrand
                  ? VehicleBrand
                  : "select brand"}{" "}
              </Typography>
              {Brand && ShowBrands.length > 0 && (
                <Box className="user-info-vehicle-selections">
                  {ShowBrands &&
                    ShowBrands.map((item, index) => {
                      return (
                        <li key={index} onClick={() => handelClickBrand(item)}>
                          {item.brand}
                        </li>
                      );
                    })}
                </Box>
              )}
            </Box>
            <Box ml={1}>
              <Typography className="user-info-field">
                {selectedModel.model
                  ? selectedModel.model
                  : VehicleModel
                  ? VehicleModel
                  : "select model"}
              </Typography>

              {Model && ShowModel.length > 0 && (
                <Box className="user-info-vehicle-selections">
                  {ShowModel &&
                    ShowModel.map((item, index) => {
                      return (
                        <li key={index} onClick={() => handelClickModel(item)}>
                          {item.model}
                        </li>
                      );
                    })}
                </Box>
              )}
            </Box>
            <Box ml={1}>
              <Typography className="user-info-field">
                {selectedVariant ? selectedVariant : VehicleVariant}
              </Typography>
              {Variant && ShowVariant.length > 0 && (
                <Box className="user-info-vehicle-selections">
                  {ShowVariant &&
                    ShowVariant?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handelClickVariant(item)}
                        >
                          {item.variantName}
                        </li>
                      );
                    })}
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <input
              className="user-info-field user-info-input"
              defaultValue={vehicleNumber}
              placeholder="Vehicle Number"
              onChange={handelVehicleNumber}
            />
          </Box>
          <Box>
            <input
              className="user-info-field user-info-input"
              defaultValue={yearOfManufacture}
              placeholder="Year of manufacture "
              onChange={handelYearOfManufacture}
              disableUnderline
            />
          </Box>
          <Box>
            <Typography className="user-info-label">
              Customer Contact
            </Typography>
            <Typography className="user-info-field ">
              {LoginRole != "Gativan Expert" && mobileNumber}
            </Typography>
          </Box>
          <Box>
            <Typography className="user-info-label">
              Customer Address
            </Typography>
            <Box className="user-info-address-section">
              <Input
                placeholder="house Number"
                value={address?.houseNumber}
                onChange={handelAddress}
                name="houseNumber"
                className="user-info-address"
                disableUnderline
              ></Input>
              <Input
                placeholder="area"
                value={address?.area}
                onChange={handelAddress}
                name="area"
                className="user-info-address"
                disableUnderline
              ></Input>
            </Box>
            <Box>
              <Input
                placeholder="city"
                value={address?.city}
                onChange={handelAddress}
                name="city"
                className="user-info-address"
                disableUnderline
              ></Input>
            </Box>
          </Box>
          <Box>
            <Typography className="user-info-label">
              Customer GSTIN :
            </Typography>
            <Input
              className="user-info-field"
              name="gstn"
              placeholder="GST number"
              value={customerGST && customerGST}
              onChange={(e) => setCustomerGST(e.target.value)}
              disableUnderline
            ></Input>
          </Box>
        </Box>
        <Box className="user-info-vehicleImg">
          <img
            src={`${baseURL}${
              selectedModel?.imagePath ? selectedModel?.imagePath : VehicleImage
            }`}
            alt={selectedModel.image_url}
          />
        </Box>
      </Box>
    </Box>
  );
}
