import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/tables/Table";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarImport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getGarages } from "../../services/garageServices/GarageServices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import EditGarage from "../../modules/garages/EditGarage";
//EditGarage";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";
import { ABPYears, selectedYear, selectedYearMonths } from "../../action";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const ABPDataRow = [
  {
    financialYear: 2020,
    visitorsTarget: 1000,
    leadsTarget: 400,
    leadsConversion: 300,
    carOrders: 200,
    carSales: 23232,
    bikeOrders: 200,
    bikeSales: 3232,
  },
  {
    financialYear: 2021,
    visitorsTarget: 1000,
    leadsTarget: 400,
    leadsConversion: 300,
    carOrders: 200,
    carSales: 23232,
    bikeOrders: 200,
    bikeSales: 3232,
  },
];

const ABPMonthlyDataRow = [
  {
    financialYear: "FY 2020",
    visitorsTarget: 1000,
    leadsTarget: 400,
    leadsConversion: 300,
    carOrders: 200,
    carSales: 23232,
    bikeOrders: 200,
    bikeSales: 3232,
  },
  {
    financialYear: "Jan 2020",
    visitorsTarget: 1000,
    leadsTarget: 400,
    leadsConversion: 300,
    carOrders: 200,
    carSales: 23232,
    bikeOrders: 200,
    bikeSales: 3232,
  },
  {
    financialYear: "Feb 2021",
    visitorsTarget: 1000,
    leadsTarget: 400,
    leadsConversion: 300,
    carOrders: 200,
    carSales: 23232,
    bikeOrders: 200,
    bikeSales: 3232,
  },
];

function ABP() {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [ABPData, setABPData] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { importedExcelData } = useSelector((state) => state?.ABPReducer);

  const [importedColumns, setImportedColumns] = useState(null);

  useEffect(() => {
    dispatch(ABPYears([2021, 2022, 2023]));
    dispatch(selectedYearMonths(["MARCH", "APRIL"]));
    dispatch(selectedYear(2023));
    setABPData(ABPDataRow);
  }, []);

  useEffect(() => {
    ABPDataRow && setABPData(ABPMonthlyDataRow);
  }, [ABPDataRow, ABPMonthlyDataRow]);

  useEffect(() => {
    const { rows, columns } = importedExcelData;

    if (rows && columns) {
      let column = columns?.map((col) => {
        return {
          field: col,
          headerName: col?.toUpperCase(),
          headerClassName: "super-app-theme--header",
          minWidth: 80,
          flex: 1,
        };
      });
      setABPData(rows);
      setImportedColumns(column);
    }
  }, [importedExcelData]);

  const getFinancialYearData = async () => {
    try {
      const response = await getGarages();
      const data = await response.data;
      // setABPData(data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "financialYear",
      headerName: "FY",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "visitorsTarget",
      headerName: "Visitors Target",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "leadsTarget",
      headerName: "Leads",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "leadsConversion",
      headerName: "Leads Conversion",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "carOrder",
      headerName: "Car Order",
      headerClassName: "super-app-theme--header",
      minWidth: 110,
    },
    {
      field: "carSales",
      headerName: "Car Sales",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "bikeOrders",
      headerName: "Bike Order",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "bikeSales",
      headerName: "Bike Sales",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
  ];

  const [bindData, setBindData] = useState([]);

  useEffect(() => {
    // setBindData(
    //   ABPData.map((garage) => {
    //     let address = `${
    //       garage?.shopNumber != null ? garage?.shopNumber : ""
    //     } ${garage?.area != null ? garage?.area : ""} ${
    //       garage?.city != null ? garage?.city : ""
    //     } ${garage?.pincode != null ? garage?.pincode : ""} ${
    //       garage?.landmark != null ? garage?.landmark : ""
    //     }`;
    //     return {
    //       ...garage,
    //       address: `${garage?.shopNumber} ${garage?.area} ${garage?.city} ${garage?.pincode} ${garage?.landmark} `,
    //       address: address,
    //       mechanicList: garage?.mechanicList?.map((mechanic) => mechanic.name),
    //     };
    //   })
    // );

    console.log("ABPData", ABPData);
  }, [ABPData]);

  useEffect(() => {
    getFinancialYearData();
  }, []);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const { handleOpenDatePicker, filteredDate } = commonData;

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  return (
    <div>
      <Table
        // customStyle={style}
        customStyle={DataGridStyle}
        heading="ABP"
        rows={ABPData}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              mechanicList: false,
              payoutBasedOn: false,
            },
          },
        }}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        // onCellClick={(params, event) => {
        //   if (!event.ignore) {
        //     if (params.field != "edit") {
        //       navigate(`/orders?garageId=${params?.row?.garageId}`);
        //     }
        //     if (params.field == "edit") {
        //       navigate(`/editgarageinfo?garageId=${params.row?.garageId}`);
        //     }
        //   }
        // }}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(Number(id));
            }
          }
          // if (visibleItems.length > 0) {
          //   const res = bindData.filter((item) =>
          //     visibleItems.includes(item.id)
          //   );
          //   // getFilteredData([...res]);
          // }
        }}
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        loading={loading}
        // total={totals}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.financialYear}
      />
    </div>
  );
}

export default ABP;
