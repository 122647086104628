 
import testHttp from "../../http-common";

//get Service Categories
export const getServiceCategories = (vehicleTypeId) => {
  return testHttp.get(`/serviceCategory/${vehicleTypeId}`);
};

// get Services by Category Id

export const getServicesByCategoryId = (categoryId) => {
  return testHttp.get(`serviceSubCategory/${categoryId}`);
};

//get Service Sub Categories
export const getServiceSubCategories = (serviceCategoryId) => {
  return testHttp.get(`/serviceSubCategory/${serviceCategoryId}`);
};

//get Service Details
export const getServiceDetails = (serviceCategoryId) => {
  return testHttp.get(`/serviceSubCategory/${serviceCategoryId}`);
};

//get Service Prices
export const getServicePrices = (serviceCategoryId, modelId, variantid) => {
  return testHttp.get(
    `/serviceSubCategory/${serviceCategoryId}/${modelId}/${variantid}`
  );
};
