import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/tables/Table";
import EditGarage from "../../modules/garages/EditGarage";
import { getGarages } from "../../services/garageServices/GarageServices";
//EditGarage";
import { CommonContextAPI } from "../../App";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import AddFranchise from "./AddFranchise";
import {
  franchiseStatus,
  franchiseStatuses,
  getFranchise,
  updateFranchise,
} from "../../services/garageServices/FranchiseServices";
import EditGarageFranchise from "./EditGarageFranchise";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

function GarageFranchise() {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [gp, setGP] = useState(0);
  const [garagePayout, setGaragePayout] = useState(0);
  const [garagesList, setGaragesList] = useState([]);
  const [loading, setLoading] = useState(true);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    handleOpenGarage,
    addFranchise,
    handleAlertOpen,
    colors,
    defaultDate,
  } = commonData;

  const [isFranchiseAdded, setIsFranchiseAdded] = useState(false);

  const navigate = useNavigate();

  const [franchiseList, setFranchiseList] = useState([]);
  const [bindFranchiseList, setBindFranchiseList] = useState([]);
  const [franchiseStatusesList, setFranchiseStatusesList] = useState([]);
  const [count, setCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [filteredTotalAmount, setFilteredTotalAmount] = useState(null);

  const loginDetail = JSON.parse(localStorage.getItem("loginDetails"));
  let config = {
    headers: {
      Authorization: `Bearer ${loginDetail?.jwtToken}`,
    },
  };

  const getGarageFranchise = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    const localConfig = {
      ...config,
      params: {
        from: startDate,
        to: endDate,
      },
    };

    try {
      const response = await getFranchise(localConfig);
      const data = await response.data;
      setFranchiseList(data.result);

      if (response?.status == 204) {
        setFranchiseList([]);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllGarages = async () => {
    try {
      const response = await getGarages();
      const data = await response.data;
      setGaragesList(data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    "& .super-app-theme--header-edit": {
      backgroundColor: "#122164",
      color: "#122164",
    },
  };

  const getFranchiseStatuses = async () => {
    try {
      const response = await franchiseStatuses();
      const data = await response.data;
      setFranchiseStatusesList(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const updateFranchiseStatus = async (params) => {
    const { id, value } = params;

    let appliedStatus = franchiseStatusesList?.find(
      (status) => status?.franchiseStatus == value
    );

    const localConfig = {
      ...config,
      params: {
        franchiseId: id,
      },
    };

    try {
      const response = await updateFranchise(appliedStatus, localConfig);
      const data = await response.data;
      console.log(data);
      handleAlertOpen(data?.message, colors.success);
      getGarageFranchise();
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors.error);
    }
  };

  useEffect(() => {
    getFranchiseStatuses();
  }, []);

  const columns = [
    {
      field: "franchiseId",
      headerName: "Franchise ID",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      // maxWidth: 100,
      flex: 1,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Garage Type",
      headerClassName: "super-app-theme--header",
      minWidth: 110,
    },
    {
      field: "amount",
      headerName: "Franchise Amount",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
    },
    {
      field: "dateOfPayment",
      headerName: "Date Of Payment",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "entityType",
      headerName: "EntityType",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 90,
      maxWidth: 90,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        ...franchiseStatusesList?.map((status) => status?.franchiseStatus),
      ],
      renderCell: (params) => {
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{params.row.status}</span>
            <CircleIcon
              style={{
                color: params.row.status == "Active" ? "#51AE3A" : "#D0342C",
                fontSize: "0.9rem",
                marginLeft: "0.5rem",
              }}
            />
          </div>
        );
      },
      flex: 1,
    },
  ];

  const [garageData, setGarageData] = useState([]);

  useEffect(() => {
    setTotalAmount(0);
    setCount(0);
    let data = franchiseList?.map((franchise) => {
      return {
        franchiseId: franchise?.id,
        garageId: franchise?.garage?.garageId,
        garageName: franchise?.garage?.garageName,
        mobileNumber: franchise?.garage?.mobileNumber,
        type: franchise?.garage?.garageType == 1 ? "Bike" : "Car",
        amount: franchise?.amount,
        dateOfPayment: franchise?.dateOfPayment,
        entityType: franchise?.garage?.entityType,
        paymentMode: franchise?.paymentMode?.mode,
        status: franchise?.franchiseStatus?.franchiseStatus,
      };
    });

    let total = franchiseList?.reduce((acc, item) => acc + item?.amount, 0);
    setTotalAmount(total);
    setCount(franchiseList?.length);
    data && setBindFranchiseList(data);
  }, [franchiseList]);

  useEffect(() => {
    if (filteredDate || defaultDate) {
      getGarageFranchise();
    }
  }, [, isFranchiseAdded, filteredDate, defaultDate]);

  const [filter, setFilter] = useState([]);
  // const getFilteredData = (data) => {
  //   setFilter(data);
  // };

  const totals = (
    <Box className={classes.totalWrapper}>
      <Grid container spacing={!smallPhone && 1}>
        <Grid item xs={12} sm={4}>
          <Box className={classes.gridColumnOdd}>
            <span>Garage Payout :</span>
            <span className={classes.totalCount}>
              {garagePayout && garagePayout.toFixed(2)}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Total Amount :{" "}
              <span className="grid-table-footer-right-value">
                {filteredTotalAmount
                  ? filteredTotalAmount?.toFixed(2)
                  : totalAmount?.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Count :{" "}
              <span className="grid-table-footer-right-value">
                {filteredCount ? filteredCount : count}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  const [passProps, setPassProps] = useState(null);

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <Table
        // customStyle={style}
        customStyle={DataGridStyle}
        heading="Garage Franchise"
        rows={bindFranchiseList}
        initialState={{
          sorting: {
            sortModel: [{ field: "dateOfPayment", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              mechanicList: false,
              payoutBasedOn: false,
            },
          },
        }}
        columns={columns}
        onCellEditCommit={(params) => {
          updateFranchiseStatus(params);
        }}
        pageSize={50}
        rowsPerPageOptions={[100]}
        onStateChange={(state) => {
          setFilteredTotalAmount(null);
          setFilteredCount(null);
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(id);
            }
          }
          const res = bindFranchiseList.filter((item) => {
            return visibleItems.includes(item?.franchiseId);
          });

          let total = res?.reduce((acc, item) => acc + item?.amount, 0);
          setFilteredTotalAmount(total);
          setFilteredCount(res?.length);
        }}
        // downloadXLS={() => downloadXLS(filter.length > 0 ? filter : garageData)}
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        loading={loading}
        // total={totals}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.franchiseId}
      />
      <Box>
        {addFranchise && (
          <AddFranchise setIsFranchiseAdded={setIsFranchiseAdded} />
        )}
      </Box>
    </div>
  );
}

export default GarageFranchise;
