import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import "./MainDashboard.css";
import ComparisionComponant from "../../components/dashboard/Comparision/ComparisionComponant";

import ServiceAndMechanic from "../../components/dashboard/ServiceAndMechanic/ServiceAndMechanic";
import GoalAndReview from "../../components/dashboard/goalAndReview_vehicleSales/GoalAndReview";
import { Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import moment from "moment";
import SalesChart from "../../components/dashboard/salesAndGrossProfit/SalesChart";
import GrossProfitCharts from "../../components/dashboard/salesAndGrossProfit/GrossProfitCharts";
import VehicleTypeSales from "../../components/dashboard/vehicleTypeSales/VehicleTypeSales";
import SarviceSales from "../../components/dashboard/ServiceAndMechanic/SarviceSales";
import ModuleHeader from "../../components/headers/ModuleHeader";
import { CommonContextAPI } from "../../App";

const activeButton = {
  backgroundColor: "#122164",
  color: "white",
};
const inactiveButton = {
  backgroundColor: "transparent",
};

export const DashboardContext = createContext();

export default function Dashboard() {
  const [modal, setmodal] = useState(false);
  const [filterDate, setFilterDate] = useState();
  const [mainFilter, setMainFilter] = useState({
    year: false,
    month: true,
    week: false,
  });

  const commonData = useContext(CommonContextAPI);

  const { handleOpenDatePicker, filteredDate } = commonData;

  const handelDateFilter = () => {
    setmodal(true);
  };

  const handleClose = () => {
    setmodal(false);
  };

  const handelMainFilterButtons = (e) => {
    const result = e.target.textContent;
    if (result === "Year") {
      setMainFilter({ year: true, month: false, week: false });
    } else if (result === "Month") {
      setMainFilter({ year: false, month: true, week: false });
    } else {
      setMainFilter({ year: false, month: false, week: true });
    }
  };

  const filerButtonsObject = {
    handelMainFilterButtons,
    mainFilter,
    activeButton,
    inactiveButton,
  };

  // ----- set Defalut current Month start and end date---------
  useEffect(() => {
    let now = new Date();
    const lastDay = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0
    ).getDate();
    const startDay = lastDay - lastDay + 1;
    let nowM = now.getMonth() + 1;
    let nowY = now.getFullYear();
    const endDate = moment(nowY + "-" + nowM + "-" + lastDay).format(
      "YYYY-MM-DD"
    );
    const startDate = moment(nowY + "-" + nowM + "-" + startDay).format(
      "YYYY-MM-DD"
    );

    setFilterDate({
      startDate,
      endDate,
    });
  }, []);

  // ----- end----------------------

  const contextObject = {
    filterDate,
    setFilterDate,
  };

  //----------- current week ------------------
  let curr = new Date();
  let frist = curr.getDate() - curr.getDay();
  let last = frist + 6;
  let fristdate = new Date(curr.setDate(frist)).toUTCString();
  let lastdate = new Date(curr.setDate(last)).toUTCString();
  //  console.log(fristdate,lastdate)

  //-------------- current month ---------------
  let date = new Date();
  let fristDateM = new Date(date.getFullYear(), date.getMonth(), 1);
  // console.log(new Date(date.getFullYear(), date.getMonth() + 1,0))

  return (
    <DashboardContext.Provider value={contextObject}>
      {/* <div className="dashboardContainer">
        {modal && (
          <Modal
            className="modalContainer"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
          >
            <DateRange
              className="date-range"
              handleClose={handleClose}
              setFilterDate={setFilterDate}
            />
          </Modal>
        )}

        <div className="section-one">
          <div className="main-filter">
            <div className="start-to-end-filter" onClick={handelDateFilter}>
              <CalendarTodayOutlinedIcon />
              <h6 className="date-box">
                {filterDate
                  ? moment(filterDate.startDate).format("D MMMM") +
                    " - " +
                    moment(filterDate.endDate).format("D MMMM")
                  : "loding"}
              </h6>
              <KeyboardArrowDownOutlinedIcon className="main-filter-downArrow" />
            </div>
            <div className="filter-sections">
              <button
                style={mainFilter.year ? activeButton : inactiveButton}
                onClick={(e) => handelMainFilterButtons(e)}
              >
                Year
              </button>
              <button
                style={mainFilter.month ? activeButton : inactiveButton}
                onClick={(e) => handelMainFilterButtons(e)}
              >
                Month
              </button>
              <button
                style={mainFilter.week ? activeButton : inactiveButton}
                onClick={(e) => handelMainFilterButtons(e)}
              >
                Week
              </button>
            </div>
          </div>
        </div>

        <div className="section-two">
          <ComparisionComponant />
        </div>

        <div className="section-three">
          <Charts filterButtons={filerButtonsObject} />
        </div>

        <div className="section-four">
          <GoalAndReview />

          <div className="Review-section">
            <h6 className="goal-header">Vehicle Type Sales</h6>
            <div>
              <VehicleType />
            </div>
          </div>
        </div>

        <div className="section-five">
          <ServiceAndMechanic />
        </div>
      </div> */}

      <div className="dashboard-container">
        <div className="dashboard-filter-section">
          {/* <ModuleHeader /> */}
          <ModuleHeader
            handleOpenDatePicker={handleOpenDatePicker}
            filteredDate={filteredDate}
          />
        </div>
        <div className="dashboard-overview-section">
          <ComparisionComponant />
        </div>
        <div className="dashboard-main-section">
          <div className="dashboard-sales-overview">
            <SalesChart filterButtons={filerButtonsObject} />
          </div>
          <div className="dashboard-grossprofit">
            <GrossProfitCharts filterButtons={filerButtonsObject} />
          </div>
          <div className="dashboard-goal-reviews-section">
            <GoalAndReview />
          </div>
          <div className="dashboard-vehicleType-sales">
            <VehicleTypeSales filterButtons={filerButtonsObject} />
          </div>
          <div className="dashboard-service-sales">
            <SarviceSales />
          </div>
          <div className="dashboard-mechanics">
            <ServiceAndMechanic />
          </div>
        </div>
      </div>
    </DashboardContext.Provider>
  );
}
