import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpIcon from "@mui/icons-material/Help";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Table from "../../../components/tables/Table";
import { GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import { getAllUsers } from "../../../services/userProfileServices/UserProfileServices";
import { CommonContextAPI } from "../../../App";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px !important",
    margin: "0px 0px 1rem !important",
  },
  adminActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "1rem  ",
    },
  },
  notificationIcon: {
    fontSize: "2rem",
  },
  helpIcon: {
    fontSize: "2rem",
  },
  homeIcon: {
    fontSize: "2rem",
  },
  profileIcon: {
    fontSize: "4rem",
  },
  subHeadingWrapper: {
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  subHeading: {
    lineHeight: 1.8,
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const Users = () => {
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);
  const navigate = useNavigate();

  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getUsersList = async () => {
    try {
      const response = await getAllUsers();
      const data = await response.data;
      setUsersList(data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [ksUsers, setKsUsers] = useState([]);

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    usersList?.map((user) => {
      if (user?.role !== "user") {
        const userObj = {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
          userName: user?.userName,
          role: user?.role,
          mobileNumber: user?.mobileNumber,
          gstn: user?.gstn,
          id: user?.id,
          pancard: user?.pancard,
          referralCode: user?.referralCode,
        };

        setKsUsers((prev) => [...prev, userObj]);
      }
    });
  }, [usersList]);

  const {
    handleOpenDatePicker,
    filteredDate,
    setFilteredDate,
  } = commonData;

  const columns = [
    {
      field: "id",
      headerName: "initials",
      headerClassName: "super-app-theme--header-initials",
      minWidth: 60,
      maxWidth: 70,
      flex: 1,
      renderCell: (params) => {
        const fname = params.row.name;
        const lname = params.row.name.slice(params.row.name.indexOf(" ") + 1);
        return (
          <div>
            <Avatar
              sx={{
                cursor: "pointer",
                width: "50px",
                height: "50px",
              }}
            >
              {`${fname[0]} ${lname != null ? lname[0] : ""}`}
            </Avatar>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "userName",
      headerName: "Username",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "pancard",
      headerName: "PAN Number",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
  ];

  const style = {
    "& .super-app-theme--header-initials": {
      backgroundColor: "#122164",
      color: "#122164",
    },
  };

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} className={classes.adminActionContainer}>
        <Grid item sm={12} md={5}>
          <Typography variant="h4" className="titles">
            Users
          </Typography>
        </Grid>
        <Grid item sm={12} md={7}>
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid item sm={12} md={8} style={{ width: "100%" }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                {/* <TextField
                  size="small"
                  label="Users"
                  sx={{ width: "100px" }}
                  select
                  //   variant="outlined"
                  // value={values.country}
                  id="users"
                  margin="dense"
                  name="users"
                  //   value={leadsData.brand && leadsData.brand.name}
                >
                  {userList.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.row}
                      // onClick={() =>
                      //   userSelection("brand", brand.brand, brand.brand_Id)
                      // }
                    >
                      {user.role}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Paper>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="secondary">
                  <NotificationsNoneIcon className={classes.notificationIcon} />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <HomeIcon className={classes.homeIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <AccountBoxIcon className={classes.profileIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {/* <Box className={classes.subHeadingWrapper}>
        <Typography className={classes.subHeading}>
          This section lists the users that have been added to your organization
          account. Click on a user's name to view detailed information about the
          particular user or to change any user-specific settings.{" "}
          <Link href="#">Learn more</Link>
        </Typography>
      </Box> */}
      {/* <Grid container spacing={2} sx={{ padding: "1rem 2rem" }}>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={2}>
              Add
            </Grid>
            <Grid item xs={4} sm={2}>
              Import
            </Grid>
            <Grid item xs={4} sm={2}>
              Invites
            </Grid>
            <Grid item xs={4} sm={2}>
              Export
            </Grid>
            <Grid item xs={4} sm={2}>
              Delete Via CSV
            </Grid>
            <Grid item xs={4} sm={2}>
              Filter
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography>Total Users: 20</Typography>
        </Grid>
      </Grid> */}
      <Box sx={{ mt: 1 }}>
        <Table
          // heading="Balance Summary"
          rows={ksUsers}
          columns={columns}
          customStyle={style}
          pageSize={50}
          rowsPerPageOptions={[100]}
          // downloadXLS={() => downloadXLS(filter.length > 0 ? filter : rows)}
          components={{ Toolbar: QuickSearchToolbar }}
          handleOpenDatePicker={handleOpenDatePicker}
          onCellClick={(params, event) => {
            // navigate("/adminConsole/users/editUser", {
            //   state: params.row.id,
            // });

            navigate(`/adminConsole/users/editUser/${params.row.id}`);
            // if (!event.ignore) {
            //   navigate(`/adminConsole/users/editUser/${params.row.id}`);
            // }
          }}
          filteredDate={filteredDate}
          setFilteredDate={setFilteredDate}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Users;
