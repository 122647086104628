import React, { useContext } from "react";
import InsuranceTable from "../InsuranceTable";
import "./Estimate.css";
import { jobCardContext } from "../../OrderDetails";

import "../InsuranceTable.css";

export default function Estimate() {
  const context = useContext(jobCardContext);

  const { OrderInfo, estimateDiscount } = context;

  return (
    <>
      <InsuranceTable
        orderDetails={OrderInfo && OrderInfo}
        Discount={estimateDiscount}
        header="Estimate"
      />
    </>
  );
}
