import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import "../../modules/leads/AddLead.css";
import { CommonContextAPI } from "../../App";
import { Card, CardContent } from "@mui/material";
import { ArrivalModeContext } from "./LeadDetails";
import axios from "axios";
import httpCommon from "../../http-common";
const URL = httpCommon.defaults.baseURL;





const locations = [
  {
    id: 1,
    location: "Pune",
  },
  {
    id: 2,
    location: "Bangaluru",
  },
  {
    id: 3,
    location: "Mumbai",
  },
  {
    id: 4,
    location: "Navi Mumbai",
  },
];

const LocationsModal = ({ propsData }) => {


  const [uniqueCities, setUniqueCities] = useState([]);


useEffect(() => {
  axios.get(`${URL}/city/unique`)
    .then(response => {
      const citiesArray = response.data.result;
      
      // Exclude "Bangalore" from the list
      const filteredCities = citiesArray.filter(city => city.city !== "Bangalore");

      setUniqueCities(filteredCities);
    })
    .catch(error => {
      console.error('Error fetching unique cities:', error);
    });
}, []);





  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [pickupDetails, setPickupDetails] = useState({});

  const {
    openArrivalModal,
    closeArrivalModal,
    openLocationModal,
    closeLocationModal,
    openReasonsModal,
    closeReasonsModal,
    openGaragesModal,
    closeGaragesModal,
  } = propsData;

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);
  const arrivalModeContextData = useContext(ArrivalModeContext);
  const {
    setArrivalModeDetails,
    arrivalModeDetails,
    leadStatusDetails,
    setLeadStatusDetails,
    openModalOf,
  } = arrivalModeContextData;

  const userSelection = (field, value) => {
    if (openModalOf == "status") {
      setLeadStatusDetails({
        ...leadStatusDetails,
        [field]: value?.city,
      });
    } else {
      setArrivalModeDetails({ 
        ...arrivalModeDetails,
        [field]: value?.city,
      });
    }

    closeLocationModal();
  };

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: isMobile ? "80%" : 615,
    height: "auto",
    maxHeight: "100%",
    border: "none !important",
    overflow: "auto !important",
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeLocationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" className="leadsTitle">
            Select Location
          </Typography>
          <Divider />
          <Box
            style={{
              position: "absolute",
              top: 1,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeLocationModal}
            />
          </Box>
          <Box sx={{ marginTop: "4px" }}>
            {uniqueCities?.map((city) => {
              return (
                <Box sx={{ marginTop: "1rem" }} key={city.id}>
                  <Card
                    sx={{
                      minWidth: 275,
                      "& .MuiCardContent-root": {
                        padding: "3px 10px",
                        marginBottom: "0px",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      userSelection("location", city);
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 16 }}
                        color="#000"
                        gutterbottom
                      >
                        {city?.city}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default LocationsModal;