import React, { useState, useMemo, useContext } from "react";
import Table from "../../components/tables/Table";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const rows = [
  {
    id: 1,
    name: "AAAAAA",
    referral: 9876543456,
    referee: 8976543456,
    date: "2022-08-25",
    converted: "Converted",
    referralAmount: 10,
    vehicleType: "Bike",
  },
  {
    id: 2,
    name: "BBBCCC",
    referral: 9876543456,
    referee: 8976543456,
    date: "2022-07-26",
    converted: "Closed",
    referralAmount: 14,
    vehicleType: "Car",
  },
  {
    id: 3,
    name: "CCCDDDSSS",
    referral: 9876543456,
    referee: 8976543456,
    date: "2022-09-27",
    converted: "Approved",
    referralAmount: 20,
    vehicleType: "Bike",
  },
];

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

function Referal() {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [referralAmount, setReferralAmount] = useState(0);
  const [filteredReferralAmount, setFilteredReferralAmount] = useState(0);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    applyFilter,
  } = commonData;

  useMemo(() => {
    rows.map((row, i) => {
      setReferralAmount((prev) => prev + row.referralAmount);
    });
  }, []);

  const [filter, setFilter] = useState([]);
  const getFilteredData = (data) => {
    setFilter(data);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "referral",
      headerName: "Referral",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "referee",
      headerName: "Referee",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "converted",
      headerName: "Converted",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      filterable: true,
      editable: true,
      flex: 1,
    },
    {
      field: "referralAmount",
      headerName: "Referral Amount",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
  ];
  const totals = (
    <Box className={classes.totalWrapper}>
      <Grid container spacing={!smallPhone && 1}>
        <Grid item xs={12} sm={3}>
          <Box className={classes.gridColumnOdd}>
            <span>Referal Amount:</span>
            <span className={classes.totalCount}>
              {filteredReferralAmount && filteredReferralAmount.toFixed(2)}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const [input, setInput] = useState([]);
  const handleClick = () => {
    setInput([
      {
        columnField: "date",
        operatorValue: "between",
        value: ["2022-06-26", "2022-06-26"],
      },
    ]);
  };

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("date");
  }, [filteredDate && filteredDate]);
  return (
    <div>
      <Table
        handleClick={handleClick}
        heading="Referal"
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(Number(id));
            }
          }
          const res = rows.filter((item) => visibleItems.includes(item.id));
          const referralprice = res
            .map((item) => item.referralAmount)
            .reduce((a, b) => a + b, 0);
          setFilteredReferralAmount(
            referralprice > 0 ? referralprice : referralAmount
          );
        }}
        filterModel={filterModel && filterModel}
        total={totals}
        downloadXLS={() => downloadXLS(filter.length > 0 ? filter : rows)}
        components={{ Toolbar: QuickSearchToolbar }}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        applyFilter={applyFilter && applyFilter}
      />
    </div>
  );
}

export default Referal;
