const constant={
    GST_RATE:18,
    CGST_RATE: 9,
    SGST_RATE: 9,
    MONTHLY_TARGET: 800000,
}
export const AppConstants = (state=constant, action) => { 
    switch (action.type) {
         case 'getAppConstants':
              return state = action.payload
              
         default:
              return state
    }
}