import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

const borderColor = "#6a7086";

const styles = StyleSheet.create({
  service: {
    width: "18%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    paddingLeft: "5px",
  },
  hsn: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  price: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  discount: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  row_taxableAmount: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "8px",
    height: "100%",
    textAlign: "center",
    paddingVertical: "5px",
  },
  row_gstSec: {
    flexDirection: "row",
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  row_cgst_Rate: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    width: "40%",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
  row_cgst_Amount: {
    width: "60%",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
  total: {
    width: "13%",
    fontSize: "8px",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
  cell: {
    borderColor: borderColor,
    borderWidth: 1,
    width: "40%",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
});
export default function TableRow({ services }) {
  return (
    <>
      {services?.map((service, index) => {
        const cgstRate = service.gstRate / 2;
        const sgstRate = service.gstRate / 2;
        return (
          <View
            style={{
              flexDirection: "row",
              borderTop: "0.5px solid #000",
              borderBottom: "0.5px solid #000",
            }}
            key={index}
            wrap={false}
          >
            <Text style={styles.service}>{service.service}</Text>
            <Text style={styles.hsn}>{service.hsn}</Text>
            <Text style={styles.qty}>{service.qty}</Text>
            <Text style={styles.price}>{service?.price?.toFixed(2)}</Text>
            <Text style={styles.discount}>{service?.discount?.toFixed(2)}</Text>
            <Text style={styles.row_taxableAmount}>
              {service?.taxableAmount?.toFixed(2)}
            </Text>
            <View style={styles.row_gstSec}>
              <Text style={styles.row_cgst_Rate}>{cgstRate}</Text>
              <Text style={styles.row_cgst_Amount}>
                {service?.gstAmount?.toFixed(2)}
              </Text>
            </View>
            <View style={styles.row_gstSec}>
              <Text style={styles.row_cgst_Rate}>{sgstRate}</Text>
              <Text style={styles.row_cgst_Amount}>
                {service?.gstAmount?.toFixed(2)}
              </Text>
            </View>
            <Text style={styles.total}>{service.total.toFixed(2)}</Text>
          </View>
        );
      })}
    </>
  );
}
