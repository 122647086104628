import testHttp from "../../http-common";

//get Order status
export const getOrdersStatus = () => {
  return testHttp.get("/orderStatus");
};

export const getServicesJobCard = (
  vehicleId,
  serviceCategoryId,
  modelId,
  variantId
) => {
  return testHttp.get(
    `/services/${vehicleId}/${serviceCategoryId}/${modelId}/${variantId}`
  );
};

// get orders
export const getOrders = (config) => {
  // return testHttp.get("/orders");
  return testHttp.get("/orders", config);
  // return testHttp.get("/orders/testing", config);
  // return testHttp.get("/orders/testing");
};

// update orders
export const updateOrders = (updateOrder) => {
  return testHttp.put("/orders", updateOrder);
};

// Deler Service from Order

export const DeleteOrderService = (serviceId) => {
  return testHttp.delete(`/orders/delete-service/${serviceId}`);
};

export const DeleteOrderNonCatalogue = (NonCatId) => {
  return testHttp.delete(`/orders/non-catalogue-delete/${NonCatId}`);
};

export const deleteNonCatalogueEstimate = (id) => {
  return testHttp.delete(`/nonCatalogueServices/delete/${id}`);
};

export const getGarageOrders = (config, garageId) => {
  return testHttp.testHttp(`/garage/orders/${garageId}`, config);
};

export const getCustomerInvoice = (orderId) => {
  return testHttp.get(`/invoice?id=${orderId}`);
};

export const getAllOrderTypes = () => {
  return testHttp.get("/orderTypes");
};

export const getDeadOrderReasons = () => {
  return testHttp.get("/deadReasons");
};

export const searchOrders = (query, cancelRequest) => {
  return testHttp.get(`/orders/search?query=${query}`, cancelRequest);
};

export const getOrdersByMobileNumber = (config, number) => {
  return testHttp.get(`/orders/ordersByMobileNumber/${number}`, config);
};
