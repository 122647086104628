import React, { useState,  useEffect } from "react";
import InsuranceTable from "../InsuranceTable";

import "../InsuranceTable.css";

import { getCustomerInvoice } from "../../../../services/orderServices/OrderServices";
import { useParams } from "react-router-dom";

export default function InsuranceInvoice() {
  const [insuranceInvoiceDetails, setInsuranceInvoiceDetails] = useState({});

  const { orderId } = useParams();

  // Get Insurance Invoice Details

  const getInsuranceInvoiceDetails = async () => {
    try {
      const response = await getCustomerInvoice(orderId);
      const data = await response.data;
      setInsuranceInvoiceDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInsuranceInvoiceDetails();
  }, []);

  return (
    <div>
      <InsuranceTable
        orderDetails={insuranceInvoiceDetails}
        header="TAX INVOICE"
      />
    </div>
  );
}
