// import httpCommon from "httpCommon";
// const baseUURL = "http://10.10.2.32:5758/api/customerIssueTracker";
import httpCommon from "../../http-common";

// export const getCustomerIssues = () => {
//     return httpCommon.get(`${baseUURL}/customerIssue`);
// }

export const getCustomerIssueList = () => {
  return httpCommon.get("/customerIssueTracker/?all=true");
};

export const postNewIssue = (issueDetails) => {
  return httpCommon.post("/customerIssueTracker/save", issueDetails);
};

export const getIssueByTicketNo = (ticketNo) => {
  return httpCommon.get(`/customerIssueTracker/issuebyid/${ticketNo}`);
};

export const updateCustomerIssue = (issueDetails) => {
  return httpCommon.put("/customerIssueTracker", issueDetails);
};

export const getIssues = () => {
  return httpCommon.get("/customerIssueTracker/customerIssue");
};

export const getIssueStatuses = () => {
  // return httpCommon.get(`${baseUURL}/customerIssueStatus`);
  return httpCommon.get("/customerIssueTracker/customerIssueStatus");
};
export const getIssueResolution = () => {
  return httpCommon.get("/customerIssueTracker/resolution");
};
export const getRootCause = () => {
  return httpCommon.get("/customerIssueTracker/rootCause");
};
