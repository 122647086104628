import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/tables/Table";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getGarages } from "../../services/garageServices/GarageServices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import EditGarage from "../../modules/garages/EditGarage";
//EditGarage";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

function GarageDatabase({ isGarageAdded }) {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [gp, setGP] = useState(0);
  const [garagePayout, setGaragePayout] = useState(0);
  const [garagesList, setGaragesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditGarage, setOpenEditGarage] = useState(false);
  const [isGarageEdited, setIsGarageEdited] = useState(false);
  const navigate = useNavigate();

  const getAllGarages = async () => {
    try {
      const response = await getGarages();
      const data = await response.data;
      setGaragesList(data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    "& .super-app-theme--header-edit": {
      backgroundColor: "#122164",
      color: "#122164",
    },
  };

  const openEditGarageModel = () => {
    setOpenEditGarage(true);
  };
  const closeEditGarageModel = () => {
    setOpenEditGarage(false);
  };

  const columns = [
    {
      field: "garageId",
      headerName: "Garage ID",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Garage Type",
      headerClassName: "super-app-theme--header",
      minWidth: 110,
    },
    {
      field: "gstn",
      headerName: "GSTN",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "pan",
      headerName: "PAN",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "entityType",
      headerName: "EntityType",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
    {
      field: "mechanicList",
      headerName: "Mechanic List",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "payoutBasedOn",
      headerName: "Payout Based On",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "payoutPercentage",
      headerName: "Garage Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted} %`;
      },
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            onClick={openEditGarageModel}
          >
            <EditIcon />
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      // editable: true,
      type: "singleSelect",
      valueOptions: [...["Active", "Inactive"].map((status) => status)],
      renderCell: (params) => {
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              // justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{params.row.status || "Inactive"}</span>
            <CircleIcon
              style={{
                color: params.row.status == "Active" ? "#51AE3A" : "#D0342C",
                fontSize: "0.9rem",
                marginLeft: "0.5rem",
              }}
            />
          </div>
        );
      },
      flex: 1,
    },
  ];

  const [garageData, setGarageData] = useState([]);
  useEffect(() => {
    setGarageData(
      garagesList.map((garage) => {
        let address = `${
          garage?.shopNumber != null ? garage?.shopNumber : ""
        } ${garage?.area != null ? garage?.area : ""} ${
          garage?.city != null ? garage?.city : ""
        } ${garage?.pincode != null ? garage?.pincode : ""} ${
          garage?.landmark != null ? garage?.landmark : ""
        }`;
        return {
          ...garage,
          address: `${garage?.shopNumber} ${garage?.area} ${garage?.city} ${garage?.pincode} ${garage?.landmark} `,
          address: address,
          mechanicList: garage.mechanicList.map((mechanic) => mechanic.name),
        };
      })
    );
  }, [garagesList]);

  useEffect(() => {
    getAllGarages();

    // rows.map((item) => {
    //   setGP((prev) => prev + item.garagePayout);
    // });
  }, [, isGarageAdded, isGarageEdited]);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const { downloadXLS, handleOpenDatePicker, filteredDate, handleOpenGarage } =
    commonData;

  const totals = (
    <Box className={classes.totalWrapper}>
      <Grid container spacing={!smallPhone && 1}>
        <Grid item xs={12} sm={4}>
          <Box className={classes.gridColumnOdd}>
            <span>Garage Payout :</span>
            <span className={classes.totalCount}>
              {garagePayout && garagePayout.toFixed(2)}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const [passProps, setPassProps] = useState(null);

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <Table
        // customStyle={style}
        customStyle={DataGridStyle}
        heading="Garage Database"
        rows={garageData}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              mechanicList: false,
              payoutBasedOn: false,
            },
          },
        }}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        onCellClick={(params, event) => {
          if (!event.ignore) {
            if (params.field != "edit") {
              navigate(`/orders?garageId=${params?.row?.garageId}`);
            }
            if (params.field == "edit") {
              navigate(`/editgarageinfo?garageId=${params.row?.garageId}`);
            }
          }
        }}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(Number(id));
            }
          }
          if (visibleItems.length > 0) {
            const res = garageData.filter((item) =>
              visibleItems.includes(item.id)
            );
            // getFilteredData([...res]);
          }
        }}
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        loading={loading}
        // total={totals}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.garageId}
      />

      {openEditGarage && (
        <EditGarage
          setIsGarageEdited={setIsGarageEdited}
          closeEditGarageModel={closeEditGarageModel}
          passProps={passProps}
        />
      )}
    </div>
  );
}

export default GarageDatabase;
