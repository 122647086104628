import React, { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import DatePicker from "react-datepicker";
import { CommonContextAPI } from "../../App";
import "./Form.css";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    padding: theme.spacing(0, 0.5),
  },
}));

const Form = ({
  heading,
  closeModal,
  isAdded,
  renderForm,
  service,
  state,
  setState,
  submit,
  discard,
  navigate,
  isEdit,
  modalHeight,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useState({});
  const classes = useStyles();
  const Navigate = useNavigate();
  // GET CONTEXT COMMON DATA
  const { handleCloseGarage, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  useEffect(() => {
    state && setFormData({ ...formData, ...state });
  }, [state]);

  useEffect(() => {
    setState && setState({ ...state });
  }, [setState]);

  const handleChange = (e, field) => {
    let { name, value } = e.target;
    const caps = ["gstn", "pan"];
    setState
      ? setState({
          ...state,
          [name]: field?.sendVal
            ? caps.includes(name)
              ? value[field?.properties?.name]?.toUpperCase()
              : field?.isPaymentMode
              ? value
              : value[field?.properties?.name]
            : field?.sendId
            ? value[field?.properties?.id]
            : field?.type == "number"
            ? Number(value)
            : caps.includes(name)
            ? value?.toUpperCase()
            : value,
          [field?.postId]: Number(value?.id),
        })
      : setFormData({
          ...formData,
          [name]: field?.sendVal
            ? caps.includes(name)
              ? value[field?.properties?.name]?.toUpperCase()
              : value[field?.properties?.name]
            : field?.sendId
            ? value[field?.properties?.id]
            : field?.type == "number"
            ? Number(value)
            : value?.toUpperCase(),
          [field?.postId]: Number(value?.id),
        });
  };

  const handleAutocomplete = (e, value, field) => {
    // const values = value?.split(",");

    setState
      ? setState({
          ...state,
          [field?.name]: value[field?.properties?.name],
          [field?.postId]: Number(value[field?.properties?.id]),
        })
      : setFormData({
          ...formData,
          [field?.name]: value[field?.properties?.name],
          [field?.postId]: Number(value[field?.properties?.id]),
        });

    // setState
    //   ? setState({
    //       ...state,
    //       [field?.name]: field?.sendVal
    //         ? value[field?.properties?.name]
    //         : field?.sendId
    //         ? value[field?.properties?.id]
    //         : values[0],
    //       [field?.postId]: Number(values[1]),
    //     })
    //   : setFormData({
    //       ...formData,
    //       [field?.name]: field?.sendVal
    //         ? value[field?.properties?.name]
    //         : field?.sendId
    //         ? value[field?.properties?.id]
    //         : values[0],
    //       [field?.postId]: Number(values[1]),
    //     });
  };

  const userSelection = (fieldName, name, id) => {
    const obj = {
      name,
      id,
    };
    setState({ ...state, [fieldName]: obj });
  };

  const localData = JSON.parse(localStorage.getItem("loginDetails"));
  const config = {
    headers: {
      Authorization: `Bearer ${localData?.jwtToken}`,
    },
  };
  const postData = async (postObj) => {
    try {
      const response = await service(postObj, config);
      const data = await response.data;
      isAdded(true);
      navigate && Navigate(navigate);
      closeModal();
      handleAlertOpen(data?.message, colors?.success);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors?.error);
      console.log(error);
    }
  };

  const handleSubmit = () => {
    delete formData?.undefined;
    submit ? submit() : service && postData(formData);
  };

  const discardDetails = () => {
    setFormData({
      hsn: "",
      hsnItemName: "",
      description: "",
      brand: "",
      grade: "",
      materialPrice: "",
      gstRate: "",
    });
  };

  const textFieldElement = (field) => {
    return (
      <Grid item xs={field?.grids} className={classes.gridItem}>
        <TextField
          size="small"
          fullWidth
          margin="dense"
          variant="outlined"
          type={field.type}
          label={!isEdit && !formData[field?.name] && field?.label}
          id={field?.id}
          onInput={
            field?.name == "mobileNumber" &&
            ((e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 10);
            })
          }
          name={field?.name}
          onChange={field?.change || ((e) => handleChange(e, field))}
          value={formData[field?.name]}
        />
      </Grid>
    );
  };

  const dropdownElement = (field) => {
    return (
      <Grid item xs={field.grids} className={classes.gridItem}>
        {field?.render ? (
          field?.render()
        ) : (
          <TextField
            size="small"
            label={field?.label}
            fullWidth
            select
            variant="outlined"
            id={field?.id}
            margin="dense"
            name={field?.name}
            onChange={(e) => handleChange(e, field)}
            value={
              formData?.[field?.name] && formData?.[field?.name]?.[field?.name]
            }
          >
            {field?.data?.map((item) => (
              <MenuItem key={item[field?.properties?.id]} value={item}>
                {item[field?.properties?.name]}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
    );
  };

  const calenderField = (field) => {
    return (
      <Grid item xs={field?.grids} className={classes.gridItem}>
        {field?.render
          ? field?.render()
          : {
              /* <Box sx={field?.style}>
          <DatePicker
            style={{
              padding: "10px !important",
              border: "2px solid gray !important",
              "& .react-datepicker__input-container": {
                backgroundColor: "red",
              },
            }}
            name={field?.name}
            id={field?.id}
            onFocus={() => field?.props?.setHideLabel(true)}
            onBlur={() => field?.props?.setHideLabel(false)}
            selected={formData?.[field?.name] || new Date()}
            onChange={(e) => {
              handleChange(e, field);
              field?.props?.setHideLabel(false);
            }}
            isClearable
            placeholderText="I have been cleared!"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </Box> */
            }}
      </Grid>
    );
  };

  const autocompleteField = (field) => {
    return (
      <Grid item xs={field?.grids} className={classes.gridItem}>
        <Autocomplete
          // style={{ marginTop: 20, marginBottom: 5 }}
          style={{ marginTop: 8, marginBottom: 5 }}
          size="small"
          freeSolo
          id={field?.id}
          name={field?.name}
          disableClearable
          // options={field?.data?.map(
          //   (option) =>
          //     `${option?.[field?.properties?.name]},${
          //       option?.[field?.properties?.id]
          //     }`
          // )}
          onChange={
            field?.change || ((e, value) => handleAutocomplete(e, value, field))
          }
          value={formData[field?.name] || state[field?.name]}
          options={field?.data}
          getOptionLabel={(option) => option[field?.properties?.name]}
          renderOption={(props, option) => (
            <li {...props}>{option[field?.properties?.name]}</li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field?.props?.hideLabel ? "" : "Search Vendor"}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const dividerElement = () => {
    return <Divider />;
  };

  const labelElement = (field) => {
    return (
      <Grid item xs={field?.grids} className={classes.gridItem}>
        <Typography className="lables">{field?.label}</Typography>
      </Grid>
    );
  };

  const buttonElement = (field) => {
    return (
      <Button sx={field?.buttonStyle} type="button" variant="contained">
        {field?.label}
      </Button>
    );
  };

  const vehicleTypeToggle = (field) => {
    return (
      <Grid item sm={field?.grids} className={classes.gridItem}>
        {field?.render ? (
          field?.render()
        ) : (
          <ul className="donate-now">
            {field?.data &&
              field?.data.map((item, index) => {
                return (
                  <Box key={index}>
                    <li>
                      <input
                        type="radio"
                        id={item?.[field?.properties?.id]}
                        name={item?.[field?.properties?.name]}
                        value={item?.[field?.properties?.id]}
                        onClick={() =>
                          userSelection(
                            field?.name,
                            item?.[field?.properties?.name],
                            item?.[field?.properties?.id]
                          )
                        }
                      />
                      <label for={item?.[field?.properties?.name]}>
                        {item?.[field?.properties?.name]}
                      </label>
                    </li>
                  </Box>
                );
              })}
          </ul>
        )}
      </Grid>
    );
  };

  const renderSelectDropdown = (field) => {
    return (
      <Grid item xs={field.grids} className={classes.gridItem}>
        {
          <select
            name={field?.name}
            onChange={(e) => handleChange(e, field)}
            className="form-select-dropdown"
            style={{ marginTop: "10px !important" }}
          >
            <option
              value={
                typeof formData?.[field?.name] != "object"
                  ? formData?.[field?.name]
                  : formData?.[field?.name] &&
                    formData?.[field?.name]?.[field?.name]
              }
            >
              {typeof formData?.[field?.name] != "object"
                ? formData?.[field?.name]
                : formData?.[field?.name] &&
                  formData?.[field?.name]?.[field?.name]}
            </option>
            {field?.data?.map((item) => {
              return (
                <option
                  key={item[field?.properties?.id]}
                  value={
                    field?.isPaymentMode ? item[field?.properties?.name] : item
                  }
                >
                  {item[field?.properties?.name]}
                </option>
              );
            })}
          </select>
        }
      </Grid>
    );
  };

  const inputFormGroup = (fields) => {
    return (
      <>
        {fields?.divider ? (
          <Divider />
        ) : fields?.tableList ? (
          <Grid container spacing={1} className="gridRow">
            {fields?.fields?.map((field) => {
              return field?.field == "Label"
                ? labelElement(field)
                : field?.field == "Autocomplete"
                ? autocompleteField(field)
                : textFieldElement(field);
            })}
          </Grid>
        ) : fields?.button ? (
          <Box sx={fields?.style} onClick={fields?.add}>
            {buttonElement(fields)}
          </Box>
        ) : fields?.total ? (
          <Box sx={fields?.style}>
            <Typography>{fields?.label}</Typography>
          </Box>
        ) : fields?.renderTable ? (
          fields?.render()
        ) : fields?.addItems ? (
          fields?.render()
        ) : (
          <Grid container className="gridRow" spacing={1}>
            <Grid item xs={4}>
              <Typography className="lables">{fields.label}</Typography>
            </Grid>
            <Grid item xs={8} className="gridRow" spacing={1}>
              <Grid container className={classes.gridItem}>
                {fields?.fields?.map((field) => {
                  return field?.field == "TextField"
                    ? textFieldElement(field)
                    : field?.field == "Dropdown"
                    ? dropdownElement(field)
                    : field?.field == "Calender"
                    ? calenderField(field)
                    : field?.field == "Autocomplete"
                    ? autocompleteField(field)
                    : field?.field == "Label"
                    ? labelElement(field)
                    : field?.field == "Toggles"
                    ? vehicleTypeToggle(field)
                    : field?.field == "SelectDropdown"
                    ? renderSelectDropdown(field)
                    : dividerElement(field);
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 2,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: 600,
    height: "auto",
    maxHeight: modalHeight ? modalHeight : "100%",
    border: "none !important",
    overflow: "auto",
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography className="leadsTitle">{heading}</Typography>
          <Divider />
          <Box
            style={{
              position: "absolute",
              top: 0,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon style={{ cursor: "pointer" }} onClick={closeModal} />
          </Box>
          {renderForm?.map((fields, index) => {
            return inputFormGroup(fields);
          })}

          <Grid container spacing={1} className="gridRow">
            <Grid item xs={2} sm={4}></Grid>
            <Grid item xs={10} sm={6}>
              <div className="buttonGroup">
                <Button
                  variant="contained"
                  size="small"
                  className="buttons"
                  onClick={discard}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="buttons"
                  style={{ marginLeft: "1rem" }}
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default Form;
