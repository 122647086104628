import http from "../../http-common";

export const getComparisions = (startDate, endDate, config) => {
  return http.get(
    `/analytics/overview?from=${startDate}&to=${endDate}`,
    config
  );
};

export const getOrdersalses = (config) => {
  return http.get("/analytics/summary/orders", config);
};

export const getGrossProfit = (config) => {
  return http.get("/analytics/summary/gross-profit", config);
};

export const getServicesSales = (config) => {
  return http.get("/analytics/servicesSales", config);
};

export const getServiceAndMechanic =(config)=> {
  return http.get("/analytics/garageSales", config);
};  

export const getOrders =(config)=> {
  return http.get("/analytics/orders/orderCount", config);
};  

export const getSaWiseSales =(config)=> {
  return http.get("/analytics/serviceAdvisorSales", config);
};  

