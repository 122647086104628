import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import TableFooter from "./TableFooter";

const borderColor = "#6a7086";

const styles = StyleSheet.create({
  service: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    paddingLeft: "5px",
  },
  hsn: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  price: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  discount: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "8px",
    height: "100%",
    paddingVertical: "5px",
    textAlign: "center",
  },
  row_taxableAmount: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    fontSize: "8px",
    height: "100%",
    textAlign: "center",
    paddingVertical: "5px",
  },
  row_gstSec: {
    flexDirection: "row",
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
  },
  row_cgst_Rate: {
    borderRightColor: borderColor,
    borderRightWidth: "0.5px",
    width: "40%",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
  row_cgst_Amount: {
    width: "60%",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
  total: {
    width: "9%",
    fontSize: "8px",
    textAlign: "center",
    paddingVertical: "5px",
    fontSize: "8px",
  },
});
export default function TableRow({ data }) {
  const quantity = data?.map((i) => i.qty).reduce((a, b) => a + b, 0);
  const price = data?.map((i) => i.price).reduce((a, b) => a + b, 0);
  const discount = data?.map((i) => i.discount).reduce((a, b) => a + b, 0);
  const taxableAmount = data
    ?.map((i) => i.taxableAmount)
    .reduce((a, b) => a + b, 0);
  const total = data?.map((i) => i.total).reduce((a, b) => a + b, 0);

  const FooterData = { quantity, price, discount, taxableAmount, total };
  return (
    <>
      {data?.map((Obj, ind) => {
        const {
          discount,
          gstAmount,
          gstRate,
          hsn,
          price,
          qty,
          service,
          taxableAmount,
          total,
        } = Obj;

        return (
          <View
            style={{
              flexDirection: "row",
              borderTopWidth: "0.5px",
              borderBottomWidth: "0.5px",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              borderColor: borderColor,
            }}
            key={ind}
            wrap={false}
          >
            <Text style={styles.service}>{service}</Text>
            <Text style={styles.hsn}>{hsn}</Text>
            <Text style={styles.qty}>{qty}</Text>
            <Text style={styles.price}>{price}</Text>
            <Text style={styles.discount}>{discount ? discount : 0}</Text>
            <Text style={styles.row_taxableAmount}>
              {taxableAmount.toFixed(2)}
            </Text>
            <View style={styles.row_gstSec}>
              <Text style={styles.row_cgst_Rate}>{gstRate / 2}</Text>
              <Text style={styles.row_cgst_Amount}>{gstAmount.toFixed(2)}</Text>
            </View>
            <View style={styles.row_gstSec}>
              <Text style={styles.row_cgst_Rate}>{gstRate / 2}</Text>
              <Text style={styles.row_cgst_Amount}>{gstAmount.toFixed(2)}</Text>
            </View>
            <Text style={styles.total}>{total}</Text>
          </View>
        );
      })}
    </>
  );
}
