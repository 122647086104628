import axios from "axios";
export default axios.create({
  // baseURL:"http://ec2-13-127-92-134.ap-south-1.compute.amazonaws.com:8282/api",

  // Production
  baseURL: "https://testapi.gativan.in/api",

  // Development
  // baseURL: "https://devapi.gativan.in/api",

  // Local
  // baseURL: "http://10.10.3.52:5758/api",
  // baseURL: "http://10.10.2.32:5758/api",

  headers: {
    "Content-Type": "application/json",
  },
});
