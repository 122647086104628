import React from "react";
import "./LoaderSpinner.css";

export default function LoaderSpinner() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="loading-spinner"></div>
    </div>
  );
}
