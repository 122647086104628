import React, { useContext, useEffect, useMemo, useState } from "react";
import Table from "../../components/tables/Table";

import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { CommonContextAPI } from "../../App";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import {
  getCustomerIssueList
} from "../../services/customerServices/CustomerServices";
import CustomerServiceGridView from "./CustomerServiceGridView";

import AddIssue from "./AddIssue";
import IssueDetails from "./IssueDetails";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#000",
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const customerOwner = ["Atul", "Ravi", "Ashutosh", "Krishna", "Nitesh"];

const rows = [
  {
    id: 1,
    orderId: "O-CAPN-2205-001",
    customerName: "Prashant Kotak",
    customerIssue: "Work done but few work pending, follow-up not proper",
    status: "Close",
    vehicle: "Car",
    brandModel: "Hyundai i10",
    createdDate: "2022-05-13",
    closedDate: "--",
    customerOwner: "Ashutosh",
    remarks: "Not Satisfied",
  },
  {
    id: 2,
    orderId: "O-CAPN-2205-002",
    customerName: "Ajit Lande",
    customerIssue:
      " AC feel work done but customer  not satisfied due to Effect it was not ok  and  Meccaniche answer is want to replace compressor",
    status: "Close",
    vehicle: "Car",
    brandModel: "Tata Tiago",
    createdDate: "2022-05-07",
    closedDate: "--",
    customerOwner: "Ashutosh",
    remarks: "Not Satisfied",
  },
  {
    id: 3,
    orderId: "O-BIPN-2205-003",
    customerName: "Pankaj Kanse",
    customerIssue:
      "Customer dissatisfied due to before work not given approval and also not sending oil change video on WhatsApp",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Honda CB Shine",
    createdDate: "2022-05-14",
    closedDate: "--",
    customerOwner: "Atul",
    remarks: "Not Satisfied",
  },
  {
    id: 4,
    orderId: "O-BIPN-2205-004",
    customerName: "Abhishekh  Singh",
    customerIssue:
      "Customer satisfide with the servicing but dissatisfide due to washing  not done properly",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Hero Karizma",
    createdDate: "2022-05-14",
    closedDate: "--",
    customerOwner: "Atul",
    remarks: "Not Satisfied",
  },
  {
    id: 5,
    orderId: "O-BIPN-2205-005",
    customerName: "Soumaba Bosh",
    customerIssue:
      "Customer dissatisfide due to after last servicing petrol tank was licage",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Bajaj Pulsar 150",
    createdDate: "2022-05-13",
    closedDate: "--",
    customerOwner: "Atul",
    remarks: "Not Satisfied",
  },
  {
    id: 6,
    orderId: "O-BIPN-2205-006",
    customerName: "Aaditya Bompilwar",
    customerIssue:
      "customer highly dissatisfide due to washing not done properly and also fitment not done properly ",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Bajaj Discover",
    createdDate: "2022-05-13",
    closedDate: "--",
    customerOwner: "Atul",
    remarks: "Not Satisfied",
  },
  {
    id: 7,
    orderId: "O-BIPN-2205-007",
    customerName: "Bhad Sagar",
    customerIssue:
      "Custemer disatisfide due to after service noise coming from chain …..and also he have xsent",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Bajaj Pulsar NS 160",
    createdDate: "2022-05-07",
    closedDate: "--",
    customerOwner: "Atul",
    remarks: "Not Satisfied",
  },
  {
    id: 8,
    orderId: "O-CAPN-2205-008",
    customerName: "Nirav Mistry",
    customerIssue:
      "Customer dissatisfide due to Meccaniche miss the replace oil filter after cutomer remide to meccaniche  then he will replace",
    status: "Close",
    vehicle: "Car",
    brandModel: "Chevrolet Beat",
    createdDate: "2022-05-13",
    closedDate: "--",
    customerOwner: "Nitesh",
    remarks: "Not Satisfied",
  },
  {
    id: 9,
    orderId: "O-BIPN-2205-009",
    customerName: "Nanddeep Nachan",
    customerIssue: "--",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Hero Splendor Plus",
    createdDate: "2022-05-07",
    closedDate: "--",
    customerOwner: "Atul",
    remarks: "Not Satisfied",
  },
  {
    id: 10,
    orderId: "O-BIPN-2204-010",
    customerName: "Rupali Bhosale",
    customerIssue: "Jump start alto",
    status: "Close",
    vehicle: "Car",
    brandModel: "Honda City",
    createdDate: "2022-04-22",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Closed",
  },
  {
    id: 11,
    orderId: "O-BIPN-2204-011",
    customerName: "Rahul Mahamuni",
    customerIssue:
      "After servicing petrol tank was broken. Carborator was not clean.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "TVS Scooty",
    createdDate: "2022-04-16",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Closed",
  },

  {
    id: 12,
    orderId: "O-BIPN-2207-012",
    customerName: "Prakash Chandra",
    customerIssue: "Starting Work repair",
    status: "Close",
    vehicle: "Bike",
    brandModel: " TVS Jupiter",
    createdDate: "2022-03-07",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Satisfied",
  },
  {
    id: 13,
    orderId: "O-BIPN-2207-013",
    customerName: "Prakash Sharma",
    customerIssue: "Starting Work repair.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "TVS Scooty",
    createdDate: "2022-02-07",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "Satisfied",
  },
  {
    id: 14,
    orderId: "O-BIPN-2206-014",
    customerName: "Sudipta Chakraborty",
    customerIssue: "After Serviceing didn't feel smooth noise from bike.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Suzuki Burgman Street",
    createdDate: "2022-06-22",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Not Satisfied",
  },
  {
    id: 15,
    orderId: "O-BIPN-2206-015",
    customerName: "Akash Pawar",
    customerIssue: "Vehicle starting problem.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Suzuki Access 125",
    createdDate: "2022-06-22",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Not Satisfied",
  },

  {
    id: 16,
    orderId: "O-BIPN-2109-016",
    customerName: "Murali T",
    customerIssue: "Speaker grill cover missing.",
    status: "Not Solved",
    vehicle: "Car",
    brandModel: "Nano",
    createdDate: "2021-09-21",
    closedDate: "--",
    customerOwner: "Chaitali",
    remarks: "Not Satisfied",
  },
  {
    id: 17,
    orderId: "O-BIPN-2110-017",
    customerName: "Sourav Datta",
    customerIssue: "Scratch on dashboard.",
    status: "Not Solved",
    vehicle: "Car",
    brandModel: "Scala",
    createdDate: "2021-10-23",
    closedDate: "--",
    customerOwner: "Shruti",
    remarks: "Not Satisfied",
  },

  {
    id: 18,
    orderId: "O-CAPN-2110-018",
    customerName: "Saurabh Bindal",
    customerIssue:
      "Cleaning not done properly. I don't believe in your servicing.",
    status: "Not Solved",
    vehicle: "Car",
    brandModel: "i10",
    createdDate: "2021-10-31",
    closedDate: "--",
    customerOwner: "Shruti",
    remarks: "Not Satisfied",
  },
  {
    id: 19,
    orderId: "O-BIPN-2107-019",
    customerName: "Priya Pandey",
    customerIssue: "Petrol was all empty when you got my bike.",
    status: "Not Solved",
    vehicle: "Bike",
    brandModel: "Activa",
    createdDate: "2021-07-31",
    closedDate: "--",
    customerOwner: "Shruti",
    remarks: "Not Satisfied",
  },
  {
    id: 20,
    orderId: "O-BIPN-2208-020",
    customerName: "Manisha Prajapati",
    customerIssue:
      "She said after servicing vehicle starter problem  need to check up send mechanic pn Tuesday.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Honda Activa",
    createdDate: "2022-08-29",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "--",
  },
  {
    id: 21,
    orderId: "O-CAPN-2208-021",
    customerName: "Manisha Prajapati",
    customerIssue:
      "After jump start vehicle not start so need to do servicing so hewill call after some days .",
    status: "Open",
    vehicle: "Car",
    brandModel: "Hyundai Grand i10",
    createdDate: "2022-08-29",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "--",
  },
  {
    id: 22,
    orderId: "O-BIPN-2208-022",
    customerName: "Brijesh Gupta",
    customerIssue: "Disc pad work not done properly send mechaninc on Monday.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Bajaj Pulsar NS 150",
    createdDate: "2022-08-28",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "--",
  },
  {
    id: 23,
    orderId: "O-BIPN-2208-023",
    customerName: "Tapendra",
    customerIssue:
      "After servising vehicle starter problem Due to vehicle race  need to recheckup .",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Hero Passion Pro",
    createdDate: "2022-08-28",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "--",
  },
  {
    id: 24,
    orderId: "O-BIPN-2208-024",
    customerName: "Ashok Jhunjhunwala",
    customerIssue:
      "Satisfide with theservice … but feedback given charges high.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "TVS Apache RTR 160",
    createdDate: "2022-08-26",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Satisfied",
  },
  {
    id: 25,
    orderId: "O-BIPN-2208-025",
    customerName: "Farooq",
    customerIssue: "Satisfide with service but vehicle not washing .",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Yamaha Ray ZR 125",
    createdDate: "2022-08-23",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Satisfied",
  },
  {
    id: 26,
    orderId: "O-BIPN-2208-026",
    customerName: "Prashant",
    customerIssue:
      "Customer satisfide with the service but Brake  very tight want to do loos ….send mechanic on Monday .",
    status: "Close",
    vehicle: "Bike",
    brandModel: "TVS Jupiter",
    createdDate: "2022-08-21",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "Satisfied",
  },
  {
    id: 27,
    orderId: "O-BIPN-2208-027",
    customerName: "Gopal  Giri",
    customerIssue: "Dissatisfied with overall servicing.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Honda Activa",
    createdDate: "2022-08-12",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "Not Satisfied",
  },
  {
    id: 28,
    orderId: "O-BIPN-2208-028",
    customerName: "Smita Chavan",
    customerIssue: "Customer facing self starter issue again.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "TVS Jupiter",
    createdDate: "2022-08-11",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "Not Satisfied",
  },
  {
    id: 29,
    orderId: "O-BIPN-2208-029",
    customerName: "Smita Chavan",
    customerIssue: "Satisfide this vehicle service.",
    status: "Close",
    vehicle: "Car",
    brandModel: "Hyundai i10",
    createdDate: "2022-08-10",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "Satisfied",
  },
  {
    id: 30,
    orderId: "O-BIPN-2207-030",
    customerName: "Pushakar Joshi",
    customerIssue: "Little bit noise concern.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Hero Pleasure",
    createdDate: "2022-07-27",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Satisfied",
  },
  {
    id: 31,
    orderId: "O-BIPN-2207-031",
    customerName: "Duct Master",
    customerIssue:
      "Satisfide with service but some work pending he will visit as posibel.",
    status: "Close",
    vehicle: "Bike",
    brandModel: "Honda Aviator",
    createdDate: "2022-07-26",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Satisfied",
  },
  {
    id: 32,
    orderId: "O-BIPN-2208-032",
    customerName: "Manoj Singh",
    customerIssue: "Some work pending he will call for that.",
    status: "Close",
    vehicle: "--",
    brandModel: "TVS Scooty Pep Plus",
    createdDate: "2022-08-20",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Satisfied",
  },
  {
    id: 33,
    orderId: "O-BIPN-2208-033",
    customerName: "Mangesh Bhandekar",
    customerIssue:
      "Customer said  haven't  service any vehicle …..he will call for servicing now he out of station .",
    status: "Open",
    vehicle: "--",
    brandModel: "Suzuki Access 125",
    createdDate: "2022-08-16",
    closedDate: "--",
    customerOwner: "--",
    remarks: "--",
  },
  {
    id: 34,
    orderId: "O-BIPN-2208-034",
    customerName: "Akansha Ravat",
    customerIssue: "After opening call dissconected by customer.",
    status: "Open",
    vehicle: "Car",
    brandModel: "TVS Jupiter",
    createdDate: "2022-08-13",
    closedDate: "--",
    customerOwner: "--",
    remarks: "--",
  },
  {
    id: 35,
    orderId: "O-CAPN-2209-035",
    customerName: "Sanjeev Kumar",
    customerIssue: "Steering and seat moving issue.",
    status: "Open",
    vehicle: "Car",
    brandModel: "Tata Manza",
    createdDate: "2022-09-23",
    closedDate: "--",
    customerOwner: "--",
    remarks: "--",
  },
  {
    id: 36,
    orderId: "O-BIPN-2205-036",
    customerName: "Prabodh Bharne",
    customerIssue:
      "We usually service bikes to ENHANCE the performance not to DOWNGRADE. It seems like they just washed the bike only which you can do with 50 bucks. Multiple follow needs to do for small issue. Roadside mechanic do the better job and you can visit their multiple time for small work. A BIG NO for future services. HIGHLY DISAPPONTED!",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Yamaha FZS",
    createdDate: "2022-05-29",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Not Satisfied",
  },
  {
    id: 37,
    orderId: "O-CAPN-2210-037",
    customerName: "Anuja Budukh",
    customerIssue:
      "Worst experience ever . They did not even wash the car. And looks like they used some chemical spray by mistake on my car. Attaching pic for reference.I haven't seen this such unprofessional people in my whole life. I was showing dust on and inside car to the mechanic and he was wiping it with his jacket. Too bad. They were supposed to give car by 4 and gave it around 8. And in unwashed condition with stains all over.",
    status: "Open",
    vehicle: "Car",
    brandModel: "Hyundai i10",
    createdDate: "2022-10-02",
    closedDate: "--",
    customerOwner: "Krishna",
    remarks: "Not Satisfied",
  },
  {
    id: 38,
    orderId: "O-BIPN-2205-038",
    customerName: "Aditya Kulkarni",
    customerIssue:
      "New batter got discharged after a week and with daily riding… i raised complaint three times through call but zero response so far … really bad experience.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Bajan Pulser RS220",
    createdDate: "2022-05-27",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Not Satisfied",
  },
  {
    id: 39,
    orderId: "O-BIPN-2202-039",
    customerName: "Rishab Tondon",
    customerIssue: "Didn't like Gativan mechanics and found the work sad.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "Honda Activa",
    createdDate: "2022-02-12",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Not Satisfied",
  },
  {
    id: 40,
    orderId: "O-BIPN-2205-040",
    customerName: "Priyank Batt",
    customerIssue: "Didn't like the service.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "RE Bullet 350",
    createdDate: "2022-05-20",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Not Satisfied",
  },
  {
    id: 41,
    orderId: "O-BIPN-2204-041",
    customerName: "Prashant Dixit",
    customerIssue: "Poor service, bad exerience, after service issues.",
    status: "Open",
    vehicle: "Bike",
    brandModel: "TVS Jupiter",
    createdDate: "2022-04-01",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Not Satisfied",
  },
  {
    id: 42,
    orderId: "O-BIPN-2209-042",
    customerName: "Harshal Joshi",
    customerIssue:
      "I am not happy with service. Mechanic serviced my vehicle within 20 minutes and after 3 days, I am facing same problem.",
    status: "Close",
    vehicle: "Suzuki",
    brandModel: "Access-125",
    createdDate: "2022-09-18",
    closedDate: "--",
    customerOwner: "--",
    remarks: "Not Satisfied",
  },
  {
    id: 43,
    orderId: "O-CAPN-2210-043",
    customerName: "Dhananjay Joshi",
    customerIssue: "Front wheel noise issue started after servicing.",
    status: "Close",
    vehicle: "Maruti Suzuki",
    brandModel: "Estilo",
    createdDate: "2022-10-14",
    closedDate: "--",
    customerOwner: "Ashutosh",
    remarks: "Not Satisfied",
  },
  {
    id: 44,
    orderId: "O-BIPN-2210-044",
    customerName: "Ashok Zunzunwala",
    customerIssue: "Brake gets loose after servicing.",
    status: "Close",
    vehicle: "Honda",
    brandModel: "Activa 125",
    createdDate: "2022-10-14",
    closedDate: "--",
    customerOwner: "Ravi",
    remarks: "Not Satisfied",
  },
];

function CustomerServiceListView() {
  const [tableView, setTableView] = useState(true);
  const [customerIssues, setCustomerIssues] = useState([]);

  const [isIssueAdded, setIsIssueAdded] = useState(false);
  const [openCustomerIssueTrackerDetails, setOpenCustomerIssueTrackerDetails] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [loading, setLoading] = useState(true);

  const [ticketNumber, setTicketNumber] = useState("");

  const getTicketNumber = (ticketNumber) => {
    // setSendData([]);
    setTicketNumber(ticketNumber);
  };

  const openEditIssueModel = (ticketNo) => {
    setOpenCustomerIssueTrackerDetails(true);
    getTicketNumber(ticketNo);
    setIsEdit(true);
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    applyFilter,
    openAddCustomerIssues,
  } = commonData;

  // Apply background color based on values

  const columns = [
    {
      field: "ticketNo",
      headerName: "Ticket Number",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "customerIssue",
      headerName: "Customer Issue",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 15%",
              width: "100%",
              height: "80%",
              // color: "white",
              backgroundColor:
                status === "Open"
                  ? "red"
                  : status === "Close"
                  ? "#51ae3a"
                  : status === "InProgress"
                  ? "yellow"
                  : status === "Solved"
                  ? "#51ae3a"
                  : "#ff1a1a",
              zIndex: "1000",
            }}
          >
            <span>{status}</span>
            {/* <ExpandMoreIcon /> */}
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "serviceAdvisor",
      headerName: "Service Adviser",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Box
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{params.row.serviceAdvisor}</span>
            {/* <ExpandMoreIcon /> */}
          </Box>
        );
      },
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remarks",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
            onClick={() => openEditIssueModel(params.row.ticketNo)}
          >
            <EditIcon />
          </div>
        );
      },
      flex: 1,
    },
  ];

  const getCustomerIssues = async () => {
    try {
      const response = await getCustomerIssueList();
      const data = await response.data;
      setCustomerIssues(data?.result);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    getCustomerIssues();
  }, [, isIssueAdded]);

  const [bindCustomerIssues, setBindCustomerIssues] = useState([]);

  useEffect(() => {
    let issues = customerIssues?.map((issue) => {
      const issueObj = {
        ...issue,
        customerIssue: issue?.issue?.issue || issue?.issueName,
        city: issue?.city?.city,
        // customerIssue: issue?.issue?.issue,
        vehicleType: issue?.vehicleType?.vehicleType,
        brand: issue?.brand?.brand,
        model: issue?.model?.model,
        status: issue?.status?.status,
        variant: issue?.variant?.variantName,
        serviceAdvisor: `${issue?.serviceAdvisor?.first_name || ""} ${
          issue?.serviceAdvisor?.last_name || ""
        }`,
      };
      return issueObj;
    });
    setBindCustomerIssues(issues);
  }, [customerIssues]);

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("createdDate");
  }, [filteredDate && filteredDate]);

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  const closeEditModal = () => {
    setOpenCustomerIssueTrackerDetails(false);
    setIsEdit(false);
  };

  return (
    <div>
      {
        <IssueDetails
          open={openCustomerIssueTrackerDetails}
          close={closeEditModal}
          isEdit={isEdit}
          ticketNumber={ticketNumber}
          setIsIssueAdded={setIsIssueAdded}
        />
      }

      {tableView ? (
        <Table
          customStyle={DataGridStyle}
          // checkBox={true}
          heading="Customer Service"
          rows={bindCustomerIssues}
          // rows={[]}
          columns={columns}
          pageSize={50}
          // loading={loading}
          rowsPerPageOptions={[100]}
          filterModel={filterModel && filterModel}
          setTableView={setTableView}
          downloadXLS={() => downloadXLS(rows)}
          handleOpenDatePicker={handleOpenDatePicker}
          filteredDate={filteredDate}
          applyFilter={applyFilter && applyFilter}
          components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
          getRowId={(row) => row?.ticketNo}
        />
      ) : (
        <CustomerServiceGridView
          heading="Customer Service"
          data={bindCustomerIssues}
          setTableView={setTableView}
          downloadXLS={() => downloadXLS(rows)}
          handleOpenDatePicker={handleOpenDatePicker}
          filteredDate={filteredDate}
          setIsIssueAdded={setIsIssueAdded}
          openCustomerIssueTrackerDetails={openCustomerIssueTrackerDetails}
          setOpenCustomerIssueTrackerDetails={
            setOpenCustomerIssueTrackerDetails
          }
          getTicketNumber={getTicketNumber}
          setIsEdit={setIsEdit}
        />
      )}

      {openAddCustomerIssues && <AddIssue setIsIssueAdded={setIsIssueAdded} />}
    </div>
  );
}

export default CustomerServiceListView;
