import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";

const borderColor = "#6a7086";

const styles = StyleSheet.create({
  container: {
    borderTopColor: borderColor,
    borderTopWidth: 1,
    height: "20px",
    flexDirection: "row",
  },
  total: {
    width: "30%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    height: "100%",
    fontSize: "10px",
    fontFamily: "AvenirDemi",
    justifyContent: "center",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  price: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  discount: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  taxableAmount: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },

  gstSection: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  }, 
  totalPrice: {
    width: "9%",
    fontSize: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
});

export default function TableFooter({ data }) {
  const { totalGst,items } = data;
 
  const quantity = items?.map(i=>i.qty).reduce((a,b)=>a+b,0)
  const price = items?.map(i=>i.price).reduce((a,b)=>a+b,0)
  const discount = items?.map(i=>i.discount).reduce((a,b)=>a+b,0)
  const taxableAmount = items?.map(i=>i.taxableAmount).reduce((a,b)=>a+b,0)
  const total = items?.map(i=>i.total).reduce((a,b)=>a+b,0) 
 
  return (
    <View style={styles.container}>
      <View style={styles.total}>
        <Text>Total</Text>
      </View>
      <View style={styles.qty}>
        <Text>{quantity}</Text>
      </View>
      <View style={styles.price}>
        <Text>{price}</Text>
      </View>
      <View style={styles.discount}>
        <Text>{discount}</Text>
      </View>
      <View style={styles.taxableAmount}>
        <Text>{taxableAmount.toFixed(2)}</Text>
      </View>
      <View style={styles.gstSection}>
        <Text>{totalGst/2}</Text>
      </View>

      <View style={styles.gstSection}>
        <Text>{totalGst/2}</Text>
      </View>
      <View style={styles.totalPrice}>
        <Text>{total.toFixed(2)}</Text>
      </View>
    </View>
  );
}
