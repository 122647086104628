const initState = {
  data: [],
  isSearching: false,
  searchQuery: "",
  status: "",
};

export const LeadsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SEARCH_LEADS":
      return {
        data: action.payload.data,
        isSearching: action.payload.isSearching,
        searchQuery: action.payload.searchQuery,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

const initCard = {
  allServices: [],
  newServices: [],
  cardTotal: 0,
};

export const LeadCardReducer = (state = initCard, action) => {
  switch (action.type) {
    case "LEAD_CARD":
      return {
        allServices: action?.payload?.allServices,
        newServices: action?.payload?.newServices,
        cardTotal: action?.payload?.cardTotal,
      };

    default:
      return state;
  }
};
