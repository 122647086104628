import React, { useState, useEffect, useContext } from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addBrand } from "../../services/inventoryServices/InventoryServices";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";

const AddMaterialBrands = ({ setIsBrandAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [brandDetails, setBrandDetails] = useState({});

  // GET CONTEXT COMMON DATA
  const { handleCloseAddMaterialBrandModel } = useContext(CommonContextAPI);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrandDetails({ ...brandDetails, [name]: value });
  };

  const saveBrand = async () => {
    try {
      const response = await addBrand(brandDetails);
      const data = await response.data;
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddBrand = () => {
    saveBrand();
    setIsBrandAdded(true);
    handleCloseAddMaterialBrandModel();
  };

  const discardDetails = () => {
    setBrandDetails({
      id: "",
      brand: "",
    });
  };

  const renderForm = [
    {
      label: "Brand",
      fields: [
        {
          label: "Brand",
          field: "TextField",
          name: "brand",
          id: "brand",
          type: "text",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
      heading="Add Brand"
        closeModal={handleCloseAddMaterialBrandModel}
        isAdded={setIsBrandAdded}
        renderForm={renderForm}
        service={addBrand}
      />
    </div>
  );
};

export default AddMaterialBrands;
