import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  getOrdersStatus,
  getOrders,
  getGarageOrders,
  getOrdersByMobileNumber,
} from "../../services/orderServices/OrderServices";
import { getOrdersByGarageId } from "../../services/garageServices/GarageServices";
import {
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import clsx from "clsx";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Table from "../../components/tables/Table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Box, Button } from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import httpCommon from "../../http-common";
import { CommonContextAPI } from "../../App";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters, downloadXLS } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />

        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const OrderHistory = () => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [local, setLocal] = useState([]);
  const navigate = useNavigate();
  let [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);

  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const { mobileNumber } = useParams();

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    setFilterModel,
    clearSelectedDate,
    getCachedData,
    storeDataInCache,
    handleAlertOpen,
    colors,
    orderType,
    setOrderType,
    currentOrders,
    setCurrentOrders,
  } = commonData;

  const ordersObject = (order) => {
    const tempNCJ = order?.nonCatalogueJobs?.toString();
    const tempService = order?.services?.toString();

    const orderObj = {
      customerName: `${
        order?.user?.first_name != null ? order?.user?.first_name : ""
      } ${order?.user?.last_name != null ? order?.user?.last_name : ""}`,
      bookingMode: order?.bookingMode?.mode,
      brand: order?.vehicle?.brand?.brand,
      modelName: order?.vehicle?.model?.model,
      variantName: order?.vehicle?.variant?.variantName,
      // service: order?.services?.map((service) => service),
      service: tempService,
      // nonCatalogueJobs: order?.nonCatalogueJobs?.map((job) => job),
      nonCatalogueJobs: tempNCJ,
      ksMaterial: "",
      serviceAdvisor: `${
        order?.serviceAdvisor?.first_name
          ? order?.serviceAdvisor?.first_name
          : ""
      } ${
        order?.serviceAdvisor?.last_name ? order?.serviceAdvisor?.last_name : ""
      }`,

      serviceBookingDate: order?.serviceBookingDate,
      serviceBookingTime: order?.serviceBookingTime,
      completionDate: order?.completionDate,
      discount: order?.discount ? order?.discount : 0,
      totalPrice: order?.totalPrice,
      sale: order?.totalPrice + (order?.discount ? order?.discount : 0),
      orderStatus: order?.orderStatus?.status,
      vehicleType: order?.vehicle?.vehicleType?.vehicleType,
      garage: order?.garage?.garageName,
      etd: order?.etd,
      tat: order?.tat,
      vehicleNumber: order?.vehicle?.vehicleNumber,
      mobileNumber: order?.user?.mobile_number,
      address: order?.area,
      location: order?.order?.city,
      slot: `${order?.preferredServiceDate} ${order?.preferredServiceTime}`,
      remarks: order?.remarks,
      saRemarks: order?.saRemarks,
      orderId: order?.orderId,
      paymentId: order?.paymentId,
      reason: order?.reason,
      orderType: order?.orderType?.type,
    };

    return orderObj;
  };

  const userName = String(sessionStorage.getItem("userName"));
  const isAdmin = JSON.parse(sessionStorage.getItem("isAdmin"));

  const ordersByMobileNumber = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
    };
    try {
      const response = await getOrdersByMobileNumber(config, mobileNumber);
      const result = await response.data;
      console.log("result", result.result);
      setOrders(result.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // get Lead Status
  const getAllOrderStatus = async () => {
    try {
      const response = await getOrdersStatus();
      const result = await response.data;
      setOrderStatus(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrderStatus();
    ordersByMobileNumber();
  }, [, mobileNumber]);

  useEffect(() => {
    // setLocal([]);
    orders &&
      orders.map((order, index) => {
        const orderObj = ordersObject(order);
        if (Number(mobileNumber) == Number(orderObj?.mobileNumber)) {
          setLocal((prev) => [
            ...prev,
            {
              ...orderObj,
              sortByCreatedDateTime: new Date(
                `${orderObj?.serviceBookingDate} ${orderObj?.serviceBookingTime}`
              ),
            },
          ]);
        }
      });
  }, [orders, filteredDate, mobileNumber]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "bookingMode",
      headerName: "Booking",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Vehicle Name",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "modelName",
      headerName: "Vehicle Model",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "variantName",
      headerName: "Vehicle Variant",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const value = params.formattedValue;
        const valueLoCa = params.formattedValue
          ?.replaceAll(" ", "")
          .toLowerCase();

        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 15%",
              width: "100%",
              height: "80%",
              color: valueLoCa === "approvalpending" ? "#000" : "white",
              backgroundColor:
                valueLoCa === "notstarted"
                  ? "#D0342C"
                  : valueLoCa === "jobcard"
                  ? "#122164"
                  : valueLoCa === "inspection"
                  ? "#FEB100"
                  : valueLoCa === "approved"
                  ? "#FEB100"
                  : valueLoCa === "delivered"
                  ? "#7B8BD4"
                  : valueLoCa === "complete"
                  ? "#51AE3A"
                  : valueLoCa === "invoice"
                  ? "#006B3E"
                  : valueLoCa === "lost"
                  ? "#000000"
                  : valueLoCa === "pickupcompleted"
                  ? "#DF764F"
                  : valueLoCa === "atworkshop"
                  ? "#F76833"
                  : valueLoCa === "estimatenotsent"
                  ? "#33F4F7"
                  : valueLoCa === "approvalpending"
                  ? "#E3E7FA"
                  : valueLoCa === "workinprogress"
                  ? "#246ED3"
                  : valueLoCa === "readyfordelivery"
                  ? "#7B8BD4"
                  : valueLoCa === "estimatesent"
                  ? "#33C2F7"
                  : valueLoCa === "unanswered"
                  ? "#9C9596"
                  : "",
            }}
          >
            <span>{value}</span>
          </div>
        );
      },

      cellClassName: (params) =>
        clsx(
          "super-app",
          orderStatus.map((order) => {
            if (
              params.value?.replace(" ", "").toLowerCase() ===
              order?.status?.replace(" ", "").toLowerCase()
            ) {
              return params.value.replace(" ", "").toLowerCase();
            }
          })
        ),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "serviceAdvisor",
      headerName: "Service Advisor",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "serviceBookingDate",
      headerName: "Created Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "serviceBookingTime",
      headerName: "Created Time",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "sortByCreatedDateTime",
      headerName: "Filter By Order Id",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      filterable: true,
      flex: 1,
    },
    {
      field: "completionDate",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "discount",
      headerName: "Discount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "sale",
      headerName: "Sale",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "orderType",
      headerName: "Order Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "service",
      headerName: "Service",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      editable: true,
      flex: 1,
    },
    {
      field: "nonCatalogueJobs",
      headerName: "Non-Catalogue Jobs",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "ksMaterial",
      headerName: "KS Material",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "garage",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "etd",
      headerName: "ETD",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tat",
      headerName: "TAT",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "slot",
      headerName: "Slot",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Customer Remarks",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "saRemarks",
      headerName: "Service Advisor Remarks",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "reason",
      headerName: "Lost Reason",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "paymentId",
      headerName: "Payment Id",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
  ];

  const [filter, setFilter] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const getFilteredData = (data) => {
    setFilter([]);
    setFilter(data);
  };

  useMemo(() => {
    setTotal(0);
    local.map((item, i) => {
      setTotal((prev) => prev + item?.sale);
    });
  }, [local]);

  function FooterSection() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Total Price :{" "}
              <span className="grid-table-footer-right-value">
                {filteredTotal ? filteredTotal?.toFixed(2) : total?.toFixed(2)}
                {/* {total && total.toFixed(2)} */}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("serviceBookingDate");
  }, [filteredDate && filteredDate]);

  const download = (list) => {
    const downloadData = list.map((data) => {
      return {
        ...data,
        // nonCatalogueJobs: data?.nonCatalogueJobs?.join(","),
        service: data?.service?.join(","),
      };
    });

    return downloadData;
  };

  return (
    <>
      <Table
        customStyle={DataGridStyle}
        heading={
          loading
            ? "Loading..."
            : `${
                local && local[0]?.customerName != " "
                  ? local[0]?.customerName
                  : mobileNumber
              } Orders`
        }
        initialState={{
          sorting: {
            sortModel: [{ field: "sortByCreatedDateTime", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              sortByCreatedDateTime: false,
            },
          },
        }}
        classes={{
          sortIcon: classes.sortIcon,
          menuIconButton: classes.menuIcon,
          iconSeparator: classes.iconSeparator,
        }}
        rows={local && local}
        columns={columns}
        getRowHeight={() => "auto"}
        pageSize={50}
        rowsPerPageOptions={[100]}
        isCellEditable={(params) => {
          return params.value == "Converted" ? false : true;
        }}
        onCellClick={(params) => {
          if (params.field !== "service") {
            navigate(
              `/orders/orderdetails/${currentOrders?.toLowerCase()}/${
                params.row.orderId
              }/estimate`
            );
          }
        }}
        onStateChange={(state) => {
          setFilteredTotal(0);
          let data = [];
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [orderId, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(orderId);
            }
          }

          const res = local?.filter((item) =>
            visibleItems.includes(item.orderId)
          );

          let temp = res.filter(
            (item, index, self) =>
              self.findIndex((s) => s.orderId == item.orderId) == index
          );

          temp?.map((item) => {
            data.push(item?.tat?.split(" "));
          });

          let totalprice = res
            ?.map((item) => item.totalPrice + Number(item.discount))
            ?.reduce((a, b) => a + b, 0);
          setFilteredTotal(totalprice > 0 && totalprice);
        }}
        onSelectionModelChange={(newSelection) => {}}
        filterModel={filterModel && filterModel}
        downloadXLS={() =>
          downloadXLS(filter.length > 0 ? download(filter) : download(local))
        }
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        loading={loading}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.orderId}
      />
    </>
  );
};

export default OrderHistory;
