import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import "./CompletePayout.css";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  getGaragePayout,
  updatePayoutCompletedDate,
} from "../../services/garageServices/GarageServices";
import { useSelector } from "react-redux";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import { useNavigate } from "react-router-dom";
import { PDFViewer, PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import GarageInvoice from "../../components/pdf/garageInvoice/GarageInvoice";
import PaginationTable from "../../components/tables/Pagination";
import { Button } from "@mui/material";
import { CommonContextAPI } from "../../App";
import moment from "moment";
import Table from "../../components/tables/Table";

const CustomToolbar = () => {
  const { clearAppliedFilters } = useContext(CommonContextAPI);
  return (
    <div className="table-toolbar">
      <GridToolbarQuickFilter />
      <div
        className="table-toolbar-buttons"
        sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}
      >
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className="table_toolbar_clrBtn"
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default function CompletePayout() {
  const [loading, setLoading] = useState(true);
  const [payoutData, setpayoutData] = useState([]);
  const [bindGaragePayout, setBindGaragePayout] = useState([]);
  const [netPayoutSum, setNetPayoutSum] = useState();
  const [payoutFilter, setPayoutFilter] = useState();
  const [filterModels, setFilterModels] = React.useState([]);

  const Navigate = useNavigate();

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);

  // --- Cinfigretion--------
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData?.jwtToken;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // const propsData = useSelector((state) => state?.CommonData);
  const propsData = useContext(CommonContextAPI);

  let isShowAllPayouts = localStorage.getItem("allPayout");

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    defaultDate,
  } = propsData;

  useMemo(() => {
    const sumPayout = bindGaragePayout
      .map((i) => i.netPayout)
      .reduce((a, b) => a + b, 0);
    setNetPayoutSum(sumPayout);
  }, [bindGaragePayout]);

  const getGaragePayoutData = async () => {
    let from;
    let to;

    if (filteredDate) {
      from = moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      to = moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      from =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      to = defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }
    try {
      const request = await getGaragePayout(from, to, "level=3", config);
      const response = await request.data;
      setBindGaragePayout([]);
      setpayoutData(response.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        handleAlertOpen("Authorization Failed!", colors.error);
      }
    }
  };

  const updatePaymentCompletedDate = async (params) => {
    const payoutDetails = payoutData?.find(
      (payout) => payout?.payout.orderId == params.id
    );

    const value = params?.value;
    const id = payoutDetails?.payout?.id;

    try {
      const response = await updatePayoutCompletedDate(id, value, config);
      const data = await response.data;
      handleAlertOpen(data?.message, colors?.success);
    } catch (error) {
      console.log(error);
      handleAlertOpen(error?.response?.data?.message, colors?.error);
    }
  };

  useMemo(() => {
    getGaragePayoutData();
  }, [filteredDate, defaultDate]);

  useEffect(() => {
    payoutData.map((garagePayout) => {
      const { orderDetail, payout, basicPrice, nonCatalogueServicesPrice } =
        garagePayout;
      const orderObj = {
        // orderId: orderDetail?.order?.orderId,
        orderId: payout?.orderId,
        customerName: `${orderDetail?.order?.userFirstName} ${orderDetail?.order?.userLastName}`,
        invoiceId: "",
        // paymentCompledon: orderDetail?.order?.completionDate,
        vehicleType: orderDetail?.order?.vehicleType,
        vehicleDetails: `${orderDetail?.order?.brand} ${orderDetail?.order?.model} ${orderDetail?.order?.variant}`,

        basicPrice: Math.round(payout?.basicPrice),
        ksMaterialPrice: Math.round(payout?.ksMaterialCostBasic),
        payoutPercentage: Math.round(payout?.payoutPercentage),
        basicPayout: Math.round(payout?.basicPayout),
        gstPayout: Math.round(payout?.payoutTotalGst),
        razorpayDeduction: Math.round(payout?.razorpayDeduction),
        totalPrice: Math.round(orderDetail?.order?.totalPrice),

        totalPayout: Math.round(payout?.totalPayout),
        tds: Math.round(payout?.tds),
        netPayout: Math.round(payout?.netPayout),
        approvalStatus: payout?.garagePayoutStatus?.status,
        garageName: payout?.garage?.garageName,
        garageId: payout?.garage?.garageId,
        paymentCompledon: payout?.paymentCompletedOn,
        orderType: payout?.orderType?.type,
      };

      setBindGaragePayout((prev) => [...prev, orderObj]);
    });
  }, [payoutData]);

  const [wait, setWait] = useState(false);
  setTimeout(() => {
    setWait(true);
  }, 5000);

  const columns = [
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      onClick: () => alert("Customer Name"),
    },
    {
      field: "orderId",
      headerName: "Invoice ID",
      headerClassName: "super-app-theme--header",
      minWidth: 240,
      renderCell: (params) => {
        const value = params.formattedValue;
        return (
          <PDFDownloadLink
            document={<GarageInvoice OrderId={value} />}
            fileName={`${params.row.customerName}.pdf`}
            style={{
              textDecoration: "none",
              // padding: "10px",
              // color: "#4a4a4a",
              // backgroundColor: "#f2f2f2",
              border: "none",
              width: "100%",
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading ..."
              ) : (
                <div
                  className="invoiceId"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    color: "blue",
                    zIndex: 5,
                  }}
                >
                  <span style={{ width: "70%", overflow: "hidden" }}>
                    {value}
                  </span>
                  <SystemUpdateAltIcon style={{ width: "30%" }} />
                </div>
              )
            }
          </PDFDownloadLink>
        );
      },
    },
    {
      field: "paymentCompledon",
      headerName: "Payment Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "orderType",
      headerName: "Order Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleDetails",
      headerName: "Vehicle Details",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
{
  field: "totalPrice",
      headerName: "Total Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1.,
},
    {
      field: "basicPrice",
      headerName: "Basic_Price",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "ksMaterialPrice",
      headerName: "KS Material Price",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "payoutPercentage",
      headerName: "Payout Percentage",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "basicPayout",
      headerName: "Basic Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "gstPayout",
      headerName: "GST Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {field: "razorpayDeduction",
    headerName: "Razorpay Deduction",
    headerClassName: "super-app-theme--header",
    minWidth: 150,
    flex: 1},
    {
      field: "totalPayout",
      headerName: "Total Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tds",
      headerName: "TDS Deduction",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "netPayout",
      headerName: "Net Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header super-app-theme--button",
      minWidth: 120,
      editable: false,
      renderCell: (params) => {
        const value = params.formattedValue;
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 8%",
              width: "100%",
              height: "80%",
              color: "white",
              backgroundColor: "#51AE3A",
              zIndex: "1000",
            }}
          >
            <span>{params.row.approvalStatus}</span>
          </div>
        );
      },
      flex: 1,
    },
  ];

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Net Payout :
              <span className="grid-table-footer-right-value">
                {payoutFilter
                  ? Math.round(payoutFilter)
                  : Math.round(netPayoutSum)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  useEffect(() => {
    localStorage.setItem("selectedItem", JSON.stringify({}));
  }, []);

  return (
    <Table
      heading="Complete Payout"
      sx={DataGridStyle}
      rows={bindGaragePayout}
      // classes={}
      columns={columns}
      pageSize={50}
      checkboxSelection
      disableSelectionOnClick
      rowsPerPageOptions={[100]}
      getCellClassName={(param) => "Rows"}
      initialState={{
        sorting: {
          sortModel: [{ field: "paymentCompledon", sort: "desc" }],
        },
      }}
      experimentalFeatures={{ aggregation: true }}
      onStateChange={(state) => {
        const visibleRows = state.filter.visibleRowsLookup;
        let visibleItems = [];
        for (const [orderId, value] of Object.entries(visibleRows)) {
          if (value === true) {
            visibleItems.push(orderId);
          }
        }
        const res = bindGaragePayout.filter((item) =>
          visibleItems.includes(item.orderId)
        );

        const Payout = res.map((i) => i.netPayout).reduce((a, b) => a + b, 0);
        setNetPayoutSum(Payout > 0 ? Payout : netPayoutSum);
        const basic = res.map((i) => i.basicPrice).reduce((a, b) => a + b, 0);
        // setBasicPrice(basic > 0 ? basic : basicPrice);
      }}
      onSelectionModelChange={(item) => {
        const filterArray = bindGaragePayout.filter((data) =>
          item.includes(data.orderId)
        );
        const payout = filterArray
          .map((i) => i.netPayout)
          .reduce((a, b) => a + b, 0);
        setPayoutFilter(payout);
      }}
      onCellEditCommit={(params) => {
        if (params.field == "paymentCompledon") {
          updatePaymentCompletedDate(params);
        }
      }}
      components={{
        Footer: FooterSection,
        Toolbar: CustomToolbar,
      }}
      onCellClick={(params) => {
        if (params.field == "orderId") {
          localStorage.setItem("selectedItem", JSON.stringify(params.row));
        }
        // Navigate(`/orders/orderdetails/${data.row.orderId}/job-card`)
      }}
      handleOpenDatePicker={handleOpenDatePicker}
      getRowHeight={() => 60}
      filterModel={filterModel && filterModel}
      filterDateRenge={filterDateRenge && filterDateRenge}
      filteredDate={filteredDate}
      getRowId={(row) => row?.orderId}
      loading={loading}
    />
  );
}
