import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DownloadIcon from "@mui/icons-material/Download";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  TextField,
  Toolbar,
  appBarClasses,
} from "@mui/material";
import { useMemo } from "react";
import { CommonContextAPI } from "../../App";
import {
  getAllArrivalModes,
  getBrands,
  getLeadById,
  getLeadChannels,
  getLeadOwners,
  getLeadStatus,
  getModels,
  getVariants,
  getVehicleType,
  updateLead,
  updateLeadStatus,
} from "../../services/leadServices/LeadServices";
import { getAllOrderTypes } from "../../services/orderServices/OrderServices";
import ArrivalModeModal from "./ArrivalModeModal";
import "./LeadDetails.css";

import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { StoreLeadCardServices } from "../../action";
import MyDocument from "../../components/pdf/MyDocument";
import AddLeadPackage from "./AddLeadPackage";
import ArrivalModes from "./ArrivalModes";
import GaragesModal from "./GaragesModal";
import LeadCard from "./LeadCard";
import LocationsModal from "./LocationModal";
import ReasonsModal from "./ReasonsModal";
import axios from "axios";
import IssueDetails from "../customerService/IssueDetails";
import httpCommon from "../../http-common";

export const ArrivalModeContext = createContext();

const LeadDetails = () => {
  // const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const aboveTablet = useMediaQuery(theme.breakpoints.up("md"));
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [leadChannels, setLeadChannels] = useState([]);
  const [lead, setLead] = useState({});
  const [leadStatus, setLeadStatus] = useState([]);
  const [initLead, setInitLead] = useState({});
  const [leadOwnersList, setLeadOwnersList] = useState([]);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [arrivalModes, setArrivalModes] = useState([]);
  const { leadId, currentPage } = useParams();
  const URL = httpCommon.defaults.baseURL;
  const [uniqueCities, setUniqueCities] = useState([]);

  console.log(uniqueCities)

  const [viewLeadsCard, setViewLeadsCard] = useState(false);

  const [arrivalModeDetails, setArrivalModeDetails] = useState({});

  const [leadStatusDetails, setLeadStatusDetails] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const [openLocation, setOpenLocation] = useState(false);
  const [openReasons, setOpenReasons] = useState(false);
  const [openGarages, setOpenGarages] = useState(false);
  const [openArrivalModes, setOpenArrivalModes] = useState(false);

  const [isArrivalModeAdded, setIsArrivalModeAdded] = useState(false);
  const [isLeadStatusSelected, setIsLeadStatusSelected] = useState(false);
  const [hasArrivalModeDetails, setHasArrivalModeDetails] = useState(false);
  const [openModalOf, setOpenModalOf] = useState("");
  const [selectedMode, setSelectedMode] = useState("");

  const [isLeadConverted, setIsLeadConverted] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);

  const openArrivalModal = () => {
    setOpenModal(true);
  };

  const closeArrivalModal = () => {
    setOpenModal(false);
  };

  const openLocationModal = () => {
    setOpenLocation(true);
    viewDetails ? closeArrivalModeDetails() : closeArrivalModal();
  };
  const closeLocationModal = () => {
    setOpenLocation(false);
    viewDetails ? openArrivalModeDetails() : openArrivalModal();
  };

  const openReasonsModal = () => {
    setOpenReasons(true);
    viewDetails ? closeArrivalModeDetails() : closeArrivalModal();
  };
  const closeReasonsModal = () => {
    setOpenReasons(false);
    viewDetails ? openArrivalModeDetails() : openArrivalModal();
  };

  const openGaragesModal = () => {
    setOpenGarages(true);
    viewDetails ? closeArrivalModeDetails() : closeArrivalModal();
  };
  const closeGaragesModal = () => {
    setOpenGarages(false);
    viewDetails ? openArrivalModeDetails() : openArrivalModal();
  };

  const openArrivalModeList = () => {
    setOpenArrivalModes(true);
  };
  const closeArrivalModeList = () => {
    setOpenArrivalModes(false);
  };
  const [openViewDetails, setOpenViewDetails] = useState(false);

  const openArrivalModeDetails = () => {
    setOpenViewDetails(true);
  };
  const closeArrivalModeDetails = () => {
    setOpenViewDetails(false);
  };

  const propsData = {
    openArrivalModal,
    closeArrivalModal,
    openLocationModal,
    closeLocationModal,
    openReasonsModal,
    closeReasonsModal,
    openGaragesModal,
    closeGaragesModal,
    openArrivalModes,
    openArrivalModeList,
    closeArrivalModeList,
    selectedMode,
    setSelectedMode,
    closeArrivalModeDetails,
    viewDetails,
    openModalOf,
  };

  // GET CONTEXT COMMON DATA
  const { handleCloseLeads, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const dispatch = useDispatch();
  const LeadCardReducer = useSelector((state) => state.LeadCardReducer);

  const [isLeadDetails, setIsLeadDetails] = useState(null);

  const path = window.location.pathname;

  // const pathname = path.slice(0, path.lastIndexOf("/"));

  const patterns = [/\/?leadCard\/?/g, /\/?leadDetails\/?/g];

  let modifiedPath = path;
  patterns.forEach((pattern) => {
    modifiedPath = modifiedPath.replace(pattern, "");
  });

  const navigate = useNavigate();

  const togglePages = (page) => {
    // navigate(`${pathname}/${page}`);
    navigate(`${modifiedPath}/${page}`);
  };

  useEffect(() => {
    if (currentPage == "leadDetails") {
      setIsLeadDetails(true);
    } else {
      setIsLeadDetails(false);
    }
  }, [currentPage]);

  useEffect(() => {
    setLead({
      ...lead,
      servicesList: LeadCardReducer?.newServices?.map((service) => {
        return { serviceId: service?.serviceId };
      }),
    });
  }, [LeadCardReducer]);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "60%",
    bgcolor: "#fff",
    boxShadow: 20,
    p: 4,
    borderRadius: "8px",
    position: "absolute",
    m: 1,
    height: isMobile ? "90%" : 500,
    overflow: "auto",
    border: "none !important",
  };

  const disableEditing = {
    pointerEvents: "none",
    opacity: 0.7,
  };

  const discardDetails = () => {
    // setLead({
    //   firstName: "",
    //   lastName: "",
    //   vehicleType: "",
    //   brand: "",
    //   modal: "",
    //   variant: "",
    //   mobileNumber: "",
    //   channels: "",
    // });
  };

  

  const [vehicleTypeId, setVehicleTypeId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [modelId, setModelId] = useState("");
  const [initVehicleType, setInitVehicleType] = useState("");




  const handleChange = (e) => {

    const { name, value } = e.target;
    setLead({
      ...lead,
      [name]: name == "vehicleNumber" ? value?.toUpperCase() : value,
    });



    if (name == "brand") {
      setVehicleModels([]);
      setVehicleVariants([]);
      const brandObj = vehicleBrands[value];
      vehicleBrands.find((brand) => {
        if (brand.brand_Id == brandObj.brand_Id) {
          setBrandId(brandObj.brand_Id);
          setLead({
            ...lead,
            [name]: {
              brandId: brandObj?.brand_Id,
              brand: brandObj?.brand,
              image_url: brandObj?.image_url,
            },
            model: null,
            variant: null,
          });
        }
      });
    }

    if (name == "model") {
      setVehicleVariants([]);
      const modelObj = vehicleModels[value];
      vehicleModels.find((model) => {
        if (model.model_id == modelObj.model_id) {
          setModelId(modelObj.model_id);
          setLead({
            ...lead,
            [name]: {
              modelId: modelObj?.model_id,
              model: modelObj?.model,
              image_url: modelObj?.image_url,
            },
            variant: null,
          });
        }
      });
    }

    if (name == "variant") {
      const variantObj = vehicleVariants[value];

      vehicleVariants.find((variant) => {
        if (variant.variantId == variantObj.variantId) {
          setLead({
            ...lead,
            [name]: {
              id: variantObj?.variantId,
              variantName: variantObj?.variantName,
              image_url: variantObj?.image_url,
            },
          });
        }
      });
    }

    if (name == "channel") {
      const channelObj = leadChannels[value];

      leadChannels.find((channel) => {
        if (channel.id == channelObj?.id) {
          setLead({
            ...lead,
            [name]: {
              id: channelObj?.id,
              channel: channelObj?.channel,
            },
          });
        }
      });
    }

    if (name == "leadOwner") {
      const leadOwnerObj = leadOwnersList[value];
      leadOwnersList.find((owner) => {
        if (owner?.id == leadOwnerObj?.id) {
          setLead({
            ...lead,
            [name]: {
              user_id: leadOwnerObj?.id,
              // leadOwner: leadOwnerObj?.firstName,
              first_name: leadOwnerObj?.firstName,
              last_name: leadOwnerObj?.lastName,
            },
          });
        }
      });
    }

    if (name == "orderType") {
      const orderTypesObj = orderTypes[value];
      orderTypes?.find((type) => {
        if (type?.id == orderTypesObj?.id) {
          setLead({
            ...lead,
            [name]: {
              id: orderTypesObj?.id,
              type: orderTypesObj?.type,
            },
          });
        }
      });
    }

    if (name == "status") {
      setOpenModalOf(name);

      const leadStatusObj = leadStatus[value];
      setIsStatusChanged(true);

      if (leadStatusObj?.status == "Converted") {
        setOpenModalOf("arrivalMode");

        openArrivalModeList();
      }

      if (leadStatusObj?.status == "Follow Up") {
        openArrivalModal();
      }

      leadStatus.find((status) => {
        if (status?.statusId == leadStatusObj?.statusId) {
          setLead({
            ...lead,
            [name]: {
              statusId: leadStatusObj?.statusId,
              status: leadStatusObj?.status,
            },
          });
        }
      });
    }
  };

  
  const [selectedCity, setSelectedCity] = useState(''); 


  const handleChangeCity = (e) => {
    setSelectedCity(e.target.value);
    // console.log("Selected City:", e.target.value);
  };
  const userSelection = (fieldName, name, id) => {
    if (fieldName == "vehicleType") {
      setVehicleTypeId(id);
    }
    if (initVehicleType != name) {
      setInitLead(lead);
      setVehicleBrands([]);
      setVehicleModels([]);
      setVehicleVariants([]);
      setLead({
        ...lead,
        [fieldName]: { name, id },
        brand: null,
        model: null,
        variant: null,
      });
    } else {
      setLead({
        ...lead,
        [fieldName]: { name, id },
        brand: initLead?.brand,
        model: initLead?.model,
        variant: initLead?.variant,
      });
    }
  };

  const [services, setServices] = useState([]);

  // Get lead by id
  const getLead = async () => {
    try {
      const response = await getLeadById(leadId);
      const data = await response.data;
      setLead(data.result);
      setServices(data?.result);

      if (data.result?.status?.status == "Converted") {
        setIsLeadConverted(true);
      }

      if (data.result?.arrivalMode && data.result?.arrivalMode != undefined) {
        const res = {
          arrivalMode: data.result?.arrivalMode,
          arrivalTime: data.result?.arrivalTime,
          arrivalDate: data.result?.arrivalDate,
          garage: data.result?.garage,
          reason: data.result?.reason,
          location: data.result?.city,
          arrivalModeRemarks: data.result?.arrivalModeRemarks,
        };
        // setViewDetails({ ...viewDetails, ...res });
        setArrivalModeDetails(res);
        setOpenModalOf("arrivalMode");
        setHasArrivalModeDetails(true);
        setIsArrivalModeAdded(true);
      } else {
        if (data.result?.status && data.result?.status != undefined) {
          setOpenModalOf("status");
          if (data.result?.status?.status == "Follow Up") {
            const res = {
              followUpDate: data.result?.arrivalDate,
              followUpTime: data.result?.arrivalTime,
              jobCardValue: data.result?.jobCardValue,
              leadType: data.result?.leadType,
              reason: data.result?.reason,
              status: data.result?.status,
            };

            // setViewDetails({ ...viewDetails, ...res });
            setArrivalModeDetails(res);
            setIsLeadStatusSelected(true);
            setHasArrivalModeDetails(true);
          }
        }
      }
      setVehicleTypeId(data?.result?.vehicleType?.id);
      setBrandId(data?.result?.brand?.brandId);
      setModelId(data?.result?.model?.modelId);
      setInitVehicleType(data?.result?.vehicleType?.vehicleType);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(
      StoreLeadCardServices({
        ...LeadCardReducer,
        allServices: services?.servicesList || [],
        cardTotal: services?.totalPrice || 0,
      })
    );
  }, [services]);

  // Get vehicle types
  const getVehicleTypes = async () => {
    try {
      const response = await getVehicleType();
      const result = await response.data;
      setVehicleTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get brands
  const getVehicleBrands = async () => {
    try {
      const response = await getBrands(vehicleTypeId);
      const result = await response.data;
      setVehicleBrands(result.result);
    } catch (error) {}
  };

  // Get models
  const getVehicleModels = async () => {
    try {
      const response = await getModels(vehicleTypeId, brandId);
      const result = await response.data;
      setVehicleModels(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // const modelId = lead.model && lead.model.id;
  const getVehicleVariants = async () => {
    try {
      const response = await getVariants(modelId);
      const result = await response.data;
      setVehicleVariants(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // get Lead Status
  const getAllLeadStatus = async () => {
    try {
      const response = await getLeadStatus();
      const result = await response.data;
      setLeadStatus(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all lead channels
  const getAllLeadChannels = async () => {
    try {
      const response = await getLeadChannels();
      const result = await response.data;
      setLeadChannels(result.result);
    } catch (error) {
      console.log(error);
    }
  };





  useEffect(() => {
    axios.get(`${URL}/city/unique`)
      .then(response => {
        // Assuming the response contains an array of city names in data.cities
        const citiesArray = response.data.result;
        setUniqueCities(citiesArray);
      })
      .catch(error => {
        console.error('Error fetching unique cities:', error);
      });
  }, []); 

  // console.log(selectedCity); 





  const [uniqueCitiesId, setUniqueCitiesId] = useState([]);

  useEffect(() => {
    axios.get(`${URL}/city/${selectedCity}`)
      .then(response => {
        // Assuming the response contains an array of city names in data.cities
        const citiesArray = response.data.result.cityId;
        setUniqueCitiesId(citiesArray);
      })
      .catch(error => {
        console.error('Error fetching unique cities:', error);
      });
  }, [selectedCity]); 


// console.log(uniqueCitiesId)




  const getAllLeadOwners = async () => {
    try {
      const response = await getLeadOwners();
      const result = await response.data;
      setLeadOwnersList(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all order types

  const getOrderTypes = async () => {
    try {
      const response = await getAllOrderTypes();
      const data = await response.data;
      setOrderTypes(data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get all Arrival Modes

  const getArrivalModes = async () => {
    try {
      const response = await getAllArrivalModes();
      const data = await response.data;
      setArrivalModes(data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  // update lead data
  const updateLeadDetails = async (lead) => {
    try {
      const response = await updateLead(lead);
      const data = await response.data;
      let currentStatus = leadStatus?.find(
        (data) => data?.statusId == lead?.status?.statusId
      );
      if (!isStatusChanged) {
        if (currentStatus?.status != "Converted") {
          handleAlertOpen(data?.message, colors?.success);
        }
      }
      getLead();
      // Clear the mode details
      setLeadStatusDetails({
        arrivalDate: "",
        arrivalTime: "",
        location: "",
        garage: "",
        reason: "",
        ArrivalModeRemark: "",
      });

      setArrivalModeDetails({
        arrivalDate: "",
        arrivalTime: "",
        location: "",
        garage: "",
        reason: "",
        ArrivalModeRemark: "",
      });

      if (isStatusChanged && data.statusCode == 200) {
        convertLeadStatus(
          lead?.leadId,
          lead?.status?.statusId,
          data?.message,
          currentStatus,
          lead?.arrivalDate,
          lead?.arrivalTime
        );
      } else {
        handleAlertOpen(data?.message, colors?.success);
        navigate("/leads");
      }
    } catch (error) {
      handleAlertOpen(error?.response?.data?.error, colors?.error);
      console.log(error);
    }
  };

  const convertLeadStatus = async (
    leadId,
    statusId,
    msg,
    currentStatus,
    arrivalDate,
    arrivalTime
  ) => {
    const params = {
      arrivalDate: arrivalDate,
      arrivalTime: arrivalTime,
    };
    try {
      const response = await updateLeadStatus(leadId, statusId, params);
      const result = await response.data;
      if (result?.statusCode == 403) {
        handleAlertOpen(result?.message, colors?.error);
        return;
      } else {
        if (
          (result?.statusCode == 200 || result?.statusCode == 201) &&
          currentStatus?.status == "Converted"
        ) {
          handleAlertOpen(result?.message, colors?.success);
          navigate("/leads");
          return;
        } else {
          handleAlertOpen(msg, colors?.success);
        }
        navigate("/leads");
        getLead();
      }
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors?.error);
      console.log(error);
    }
  };

  useEffect(() => {
    getLead();
    getVehicleTypes();
    getAllLeadStatus();
    getAllLeadChannels();
    getAllLeadOwners();
    getOrderTypes();
    getArrivalModes();
  }, []);

  useEffect(() => {
    // if (lead?.servicesList) {
    //   dispatch(
    //     StoreLeadCardServices({
    //       ...LeadCardReducer,
    //       allServices: lead?.servicesList,
    //       newServices: lead?.servicesList,
    //     })
    //   );
    // }
  }, [lead]);

  useMemo(() => {
    vehicleTypeId && getVehicleBrands();
  }, [lead.vehicleType, vehicleTypeId]);

  useMemo(() => {
    brandId && getVehicleModels();
  }, [lead?.brand, brandId]);

  useEffect(() => {
    getVehicleVariants();
  }, [lead?.model, modelId]);

  const saveDetails = () => {
    let leadObj = {
      // ...lead,
      leadId: lead?.leadId,
      mobileNumber: lead?.mobileNumber,
      vehicleType: lead?.vehicleType?.id,
      remark: lead?.remarks,
      leadName: lead?.leadName,
      channel: lead?.channel?.id,
      brandId: lead?.brand?.brandId,
      modelId: lead?.model?.modelId,
      variant: lead?.variant?.id,
      leadOwner: lead?.leadOwner?.user_id,
      status: lead?.status,
      vehicleNumber: lead?.vehicleNumber,
      cityId: uniqueCitiesId || lead?.city?.cityId,
      city: selectedCity || lead?.city?.city,
      
      // orderType: lead?.orderType?.orderType,
      arrivalModeId: lead?.arrivalMode?.id,
      // arrivalModeId: lead?.status?.statusId,
      orderTypeId: lead?.orderType?.id,
      servicesList: lead?.servicesList || [],
    };

      // If both cityId and city are null, use lead?.city?.cityId and lead?.city?.city
  if (!leadObj.cityId && !leadObj.city) {
    leadObj.cityId = lead?.city?.cityId;
    leadObj.city = lead?.city?.city;
  }

    if (
      Object.keys(arrivalModeDetails).length !== 0 &&
      openModalOf == "arrivalMode"
    ) {
      leadObj = {
        ...leadObj,
        arrivalModeId: arrivalModeDetails?.arrivalMode?.id,
        arrivalDate: moment(arrivalModeDetails?.arrivalDate?.$d)?.format(
          "YYYY-MM-DD"
        ),
        arrivalTime: moment(arrivalModeDetails?.arrivalTime?.$d, [
          "HH:mm:ss",
        ])?.format("HH:mm:ss"),
        location: arrivalModeDetails?.location,
        garage: arrivalModeDetails?.garage,
        reason: arrivalModeDetails?.reason,
        ArrivalModeRemark: arrivalModeDetails?.ArrivalModeRemark,
      };
    }

    if (
      Object.keys(leadStatusDetails).length !== 0 &&
      openModalOf == "status"
    ) {
      leadObj = {
        ...leadObj,
        followUpDate: moment(leadStatusDetails?.arrivalDate).format(
          "YYYY-MM-DD"
        ),
        followUpTime: moment(leadStatusDetails?.arrivalTime, [
          "hh:mm:ss",
        ]).format("hh:mm:ss"),
        jobCardValue: leadStatusDetails?.jobCardValue,
        leadType: leadStatusDetails?.leadType,
        reason: leadStatusDetails?.reason,
      };
    }

    if (
      lead.brand != null &&
      lead.model != null &&
      lead.variant != null &&
      lead.vehicleType != null
    ) {
      if (lead.leadOwner == null) {
        handleAlertOpen("Lead Select Lead Owner.", colors?.error);
      } else if (lead.vehicleNumber == null || lead.vehicleNumber == "") {
        handleAlertOpen("Please Enter Vehicle Number.", colors?.error);
      } else {
        updateLeadDetails(leadObj && leadObj);
      }
    } else {
      handleAlertOpen("Vehicle details are required.", colors?.error);
    }

    dispatch(
      StoreLeadCardServices({
        allServices: [],
        newServices: [],
        cardTotal: 0,
      })
    );
  };

  const viewStatusDetails = () => {
    setViewDetails(true);
    openArrivalModeDetails();
  };

  useEffect(() => {
    let temp1 = arrivalModeDetails;
    if (Object.keys(temp1)?.length != 0) {
      for (var key in temp1) {
        if (temp1[key] === "") {
          return;
        } else {
          setHasArrivalModeDetails(true);
          return;
        }
      }
    }
    let temp2 = leadStatusDetails;
    if (Object.keys(temp2)?.length != 0) {
      for (var key in temp2) {
        if (temp2[key] === "") {
          return;
        } else {
          setHasArrivalModeDetails(true);
          return;
        }
      }
    }
  }, [arrivalModeDetails, leadStatusDetails]);

  const shareContext = {
    arrivalModeDetails,
    setArrivalModeDetails,
    leadStatusDetails,
    setLeadStatusDetails,
    openModalOf,
  };

  const handleViewLeadCard = (state) => {
    setViewLeadsCard(state);
  };

  const backToLeads = () => {
    navigate("/leads");
  };

  // WATI message integration all data collect here




  const sendLeadCard = () => {
    const firstName = lead?.leadName?.split(" ")[0];
    const vehicle = `${lead?.brand?.brand} ${lead?.model?.model} ${lead?.variant?.variantName}`;
    const mobileNumber = lead?.mobileNumber;

    const leadId = lead?.leadId;
    const createdDate = lead?.createdDate?.split("-");
    const createdTime = lead?.createdTime?.split(":");
    const watiCity = lead?.city?.city;
    const cityWithHyphen = watiCity.replace(/ /g, '-');
    const URL = `${cityWithHyphen}/leadcard/${leadId}/${firstName}/${createdDate[0]}${createdTime[0]}${createdTime[1]}${createdDate[1]}${createdTime[2]}${createdDate[2]}`;
    const pdfURL = URL?.toLowerCase();

    let option = {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
      },
      body: JSON.stringify({
        receivers: [
          {
            customParams: [
              {
                name: "name",
                value: firstName ? firstName : null,
              },
              { name: "vehicle", value: vehicle ? vehicle : null },
              { name: "link", value: pdfURL && pdfURL },
            ],
            whatsappNumber: "+91" + mobileNumber,
          },
        ],
        broadcast_name: "Gativan",
        template_name: "send_lead_card_3",
      }),
    };
    const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
    fetch(url, option)
      .then((response) => response.json())
      .then((response) =>
        handleAlertOpen("Lead Card send to Lead", colors?.success)
      )
      // .then((response) => console.log(response))
      .catch((err) =>
        handleAlertOpen("Unable to Send Lead Card", colors?.error)
      );
  };

  // const sendLeadCard = () => {
  //   const CustomerFirstName = lead?.leadName;
  //   const Id = lead?.orderId;
  //   const C_Date = lead?.createdDate && lead?.createdDate.split("-");
  //   const C_Time = lead?.createdDate && lead?.createdDate.split(":");
  //   const Year = C_Date && C_Date[0];
  //   const month = C_Date && C_Date[1];
  //   const date = C_Date && C_Date[2];
  //   const hours = C_Time && C_Time[0];
  //   const minutes = C_Time && C_Time[1];
  //   const seconds = C_Time && C_Time[2];
  //   //  create here dynamic share url
  //   const URL = `${Id}/${CustomerFirstName}/${Year}${hours}${minutes}${month}${seconds}${date}`;
  //   //conver URL to lower case
  //   const pdfURL = URL.toLowerCase();
  //   const vehicle =
  //     lead?.brand?.brand +
  //     " " +
  //     lead?.model?.model +
  //     " " +
  //     lead?.variant?.variantName;
  //   const mobile_number = lead?.mobileNumber;

  //   let option = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "text/json",
  //       Authorization:
  //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkMDFmODBhNS02YzYzLTRlZTQtYjQ5ZS1lM2Y3YmVhZTJiMTYiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDgvMTYvMjAyMyAwNDo0MDowNCIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.Sv-J97Zvi94EK_0NJ3ELmM5Jh9BzkyuBrmGdL8f4Y1Q",
  //     },
  //     body: JSON.stringify({
  //       receivers: [
  //         {
  //           customParams: [
  //             {
  //               name: "name",
  //               value: CustomerFirstName ? CustomerFirstName : null,
  //             },
  //             { name: "vehicle", value: vehicle ? vehicle : null },
  //             { name: "link", value: pdfURL && pdfURL },
  //           ],
  //           whatsappNumber: "+91" + mobile_number,
  //         },
  //       ],
  //       broadcast_name: "Gativan",
  //       template_name: "share_job_card_to_customers",
  //     }),
  //   };
  //   const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
  //   fetch(url, option)
  //     .then((response) => response.json())
  //     .then((response) => console.log(response))
  //     .catch((err) => console.error(err));
  // };

  // SAVE LEAD CARD

  const saveLeadCard = () => {
    let leadObj = {
      // ...lead,
      leadId: lead?.leadId,
      mobileNumber: lead?.mobileNumber,
      vehicleType: lead?.vehicleType?.id,
      remark: lead?.remarks,
      leadName: lead?.leadName,
      channel: lead?.channel?.id,
      brandId: lead?.brand?.brandId,
      modelId: lead?.model?.modelId,
      variant: lead?.variant?.id,
      leadOwner: lead?.leadOwner?.user_id,
      status: lead?.status,
      vehicleNumber: lead?.vehicleNumber,
      cityId: 1,
      // orderType: lead?.orderType?.orderType,
      arrivalModeId: lead?.arrivalMode?.id,
      // arrivalModeId: lead?.status?.statusId,
      orderTypeId: lead?.orderType?.id,
      servicesList: lead?.servicesList || [],
    };

    dispatch(
      StoreLeadCardServices({
        allServices: [],
        newServices: [],
        cardTotal: 0,
      })
    );
  };

  return (
    <Box className="leadsPanelContainer" style={{ marginTop: "5rem" }}>
      <ArrivalModeContext.Provider value={shareContext}>
        {openModal && (
          <ArrivalModeModal
            propsData={propsData}
            mode={
              openModalOf == "status"
                ? lead?.status?.status
                : lead?.arrivalMode?.mode
            }
          />
        )}
        <>
          {openLocation && <LocationsModal propsData={propsData} />}
          {openReasons && <ReasonsModal propsData={propsData} />}
          {openGarages && <GaragesModal propsData={propsData} />}
          {/* {openConfirmation && <ConfirmationModal propsData={propsData} />} */}
          {openArrivalModes && <ArrivalModes propsData={propsData} />}
          {/* {openViewDetails && <ViewModeDetails propsData={propsData} />} */}
          {openViewDetails && (
            <ArrivalModeModal
              propsData={propsData}
              mode={
                openModalOf == "status"
                  ? lead?.status?.status
                  : lead?.arrivalMode?.mode
              }
              // viewDetails={true}
              viewDetails={viewDetails}
            />
          )}
        </>
      </ArrivalModeContext.Provider>
      <Toolbar style={{ minHeight: isMobile ? "0px" : "0px" }} />
      {viewLeadsCard && !aboveTablet ? (
        <Box className="lead-card-wrapper">
          <LeadCard lead={lead} handleViewLeadCard={handleViewLeadCard} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} className="leadsDetailsWrapper">
            <Box className="link-header-container">
              <Card className="link-header-card">
                <Box
                  className={
                    currentPage == "leadDetails"
                      ? "active-link-wrapper"
                      : "link-wrapper"
                  }
                >
                  <Typography
                    className={
                      currentPage == "leadDetails" ? "active-link" : "links"
                    }
                    onClick={() => togglePages("leadDetails")}
                  >
                    Lead Details
                  </Typography>
                </Box>
                <Box
                  className={
                    currentPage == "leadCard"
                      ? "active-link-wrapper"
                      : "link-wrapper"
                  }
                >
                  <Typography
                    className={
                      currentPage == "leadCard" ? "active-link" : "links"
                    }
                    onClick={() => togglePages("leadCard")}
                  >
                    Lead Card
                  </Typography>
                </Box>
              </Card>
            </Box>
            {isLeadDetails ? (
              <Card>
                <Box className="cardDetails">
                  <Typography className="detailsTitle">Lead Remarks</Typography>
                  <CardContent
                    className="cardContent"
                    sx={isLeadConverted ? disableEditing : {}}
                  >
                    <TextField
                      id="outlined-multiline-static"
                      style={{ width: "100%" }}
                      label=""
                      className="customerNote"
                      name="remarks"
                      multiline
                      InputProps={{
                        classes: {
                          input: "values",
                        },
                      }}
                      minRows={4}
                      placeholder="Write lead remarks here..."
                      variant="outlined"
                      value={lead?.remarks || ""}
                      onChange={handleChange}
                    />
                  </CardContent>
                  <CardActions className="cardActions">
                    <Grid container spacing={1} className="actionRow">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className="actionCols"
                        sx={isLeadConverted ? disableEditing : {}}
                      >
                        <select
                          name="status"
                          onChange={handleChange}
                          className="selectDropdown"
                          disabled={isLeadConverted ? true : false}
                        >
                          <option value={lead?.status && lead?.status?.status}>
                            {lead?.status && lead?.status?.status}
                          </option>
                          {leadStatus &&
                            leadStatus.map((status, index) => {
                              return (
                                <option value={index} key={status.statusId}>
                                  {status.status}
                                </option>
                              );
                            })}
                        </select>
                      </Grid>
                      <Grid item xs={12} sm={6} className="actionCols">
                        <Button
                          fullWidth
                          variant="contained"
                          className="updateStatusButton"
                          onClick={viewStatusDetails}
                          disabled={hasArrivalModeDetails ? false : true}
                          sx={{
                            "&.Mui-disabled": {
                              pointerEvents: "auto",
                              cursor: "not-allowed",
                            },
                          }}
                          title={
                            hasArrivalModeDetails
                              ? "View Details"
                              : "Arrival Mode/ Lead Status not assinged!"
                          }
                        >
                          View Status Details
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                  <Box className="saveButtonWrapper cardActions">
                    <Button
                      variant="contained"
                      className="saveButton"
                      title={isLeadConverted && "Lead is already converted"}
                      disabled={isLeadConverted ? true : false}
                      onClick={saveDetails}
                      sx={{
                        "&.Mui-disabled": {
                          pointerEvents: "auto",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Card>
            ) : (
              <Box className="lead-package-wrapper">
                <Box sx={isLeadConverted ? disableEditing : {}}>
                  <AddLeadPackage lead={lead} />
                </Box>
                <Card className="action-card">
                  <Grid container spacing={1} className="grid-row">
                    <Grid item xs={6} sm={6} className="action-grid-col">
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            isLeadCard={true}
                            leadData={lead}
                            leadServices={LeadCardReducer}
                            heading="Lead Card"
                          />
                        }
                        fileName={`${lead?.leadName || "Lead Card"}.pdf`}
                      >
                        {({ blob, url, loading, error }) => (
                          <Button
                            variant="contained"
                            className="download-lead-card-button"
                          >
                            {!loading && <DownloadIcon className="icons" />}
                            {loading ? "Loading Document..." : "Download"}
                          </Button>
                        )}
                      </PDFDownloadLink>

                      {/* <Button
                            variant="contained"
                            className="download-lead-card-button"
                          >
                            <DownloadIcon className="icons" /> Download
                          </Button> */}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      className="action-grid-col"
                      sx={isLeadConverted ? disableEditing : {}}
                    >
                      <Button
                        variant="contained"
                        className="save-lead-button"
                        // onClick={saveLeadCard}
                        onClick={saveDetails}
                        title={isLeadConverted && "Lead is already converted"}
                        disabled={isLeadConverted ? true : false}
                        sx={{
                          "&.Mui-disabled": {
                            pointerEvents: "auto",
                            cursor: "not-allowed",
                          },
                        }}
                      >
                        Save Lead Card
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} className="action-grid-col">
                      <Button
                        variant="contained"
                        className="send-booking-link-button"
                      >
                        <SendIcon className="icons" /> Send Booking Payment Link
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} className="action-grid-col">
                      <Button
                        variant="contained"
                        className="send-to-lead-button"
                        // onClick={backToLeads}
                        onClick={sendLeadCard}
                      >
                        <ReplyIcon className="icons" />
                        Send Card to Lead
                      </Button>
                    </Grid>
                    {!aboveTablet && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className="action-grid-col"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="view-lead-card"
                          onClick={() => handleViewLeadCard(true)}
                        >
                          <VisibilityOffIcon className="icons" />
                          View Card
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="jobCardWrapper">
            {isLeadDetails ? (
              <div
                style={
                  isLeadConverted
                    ? { ...disableEditing, width: "90%", margin: "auto" }
                    : { width: "90%", margin: "auto" }
                }
              >
                <Divider className="lead-details-top-divider" />
                <Grid container spacing={1} className="gridRow">
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Lead Name</Typography>
                  </Grid>
                  <Grid item xs={7} sm={8}>
                    <TextField
                      fullWidth
                      size="small"
                      margin="dense"
                      variant="outlined"
                      id="leadName"
                      name="leadName"
                      InputProps={{
                        classes: {
                          input: "values",
                        },
                      }}
                      onChange={handleChange}
                      value={(lead && lead?.leadName) || ""}
                      defaultValue={lead && lead?.leadName}
                    />
                  </Grid>
                </Grid>
                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Vehicle Type</Typography>
                  </Grid>
                  <Grid item xs={7} sm={8}>
                    <ul className="donate-now" style={{ marginTop: 0 }}>
                      {vehicleTypes &&
                        lead?.vehicleType &&
                        vehicleTypes.map((types, index) => {
                          return (
                            <Box key={index}>
                              <li>
                                <input
                                  type="radio"
                                  id={types.id}
                                  name="vehicleType"
                                  value={types.id || ""}
                                  defaultChecked={
                                    lead?.vehicleType &&
                                    types?.vehicleType ==
                                      lead?.vehicleType?.vehicleType
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    userSelection(
                                      "vehicleType",
                                      types.vehicleType,
                                      types.id
                                    )
                                  }
                                />
                                <label htmlFor={types?.vehicleType}>
                                  {types?.vehicleType}
                                </label>
                              </li>
                            </Box>
                          );
                        })}
                    </ul>
                  </Grid>
                </Grid>
                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Vehical Name</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <select
                          name="brand"
                          onChange={handleChange}
                          className="selectDropdown values"
                        >
                          <option value={lead?.brand && lead?.brand.brand}>
                            {lead?.brand && lead?.brand?.brand}
                          </option>
                          {vehicleBrands &&
                            vehicleBrands.map((brand, index) => {
                              return (
                                <option value={index} key={brand.brand_Id}>
                                  {brand.brand}
                                </option>
                              );
                            })}
                        </select>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <select
                          name="model"
                          onChange={handleChange}
                          className="selectDropdown values"
                        >
                          <option value={lead?.model && lead?.model?.model}>
                            {lead?.model && lead?.model?.model}
                          </option>
                          {vehicleModels &&
                            vehicleModels.map((model, index) => {
                              return (
                                <option value={index} key={model.model_id}>
                                  {model.model}
                                </option>
                              );
                            })}
                        </select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels"> Vehical Variant</Typography>
                  </Grid>
                  <Grid item xs={7} sm={4}>
                    <select
                      name="variant"
                      onChange={handleChange}
                      className="selectDropdown values"
                    >
                      <option
                        value={lead?.variant && lead?.variant?.variantName}
                      >
                        {lead?.variant && lead?.variant?.variantName}
                      </option>
                      {vehicleVariants &&
                        vehicleVariants.map((variant, index) => {
                          return (
                            <option value={index} key={variant.variantId}>
                              {variant.variantName}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                </Grid>
                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Vehicle Number</Typography>
                  </Grid>
                  <Grid item xs={7} sm={4}>
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      id="vehicleNumber"
                      name="vehicleNumber"
                      InputProps={{
                        classes: {
                          input: "values",
                        },
                      }}
                      onChange={handleChange}
                      value={lead?.vehicleNumber || ""}
                      defaultValue={lead?.vehicleNumber}
                    />
                  </Grid>
                </Grid>
                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Contact Number</Typography>
                  </Grid>
                  <Grid item xs={7} sm={4}>
                    <TextField
                      size="small"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      id="mobileNumber"
                      inputProps={{ maxLength: 10 }}
                      name="mobileNumber"
                      InputProps={{
                        classes: {
                          input: "values",
                        },
                      }}
                      onChange={handleChange}
                      value={(lead && lead?.mobileNumber) || ""}
                      defaultValue={lead && lead?.mobileNumber}
                    />
                  </Grid>
                </Grid>

                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels"> Channels</Typography>
                  </Grid>
                  <Grid item xs={7} sm={4}>
                    <select
                      name="channel"
                      onChange={handleChange}
                      className="selectDropdown values"
                    >
                      <option value={lead?.channel && lead?.channel?.channel}>
                        {lead?.channel && lead?.channel?.channel}
                      </option>
                      {leadChannels &&
                        leadChannels.map((channel, index) => {
                          return (
                            <option value={index} key={channel.id}>
                              {channel.channel}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                </Grid>

                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Order Type</Typography>
                  </Grid>
                  <Grid item xs={7} sm={4}>
                    <select
                      name="orderType"
                      onChange={handleChange}
                      className="selectDropdown values"
                    >
                      <option value={lead?.orderType && lead.orderType?.type}>
                        {lead?.orderType && lead.orderType?.type}
                      </option>
                      {orderTypes &&
                        orderTypes?.map((orderType, index) => {
                          return (
                            <option value={index} key={orderType?.id}>
                              {orderType?.type}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                </Grid>

                <Grid container className="gridRow" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Typography className="labels">Lead Owner</Typography>
                  </Grid>
                  <Grid item xs={7} sm={4}>
                    <select
                      name="leadOwner"
                      onChange={handleChange}
                      className="selectDropdown values"
                    >
                      <option
                        value={
                          lead?.leadOwner &&
                          (lead?.leadOwner?.first_name || lead?.firstName)
                        }
                        // value={lead?.leadOwner && lead.leadOwner?.leadOwner}
                      >
                        {lead?.leadOwner &&
                          (lead?.leadOwner?.first_name || lead?.firstName)}
                        {/* {lead?.leadOwner && lead.leadOwner?.leadOwner} */}
                      </option>
                      {leadOwnersList &&
                        leadOwnersList.map((owner, index) => {
                          return (
                            <option value={index} key={owner.id}>
                              {owner.firstName}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                </Grid>

{/* Aditya Working */}
{/* <Grid container className="gridRow" spacing={1}>
  <Grid item xs={5} sm={4}>
    <Typography className="labels">City</Typography>
  </Grid>
  <Grid item xs={7} sm={4}>
    <select
      name="city"
      // onChange={handleChange}
      className="selectDropdown values"
      // defaultValue={uniqueCities.length > 0 ? uniqueCities[0].city : ""}
      defaultValue={"Select City"}
    >
      {uniqueCities.map((city, index) => (
        <option value={city.city} key={index}>
          {city.city}
        </option>
      ))}
    </select>
  </Grid>
</Grid> */}



{/* <Grid container className="gridRow" spacing={1}>
  <Grid item xs={5} sm={4}>
    <Typography className="labels">City</Typography>
  </Grid>
  <Grid item xs={7} sm={4}>
    <select
      name="city"
      // onChange={handleChange}
      className="selectDropdown values"
      defaultValue=""
    >
      <option value="" disabled hidden>
        Select City
      </option>
      {uniqueCities.map((city, index) => (
        <option value={city.city} key={index}>
          {city.city}
        </option>
      ))}
    </select>
  </Grid>
</Grid> */}


<Grid container className="gridRow" spacing={1}>
  <Grid item xs={5} sm={4}>
    <Typography className="labels">City</Typography>
  </Grid>
  <Grid item xs={7} sm={4}>
    <select
      name="city"
      onChange={handleChangeCity}
      className="selectDropdown values"
      value={selectedCity}
    >
      <option value="" disabled hidden>
        {lead?.city?.city}
      </option>
      {uniqueCities.map((city, index) => {
        // Skip Bangalore
        if (city.city.toLowerCase() === 'bangalore') {
          return null;
        }
        return (
          <option value={city.city} key={index}>
            {city.city}
          </option>
        );
      })}
    </select>
  </Grid>
</Grid>




              </div>
            ) : aboveTablet ? (
              <Box className="lead-card-wrapper">
                <LeadCard lead={lead} />
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default LeadDetails;


