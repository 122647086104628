const view = {
  viewProfile: null,
};
export const ViewProfile = (state = view, action) => {
  switch (action.type) {
    case "ViewMyProfile":
      return (state = action.payload);

    case "HideProfile":
      return (state = action.payload);
    default:
      return state;
  }
};
