import React, { useContext, useEffect, useMemo, useState } from "react";
import "./InsModal.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import {
  getAllPolicy,
  getAllCodeOfInsurance,
} from "../../../services/insurance/Insurance"; 
 
import { jobCardContext } from "../OrderDetails";
import { getAllInsuranceCompanies,getAllInsuranceType } from "../../../services/Services";

const activeBtnStyle = {
  backgroundColor: "#7B8BD4 !important",
  color: "#f7f7f7 !important",
};

export default function InsModal({ open, setOpen, activeBtn, setActiveBtn }) {
  const [insuranceType, setInsuranceType] = useState("Select Type");
  const [insuranceCompany, setInsuranceCompany] = useState("Select Company");
  const [policyType, setPolicyType] = useState("Select Policy");
  const [allPolicies, setAllPolicies] = useState([]);
  const [allCodeOfInsurance, setAllCodeOfInsurance] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allInsuranceTypes, setAllInsuranceTypes] = useState([]);
  const [modelData, setModelData] = useState();

  const contextdata = useContext(jobCardContext);

  const { insuranceDetails, setInsuranceDetails, updateObj, setUpdateObj } =
    contextdata;

  useMemo(() => {
    setModelData({ ...insuranceDetails });
  }, [insuranceDetails]);

  useEffect(() => {
    setInsuranceType(modelData?.insuranceType?.type);
    setInsuranceCompany(modelData?.company?.name);
  }, [modelData]);

  const handleChangeDetails = (e) => {
    setModelData({
      ...modelData,
      [e.target.name]: e.target.value,
    });
  };

  
  const handleChangePolicyType = (event) => {
    setPolicyType(event.target.value);
  };

  async function getPolicyInfo() {
    try {
      const request = await getAllPolicy();
      const response = await request.data;
      setAllPolicies(response.result);
    } catch (error) {
      console.log(error);
    }
  }
  async function getCodeInsuranceInfo() {
    try {
      const request = await getAllCodeOfInsurance();
      const response = await request.data;
      setAllCodeOfInsurance(response.result);
    } catch (error) {
      console.log(error);
    }
  }
  const getCompanies = async () => {
    try {
      const request = await getAllInsuranceCompanies();
      const response = request.data;
      setAllCompanies(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getInsuranceTypes = async () => {
    try {
      const request = await getAllInsuranceType();
      const response = await request.data;
      setAllInsuranceTypes(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCompanies();
    getPolicyInfo();
    getCodeInsuranceInfo();
    getInsuranceTypes();
  }, []);
  const handleClose = () => setOpen(false);

  const handleConfirm = () => {
    setActiveBtn(modelData?.codeOfInsuranceId == 1 ? "Garage" : "Gativan");
    setInsuranceDetails({ ...modelData });

    setOpen(false);
  };
  const handleDiscard = () => {
    setOpen(false);
  };

  return (
    // <div className='ins-modal-container'>
    //   InsModal
    // </div>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <Box className="ins-modal-container">
        <Box className="ins-modal-header">
          <Typography variant="h5" className="ins-modal-title">
            Insurance Details
          </Typography>
        </Box>

        <Box mt={4} className="ins-modal-body">
          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">
              Code of Insurance
            </Typography>
            <Box sx={{ display: "flex" }}>
              {allCodeOfInsurance?.map((ins) => (
                <Button
                  sx={activeBtn === ins.code ? activeBtnStyle : ""}
                  className="ins-modal-top-btns"
                  onClick={() => {
                    setActiveBtn(ins.code);
                    setModelData({
                      ...modelData,
                      codeOfInsuranceId: ins.id,
                    });
                  }}
                >
                  {ins.code}
                </Button>
              ))}
            </Box>
          </Box>
          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">Insurance Type</Typography>
            <Box>
              <Select
                value={insuranceType}
                className="ins-modal-filed-box"
                name="insuranceType"
                onChange={(event) => setInsuranceType(event.target.value)}
              >
                <MenuItem disabled value="Select Type">Select Type</MenuItem>
                {allInsuranceTypes?.map((insuranceType) => (
                  <MenuItem
                    value={insuranceType.type}
                    onClick={() => {
                      setModelData({
                        ...modelData,
                        insuranceType: insuranceType,
                      });
                    }}
                  >
                    {insuranceType.type}
                  </MenuItem>
                ))}
                {/* <MenuItem value="Cashless">Cashless</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem> */}
              </Select>
            </Box>
          </Box>

          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">
              Insurance Company
            </Typography>
            <Box>
              <Select
                value={insuranceCompany}
                className="ins-modal-filed-box"
                onChange={(event) => setInsuranceCompany(event.target.value)}
              >
                <MenuItem disabled value="Select Company">
                  Select Company
                </MenuItem>
                {allCompanies?.map((company) => (
                  <MenuItem
                    value={company.name}
                    onClick={() => {
                      setModelData({
                        ...modelData,
                        company: company,
                      });
                    }}
                  >
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">Company GSTIN</Typography>
            <Typography className="ins-modal-filed-box">
              {modelData?.company?.gstn}
            </Typography>
          </Box>

          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">Company Address</Typography>
            <Typography className="ins-modal-textarea">
              {modelData?.company?.shopNumber +
                ", " +
                modelData?.company?.landmark +
                ", " +
                modelData?.company?.area +
                ", " +
                modelData?.company?.city +
                ", " +
                modelData?.company?.state +
                ", " +
                modelData?.company?.country +
                ", " +
                modelData?.company?.pincode}
            </Typography>
          </Box>

          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">Claim No.</Typography>
            <Box>
              <Input
                className="ins-modal-filed-box"
                defaultValue={modelData?.claimNo}
                disableUnderline
                name="claimNo"
                onChange={handleChangeDetails}
              ></Input>
            </Box>
          </Box>

          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">Policy Type</Typography>
            <Box>
              <Select
                value={
                  modelData?.policyType
                    ? modelData?.policyType?.type
                    : policyType
                }
                className="ins-modal-filed-box"
                name="policyType"
                onChange={handleChangePolicyType}
                disableAutoFocus
              >
                <MenuItem disabled value="Select Policy">
                  Select Policy
                </MenuItem>
                {allPolicies?.map((policies) => (
                  <MenuItem
                    key={policies.id}
                    value={policies?.type}
                    onClick={() => {
                      setModelData({
                        ...modelData,
                        policyType: policies,
                      });
                    }}
                  >
                    {policies?.type}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          {/* ------Policy number */}
          <Box className="ins-modal-fileds">
            <Typography className="ins-modal-label">Policy Number</Typography>
            <Box>
              <Input
                className="ins-modal-filed-box"
                name="policyNo"
                defaultValue={modelData?.policyNo}
                onChange={handleChangeDetails}
                placeholder="Policy Number"
                disableUnderline
              ></Input>
            </Box>
          </Box>

          <Box className="ins-modal-fileds">
            <Box></Box>
            <Box mt={2} sx={{ display: "flex" }}>
              <Button className="ins-modal-bottom-btns" onClick={handleDiscard}>
                Discard
              </Button>
              <Button className="ins-modal-bottom-btns" onClick={handleConfirm}>
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
