import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { Tooltip, IconButton } from "@mui/material";
import {
  getCustomerIssueList,
  getIssueByTicketNo,
  getIssueResolution,
  getIssueStatuses,
  getIssues,
  getRootCause,
  updateCustomerIssue,
} from "../../services/customerServices/CustomerServices";
import { getCustomerAdvisor } from "../../services/Services";
import { CommonContextAPI } from "../../App";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  closeIconContainer: {
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    top: -15,
    right: 5,
    cursor: "pointer",
    backgroundColor: "#122164",
    borderRadius: "20px",
    color: "#fff",
    width: "25px !important",
    height: "25px !important",
    "&:hover": {
      backgroundColor: "red !important",
      color: "#fff",
      transition: "0.4s",
    },
  },
  customerDetailsContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0.6rem 1rem",
  },
  detailsHeading: {
    fontSize: "1.6rem !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem !important",
    },
  },
  customerIssueContainer: {
    padding: "0.6rem 1rem",
  },
  subHeading: {
    color: "#707070",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  subValues: {
    fontSize: "1rem",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  titles: {
    fontSize: "1.3rem !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  gridContainer: {
    width: "100%",
    padding: "0.8rem 1rem",
  },
  remarks: {
    padding: "0.7rem 1rem",
  },
  dropdowns: {
    margin: "0.5rem 0px",
    position: "relative",
  },
  clearIcons: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    right: 20,
    fontSize: "1.2rem",
  },
  selectDropdown: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    backgroundColor: "inherit",
    fontSize: "1rem",
    cursor: "pointer !important",
    "& option": {
      fontSize: "1.1rem",
      cursor: "pointer !important",
    },
  },
  saveCardButton: {
    backgroundColor: "#51AE3A !important",
    color: "#fff",
  },
  descardButton: {
    backgroundColor: "#F8F8F8 !important",
    color: "black",
  },
  customTooltip: {
    backgroundColor: "rgba(18, 33, 100, 1)",
  },
  customArrow: {
    color: "rgba(18, 33, 100, 1)",
  },
}));

const customerOwner = ["Ganesh", "Test1", "Test2", "Test3"];

// const status = ["Open", "Close", "Solved", "In Progress", "Not Solved"];

const IssueDetails = ({
  open,
  close,
  isEdit,
  ticketNumber,
  setIsIssueAdded,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));

  const [issue, setIssues] = React.useState({});
  const [issuesList, setIssuesList] = useState([]);
  const [issueStatuses, setIssueStatuses] = useState([]);
  const [resolutions, setResolutions] = useState([]);
  const [rootCauses, setRootCauses] = useState([]);
  const [serviceAdvisors, setServiceAdvisors] = useState([]);

  // DROPDOWN REFERENCES
  const issueRef = useRef(null);
  const resolutionRef = useRef(null);
  const rootCauseRef = useRef(null);

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);

  const navigate = useNavigate();

  const [issueData, setIssueData] = useState({});
  const [originalData, setOriginalData] = useState({});

  const customerIssueByTicketNo = async (ticketNumber) => {
    try {
      const response = await getIssueByTicketNo(ticketNumber);
      const data = await response.data;
      const result = data.result;
      const temp = {
        ...result,
        serviceAdvisor: {
          ...result.serviceAdvisor,
          firstName: result?.serviceAdvisor?.first_name,
          lastName: result?.serviceAdvisor?.last_name,
        },
      };

      setIssueData(temp);
      setOriginalData(temp);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    open && customerIssueByTicketNo(ticketNumber);
  }, [, ticketNumber, open]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name == "issue") {
      const temp = issuesList[value];
      setIssueData({ ...issueData, [name]: temp });
    } else if (name == "resolution") {
      const temp = resolutions[value];
      setIssueData({ ...issueData, [name]: temp });
    } else if (name == "rootCause") {
      const temp = rootCauses[value];
      setIssueData({ ...issueData, [name]: temp });
    } else if (name == "serviceAdvisor") {
      const temp = serviceAdvisors[value];

      if (temp !== undefined) {
        setIssueData({ ...issueData, [name]: temp });
      }
    } else if (name == "status") {
      const temp = issueStatuses[value];
      setIssueData({ ...issueData, [name]: temp });
    } else {
      setIssueData({ ...issueData, [name]: value });
    }

    setIssues(value);
    // switch(name) {
    //     case "issue":
    //     setIssues(value);
    //     break;
    // }
  };

  const discardDetails = () => {
    setIssueData(originalData);
  };

  const style = {
    top: smallPhone ? "55%" : "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: smallPhone ? "90%" : 600,
    bgcolor: "background.paper",
    boxShadow: 20,
    pt: 2,
    borderRadius: "8px",
    position: "absolute",
    // height: smallPhone ? 550 : 730,
    maxHeight: smallPhone ? "80%" : "90%",
    border: "none !important",
    overflowY: "auto",
    // pointerEvents: isEdit ? "all" : "none",
  };

  // Get Customer issues list

  const getIssuesList = async () => {
    try {
      const response = await getIssues();
      const data = await response.data;
      setIssuesList(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getIssueStatusesList = async () => {
    try {
      const response = await getIssueStatuses();
      const data = await response.data;
      setIssueStatuses(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getResolutionsList = async () => {
    try {
      const response = await getIssueResolution();
      const data = await response.data;
      setResolutions(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  const getRootCauseList = async () => {
    try {
      const response = await getRootCause();
      const data = await response.data;
      setRootCauses(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceAdvisor = async () => {
    try {
      const request = await getCustomerAdvisor();
      const response = await request.data;
      setServiceAdvisors(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(() => {
    getIssuesList();
    getIssueStatusesList();
    getResolutionsList();
    getRootCauseList();
    getServiceAdvisor();
  }, []);

  useEffect(() => {
    if (!open) {
      setIssueData({});
      setOriginalData({});
    }
  }, [open]);
  const updateIssueDetails = async () => {
    const putObj = {
      ticketNo: issueData?.ticketNo,
      orderId: issueData?.orderId,
      remark: issueData?.remark,
      mobileNumber: issueData?.mobileNumber,
      customerName: issueData?.customerName,
      cityId: issueData?.city?.cityId,
      brandId: issueData?.brand?.brandId,
      modelId: issueData?.model?.modelId,
      vehicleType: issueData?.vehicleType?.id,

      issue: issueData?.issueName ? null : issueData?.issue?.id,
      rootCause: issueData?.rootCauseName ? null : issueData?.rootCause?.id,
      resolution: issueData?.resolutionName ? null : issueData?.resolution?.id,

      issueName: issueData?.issue?.id ? null : issueData?.issueName,
      rootCauseName: issueData?.rootCause?.id ? null : issueData?.rootCauseName,
      resolutionName: issueData?.resolution?.id
        ? null
        : issueData?.resolutionName,

      serviceAdvisor:
        issueData?.serviceAdvisor?.id || issueData?.serviceAdvisor?.user_id,
      status: issueData?.status?.statusId,
    };

    try {
      const response = await updateCustomerIssue(putObj);
      const data = await response.data;

      if (data?.statusCode == 200) {
        handleAlertOpen(data?.message, colors.success);
        close();
        setIsIssueAdded((prev) => !prev);
        // setIssueData({});
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  const statusStyle = {};

  const clearValue = (name) => {
    switch (name) {
      case "issue":
        issueRef.current.value = "";
        setIssueData({
          ...issueData,
          issue: null,
        });
        break;

      case "resolution":
        resolutionRef.current.value = "";
        setIssueData({
          ...issueData,
          resolution: null,
        });
        break;

      case "rootCause":
        rootCauseRef.current.value = "";
        setIssueData({
          ...issueData,
          rootCause: null,
        });
        break;

      default:
        return false;
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box style={{ pointerEvents: isEdit ? "all" : "none" }}>
            <Grid container className={classes.customerDetailsContainer}>
              <Grid item xs={6} sm={7}>
                <Box>
                  <Typography className={classes.detailsHeading}>
                    {issueData?.customerName}
                  </Typography>
                  <Typography>
                    <span className={classes.subHeading}>Ticket No.:</span>{" "}
                    <span className={classes.subValues}>
                      {issueData?.ticketNo}
                    </span>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={5}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography>
                    <span className={classes.subHeading}>Created Date: </span>
                    <span className={classes.subValues}>
                      {issueData?.createdDate}
                    </span>
                  </Typography>
                  <Typography>
                    <span className={classes.subHeading}>Vehicle: </span>
                    <span className={classes.subValues}>
                      {issueData?.vehicleType?.vehicleType}
                    </span>
                  </Typography>
                  <Typography>
                    <span className={classes.subHeading}>Brand & Model: </span>
                    <span className={classes.subValues}>
                      {`${issueData?.brand?.brand} ${issueData?.model?.model}`}
                    </span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box className={classes.customerIssueContainer}>
              <Typography className={classes.titles}>Select Issue</Typography>
              <Box sx={{ minWidth: 120 }} className={classes.dropdowns}>
                {issueData?.issue?.issue && (
                  <Tooltip
                    title="Remove selected Issue."
                    className={classes.clearIcons}
                    classes={{
                      tooltip: classes.customTooltip,
                      arrow: classes.customArrow,
                    }}
                    arrow
                  >
                    <IconButton aria-label="issue">
                      <CloseIcon onClick={() => clearValue("issue")} />
                    </IconButton>
                  </Tooltip>
                )}

                <select
                  ref={issueRef}
                  name="issue"
                  onChange={handleChange}
                  className="selectDropdown"
                  disabled={issueData?.issueName ? true : false}
                >
                  <option value={issueData?.issue && issueData?.issue?.issue}>
                    {issueData?.issue && issueData?.issue?.issue}
                  </option>
                  {issuesList &&
                    issuesList.map((issue, index) => {
                      return (
                        <>
                          <option value={index} key={index}>
                            {issue.issue}
                          </option>
                        </>
                      );
                    })}
                </select>
              </Box>
              <Box style={{ margin: "0.7rem 0px" }}>
                <TextField
                  id="issueName"
                  name="issueName"
                  label={
                    issueData?.issueName
                      ? ""
                      : "If not Mentioned Please type here."
                  }
                  multiline
                  fullWidth
                  rows={3}
                  onChange={handleChange}
                  value={issueData?.issueName}
                  disabled={issueData?.issue?.id ? true : false}
                />
              </Box>
            </Box>

            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.titles}>
                    Select Rootcause
                  </Typography>
                  <Box sx={{ minWidth: 120 }} className={classes.dropdowns}>
                    {issueData?.rootCause?.rootCause && (
                      <Tooltip
                        title="Remove selected Root Cause."
                        className={classes.clearIcons}
                        classes={{
                          tooltip: classes.customTooltip,
                          arrow: classes.customArrow,
                        }}
                        arrow
                      >
                        <IconButton aria-label="rootCouse">
                          <CloseIcon onClick={() => clearValue("rootCause")} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <select
                      ref={rootCauseRef}
                      name="rootCause"
                      onChange={handleChange}
                      className="selectDropdown"
                      disabled={issueData?.rootCauseName ? true : false}
                    >
                      <option
                        value={
                          issueData?.rootCause &&
                          issueData?.rootCause?.rootCause
                        }
                      >
                        {issueData?.rootCause &&
                          issueData?.rootCause?.rootCause}
                      </option>
                      {rootCauses &&
                        rootCauses.map((rootcause, index) => {
                          return (
                            <option value={index} key={index}>
                              {rootcause.rootCause}
                            </option>
                          );
                        })}
                    </select>
                  </Box>
                  <Box style={{ margin: "0.7rem 0px" }}>
                    <TextField
                      id="rootCauseName"
                      name="rootCauseName"
                      label={
                        issueData?.rootCauseName
                          ? ""
                          : "If not Mentioned Please type here."
                      }
                      multiline
                      fullWidth
                      rows={3}
                      onChange={handleChange}
                      value={issueData?.rootCauseName}
                      disabled={issueData?.rootCause?.id ? true : false}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography className={classes.titles}>
                    Select Resolution
                  </Typography>
                  <Box sx={{ minWidth: 120 }} className={classes.dropdowns}>
                    {issueData?.resolution?.resolution && (
                      <Tooltip
                        title="Remove selected Resolution."
                        className={classes.clearIcons}
                        classes={{
                          tooltip: classes.customTooltip,
                          arrow: classes.customArrow,
                        }}
                        arrow
                      >
                        <IconButton aria-label="resolution">
                          <CloseIcon onClick={() => clearValue("resolution")} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <select
                      ref={resolutionRef}
                      name="resolution"
                      onChange={handleChange}
                      className="selectDropdown"
                      disabled={issueData?.resolutionName ? true : false}
                    >
                      <option
                        value={
                          issueData?.resolution &&
                          issueData?.resolution?.resolution
                        }
                      >
                        {issueData?.resolution &&
                          issueData?.resolution?.resolution}
                      </option>
                      {resolutions &&
                        resolutions.map((resolution, index) => {
                          return (
                            <option value={index} key={index}>
                              {resolution.resolution}
                            </option>
                          );
                        })}
                    </select>
                  </Box>
                  <Box style={{ margin: "0.7rem 0px" }}>
                    <TextField
                      id="resolutionName"
                      name="resolutionName"
                      label={
                        issueData?.resolutionName
                          ? ""
                          : "If not Mentioned Please type here."
                      }
                      multiline
                      fullWidth
                      rows={3}
                      onChange={handleChange}
                      value={issueData?.resolutionName}
                      disabled={issueData?.resolution?.id ? true : false}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.remarks}>
              <TextField
                id="issueRemark"
                name="remark"
                label={issueData?.remark ? "" : "Please enter remarks here."}
                multiline
                fullWidth
                rows={3}
                onChange={handleChange}
                value={issueData?.remark}
              />
            </Box>
            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={6} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <select
                    name="serviceAdvisor"
                    onChange={handleChange}
                    className={classes.selectDropdown}
                  >
                    <option
                      value={`${issueData?.serviceAdvisor?.firstName} ${issueData?.serviceAdvisor?.lastName}`}
                    >
                      {`${issueData?.serviceAdvisor?.firstName} ${issueData?.serviceAdvisor?.lastName}`}
                    </option>
                    {serviceAdvisors &&
                      serviceAdvisors.map((advisor, index) => {
                        return (
                          <option value={index} key={index}>
                            {`${advisor?.firstName} ${advisor?.lastName}`}
                          </option>
                        );
                      })}
                  </select>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <select
                    name="status"
                    onChange={handleChange}
                    className={classes.selectDropdown}
                  >
                    <option value={issueData?.status?.status}>
                      {issueData?.status?.status}
                    </option>
                    {issueStatuses.map((status, index) => {
                      return (
                        <option value={index} key={index}>
                          {status?.status}
                        </option>
                      );
                    })}
                  </select>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridContainer}>
              <Grid item xs={6} sm={6}>
                <Button
                  variant="contained"
                  className={classes.descardButton}
                  fullWidth
                  onClick={discardDetails}
                >
                  Descard
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  variant="contained"
                  className={classes.saveCardButton}
                  fullWidth
                  onClick={updateIssueDetails}
                >
                  Save Card
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default React.memo(IssueDetails);
