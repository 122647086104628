import React, { useContext } from "react";
import { addNewVendor } from "../../services/inventoryServices/InventoryServices";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";

const gstRegTypes = [
  {
    type: "Registered",
    id: 1,
  },
  {
    type: "Un-Registered",
    id: 2,
  },
];

const AddVendor = ({ setIsVendorAdded }) => {
  // GET CONTEXT COMMON DATA
  const { handleCloseAddVendorModel } = useContext(CommonContextAPI);

  const renderForm = [
    {
      label: "Vendor Name",
      fields: [
        {
          label: "Vendor Name",
          field: "TextField",
          name: "name",
          id: "name",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Mobile Number",
      fields: [
        {
          label: "Mobile Number",
          field: "TextField",
          name: "mobileNumber",
          id: "mobileNumber",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "GST Reg. Type",
      fields: [
        {
          label: "GST Reg. Type",
          field: "Dropdown",
          name: "gstType",
          id: "gstType",
          type: "select",
          data: gstRegTypes,
          properties: { name: "type", id: "id" },
          sendVal: true,
          grids: 12,
        },
      ],
    },
    {
      label: "GSTN",
      fields: [
        {
          label: "GSTN",
          field: "TextField",
          name: "gstn",
          id: "gstn",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Terms",
      fields: [
        {
          label: "Terms",
          field: "TextField",
          name: "terms",
          id: "terms",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Address",
      fields: [
        {
          label: "Shop Number",
          field: "TextField",
          name: "shopNumber",
          id: "shopNumber",
          type: "text",
          grids: 6,
        },
        {
          label: "Area",
          field: "TextField",
          name: "area",
          id: "area",
          type: "text",
          grids: 6,
        },
        {
          label: "City",
          field: "TextField",
          name: "city",
          id: "city",
          type: "text",
          grids: 6,
        },
        {
          label: "Pin Code",
          field: "TextField",
          name: "pincode",
          id: "pincode",
          type: "text",
          grids: 6,
        },
        {
          label: "Land Mark",
          field: "TextField",
          name: "landmark",
          id: "landmark",
          type: "text",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <Form
        heading="Add Vendor"
        closeModal={handleCloseAddVendorModel}
        isAdded={setIsVendorAdded}
        renderForm={renderForm}
        service={addNewVendor}
      />
    </div>
  );
};

export default AddVendor;
