import React, { useState, useEffect, useContext } from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addBrand } from "../../services/inventoryServices/InventoryServices";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";

const AddCustomServices = ({ setIsOpenAddCustomServiceModal, closeCustomServiceModal }) => {
  const theme = useTheme();

  const renderForm = [
    {
      label: "Custom Service",
      fields: [
        {
          label: "Custom Service",
          field: "TextField",
          name: "customService",
          id: "customService",
          type: "text",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Custom Service"
        closeModal={closeCustomServiceModal}
        isAdded={setIsOpenAddCustomServiceModal}
        renderForm={renderForm}
        service={addBrand}
      />
    </div>
  );
};

export default AddCustomServices;
