import { Box, Button, MenuItem, Select } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";

import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useMemo,
} from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ReplyIcon from "@mui/icons-material/Reply";
import ControlPanel from "./controlPanel/ControlPanel";
import JobCard from "./jobCard/JobCard";
import "./OrderDetails.css";
import Invoice from "./invoice/Invoice";
import { useNavigate } from "react-router-dom";
import {
  getGarages,
  getOrderById,
  getOrdersStatus,
  putOrderStatus,
  putOrder,
  putUserName,
  getCustomerAdvisor,
  PatchOdometerReading,
  deleteService,
} from "../../services/Services";
import { useParams } from "react-router-dom";
import {
  DeliveredOrderStatusWATI,
  CompleteOrderStatusWATI,
  InspectionOrderStatusWATI,
  ApprovedOrderStatusWATI,
  CustomerFeedback,
} from "./WatiIntegration";
import LoaderSpinner from "../../components/loading/LoaderSpinner";
import FinalInspection from "./finalInspection/finalInspection";
import MyDocument from "../../components/pdf/MyDocument";
import UserInfoSection from "./reusableComponents/UserInfoSection";
import Test from "./inspectionReport/Test";
import InvoicePDF from "../../components/pdf/Invoice/Invoice";
import EstimatePDF from "../../components/pdf/insurance/estimate";
import { CommonContextAPI } from "../../App";
import InsControlPanel from "./insurance/InsControlPanel";
import InsuranceInvoice from "./insurance/invoice/InsuranceInvoice";
import Estimate from "./insurance/estimate/Estimate";
import ResonModal from "./ResonModal";
import Form from "../../components/forms/Form";

export const jobCardContext = createContext();
//    toggle button active style
const Active = {
  borderBottom: "4px solid #122164",
  color: "black !important",
  backgroundColor: "rgba(66, 69, 231, 0.466)",
};
//    toggle button normal style
const NotActive = {
  borderBottom: "4px solid #707070",
  color: "gray",
};

const ControlPanelActive = {
  opacity: "1",
  pointerEvents: "all",
};

const ControlPanelDisabel = {
  opacity: "0.5",
  pointerEvents: "none",
};

const disableEvents = {
  opacity: "0.6",
  pointerEvents: "none",
};

const renderForm = [
  {
    label: "Arrival Date",
    fields: [
      {
        label: "Arrival Date",
        field: "TextField",
        name: "arrivalDate",
        id: "arrivalDate",
        type: "date",
        grids: 12,
      },
    ],
  },
  {
    label: "Arrival Time",
    fields: [
      {
        label: "Insurance Type",
        field: "TextField",
        name: "arrivalTime",
        id: "arrivalTime",
        type: "time",
        grids: 12,
      },
    ],
  },
];

export default function OrderDetails() {
  const [isLoding, setIsLoding] = useState(true);
  const [isReasonModal, setIsReasonModal] = useState(false);
  const [isInsurance, setIsInsurance] = useState(false);
  const [isArrival, setIsArrival] = useState(false);

  const [shareJobCard, setShareJobCard] = useState(false);
  const [OrderInfo, setOrderInfo] = useState();
  const [serviceAdvisor, setServiceAdvisor] = useState([]);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [servicesList, setServicesList] = useState([]);

  const [selectedMaterials, setSelectedMaterials] = useState({
    total: 0,
    result: [],
  });
  const [selectedServises, setSelectedServises] = useState({
    total: 0,
    result: [],
  });
  const [saveSelectedServises, setSaveSelectedServises] = useState({
    total: 0,
    result: [],
  });
  const [nonCatalogueSaveServices, setNonCatalogueSaveServices] = useState({
    total: 0,
    result: [],
  });
  const [nonCatalogueService, setNonCatalogueService] = useState({
    total: 0,
    result: [],
  });

  const [nonCatalogueEstimate, setNonCatalogueEstimate] = useState([]);
  const [savedNonCatEstimate, setSavedNonCatEstimate] = useState([]);

  const [estimateDiscount, setEstimateDiscount] = useState();

  const [removeSaveServices, setRemoveSaveServices] = useState([]);
  const [removeSaveNonCatalogueServices, setRemoveSaveNonCatalogueServices] =
    useState([]);

  const [status, setstatus] = useState("Select Status"); //bydefault status show

  const [Garage, setGarage] = useState();
  const [orderStatusList, setOrderStatusList] = useState([]); //get and store all order status here
  const [gativanGarages, setGativanGarages] = useState([]); //store garage list

  const [Advisor, setAdvisor] = useState();

  const [storeStatus, setStoreStatus] = useState(); //store status object
  const [reasonId, setReasonId] = useState(); // store reason id

  const [inspection, setInspection] = useState(false);
  const [jobcard, setJobCard] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [finalInspection, setfinalInspection] = useState(false);
  const [deleteInsuranceServices, setDeleteInsuranceServices] = useState([]);

  // ------Insurance States ----------------------
  const [insEstimate, setInsEstimate] = useState(false);
  const [insInvoice, setInsInvoice] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState({});

  const [updateObj, setUpdateObj] = useState({ nonCatalogueJobs: [] });
  const [updateUserName, setUpdateUserName] = useState();

  const { type, orderId, name } = useParams();
  const { handleAlertOpen, colors } = React.useContext(CommonContextAPI);
  localStorage.setItem("orderId", orderId); // store orderId in local storage
  const Navigate = useNavigate();

  const loggedUserRole = JSON.parse(
    localStorage?.getItem("loginDetails")
  )?.role;

  const isServiceAdvisorLoggedIn = loggedUserRole == "Service Advisor";

  //   handle conditionly rendering here
  useEffect(() => {
    if (type === "insurance") {
      setIsInsurance(true);
      switch (name) {
        case "estimate":
          setInsEstimate(true);
          setInsInvoice(false);
          break;
        case "invoice":
          setInsEstimate(false);
          setInsInvoice(true);
      }
    }

    if (type === "general") {
      switch (name) {
        case "inspection":
          setInspection(true);
          setJobCard(false);
          setInvoice(false);
          setfinalInspection(false);
          break;
        case "job-card":
          setInspection(false);
          setJobCard(true);
          setInvoice(false);
          setfinalInspection(false);
          break;

        case "final-inspection":
          setInspection(false);
          setJobCard(false);
          setInvoice(false);
          setfinalInspection(true);
          break;

        case "invoice":
          setInspection(false);
          setJobCard(false);
          setInvoice(true);
          setfinalInspection(false);
          break;

        default:
          break;
      }
    }
  }, [name]);

  //---------- end ----------------------

  const getOrderStatus = async () => {
    try {
      const request = await getOrdersStatus();
      const response = await request.data;
      setOrderStatusList(response.result);
    } catch (error) {
      // console.log(error);
    }
  };

  // ---------  handle share job card ----- ------------------------
  const CustomerFirstName = OrderInfo?.user?.first_name;
  const Id = OrderInfo?.orderId;
  const createdDate = OrderInfo?.serviceBookingDate;
  const createdTime = OrderInfo?.serviceBookingTime;
  const C_Date = createdDate && createdDate.split("-");
  const C_Time = createdTime && createdTime.split(":");
  const Year = C_Date && C_Date[0];
  const month = C_Date && C_Date[1];
  const date = C_Date && C_Date[2];
  const hours = C_Time && C_Time[0];
  const minutes = C_Time && C_Time[1];
  const seconds = C_Time && C_Time[2];

  useMemo(() => {
    if (CustomerFirstName && createdDate && createdTime) {
      setShareJobCard(true);
    }
  }, [createdDate]);
  //  create here dynamic share url
  const URL = `${Id}/${CustomerFirstName}/${Year}${hours}${minutes}${month}${seconds}${date}`;
  //conver URL to lower case
  const pdfURL = URL.toLowerCase();

  // WATI message integration all data collect here
  const customerName =
    OrderInfo?.user?.first_name + " " + OrderInfo?.user?.last_name;
  const vehicleType = OrderInfo?.vehicle?.vehicleType?.vehicleType;
  const vehicleImage = OrderInfo?.vehicle?.model?.imageUrl;
  const vehicle =
    OrderInfo?.vehicle?.brand?.brand +
    " " +
    OrderInfo?.vehicle?.model?.model +
    " " +
    OrderInfo?.vehicle?.variant?.variantName;
  const mobile_number = OrderInfo?.user?.mobile_number;
  const services = OrderInfo?.services?.map((i) => i.service).toString();

  const ShareJobcarToWati = () => {
    let option = {
      method: "POST",
      headers: {
        "Content-Type": "text/json",
        // Authorization:
        //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjZlYmUxNi04ZWE3LTQ3OWUtYmMyYi1hZTQ2MTVlMjZkNDMiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMDQvMDQvMjAyMiAwNzowMTozNyIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.1hWXiXp6C015_RxoES4Vf6Q6LOxpJxm1qzs-NIH9m5I",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2M2NhYWFkMi1mZDAxLTQ4MDUtYmFjYi04ZjlkZjA5ODQ2ZjEiLCJ1bmlxdWVfbmFtZSI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwibmFtZWlkIjoiZ2F0aXZhbmFwcEBnbWFpbC5jb20iLCJlbWFpbCI6ImdhdGl2YW5hcHBAZ21haWwuY29tIiwiYXV0aF90aW1lIjoiMTEvMjcvMjAyMyAwNDo1MDozNSIsImRiX25hbWUiOiI2NjA1IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.VnSo4aCFuksqLSTYbqbW5F0IjqDe134cPO_9h1YhTX8",
      },
      body: JSON.stringify({
        receivers: [
          {
            customParams: [
              {
                name: "name",
                value: CustomerFirstName ? CustomerFirstName : null,
              },
              { name: "vehicle", value: vehicle ? vehicle : null },
              { name: "link", value: pdfURL && pdfURL },
            ],
            whatsappNumber: "+91" + mobile_number,
          },
        ],
        broadcast_name: "Gativan",
        template_name: "share_job_card_to_customers",
      }),
    };
    const url = "https://live-server-6605.wati.io/api/v1/sendTemplateMessages";
    fetch(url, option)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  // handle dynamic url of Inspection , job card , final inspection , Invoice,estimate and InsuraneInvoice ------
  const handelInialInspection = () => {
    Navigate(`/orders/orderdetails/general/${orderId}/inspection`);
  };
  const handelJobcad = () => {
    Navigate(`/orders/orderdetails/general/${orderId}/job-card`);
  };

  const handelFinalInspection = () => {
    Navigate(`/orders/orderdetails/general/${orderId}/final-inspection`);
  };

  const handelInvoice = () => {
    Navigate(`/orders/orderdetails/general/${orderId}/invoice`);
  };

  const handleInsInvoice = () => {
    Navigate(`/orders/orderdetails/insurance/${orderId}/invoice`);
  };

  const handleInsEstimate = () => {
    Navigate(`/orders/orderdetails/insurance/${orderId}/estimate`);
  };
  // ---------------------------------------------------------

  const getGarageNames = async () => {
    try {
      const response = await getGarages();
      const data = await response.data;
      setGativanGarages(data.result);
    } catch (error) {
      // console.log(error);
    }
  };

  const getServiceAdvisor = async () => {
    try {
      const request = await getCustomerAdvisor();
      const response = await request.data;
      setServiceAdvisor(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  const getOrderInfo = async (e) => {
    try {
      const req = await getOrderById(orderId);
      const res = await req.data;
      setOrderInfo(res.result);
    } catch (error) {
      // console.log(error);
    }
  };

  //  ----Mobile veiw toggler handle Start-----------------
  // its wrong way to handel buttons
  const handleToggleJobcard = () => {
    document.getElementById("previewButton").style.display = "none";
    document.getElementById("returnToPanleButton").style.display = "block";
  };
  const handleReturnToPanel = () => {
    document.getElementById("previewButton").style.display = "block";
    document.getElementById("returnToPanleButton").style.display = "none";
  };
  const handelMobileVeiwOrderDetails = () => {
    document.getElementById("previewButton").style.display = "block";
    document.getElementById("returnToPanleButton").style.display = "none";
  };

  // -------Upadate Order Status here--------------------

  const UpdateOrderStatus = async () => {
    try {
      const request = await putOrderStatus(orderId, storeStatus, reasonId);
      const response = await request.data;
      //  Send Wati masseges ---
      if (response.statusCode === 200) {
        switch (storeStatus) {
          case 2:
            InspectionOrderStatusWATI(
              customerName,
              vehicleType,
              mobile_number,
              vehicle
            );
            break;
          case 4:
            ApprovedOrderStatusWATI(
              customerName,
              vehicleType,
              mobile_number,
              vehicle,
              services
            );
            break;
          case 6:
            // CompleteOrderStatusWATI(customerName, vehicleType, mobile_number);
            CustomerFeedback(customerName, vehicleType, mobile_number);

            break;

          case 5:
            DeliveredOrderStatusWATI(customerName, vehicleType, mobile_number);
          default:
            break;
        }
      }
      handleAlertOpen(response?.message, colors?.success);
    } catch (error) {
      // console.log(error);
      handleAlertOpen(error?.message, colors?.error);
    }
  };

  // ----------------------------------------------

  const removeInsuranceServices = async (bookingId) => {
    try {
      const request = await deleteService(bookingId);
      const response = await request.data;
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // -----------------Save JobCard ----------------------------------------

  const handleSaveJobCard = async () => {
    isUpdateUser && (await updateUserInfo());
    await UpdateJobcard();

    insuranceDetails?.odometer && (await UpdateOdometer());

    // Delete insurance services
    deleteInsuranceServices?.length > 0 &&
      deleteInsuranceServices?.map((id) => removeInsuranceServices(id));

    Navigate("/orders/general");
  };

  const updateUserInfo = async () => {
    try {
      const request = await putUserName(updateUserName);
      const response = await request.data;
      handleAlertOpen(response?.message, colors?.success);
    } catch (err) {
      handleAlertOpen(err?.message, colors?.error);
    }
  };

  const UpdateJobcard = async () => {
    try {
      const request = await putOrder(updateObj);
      const response = await request.data;
      handleAlertOpen(response?.message, colors?.success);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors?.error);
    }
  };

  const UpdateOdometer = async () => {
    const reading = insuranceDetails?.odometer;
    try {
      const request = PatchOdometerReading(orderId, reading);
      const response = request.data;
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // ------ end -----------------

  // ------ send payment Link------------
  const hamdelSendPaymentLink = () => {
    let url = `https://nowd66x0h6.execute-api.us-east-1.amazonaws.com/default/rzp_link?mobilenumber=${mobile_number}&amount=${OrderInfo?.totalPrice}`;
    fetch(url, {
      mode: "no-cors",
    });
    handleAlertOpen("Peyment Link Sent successfully.", colors.success);
  };
  //  --- end -------------------

  useEffect(() => {
    setGarage(OrderInfo?.garage?.garageName);
  }, [OrderInfo?.garage]);

  useEffect(() => {
    setStoreStatus(OrderInfo?.orderStatus?.statusId);
  }, [OrderInfo?.orderStatus]);

  const Gativangarage = OrderInfo?.garage && OrderInfo?.garage?.garageName;
  const GatiOrderStatus =
    OrderInfo?.orderStatus && OrderInfo?.orderStatus?.status;

  useMemo(() => {
    GatiOrderStatus && setstatus(GatiOrderStatus);
  }, [GatiOrderStatus]);

  useMemo(() => {
    Gativangarage && setGarage(Gativangarage);
  }, [Gativangarage]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 768) {
      handelMobileVeiwOrderDetails();
    }
  }, []);
  useEffect(() => {
    setIsLoding(false);

    setServicesList(OrderInfo?.services);
    console.log("OrderInfo", OrderInfo);
  }, [OrderInfo]);

  useEffect(() => {
    getGarageNames();
    getServiceAdvisor();
    getOrderStatus();
  }, []);

  // Current URL stored to localstorage refresh order's page when we go back to order from order details page.
  useEffect(() => {
    const pathname = window.location.pathname;
    const path = pathname.slice(0, pathname.indexOf("/O-"));
    localStorage.setItem("pageUrl", JSON.stringify(path));
  }, []);

  const ContextObject = {
    OrderInfo,
    updateObj,
    setUpdateObj,
    updateUserName,
    setUpdateUserName,
    orderId,
    selectedMaterials,
    setSelectedMaterials,
    selectedServises,
    setSelectedServises,
    saveSelectedServises,
    setSaveSelectedServises,
    removeSaveServices,
    setRemoveSaveServices,
    nonCatalogueSaveServices,
    setNonCatalogueSaveServices,
    removeSaveNonCatalogueServices,
    setRemoveSaveNonCatalogueServices,
    nonCatalogueService,
    setNonCatalogueService,
    setIsUpdateUser,
    setIsUpdateUser,
    nonCatalogueEstimate,
    setNonCatalogueEstimate,
    savedNonCatEstimate,
    setSavedNonCatEstimate,
    estimateDiscount,
    setEstimateDiscount,
    insuranceDetails,
    setInsuranceDetails,
    deleteInsuranceServices,
    setDeleteInsuranceServices,
    servicesList,
    setServicesList,
  };

  useMemo(() => {
    orderId && getOrderInfo();
  }, [orderId]);

  useEffect(() => {
    console.log("insuranceDetails", insuranceDetails);
  }, [insuranceDetails]);

  useEffect(() => {
    setInsuranceDetails({
      codeOfInsuranceId: 1,
      insuranceType: "Cash",
    });
  }, []);

  useMemo(() => {
    setNonCatalogueEstimate(OrderInfo?.nonCatalogueJobs);
    setEstimateDiscount(OrderInfo?.discount);
    setInsuranceDetails({
      ...insuranceDetails,
      // codeOfInsuranceId: OrderInfo?.codeOfInsurance?.id,
      codeOfInsuranceId:
        OrderInfo?.codeOfInsurance?.id || insuranceDetails?.codeOfInsuranceId,
      claimNo: OrderInfo?.claimNo,
      policyNo: OrderInfo?.policyNo,
      policyType: OrderInfo?.policyType,
      company: OrderInfo?.insuranceCompany,
      insuranceType: OrderInfo?.insuranceType,
      odometer: OrderInfo?.odometerReading,
    });
  }, [OrderInfo?.nonCatalogueJobs]);

  //  saved service advisore set to Advisore state
  useMemo(() => {
    const last_name = OrderInfo?.serviceAdvisor?.last_name;
    const firstName = OrderInfo?.serviceAdvisor?.first_name;
    let Advisor =
      OrderInfo?.serviceAdvisor != null
        ? firstName + " " + last_name
        : "Service Advisor";
    setAdvisor(Advisor);
  }, [OrderInfo?.serviceAdvisor]);

  // set all user properties sent UserObj object
  const UserObj = {
    userId: OrderInfo?.user?.user_id,
    firstName: OrderInfo?.user?.first_name,
    lastName: OrderInfo?.user?.last_name,
    email: OrderInfo?.user?.email,
    userName: OrderInfo?.user?.user_name,
    gstn: OrderInfo?.user?.gstn,
    role: OrderInfo?.user?.role,
  };

  //set UserObjobject in updateUserName state
  useMemo(() => {
    setUpdateUserName(UserObj);
  }, [OrderInfo]);

  useMemo(() => {
    setTimeout(() => {
      setUpdateObj({
        ...updateObj,
        orderId: OrderInfo?.orderId,
        address: OrderInfo?.address,
        codeOfInsuranceId: insuranceDetails?.codeOfInsuranceId,
        claimNo: insuranceDetails?.claimNo,
        policyNo: insuranceDetails?.policyNo,
        policyTypeId: insuranceDetails?.policyType?.id,
        insuranceCompanyId: insuranceDetails?.company?.id,
        insuranceTypeId: insuranceDetails?.insuranceType?.id,
        paymentId: OrderInfo?.paymentId,
        preferredServiceDate: OrderInfo?.preferredServiceDate,
        preferredServiceTime: OrderInfo?.preferredServiceTime,
        remarks: OrderInfo?.remarks,
        orderTypeId: OrderInfo?.orderType?.id,
        nonCatalogueJobs: [],
        serviceAdvisor: OrderInfo?.serviceAdvisor?.user_id,
        garageId: OrderInfo?.garage?.garageId,
        labourCost: OrderInfo?.labourCost,
        discount: OrderInfo?.discount,
        arrivalDate: OrderInfo?.arrivalDate,
        arrivalTime: OrderInfo?.arrivalTime,
        arrivalModeId: OrderInfo?.arrivalMode?.id,
        servicesList: [],
      });
    }, []);
  }, [OrderInfo, insuranceDetails]);

  return (
    <jobCardContext.Provider value={ContextObject}>
      {isLoding && (
          <LoaderSpinner />
      )}
      {isReasonModal && (
        <ResonModal
          isReasonModal={isReasonModal}
          setIsReasonModal={setIsReasonModal}
          setReasonId={setReasonId}
        />
      )}

      {isArrival && (
        <Form
          heading="Arrival Time"
          closeModal={() => setIsArrival(false)}
          renderForm={renderForm}
          isEdit={true}
          state={updateObj}
          setState={setUpdateObj}
          submit={() => setIsArrival(false)}
        />
      )}

      <Box className="order-detailsContainer">
        <Box className="order-details-leftside" id="previewButton">
          <Box className="control-panel">
            {isInsurance ? (
              <Box className="control-panel-header">
                <Box
                  className="controlpanel-header-buttons"
                  style={insEstimate ? Active : NotActive}
                  id="invoiceButton"
                >
                  <button
                    onClick={handleInsEstimate}
                    className="control-panel-header-btn"
                    style={{
                      color: insEstimate ? "black" : "",
                      fontFremarksamily: insEstimate ? "AvenirDemi" : "",
                      fontSize: insEstimate ? "18px" : "",
                    }}
                  >
                    Estimate
                  </button>
                </Box>
                <Box
                  className="controlpanel-header-buttons"
                  style={insInvoice ? Active : NotActive}
                  id="invoiceButton"
                >
                  <button
                    onClick={handleInsInvoice}
                    className="control-panel-header-btn"
                    style={{
                      color: insInvoice ? "black" : "",
                      fontFamily: insInvoice ? "AvenirDemi" : "",
                      fontSize: insInvoice ? "18px" : "",
                    }}
                  >
                    Invoice
                  </button>
                </Box>
              </Box>
            ) : (
              <Box className="control-panel-header">
                <Box
                  className="controlpanel-header-buttons"
                  style={inspection ? Active : NotActive}
                  id="initiInspButton"
                >
                  <button
                    onClick={handelInialInspection}
                    className="control-panel-header-btn"
                    style={{
                      color: inspection ? "black" : "",
                      fontFamily: inspection ? "AvenirDemi" : "",
                      fontSize: inspection ? "18px" : "",
                    }}
                  >
                    Init Inspection
                  </button>
                </Box>
                <Box
                  className="controlpanel-header-buttons"
                  style={jobcard ? Active : NotActive}
                  id="jobCardButton"
                >
                  <button
                    onClick={handelJobcad}
                    className="control-panel-header-btn"
                    style={{
                      color: jobcard ? "black" : "",
                      fontFamily: jobcard ? "AvenirDemi" : "",
                      fontSize: jobcard ? "18px" : "",
                    }}
                  >
                    Job Card
                  </button>
                </Box>
                <Box
                  className="controlpanel-header-buttons"
                  style={finalInspection ? Active : NotActive}
                  id="finalInspButton"
                >
                  <button
                    onClick={handelFinalInspection}
                    className="control-panel-header-btn"
                    style={{
                      color: finalInspection ? "black" : "",
                      fontFamily: finalInspection ? "AvenirDemi" : "",
                      fontSize: finalInspection ? "18px" : "",
                    }}
                  >
                    Final Inspection
                  </button>
                </Box>
                <Box
                  className="controlpanel-header-buttons"
                  style={invoice ? Active : NotActive}
                  id="invoiceButton"
                >
                  <button
                    onClick={handelInvoice}
                    className="control-panel-header-btn"
                    style={{
                      color: invoice ? "black" : "",
                      fontFamily: invoice ? "AvenirDemi" : "",
                      fontSize: invoice ? "18px" : "",
                    }}
                  >
                    Invoice
                  </button>
                </Box>
              </Box>
            )}

            <Box
              position="relative"
              // style={
              //   isServiceAdvisorLoggedIn
              //     ? disableEvents
              //     : jobcard || insEstimate
              //     ? ControlPanelActive
              //     : ControlPanelDisabel
              // }

              style={
                jobcard || insEstimate
                  ? ControlPanelActive
                  : ControlPanelDisabel
              }
              id="controlPanel"
            >
              {isInsurance && (
                <InsControlPanel
                  isServiceAdvisor={
                    isServiceAdvisorLoggedIn
                      ? ControlPanelDisabel
                      : ControlPanelActive
                  }
                />
              )}

              {type === "general" && (
                <ControlPanel
                  isServiceAdvisor={
                    isServiceAdvisorLoggedIn
                      ? ControlPanelDisabel
                      : ControlPanelActive
                  }
                />
              )}
            </Box>
          </Box>

          <Box className="leftside-bottum">
            <Box
              className="buttonsContainer"
              // sx={isServiceAdvisorLoggedIn ? disableEvents : {}}
            >
              <Box
                className="garageAndRepresentative"
                sx={isServiceAdvisorLoggedIn ? disableEvents : {}}
              >
                <Select
                  className="gativanRepresentative"
                  value={Advisor && Advisor}
                  onChange={(e) => {
                    setAdvisor(e.target.value);
                  }}
                >
                  <MenuItem
                    style={{ display: "block", paddingLeft: "5px" }}
                    value="Service Advisor"
                    disabled
                  >
                    Service Advisor
                  </MenuItem>

                  {serviceAdvisor?.map((list, index) => {
                    const { id, firstName, lastName } = list;
                    const Advisor = `${firstName} ${lastName}`;

                    return (
                      <MenuItem
                        key={index}
                        style={{ display: "block", paddingLeft: "5px" }}
                        value={Advisor}
                        onClick={() => {
                          setUpdateObj({
                            ...updateObj,
                            serviceAdvisor: id,
                          });
                        }}
                      >
                        {Advisor}
                      </MenuItem>
                    );
                  })}
                </Select>

                <Select
                  className="gativanGarage"
                  value={Garage ? Garage : "Select Gativan Garage"}
                  onChange={(e) => {
                    setGarage(e.target.value);
                  }}
                >
                  <MenuItem
                    style={{ display: "block", paddingLeft: "5px" }}
                    value="Select Gativan Garage"
                    disabled
                  >
                    Select Garage
                  </MenuItem>
                  {gativanGarages &&
                    gativanGarages.map((list, index) => {
                      return (
                        <MenuItem
                          key={index}
                          style={{ display: "block", paddingLeft: "5px" }}
                          value={list?.garageName}
                          onClick={(e) => {
                            setUpdateObj({
                              ...updateObj,
                              garageId: list.garageId,
                            });
                          }}
                        >
                          {list?.garageName}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Button
                  className="snd-payment-buttons"
                  onClick={() => setIsArrival(true)}
                >
                  Arrival Time
                </Button>
              </Box>
              <Box
                className="button1"
                sx={isServiceAdvisorLoggedIn ? disableEvents : {}}
              >
                <Select
                  className="select-status-button"
                  value={status}
                  onChange={(e) => setstatus(e.target.value)}
                >
                  <MenuItem
                    style={{ display: "block", paddingLeft: "5px" }}
                    value="Select Status"
                    disabled
                  >
                    Select Status
                  </MenuItem>

                  {orderStatusList.map((item) => {
                    const { statusId, status } = item;
                    return (
                      <MenuItem
                        style={{ display: "block", paddingLeft: "5px" }}
                        key={statusId}
                        value={status}
                        onClick={() => {
                          setStoreStatus(statusId);
                          status === "Lost" && setIsReasonModal(true);
                        }}
                      >
                        {status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box
                className="button2"
                style={{ backgroundColor: "red", position: "relative" }}
              >
                <PDFDownloadLink
                  document={
                    invoice ? (
                      <InvoicePDF
                        orderId={orderId}
                        img={vehicleImage}
                        vehicleDetails={vehicle}
                        saRemark={OrderInfo?.saRemarks}
                      />
                    ) : insEstimate ? (
                      <EstimatePDF
                        orderId={orderId}
                        header="ESTIMATE"
                        servicesList={servicesList}
                      />
                    ) : insInvoice ? (
                      <EstimatePDF orderId={orderId} header="TAX INVOICE" />
                    ) : (
                      <MyDocument />
                    )
                  }
                  fileName={`${customerName}.pdf`}
                >
                  {({ blob, url, loading, error }) => (
                    <Button className="download-buttons">
                      {loading ? "Loading document..." : "Download Pdf"}
                    </Button>
                  )}
                </PDFDownloadLink>
              </Box>
              <Box className="button3">
                <Button
                  className="saveJobCard-buttons"
                  onClick={handleSaveJobCard}
                >
                  Save
                </Button>
              </Box>
              <Box
                className="button4"
                sx={isServiceAdvisorLoggedIn ? disableEvents : {}}
              >
                <Button className="update-buttons" onClick={UpdateOrderStatus}>
                  Update Status
                </Button>
              </Box>
              <Box className="button5">
                <Button
                  className="snd-payment-buttons"
                  onClick={hamdelSendPaymentLink}
                >
                  Send Payment Link
                </Button>
              </Box>
              <Box className="button6">
                {shareJobCard ? (
                  <Button
                    className="snd-order-buttons"
                    onClick={ShareJobcarToWati}
                  >
                    <ReplyIcon className="replyIcon" /> Share
                  </Button>
                ) : (
                  <Button className="snd-order-buttons">Loding...</Button>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            className="previewButton"
            style={isServiceAdvisorLoggedIn ? disableEvents : {}}
          >
            <Button onClick={handleToggleJobcard} className="pButton">
              Preview <VisibilityOffIcon className="pButton-icon" />
            </Button>
          </Box>
        </Box>

        {/* ---------------------------------------------------------------- */}

        <Box className="order-details-rightside" id="returnToPanleButton">
          <Box
            id="download"
            bgcolor="#122164"
            style={
              isServiceAdvisorLoggedIn ? { ...disableEvents, opacity: 1 } : {}
            }
          >
            {type === "general" && !invoice && <UserInfoSection />}
            {jobcard && <JobCard saRemark={OrderInfo?.saRemarks} />}
            {invoice && (
              <Invoice saRemark={OrderInfo?.saRemarks} name="hello" />
            )}
            {inspection && <Test />}
            {finalInspection && <FinalInspection />}
            {insInvoice && <InsuranceInvoice saRemark={OrderInfo?.saRemarks} />}
            {insEstimate && <Estimate saRemark={OrderInfo?.saRemarks} />}
          </Box>

          <Box className="mobileView-Buttons">
            <Button onClick={handleReturnToPanel} className="ReturnButton">
              <ArrowBackIosIcon className="ReturnbackButton" />
              <p>Return To Panel</p>
            </Button>
            <PDFDownloadLink
              document={
                invoice ? (
                  <InvoicePDF
                    orderId={orderId}
                    img={vehicleImage}
                    vehicleDetails={vehicle}
                    saRemark={OrderInfo?.saRemarks}
                  />
                ) : (
                  <MyDocument />
                )
              }
              fileName={`${customerName}.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <Button className="download-buttons">
                  {loading ? "Loading document..." : "Download Pdf"}
                </Button>
              )}
            </PDFDownloadLink>
            <Button
              className="saveJobCard-buttons-mobile"
              onClick={handleSaveJobCard}
            >
              Save Job Card
            </Button>
          </Box>
        </Box>
      </Box>
    </jobCardContext.Provider>
  );
}
