import React, { useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IssueDetails from "./IssueDetails";
import IssueTickets from "../../components/grids/IssueTickets";
import ModuleHeader from "../../components/headers/ModuleHeader";

const useStyles = makeStyles((theme) => ({
  customerName: {
    fontSize: "1.3rem",
  },
  ticketNumber: {
    fontSize: "0.9rem !important",
  },
  createdDate: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  vehicle: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  brandModel: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  checkbox: {
    transform: "scale(1.4)",
    color: "#122164 !important",
  },
  subHeading: {
    color: "#707070",
  },
  subValues: {
    fontSize: "0.8rem",
    color: "#000",
  },
  edit: {
    display: "flex",
    alignItems: "center",
    color: "#000",
    "& span": {
      color: "#000",
      fontWeight: "600 !important",
    },
  },
  sections: {
    padding: "1rem",
  },
  customerIssueContainer: {
    width: "100%",
    margin: "0.5rem 0px 1rem 0px",
    minHeight: "100px",
  },
  customerIssue: {
    fontSize: "1rem !important",
  },
  viewDetails: {
    width: "100%",
    backgroundColor: "#122164 !important",
  },
  statusCustomerOwnerContainer: {
    marginTop: "0.5rem !important",
  },
  statusCustomerOwner: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    backgroundColor: "inherit",
    border: "1px solid black",
    textAlign: "center",
    fontSize: "1.2rem",
  },
  toolbarStyle: {
    minHeight: "2rem !important",
    height: "2rem",
  },
}));

const statusList = ["Open", "Close", "In Progress", "Solved", "Not Solved"];
const customerOwnersList = ["Ganesh", "Test1", "Test2", "Test3"];

const CustomerServiceGridView = ({
  heading,
  downloadXLS,
  data,
  setTableView,
  handleOpenDatePicker,
  filteredDate,
  setIsIssueAdded,
  openCustomerIssueTrackerDetails,
  setOpenCustomerIssueTrackerDetails,
  getTicketNumber,
  setIsEdit
}) => {
  const classes = useStyles();
  const localClasses = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [status, setStatus] = useState("");
  const [customerOwner, setCustomerOwner] = useState("");
  
  // const [sendData, setSendData] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name == "status") {
      setStatus(value);
    } else {
      setCustomerOwner(value);
    }
  };

  return (
    <>
      <Box
        sx={{ display: "flex", marginTop: "-15px" }}
        className={localClasses.tableWrapper}
      >
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        ></Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: isMobile ? 2 : 4,
            mb: 2,
            width: { sm: "100%" },
            backgroundColor: "#e5e5e5",
          }}
        >
          <Toolbar className={classes.toolbarStyle} />
          <Box>
            <ModuleHeader
              heading={heading}
              downloadXLS={downloadXLS}
              setTableView={setTableView}
              handleOpenDatePicker={handleOpenDatePicker}
              filteredDate={filteredDate}
            />
          </Box>
          <Toolbar className={classes.toolbarStyle} />
          <Box className={localClasses.dataGridWrapper}>
            <Grid container spacing={3}>
              {data &&
                data.map((lead) => (
                  <>
                    <IssueTickets
                      lead={lead}
                      setOpenCustomerIssueTrackerDetails={
                        setOpenCustomerIssueTrackerDetails
                      }
                      setIsIssueAdded={setIsIssueAdded}
                      setIsEdit={setIsEdit}
                      getTicketNumber={getTicketNumber}
                    />
                  </>
                ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomerServiceGridView;