const data = {
  years: [],
  selectedYear: null,
  selectedYearsMonths: [],
  importedExcelData: [],
};
export const ABPReducer = (state = data, action) => {
  switch (action.type) {
    case "SET_ABP_YEARS":
      return {
        ...state,
        years: action.payload,
      };

    case "SET_SELECTED_YEAR_DATA":
      return {
        ...state,
        selectedYearsMonths: action.payload,
      };

    case "SET_SELECTED_YEAR":
      return {
        ...state,
        selectedYear: action.payload,
      };

      case "IMPORT_EXCEL":
        return {
          ...state,
          importedExcelData: action.payload,
        };

    default:
      return state;
  }
};
