
const initialstate={ 
}

export const AddInspectionData=(state=initialstate,action)=>{
 
    switch (action.type) {
        case "SelectInspection":
            
        return state=action.payload
            break;
    
        default:
            return state
            break;
    }
}