import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Divider from "@mui/material/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  getGarageBankAccountDetails,
  getGarageUPIAccountDetails,
} from "../../services/garageServices/GarageServices";

import "./AddGarage.css";
import { Button, Grid, TextField } from "@mui/material";
import {
  addGarageBankAccountDetails,
  addGarageUpiAccount,
} from "../../services/garageServices/GarageServices";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "100%",
  },
  gridRow: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  headingWrapper: {
    margin: "1rem 0px",
  },
  error: {
    color: "red",
  },
}));

const PaymentInfo = ({ garageId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
  });
  const [bankDetailsError, setBankDetailsErrors] = useState({});
  const [upiErrors, setUpiErrors] = useState({});
  const [upiAccountDetails, setUpiAccountDetails] = useState({ upiId: "" });
  const [accountDetails, setAccountDetails] = useState([]);
  const [currentGarageDetails, setCurrentGarageDetails] = useState({});

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);

  const classes = useStyles();

  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const getBankAccountDetials = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
    };
    try {
      const response = await getGarageBankAccountDetails(garageId, config);
      const data = await response.data;
      console.log("bank", data.result);

      if (data.result != undefined) {
        const { accountHolderName, accountNumber, ifscCode } = data.result[0];
        setBankDetails({
          accountHolderName: accountHolderName,
          accountNumber: accountNumber,
          ifscCode: ifscCode,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUpiAccountDetials = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
    };
    try {
      const response = await getGarageUPIAccountDetails(garageId, config);
      const data = await response.data;
      if (data.result != undefined) {
        const { upiId } = data.result[0];
        setUpiAccountDetails({
          upiId: upiId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBankAccountDetials();
    getUpiAccountDetials();
  }, []);

  useEffect(() => {
    const data = accountDetails.find(
      (account) => account?.garageId == garageId
    );
    setCurrentGarageDetails(data);
  }, [accountDetails]);

  useEffect(() => {
    console.log("data", currentGarageDetails);
    // setBankDetails({});
    // setBankDetails({
    //   accountHolderName: "",
    //   accountNumber: "",
    //   ifscCode: "",
    // })
  }, [currentGarageDetails]);

  const addBankDetails = () => {
    const handleBankDetails = (e) => {
      const { name, value } = e.target;
      setBankDetails({ ...bankDetails, [name]: value });
    };

    const saveGarageBankAccount = async (bankDetails, config) => {
      try {
        const response = await addGarageBankAccountDetails(bankDetails, config);
        const data = await response.data;
        console.log("Bank", response);
        handleAlertOpen(data?.message, colors?.success);
        // navigate("/garageDatabase");
        getBankAccountDetials();
        setBankDetails({});
      } catch (error) {
        handleAlertOpen(error?.response?.data?.message, colors?.error);
        console.log(error);
      }
    };

    const saveBankDetails = () => {
      const config = {
        headers: {
          Authorization: `Bearer ${localData.jwtToken}`,
        },
      };

      const postObj = {
        garageId: garageId,
        ...bankDetails,
      };
      if (bankDetails?.accountHolderName == "") {
        setBankDetailsErrors({
          bankDetailsError,
          accountHolderName: "Please Enter Account Holder Name.",
        });
      } else if (bankDetails?.accountNumber == "") {
        setBankDetailsErrors({
          bankDetailsError,
          accountNumber: "Please Enter Account Number.",
        });
      } else if (bankDetails?.ifscCode == "") {
        setBankDetailsErrors({
          bankDetailsError,
          ifscCode: "Please Enter IFSC Code.",
        });
      } else {
        saveGarageBankAccount(postObj, config);
      }
    };

    const handleBankDetailsFocus = (e) => {
      console.log("e.target.name", e.target.name);
      if (e.target.name == "accountHolderName") {
        setBankDetailsErrors({
          ...bankDetailsError,
          accountHolderName: "",
        });
      } else if (e.target.name == "accountNumber") {
        setBankDetailsErrors({
          ...bankDetailsError,
          accountNumber: "",
        });
      } else if (e.target.name == "ifscCode") {
        setBankDetailsErrors({
          ...bankDetailsError,
          ifscCode: "",
        });
      } else {
        return false;
      }
    };

    const discardBankDetails = () => {
      setBankDetails({
        accountHolderName: "",
        accountNumber: "",
        ifscCode: "",
      });
    };

    return (
      <Box>
        <Grid container className={classes.gridRow} spacing={1}>
          <Grid item xs={4}>
            <Typography className="lables">Account Holder Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              fullWidth
              margin="dense"
              variant="outlined"
              label="Account Holder Name"
              id="accountHolderName"
              name="accountHolderName"
              onChange={handleBankDetails}
              onFocus={handleBankDetailsFocus}
              value={bankDetails.accountHolderName}
            />
            {bankDetailsError?.accountHolderName && (
              <Typography className={classes.error}>
                {bankDetailsError?.accountHolderName}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.gridRow} spacing={1}>
          <Grid item xs={4}>
            <Typography className="lables">Account Number</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              fullWidth
              margin="dense"
              variant="outlined"
              label="Account NUmber"
              id="accountNumber"
              name="accountNumber"
              onChange={handleBankDetails}
              onFocus={handleBankDetailsFocus}
              value={bankDetails.accountNumber}
            />
            {bankDetailsError?.accountNumber && (
              <Typography className={classes.error}>
                {bankDetailsError?.accountNumber}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.gridRow} spacing={1}>
          <Grid item xs={4}>
            <Typography className="lables">IFSC Code</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              fullWidth
              margin="dense"
              variant="outlined"
              label="IFSC Code"
              id="ifscCode"
              name="ifscCode"
              onChange={handleBankDetails}
              onFocus={handleBankDetailsFocus}
              value={bankDetails.ifscCode}
            />
            {bankDetailsError?.ifscCode && (
              <Typography className={classes.error}>
                {bankDetailsError?.ifscCode}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4}></Grid>
          <Grid item xs={8} sm={6}>
            <div className="buttonGroup">
              <Button
                variant="contained"
                size="small"
                className="buttons"
                onClick={discardBankDetails}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                size="small"
                className="buttons"
                style={{ marginLeft: "1rem" }}
                onClick={saveBankDetails}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const addUpiAccountDetails = () => {
    const handleUpiAccountDetails = (e) => {
      const { name, value } = e.target;

      setUpiAccountDetails({ ...upiAccountDetails, [name]: value });
    };

    const saveUpiId = async (postObj) => {
      const config = {
        headers: {
          Authorization: `Bearer ${localData.jwtToken}`,
        },
      };

      try {
        const response = await addGarageUpiAccount(postObj, config);
        const data = await response.data;
        handleAlertOpen(data?.message, colors?.success);
        getUpiAccountDetials();
        // navigate("/garageDatabase");
      } catch (error) {
        handleAlertOpen(error?.message, colors?.error);
        console.log(error);
      }
    };
    const handleUpiFocus = (e) => {
      if (e.target.name == "upiId") {
        setUpiErrors({
          ...upiErrors,
          upiId: "",
        });
      }
    };

    const saveUpiDetails = () => {
      const postObj = {
        garageId: garageId,
        ...upiAccountDetails,
      };

      if (upiAccountDetails?.upiId == "") {
        setUpiErrors({ ...upiErrors, upiId: "Please Enter UPI ID." });
      } else {
        saveUpiId(postObj);
      }
    };

    const discardUpiAccountDetails = () => {
      setUpiAccountDetails({
        upiId: "",
      });
    };

    return (
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">UPI ID</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="UPI ID"
                id="upiId"
                name="upiId"
                onChange={handleUpiAccountDetails}
                onFocus={handleUpiFocus}
                value={upiAccountDetails.upiId}
              />
              {upiErrors.upiId && (
                <Typography className={classes.error}>
                  {upiErrors.upiId}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4}></Grid>
          <Grid item xs={8} sm={6}>
            <div className="buttonGroup">
              <Button
                variant="contained"
                size="small"
                className="buttons"
                onClick={discardUpiAccountDetails}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                size="small"
                className="buttons"
                style={{ marginLeft: "1rem" }}
                onClick={saveUpiDetails}
              >
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const paymentOptions = [
    { title: "Bank Transfer (Manual Process)", form: addBankDetails() },
    { title: "UPI Account", form: addUpiAccountDetails() },
  ];

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        {/* <Box className="expandIconWrapper">
          <CircleIcon disabled sx={{ color: "black" }} />
        </Box>
        <Box className="collapsIconWrapper">
          <CheckCircleIcon disabled sx={{ color: "green" }} />
        </Box> */}
        <Box className="expandIconWrapper">
          <RemoveCircle style={{ color: "red" }} />
        </Box>
        <Box className="collapsIconWrapper">
          <AddCircle style={{ color: "green" }} />
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.headingWrapper}>
        <h2 className={classes.title}>
          How would you like to pay this Garage?
        </h2>
      </Box>
      <Box>
        {paymentOptions?.map((options, index) => {
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <CustomExpandIcon />
                  // <CheckCircleIcon disabled sx={{ color: "green" }} />
                }
                // expandIcon={<CircleIcon disabled sx={{ color: "black" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{
                  height: "100px",
                  // backgroundColor: "#e3e3e3",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  margin: "0.2rem 0px",
                }}
              >
                <Typography>{options.title}</Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails
                sx={{
                  // backgroundColor: "#e3e3e3",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                {options.form}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default PaymentInfo;
