import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Input, Typography } from "@mui/material";
import "./InspectionReport.css";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { SelectInspection } from "../../../action";
import { useLocation } from "react-router-dom";
import httpCommon from "../../../http-common";
import { getInitInspection } from "../../../services/inspections/Inspection";
import UserInfoSection from "../reusableComponents/UserInfoSection"; 

export default function InspectionReport() {
  const [showInput, setShowInput] = useState({ checked: false, ele: "" }); // handel checked icon and problem icon
  const [InspectionData, setInspectionData] = useState([]); //handel which inspection show depend on vehicletype
  const ProblemObj = {
    order: { id: "" },
    heading: "",
    remarks: " ",
  };
  const [Problem, setProblem] = useState(ProblemObj);
  const [allproblems, setAllProblems] = useState([]); //all inspection and theres problem store here
  const [ShowInspection, setShowInspection] = useState([]);

  const URL = httpCommon.defaults.baseURL; // base url

  const CustomerData = useLocation().state;

  const dispatch = useDispatch();

  const ServiceId = CustomerData?.id; // finde service id is need to post Object only
 const vehicleType = CustomerData?.vehicleType?.id;

  const bikeInspectionData = [
    {
      id: 1,
      name: "Ignition",
      activity: ["Button Start", "Kick Start"],
    },
    {
      id: 2,
      name: "Engine",
      activity: [
        "Engine Oil  ",
        "Engine Noise ",
        "Engine Oil Leakage",
        "Engine Operation",
        "Smoke Condition",
      ],
    },
    {
      id: 3,
      name: "Transmission",
      activity: [
        "Accelerator Play",
        "Jerk While Acceleration",
        "Accelerator Cable",
        "Chain Noise",
        "Gear Shifting",
      ],
    },
    {
      id: 4,
      name: "Braking",
      activity: ["Braking Operations", " Brake Oil Lever"],
    },
    {
      id: 5,
      name: "Suspension",
      activity: [
        "Comfort Jerk and Vibration Check",
        "Front Fork Out",
        "Rear Shock Absorber",
      ],
    },
    {
      id: 6,
      name: "Chassis & Wheels",
      activity: [
        "Bike Frame and Joints",
        "Petrol Leakage",
        "Tyre Pressure",
        "Tyre Wear and Tear",
        "Wheel Rim Dent and Cracks",
        "Wheel & Tyre",
      ],
    },
    {
      id: 7,
      name: "Electronics",
      activity: [
        "Lighting",
        "Indicators and Buzzers",
        "Battery Voltage Level",
        "Horn",
        "Speedomete",
      ],
    },
  ];
  const carInspectionData = [
    {
      id: 1,
      name: "Ignition",
      activity: ["Car Starting", "Remote Key"],
    },
    {
      id: 2,
      name: "Engine",
      activity: [
        "Engine Oil Level",
        "Engine Oil Leakage",
        "Engine Noise Level",
        "Engine Mounting",
        "Coolant Level",
      ],
    },
    {
      id: 3,
      name: "Transmission",
      activity: [
        "Acceleration Operation",
        "Gear Shifting",
        "Clutch Operation",
        "Steering Operation",
      ],
    },
    {
      id: 4,
      name: "Braking",
      activity: [
        "Braking Operations",
        "Brake Pad Thickness",
        "Brake Disc Wear and Tear",
      ],
    },
    {
      id: 5,
      name: "Suspension",
      activity: [
        "Suspension Comfort",
        "Jerks and Vibration",
        "Shock Absorber Leakage",
      ],
    },
    {
      id: 6,
      name: "Chassis & Wheels",
      activity: [
        "Car Frame and Body",
        "Tyre Pressure",
        "Tyre Wear and Tear",
        "Wheel Dent and Cracks",
      ],
    },
    {
      id: 7,
      name: "Electronics",
      activity: [
        "Lighting",
        "Indicators Buzzers and Horn",
        "Battery Voltage Level",
        "Window and Wipers",
      ],
    },
    {
      id: 8,
      name: "Air Conditioning",
      activity: ["AC Cooling and Heating", "Rear Defogger"],
    },
  ];

  //inspection Data dievide into 2 parts InspectionData1 and InspectionData2 show UI
  const length = InspectionData.length / 2;
  const InspectionData1 = InspectionData.slice(0, length);
  const InspectionData2 = InspectionData.slice(length);

  // -----Get Inspection -----------------------------

  const getInspectionInfo = async () => {
    try {
      const request = await getInitInspection(ServiceId);
      const response = await request.data;
      setShowInspection(response.result);
    } catch (error) {
      // console.log(error);
    }
  };

  // if the vehicle Type "bilke" then show bike inspection and type "car" that time show car ispection
  useEffect(() => {
    // getInspectionInfo();
    vehicleType == "1"
      ? setInspectionData(carInspectionData)
      : setInspectionData(bikeInspectionData);
  }, []);

  const handelSubmitInput = () => {
    setAllProblems([...allproblems, Problem]);
    document.getElementById("input").style.display = "none";
  };

  const handelShowInput = (e, checked) => {
    setShowInput({ checked: checked, ele: e });
  };

  //------ store inserted problem inside a problem useState
  const handelChangeInputFields = (e) => {
    setProblem({
      order: {
        id: ServiceId,
      },
      heading: e.target.name,
      remarks: e.target.value,
    });
  };
  useEffect(() => {
    setAllProblems(ShowInspection);
  }, [ShowInspection]);

  //  ------ inspections report Store Array using Conditions ---------------
  useMemo(() => {
    let temp = [];
    showInput.checked === false &&
      allproblems &&
      allproblems.map((item) => {
        if (showInput.ele != item.heading) {
          // if inspection heading is not match the push temp
          temp.push(item);
          setAllProblems([...temp]);
        }
        //temp length is less then 1 that time update empty array
        temp.length <= 0 && setAllProblems(temp);
      });
  }, [showInput]);

  //-----------------inspections end-------------------------------------------------

  useMemo(() => {
    dispatch(SelectInspection(allproblems));
  }, [allproblems]);

  return (
    <Box className="initInspectionContainer">
     <Box className="inspectionActivites">

        <Box className="activitySection">
          {InspectionData1 &&
            InspectionData1.map((item) => {
              const { id, name, activity } = item;
              return (
                <Box key={id}>
                  <Typography className="inspectionName">{name}</Typography>

                  <Box className="activityContainer">
                    {activity.map((ele, index) => {
                      return (
                        <Box key={index}>
                          <Box className="activites">
                            <Typography className="activityName">
                              {ele}
                            </Typography>

                            <Checkbox
                              className="CheckBoxIcon"
                              checked={
                                allproblems.find(
                                  (problem) => ele === problem.heading
                                )
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handelShowInput(ele, e.target.checked);
                              }}
                              name={name}
                              value={ele}
                              icon={<CheckCircleIcon className="checkedIcon" />}
                              checkedIcon={<ErrorIcon className="checkIcon" />}
                            />
                          </Box>
                          {showInput.checked && showInput.ele === ele && (
                            <Box id="input">
                              <textarea
                                className="textArea"
                                rows="1"
                                cols=""
                                onChange={handelChangeInputFields}
                                name={ele}
                                placeholder="Enter Problem"
                              ></textarea>
                              <CheckIcon
                                className="submitData"
                                onClick={handelSubmitInput}
                              />
                            </Box>
                          )}
                          <span className="showProblems">
                            {allproblems &&
                              allproblems.map((list, index) => {
                                if (list.heading === ele) {
                                  return <p key={index}>{list.remarks}</p>;
                                }
                              })}
                          </span>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
        </Box>

        <Box className="activitySection">
          {InspectionData2 &&
            InspectionData2.map((item) => {
              const { id, name, activity } = item;
              return (
                <Box key={id}>
                  <Typography className="inspectionName">{name}</Typography>

                  <Box className="activityContainer">
                    {activity.map((ele, index) => {
                      return (
                        <Box key={index}>
                          <Box className="activites" key={index}>
                            <Typography className="activityName">
                              {ele}
                            </Typography>
                            <Checkbox
                              className="CheckBoxIcon"
                              checked={
                                allproblems.find(
                                  (problem) => problem.heading === ele
                                )
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handelShowInput(ele, e.target.checked);
                              }}
                              name={name}
                              value={ele}
                              icon={<CheckCircleIcon className="checkedIcon" />}
                              checkedIcon={<ErrorIcon className="checkIcon" />}
                            />
                          </Box>
                          {showInput.checked && showInput.ele === ele && (
                            <Box id="input">
                              <textarea
                                className="textArea"
                                rows="1"
                                cols=""
                                onChange={handelChangeInputFields}
                                name={ele}
                                placeholder="Enter Problem"
                              ></textarea>
                              <CheckIcon
                                className="submitData"
                                onClick={handelSubmitInput}
                              />
                            </Box>
                          )}
                          <span className="showProblems">
                            {allproblems &&
                              allproblems.map((list, index) => {
                                if (list.heading === ele) {
                                  return <p key={index}>{list.remarks}</p>;
                                }
                              })}
                          </span>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
        </Box>
        
      </Box>
    </Box>
  );
}
