import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  getVehicleType,
  getBrands,
  getModels,
  getVariants,
} from "../../services/leadServices/LeadServices";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  saveLead,
  getLeadChannels,
} from "../../services/leadServices/LeadServices";
import "./AddLead.css";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";
import axios from "axios";
import httpCommon from "../../http-common";

const AddLeads = ({ setIsLeadAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [leadChannels, setLeadChannels] = useState([]);
  const URL = httpCommon.defaults.baseURL;
  const [leadsData, setLeadsData] = useState({});




  // GET CONTEXT COMMON DATA
  const { handleCloseLeads, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const userSelection = (fieldName, name, id) => {
    console.log(fieldName, name, id);

    const obj = {
      name,
      id,
    };
    setLeadsData({ ...leadsData, [fieldName]: obj });
  };

  const postLead = async (postObj) => {
    try {
      const response = await saveLead(postObj);
      const data = await response.data;
      navigate("/leads");
      handleAlertOpen(data?.message, colors?.success);
    } catch (error) {
      console.log(error);
      handleAlertOpen(error?.response?.data?.message, colors?.error);
    }
  };


  const [selectedCity, setSelectedCity] = useState('');




  const [uniqueCities, setUniqueCities] = useState([]);

  useEffect(() => {
    axios.get(`${URL}/city/unique`)
      .then(response => {
        const citiesArray = response.data.result;
        setUniqueCities(citiesArray);
      })
      .catch(error => {
        console.error('Error fetching unique cities:', error);
      });
  }, []); 





// const [uniqueCitiesId, setUniqueCitiesId] = useState([]);

// useEffect(() => {
//   axios.get(`https://devapi.gativan.in/api/city/${selectedCity}`)
//     .then(response => {
//       // Assuming the response contains an array of city names in data.cities
//       const citiesArray = response.data.result.cityId;
//       setUniqueCitiesId(citiesArray);
//     })
//     .catch(error => {
//       console.error('Error fetching unique cities:', error);
//     });
// }, [selectedCity]); 

// console.log(uniqueCitiesId)


// console.log(selectedCity)


  const addLead = () => {
    const postObj = {
      mobileNumber: leadsData?.mobileNumber,
      vehicleType: leadsData?.vehicleType?.id,
      leadName: `${leadsData?.firstName} ${leadsData?.lastName}`,
      channel: leadsData?.channels?.id,
      brandId: leadsData?.brand?.id,
      modelId: leadsData?.model?.id,
      variant: leadsData?.variant?.id,
      // cityId: 1,
      city:selectedCity
    };

    if (postObj?.brandId && postObj?.modelId && postObj?.variant) {
      postLead(postObj);
      handleCloseLeads();
      // navigate("/leads");
      setIsLeadAdded((prev) => !prev);
    } else {
      handleAlertOpen("Vehicle Details are required.", colors?.error);
    }
  };

  // Get vehicle types
  const getVehicleTypes = async () => {
    try {
      const response = await getVehicleType();
      const result = await response.data;
      setVehicleTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get brands
  const vehicleType = leadsData.vehicleType && leadsData.vehicleType.id;
  const getBrandInfo = async () => {
    try {
      const response = await getBrands(vehicleType);
      const result = await response.data;
      setVehicleBrands(result.result);
    } catch (error) {}
  };
  // Get modals
  const brandId = leadsData.brand && leadsData.brand.id;
  const getVehicleModels = async () => {
    try {
      const response = await getModels(vehicleType, brandId);
      const result = await response.data;
      setVehicleModels(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const modelId = leadsData.model && leadsData.model.id;
  const getVehicleVariants = async () => {
    try {
      const response = await getVariants(modelId);
      const result = await response.data;
      setVehicleVariants(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // Get lead channels
  const getAllLeadChannels = async () => {
    try {
      const response = await getLeadChannels();
      const result = await response.data;
      setLeadChannels(result.result);
    } catch (error) {}
  };

  useEffect(() => {
    getVehicleTypes();
    getAllLeadChannels();
  }, []);

  useEffect(() => {
    setVehicleBrands([]);
    setVehicleModels([]);
    setVehicleVariants([]);
    leadsData.vehicleType && getBrandInfo();
  }, [leadsData.vehicleType]);

  useEffect(() => {
    setVehicleModels([]);
    setVehicleVariants([]);
    leadsData.brand && getVehicleModels();
  }, [leadsData.brand]);

  useEffect(() => {
    setVehicleVariants([]);
    leadsData.model && getVehicleVariants();
  }, [leadsData.model]);

  const renderVehicleTypes = () => {
    return (
      <Grid item sm={8}>
        <ul className="donate-now">
          {vehicleTypes &&
            vehicleTypes.map((types, index) => {
              return (
                <li key={index}>
                  <input
                    type="radio"
                    id={types.vehicleType}
                    name="vehicleType"
                    value={types.id}
                    onClick={() =>
                      userSelection("vehicleType", types.vehicleType, types.id)
                    }
                  />
                  <label for={types.vehicleType}>{types.vehicleType}</label>
                </li>
              );
            })}
        </ul>
      </Grid>
    );
  };

  const renderVehicleBrands = () => {
    return (
      <TextField
        required
        size="small"
        label="Brand"
        fullWidth
        select
        variant="outlined"
        id="brand"
        margin="dense"
        name="brand"
        value={leadsData.brand && leadsData.brand.name}
      >
        {vehicleBrands.map((brand) => (
          <MenuItem
            key={brand.brand_Id}
            value={brand.brand_Id}
            onClick={() => userSelection("brand", brand.brand, brand.brand_Id)}
          >
            {brand.brand}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  // const renderCitiesDropdown = () => {
  //   return (
  //     <TextField
  //     required
  //     size="small"
  //     label="City"
  //     fullWidth
  //     select
  //     variant="outlined"
  //     id="city"
  //     margin="dense"
  //     name="city"
  //     value={leadsData.city && leadsData.city.city}  // Update this line
  //     onChange={(e) => setLeadsData({ ...leadsData, city: e.target.value })}
  //   >
  //     {uniqueCities.map((city) => (
  //       <MenuItem key={city.city} value={city}>
  //         {city.city}
  //       </MenuItem>
  //     ))}
  //   </TextField>
    
  //   );
  // };

  const renderCitiesDropdown = () => {
    const filteredCities = uniqueCities.filter((city) => city.city !== 'Bangalore');
  
    return (
      <TextField
        required
        size="small"
        label="City"
        fullWidth
        select
        variant="outlined"
        id="city"
        margin="dense"
        name="city"
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
      >
        {filteredCities.map((city) => (
          <MenuItem key={city.city} value={city.city}>
            {city.city}
          </MenuItem>
        ))}
      </TextField>
    );
  };
  
  

  

  const renderVehicleModels = () => {
    return (
      <TextField
        required
        size="small"
        label="Model"
        fullWidth
        select
        variant="outlined"
        id="model"
        margin="dense"
        name="model"
        value={leadsData.model && leadsData.model.name}
      >
        {vehicleModels &&
          vehicleModels.map((model) => (
            <MenuItem
              key={model.model_id}
              value={model.model_id}
              onClick={() =>
                userSelection("model", model.model, model.model_id)
              }
            >
              {model.model}
            </MenuItem>
          ))}
      </TextField>
    );
  };

  const renderVehicleVariants = () => {
    return (
      <TextField
        required
        size="small"
        label="Variant"
        fullWidth
        select
        variant="outlined"
        id="variant"
        margin="dense"
        name="variant"
        value={leadsData.variant && leadsData.variant.name}
      >
        {vehicleVariants.map((variant) => (
          <MenuItem
            key={variant.variantId}
            value={variant.variantId}
            onClick={() =>
              userSelection("variant", variant.variantName, variant.variantId)
            }
          >
            {variant.variantName}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderForm = [
    {
      label: "Customer",
      fields: [
        {
          label: "First Name",
          field: "TextField",
          name: "firstName",
          id: "firstName",
          type: "text",
          grids: 6,
        },
        {
          label: "lastName",
          field: "TextField",
          name: "lastName",
          id: "lastName",
          type: "text",
          grids: 6,
        },
      ],
    },
    {
      label: "Vehicle Type",
      fields: [
        {
          label: "Vehicle Type",
          field: "Toggles",
          name: "vehicleType",
          id: "vehicleType",
          data: vehicleTypes,
          properties: { name: "vehicleType", id: "id" },
          type: "text",
          render: renderVehicleTypes,
          grids: 12,
        },
      ],
    },
    {
      label: "Vehicle Name",
      fields: [
        {
          label: "Brand",
          field: "Dropdown",
          name: "brand",
          id: "brand",
          data: vehicleBrands,
          properties: { name: "brand", id: "brand_Id" },
          type: "text",
          render: renderVehicleBrands,
          grids: 6,
        },
        {
          label: "Model",
          field: "Dropdown",
          name: "model",
          id: "model",
          data: vehicleModels,
          properties: { name: "model", id: "model_id" },
          type: "text",
          render: renderVehicleModels,
          grids: 6,
        },
      ],
    },
    {
      label: "Vehicle Variant",
      fields: [
        {
          label: "Variant",
          field: "Dropdown",
          name: "variant",
          id: "variant",
          data: vehicleVariants,
          properties: { name: "variantName", id: "variantId" },
          type: "text",
          render: renderVehicleVariants,
          grids: 6,
        },
      ],
    },
    {
      label: "Contact Number",
      fields: [
        {
          label: "Contact Number",
          field: "TextField",
          name: "mobileNumber",
          id: "mobileNumber",
          type: "number",
          grids: 6,
        },
      ],
    },
    {
      label: "City",
      fields: [
        {
          label: "City",
          field: "Dropdown",
          name: "city",
          id: "city",
          data: uniqueCities,
          type: "text",
          render: renderCitiesDropdown,
          grids: 6,
        },
      ],
    },
    {
      label: "Channels",
      fields: [
        {
          label: "Channels",
          field: "Dropdown",
          name: "channels",
          id: "channels",
          data: leadChannels,
          properties: { name: "channel", id: "id" },
          type: "text",
          grids: 6,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Lead"
        closeModal={handleCloseLeads}
        isAdded={setIsLeadAdded}
        renderForm={renderForm}
        service={saveLead}
        state={leadsData}
        setState={setLeadsData}
        submit={addLead}
        navigate="/leads"
      />
    </div>
  );
};

export default AddLeads;
