import { makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CommonContextAPI } from "../../../App";
import {
  getAllUserRoles,
  updateUserDetailsByAdmin,
  updateUserProfileDetails,
} from "../../../services/userProfileServices/UserProfileServices";
import { getUserProfile } from "../../../services/userProfileServices/UserProfileServices";
import {
  updateUserDetails,
  getUserDetailsById,
} from "../../../services/userProfileServices/UserProfileServices";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "3rem !important",
    margin: "0px !important",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      padding: "0rem !important",
      width: "100%",
    },
  },
  adminActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "1rem  ",
    },
  },
  notificationIcon: {
    fontSize: "2rem",
  },
  helpIcon: {
    fontSize: "2rem",
  },
  homeIcon: {
    fontSize: "2rem",
  },
  profileIcon: {
    fontSize: "4rem",
  },
  subHeadingWrapper: {
    padding: "2rem",
  },
  subHeading: {
    lineHeight: 1.8,
  },
  textField: {
    width: "100%",
    margin: "0.4rem 0",
  },
  userIcon: {
    fontSize: "6rem",
  },

  input: {
    display: "none",
  },
  imageAvatar: {
    color: "#000",
    margin: 10,
    height: "100px",
    width: "100px",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#837c7c",
    width: "140px",
    height: "140px",
    "&:hover": {
      opacity: 0.7,
    },
  },
  saveButton: {
    backgroundColor: "#10bc83",
    "&:hover": {
      backgroundColor: "#10bc83",
    },
  },
  cancelButton: {
    backgroundColor: "#f6f6f6",
    color: "#000",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    },
  },
  selectDropdown: {
    width: "100%",
    padding: "1.1rem",
    marginTop: "0.39rem",
    borderRadius: "5px",
    borderColor: "#b3adad",
    fontSize: "1rem",
    cursor: "pointer",
    backgroundColor: "inherit",
    [theme.breakpoints.down("sm")]: {
      width: "98.1%",
    },
  },
  gridItem: {
    width: "100%",
  },
  buttonWrapper: {
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      // flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

const EditUser = ({ handleEdit, isEditing, uId }) => {
  console.log("handleEdit", isEditing);
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const classes = useStyles();
  const navigate = useNavigate();

  const { userId } = useParams();

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  const { handleAlertOpen, colors } = commonData;
  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const getUserById = async (id) => {
    try {
      const response = await getUserDetailsById(id);
      const data = await response.data;
      setUserDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const [roleChanged, setRoleChanged] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "checkbox") {
      setUserDetails({ ...userDetails, [name]: e.target.checked });
    }

    if (name == "role") {
      const userRolesObj = userRoles[value];

      userRoles.find((role) => {
        if (role.id == userRolesObj.id) {
          setRoleChanged(Number(userRolesObj?.id));
          setUserDetails({
            ...userDetails,
            [name]: {
              id: Number(userRolesObj?.id),
              role: userRolesObj?.role,
            },
          });
        }
      });
      return;
    }

    setUserDetails({ ...userDetails, [name]: value });
  };

  const updateUserDetails = async (data) => {
    data.userId = Number(userId);
    userRoles.find((role) => {
      if (role.role == userDetails?.role) {
        data.role = Number(role?.id);
      }
    });

    if (roleChanged) {
      data.role = roleChanged;
    }
    try {
      const response = await updateUserDetailsByAdmin(data);
      const result = await response.data;
      handleAlertOpen(result.message, colors.success);
    } catch (error) {
      console.log(error);
      handleAlertOpen(error.message, colors.error);
    }
  };

  const updateUserProfile = async (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
    };
    console.log("User", data);
    try {
      const response = await updateUserProfileDetails(data, config);
      const result = await response.data;
      handleAlertOpen(result.message, colors.success);
    } catch (error) {
      console.log(error);
      handleAlertOpen(error.message, colors.error);
    }
  };

  const saveUser = () => {
    const putObj = {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      email: userDetails?.email,
      userName: userDetails?.userName,
      mobileNumber: userDetails?.mobileNumber,
      pancard: userDetails?.pancard,
      referralCode: userDetails?.referralCode,
      gstn: userDetails?.gstn,
      role: userDetails?.role?.id,
    };

    if (isEditing) {
      updateUserProfile(putObj);
      handleEdit(false);
    } else {
      updateUserDetails(putObj);
      navigate("/adminConsole/users");
    }

    // isEditing ?  : updateUserDetails(putObj);
  };

  const userSelection = (field, id, value) => {
    setUserDetails({ ...userDetails, [field]: id });
  };

  const getUserRoles = async () => {
    try {
      const response = await getAllUserRoles();
      const data = await response.data;
      setUserRoles(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const path = window.location.pathname;

  const isEditUser =
    path.slice(0, path.lastIndexOf("/")) == "/adminConsole/users/editUser";

  useEffect(() => {
    (userId || uId) && getUserById(isEditing ? uId : userId);
  }, [, userId, isEditing]);

  useEffect(() => {
    getUserRoles();
  }, []);
  return (
    // <Box sx={{ padding: "0rem 3rem" }}>
    <Box className={classes.container}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Edit User
      </Typography>
      <form method="POST">
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              label={!userDetails.firstName && "First Name"}
              name="firstName"
              className={classes.textField}
              value={userDetails.firstName}
              onChange={handleChange}
              variant="outlined"
              autoComplete="off"
            />
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              label={!userDetails.lastName && "Last Name"}
              name="lastName"
              className={classes.textField}
              value={userDetails.lastName}
              onChange={handleChange}
              variant="outlined"
              autoComplete="off"
            />
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              // disabled={userDetails.email ? true : false}
              label={!userDetails.email && "Email"}
              name="email"
              type="email"
              className={classes.textField}
              value={userDetails.email}
              onChange={handleChange}
              variant="outlined"
              autoComplete="off"
            />
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              // disabled={userDetails.userName ? true : false}
              label={!userDetails.userName && "User Name"}
              name="userName"
              className={classes.textField}
              value={userDetails.userName}
              onChange={handleChange}
              variant="outlined"
              autoComplete="off"
            />
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              // disabled={userDetails.mobileNumber ? true : false}
              label={!userDetails.mobileNumber && "Mobile Number"}
              name="mobileNumber"
              className={classes.textField}
              value={userDetails.mobileNumber}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              label={!userDetails.pancard && "PAN Card"}
              name="pancard"
              className={classes.textField}
              value={userDetails.pancard}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridItem}>
            <TextField
              // required
              label={!userDetails.gstn && "GST No."}
              name="gstn"
              className={classes.textField}
              value={userDetails.gstn}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <select
              onChange={handleChange}
              // disabled={
              //   userDetails?.role?.role || userDetails?.role ? true : false
              // }
              className={classes.selectDropdown}
              id="role"
              name="role"
            >
              <option value={userDetails?.role?.role || userDetails?.role}>
                {userDetails?.role?.role || userDetails?.role}
              </option>
              {userRoles &&
                userRoles.map((role, index) => {
                  return (
                    <option value={index} key={role.id}>
                      {role.role}
                    </option>
                  );
                })}
            </select>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={saveUser}
          >
            Save
          </Button>
          <Button
            sx={{ marginLeft: "1rem" }}
            variant="contained"
            color="primary"
            className={classes.cancelButton}
            onClick={() =>
              isEditUser ? navigate("/adminConsole/users") : handleEdit(false)
            }
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </Box>
    // </Box>
  );
};

export default EditUser;
