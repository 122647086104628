import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import "./ControlPanel.css";
import { jobCardContext } from "../OrderDetails";
import {
  deleteNonCatalogue,
  deleteService,
  getAllProducts,
  getAllServiceTypes,
  getCatagoriesByVehicle,
  getServices,
} from "../../../services/Services";
import { CommonContextAPI } from "../../../App";

export default function JobCard({ isServiceAdvisor }) {
  const [category, setcategory] = useState(false); //handle selection of categories inside the packages
  const [service, setService] = useState(false); //handle selection of Services inside the packages
  const [product, setProduct] = useState(false);
  const [Categories, setCategories] = useState({
    id: "",
    categoryName: "Select Category",
  }); //selected category store here
  const [serviceType, setServiceType] = useState("Service Type");
  const [serviceTypes, setserviceTypes] = useState([]);
  const [selectService, setSelectSetservice] = useState(); // selected service store here
  const [selectMaterial, setselectMaterial] = useState("Select Material");
  const [materialAmount, setMaterialAmount] = useState({
    price: null,
    qty: null,
  });
  const [saRemark, setSaRemark] = useState();
  const [nonCatalogues, setNonCatalogues] = useState({
    name: "",
    quantity: null,
    price: null,
    serviceTypeId: "",
    hsn: null,
  });
  const [showServices, setShowServices] = useState([]);
  const [showCategories, setshowCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [selectProduct, setSelectProduct] = useState({
    quantity: "",
    price: "",
  });

  const [searchData, setSearchData] = useState([]);
  const [searchModal, setSearchModal] = useState(false);
  const [searchItem, setSearchItem] = useState();

  const [search, setSearch] = useState();

  const { handleAlertOpen, colors } = React.useContext(CommonContextAPI);

  const {
    OrderInfo,
    updateObj,
    setUpdateObj,
    selectedMaterials,
    setSelectedMaterials,
    selectedServises,
    setSelectedServises,
    saveSelectedServises,
    setSaveSelectedServises,
    removeSaveServices,
    setRemoveSaveServices,
    nonCatalogueSaveServices,
    setNonCatalogueSaveServices,
    removeSaveNonCatalogueServices,
    setRemoveSaveNonCatalogueServices,
    nonCatalogueService,
    setNonCatalogueService,
  } = useContext(jobCardContext);

  const SaveServices = OrderInfo?.services;
  const SaveNonCatalogue = OrderInfo?.nonCatalogueJobs;

  // const defaultRemark = OrderInfo?.remarks;
  const defaultRemark = OrderInfo?.saRemarks;

  const userVehicle = updateObj?.userVehicle;
  const variantId = userVehicle
    ? userVehicle?.variant
    : OrderInfo?.vehicle?.variant?.id; // when you change variant its change dynamicly
  const modelId = userVehicle
    ? userVehicle?.model
    : OrderInfo?.vehicle?.model?.modelId; // when you change model its change dynamicly

  // ----- serach material--------
  const filterData = useMemo(
    () =>
      products.filter((item) => {
        return item.name.toLowerCase().includes(search.toLocaleLowerCase());
      }),
    [search]
  );

  useEffect(() => {
    searchData.length > 0 && setSearchModal(true);
  }, [searchData]);

  useMemo(() => {
    setSearchData([...filterData]);
  }, [filterData]);

  // ------- end --------------------

  const BackEndServiceDelete = async (bookingId) => {
    try {
      const request = await deleteService(bookingId);
      const response = await request.data;
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const handelRemoveSaveService = (index, price, bookingId) => {
    let Temp = [...saveSelectedServises.result];
    Temp.splice(index, 1);
    setSaveSelectedServises({
      total: saveSelectedServises.total - price,
      result: [...Temp],
    });
    BackEndServiceDelete(bookingId);
    setRemoveSaveServices([...removeSaveServices, bookingId]);
  };

  const BackEndNonCatalogueDelete = async (Id) => {
    try {
      const request = await deleteNonCatalogue(Id);
      const response = request.data;
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const handelRemoveSaveNonCatalogue = (index, price, id) => {
    let Temp = [...nonCatalogueSaveServices.result];
    Temp.splice(index, 1);
    setNonCatalogueSaveServices({
      total: nonCatalogueSaveServices.total - price,
      result: [...Temp],
    });
    BackEndNonCatalogueDelete(id);
    setRemoveSaveNonCatalogueServices([...removeSaveNonCatalogueServices, id]);
  };

  // ------------------------- handel Duplicet Service-------------------------------------
  const [backEndServices, setBackEndServices] = useState([]);

  const handleSelection = () => {
    !category && !service && setcategory(true);
  };

  const handleCategoryMenu = (categoryData) => {
    const { categoryId, category, imageUrl } = categoryData;
    setCategories(categoryData);
    setCategories({ id: categoryId, categoryName: category });
    setcategory(false);
    setService(true);
  };

  const handleServiceMenu = (serviceData) => {
    setSelectSetservice(serviceData);
    setService(false);
    setcategory(false);
  };

  const handelAddService = () => {
    const {
      service,
      actualPrice,
      serviceId,
      detailsList,
      activities,
      displayPrice,
    } = selectService;
    const hadelServiseStore = () => {
      const ServiceObject = {
        serviceId,
        service,
        actualPrice,
        activities,
        detailsList,
        displayPrice,
      };
      backEndServices.push(ServiceObject);
      setSelectedServises({
        total: selectedServises.total + displayPrice,
        result: [...selectedServises.result, ServiceObject],
      });
    };

    var index = backEndServices.findIndex((x) => x.service === service);
    index === -1 ? hadelServiseStore() : alert("object already exists");
    setSelectSetservice("");
    setCategories({ categoryName: "Select Categorie" });
  };

  useEffect(() => {
    SaveServices && setBackEndServices(SaveServices);
  }, [SaveServices]);

  const handelRemoveSelectedService = (index, price) => {
    const Temp = [...selectedServises.result];
    Temp.splice(index, 1);
    setSelectedServises({
      total: selectedServises.total - price,
      result: [...Temp],
    });
  };

  // ----------------------------handel Duplicte Non Catalogues ----------------------------------
  const [backEndNonCatalogue, setBackEndNonCatalogue] = useState([]);

  const handelNonCatalogue = (e) => {
    setNonCatalogues({ ...nonCatalogues, [e.target.name]: e.target.value });
  };

  const handelAddNonCatalogue = () => {
    const { name, quantity, price, gst, hsn, serviceTypeId } = nonCatalogues;
    const countPrice = parseInt(quantity) * parseInt(price);

    const Object = {
      name,
      quantity,
      price: countPrice,
      gst,
      hsn,
      serviceTypeId,
    };
    const hadelNonCatalogueStore = () => {
      backEndNonCatalogue.push(Object);
      setNonCatalogueService({
        total: nonCatalogueService.total + countPrice,
        result: [...nonCatalogueService.result, Object],
      });
    };
    if (name && quantity && price) {
      var index = backEndNonCatalogue?.findIndex((x) => x.name == name);
      index === -1 ? hadelNonCatalogueStore() : alert("object already exists");
      setNonCatalogues({
        name: "",
        quantity: "",
        price: "",
        gst: "",
        hsn: "",
        serviceTypeId: "",
      });
    }
  };

  useEffect(() => {
    setBackEndNonCatalogue(SaveNonCatalogue);
  }, [SaveNonCatalogue]);

  const handelRemoveNonCatalogue = (index, price) => {
    let Temp = [...nonCatalogueService.result];
    Temp.splice(index, 1);
    setNonCatalogueService({
      total: nonCatalogueService.total - price,
      result: [...Temp],
    });
  };

  // --------------------------------------------------------------

  const handelChangeAddMaterial = (e) => {
    setMaterialAmount({ ...materialAmount, [e.target.name]: e.target.value });
    setSelectProduct({ ...selectProduct, [e.target.name]: e.target.value });
  };
  const handelAddMaterial = () => {
    const { quantity, price } = selectProduct;
    let totalPrice = parseInt(quantity) * parseInt(price);
    const reduxObj = {
      name: searchItem,
      quantity,
      price: totalPrice,
    };
    if (quantity && price && searchItem) {
      setSelectedMaterials({
        total: selectedMaterials.total + totalPrice,
        result: [...selectedMaterials.result, reduxObj],
      });
      setselectMaterial("Select Material");
      setSearchItem("");
      setSelectProduct({ quantity: "", price: "" });
    }
  };

  const handelRemoveMaterial = (index, price) => {
    let Temp = [...selectedMaterials.result];
    Temp.splice(index, 1);
    setSelectedMaterials({
      total: selectedMaterials.total - price,
      result: [...Temp],
    });
  };

  const handleChangeAddLabour = (e) => {
    setUpdateObj({ ...updateObj, labourCost: e.target.value });
  };

  const handleSubmitLabour = () => {};
  const handleDiscount = (e) => {
    setUpdateObj({ ...updateObj, discount: e.target.value });
  };

  const handleSubmitDiscount = () => {};

  const handleAddRemark = () => {};

  const handleChangeRemark = (e) => {
    setSaRemark(e.target.value);
    setUpdateObj({ ...updateObj, saRemarks: e.target.value });
  };

  useMemo(() => {
    setSaRemark(defaultRemark);
  }, [defaultRemark]);

  useMemo(() => {
    category &&
      (async () => {
        try {
          const request = await getCatagoriesByVehicle(
            OrderInfo?.vehicle?.vehicleType?.id
          );
          const response = await request.data;
          setshowCategories(response.result);
        } catch (error) {
          handleAlertOpen(error?.message, colors?.error);
        }
      })();
  }, [category]);

  useMemo(() => {
    service &&
      (async () => {
        try {
          const request = await getServices(Categories?.id, modelId, variantId);
          const response = await request.data;
          setShowServices(response.result);
        } catch (error) {
          handleAlertOpen(error?.message, colors?.error);
        }
      })();
  }, [service]);

  useMemo(async () => {
    product &&
      (async () => {
        try {
          const request = await getAllProducts();
          const response = await request.data;
          setProducts(response.result);
        } catch (err) {
          console.log(err);
        }
      })();
  }, [product]);

  useMemo(() => {
    let total = 0;
    let services = [];
    SaveNonCatalogue &&
      SaveNonCatalogue.map((item) => {
        total += item.price;
        services.push(item);
      });
    setNonCatalogueSaveServices({ total, result: [...services] });
  }, [SaveNonCatalogue]);

  useMemo(() => {
    let total = 0;
    let services = [];
    SaveServices &&
      SaveServices.map((item) => {
        total += item.displayPrice;
        services.push(item);
      });
    setSaveSelectedServises({ total, result: [...services] });
  }, [SaveServices]);

  const getServicesTyeps = async () => {
    try {
      const request = await getAllServiceTypes();
      const response = await request.data;
      setserviceTypes(response.result);
    } catch (error) {}
  };
  useEffect(() => {
    getServicesTyeps();
  }, []);
  return (
    <Box className="jobcardContainer">
      <Box className="packageContainer">
        <Typography variant="h6" className="controlpanel-section-header">
          {OrderInfo?.vehicle?.vehicleType?.vehicleType} Packages
        </Typography>
        <Box sx={isServiceAdvisor}>
          <Box className="selectPackaagesContainer" mt={3}>
            <Box className="select-packages">
              <Box className="selectCategoryLabel">
                <Typography className="selectButton" onClick={handleSelection}>
                  {Categories.categoryName}
                  {selectService && `, ${selectService.service}`}
                </Typography>
              </Box>
              <Box className="mainMenu">
                {category &&
                  showCategories.map((item) => {
                    const { categoryId, category, imageUrl } = item;
                    return (
                      <Typography
                        className="mainMenuLink"
                        onClick={() => handleCategoryMenu(item)}
                      >
                        {category}
                      </Typography>
                    );
                  })}
              </Box>
              <Box className="subMenu">
                {service &&
                  showServices.map((item) => {
                    const { service, categoryId, subCategory } = item;
                    return (
                      <Box>
                        {service &&
                          service.map((ele) => {
                            const { serviceId, service } = ele;

                            return (
                              <Typography
                                className="subMenuLink"
                                onClick={() => handleServiceMenu(ele)}
                              >
                                {service}
                              </Typography>
                            );
                          })}
                      </Box>
                    );
                  })}
              </Box>
            </Box>

            <Box className="add-button">
              <Button className="add-btn" onClick={handelAddService}>
                + Add
              </Button>
            </Box>
          </Box>

          <Box mt={3}>
            {saveSelectedServises &&
              saveSelectedServises?.result?.map((item, index) => {
                return (
                  <Box className="showAddedServices" key={index}>
                    <Typography className="addedService">
                      {item.service}{" "}
                      <DeleteIcon
                        onClick={() => {
                          handelRemoveSaveService(
                            index,
                            item.displayPrice,
                            item.bookingId
                          );
                        }}
                        className="delete-btn"
                      />
                    </Typography>
                  </Box>
                );
              })}

            {selectedServises &&
              selectedServises.result.map((item, index) => {
                const price = item.displayPrice;
                return (
                  <Box className="showAddedServices" key={index}>
                    <Typography className="addedService">
                      {item.service}{" "}
                      <DeleteIcon
                        onClick={() => {
                          // dispatch(ServiceRemoveToJobCard({ index, price }));
                          handelRemoveSelectedService(index, price);
                        }}
                        className="delete-btn"
                      />
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>

      <Box className="non-catalogueContainer" mt={2} sx={isServiceAdvisor}>
        <Typography variant="h6" className="controlpanel-section-header">
          Non - Catalogue Jobs
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "8px",
            gridTemplateColumns:
              "repeat(auto-fit, minmax(100px, 1fr))!important",
          }}
        >
          <Input
            className="control-panel-inputeField"
            name="name"
            value={nonCatalogues.name}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder="Enter Job Name"
          ></Input>
          <Input
            className="control-panel-inputeField "
            placeholder="HSN Code"
            disableUnderline
            value={nonCatalogues.hsn}
            onChange={handelNonCatalogue}
            name="hsn"
          />
          <Input
            className="control-panel-inputeField"
            name="gst"
            value={nonCatalogues.gst}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder="GST"
          ></Input>
          <Input
            className="control-panel-inputeField"
            name="quantity"
            value={nonCatalogues.quantity}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder=" Quantity "
          ></Input>
          <Input
            className="control-panel-inputeField"
            name="price"
            value={nonCatalogues.price}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder="Enter Amount"
          ></Input>
        </Box>
        <Box mt={1}>
          <Select
            value={serviceType}
            className="icp-box icp-input-fileds-box"
            style={{ width: "25%" }}
            name="serviceType"
            onChange={(event) => setServiceType(event.target.value)}
          >
            <MenuItem disabled value="Service Type">
              Service Type
            </MenuItem>
            {serviceTypes?.map((service) => (
              <MenuItem
                key={service?.id}
                value={service.type}
                onClick={() => {
                  setNonCatalogues({
                    ...nonCatalogues,
                    serviceTypeId: service?.id,
                  });
                  // setNonCatalogue({
                  //   ...nonCatalogue,
                  //   serviceTypeId: service?.id,
                  //   serviceType: service?.type,
                  // });
                }}
              >
                {service.type}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="non-catalogueButtons">
          <Button className="addJob-button" onClick={handelAddNonCatalogue}>
            Add Job
          </Button>
          {/* <Button className='saveJob-button'>Save Job</Button> */}
        </Box>

        <Box mt={3}>
          {nonCatalogueSaveServices &&
            nonCatalogueSaveServices.result.map((item, index) => {
              const { id, name, price, service_id } = item;
              return (
                <Box className="showAddedServices" key={id}>
                  <Typography className="addedService">
                    {name}
                    <DeleteIcon
                      onClick={() => {
                        const { id, name, price, service_id } = item;
                        handelRemoveSaveNonCatalogue(index, price, id);
                      }}
                      className="delete-btn"
                    />
                  </Typography>
                </Box>
              );
            })}

          {nonCatalogueService &&
            nonCatalogueService.result.map((item, index) => {
              const { id, name, price, service_id } = item;
              return (
                <Box className="showAddedServices" key={id}>
                  <Typography className="addedService">
                    {name}
                    <DeleteIcon
                      onClick={() => {
                        handelRemoveNonCatalogue(index, price);
                      }}
                      className="delete-btn"
                    />
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>

      <Box className="non-catalogueContainer" mt={2} sx={isServiceAdvisor}>
        <Typography variant="h6" className="controlpanel-section-header">
          Custom Services
        </Typography>

        <Box className="noncatalogue-search_select-section">
          <Box className="noncatalogue-search-field">
            <SearchIcon />
            <Input
              className="noncatalogue-search-input-field"
              placeholder="Search Part"
              disableUnderline
            />
          </Box>
          <Select
            className="noncatalogue-select-section"
            value={serviceType}
            name="serviceType"
            onChange={(event) => setServiceType(event.target.value)}
          >
            <MenuItem disabled value="Service Type">
              Service Type
            </MenuItem>
            {serviceTypes?.map((service) => (
              <MenuItem
                key={service?.id}
                value={service.type}
                onClick={() => {
                  setNonCatalogues({
                    ...nonCatalogues,
                    serviceTypeId: service?.id,
                  });
                }}
              >
                {service.type}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: "10px",
            gridTemplateColumns:
              "repeat(auto-fit, minmax(100px, 1fr))!important",
          }}
        >
          <Input
            className="control-panel-inputeField "
            placeholder="HSN Code"
            disableUnderline
            value={nonCatalogues.hsn}
            onChange={handelNonCatalogue}
            name="hsn"
          />
          <Input
            className="control-panel-inputeField"
            name="gst"
            value={nonCatalogues.gst}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder="GST Rate"
          ></Input>
          <Input
            className="control-panel-inputeField"
            name="quantity"
            value={nonCatalogues.quantity}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder=" Quantity "
          ></Input>
          <Input
            className="control-panel-inputeField"
            name="price"
            value={nonCatalogues.price}
            onChange={handelNonCatalogue}
            disableUnderline
            placeholder="Amount"
          ></Input>
        </Box>

        {/* <Box mt={1}>
          <Select
            value={serviceType}
            className="icp-box icp-input-fileds-box"
            style={{ width: "25%" }}
            name="serviceType"
            onChange={(event) => setServiceType(event.target.value)}
          >
            <MenuItem disabled value="Service Type">
              Service Type
            </MenuItem>
            {serviceTypes?.map((service) => (
              <MenuItem
                key={service?.id}
                value={service.type}
                onClick={() => {
                  setNonCatalogues({
                    ...nonCatalogues,
                    serviceTypeId: service?.id,
                  });
                  // setNonCatalogue({
                  //   ...nonCatalogue,
                  //   serviceTypeId: service?.id,
                  //   serviceType: service?.type,
                  // });
                }}
              >
                {service.type}
              </MenuItem>
            ))}
          </Select>
        </Box> */}
        <Box className="non-catalogueButtons">
          <Button className="addJob-button" onClick={handelAddNonCatalogue}>
            Add Service
          </Button>
          {/* <Button className='saveJob-button'>Save Job</Button> */}
        </Box>

        <Box mt={3}>
          {nonCatalogueSaveServices &&
            nonCatalogueSaveServices.result.map((item, index) => {
              const { id, name, price, service_id } = item;
              return (
                <Box className="showAddedServices" key={id}>
                  <Typography className="addedService">
                    {name}
                    <DeleteIcon
                      onClick={() => {
                        const { id, name, price, service_id } = item;
                        handelRemoveSaveNonCatalogue(index, price, id);
                      }}
                      className="delete-btn"
                    />
                  </Typography>
                </Box>
              );
            })}

          {nonCatalogueService &&
            nonCatalogueService.result.map((item, index) => {
              const { id, name, price, service_id } = item;
              return (
                <Box className="showAddedServices" key={id}>
                  <Typography className="addedService">
                    {name}
                    <DeleteIcon
                      onClick={() => {
                        handelRemoveNonCatalogue(index, price);
                      }}
                      className="delete-btn"
                    />
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>

      <Box className="addMaterialCostContainer" mt={2} sx={isServiceAdvisor}>
        <Typography variant="h6" className="controlpanel-section-header">
          Add Material
        </Typography>

        <Box className="addMaterialCost-selectionFeilds">
          <Box className="control-panel-inputBox">
            <Input
              className="control-panel-inputeField"
              name="quantity"
              value={searchItem ? searchItem : ""}
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchItem(e.target.value);
                setProduct(true);
              }}
              disableUnderline
              placeholder="Search Material"
            ></Input>
          </Box>
          <Box className="control-panel-inputBox">
            <Input
              className="control-panel-inputeField"
              name="quantity"
              value={selectProduct.quantity}
              onChange={handelChangeAddMaterial}
              disableUnderline
              placeholder="Quantity"
            ></Input>
          </Box>
          <Box className="control-panel-inputBox">
            <Input
              className="control-panel-inputeField"
              name="price"
              value={selectProduct.price}
              onChange={handelChangeAddMaterial}
              disableUnderline
              placeholder="Enter Amount"
            ></Input>
          </Box>
        </Box>

        {searchModal && (
          <Box className="searchData">
            <ul>
              {searchData &&
                searchData.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setSearchItem(item.name);
                        setSearchModal(false);
                      }}
                    >
                      {item.name}
                    </li>
                  );
                })}
            </ul>
          </Box>
        )}
        <Box className="non-catalogueButtons">
          <Button className="addJob-button" onClick={handelAddMaterial}>
            Add Material
          </Button>
          {/* <Button className='saveMaterial-button'>Save</Button> */}
        </Box>

        <Box mt={3}>
          {selectedMaterials &&
            selectedMaterials.result.map((item, index) => {
              const { name, price } = item;

              return (
                <Box className="showAddedServices" key={index}>
                  <Typography className="addedService">
                    {name}
                    <DeleteIcon
                      onClick={() => {
                        handelRemoveMaterial(index, price);
                      }}
                      className="delete-btn"
                    />
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>

      <Box className="addMaterialCostContainer" mt={2} sx={isServiceAdvisor}>
        <Typography variant="h6" className="controlpanel-section-header">
          Add Labour Cost
        </Typography>
        <Box className="non-catalogueFelids">
          <Box className="control-panel-inputBox">
            <Input
              className="control-panel-inputeField"
              name="labour"
              value={updateObj?.labourCost}
              onChange={handleChangeAddLabour}
              disableUnderline
              placeholder="Enter Labour"
            ></Input>
          </Box>
        </Box>
        <Box className="non-catalogueButtons">
          <Button className="addJob-button" onClick={handleSubmitLabour}>
            Add Labour
          </Button>
        </Box>
      </Box>

      <Box className="non-catalogueContainer" mt={2}>
        <Typography variant="h6" className="controlpanel-section-header">
          Add Discount
        </Typography>
        <Box className="non-catalogueFelids">
          <Box className="control-panel-inputBox">
            <Input
              className="control-panel-inputeField"
              name="discount"
              value={updateObj?.discount}
              onChange={handleDiscount}
              disableUnderline
              placeholder="Enter Discount"
            ></Input>
          </Box>
        </Box>
        <Box className="non-catalogueButtons">
          <Button className="addJob-button" onClick={handleSubmitDiscount}>
            Add Discount
          </Button>
        </Box>
      </Box>

      <Box className="non-catalogueContainer" mt={2}>
        <Typography variant="h6" className="controlpanel-section-header">
          Add Remark
        </Typography>
        <Box className="" sx={{ marginBottom: "5rem" }}>
          <Box className="control-panel-inputBox">
            <TextField
              style={{ width: "100%" }}
              className="control-panel-inputeField"
              placeholder="Write remarks here..."
              value={saRemark}
              multiline
              minRows={4}
              maxRows={4}
              onChange={handleChangeRemark}
            />
          </Box>
        </Box>
        <Box className="non-catalogueButtons">
          <Button className="addJob-button" onClick={handleAddRemark}>
            Add Remark
          </Button>
        </Box>
      </Box>
    </Box>
  );
}