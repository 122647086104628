import React, { useState, useEffect, useMemo, useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import {
  getAllGaragePayouts,
  getBankAccountByGarageId,
  getGaragePayout,
  getGaragePayoutStatus,
  updateGaragePayout,
} from "../../services/garageServices/GarageServices";
import PaymentOptionModal from "../../modules/garages/PaymentOptionModal";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import { useNavigate } from "react-router-dom";
import PaginationTable from "../../components/tables/Pagination";
import { Box, Button } from "@mui/material";
import { getCMSReport } from "../../services/garageServices/GarageServices";
import moment from "moment";
import { CommonContextAPI } from "../../App";
import Table from "../../components/tables/Table";
import "./Payout.css";

const CustomToolbar = () => {
  const commonData = useContext(CommonContextAPI);
  const { clearAppliedFilters } = commonData;
  return (
    <div className="table-toolbar">
      <GridToolbarQuickFilter />
      <div
        className="table-toolbar-buttons"
        sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}
      >
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className="table_toolbar_clrBtn"
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

function GarageApprovedPayout() {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [basicPrice, setBasicPrice] = useState(0);
  const [filterBasicPrice, setFilterBasicPrice] = useState();
  const [garagePayout, setGaragePayout] = useState(0);
  const [clubbedBasicPayout, setClubbedBasicPayout] = useState(0);
  const [clubbedNetPayout, setClubbedNetPayout] = useState(0);

  const [payoutData, setpayoutData] = useState([]);
  const [bindApprovedPayout, setBindApprovedPayout] = useState([]);

  const [payoutStatus, setPayoutStatus] = useState([]);

  const [payoutFilter, setPayoutFilter] = useState();

  const [filterModels, setFilterModels] = React.useState([]);

  const [cmsReport, setCmsReport] = useState([]);
  const [isGarageCms, setIsGarageCms] = useState(false);

  const Navigate = useNavigate();

  // --- Cinfigretion--------
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  // const filterDate = useSelector((state) => state?.DateRange_Reducer);
  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    handleAlertOpen,
    colors,
    setDownloadCmsList,
    isDownloadCMSReport,
    setIsDownloadCMSReport,
    defaultDate,
    ordersGaragesToggle,
  } = commonData;

  const [filter, setFilter] = useState([]);

  // --------------calculate footer -------------------

  useEffect(() => {
    const payout = bindApprovedPayout?.map((i) => i.netPayout);
    const payoutPrice = payout?.reduce((a, b) => Number(a) + Number(b), 0);
    setGaragePayout(payoutPrice);
    const basickPrice = bindApprovedPayout
      ?.map((i) => i.basicPrice)
      .reduce((a, b) => Number(a) + Number(b), 0);
    setBasicPrice(basickPrice);
  }, [bindApprovedPayout]);

  useEffect(() => {
    payoutData.map((garagePayout) => {
      const { orderDetail, payout, basicPrice, nonCatalogueServicesPrice } =
        garagePayout;
      const orderObj = {
        // orderId: orderDetail?.order?.orderId,
        orderId: payout?.orderId,
        customerName: `${orderDetail?.order?.userFirstName} ${orderDetail?.order?.userLastName}`,
        // completedDate: orderDetail?.order?.completionDate,
        paymentInitiatedOn: payout?.paymentInitiatedOn,
        vehicleType: orderDetail?.order?.vehicleType,
        garageName: orderDetail?.order?.garageName,
        vehicleDetails: `${orderDetail?.order?.brand} ${orderDetail?.order?.model} ${orderDetail?.order?.variant}`,
        basicPrice: Math.round(payout?.basicPrice),
        ksMaterialCostBasic: Math.round(payout?.ksMaterialCostBasic),
        payoutPercentage: Math.round(payout?.garage?.payoutPercentage),
        basicPayout: Math.round(payout?.basicPayout),
        gstPayout: Math.round(payout?.payoutTotalGst),
        razorpayDeduction: Math.round(payout?.razorpayDeduction),
        totalPrice: Math.round(orderDetail?.order?.totalPrice),

        totalPayout: Math.round(payout?.totalPayout),
        tds: Math.round(payout?.tds),
        netPayout: Math.round(payout?.netPayout),
        approvalStatus: payout?.garagePayoutStatus?.status,
        orderType: payout?.orderType?.type,
      };

      setBindApprovedPayout((prev) => [...prev, orderObj]);
    });
  }, [payoutData]);

  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
    {
      field: "paymentInitiatedOn",
      headerName: "Approved Date",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      // filterable: true,
      // editable: true,
      // filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "orderType",
      headerName: "Order Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "vehicleDetails",
      headerName: "Vehicle Details",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      
      field: "totalPrice",
      headerName: "Total Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    }
,
    {
      field: "basicPrice",
      headerName: "Basic_Price",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "ksMaterialCostBasic",
      headerName: "KS Material Price",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "payoutPercentage",
      headerName: "Payout Percentage",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "basicPayout",
      headerName: "Basic Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "gstPayout",
      headerName: "GST Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "razorpayDeduction",
      headerName: "Razorpay Deduction",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "totalPayout",
      headerName: "Total Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tds",
      headerName: "TDS Deduction",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "netPayout",
      headerName: "Net Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: [...payoutStatus.map((i) => i.status)],
      renderCell: (params) => {
        const value = params.formattedValue;
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 8%",
              width: "100%",
              height: "80%",
              color: "white",
              backgroundColor: "#FFAF02",

              // backgroundColor:
              //   value === "Pending"
              //     ? "#D0342C"
              //     : value === "Hold"
              //     ? "#707070"
              //     : value === "Approved"
              //     ? "#FFAF02"
              //     : value === "Complete"
              //     ? "#51AE3A"
              //     : "white",
              zIndex: "1000",
            }}
          >
            <span>{params.row.approvalStatus}</span>
            <ExpandMoreIcon />
          </div>
        );
      },
      flex: 1,
    },
  ];

  // Garage CMS columns

  const cmsColumns = [
    {
      field: "garageId",
      headerName: "Garage ID",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
    },
    {
      field: "basicPrice",
      headerName: "Basic Price",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "netPayout",
      headerName: "Net Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
  ];

  const [clubbedGaragePayoutsList, setClubbedGaragePayoutsList] = useState([]);

  const clubbedGaragePayouts = async () => {
    setClubbedBasicPayout(0);
    setClubbedNetPayout(0);
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate = moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate = moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate?.[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate?.[0]?.endDate).format("YYYY-MM-DD");
    }

    let groupData = true;

    try {
      const response = await getGaragePayout(
        startDate,
        endDate,
        "level=2",
        config,
        groupData
      );
      const data = await response.data;

      if (response?.status == 200) {
        const dataResult = Object.values(data?.result);

        setLoading(false);
        const filteredData = dataResult?.map((item) => {
          setClubbedBasicPayout((prev) => prev + item?.basicPayout);
          setClubbedNetPayout((prev) => prev + item?.netPayout);
          return {
            garageId: item?.garageId,
            garageName: item?.garage?.garageName,
            basicPrice: Math.round(item?.basicPrice),
            netPayout: Math.round(item?.netPayout),
          };
        });

        filteredData && setClubbedGaragePayoutsList(filteredData);
      } else {
        setLoading(false);
        // handleAlertOpen("CMS reports not found.", colors?.error);
        setClubbedGaragePayoutsList([]);
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.status == 400) {
        handleAlertOpen(
          "Please select valid start date and end date.",
          colors?.error
        );
        setClubbedGaragePayoutsList([]);
      } else {
        handleAlertOpen(error?.response?.data?.message, colors?.error);
      }
    }
  };

  const [minMax, setMinmax] = useState([]);

  useEffect(() => {
    payoutData?.map((payout) => {
      if (payout?.orderDetail?.order?.completionDate != undefined) {
        setMinmax((prev) => [
          ...prev,
          payout?.orderDetail?.order?.completionDate,
        ]);
      }
    });
  }, [payoutData]);

  const getBankAccount = async (garageId) => {
    try {
      const request = await getBankAccountByGarageId(
        // paymentMethod.garageId,
        garageId,
        config
      );
      const response = await request.data;
      const data = await response?.result;
      return data[0];
    } catch (error) {
      console.log(error);
    }
  };

  const updatePayoutStatus = async (params) => {
    const orderId = params.id;
    const status = params.value;
    const Status = payoutStatus.find((i) => i.status === status);
    const payoutId = payoutData.find((i) => i.payout.orderId === orderId);
    const bankDetails = await getBankAccount(payoutId?.payout?.garageId);

    let updateObj = {
      id: payoutId.payout.id,
      payoutStatus: Status.id,
      otherMethod: true,
      bankAccount: bankDetails?.id,
    };
    const UpdatePayout = async () => {
      try {
        const request = await updateGaragePayout(updateObj, config);
        const response = await request.data;

        if (response.statusCode === 200) {
          handleAlertOpen(response.message, colors.success);
          getGaragePayoutData();
          // setTimeout(() => {
          //   Navigate("/completepayout");
          // }, 1000);
        }
      } catch (err) {
        console.log(err);
      }
    };
    params.value === "Complete" && UpdatePayout();

    const sendObj = {
      id: payoutId.payout.id,
      payoutStatus: Status.id,
      garageId: payoutId.payout.garageId,
    };
    setPaymentMethod(sendObj);
  };
  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("paymentInitiatedOn");
  }, [filteredDate && filteredDate]);

  const getGaragePayoutData = async () => {
    let from;
    let to;

    if (filteredDate) {
      from = moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      to = moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      from =
        defaultDate && moment(defaultDate?.[0]?.startDate).format("YYYY-MM-DD");
      to =
        defaultDate && moment(defaultDate?.[0]?.endDate).format("YYYY-MM-DD");
    }

    try {
      const request = await getGaragePayout(from, to, "level=2", config);
      const response = await request.data;
      setBindApprovedPayout([]);
      setpayoutData(response.result);
      setLoading(false);
    } catch (error) {
      setpayoutData([]);
      console.log(error);
      if (error.response.status == 403) {
        handleAlertOpen("Authorization Failed!", colors.error);
      }
    }
  };

  // ****************** Call API's based on condition *****************************
  useEffect(() => {
    if (ordersGaragesToggle == "garages") {
      setIsGarageCms(true);
      setpayoutData([]);
    } else if (ordersGaragesToggle == "orders") {
      setIsGarageCms(false);
      setClubbedGaragePayoutsList([]);
    } else {
      return false;
    }
  }, [ordersGaragesToggle]);

  useMemo(() => {
    setpayoutData([]);
    setClubbedGaragePayoutsList([]);
    setBindApprovedPayout([]);
    setLoading(true);
    if (isGarageCms) {
      (filteredDate || defaultDate) && clubbedGaragePayouts();
    } else {
      (filteredDate || defaultDate) && getGaragePayoutData();
    }
  }, [filteredDate, defaultDate, isGarageCms]);

  const getGaragePayoutStatusList = async () => {
    const req = await getGaragePayoutStatus(config);
    const res = await req.data;
    const result = res.result.filter(
      (i) => i.status === "Complete" || i.status === "Approved"
    );
    setPayoutStatus(result);
  };

  // ----------------------------------------------
  // Download CMS Report

  const downloadCmsReport = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate = moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate = moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate?.[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate?.[0]?.endDate).format("YYYY-MM-DD");
    }
    let groupData = true;
    try {
      const response = await getCMSReport(
        startDate,
        endDate,
        groupData,
        config
      );
      const data = await response.data;
      const dataResult = Object.values(data.result);

      if (response?.status == 200) {
        const filteredData = dataResult?.map((item) => {
          return {
            Client_Code: item?.client_Code,
            Product_Code: item?.product_Code,
            Payment_Type: item?.payment_Type,
            Payment_Ref_No: item?.payment_Ref_No,
            Payment_Date: item?.payment_Date,
            Dr_Ac_No: item?.dr_Ac_No,
            Amount: Math.round(item?.amount),
            Beneficiary_Code: item?.beneficiary_Code,
            Beneficiary_Name: item?.beneficiary_Name,
            "IFSC Code": item?.ifsc_Code,
            Beneficiary_Acc_No: item?.beneficiary_Acc_No,
            Beneficiary_Email: item?.beneficiary_Email,
            Beneficiary_Mobile: item?.beneficiary_Mobile,
            Debit_Narration: item?.debit_Narration,
            Credit_Narration: item?.credit_Narration,
            Enrichment_1: item?.enrichment_1,
            Enrichment_2: item?.enrichment_2,
          };
        });

        downloadXLS(
          filteredData,
          `CMS Report From ${startDate} To ${endDate} `
        );
        handleAlertOpen("CMS Report Downloaded!", colors?.success);
      } else {
        handleAlertOpen("CMS reports not found.", colors?.error);
      }
    } catch (error) {
      console.log("error", error);
      if (error.response.status == 400) {
        handleAlertOpen(
          "Please select valid start date and end date.",
          colors?.error
        );
      } else {
        handleAlertOpen(error?.response?.data?.message, colors?.error);
      }
    }
  };

  useMemo(() => {
    isDownloadCMSReport && downloadCmsReport();
    // isDownloadCMSReport && console.log("!!!!!!!!!", filteredDate);
    setIsDownloadCMSReport(false);
  }, [isDownloadCMSReport]);

  /////////////////////////////////////////////////////////////////////////////////////

  const [weeklyRecords, setWeeklyRecords] = useState([]);

  useEffect(() => {
    payoutData?.map((payout) => {
      if (payout?.orderDetail?.order?.completionDate != undefined) {
        setWeeklyRecords((prev) => [...prev, payout]);
      }
    });
  }, [payoutData]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const temp = () => {
    const customIndexes = [];
    let firstWeek = [];
    let secondWeek = [];
    let thirdWeek = [];
    let fourthWeek = [];

    let weekReport = Object.values(
      weeklyRecords.reduce((acc, data) => {
        const completedYear =
          data?.orderDetail?.order?.completionDate.split("-")[0];
        const completedMonth =
          data?.orderDetail?.order?.completionDate.split("-")[1];
        const completedDate =
          data?.orderDetail?.order?.completionDate.split("-")[2];

        if (completedDate >= 1 && completedDate <= 7) {
          firstWeek.push(data);
          const date = `${completedYear}/${parseInt(completedMonth)}/1`;
          const displayDate = `${completedYear} ${
            months[parseInt(completedMonth) - 1]
          } week - 1`;

          customIndexes[date] = customIndexes[date] || {
            week: date,
            displayWeek: displayDate,
            // data: firstWeek,
          };
        } else if (completedDate >= 8 && completedDate <= 14) {
          secondWeek.push(data);
          const date = `${completedYear}/${parseInt(completedMonth)}/2`;
          const displayDate = `${completedYear} ${
            months[parseInt(completedMonth) - 1]
          } week - 2`;
          customIndexes[date] = customIndexes[date] || {
            week: date,
            displayWeek: displayDate,
            // data: secondWeek,
          };
        } else if (completedDate >= 15 && completedDate <= 21) {
          thirdWeek.push(data);
          const date = `${completedYear}/${parseInt(completedMonth)}/3`;
          const displayDate = `${completedYear} ${
            months[parseInt(completedMonth) - 1]
          } week - 3`;
          customIndexes[date] = customIndexes[date] || {
            week: date,
            displayWeek: displayDate,
            // data: thirdWeek,
          };
        } else {
          fourthWeek.push(data);
          const date = `${completedYear}/${parseInt(completedMonth)}/4`;
          const displayDate = `${completedYear} ${
            months[parseInt(completedMonth) - 1]
          } week - 4`;
          customIndexes[date] = customIndexes[date] || {
            week: date,
            displayWeek: displayDate,
            // data: fourthWeek,
          };
        }
        return customIndexes;
      }, {})
    );

    let sortedData = weekReport.sort(
      (a, b) => Number(new Date(a.week)) - Number(new Date(b.week))
    );

    setDownloadCmsList(sortedData);
  };

  useEffect(() => {
    temp();
  }, [weeklyRecords]);

  /////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    getGaragePayoutStatusList();
  }, []);

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            {isGarageCms ? (
              <h5 className="grid-table-footer-right-label">
                Basic Price :{" "}
                <span className="grid-table-footer-right-value">
                  {Math.round(clubbedBasicPayout)}
                </span>
              </h5>
            ) : (
              <h5 className="grid-table-footer-right-label">
                Basic Price :{" "}
                <span className="grid-table-footer-right-value">
                  {filterBasicPrice
                    ? Math.round(filterBasicPrice)
                    : Math.round(basicPrice)}
                </span>
              </h5>
            )}
          </div>
          <div style={{ marginLeft: "10px" }}>
            {isGarageCms ? (
              <h5 className="grid-table-footer-right-label">
                Net Payout :{" "}
                <span className="grid-table-footer-right-value">
                  {Math.round(clubbedNetPayout)}
                </span>
              </h5>
            ) : (
              <h5 className="grid-table-footer-right-label">
                Garage Payout :{" "}
                <span className="grid-table-footer-right-value">
                  {/* {payoutFilter
      ? payoutFilter?.toFixed(2)
      : garagePayout?.toFixed(2)} */}

                  {Math.round(garagePayout)}
                </span>
              </h5>
            )}
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  return (
    <>
      {paymentModal && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.7)",
            width: "100%",
            zIndex: "2",
          }}
        >
          <PaymentOptionModal
            paymentMethod={paymentMethod}
            setPaymentModal={setPaymentModal}
            getGaragePayoutData={getGaragePayoutData}
          />
        </div>
      )}

      <Table
        heading="Approved Payout"
        sx={DataGridStyle}
        rows={isGarageCms ? clubbedGaragePayoutsList : bindApprovedPayout}
        columns={isGarageCms ? cmsColumns : columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        getCellClassName={(param) => "Rows"}
        onCellEditCommit={(params) => {
          updatePayoutStatus(params);
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "paymentInitiatedOn", sort: "desc" }],
          },
        }}
        onStateChange={(state) => {
          if (isGarageCms) {
            const visibleRows = state.filter.visibleRowsLookup;
            let visibleItems = [];
            let Payout = 0;
            let basic = 0;
            setClubbedBasicPayout(0);
            setClubbedNetPayout(0);
            for (const [garageId, value] of Object.entries(visibleRows)) {
              if (value === true) {
                visibleItems.push(Number(garageId));
              }
            }
            const res = clubbedGaragePayoutsList.filter((item) =>
              visibleItems.includes(item.garageId)
            );
            let totalPayout = 0;
            let totalBasic = 0;
            if (res?.length == 0) {
              totalPayout = clubbedGaragePayoutsList
                .map((i) => i.netPayout)
                .reduce((a, b) => a + b, 0);
              totalBasic = clubbedGaragePayoutsList
                .map((i) => i.basicPrice)
                .reduce((a, b) => a + b, 0);
            }

            Payout = res.map((i) => i.netPayout).reduce((a, b) => a + b, 0);
            setClubbedNetPayout(
              Payout > 0 && res?.length > 0 ? Payout : totalPayout
            );
            basic = res.map((i) => i.basicPrice).reduce((a, b) => a + b, 0);
            setClubbedBasicPayout(
              basic > 0 && res?.length > 0 ? basic : totalBasic
            );
          } else {
            const visibleRows = state.filter.visibleRowsLookup;
            let visibleItems = [];
            for (const [orderId, value] of Object.entries(visibleRows)) {
              if (value === true) {
                visibleItems.push(orderId);
              }
            }
            const res = bindApprovedPayout.filter((item) =>
              visibleItems.includes(item.orderId)
            );

            let totalPayout = 0;
            let totalBasic = 0;
            if (res?.length == 0) {
              totalPayout = bindApprovedPayout
                .map((i) => i.netPayout)
                .reduce((a, b) => a + b, 0);
              totalBasic = bindApprovedPayout
                .map((i) => i.basicPrice)
                .reduce((a, b) => a + b, 0);
            }

            const Payout = res
              .map((i) => i.netPayout)
              .reduce((a, b) => a + b, 0);
            setGaragePayout(Payout > 0 ? Payout : totalPayout);
            const basic = res
              .map((i) => i.basicPrice)
              .reduce((a, b) => a + b, 0);
            // setFilterBasicPrice(basic);
            setBasicPrice(basic > 0 ? basic : totalBasic);
          }
        }}
        components={{
          Footer: FooterSection,
          Toolbar: CustomToolbar,
        }}
        handleOpenDatePicker={handleOpenDatePicker}
        getRowHeight={() => 60}
        filterModel={filterModel && filterModel}
        filterDateRenge={filterDateRenge && filterDateRenge}
        filteredDate={filteredDate}
        getRowId={(row) => (isGarageCms ? row?.garageId : row?.orderId)}
        loading={loading}
      />
    </>
  );
}

export default GarageApprovedPayout;
