import React, { useEffect, useState, useContext } from "react";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import "./ComparisionComponant.css";
import Carousel from "react-multi-carousel";
import { getComparisions } from "../../../services/dashboard/DashboardServices";
import { useSelector } from "react-redux";
import { CommonContextAPI } from "../../../App";
import moment from "moment";
import LoaderSpinner from "../../loading/LoaderSpinner";

export default function ComparisionComponant() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // const filterDate = useSelector((state) => state?.DateRange_Reducer);

  // const startDate = filterDate?.startDate;
  // const endDate = filterDate?.endDate;

  const commonData = useContext(CommonContextAPI);

  const { filteredDate, defaultDate } = commonData;

  // ----configration ---------------------
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // -------------end----------------------

  const getComparisionsInfo = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }

    try {
      const request = await getComparisions(startDate, endDate, config);
      const response = await request.data;
      setData(response.result);
      setIsLoading(false);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (filteredDate || defaultDate) {
      getComparisionsInfo();
      setIsLoading(true);
    }
  }, [, filteredDate, defaultDate]);
  return (
    <>
      <div className="comparisionContainer">
        <div className="section-two-cards">
          <div className="section-two-card-header">
            <h6 className="section-two-card-title">Orders</h6>
            <img
              src={process.env.PUBLIC_URL + "/Assets/dashboard/orders.png"}
              className="section-two-card-image"
            />
          </div>
          <div className="section-two-card-body">
            {isLoading ? (
                <LoaderSpinner />
            ) : (
              <>
                <div className="mainValue-section">
                  <h2 className="mainvalue">{data?.ordersCount}</h2>
                  {data?.ordersCountDifference >= 0 ? (
                    <p>
                      + {data?.ordersCountDifference}{" "}
                      <NorthIcon style={{ color: "green", fontSize: "17px" }} />
                    </p>
                  ) : (
                    <p>
                      {data?.ordersCountDifference}{" "}
                      <SouthIcon style={{ color: "red", fontSize: "17px" }} />
                    </p>
                  )}
                </div>
                <p className="two-card-bottom">Compare to last time</p>
              </>
            )}
          </div>
        </div>

        <div className="section-two-cards">
          <div className="section-two-card-header">
            <h6 className="section-two-card-title">Gross Profit</h6>
            <img
              src={
                process.env.PUBLIC_URL + "/Assets/dashboard/gross profit.png"
              }
              className="section-two-card-image"
            />
          </div>
          <div className="section-two-card-body">
            {isLoading ? (
                <LoaderSpinner />
            ) : (
              <>
                <div className="mainValue-section">
                  <h2 className="mainvalue">{data?.grossProfit}</h2>
                  {data?.grossProfitDifference >= 0 ? (
                    <p>
                      + {data?.grossProfitDifference}{" "}
                      <NorthIcon style={{ color: "green", fontSize: "17px" }} />
                    </p>
                  ) : (
                    <p>
                      {data?.grossProfitDifference}{" "}
                      <SouthIcon style={{ color: "red", fontSize: "17px" }} />
                    </p>
                  )}
                </div>
                <p className="two-card-bottom">Compare to last time</p>
              </>
            )}
          </div>
        </div>

        <div className="section-two-cards">
          <div className="section-two-card-header">
            <h6 className="section-two-card-title">Leads</h6>
            <img
              src={process.env.PUBLIC_URL + "/Assets/dashboard/leads.png"}
              className="section-two-card-image"
            />
          </div>
          <div className="section-two-card-body">
            {isLoading ? (
                <LoaderSpinner />
            ) : (
              <>
                <div className="mainValue-section">
                  <h2 className="mainvalue">{data?.leadsCount}</h2>
                  {data?.leadsCountDifference >= 0 ? (
                    <p>
                      + {data?.leadsCountDifference}{" "}
                      <NorthIcon style={{ color: "green", fontSize: "17px" }} />
                    </p>
                  ) : (
                    <p>
                      {data?.leadsCountDifference}{" "}
                      <SouthIcon style={{ color: "red", fontSize: "17px" }} />
                    </p>
                  )}
                </div>
                <p className="two-card-bottom">Compare to last time</p>
              </>
            )}
          </div>
        </div>

        <div className="section-two-cards">
          <div className="section-two-card-header">
            <h6 className="section-two-card-title">Revenue</h6>
            <img
              src={process.env.PUBLIC_URL + "/Assets/dashboard/revenue.png"}
              className="section-two-card-image"
            />
          </div>
          <div className="section-two-card-body">
            {isLoading ? (
                <LoaderSpinner />
            ) : (
              <>
                <div className="mainValue-section">
                  <h2 className="mainvalue">{data?.orderRevenue}</h2>
                  {data?.orderRevenueDifference >= 0 ? (
                    <p>
                      + {data?.orderRevenueDifference}{" "}
                      <NorthIcon style={{ color: "green", fontSize: "17px" }} />
                    </p>
                  ) : (
                    <p>
                      {data?.orderRevenueDifference}{" "}
                      <SouthIcon style={{ color: "red", fontSize: "17px" }} />
                    </p>
                  )}
                </div>
                <p className="two-card-bottom">Compare to last time</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="carouselContainer">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={3500}
          centerMode={false}
          className="ccc"
          // containerClass="container"
          dotListClass="dot-class"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderDotsOutside={false}
          responsive={{
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 1,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          <div className="section-two-cards">
            <div className="section-two-card-header">
              <h6 className="section-two-card-title">Orders</h6>
              <img
                src={process.env.PUBLIC_URL + "/Assets/dashboard/orders.png"}
                className="section-two-card-image"
              />
            </div>
            <div className="section-two-card-body">
              <div className="mainValue-section">
                <h2 className="mainvalue">{data?.ordersCount}</h2>
                {data?.ordersCountDifference >= 0 ? (
                  <p>
                    + {data?.ordersCountDifference}
                    <NorthIcon style={{ color: "green", fontSize: "16px" }} />
                  </p>
                ) : (
                  <p>
                    {" "}
                    {data?.ordersCountDifference}{" "}
                    <SouthIcon style={{ color: "red", fontSize: "16px" }} />
                  </p>
                )}
              </div>
              <p className="two-card-bottom">Compare to last time</p>
            </div>
          </div>

          <div className="section-two-cards">
            <div className="section-two-card-header">
              <h6 className="section-two-card-title">Gross Profit</h6>
              <img
                src={process.env.PUBLIC_URL + "/Assets/dashboard/orders.png"}
                className="section-two-card-image"
              />
            </div>
            <div className="section-two-card-body">
              <div className="mainValue-section">
                <h2 className="mainvalue">{data?.grossProfit}</h2>
                {data?.grossProfitDifference >= 0 ? (
                  <p>
                    + {data?.grossProfitDifference}
                    <NorthIcon style={{ color: "green", fontSize: "16px" }} />
                  </p>
                ) : (
                  <p>
                    {" "}
                    {data?.grossProfitDifference}{" "}
                    <SouthIcon style={{ color: "red", fontSize: "16px" }} />
                  </p>
                )}
              </div>
              <p className="two-card-bottom">Compare to last time</p>
            </div>
          </div>

          <div className="section-two-cards">
            <div className="section-two-card-header">
              <h6 className="section-two-card-title">Leads</h6>
              <img
                src={process.env.PUBLIC_URL + "/Assets/dashboard/orders.png"}
                className="section-two-card-image"
              />
            </div>
            <div className="section-two-card-body">
              <div className="mainValue-section">
                <h2 className="mainvalue">{data?.leadsCount}</h2>
                {data?.leadsCountDifference >= 0 ? (
                  <p>
                    + {data?.leadsCountDifference}
                    <NorthIcon style={{ color: "green", fontSize: "16px" }} />
                  </p>
                ) : (
                  <p>
                    {" "}
                    {data?.leadsCountDifference}{" "}
                    <SouthIcon style={{ color: "red", fontSize: "16px" }} />
                  </p>
                )}
              </div>
              <p className="two-card-bottom">Compare to last time</p>
            </div>
          </div>

          <div className="section-two-cards">
            <div className="section-two-card-header">
              <h6 className="section-two-card-title">Revenue</h6>
              <img
                src={process.env.PUBLIC_URL + "/Assets/dashboard/orders.png"}
                className="section-two-card-image"
              />
            </div>
            <div className="section-two-card-body">
              <div className="mainValue-section">
                <h2 className="mainvalue">{data?.orderRevenue}</h2>
                {data?.orderRevenueDifference >= 0 ? (
                  <p>
                    + {data?.orderRevenueDifference}
                    <NorthIcon style={{ color: "green", fontSize: "16px" }} />
                  </p>
                ) : (
                  <p>
                    {" "}
                    {data?.orderRevenueDifference}{" "}
                    <SouthIcon style={{ color: "red", fontSize: "16px" }} />
                  </p>
                )}
              </div>
              <p className="two-card-bottom">Compare to last time</p>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
}
