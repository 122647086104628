import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@mui/icons-material/Edit";
import IssueDetails from "../../modules/customerService/IssueDetails";

const useStyles = makeStyles((theme) => ({
  customerName: {
    fontSize: "1.3rem",
  },
  ticketNumber: {
    fontSize: "0.9rem !important",
  },
  createdDate: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  vehicle: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  brandModel: {
    fontSize: "0.8rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  checkbox: {
    transform: "scale(1.4)",
    color: "#122164 !important",
  },
  subHeading: {
    color: "#707070",
  },
  subValues: {
    fontSize: "0.8rem",
    color: "#000",
  },
  edit: {
    display: "flex",
    alignItems: "center",
    color: "#000",
    "& span": {
      color: "#000",
      fontWeight: "600 !important",
    },
  },
  sections: {
    padding: "1rem",
  },
  customerIssueContainer: {
    width: "100%",
    margin: "0.5rem 0px 1rem 0px",
    minHeight: "100px",
  },
  customerIssue: {
    fontSize: "1rem !important",
  },
  viewDetails: {
    width: "100%",
    backgroundColor: "#122164 !important",
  },
  statusCustomerOwnerContainer: {
    marginTop: "0.5rem !important",
  },
  statusCustomerOwner: {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    backgroundColor: "inherit",
    border: "1px solid black",
    textAlign: "center",
    fontSize: "1.2rem",
    whiteSpace: "nowrap",
  },
}));

const IssueTickets = ({
  lead,
  setOpenCustomerIssueTrackerDetails,
  setIsIssueAdded,
  setIsEdit,
  getTicketNumber,
  // setSendData,
}) => {
  const classes = useStyles();
  const [sendData, setSendData] = useState({});

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Card
          style={{
            borderRadius: "0.8rem",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <CardContent style={{ padding: "0" }}>
            <Box className={classes.sections}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Box>
                  <Checkbox className={classes.checkbox} />
                </Box>
                <Box>
                  <Button
                    variant="default"
                    className={classes.edit}
                    onClick={() => {
                      setOpenCustomerIssueTrackerDetails(true);
                      // setSendData(lead);
                      getTicketNumber(lead?.ticketNo);
                      setIsEdit(true);
                    }}
                  >
                    <span>Edit </span>
                    <EditIcon />{" "}
                  </Button>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "1rem 0px",
                }}
              >
                <Box>
                  <h5 className={classes.customerName}>{lead.customerName}</h5>
                  <Typography>
                    <span className={classes.subHeading}>Ticket No.</span>
                    <span className={classes.subValues}>{lead.ticketNo}</span>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p className={classes.createdDate}>
                    <span className={classes.subHeading}>Created Date: </span>
                    <span className={classes.subValues}>
                      {lead.createdDate}
                    </span>
                  </p>
                  <p className={classes.vehicle}>
                    <span className={classes.subHeading}>Vehicle: </span>
                    <span className={classes.subValues}>
                      {lead.vehicleType}
                    </span>
                  </p>
                  <p className={classes.brandModel}>
                    <span className={classes.subHeading}>Brand & Model: </span>
                    <span
                      className={classes.subValues}
                    >{`${lead.brand} ${lead.model}`}</span>
                  </p>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className={classes.sections}>
              <Box className={classes.customerIssueContainer}>
                <Typography>{lead.customerIssue}</Typography>
              </Box>
              <Button
                variant="contained"
                className={classes.viewDetails}
                onClick={() => {
                  setOpenCustomerIssueTrackerDetails(true);
                  // setSendData(lead);
                  getTicketNumber(lead?.ticketNo);
                  setIsEdit(false);
                }}
              >
                View Details
              </Button>
              <Grid
                container
                spacing={2}
                className={classes.statusCustomerOwnerContainer}
              >
                <Grid item xs={6} sm={6} className={classes.status}>
                  <Typography className={classes.statusCustomerOwner}>
                    {lead.status || "Status: --"}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.customerOwner}>
                  <Typography className={classes.statusCustomerOwner}>
                    {lead.serviceAdvisor?.split(" ")[0] || "Advisor: --"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default IssueTickets;
