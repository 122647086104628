import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import moment from "moment";

import { useSelector } from "react-redux";
import { CommonContextAPI } from "../../../App";
import { getOrders } from "../../../services/dashboard/DashboardServices";
import LoaderSpinner from "../../loading/LoaderSpinner";
Chart.register(CategoryScale);

const orderSalesOptions = {
  plugins: {
    legend: {
      display: true,
    },
    datalabels: {
      display: true,
      anchor: "end",
      align: "top",
      offset: 5,
    },
    title: {
      display: true,
      text: "Vehicle Type Wise Orders",
      font: {
        size: 20,
      },
      padding: {
        bottom: 20,
      },
    },
  },
  elements: {
    line: {
      tension: 0.2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        minTicksLimit: 0,
        maxTicksLimit: 10,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        minTicksLimit: 0,
        maxTicksLimit: 5,
      },
    },
  },
  layout: {
    padding: {
      top: 20,
    },
  },
};

export default function VehicleTypeSales({ filterButtons }) {
  const [orderSales, setOrderSales] = useState([]);
  const [salesGroup, setSalesGroup] = useState("week");
  const { activeButton, inactiveButton } = filterButtons;
  const [isLoading, setIsLoading] = useState(true);

  const filterDate = useSelector((state) => state?.DateRange_Reducer);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  const { filteredDate, defaultDate } = commonData;

  const searchData = useSelector((state) => state.LeadsReducer);

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;

  const getOrderSalesInfo = async (salesGroup) => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate = moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate = moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate = moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate = moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        fromDate: startDate,
        toDate: endDate,
        groupBy: salesGroup,
      },
    };

    try {
      const request = await getOrders(config);
      const response = request.data;

      // Check if response.result is an object with vehicle types
      if (typeof response.result === "object") {
        // Combine counts for Car and Bike on the same date
        const combinedData = {};

        Object.keys(response.result).forEach((vehicleType) => {
          response.result[vehicleType].forEach((entry) => {
            const date = moment(entry.date).format("YYYY-MM-DD");
            const count = entry.orderCount;

            if (!combinedData[date]) {
              combinedData[date] = {
                date,
                carCount: 0,
                bikeCount: 0,
              };
            }

            // Assign count to the corresponding vehicle type
            if (vehicleType === "Car") {
              combinedData[date].carCount += count;
            } else if (vehicleType === "Bike") {
              combinedData[date].bikeCount += count;
            }
          });
        });

        // Convert combinedData object to an array
        const flattenedData = Object.values(combinedData);

        setOrderSales(flattenedData);
        setIsLoading(false);
      } else {
        console.error("Invalid response structure:", response);
        // Handle the error or set a default value for orderSales
        setOrderSales([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error fetching order sales:", err);
      // Handle the error or set a default value for orderSales
      setOrderSales([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filteredDate || defaultDate) {
      getOrderSalesInfo(salesGroup);
    }
  }, [filteredDate, defaultDate, salesGroup]);

  return (
    <div className="salesSection">
      <div className="Vehicle-header">
        <div className="sales-title-section">
          {/* <p>Overview</p> */}
          <h6>Vehicle Type Sales</h6>
        </div>
        <div className="filter-section">
          <button
            style={salesGroup === "year" ? activeButton : inactiveButton}
            onClick={() => setSalesGroup("year")}
          >
            Year
          </button>
          <button
            style={salesGroup === "month" ? activeButton : inactiveButton}
            onClick={() => setSalesGroup("month")}
          >
            Month
          </button>
          <button
            style={salesGroup === "week" ? activeButton : inactiveButton}
            onClick={() => setSalesGroup("week")}
          >
            Week
          </button>
        </div>
      </div>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <div>
          <Bar
            height="400px"
            width="600px"
            data={{
              labels: orderSales?.map((i) => {
                let date = new Date(i.date);

                if (salesGroup === "week") {
                  let prefixes = ["W1", "W2", "W3", "W4", "W5"];
                  let label = "";
                  if (orderSales.length <= 5) {
                    label =
                      moment(i.date).format("MMM") +
                      " " +
                      prefixes[Math.floor(date.getDate() / 7)];
                  } else if (orderSales.length <= 10) {
                    label =
                      prefixes[Math.floor(date.getDate() / 7)] +
                      " " +
                      moment(i.date).format("MMM");
                  } else {
                    label =
                      prefixes[Math.floor(date.getDate() / 7)] +
                      " " +
                      moment(i.date).format("MMM YY");
                  }
                  return label;
                } else if (salesGroup === "month") {
                  return orderSales.length <= 12
                    ? moment(i.date).format("MMM YYYY")
                    : moment(i.date).format("MMM YY");
                } else if (salesGroup === "year") {
                  return moment(i.date).format("YYYY");
                }
              }),
              datasets: [
                {
                  label: "Car",
                  data: orderSales.map((entry) => entry.carCount),
                  stack: "stack",
                  backgroundColor: "#122164",
                  maxBarThickness: 20,
                  barPercentage: 0.5,
                },
                {
                  label: "Bike",
                  data: orderSales.map((entry) => entry.bikeCount),
                  stack: "stack",
                  backgroundColor: "#7B8BD4",
                  maxBarThickness: 20,
                  barPercentage: 0.5,
                },
              ],
            }}
            plugins={[ChartDataLabels]}
            options={{
              ...orderSalesOptions,

              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    minTicksLimit: 0,
                    maxTicksLimit: 10,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
                y: {
                  grid: {
                    display: true,
                  },
                  ticks: {
                    minTicksLimit: 0,
                    maxTicksLimit: 5,
                  },
                },
              },
              layout: {
                padding: {
                  top: 20,
                },
              },
              indexAxis: "x",
              barPercentage: 0.5,
              categoryPercentage: 1,
              plugins: {
                datalabels: {
                  formatter: function (value, context) {
                    // Display the count in the middle of the bar
                    return context.dataset.label === "Car" ? value : value;
                  },
                  color: "white", // You can customize the color of the label
                  anchor: "center", // Adjust the anchor to center
                  align: "center", // Adjust the alignment to center
                  offset: function (context) {
                    // Offset based on dataset to position labels in the middle
                    return context.dataset.label === "Car" ? 0 : 10;
                  },
                  title: {
                    display: true,
                    text: "Vehicle Type Wise Orders",
                  },
                  font: {
                    size: 10, // You can adjust the font size
                  },
                  
                },
              },
              elements: {
                bar: {
                  borderRadius: 5, // Adjust the value based on your preference
                },
              },
            }}
          />
        </div>
      )}
    </div>
  );
}

// // Helper function to generate a random color
// function getRandomColor() {
//   return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
// }
