import React, { useState, useEffect, useContext } from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { addMaterialLib } from "../../services/inventoryServices/InventoryServices";

import { getBrands } from "../../services/inventoryServices/InventoryServices";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";

const AddMaterial = ({ setIsMaterialAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [brandsList, setBrandsList] = useState([]);

  // GET CONTEXT COMMON DATA
  const { handleCloseAddMaterialLibModel } = useContext(CommonContextAPI);

  const getAllBrandsList = async () => {
    try {
      const response = await getBrands();
      const data = await response.data;
      setBrandsList(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBrandsList();
  }, []);

  const renderForm = [
    {
      label: "Name",
      fields: [
        {
          label: "Name",
          field: "TextField",
          name: "name",
          id: "name",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Brand",
      fields: [
        {
          label: "Brand",
          field: "Dropdown",
          name: "brand",
          id: "brand",
          type: "select",
          data: brandsList,
          properties: { name: "brand", id: "id" },
          postId: "brandId",
          grids: 12,
        },
      ],
    },
    {
      label: "Grade",
      fields: [
        {
          label: "Grade",
          field: "TextField",
          name: "grade",
          id: "grade",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "HSN",
      fields: [
        {
          label: "HSN",
          field: "TextField",
          name: "hsn",
          id: "hsn",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Description",
      fields: [
        {
          label: "Description",
          field: "TextField",
          name: "description",
          id: "description",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "GST Rate (%)",
      fields: [
        {
          label: "GST Rate (%)",
          field: "TextField",
          name: "gst",
          id: "gst",
          type: "number",
          grids: 12,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Material"
        closeModal={handleCloseAddMaterialLibModel}
        isAdded={setIsMaterialAdded}
        renderForm={renderForm}
        service={addMaterialLib}
      />
    </div>
  );
};

export default AddMaterial;
