import React, { useState, useEffect } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import "./InspectionReport.css";
import {Typography} from "@mui/material";

export default function Test() {
    const [InspectionData, setInspectionData] = useState([]);
  const [isChecke, setisChecke] = useState({ checked: false, heading: "" });
  const [issue, setIssue] = useState();

  const [allIssues, setAllIssues] = useState([]);
 

  const bikeInspectionData = [
    {
      id: 1,
      name: "Ignition",
      activity: ["Button Start", "Kick Start"],
    },
    {
      id: 2,
      name: "Engine",
      activity: [
        "Engine Oil  ",
        "Engine Noise ",
        "Engine Oil Leakage",
        "Engine Operation",
        "Smoke Condition",
      ],
    },
    {
      id: 3,
      name: "Transmission",
      activity: [
        "Accelerator Play",
        "Jerk While Acceleration",
        "Accelerator Cable",
        "Chain Noise",
        "Gear Shifting",
      ],
    },
    {
      id: 4,
      name: "Braking",
      activity: ["Braking Operations", " Brake Oil Lever"],
    },
    {
      id: 5,
      name: "Suspension",
      activity: [
        "Comfort Jerk and Vibration Check",
        "Front Fork Out",
        "Rear Shock Absorber",
      ],
    },
    {
      id: 6,
      name: "Chassis & Wheels",
      activity: [
        "Bike Frame and Joints",
        "Petrol Leakage",
        "Tyre Pressure",
        "Tyre Wear and Tear",
        "Wheel Rim Dent and Cracks",
        "Wheel & Tyre",
      ],
    },
    {
      id: 7,
      name: "Electronics",
      activity: [
        "Lighting",
        "Indicators and Buzzers",
        "Battery Voltage Level",
        "Horn",
        "Speedomete",
      ],
    },
  ];
  const carInspectionData = [
    {
      id: 1,
      name: "Ignition",
      activity: ["Car Starting", "Remote Key"],
    },
    {
      id: 2,
      name: "Engine",
      activity: [
        "Engine Oil Level",
        "Engine Oil Leakage",
        "Engine Noise Level",
        "Engine Mounting",
        "Coolant Level",
      ],
    },
    {
      id: 3,
      name: "Transmission",
      activity: [
        "Acceleration Operation",
        "Gear Shifting",
        "Clutch Operation",
        "Steering Operation",
      ],
    },
    {
      id: 4,
      name: "Braking",
      activity: [
        "Braking Operations",
        "Brake Pad Thickness",
        "Brake Disc Wear and Tear",
      ],
    },
    {
      id: 5,
      name: "Suspension",
      activity: [
        "Suspension Comfort",
        "Jerks and Vibration",
        "Shock Absorber Leakage",
      ],
    },
    {
      id: 6,
      name: "Chassis & Wheels",
      activity: [
        "Car Frame and Body",
        "Tyre Pressure",
        "Tyre Wear and Tear",
        "Wheel Dent and Cracks",
      ],
    },
    {
      id: 7,
      name: "Electronics",
      activity: [
        "Lighting",
        "Indicators Buzzers and Horn",
        "Battery Voltage Level",
        "Window and Wipers",
      ],
    },
    {
      id: 8,
      name: "Air Conditioning",
      activity: ["AC Cooling and Heating", "Rear Defogger"],
    },
  ];

  const length = InspectionData.length / 2;
  const InspectionData1 = InspectionData.slice(0, length);
  const InspectionData2 = InspectionData.slice(length);

   

  const backEndData = [
    {
      heading: "Engine Oil Level",
      remark: "oil level is low",
    },
    {
      heading: "Steering Operation",
      remark: "is not working proper",
    },
  ];

  const handelRemoveMark = (index) => {
    let Temp = [...allIssues];
    Temp.splice(index, 1);

    setAllIssues(Temp);
  };

  useEffect(() => {
    setAllIssues([...allIssues, ...backEndData]);

    setInspectionData(carInspectionData)
  }, []);
  return (
    <div className="initInspectionContainer">
      <div className="inspectionActivites">

        <div className="activitySection">
          {InspectionData1?.map((i) => {
            return (
              <div >
                <Typography className="inspectionName" >{i.name}</Typography>
                <div className="activityContainer">
                  {i.activity.map((l,index) => {
                    return (
                      <div key={index}>
                        <div className="activites">
                          <Typography className="activityName" style={{color:'gray',marginTop:'5px'}}>{l}</Typography>
                          <span
                            style={{
                              //   backgroundColor: "red",
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={(e) =>
                              !allIssues.find((r) => r.heading === l) &&
                              !isChecke.checked
                                ? setisChecke({ checked: true, heading: l })
                                : setisChecke({ checked: false, heading: "" })
                            }
                          >
                            {allIssues.find((r) => r.heading === l) ? (
                              <ErrorIcon style={{ color: "orange" }} />
                            ) : (
                              <CheckCircleIcon style={{ color: "green" }} />
                            )}
                          </span>
                        </div>
                        {isChecke.checked && isChecke.heading === l && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            <input className="myInput"
                              onChange={(e) => {
                                setIssue({
                                  heading: l,
                                  remark: e.target.value,
                                });
                              }}
                            />{" "}
                            <CheckIcon
                              style={{
                                backgroundColor: "#122167",
                                color: "white",
                                margin: "2px",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setAllIssues([...allIssues, issue]);
                                setisChecke({ checked: false, heading: "" });
                              }}
                            />{" "}
                          </div>
                        )}
                        {allIssues.map((r, index) => {
                          if (r.heading === l) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Typography style={{ color: "red" }}>
                                  {r.remark}
                                </Typography>{" "}
                                <DeleteIcon
                                  style={{ color: "red" }}
                                  onClick={() => handelRemoveMark(index)}
                                />{" "}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="activitySection">
          {InspectionData2?.map((i) => {
            return (
              <div >
                <Typography className="inspectionName">{i.name}</Typography>
                <div className="activityContainer">
                  {i.activity.map((l,index) => {
                    return (
                      <div key={index} style={{marginTop:'5px'}}>
                        <div className="activites">
                          <Typography className="activityName" style={{color:'gray',marginTop:'5px'}}>{l}</Typography>
                          <span
                            style={{
                              //   backgroundColor: "red",
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={(e) =>
                              !allIssues.find((r) => r.heading === l) &&
                              !isChecke.checked
                                ? setisChecke({ checked: true, heading: l })
                                : setisChecke({ checked: false, heading: "" })
                            }
                          >
                            {allIssues.find((r) => r.heading === l) ? (
                              <ErrorIcon style={{ color: "orange" }} />
                            ) : (
                              <CheckCircleIcon style={{ color: "green" }} />
                            )}
                          </span>
                        </div>
                        {isChecke.checked && isChecke.heading === l && (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                            <input className="myInput"
                              onChange={(e) => {
                                setIssue({
                                  heading: l,
                                  remark: e.target.value,
                                });
                              }}
                            />{" "}
                            <CheckIcon
                              style={{
                                backgroundColor: "#122167",
                                color: "white",
                                margin: "2px",
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setAllIssues([...allIssues, issue]);
                                setisChecke({ checked: false, heading: "" });
                              }}
                            />{" "}
                          </div>
                        )}
                        {allIssues.map((r, index) => {
                          if (r.heading === l) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {" "}
                                <Typography style={{ color: "red", fontWeight:'600' }}>
                                  {r.remark}
                                </Typography>{" "}
                                <DeleteIcon
                                  style={{ color: "red" }}
                                  onClick={() => handelRemoveMark(index)}
                                />{" "}
                              </div>
                            );
                          }
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
}
