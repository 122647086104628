import React, { useMemo, useState, useEffect, useContext } from "react";
// import "./Dashboard.css";
import { styled } from "@mui/material/styles";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../modules/dashboard/Dashboard.css";
import moment from "moment";
import { useSelector } from "react-redux";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Box,
  Grid,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Button,
  ButtonGroup,
  Avatar,
  MenuItem,
  FormControl,
  Select,
  CardHeader,
  Rating,
  IconButton,
  Table,
  Typography,
  TextField,
} from "@mui/material";
import {
  ResponsiveContainer,
  Line,
  LineChart,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  CartesianGrid,
  BarChart,
  Tooltip,
  Legend,
  Bar,
  AreaChart,
  Area,
  Cell,
} from "recharts";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ModuleHeader from "../headers/ModuleHeader";
import { CommonContextAPI } from "../../App";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function DashboardComponent({ propsData }) {
  const theme = useTheme();

  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const belowTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.only("md"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));
  const extraLarge = useMediaQuery(theme.breakpoints.up("xlg"));

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    filteredDate,
    handleOpenDatePicker,
    dayFilter,
    renderCustomizedLabel,
    handleDayFilterChange,
    clearAppliedFilters,
  } = commonData;

  const { saleByVehicleType, saleByVehicleTypeHeading } =
    propsData?.saleByVehicleType;

  const { serviceSalesHeading, saleByServiceType } =
    propsData?.saleByServiceType;
  const { saleByGarage, saleByGarageHeading } = propsData?.saleByGarage;

  // const saleByGarage = null;
  // const saleByGarageHeading = null;

  const { grossProfitHeading, grossProfitData } = propsData?.grossProfit;

  const { compareSections, compareSectionsView } = propsData?.comparison;

  const { salesHeading, sales, salesDataKey } = propsData?.salesGraph;

  const { setYearToggle, YearToggle, setYearsToggle, YearsToggle } =
    propsData?.graphFilters;

  const {
    handleSalesYears,
    handleSalesMonths,
    handleSalesWeeks,
    handleSalesDays,
  } = propsData?.graphFilterFunctions;

  const latestOrdersReview = propsData?.latestOrdersReview;

  const { goalHeading, goal, goalOverviewValues, actualSale, COLORS } =
    propsData?.goalOverview;

  const productIn = propsData?.productIn;
  const productOut = propsData?.productOut;
  const viewMoreGarageSale = propsData?.viewMoreGarageSale;
  const isViewMoreGarageSale = propsData?.isViewMoreGarageSale;

  const isViewMoreServiceSale = propsData?.isViewMoreServiceSale;
  const viewMoreServiceSale = propsData?.viewMoreServiceSale;

  const viewMoreVendorSale = propsData?.viewMoreVendorSale;
  const isViewMoreVendorSale = propsData?.isViewMoreVendorSale;

  const viewMoreMaterialSale = propsData?.viewMoreMaterialSale;
  const isViewMoreMaterialSale = propsData?.isViewMoreMaterialSale;
  //   const { productInHeading, productInGraphData } = propsData?.productIn;
  //   const { productOutHeading, productOutGraphData } = propsData?.productOut;

  const isMainDashboard = window.location.pathname == "/";
  const isInventoryDashboard =
    window.location.pathname == "/inventorydashboard";
  const isGrossProfitDashboard = window.location.pathname == "/gpdashboard";

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "14" },
      },
    },
    legend: {
      display: false,
    },
  };

  // useEffect(() => {
  //   handleSalesMonths();
  // }, []); 
  return ( 
    //============Dashboard main container=============//
    <Box className="container" sx={{ marginTop: "4rem" }}>
      <Box className="dashboard_container ">
        {/*-----------dashboard first container------------*/}
        <Box className="globalDateFilter">
          <Box>
            {filteredDate && (
              <Box>
                <Typography
                  sx={{
                    marginRight: "1rem",
                    fontSize: { xs: "1rem", sm: "1.4rem" },
                  }}
                >
                  {moment(
                    filteredDate[0] && filteredDate[0]?.startDate
                  )?.format("ll")}{" "}
                  -{" "}
                  {moment(filteredDate[0] && filteredDate[0]?.endDate)?.format(
                    "ll"
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          <div className="buttons-box">
            <Button
              type="button"
              variant="contained"
              className="start-to-end-filter"
              onClick={
                filteredDate ? clearAppliedFilters : handleOpenDatePicker
              }
            >
              <h6 className="date-box">
                {filteredDate ? (
                  "Clear Filter"
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CalendarTodayOutlinedIcon />{" "}
                    <span style={{ margin: "0px 0.5rem" }}>
                      Date Filter
                    </span>{" "}
                    <KeyboardArrowDownOutlinedIcon className="buttons-box-downArrow" />
                  </Box>
                )}
              </h6>
            </Button>
          </div>
        </Box>

        {!isGrossProfitDashboard && (
          <Box>
            <Carousel
              responsive={compareSectionsView}
              partialVisible={true}
              arrows={false}
              autoPlay
              autoPlaySpeed={1000}
              containerClass="container"
              className="Carousel"
              additionalTransfrom={5}
              showDots={false}
            >
              {compareSections?.map((item, index) => {
                return (
                  <>
                    {/*---------------------------Order-Grossprofit-Leads-Revenue Section-------------------------*/}
                    <Box
                      container
                      className="box_container"
                      spacing={5}
                      key={index}
                    >
                      <Box>
                        <Box className="box-title">{item.title}</Box>
                        <Box className="comapre-number">
                          <span className="compare-no">{item.item}</span>
                          <span className="number">{item.number}</span>
                          <h5> Compared to last time</h5>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <img className="box-img " src={`${item.img}`} />
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Carousel>
          </Box>
        )}



        {/*----------------------dashboardsecond container--------------------*/}
        <Box>
          <Box sx={{ flexGrow: 1, mt: 1 }}>
            <Grid container direction="row" spacing={2} alignItems="stretch">
              <Grid item md={6} xs={12} lg={isGrossProfitDashboard ? 8 : 4}>
                <Box
                  className="sales"
                  sx={{
                    height: isGrossProfitDashboard
                      ? isMobile
                        ? "auto"
                        : "480px"
                      : "100%",
                    padding: isGrossProfitDashboard ? 0 : "2%",
                  }}
                >
                  {isGrossProfitDashboard ? (
                    <Box
                      sx={{ width: "100%", height: isMobile ? "300" : "476px" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: belowTablet ? "column" : "row",
                          paddingX: "10px",
                        }}
                      >
                        <Box>
                          <Typography className="sub-heading">
                            Overview
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: smallPhone
                                ? "1rem"
                                : belowTablet
                                ? "1.3rem"
                                : "1.9rem",
                            }}
                            className="heading"
                          >
                            {salesHeading}
                          </Typography>
                          {/* <Box className="heading">{salesHeading}</Box> */}
                          {/* <Box>{salesHeading}</Box> */}
                        </Box>
                        <Box className="button-container">
                          <ButtonGroup
                            className="filter-btn"
                            sx={{ width: belowTablet ? "100%" : "auto" }}
                          >
                            <Button
                              variant="contained"
                              name="year"
                              onClick={(e) => {
                                setYearToggle(0);
                                // filterByYear();
                                handleSalesYears(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 0 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 0 ? "white" : "black",
                              }}
                            >
                              Year
                            </Button>
                            <Button
                              variant="contained"
                              name="month"
                              onClick={(e) => {
                                setYearToggle(1);
                                handleSalesMonths(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 1 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 1 ? "white" : "black",
                              }}
                            >
                              month
                            </Button>
                            <Button
                              variant="contained"
                              name="week"
                              onClick={(e) => {
                                setYearToggle(2);
                                handleSalesWeeks(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 2 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 2 ? "white" : "black",
                              }}
                            >
                              Week
                            </Button>
                            <Button
                              variant="contained"
                              name="date"
                              onClick={(e) => {
                                setYearToggle(3);
                                handleSalesDays(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 3 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 3 ? "white" : "black",
                              }}
                            >
                              Days
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      <Divider />
                      <Box sx={{ width: "100%", height: "400px" }}>
                        <ResponsiveContainer
                          options={{ responsive: true }}
                          // aspect={
                          //   isMobile
                          //     ? 1
                          //     : isTablet
                          //     ? 1
                          //     : isLaptop
                          //     ? 2.3
                          //     : large
                          //     ? 2.9
                          //     : extraLarge
                          //     ? 4
                          //     : 3.4
                          // }
                        >
                          <BarChart
                            options={{ responsive: true }}
                            className="bar-chart"
                            width="100"
                            height="100%"
                            data={sales && sales}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="grossProfit"
                              stackId="a"
                              fill="#7B8BD4"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      {/* <Box className="heading">{salesHeading}</Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: belowTablet ? "column" : "row",
                        }}
                      >
                        <Box>
                          <Box className="heading">{salesHeading}</Box>
                          {/* <Typography
                            className="heading"
                            // sx={{
                            //   fontSize: smallPhone
                            //     ? "1rem"
                            //     : belowTablet
                            //     ? "1.3rem"
                            //     : "1.9rem",
                            // }}
                          >
                            {salesHeading}
                          </Typography> */}
                          {/* <Box className="heading">{salesHeading}</Box> */}
                          {/* <Box>{salesHeading}</Box> */}
                        </Box>
                        <Box className="button-container">
                          <ButtonGroup
                            className="filter-btn"
                            sx={{ width: belowTablet ? "100%" : "auto" }}
                          >
                            <Button
                              variant="contained"
                              name="year"
                              onClick={(e) => {
                                setYearToggle(0);
                                // filterByYear();
                                handleSalesYears(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 0 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 0 ? "white" : "black",
                              }}
                            >
                              Year
                            </Button>
                            <Button
                              variant="contained"
                              name="month"
                              onClick={(e) => {
                                setYearToggle(1);
                                handleSalesMonths(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 1 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 1 ? "white" : "black",
                              }}
                            >
                              month
                            </Button>
                            <Button
                              variant="contained"
                              name="week"
                              onClick={(e) => {
                                setYearToggle(2);
                                handleSalesWeeks(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 2 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 2 ? "white" : "black",
                              }}
                            >
                              Week
                            </Button>
                            <Button
                              variant="contained"
                              name="date"
                              onClick={(e) => {
                                setYearToggle(3);
                                handleSalesDays(e);
                              }}
                              style={{
                                backgroundColor:
                                  YearToggle === 3 ? "#122164" : "#E6E6E6",
                                color: YearToggle === 3 ? "white" : "black",
                              }}
                            >
                              Days
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      <Divider />
                      <ResponsiveContainer
                        width="100%"
                        height={isGrossProfitDashboard ? "400px" : "100%"}
                        aspect={
                          isGrossProfitDashboard
                            ? 2.7
                            : isMobile
                            ? 2
                            : isLaptop
                            ? 0.9
                            : extraLarge
                            ? 1
                            : 1.2
                        }
                      >
                        <LineChart
                          className="sales-line-chart"
                          data={sales && sales}
                        >
                          <CartesianGrid horizontal={false} vertical={false} />
                          <XAxis dataKey={salesDataKey} />
                          <YAxis />
                          <Tooltip />
                          <Line dataKey="sale" stackId="a" fill="#E5E5E5" />
                          <Line
                            dataKey="carsale"
                            stackId="b"
                            fill="#FF8042"
                            stroke="none"
                            dot={false}
                          />
                          <Line
                            dataKey="bikesale"
                            stackId="c"
                            fill="#82ca9d"
                            stroke="none"
                            dot={false}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </>
                  )}
                  {/* <Box className="button-container">
                      <ButtonGroup className="filter-btn">
                        <Button
                          variant="contained"
                          name="year"
                          onClick={(e) => {
                            setYearToggle(0);
                            // filterByYear();
                            handleSalesYears(e);
                          }}
                          style={{
                            backgroundColor:
                              YearToggle === 0 ? "#122164" : "#E6E6E6",
                            color: YearToggle === 0 ? "white" : "black",
                          }}
                        >
                          Year
                        </Button>
                        <Button
                          variant="contained"
                          name="month"
                          onClick={(e) => {
                            setYearToggle(1);
                            handleSalesMonths(e);
                          }}
                          style={{
                            backgroundColor:
                              YearToggle === 1 ? "#122164" : "#E6E6E6",
                            color: YearToggle === 1 ? "white" : "black",
                          }}
                        >
                          month
                        </Button>
                        <Button
                          variant="contained"
                          name="week"
                          onClick={(e) => {
                            setYearToggle(2);
                            handleSalesWeeks(e);
                          }}
                          style={{
                            backgroundColor:
                              YearToggle === 2 ? "#122164" : "#E6E6E6",
                            color: YearToggle === 2 ? "white" : "black",
                          }}
                        >
                          Week
                        </Button>
                        <Button
                          variant="contained"
                          name="date"
                          onClick={(e) => {
                            setYearToggle(3);
                            handleSalesDays(e);
                          }}
                          style={{
                            backgroundColor:
                              YearToggle === 3 ? "#122164" : "#E6E6E6",
                            color: YearToggle === 3 ? "white" : "black",
                          }}
                        >
                          Days
                        </Button>
                      </ButtonGroup>
                    </Box> */}
                </Box>
                {/* {isGrossProfitDashboard ? (
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      //  className={classes.grossProfiteBarChart}
                    >
                      <ResponsiveContainer
                        aspect={
                          isGrossProfitDashboard
                            ? 2
                            : isMobile
                            ? 2
                            : isLaptop
                            ? 0.9
                            : extraLarge
                            ? 1
                            : 1.2
                        }
                      >
                        <BarChart
                          width="100"
                          height="100%"
                          data={sales && sales}
                        >
                          <YAxis />
                          <XAxis dataKey="month" />
                          <Tooltip />
                          <Bar
                            dataKey="sale"
                            fill="#8884d8"
                            options={options}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  ) : (
                    <ResponsiveContainer
                      width="100%"
                      height={isGrossProfitDashboard ? "400px" : "100%"}
                      aspect={
                        isGrossProfitDashboard
                          ? 2.7
                          : isMobile
                          ? 2
                          : isLaptop
                          ? 0.9
                          : extraLarge
                          ? 1
                          : 1.2
                      }
                    >
                      <LineChart
                        className="sales-line-chart"
                        data={sales && sales}
                      >
                        <CartesianGrid horizontal={false} vertical={false} />
                        <XAxis dataKey={salesDataKey} />
                        <YAxis />
                        <Tooltip />
                        <Line dataKey="sale" stackId="a" fill="#E5E5E5" />
                        <Line
                          dataKey="carsale"
                          stackId="b"
                          fill="#FF8042"
                          stroke="none"
                          dot={false}
                        />
                        <Line
                          dataKey="bikesale"
                          stackId="c"
                          fill="#82ca9d"
                          stroke="none"
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  )} */}
              </Grid>
              <Grid item md={6} xs={12} lg={isGrossProfitDashboard ? 4 : 4}>
                {isGrossProfitDashboard ? (
                  <Box
                    sx={{ height: isGrossProfitDashboard ? "480px" : "100%" }}
                  >
                    <Box
                      className="goal_overview"
                      sx={{
                        height: "100%",
                      }}
                    >
                      <Box className="heading-box" sx={{ height: "70px" }}>
                        <Box
                          className={
                            isGrossProfitDashboard ? "heading" : "heading"
                          }
                        >
                          {goalHeading}
                        </Box>
                      </Box>
                      <Divider />
                      <Box
                        className="goal-overview-piechart"
                        sx={{
                          height: isTablet || isMobile ? "auto" : "100%",
                          display: "flex",
                          // flexDirection: "column-reverse",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PieChart width={340} height={340} className="piechart">
                          <Pie
                            data={goalOverviewValues}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={140}
                            // innerRadius={120}
                            fill="#8884d8"
                            labelLine={false}
                            label={renderCustomizedLabel}
                          >
                            {goalOverviewValues?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                        {/* <Pie dataKey="value" stackId="a" fill="#7B8BD4" /> */}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ height: "100%" }}>
                    <Box
                      className="goal_overview"
                      sx={{
                        height: latestOrdersReview
                          ? extraLarge
                            ? "350px"
                            : "auto"
                          : isMobile
                          ? "100%"
                          : isLaptop
                          ? "500px"
                          : extraLarge
                          ? "600px"
                          : "auto",
                      }}
                    >
                      <Box className="heading-box">
                        <Box className="heading">{goalHeading}</Box>
                      </Box>
                      <Divider />
                      <Box
                        className="goal-overview-piechart"
                        sx={{
                          // height: isTablet || isMobile ? "auto" : "80%",
                          display: "flex",
                          flexDirection: "column-reverse",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          className="compare-details"
                          sx={{
                            width: "80%",
                            height:
                              !latestOrdersReview && extraLarge
                                ? "150px"
                                : latestOrdersReview && isLaptop
                                ? "auto"
                                : isLaptop
                                ? "170px"
                                : latestOrdersReview && isTablet
                                ? "auto"
                                : isTablet
                                ? "140px"
                                : latestOrdersReview && extraLarge
                                ? "80px"
                                : "auto",
                            marginTop:
                              !latestOrdersReview && isLaptop ? "4rem" : "none",
                          }}
                        >
                          <table sx={{ width: "100%" }}>
                            <tr>
                              <td>Sales Actual</td>
                              <td> {actualSale}</td>
                            </tr>
                            <tr>
                              <td>Target</td>
                              <td>{goal}</td>
                            </tr>
                          </table>
                        </Box>
                        <PieChart
                          width={
                            latestOrdersReview
                              ? extraLarge
                                ? 150
                                : 150
                              : isMobile
                              ? 150
                              : extraLarge
                              ? 250
                              : 180
                          }
                          height={
                            latestOrdersReview
                              ? extraLarge
                                ? 150
                                : 150
                              : isMobile
                              ? 150
                              : extraLarge
                              ? 250
                              : 180
                          }
                          className="piechart"
                        >
                          <Pie
                            data={goalOverviewValues}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={
                              latestOrdersReview
                                ? extraLarge
                                  ? 60
                                  : 50
                                : isMobile
                                ? 50
                                : extraLarge
                                ? 100
                                : 70
                            }
                            fill="#8884d8"
                            labelLine={false}
                            label={renderCustomizedLabel}
                          >
                            {goalOverviewValues?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                        <Pie dataKey="value" stackId="a" fill="#7B8BD4" />
                      </Box>
                    </Box>
                    {/*---------latest review---------*/}
                    {latestOrdersReview && (
                      <Box
                        sx={{
                          height:
                            latestOrdersReview && extraLarge ? "250px" : "auto",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Carousel
                          responsive={
                            latestOrdersReview?.latestOrdersReviewCarouselView
                          }
                          partialVisible={true}
                          arrows={false}
                          infinite
                          autoPlay
                          autoPlaySpeed={2000}
                          containerClass="container"
                          className="Carousel"
                          additionalTransfrom={0}
                          showDots={true}
                        >
                          {latestOrdersReview?.latestReview?.map((item) => {
                            return (
                              <>
                                <Box className="review-card">
                                  <Box className="heading-box">
                                    <Box className="heading">
                                      Latest Order Review
                                    </Box>
                                    <Box></Box>
                                  </Box>
                                  <Divider />

                                  <CardHeader
                                    avatar={
                                      <Avatar
                                        alt={item.name}
                                        src="/static/images/avatar/1.jpg"
                                      />
                                    }
                                    title={
                                      <>
                                        <Box className="reviewer-name">
                                          {item.name}
                                        </Box>
                                        <Box className="rating">
                                          <Rating
                                            name="half-rating-read"
                                            defaultValue={2.5}
                                            precision={0.5}
                                            readOnly
                                          />
                                        </Box>
                                      </>
                                    }
                                  />
                                  <Box className="review">
                                    <p>{item.desp}</p>
                                  </Box>
                                </Box>
                              </>
                            );
                          })}
                        </Carousel>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
              <Grid item md={6} xs={12} lg={isGrossProfitDashboard ? 3 : 4}>
                {isGrossProfitDashboard ? (
                  <Box className="Mehanics" sx={{ height: "100%" }}>
                    <Box className="heading-box">
                      <Box className="heading-container">
                        <Box className="sub-heading">Gross Profit</Box>
                        <Box className="heading">
                          {saleByVehicleTypeHeading}
                        </Box>
                      </Box>
                      {/*------------Garage Name-----------------*/}
                      <Box>
                        <FormControl variant="filled">
                          <Select onChange={handleDayFilterChange}>
                            {dayFilter?.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />
                    {/*-----------------Mechanics image - name- garage name-value------------*/}
                    <Box className="period-sale-container">
                      <Box className="date-filter-box">
                        <TextField
                          sx={{ width: "90%", my: 2 }}
                          size="small"
                          label="Channels"
                          fullWidth
                          select
                          variant="outlined"
                          id="channels"
                          margin="dense"
                          name="channels"
                          // onChange={handleChange}
                          // value={leadsData.country}
                        >
                          {grossProfitData?.map((channel) => (
                            <MenuItem key={channel.id} value={channel.name}>
                              {channel.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Divider />
                        <TextField
                          sx={{ width: "90%", my: 2 }}
                          size="small"
                          label="Channels"
                          fullWidth
                          select
                          variant="outlined"
                          id="channels"
                          margin="dense"
                          name="channels"
                          // onChange={handleChange}
                          // value={leadsData.country}
                        >
                          {grossProfitData?.map((channel) => (
                            <MenuItem key={channel?.id} value={channel?.name}>
                              {channel?.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box className="period-wise-figure">
                        <Typography variant="h4">
                          $ 25000 <sub>+4%</sub>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box className="service_sales" sx={{ height: "100%" }}>
                    <Box className="heading-box">
                      <Box className="heading">{serviceSalesHeading}</Box>
                      {/*----------drop-down Days------------*/}
                      <Box>
                        <FormControl variant="filled">
                          <Select onChange={handleDayFilterChange}>
                            {dayFilter?.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />

                    <Box
                      sx={{
                        height: isMobile ? "86%" : isTablet ? "450px" : "480px",
                        overflow: "auto",
                        scrollbarGutter: "stable",
                      }}
                    >
                      {saleByServiceType && saleByServiceType?.length > 0 ? (
                        saleByServiceType?.map((item, index) => {
                          return (
                            <>
                              <Box className="services" key={index}>
                                <Grid
                                  container
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {/*--------------Service image and image name-----------*/}
                                  <Grid item xs={3} sm={3} md={3} container>
                                    <img
                                      className="service-img"
                                      style={{
                                        width: "40px",
                                        ms: 1,
                                        height: "40px",
                                        filter: isInventoryDashboard
                                          ? `invert(56%) sepia(4%) saturate(5472%) hue-rotate(192deg) brightness(103%) contrast(78%)`
                                          : "auto",
                                      }}
                                      src={`${item.serviceimg}`}
                                    />
                                  </Grid>
                                  {/*-----------------------Service Name-Orders-sale--------------*/}
                                  <Grid item xs={9} sm={9} md={9} container>
                                    <ListItemText
                                      primary={
                                        <Box className="service-name">
                                          {item.services}
                                        </Box>
                                      }
                                      secondary={
                                        <>
                                          <Box className="orders-sales">
                                            <span className="orders">
                                              {item.orders}{" "}
                                            </span>
                                            <span className="service-sales">
                                              {item.sale}
                                            </span>
                                          </Box>
                                        </>
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </>
                          );
                        })
                      ) : (
                        <Typography variant="h5">
                          No Service Wise Sale
                        </Typography>
                      )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {isMainDashboard ? (
                        <Button onClick={viewMoreServiceSale}>
                          {" "}
                          {isViewMoreServiceSale != undefined
                            ? isViewMoreServiceSale
                              ? "View Less"
                              : "View More"
                            : "View More"}{" "}
                        </Button>
                      ) : (
                        <Button onClick={viewMoreMaterialSale}>
                          {" "}
                          {isViewMoreMaterialSale != undefined
                            ? isViewMoreMaterialSale
                              ? "View Less"
                              : "View More"
                            : "View More"}{" "}
                        </Button>
                      )}
                      {/* <Button onClick={viewMoreServiceSale}> {isViewMoreServiceSale != undefined ? isViewMoreServiceSale ? "View Less"  : "View More" : "View More"} </Button> */}
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item md={6} xs={12} lg={isGrossProfitDashboard ? 3 : 4}>
                {isGrossProfitDashboard ? (
                  <Box className="Mehanics" sx={{ height: "100%" }}>
                    <Box className="heading-box">
                      <Box className="heading-container">
                        <Box className="sub-heading">Garage</Box>
                        <Box className="heading" sx={{ m: 0.2 }}>
                          {grossProfitHeading}
                        </Box>
                      </Box>
                      {/*------------Garage Name-----------------*/}
                      <Box>
                        <FormControl variant="filled">
                          <Select onChange={handleDayFilterChange}>
                            {dayFilter?.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />
                    {/*-----------------Mechanics image - name- garage name-value------------*/}
                    <Box className="gross-profit-container">
                      <Box className="garage-gross-profit-box">
                        <TextField
                          sx={{ width: "90%", my: 2 }}
                          size="small"
                          label="Channels"
                          fullWidth
                          select
                          variant="outlined"
                          id="channels"
                          margin="dense"
                          name="channels"
                          // onChange={handleChange}
                          // value={leadsData.country}
                        >
                          {grossProfitData.map((channel) => (
                            <MenuItem key={channel.id} value={channel.name}>
                              {channel.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Typography variant="h4" sx={{ marginTop: "3rem" }}>
                          $ 25000 <sub>+4%</sub>
                        </Typography>
                      </Box>
                      <Box className="location-wise-map">
                        <Typography>Google Map</Typography>
                        {/* <Box>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15129.888782070655!2d73.93721803673257!3d18.552738640862486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3c3288ba495%3A0x38e833613a63004a!2sKharadi%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1668668177099!5m2!1sen!2sin" width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Box> */}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box className="gross_profit" sx={{ height: "100%" }}>
                    <Box className="heading-box ">
                      <Box className="heading">{grossProfitHeading}</Box>
                      <Box className="button-container">
                        <ButtonGroup className="filter-btn">
                          <Button
                            variant="contained"
                            onClick={() => {
                              setYearsToggle(0);
                            }}
                            style={{
                              backgroundColor:
                                YearsToggle === 0 ? "#122164" : "#E6E6E6",
                              color: YearsToggle === 0 ? "white" : "black",
                            }}
                          >
                            Year
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setYearsToggle(1);
                            }}
                            style={{
                              backgroundColor:
                                YearsToggle === 1 ? "#122164" : "#E6E6E6",
                              color: YearsToggle === 1 ? "white" : "black",
                            }}
                          >
                            month
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setYearsToggle(2);
                            }}
                            style={{
                              backgroundColor:
                                YearsToggle === 2 ? "#122164" : "#E6E6E6",
                              color: YearsToggle === 2 ? "white" : "black",
                            }}
                          >
                            Week
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setYearsToggle(3);
                            }}
                            style={{
                              backgroundColor:
                                YearsToggle === 3 ? "#122164" : "#E6E6E6",
                              color: YearsToggle === 3 ? "white" : "black",
                            }}
                          >
                            Days
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                    <Divider />
                    <ResponsiveContainer
                      width="100%"
                      aspect={isLaptop ? 1.2 : 1.5}
                    >
                      <AreaChart className="areachart" data={grossProfitData}>
                        <CartesianGrid />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="sale"
                          stroke="#8884d8"
                          fill="#8884d8"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Box>
                )}
              </Grid>
              <Grid item md={6} xs={12} lg={isGrossProfitDashboard ? 3 : 4}>
                {isGrossProfitDashboard ? (
                  <Box className="Mehanics" sx={{ height: "100%" }}>
                    <Box className="heading-box">
                      <Box className="heading-container">
                        <Box className="sub-heading">Gross Profit</Box>
                        <Box className="heading" sx={{ m: 0.2 }}>
                          {serviceSalesHeading}
                        </Box>
                      </Box>
                      {/*------------Garage Name-----------------*/}
                      <Box>
                        <FormControl variant="filled">
                          <Select onChange={handleDayFilterChange}>
                            {dayFilter?.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />
                    {/*-----------------Mechanics image - name- garage name-value------------*/}
                    <Box
                      sx={{
                        height: "390px",
                        overflow: "auto",
                        scrollbarGutter: "stable",
                      }}
                    >
                      {saleByServiceType && saleByServiceType?.length > 0 ? (
                        saleByServiceType?.map((sale, index) => {
                          return (
                            <Box key={index}>
                              <List sx={{ padding: 0 }}>
                                <ListItem alignItems="flex-start">
                                  {/* <ListItemAvatar>
                              <Avatar
                                alt={sale?.name}
                                src="/static/images/avatar/1.jpg"
                              />
                            </ListItemAvatar> */}

                                  <img
                                    className="service-img"
                                    style={{
                                      width: "40px",
                                      ms: 1,
                                      height: "40px",
                                      filter: isInventoryDashboard
                                        ? `invert(56%) sepia(4%) saturate(5472%) hue-rotate(192deg) brightness(103%) contrast(78%)`
                                        : "auto",
                                    }}
                                    src={`${sale.serviceimg}`}
                                  />

                                  <ListItemText
                                    primary={
                                      <Box className="mechanic-name">
                                        {sale?.service}
                                      </Box>
                                    }
                                    secondary={
                                      <>
                                        <Box className="garage-sales">
                                          <span className="garage-name">
                                            {sale?.service}{" "}
                                          </span>
                                          <span className="mechanics-sales">
                                            <CurrencyRupeeIcon className="rupees-icon" />
                                            {sale?.sale}
                                          </span>
                                        </Box>
                                      </>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                              </List>
                            </Box>
                          );
                        })
                      ) : (
                        <Typography variant="h5">No Garage Sale</Typography>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {isMainDashboard ? (
                        <Button onClick={viewMoreGarageSale}>
                          {isViewMoreGarageSale != undefined
                            ? isViewMoreGarageSale
                              ? "View Less"
                              : "View More"
                            : "View More"}
                        </Button>
                      ) : (
                        <Button onClick={viewMoreVendorSale}>
                          {" "}
                          {isViewMoreVendorSale != undefined
                            ? isViewMoreVendorSale
                              ? "View Less"
                              : "View More"
                            : "View More"}{" "}
                        </Button>
                      )}

                      {/* <Button onClick={viewMoreGarageSale}>{isViewMoreGarageSale != undefined ? isViewMoreGarageSale ? "View Less"  : "View More": "View More"}</Button> */}
                    </Box>
                  </Box>
                ) : (
                  <Box className="vehicle_type_sales" sx={{ height: "100%" }}>
                    <Box className="heading-box ">
                      <Box className="heading">{saleByVehicleTypeHeading}</Box>
                    </Box>
                    <Divider />
                    <ResponsiveContainer
                      aspect={isLaptop ? 1 : extraLarge ? 1.3 : 1.5}
                    >
                      <BarChart
                        className="bar-chart"
                        width="100"
                        height="100%"
                        data={saleByVehicleType && saleByVehicleType}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="bikesale" stackId="a" fill="#7B8BD4" />
                        <Bar dataKey="carsale" stackId="a" fill="#122164" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                )}
              </Grid>
              <Grid item md={6} xs={12} lg={isGrossProfitDashboard ? 3 : 4}>
                {isGrossProfitDashboard ? (
                  <Box className="Mehanics" sx={{ height: "100%" }}>
                    <Box className="heading-box">
                      <Box className="heading-container">
                        <Box className="sub-heading">Gross Profit</Box>
                        <Box className="heading" sx={{ m: 0.2 }}>
                          {saleByGarageHeading}
                        </Box>
                      </Box>
                      {/*------------Garage Name-----------------*/}
                      <Box>
                        <FormControl variant="filled">
                          <Select onChange={handleDayFilterChange}>
                            {dayFilter?.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />
                    {/*-----------------Mechanics image - name- garage name-value------------*/}
                    <Box
                      sx={{
                        height: "390px",
                        overflow: "auto",
                        scrollbarGutter: "stable",
                      }}
                    >
                      {saleByGarage && saleByGarage?.length > 0 ? (
                        saleByGarage?.map((sale) => {
                          return (
                            <Box>
                              <List sx={{ padding: 0 }}>
                                <ListItem alignItems="flex-start">
                                  {/* <ListItemAvatar>
                              <Avatar
                                alt={sale?.name}
                                src="/static/images/avatar/1.jpg"
                              />
                            </ListItemAvatar> */}
                                  <img
                                    className="service-img"
                                    style={{
                                      width: "40px",
                                      ms: 1,
                                      height: "40px",
                                      filter: isInventoryDashboard
                                        ? `invert(56%) sepia(4%) saturate(5472%) hue-rotate(192deg) brightness(103%) contrast(78%)`
                                        : "auto",
                                    }}
                                    src={`${sale?.serviceimg}`}
                                  />

                                  <ListItemText
                                    primary={
                                      <Box className="mechanic-name">
                                        {sale?.location}
                                      </Box>
                                    }
                                    secondary={
                                      <>
                                        <Box className="garage-sales">
                                          <span className="garage-name">
                                            {sale?.location}{" "}
                                          </span>
                                          <span className="mechanics-sales">
                                            <CurrencyRupeeIcon className="rupees-icon" />
                                            {sale?.sale}
                                          </span>
                                        </Box>
                                      </>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                              </List>
                            </Box>
                          );
                        })
                      ) : (
                        <Typography variant="h5">No Garage Sale</Typography>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {isMainDashboard ? (
                        <Button onClick={viewMoreGarageSale}>
                          {isViewMoreGarageSale != undefined
                            ? isViewMoreGarageSale
                              ? "View Less"
                              : "View More"
                            : "View More"}
                        </Button>
                      ) : (
                        <Button onClick={viewMoreVendorSale}>
                          {" "}
                          {isViewMoreVendorSale != undefined
                            ? isViewMoreVendorSale
                              ? "View Less"
                              : "View More"
                            : "View More"}{" "}
                        </Button>
                      )}

                      {/* <Button onClick={viewMoreGarageSale}>{isViewMoreGarageSale != undefined ? isViewMoreGarageSale ? "View Less"  : "View More": "View More"}</Button> */}
                    </Box>
                  </Box>
                ) : (
                  <Box className="Mehanics" sx={{ height: "100%" }}>
                    <Box className="heading-box">
                      <Box className="heading">{saleByGarageHeading}</Box>
                      {/*------------Garage Name-----------------*/}
                      <Box>
                        <FormControl variant="filled">
                          <Select onChange={handleDayFilterChange}>
                            {dayFilter?.map((item, index) => {
                              return (
                                <MenuItem value={item.name} key={index}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />
                    {/*-----------------Mechanics image - name- garage name-value------------*/}
                    <Box
                      sx={{
                        height: "390px",
                        overflow: "auto",
                        scrollbarGutter: "stable",
                      }}
                    >
                      {saleByGarage && saleByGarage?.length > 0 ? (
                        saleByGarage?.map((sale, index) => {
                          return (
                            <Box key={index}>
                              <List sx={{ padding: 0 }}>
                                <ListItem alignItems="flex-start">
                                  {/* <ListItemAvatar>
                              <Avatar
                                alt={sale?.name}
                                src="/static/images/avatar/1.jpg"
                              />
                            </ListItemAvatar> */}
                                  <ListItemText
                                    primary={
                                      <Box className="mechanic-name">
                                        {sale?.garage}
                                      </Box>
                                    }
                                    secondary={
                                      <>
                                        <Box className="garage-sales">
                                          <span className="garage-name">
                                            {sale?.garage}{" "}
                                          </span>
                                          <span className="mechanics-sales">
                                            <CurrencyRupeeIcon className="rupees-icon" />
                                            {sale?.totalPrice}
                                          </span>
                                        </Box>
                                      </>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                              </List>
                            </Box>
                          );
                        })
                      ) : (
                        <Typography variant="h5">No Garage Sale</Typography>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {isMainDashboard ? (
                        <Button onClick={viewMoreGarageSale}>
                          {isViewMoreGarageSale != undefined
                            ? isViewMoreGarageSale
                              ? "View Less"
                              : "View More"
                            : "View More"}
                        </Button>
                      ) : (
                        <Button onClick={viewMoreVendorSale}>
                          {" "}
                          {isViewMoreVendorSale != undefined
                            ? isViewMoreVendorSale
                              ? "View Less"
                              : "View More"
                            : "View More"}{" "}
                        </Button>
                      )}

                      {/* <Button onClick={viewMoreGarageSale}>{isViewMoreGarageSale != undefined ? isViewMoreGarageSale ? "View Less"  : "View More": "View More"}</Button> */}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
            {(productIn || productOut) && (
              <Grid container spacing={3} sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <Box className="product-in">
                    <Box className="heading-box ">
                      <Box className="heading">
                        {productIn?.productInHeading}
                      </Box>
                      <Box className="button-container">
                        <ButtonGroup className="filter-btn">
                          <Button
                            variant="contained"
                            name="year"
                            onClick={(e) => {
                              setYearToggle(0);
                              // filterByYear();
                              handleSalesYears(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 0 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 0 ? "white" : "black",
                            }}
                          >
                            Year
                          </Button>
                          <Button
                            variant="contained"
                            name="month"
                            onClick={(e) => {
                              setYearToggle(1);
                              handleSalesMonths(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 1 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 1 ? "white" : "black",
                            }}
                          >
                            month
                          </Button>
                          <Button
                            variant="contained"
                            name="week"
                            onClick={(e) => {
                              setYearToggle(2);
                              handleSalesWeeks(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 2 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 2 ? "white" : "black",
                            }}
                          >
                            Week
                          </Button>
                          <Button
                            variant="contained"
                            name="date"
                            onClick={(e) => {
                              setYearToggle(3);
                              handleSalesDays(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 3 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 3 ? "white" : "black",
                            }}
                          >
                            Days
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                    <ResponsiveContainer width="100%" aspect={2}>
                      {/* <LineChart className="sales-line-chart" data={productIn?.productInGraphData}> */}
                      <LineChart
                        className="sales-line-chart"
                        data={sales && sales}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={salesDataKey} />
                        <YAxis />
                        <Tooltip />
                        <Line dataKey="sale" stackId="a" fill="#E5E5E5" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="product-out">
                    <Box className="heading-box">
                      <Box className="heading">
                        {productOut?.productOutHeading}
                      </Box>
                      <Box className="button-container">
                        <ButtonGroup className="filter-btn">
                          <Button
                            variant="contained"
                            name="year"
                            onClick={(e) => {
                              setYearToggle(0);
                              // filterByYear();
                              handleSalesYears(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 0 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 0 ? "white" : "black",
                            }}
                          >
                            Year
                          </Button>
                          <Button
                            variant="contained"
                            name="month"
                            onClick={(e) => {
                              setYearToggle(1);
                              handleSalesMonths(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 1 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 1 ? "white" : "black",
                            }}
                          >
                            month
                          </Button>
                          <Button
                            variant="contained"
                            name="week"
                            onClick={(e) => {
                              setYearToggle(2);
                              handleSalesWeeks(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 2 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 2 ? "white" : "black",
                            }}
                          >
                            Week
                          </Button>
                          <Button
                            variant="contained"
                            name="date"
                            onClick={(e) => {
                              setYearToggle(3);
                              handleSalesDays(e);
                            }}
                            style={{
                              backgroundColor:
                                YearToggle === 3 ? "#122164" : "#E6E6E6",
                              color: YearToggle === 3 ? "white" : "black",
                            }}
                          >
                            Days
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                    <ResponsiveContainer width="100%" aspect={2}>
                      {/* <LineChart className="sales-line-chart" data={productOut?.productOutGraphData}> */}
                      <LineChart
                        className="sales-line-chart"
                        data={sales && sales}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={salesDataKey} />
                        <YAxis />
                        <Tooltip />
                        <Line dataKey="sale" stackId="a" fill="#E5E5E5" />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
