import React, { useContext, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HelpIcon from "@mui/icons-material/Help";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useSelector } from "react-redux";
import Table from "../../../components/tables/Table";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../../services/userProfileServices/UserProfileServices";
import { getAllUserRoles } from "../../../services/userProfileServices/UserProfileServices";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CommonContextAPI } from "../../../App";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px !important",
    margin: "0px !important",
  },
  adminActionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "1rem  ",
    },
  },
  notificationIcon: {
    fontSize: "2rem",
  },
  helpIcon: {
    fontSize: "2rem",
  },
  homeIcon: {
    fontSize: "2rem",
  },
  profileIcon: {
    fontSize: "4rem",
  },
  subHeadingWrapper: {
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  subHeading: {
    lineHeight: 1.8,
  },
  textField: {
    width: "100%",
    margin: "0.4rem 0",
  },
  userIcon: {
    fontSize: "6rem",
  },

  papper: {
    padding: "20px",
    width: "40vh",
    height: "50vh",
  },
  formContainer: {
    height: "auto",
    width: "90%",
    margin: "auto",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    display: "none",
  },
  imageAvatar: {
    color: "#000",
    margin: 10,
    height: "100px",
    width: "100px",
    cursor: "pointer",
    position: "relative",
    backgroundColor: "#837c7c",
    width: "140px",
    height: "140px",
    "&:hover": {
      opacity: 0.7,
    },
  },
  eyeIcon: {
    cursor: "pointer",
  },
  gridCol: {
    [theme.breakpoints.down("sm")]: {
      width: "94%",
    },
    "& .MuiGrid-item": {
      paddingLeft: "1px !important",
    },
  },
}));

const userList = [
  {
    id: 1,
    role: "Admin",
  },
  {
    id: 1,
    role: "User",
  },
];

const AddUser = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);
  const [userDetails, setUserDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const navigate = useNavigate();

  const handleErrors = (e) => {
    const { name, value } = e.target;

    if (name == "confirmPassword") {
      if (userDetails?.password !== userDetails?.confirmPassword) {
        setErrors({
          errors,
          [name]: "Password and Confirm Password not same.",
        });
      } else {
        setErrors({
          errors,
          [name]: "",
        });
      }
    }
  };

  const viewPassword = () => {
    setPasswordVisibility((prev) => !prev);
  };

  const checkPasswordStrength = (password) => {
    var strength = 0;
    var tips = "";

    if (password.length < 8) {
      tips += "Password needs to be 8 char long.";
    } else {
      strength += 1;
    }

    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      strength += 1;
    } else {
      tips = "Use both lowercase and uppercase letters. ";
    }

    if (password.match(/\d/)) {
      strength += 1;
    } else {
      tips = "Include at least one number. ";
    }

    if (password.match(/[^a-zA-Z\d]/)) {
      strength += 1;
    } else {
      tips = "Include at least one special character. ";
    }

    if (strength < 2) {
      return {
        msg: "Easy. " + tips,
        level: 1,
      };
    } else if (strength === 2) {
      return {
        msg: "Medium. " + tips,
        level: 2,
      };
    } else if (strength === 3) {
      return {
        msg: "Difficult. " + tips,
        level: 3,
      };
    } else {
      return {
        msg: "Extremely difficult. " + tips,
        level: 4,
      };
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "password") {
      let result = checkPasswordStrength(value);
      setErrors({
        errors,
        ["strength"]: result,
      });
      setUserDetails({ ...userDetails, [name]: value });
      return;
    }

    if (name == "checkbox") {
      setUserDetails({ ...userDetails, [name]: e.target.checked });
    } else {
      setUserDetails({ ...userDetails, [name]: value });
    }
  };

  const clearErrors = (e) => {
    const { name, value } = e.target;

    if (name == "email") {
      setErrors((prev) => ({
        ...prev,
        emailExists: "",
      }));
    }

    if (name == "mobileNumber") {
      setErrors((prev) => ({
        ...prev,
        mobileNumberExists: "",
      }));
    }
    if (name == "userName") {
      setErrors((prev) => ({
        ...prev,
        usernameExists: "",
      }));
    }
  };

  const userSelection = (field, id, value) => {
    setUserDetails({ ...userDetails, [field]: id });
  };

  const handleImage = (e) => {
    const image = e.target.files[0];
    const name = e.target.name;

    // if (image) {
    //   setUserDetails({
    //     ...userDetails,
    //     [name]: image,
    //   });
    // }
  };

  // const generateEmail = () => {
  //   const firstName = userDetails.firstName ? userDetails.firstName : "";
  //   const lastName = userDetails.lastName ? userDetails.lastName : "";

  //   if (firstName && lastName) {
  //     setUserDetails({
  //       ...userDetails,
  //       ["email"]: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@kalyanistudio.com`,
  //     });
  //   }
  // };

  const getUserRoles = async () => {
    try {
      const response = await getAllUserRoles();
      const data = await response.data;
      setUserRoles(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewUser = async (details) => {
    try {
      const response = await addUser(details);
      navigate("/adminConsole/users");
      setErrors((prev) => ({
        ...prev,
        alreadyInUse: "",
      }));
    } catch (error) {
      console.log("error 12", error);

      const err = error.response.data.message;

      if (err?.includes("Mobile Number")) {
        setErrors((prev) => ({
          ...prev,
          mobileNumberExists: err,
        }));
      }

      if (err?.includes("Email")) {
        setErrors((prev) => ({
          ...prev,
          emailExists: err,
        }));
      }

      if (err?.includes("Username")) {
        setErrors((prev) => ({
          ...prev,
          usernameExists: err,
        }));
      }

      // setErrors((prev) => ({
      //   ...prev,
      //   alreadyInUse: err,
      // }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const postObj = {
    //   firstName: userDetails?.firstName,
    //   lastName: userDetails?.lastName,
    //   email: userDetails?.email,
    //   userName: userDetails?.userName,
    //   mobileNumber: userDetails?.mobileNumber,
    //   alternativeMobileNumber: userDetails?.alternativeMobileNumber,
    //   password: userDetails?.password,
    //   confirmPassword: userDetails?.confirmPassword,
    //   role: userDetails?.role,
    // };

    addNewUser(userDetails);

    // setUserDetails({
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   userName: "",
    //   password: "",
    //   confirmPassword: "",
    //   mobileNumber: "",
    //   alternativeMobileNumber: "",
    //   role: "",
    //   picture: {},
    // });
    // navigate("/userProfile/list");
  };

  useEffect(() => {
    getUserRoles();
  }, []);

  return (
    <Box className={classes.container}>
      <Grid container spacing={2} className={classes.adminActionContainer}>
        <Grid item sm={12} md={5}>
          <Typography variant="h5" className="titles">
            Add User
          </Typography>
        </Grid>
        <Grid item sm={12} md={7}>
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid item sm={12} md={8} style={{ width: "100%" }}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Paper>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="secondary">
                  <NotificationsNoneIcon className={classes.notificationIcon} />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <HomeIcon className={classes.homeIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={3} md={1}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <AccountBoxIcon className={classes.profileIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {/* <Box className={classes.subHeadingWrapper}>
        <Typography className={classes.subHeading}>
          Enter the basic user details. The username will get autofilled based
          on the Preferred Email format set. If you prefer any other username
          from the given suggestions, you can edit the username field.
          <Link href="#">Learn more</Link>
        </Typography>
      </Box> */}

      <Grid
        container
        spacing={2}
        // direction="row"
        className={classes.formContainer}
      >
        <Grid item sm={12} md={8}>
          <form
            method="POST"
            onSubmit={handleSubmit}
            enctype="multipart/form-data"
            sx={{ width: "100%" }}
          >
            <Grid container spacing={1}>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  required
                  label="First Name"
                  name="firstName"
                  className={classes.textField}
                  value={userDetails.firstName}
                  onChange={handleChange}
                  // onBlur={generateEmail}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  label="Last Name"
                  name="lastName"
                  className={classes.textField}
                  value={userDetails.lastName}
                  onChange={handleChange}
                  // onBlur={generateEmail}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  required
                  label="Email"
                  name="email"
                  className={classes.textField}
                  value={userDetails.email}
                  onChange={handleChange}
                  onFocus={clearErrors}
                  variant="outlined"
                  autoComplete="off"
                />
                {errors?.emailExists && (
                  <Typography sx={{ color: "red" }}>
                    {" "}
                    {errors?.emailExists}
                  </Typography>
                )}
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  required
                  label="Username"
                  name="userName"
                  className={classes.textField}
                  value={userDetails.userName}
                  onChange={handleChange}
                  onFocus={clearErrors}
                  type="text"
                  inputProps={{
                    autoComplete: "off",
                  }}
                  variant="outlined"
                />
                {errors?.usernameExists && (
                  <Typography sx={{ color: "red" }}>
                    {" "}
                    {errors?.usernameExists}
                  </Typography>
                )}
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <Box sx={{ position: "relative" }}>
                  <TextField
                    size={isMobile ? "small" : "medium"}
                    required
                    label="Password"
                    name="password"
                    type={passwordVisibility ? "text" : "password"}
                    className={classes.textField}
                    value={userDetails.password}
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="new-password "
                  />
                  <Box
                    className={classes.viewPasswordBox}
                    sx={{ position: "absolute", top: "30%", right: "0%" }}
                  >
                    {passwordVisibility ? (
                      <VisibilityIcon
                        className={classes.eyeIcon}
                        onClick={viewPassword}
                      />
                    ) : (
                      <VisibilityOffIcon
                        className={classes.eyeIcon}
                        onClick={viewPassword}
                      />
                    )}
                  </Box>
                </Box>
                {userDetails?.password?.length > 0 && errors?.strength && (
                  <Typography
                    sx={{
                      color:
                        errors.strength.level < 2
                          ? "red"
                          : errors.strength.level == 2
                          ? "orange"
                          : errors.strength.level == 3
                          ? "#22dba8"
                          : "green",
                    }}
                  >
                    {errors.strength?.msg}
                  </Typography>
                )}
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  required
                  disabled={userDetails?.password?.length > 0 ? false : true}
                  label={"Confirm Password"}
                  name="confirmPassword"
                  type="password"
                  className={classes.textField}
                  value={userDetails.confirmPassword}
                  onBlur={(e) => handleErrors(e)}
                  onChange={handleChange}
                  onFocus={clearErrors}
                  variant="outlined"
                  autocomplete="no"
                />
                {errors.confirmPassword && (
                  <Typography sx={{ color: "red" }}>
                    {errors.confirmPassword}
                  </Typography>
                )}
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  required
                  label="Mobile Number"
                  name="mobileNumber"
                  inputProps={{ maxLength: 10 }}
                  className={classes.textField}
                  value={userDetails.mobileNumber}
                  onChange={handleChange}
                  onFocus={clearErrors}
                  variant="outlined"
                  autocomplete="off"
                />
                {errors?.mobileNumberExists && (
                  <Typography sx={{ color: "red" }}>
                    {" "}
                    {errors?.mobileNumberExists}
                  </Typography>
                )}
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  label="alternate Mobile Number"
                  name="alternativeMobileNumber"
                  inputProps={{ maxLength: 10 }}
                  className={classes.textField}
                  value={userDetails.alternativeMobileNumber}
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item sm={12} md={6} className={classes.gridCol}>
                <TextField
                  size={isMobile ? "small" : "medium"}
                  fullWidth
                  label="Role"
                  select
                  id="users"
                  margin="dense"
                  className={classes.textField}
                  name="users"
                >
                  {userRoles.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.role}
                      onClick={() =>
                        userSelection("role", user?.id, user?.role)
                      }
                    >
                      {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                    </MenuItem>
                  ))}
                </TextField>{" "}
              </Grid>

              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={userDetails.check}
                      name="checkbox"
                      onChange={handleChange}
                    />
                  }
                  label={`Force user to change password on first log in ${(
                    <HelpOutlineIcon />
                  )}`}
                />
                <Typography sx={{ lineHeight: 2 }}>
                  <Link href="#">Show Additional fields</Link>
                </Typography>
                <Typography sx={{ lineHeight: 2 }}>
                  <Link href="#">Show Zoho People Details</Link>
                </Typography>
              </Grid> */}
            </Grid>
            {/* {errors?.alreadyInUse && (
              <Typography sx={{ color: "red" }}>
                {" "}
                {errors?.alreadyInUse}
              </Typography>
            )} */}
            {!isMobile && (
              <Box sx={{ mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.addButton}
                  // onClick={handleSubmit}
                >
                  Add User
                </Button>
              </Box>
            )}
          </form>
        </Grid>
        <Grid
          item
          sm={12}
          md={4}
          // sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Box sx={{ width: "140px", textAlign: "center" }}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="picture"
                  name="picture"
                  type="file"
                  onChange={handleImage}
                />
                <label htmlFor="picture">
                  <Avatar className={classes.imageAvatar} variant="rounded">
                    {userDetails?.picture ? (
                      <img
                        src={URL.createObjectURL(userDetails?.picture)}
                        width="99%"
                        height="99%"
                      />
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            width: "100%",
                            color: "#fff",
                            transform: "translate(-50%,-50%)",
                            textAlign: "center",
                          }}
                        >
                          <AddIcon />
                          <Typography>Upload Photo</Typography>
                        </Box>
                        <PersonIcon style={{ fontSize: "100px" }} />
                      </Box>
                    )}
                  </Avatar>
                </label>
                {userDetails?.picture && (
                  <Typography>{userDetails?.picture?.name}</Typography>
                )}
              </Box>
            </Grid>

            <Grid
              item
              sm={6}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                // justifyContent: "flex-end",
                marginBottom: "1rem",
              }}
            >
              {isTablet && isMobile && (
                <Box sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                    // onClick={handleSubmit}
                  >
                    Add User
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUser;
