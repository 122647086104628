import testHttpCommon from "../../http-common";

export const getServicingGrossProfitReports = (params) => {
  return testHttpCommon.get("/grossProfitReport/servicingGrossProfit", params);
};

export const getGrossProfitReports = (params) => {
  return testHttpCommon.get("/grossProfitReport", params);
};

export const updateGrossProfitReport = (updateDetails) => {
  return testHttpCommon.put("/grossProfitReport", updateDetails);
};

export const getFranchiseGrossProfit = (config) => {
  return testHttpCommon.get("/franchiseGrossProfit", config);
};

export const getGarageReceivablesGrossProfit = (config) => {
  return testHttpCommon.get("/garageReceivablesGrossProfit", config);
};

export const updateGarageReceivablesGrossProfit = (updateObj) => {
  return testHttpCommon.put("/garageReceivablesGrossProfit", updateObj);
};

export const updateFranchiseGrossProfit = (updateObj, config) => {
  return testHttpCommon.put("/franchiseGrossProfit", updateObj, config);
};
