import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import "../../modules/leads/AddLead.css";
import { CommonContextAPI } from "../../App";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { ArrivalModeContext } from "./LeadDetails";
import { getAllFollowUpReasons } from "../../services/leadServices/LeadServices";

const reasons = [
  {
    id: 1,
    reason: "Reasons 1",
  },
  {
    id: 2,
    reason: "Reasons 2",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
  {
    id: 3,
    reason: "Reasons 3",
  },
];

const ReasonsModal = ({ propsData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [allFollowUpReasons, setAllFollowUpReasons] = useState([]);
  const {
    openArrivalModal,
    closeArrivalModal,
    openLocationModal,
    closeLocationModal,
    openReasonsModal,
    closeReasonsModal,
    openGaragesModal,
    closeGaragesModal,
  } = propsData;

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);
  const arrivalModeContextData = useContext(ArrivalModeContext);
  const {
    setArrivalModeDetails,
    arrivalModeDetails,
    leadStatusDetails,
    setLeadStatusDetails,
    openModalOf,
  } = arrivalModeContextData;

  const userSelection = (field, value) => {
    if (openModalOf == "status") {
      setLeadStatusDetails({ ...leadStatusDetails, [field]: value?.reason });
    } else {
      setArrivalModeDetails({ ...arrivalModeDetails, [field]: value?.reason });
    }

    closeReasonsModal();
  };

  const followUpReasonsList = async () => {
    try {
      const response = await getAllFollowUpReasons();
      const data = await response.data;
      setAllFollowUpReasons(data.result);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors.error);
    }
  };

  useEffect(() => {
    followUpReasonsList();
  }, []);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "95%" : 500,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: isMobile ? "80%" : 615,
    height: "auto",
    maxHeight: "90%",
    border: "none !important",
    // overflow: "auto !important",
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={closeReasonsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" className="leadsTitle">
            Select Reasons
          </Typography>
          <Divider />
          <Box
            style={{
              position: "absolute",
              top: 1,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeReasonsModal}
            />
          </Box>

          <Box
            sx={{
              marginTop: "4px",
              height: allFollowUpReasons?.length <= 11 ? "100%" : "600px",
              overflow: "auto !important",
            }}
          >
            {allFollowUpReasons?.map((reason) => {
              return (
                <Box sx={{ marginTop: "1rem" }}>
                  <Card
                    sx={{
                      minWidth: 275,
                      "& .MuiCardContent-root": {
                        padding: "3px 10px",
                        marginBottom: "0px",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => userSelection("reason", reason)}
                  >
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 16 }}
                        color="#000"
                        gutterbottom
                      >
                        {reason?.reason}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ReasonsModal;
