import {
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Font,
  Document,
} from "@react-pdf/renderer";
import React, { useEffect, useMemo, useState } from "react";
import httpCommon from "../../../http-common";

import fontURL1 from "../../../assets/fonts/AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../../assets/fonts/AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../../assets/fonts/AvenirNextLTPro-Demi.otf";
import { borderRight, display } from "@mui/system";
import { getOrderById } from "../../../services/Services";
import { getCustomerInvoice } from "../../../services/orderServices/OrderServices";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

const borderColor = "#6a7086";
const borderWidth = "1px";
const field = "10px";
const cellPadding = "5px 0px";

const table_header_value = {
  border: borderColor,
  borderRightWidth: "1px",
  fontSize: "8px",
  padding: "2px 0px",
  textAlign: "center",
};

const table_header_field = {
  border: borderColor,
  borderRightWidth: "1px",
  fontSize: "8px",
  fontFamily: "AvenirDemi",
  padding: "2px 0px",
  textAlign: "center",
};

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#fff",
    padding: "4% 2% 3%",
    height: "100vh",
  },
  conatiner: {
    backgroundColor: "white",
    padding: "8",
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },

  bottomBorder: {
    border: borderColor,
    borderBottomWidth: "1px",
    padding: "5px 2px",
    fontSize: "10px",
    fontFamily: "AvenirDemi",
  },
  table: {
    border: borderColor,
    borderWidth: "1px",
    padding: "5px 0px 0px 0px",
  },
  tableHeader: {
    borderBottom: borderColor,
    borderBottomWidth: "1px",
    padding: "1px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 800,
    fontFamily: "AvenirDemi",
  },
  order_details: {
    borderBottom: borderColor,
    borderBottomWidth: "1px",
    padding: "5px 0px",
    textAlign: "center",
    fontSize: "8px",
    fontWeight: 800,
    fontFamily: "AvenirDemi",
  },
  sections_container: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    border: borderColor,
    borderBottomWidth: "1px",
  },
  sections_container2: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
  },
  section_one: {
    width: "50%",
    border: borderColor,
    borderRightWidth: "1px",
    fontSize: field,
    // textTransform: "uppercase",
    position: "relative",
  },
  section_two: {
    width: "50%",
    fontSize: field,
    // textTransform: "uppercase",
    position: "relative",
  },
  fieldCell: {
    width: "50%",
    borderColor: borderColor,
    borderRightWidth: borderWidth,
    padding: cellPadding,
    fontSize: "8px",
    fontFamily: "AvenirDemi",
    paddingLeft: "2px",
    textTransform: "uppercase",
  },
  fieldCell1: {
    width: "50%",
    // borderColor: borderColor,
    // borderRightWidth: borderWidth,
    padding: cellPadding,
    fontSize: "8px",
    fontFamily: "AvenirDemi",
    paddingLeft: "2px",
  },
  valueCell: {
    width: "50%",
    padding: cellPadding,
    fontSize: "8px",
    paddingLeft: "2px",
  },

  service_table_container: {
    // flexDirection: "row",
    // border: borderColor,
    // borderWidth: "1px",
    marginTop: "10px",
    // height: "25px",
    flexGrow: 1,
    // borderBottom: "none",
  },

  sr: {
    ...table_header_field,
    borderLeftWidth: "1px",
    border: borderColor,
    width: "5%",
    height: "100%",
    paddingVertical: "6px",
  },
  description: {
    ...table_header_field,
    width: "25%",
    height: "100%",
    paddingVertical: "6px",
  },
  hsn: {
    ...table_header_field,
    width: "10%",
    height: "100%",
    paddingVertical: "6px",
  },
  units: {
    ...table_header_field,
    width: "5%",
    height: "100%",
    paddingVertical: "6px",
  },
  discount: {
    ...table_header_field,
    width: "10%",
    height: "100%",
    paddingVertical: "6px",
  },
  taxable_amount: {
    ...table_header_field,
    width: "10%",
    height: "100%",
    paddingVertical: "6px",
  },
  gst_container: {
    width: "15%",
  },
  gst: {
    ...table_header_field,
    borderBottomColor: borderColor,
    borderBottomWidth: "1px",
    width: "100%",
    textAlign: "center",
  },
  gstSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  gst_rate: {
    ...table_header_field,
    width: "50%",
    textAlign: "center",
  },
  amount: {
    ...table_header_field,
    width: "10%",
    fontSize: "8px",
    fontFamily: "AvenirDemi",
    textAlign: "center",
    padding: "2px 0px",
    height: "100%",
    paddingVertical: "6px",
    borderRightWidth: "1px",
  },

  //   ---------------table rows styles -----------------------
  service_types_section: {
    display: "flex",
    flexDirection: "row",
    border: borderColor,
    borderRightWidth: "1px",
  },
  service_types: {
    width: "100%",
    fontSize: "8px",
    fontFamily: "AvenirDemi",
    textAlign: "center",
    padding: "2px 0px",
    height: "100%",
    paddingVertical: "6px",
    // borderRightWidth: "1px",
    // border: borderColor,
  },

  tableCalls: {},

  row_sr: {
    ...table_header_value,
    width: "5%",
    height: "100%",
    paddingVertical: "6px",
    fontFamily: "AvenirDemi",
    borderLeftWidth: "1px",
    border: borderColor,
  },
  row_description: {
    ...table_header_value,
    width: "25%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "start",
    padding: "0px 2px",
  },
  row_hsn: {
    ...table_header_value,
    width: "10%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "center",
    padding: "0px 2px",
  },
  row_units: {
    ...table_header_value,
    width: "5%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "center",
    padding: "0px 2px",
  },
  row_discount: {
    ...table_header_value,
    width: "10%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "right",
    padding: "0px 2px",
  },
  row_taxable_amount: {
    ...table_header_value,
    width: "10%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "right",
    padding: "0px 2px",
  },

  row_gst_container: {
    width: "15%",
    display: "right",
    flexDirection: "row",
  },
  row_gst_rate: {
    ...table_header_value,
    width: "50%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "center",
    padding: "0px 2px",
  },
  row_gst_amount: {
    ...table_header_value,
    width: "50%",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "right",
    padding: "0px 2px",
  },
  row_amount: {
    ...table_header_value,
    width: "10%",
    fontSize: "8px",
    padding: "2px 0px",
    height: "100%",
    paddingVertical: "6px",
    textAlign: "right",
    padding: "0px 2px",
    borderRightWidth: "1px",
    // border: borderColor,
  },

  //   ----------------   calculation section-------------
  calculation_section: {
    border: borderColor,
    borderWidth: "1px",
    marginTop: "10px",
    // height: "25px",
    flexGrow: 1,
  },
});

export default function Estimate({ orderId, header, servicesList }) {
  const [insurance, setInsurance] = useState([]);
  const [parts, setParts] = useState([]);
  const [labours, setLabours] = useState([]);
  const [buyerAddress, setBuyerAddress] = useState();
  const [sellerInfo, setSellerInfo] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [isEstimate, setIsEstimate] = useState(false);

  useEffect(() => {
    if (header == "ESTIMATE") {
      setIsEstimate(true);
    } else {
      setIsEstimate(false);
    }
  }, [, header]);

  const fristName = insurance?.user?.first_name;
  const lastName = insurance?.user?.last_name;

  const CreateFullAddress = (address) => {
    let fullAddress = "";
    if (address === null) return;

    if (address?.houseNumber !== null && address?.houseNumber !== undefined) {
      fullAddress = address?.houseNumber;
    }
    if (address?.shopNumber !== null && address?.shopNumber !== undefined) {
      fullAddress = address?.shopNumber;
    }
    if (address?.landmark !== null && address?.landmark != undefined) {
      fullAddress = fullAddress + " " + address?.landmark;
    }
    if (address?.area !== null && address?.area !== undefined) {
      fullAddress = fullAddress + " " + address?.area;
    }
    if (address?.city !== null && address?.city !== undefined) {
      fullAddress = fullAddress + " " + address?.city;
    }
    if (address?.pincode !== null && address?.pincode !== undefined) {
      fullAddress = fullAddress + " " + address?.pincode;
    }
    return fullAddress;
  };

  // Get Insurance Invoice Details

  const getInsuranceInvoiceDetails = async () => {
    try {
      const response = await getCustomerInvoice(orderId);
      const data = await response.data;
      setInvoiceDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !isEstimate && getInsuranceInvoiceDetails();
  }, [isEstimate]);

  // console.log(insurance)

  useEffect(() => {
    if (insurance?.codeOfInsurance?.id === 2) {
      setSellerInfo({
        name: "K Studio Solutions Pvt. Ltd",
        gstn: "27AAGCK1784J1Z6",
        address: "Office No 802 Tower 2 World Trade center Kharadi Pune",
      });
    } else {
      const garage_address = CreateFullAddress(insurance?.garage);
      setSellerInfo({
        name: insurance?.garage?.garageName,
        gstn: insurance?.garage?.gstn,
        address: garage_address,
      });
    }

    if (insurance?.insuranceType?.type === "Cashless") {
      let name = insurance?.insuranceCompany?.name;
      let gstn = insurance?.insuranceCompany?.gstn;
      let address = CreateFullAddress(insurance?.insuranceCompany);
      setBuyerAddress({ name, gstn, address });
    } else {
      let name = insurance?.user?.first_name + " " + insurance?.user?.last_name;
      let gstn = insurance?.user?.gstn;
      let address = CreateFullAddress(insurance?.address);
      setBuyerAddress({ name, gstn, address });
    }
  }, [insurance]);

  let TotalParts = 0;
  let TotalLabours = 0;
  // calculate grand total of non catalogues

  // Store grand total
  let grandTotal = 0;

  // Store grand gst total
  let grandGstTotal = 0;

  // let grandTotal = insurance?.nonCatalogueJobs
  //   ?.map((i) => Number(i.price))
  //   .reduce((a, b) => a + b, 0);

  // calculate total taxable amount
  // let taxableAmountNC = insurance?.nonCatalogueJobs?.map((i) => {
  //   let tt = i.price / `1.${i.gst}`;
  //   return i.price - tt;
  // });

  // let taxableAmountServices = insurance?.servicesList?.map((i) => {
  //   let tt = i.displayPrice / `1.${i.gst}`;
  //   return i.displayPrice - tt;
  // });

  // let taxableAmount = taxableAmountNC &&
  //   taxableAmountServices && [...taxableAmountNC, ...taxableAmountServices];
  // calculate total gstn amount
  // let grandGstTotal =
  //   taxableAmount && taxableAmount?.reduce((a, b) => Number(a) + Number(b), 0);

  useMemo(() => {
    const findeParts = insurance?.nonCatalogueJobs?.filter(
      (i) => i.serviceType === "Parts"
    );
    const findeLabours = insurance?.nonCatalogueJobs?.filter(
      (i) => i.serviceType === "Labour"
    );
    findeParts && setParts(findeParts);
    setLabours(findeLabours);
  }, [insurance?.nonCatalogueJobs]);

  const getOrderInfo = async (e) => {
    try {
      const req = await getOrderById(orderId);
      const res = await req.data;
      setInsurance(res.result);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getOrderInfo();
  }, []);
  return (
    <>
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}> */}
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <View style={styles.conatiner}>
            {/* header logo */}
            <View style={styles.logo}>
              <Image
                style={{ width: "120px" }}
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/logos/GativanFullLogoBlue.png"
                }
              />
            </View>

            <View style={styles.table}>
              <Text style={styles.tableHeader}>{header}</Text>
              <View style={styles.sections_container}>
                {/* -----Buyer section ----------------- */}
                <View style={styles.section_one}>
                  <Text style={styles.bottomBorder}>Buyer</Text>

                  <View
                    style={
                      {
                        // position: "absolute",
                        // marginTop: "8%",
                        // height: "79%",
                      }
                    }
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.fieldCell1}>Name:</Text>
                      <Text style={styles.valueCell}>
                        {isEstimate
                          ? buyerAddress?.name
                          : invoiceDetails?.customerName}
                      </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.fieldCell1}>GSTIN:</Text>
                      <Text style={styles.valueCell}>
                        {isEstimate
                          ? buyerAddress?.gstn
                          : invoiceDetails?.customerGst}
                      </Text>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "auto",
                      }}
                    >
                      <Text style={styles.fieldCell1}>Address:</Text>
                      <Text style={styles.valueCell}>
                        {isEstimate
                          ? buyerAddress?.address
                          : invoiceDetails?.customerAddress}
                      </Text>
                    </View>
                    <View
                      style={{
                        backgroundColor: borderColor,
                        position: "absolute",
                        left: "50%",
                        height: "100%",
                        width: "1px",
                      }}
                    ></View>
                  </View>
                </View>
                {/* ------- Seller section ------------------- */}
                <View style={styles.section_two}>
                  <Text style={styles.bottomBorder}>seller</Text>
                  <View
                    style={{
                      position: "absolute",
                      marginTop: "8%",
                      height: "75%",
                    }}
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.fieldCell1}>Name:</Text>
                      <Text style={styles.valueCell}>
                        {isEstimate
                          ? sellerInfo?.name
                          : invoiceDetails?.sellerName}
                      </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={styles.fieldCell1}>GSTIN:</Text>
                      <Text style={styles.valueCell}>
                        {isEstimate
                          ? sellerInfo?.gstn
                          : invoiceDetails?.sellerGSTN}
                      </Text>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Text style={styles.fieldCell1}>Address:</Text>
                      <Text style={styles.valueCell}>
                        {isEstimate
                          ? sellerInfo?.address
                          : invoiceDetails?.sellerAddress}
                      </Text>
                    </View>

                    <View
                      style={{
                        backgroundColor: borderColor,
                        position: "absolute",
                        left: "50%",
                        height: "100%",
                        width: "1px",
                      }}
                    ></View>
                  </View>
                </View>
              </View>

              <Text style={styles.order_details}>ORDER DETAILS</Text>

              <View style={styles.sections_container2}>
                <View style={styles.section_one}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>Date:</Text>
                    <Text style={styles.valueCell}>
                      {isEstimate
                        ? insurance?.arrivedAtWorkshopOn
                        : invoiceDetails?.invoiceDate}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>Year Of Manufacture:</Text>
                    <Text style={styles.valueCell}>
                      {isEstimate
                        ? insurance?.vehicle?.yearOfManufacture
                        : invoiceDetails?.yearOfManufacture}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>ODOMETER READING:</Text>
                    <Text style={styles.valueCell}>
                      {isEstimate
                        ? insurance?.odometerReading
                        : invoiceDetails?.odometer}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>SERVICE TYPE: </Text>
                    <Text style={styles.valueCell}>Insurance Service </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>CLAIM NO: </Text>
                    <Text style={styles.valueCell}>
                      {isEstimate
                        ? insurance?.claimNo
                        : invoiceDetails?.claimNumber}{" "}
                    </Text>
                  </View>
                </View>
                {/* ------- Seller section ------------------- */}
                <View style={styles.section_two}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>ORDER ID:</Text>
                    <Text style={styles.valueCell}>
                      {isEstimate
                        ? insurance?.orderId
                        : invoiceDetails?.orderId}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>BRAND:</Text>
                    <Text style={styles.valueCell}>
                      {isEstimate
                        ? insurance?.vehicle?.brand?.brand
                        : invoiceDetails?.vehicleBrand}
                    </Text>
                  </View>

                  {isEstimate ? (
                    <>
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.fieldCell}>MODEL:</Text>
                        <Text style={styles.valueCell}>
                          {insurance?.vehicle?.model?.model}
                        </Text>
                      </View>

                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.fieldCell}>VARIANT:</Text>
                        <Text style={styles.valueCell}>
                          {insurance?.vehicle?.variant?.variantName}
                        </Text>
                      </View>
                    </>
                  ) : (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <Text style={styles.fieldCell}>MODEL & VARIANT:</Text>
                        <Text style={styles.valueCell}>
                          {`${invoiceDetails?.vehicleModel} / ${invoiceDetails?.vehicleVariant}`}
                        </Text>
                      </View>
                    </View>
                  )}
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.fieldCell}>VEHICLE NO:</Text>
                    <Text style={styles.valueCell}>
                      {insurance?.vehicle?.vehicleNumber}
                    </Text>
                  </View>

                  {!isEstimate && (
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      {" "}
                      <Text style={styles.fieldCell}>Invoice NO:</Text>
                      <Text style={styles.valueCell}>
                        {invoiceDetails?.invoiceId}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>

            <View style={styles.service_table_container}>
              {/* ---------- tabel header -------------- */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottomWidth: "1px",
                  borderTopWidth: "1px",
                  borderColor: borderColor,
                }}
              >
                <Text style={styles.sr}>Sr. No.</Text>
                <Text style={styles.description}>Description</Text>
                <Text style={styles.hsn}>HSN/SAC</Text>
                <Text style={styles.units}>Units</Text>
                <Text style={styles.discount}>Discount</Text>
                <Text style={styles.taxable_amount}>Taxable Amt.</Text>
                <View style={styles.gst_container}>
                  <Text style={styles.gst}>CGST</Text>
                  <View style={styles.gstSection}>
                    <Text style={styles.gst_rate}>Rate</Text>
                    <Text style={styles.gst_rate}>Amt.</Text>
                  </View>
                </View>
                <View style={styles.gst_container}>
                  <Text style={styles.gst}>SGST</Text>
                  <View style={styles.gstSection}>
                    <Text style={styles.gst_rate}>Rate</Text>
                    <Text style={styles.gst_rate}>Amt.</Text>
                  </View>
                </View>
                <Text style={styles.amount}>Amount</Text>
              </View>
              {/* ---------- tabel Rows -------------- */}

              {isEstimate ? (
                <>
                  {parts?.length > 0 && (
                    <>
                      <View style={styles.service_types_section}>
                        <Text style={styles.row_sr}>A</Text>
                        <Text style={styles.service_types}>Parts</Text>
                      </View>

                      {parts?.map((item, index) => {
                        const {
                          id,
                          gst,
                          hsn,
                          name,
                          price,
                          quantity,
                          serviceType,
                        } = item;

                        let taxableAmount = price / `1.${gst}`;
                        let totalGst = price - taxableAmount;
                        TotalParts += taxableAmount;
                        grandTotal += price;
                        grandGstTotal += totalGst;
                        return (
                          <View
                            key={id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: borderColor,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                            }}
                            wrap={false}
                          >
                            <Text style={styles.row_sr}>{index + 1}</Text>
                            <Text style={styles.row_description}>{name}</Text>
                            <Text style={styles.row_hsn}>{hsn}</Text>
                            <Text style={styles.row_units}>{quantity}</Text>
                            <Text style={styles.row_discount}>0</Text>
                            <Text style={styles.row_taxable_amount}>
                              {taxableAmount?.toFixed(2)}
                            </Text>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gst / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {(totalGst / 2)?.toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gst / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {(totalGst / 2)?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.row_amount}>
                              {price?.toFixed(2)}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}
                  {labours?.length > 0 && (
                    <>
                      <View style={styles.service_types_section}>
                        <Text style={styles.row_sr}>B</Text>
                        <Text style={styles.service_types}>Labour</Text>
                      </View>

                      {labours?.map((item, index) => {
                        const {
                          id,
                          gst,
                          hsn,
                          name,
                          price,
                          quantity,
                          serviceType,
                        } = item;

                        let taxableAmount = price / `1.${gst}`;
                        let totalGst = price - taxableAmount;
                        TotalLabours += taxableAmount;
                        grandTotal += price;
                        grandGstTotal += totalGst;

                        let srn = parts?.length;
                        return (
                          <View
                            key={id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: borderColor,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                            }}
                            wrap={false}
                          >
                            <Text style={styles.row_sr}>{srn + index + 1}</Text>
                            <Text style={styles.row_description}>{name}</Text>
                            <Text style={styles.row_hsn}>{hsn}</Text>
                            <Text style={styles.row_units}>{quantity}</Text>
                            <Text style={styles.row_discount}>0</Text>
                            <Text style={styles.row_taxable_amount}>
                              {taxableAmount?.toFixed(2)}
                            </Text>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gst / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {(totalGst / 2).toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gst / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {(totalGst / 2)?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.row_amount}>
                              {price?.toFixed(2)}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}

                  {servicesList?.length > 0 && (
                    <>
                      {labours?.length == 0 && (
                        <View style={styles.service_types_section}>
                          <Text style={styles.row_sr}>B</Text>
                          <Text style={styles.service_types}>Labour</Text>
                        </View>
                      )}

                      {servicesList?.map((item, index) => {
                        const {
                          id,
                          service,
                          hsn,
                          gst,
                          displayPrice,
                          quantity,
                          serviceType,
                          discount,
                        } = item;

                        let taxableAmount = displayPrice / `1.${gst}`;
                        let totalGst = displayPrice - taxableAmount;
                        TotalLabours += taxableAmount;
                        grandTotal += displayPrice;
                        grandGstTotal += totalGst;

                        let srn = parts?.length;
                        return (
                          <View
                            key={id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: borderColor,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                            }}
                            wrap={false}
                          >
                            <Text style={styles.row_sr}>{srn + index + 1}</Text>
                            <Text style={styles.row_description}>
                              {service}
                            </Text>
                            <Text style={styles.row_hsn}>{hsn}</Text>
                            <Text style={styles.row_units}>
                              {quantity ? quantity : 1}
                            </Text>
                            <Text style={styles.row_discount}></Text>
                            <Text style={styles.row_taxable_amount}>
                              {taxableAmount?.toFixed(2)}
                            </Text>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gst / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {(totalGst / 2).toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gst / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {(totalGst / 2)?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.row_amount}>
                              {displayPrice?.toFixed(2)}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}
                  {insurance?.discount > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        border: borderColor,
                        borderBottomWidth: "0.5px",
                        borderTopWidth: "0.5px",
                      }}
                      wrap={false}
                    >
                      <Text style={styles.row_sr}>
                        {parts?.length + labours?.length + 1}{" "}
                      </Text>
                      <Text style={styles.row_description}>
                        Service Discount{" "}
                      </Text>
                      <Text style={styles.row_hsn}></Text>
                      <Text style={styles.row_units}>1</Text>
                      <Text style={styles.row_discount}>
                        -{insurance?.discount.toFixed(2)}
                      </Text>
                      <Text style={styles.row_taxable_amount}>
                        -{(insurance?.discount / 1.18).toFixed(2)}
                      </Text>
                      <View style={styles.row_gst_container}>
                        <Text style={styles.row_gst_rate}>-9%</Text>
                        <Text style={styles.row_gst_amount}>
                          -
                          {(
                            (insurance?.discount - insurance?.discount / 1.18) /
                            2
                          ).toFixed(2)}
                        </Text>
                      </View>
                      <View style={styles.row_gst_container}>
                        <Text style={styles.row_gst_rate}>-9%</Text>
                        <Text style={styles.row_gst_amount}>
                          -
                          {(
                            (insurance?.discount - insurance?.discount / 1.18) /
                            2
                          ).toFixed(2)}
                        </Text>
                      </View>
                      <Text style={styles.row_amount}>
                        -{insurance?.discount?.toFixed(2)}
                      </Text>
                    </View>
                  )}
                </>
              ) : (
                <>
                  {invoiceDetails?.parts?.length > 0 && (
                    <>
                      <View style={styles.service_types_section}>
                        <Text style={styles.row_sr}>A</Text>
                        <Text style={styles.service_types}>Parts</Text>
                      </View>

                      {invoiceDetails?.parts?.map((item, index) => {
                        const {
                          id,
                          invoiceId,
                          service,
                          qty,
                          discount,
                          taxableAmount,
                          gstRate,
                          gstAmount,
                          total,
                          type,
                          hsn,
                          price,
                        } = item;

                        return (
                          <View
                            key={id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: borderColor,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                            }}
                            wrap={false}
                          >
                            <Text style={styles.row_sr}>{index + 1}</Text>
                            <Text style={styles.row_description}>
                              {service}
                            </Text>
                            <Text style={styles.row_hsn}>{hsn}</Text>
                            <Text style={styles.row_units}>{qty}</Text>
                            <Text style={styles.row_discount}>{discount}</Text>
                            <Text style={styles.row_taxable_amount}>
                              {taxableAmount?.toFixed(2)}
                            </Text>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gstRate / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {gstAmount?.toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gstRate / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {gstAmount?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.row_amount}>
                              {total?.toFixed(2)}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}
                  {invoiceDetails?.labour?.length > 0 && (
                    <>
                      <View style={styles.service_types_section}>
                        <Text style={styles.row_sr}>B</Text>
                        <Text style={styles.service_types}>Labour</Text>
                      </View>

                      {invoiceDetails?.labour?.map((item, index) => {
                        const {
                          id,
                          invoiceId,
                          service,
                          qty,
                          discount,
                          taxableAmount,
                          gstRate,
                          gstAmount,
                          total,
                          type,
                          hsn,
                          price,
                        } = item;

                        return (
                          <View
                            key={id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: borderColor,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                            }}
                            wrap={false}
                          >
                            <Text style={styles.row_sr}>{index + 1}</Text>
                            <Text style={styles.row_description}>
                              {service}
                            </Text>
                            <Text style={styles.row_hsn}>{hsn}</Text>
                            <Text style={styles.row_units}>{qty}</Text>
                            <Text style={styles.row_discount}>{discount}</Text>
                            <Text style={styles.row_taxable_amount}>
                              {taxableAmount?.toFixed(2)}
                            </Text>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gstRate / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {gstAmount?.toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gstRate / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {gstAmount?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.row_amount}>
                              {total?.toFixed(2)}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}

                  {invoiceDetails?.items?.length > 0 && (
                    <>
                      <View
                        style={{
                          width: "100%",
                          border: borderColor,
                          borderBottomWidth: "0.5px",
                          borderTopWidth: "0.5px",
                        }}
                      >
                        <Text
                          style={{
                            width: "100%",
                            height: "2rem",
                          }}
                        ></Text>
                      </View>
                      {invoiceDetails?.items?.map((item, index) => {
                        const {
                          id,
                          invoiceId,
                          service,
                          qty,
                          discount,
                          taxableAmount,
                          gstRate,
                          gstAmount,
                          total,
                          type,
                          hsn,
                          price,
                        } = item;
                        return (
                          <View
                            key={id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              border: borderColor,
                              borderBottomWidth: "0.5px",
                              borderTopWidth: "0.5px",
                            }}
                            wrap={false}
                          >
                            <Text style={styles.row_sr}>{index + 1}</Text>
                            <Text style={styles.row_description}>
                              {service}
                            </Text>
                            <Text style={styles.row_hsn}>{hsn}</Text>
                            <Text style={styles.row_units}>{qty}</Text>
                            <Text style={styles.row_discount}>{discount}</Text>
                            <Text style={styles.row_taxable_amount}>
                              {taxableAmount?.toFixed(2)}
                            </Text>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gstRate / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {gstAmount?.toFixed(2)}
                              </Text>
                            </View>
                            <View style={styles.row_gst_container}>
                              <Text style={styles.row_gst_rate}>
                                {gstRate / 2}%
                              </Text>
                              <Text style={styles.row_gst_amount}>
                                {gstAmount?.toFixed(2)}
                              </Text>
                            </View>
                            <Text style={styles.row_amount}>
                              {total?.toFixed(2)}
                            </Text>
                          </View>
                        );
                      })}
                    </>
                  )}
                </>
              )}

              {/* ------------- Parts ------------- */}
            </View>

            <View style={styles.calculation_section} wrap={false}>
              <View
                style={{
                  flexDirection: "row",
                  border: borderColor,
                  borderBottomWidth: "1px",
                }}
              >
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  PARTS SUBTOTAL{" "}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  LABOUR SUBTOTAL{" "}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  TOTAL CGST{" "}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  TOTAL SGST{" "}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  GRAND TOTAL{" "}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  {isEstimate
                    ? TotalParts.toFixed(2)
                    : invoiceDetails?.partsTaxableSubtotal?.toFixed(2)}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  {isEstimate
                    ? TotalLabours.toFixed(2)
                    : invoiceDetails?.labourTaxableSubtotal?.toFixed(2)}{" "}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  {isEstimate
                    ? (
                        grandGstTotal / 2 -
                        (insurance?.discount - insurance?.discount / 1.18) / 2
                      ).toFixed(2)
                    : invoiceDetails?.cgst?.toFixed(2)}{" "}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  {isEstimate
                    ? (
                        grandGstTotal / 2 -
                        (insurance?.discount - insurance?.discount / 1.18) / 2
                      ).toFixed(2)
                    : invoiceDetails?.sgst?.toFixed(2)}
                </Text>
                <Text style={{ ...styles.fieldCell, textAlign: "center" }}>
                  {isEstimate
                    ? insurance?.discount
                      ? (grandTotal - insurance?.discount).toFixed(2)
                      : grandTotal?.toFixed(2)
                    : invoiceDetails?.totalAmount?.toFixed(2)}
                </Text>
              </View>
            </View>

            <View
              style={{
                marginTop: "10px",
                border: borderColor,
                borderWidth: "1px",
                flexDirection: "row",
              }}
              wrap={false}
            >
              <View
                style={{
                  width: "50%",
                  padding: "8px",
                  border: borderColor,
                  borderRightWidth: "1px",
                }}
              >
                <Text style={{ fontSize: "8px", fontFamily: "AvenirDemi" }}>
                  BANK DETAILS:
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  BANKING NAME: K Studio Solutions Pvt. Ltd.
                </Text>
                <Text style={{ fontSize: "8px" }}>
                  BANK : KOTAK MAHINDRA BANK
                </Text>
                <Text style={{ fontSize: "8px" }}>ACCOUNT NO : 9412238971</Text>
                <Text style={{ fontSize: "8px" }}>IFSC : KKBK0001758</Text>
              </View>
              <View style={{ width: "50%", position: "relative" }}>
                <Text
                  style={{
                    fontSize: "8px",
                    fontFamily: "AvenirDemi",
                    position: "absolute",
                    bottom: "10%",
                    right: "5%",
                  }}
                >
                  Authorised Signatory
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
      {/* </PDFViewer> */}
    </>
  );
}
