import React, { useMemo, useState, useEffect, useContext } from "react";
import DashboardComponent from "../../components/dashboard/DashboardComponent";
import { getOrders } from "../../services/orderServices/OrderServices";
import { getLeads } from "../../services/leadServices/LeadServices";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import moment from "moment";
import { useSelector } from "react-redux";

import { getYear } from "date-fns";
import {
  ConstructionOutlined,
  CoPresentOutlined,
  Hearing,
} from "@mui/icons-material";
import { CommonContextAPI } from "../../App";

const InventoryDashboard = () => {
  const [dayFilters, setDayFilters] = useState();
  const [allOrders, setAllOrders] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [leads, setLeads] = useState([]);
  const [actulLeads, setActulLeads] = useState([]);

  const [ordersCount, setOrdersCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [revenue, setRevenue] = useState(0);

  const [YearToggle, setYearToggle] = useState(1);
  const [YearsToggle, setYearsToggle] = useState(0);
  const [sales, setSales] = useState([]);
  const [salesTemp, setSalesTemp] = useState([]);
  const [salesDataKey, setSalesDataKey] = useState("month");
  const [salesMonthllyData, setSalesMonthllyData] = useState([]);
  const [salesDaillyData, setSalesDaillyData] = useState([]);
  const [saleByVehicleType, setSaleByVehicleType] = useState([]);
  const [saleByGarage, setGarageSale] = useState([]);

  // CONSTANT VALUES
  const { MONTHLY_TARGET } = useSelector((state) => state?.AppConstants);

  const localData = JSON.parse(localStorage.getItem("loginDetails"));
  // get Orders
  const getOrdersList = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
      params: { all: true },
    };
    try {
      const response = await getOrders(config);
      const result = await response.data;
      setAllOrders(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  // get Leads
  const getLeadsList = async () => {
    try {
      const response = await getLeads();
      const result = await response.data;
      setLeads(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const ordersObject = (order) => {
    const orderObj = {
      ...order?.order,
      services: order?.services,
      nonCatalogueJobs: order?.nonCatalogueJobs,
    };
    return orderObj;
  };

  useEffect(() => {
    allOrders?.map((order) => {
      const orderObj = ordersObject(order);
      setOrdersList((prev) => [...prev, orderObj]);
    });
  }, [allOrders]);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const { handleOpenDatePicker, filteredDate } = commonData;

  // API Call
  useMemo(() => {
    getOrdersList();
    getLeadsList();
  }, []);

  useEffect(() => {
    setOrders([]);
    setYearToggle(1);
    filteredDate &&
      ordersList.map((order) => {
        if (order?.completionDate != null) {
          if (
            moment(order?.completionDate).format("YYYY-MM-DD") >=
              moment(filteredDate[0] && filteredDate[0]?.startDate).format(
                "YYYY-MM-DD"
              ) &&
            moment(order?.completionDate).format("YYYY-MM-DD") <=
              moment(filteredDate[0] && filteredDate[0]?.endDate).format(
                "YYYY-MM-DD"
              )
          ) {
            setOrders((prev) => [...prev, order]);
          }
        }
      });
  }, [filteredDate && filteredDate]);

  useMemo(() => {
    setOrders(ordersList.filter((order) => order?.completionDate != null));
  }, [ordersList]);

  // Get orders count and revenue value.
  useMemo(() => {
    setRevenue(0);

    setOrdersCount(orders?.length);

    orders.filter((order) => {
      if (order?.orderId?.length >= 15) {
        if (
          order?.completionDate != null &&
          order?.orderStatus?.status == "Complete"
        ) {
          setRevenue((prev) => prev + order?.totalPrice);
        }
      }
    });
  }, [orders]);

  // Get leads with 10 digit mobile number
  useMemo(() => {
    leads &&
      leads.map((lead) => {
        if (lead?.mobileNumber && lead?.mobileNumber.length == 10) {
          setActulLeads((prev) => [...prev, lead]);
        }
      });
  }, [leads]);

  //  Get leads count
  useMemo(() => {
    setLeadsCount(actulLeads?.length);
  }, [actulLeads]);

  const handleDayFilterChange = (event) => {
    setDayFilters(event.target.value);
  };
  //=============Y

  // =========================Carousel=======================//
  const compareSectionsView = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 0,
    },

    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },

    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
  };
  // ==================Latest Order Review Carosel=========//
  const latestOrdersReviewCarouselView = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 0,
    },

    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },

    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
  };

  // =======================Order-GrossProfit-Leads-Revenue Boxes=============================//
  const compareSections = [
    {
      id: 1,
      title: "Product In",
      item: ordersCount,
      number: "+10",
      img: "/Assets/dashboard/orders.png",
    },
    {
      id: 2,
      title: "Product Out",
      item: 712,
      number: "+50",
      img: "/Assets/dashboard/gross profit.png",
    },
    {
      id: 3,
      title: "Total Material Cost",
      item: leadsCount,
      number: "+14",
      img: "/Assets/dashboard/leads.png",
    },
    {
      id: 4,
      title: "Material Sale",
      item: revenue,
      number: "+0.2L",
      img: "/Assets/dashboard/revenue.png",
    },
  ];

  // ==============================Material Wise Sales Array===============================//
  const materailWiseSale = [
    {
      id: 1,
      serviceimg: "/Assets/services/gear.png",
      services: "Brake Shoes",
      orders: "120+Orders",
      sale: "2.8L",
    },
    {
      id: 2,
      serviceimg: "/Assets/services/gear.png",
      services: "Clutch Cable",
      orders: "120+Orders",
      sale: "2.6L",
    },
    {
      id: 3,
      serviceimg: "/Assets/services/gear.png",
      services: "AC Gas Bottle",
      orders: "120+Orders",
      sale: "0.8L",
    },
    {
      id: 4,
      serviceimg: "/Assets/services/gear.png",
      services: "Car Duster",
      orders: "120+Orders",
      sale: "0.8L",
    },
    {
      id: 5,
      serviceimg: "/Assets/services/gear.png",
      services: "Air Filter Shine",
      orders: "120+Orders",
      sale: "1.2L",
    },
    {
      id: 6,
      serviceimg: "/Assets/services/gear.png",
      services: "Air Filter Shine",
      orders: "120+Orders",
      sale: "1.2L",
    },
    {
      id: 7,
      serviceimg: "/Assets/services/gear.png",
      services: "Air Filter Shine",
      orders: "120+Orders",
      sale: "1.2L",
    },
    {
      id: 8,
      serviceimg: "/Assets/services/gear.png",
      services: "Air Filter Shine",
      orders: "120+Orders",
      sale: "1.2L",
    },
  ];

  // ==============================Service Sales Array===============================//
  const vendorWiseSale = [
    {
      id: 1,
      serviceimg: "/Assets/services/gear.png",
      garage: "Sai Enterprises",
      orders: "120+Orders",
      totalPrice: "2.8L",
    },
    {
      id: 2,
      serviceimg: "/Assets/services/gear.png",
      garage: "Vardhaman Distributors",
      orders: "120+Orders",
      totalPrice: "2.6L",
    },
    {
      id: 3,
      serviceimg: "/Assets/services/gear.png",
      garage: "Nakoda Automobiles",
      orders: "120+Orders",
      totalPrice: "0.8L",
    },
    {
      id: 4,
      serviceimg: "/Assets/services/gear.png",
      garage: "Darshan Tyres",
      orders: "120+Orders",
      totalPrice: "1.2L",
    },
    {
      id: 5,
      serviceimg: "/Assets/services/gear.png",
      garage: "Icon Innovative Products Pvt. Ltd.",
      orders: "120+Orders",
      totalPrice: "1.2L",
    },
    {
      id: 6,
      serviceimg: "/Assets/services/gear.png",
      garage: "Icon Innovative Products Pvt. Ltd.",
      orders: "120+Orders",
      totalPrice: "1.2L",
    },
    {
      id: 7,
      serviceimg: "/Assets/services/gear.png",
      garage: "Icon Innovative Products Pvt. Ltd.",
      orders: "120+Orders",
      totalPrice: "1.2L",
    },
    {
      id: 8,
      serviceimg: "/Assets/services/gear.png",
      garage: "Icon Innovative Products Pvt. Ltd.",
      orders: "120+Orders",
      totalPrice: "1.2L",
    },
  ];

  //==================year-month-week===============================//
  const dayFilter = [
    {
      id: 1,
      name: "Today ",
    },
    {
      id: 2,
      name: "last day",
    },
    {
      id: 3,
      name: "last month",
    },
  ];

  // ===========================Latest order Review====================//
  const latestReview = [
    {
      id: 1,
      photo: "xyz.png",
      name: "Shailesh Jain",
      desp: " I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
    },
    {
      id: 2,
      photo: "xyz.png",
      name: "Shailesh Jain",
      desp: " I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
    },
    {
      id: 3,
      photo: "abc.png",
      name: "Shailesh Jain",
      desp: " I liked the part where they informed me before replacing the bike part by providing a photo proof. It was quick and easy. Overall I'm really satisfied and had a great experience.",
    },
  ];

  // =============================Graph-Overview Sales array=======================//

  const grossProfitData = [
    {
      // month: "Jan",
      year: 2011,
      sale: 0,
      carsale: 0,
      bikesle: 0,
    },
    {
      month: "Feb",
      year: 2012,
      sale: 200000,
      carsale: 100000,
      bikesle: 10000,
    },
    {
      month: "March",
      year: 2013,
      sale: 300000,
      carsale: 200000,
      bikesle: 10000,
    },
    {
      month: "April",
      year: 2013,
      sale: 400000,
      carsale: 300000,
      bikesle: 100000,
    },
    {
      month: "May",
      year: 2013,
      sale: 300000,
      carsale: 250000,
      bikesle: 50000,
    },
    {
      month: "Jun",
      year: 2016,
      sale: 250000,
      carsale: 200000,
      bikesle: 50000,
    },
    {
      month: "July",
      year: 2014,
      sale: 120000,
      carsale: 80000,
      bikesle: 40000,
    },
    {
      month: "Aug",
      year: 2018,
      sale: 100000,
      carsale: 90000,
      bikesle: 10000,
    },
    {
      month: "Sep",
      year: 2019,
      sale: 110000,
      carsale: 50000,
      bikesle: 60000,
    },
    {
      month: "Oct",
      year: 2020,
      sale: 410000,
      carsale: 300000,
      bikesle: 110000,
    },
    {
      month: "Nov",
      year: 2021,
      sale: 100000,
      carsale: 30000,
      bikesle: 70000,
    },
    {
      month: "Dec",
      year: 2022,
      sale: 101000,
      carsale: 30000,
      bikesle: 71000,
    },
  ];

  const getUniqueYearsFromDate = () => {
    let getyears = orders.map((order) => {
      if (order?.completionDate != null && order?.completionDate != undefined) {
        return order?.completionDate?.split("-")[0];
      }
    });
    const years = [...new Set(getyears)];
    return years.reverse().filter((year) => year != undefined);
  };

  // Handle yearly sale
  const handleSalesYears = () => {
    setSales([]);

    let yearllySalesTotal = Object.values(
      salesTemp.reduce((acc, { year, sale, carsale, bikesale }) => {
        year = year?.split("-")[0];
        acc[year] = acc[year] || { year, sale: 0, carsale: 0, bikesale: 0 };
        acc[year].sale = String(Number(acc[year].sale) + Number(sale));
        return acc;
      }, {})
    );

    setSales(yearllySalesTotal);

    setSalesDataKey("year");
  };
  const generatedMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Handle monthly sale
  const handleSalesMonths = () => {
    setSales([]);

    let customIndexes = [];
    const filteredYear = getUniqueYearsFromDate();

    let curYear = new Date().getFullYear();
    let curMonth = new Date().getMonth();

    filteredYear?.map((u) => {
      if (filteredDate != null) {
        let start = moment(
          filteredDate[0] && filteredDate[0]?.startDate
        ).format("YYYY-MM-DD");
        let end = moment(filteredDate[0] && filteredDate[0]?.endDate).format(
          "YYYY-MM-DD"
        );

        for (let i = start?.split("-")[1]; i <= end?.split("-")[1]; i++) {
          let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;
          customIndexes[`${u} ${mo}`] = {
            sale: 0,
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${u}`,
          };
        }
      } else if (u == curYear) {
        curYear = curYear;
        curMonth = curMonth;
        for (let i = 1; i <= curMonth + 1; i++) {
          let mo = i < 10 ? `0${i}` : i;
          customIndexes[`${curYear} ${mo}`] = {
            sale: 0,
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${curYear}`,
          };
        }
      } else if (u == 2021) {
        for (let i = 6; i <= 12; i++) {
          let mo = i < 10 ? `0${i}` : i;
          customIndexes[`${u} ${mo}`] = {
            sale: 0,
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${u}`,
          };
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          let mo = i < 10 ? `0${i}` : i;
          customIndexes[`${u} ${mo}`] = {
            sale: 0,
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${u}`,
          };
        }
      }
    });

    let monthllySalesTotal = Object.values(
      salesMonthllyData.reduce((acc, { year, sale, carsale, bikesal }) => {
        let month = `${year?.split("-")[0]} ${year?.split("-")[1]}`;
        let yInd = `${year?.split("-")[0]}`;
        let mInd = `${year?.split("-")[1]}`;
        customIndexes[month] = customIndexes[month] || {
          month: `${generatedMonths[mInd]} ${filteredYear[yInd]}`,
          sale: 0,
          carsale: 0,
          bikesal: 0,
          year: year,
        };
        customIndexes[month].sale = String(
          Number(customIndexes[month].sale) + Number(sale)
        );
        // customIndexes[month].year = year;
        return customIndexes;
      }, {})
    );

    monthllySalesTotal && setSales(monthllySalesTotal);
    setSalesDataKey("month");
  };

  // Get months between two dates
  function getNumberOfMonths(startDate, endDate) {
    console.log(startDate, endDate);
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var months = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        months.push({ month: displayMonth, year: i });
      }
    }
    return months;
  }

  // get number of weeks
  const getNumberOfWeeks = () => {
    let weeks = [];
    let year = new Date().getFullYear();
    let month = new Date().getMonth();

    let numberOfMonths;

    if (filteredDate !== null) {
      const startDate = filteredDate[0] && filteredDate[0]?.startDate;
      const endDate = filteredDate[0] && filteredDate[0]?.endDate;

      const startYear = new Date(startDate).getFullYear();
      const lastYear = new Date(endDate).getFullYear();
      const startMonth = new Date(startDate).getMonth();
      const lastMonth = new Date(endDate).getMonth();

      numberOfMonths = getNumberOfMonths(
        `${startYear}-${startMonth + 1}`,
        `${lastYear}-${lastMonth + 1}`
      );
    } else {
      // numberOfMonths = getNumberOfMonths(`${year}-${month + 2 - 3}`, `${year}-${month + 1}`);
      numberOfMonths = getNumberOfMonths(
        `${2021}-${6 + 1}`,
        `${year}-${month + 1}`
      );
    }

    let weekNumber = 0;
    numberOfMonths &&
      numberOfMonths.map((res) => {
        const curMonth = new Date().getMonth() + 1;
        const curDate = new Date().getDate();

        if (res.month == curMonth) {
          const today = new Date(res.year, res.month);
          const fst = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          ).getDate();
          const lst = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          ).getDate();

          for (let i = fst; i <= curDate; i += 7) {
            weekNumber += 1;
            if (i < 22) {
              weeks.push({
                ws: i,
                we: i + 6,
                weekOf: `${i} - ${i + 6}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
            } else {
              weeks.push({
                ws: i,
                we: lst,
                weekOf: `${i} - ${lst}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
              break;
            }
          }
        } else {
          const today = new Date(res.year, res.month);
          const fst = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          ).getDate();
          const lst = new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          ).getDate();
          for (let i = fst; i <= lst; i += 7) {
            weekNumber += 1;
            if (i < 22) {
              weeks.push({
                ws: i,
                we: i + 6,
                weekOf: `${i} - ${i + 6}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
            } else {
              weeks.push({
                ws: i,
                we: lst,
                weekOf: `${i} - ${lst}`,
                weekNumber: weekNumber,
                month: res.month,
                year: res.year,
              });
              break;
            }
          }
        }
      });

    return filteredDate !== null ? weeks : weeks.splice(weeks.length - 12);
  };

  // Hanldle weekly sale.
  const handleSalesWeeks = () => {
    setSales([]);
    const weeks = getNumberOfWeeks();

    let result = weeks.map((week) => {
      let total = [];
      salesTemp.reduce((acc, { year, sale }) => {
        if (
          new Date(year) >= new Date(week.year, week.month - 1, week.ws) &&
          new Date(year) <= new Date(week.year, week.month - 1, week.we)
        ) {
          total[week.weekNumber] = total[week.weekNumber] || {
            sale: 0,
            weekNumber: week.weekNumber,
            carsale: 0,
            bikesale: 0,
            weeks: `${week.year}-${week.month}-${week.ws} / ${week.year}-${week.month}-${week.we}`,
          };
          total[week.weekNumber].sale += Number(sale);
          total[week.weekNumber].weekNumber = week.weekNumber;
          total[
            week.weekNumber
          ].weeks = `${week.year}-${week.month}-${week.ws} / ${week.year}-${week.month}-${week.we}`;
        } else {
          if (
            new Date(week.year, week.month - 1, week.ws) <=
            new Date(week.year, week.month - 1, week.we)
          ) {
            total[week.weekNumber] = total[week.weekNumber] || {
              sale: 0,
              weekNumber: week.weekNumber,
              carsale: 0,
              bikesale: 0,
              weeks: `${week.year}-${week.month}-${week.ws} / ${week.year}-${week.month}-${week.we}`,
            };
          }
        }
      }, {});
      return total.filter((t) => t !== undefined);
    });
    let flattenArray = [].concat(...result);
    setSalesDataKey("weeks");
    flattenArray && setSales(flattenArray);
  };

  // Handle daily sale
  const handleSalesDays = () => {
    setSales([]);
    function getDaysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let customIndexes = [];
    const filteredYear = getUniqueYearsFromDate();

    let curYear = new Date().getFullYear();
    let curMonth = new Date().getMonth();

    filteredYear?.map((u) => {
      if (filteredDate != null) {
        let start = moment(
          filteredDate[0] && filteredDate[0]?.startDate
        ).format("YYYY-MM-DD");
        let end = moment(filteredDate[0] && filteredDate[0]?.endDate).format(
          "YYYY-MM-DD"
        );

        for (let i = start?.split("-")[1]; i <= end?.split("-")[1]; i++) {
          let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;

          for (let j = start?.split("-")[2]; j <= end?.split("-")[2]; j++) {
            let day = j < 10 && j.toString.length == 1 ? `0${j}` : j;
            let date = `${u}-${mo}-${day}`;
            customIndexes[date] = {
              date: date,
              sale: 0,
              carsale: 0,
              bikesale: 0,
            };
          }
        }
      } else if (u == curYear) {
        curYear = curYear;
        curMonth = curMonth;
        let getDate = new Date().getDate();

        for (let i = 1; i <= curMonth + 1; i++) {
          let daysInMonth = getDaysInMonth(i, u);
          let mo = i < 10 ? `0${i}` : i;
          if (i == curMonth + 1) {
            for (let j = 1; j <= getDate; j++) {
              let day = j < 10 ? `0${j}` : j;
              let date = `${u}-${mo}-${day}`;

              customIndexes[date] = {
                date: date,
                sale: 0,
                carsale: 0,
                bikesale: 0,
              };
            }
          } else {
            for (let j = 1; j <= daysInMonth; j++) {
              let day = j < 10 ? `0${j}` : j;
              let date = `${u}-${mo}-${day}`;

              customIndexes[date] = {
                date: date,
                sale: 0,
                carsale: 0,
                bikesale: 0,
              };
            }
          }
        }
      } else if (u == 2021) {
        for (let i = 6; i <= 12; i++) {
          let daysInMonth = getDaysInMonth(i, u);
          let mo = i < 10 ? `0${i}` : i;

          for (let j = 1; j <= daysInMonth; j++) {
            let day = j < 10 ? `0${j}` : j;
            let date = `${u}-${mo}-${day}`;

            customIndexes[date] = {
              date: date,
              sale: 0,
              carsale: 0,
              bikesale: 0,
            };
          }
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          let daysInMonth = getDaysInMonth(i, u);
          let mo = i < 10 ? `0${i}` : i;

          for (let j = 1; j <= daysInMonth; j++) {
            let day = j < 10 ? `0${j}` : j;
            let date = `${u}-${mo}-${day}`;

            customIndexes[date] = {
              date: date,
              sale: 0,
              carsale: 0,
              bikesale: 0,
            };
          }
        }
      }
    });

    let dailySalesTotal = Object.values(
      salesDaillyData.reduce((acc, { date, sale, carsale, bikesal }) => {
        customIndexes[date] = customIndexes[date] || {
          date: date,
          sale: 0,
          carsale: 0,
          bikesal: 0,
        };
        customIndexes[date].sale = String(
          Number(customIndexes[date].sale) + Number(sale)
        );
        return customIndexes;
      }, {})
    );

    let filteredData = dailySalesTotal.sort(
      (a, b) => Number(new Date(a.date)) - Number(new Date(b.date))
    );

    filteredData && setSales(filteredData);
    setSalesDataKey("date");
  };

  // STATE'S SETS ON ORDERS CHANGE
  useMemo(() => {
    setSalesTemp([]);
    setSalesDaillyData([]);
    setSalesMonthllyData([]);

    orders.map((order) => {
      if (
        order?.completionDate != null &&
        order?.orderStatus?.status == "Complete"
      ) {
        setSalesTemp((prev) => [
          ...prev,
          {
            year: order?.completionDate,
            sale: order?.totalPrice,
            carsale: 0,
            bikesle: 0,
          },
        ]);

        setSalesDaillyData((prev) => [
          ...prev,
          {
            date: order?.completionDate,
            sale: order?.totalPrice,
            carsale: 0,
            bikesle: 0,
          },
        ]);

        setSalesDataKey("month");
        setSalesMonthllyData((prev) => [
          ...prev,
          {
            year: order?.completionDate,
            sale: order?.totalPrice,
            carsale: 0,
            bikesle: 0,
          },
        ]);
      }
    });
  }, [orders]);

  // Init daily total.
  useEffect(() => {
    // call daily sale total function.
    handleSalesMonths();
  }, [salesDaillyData]);

  // ======================SALES GRAPH END===============================

  // ======================SERVICE TYPE SALE START===============================
  // const [serviceTypeSale, setServiceTypeSale] = useState([]);

  // const viewMoreServiceTypeSale = () => {
  //   setServiceTypeSale(materailWiseSale);
  // };

  // useEffect(() => {
  //   setServiceTypeSale(materailWiseSale);
  // }, []);
  // ======================SERVICE TYPE SALE END===============================

  // ======================GARAGE SALE END===============================

  // const viewMoreGarageSale = () => {
  //  console.log("Test")
  // };

  // const garageRevenue = () => {
  //   let garageTotal =
  //     orders &&
  //     Object.values(
  //       orders?.reduce((acc, { garage, totalPrice }) => {
  //         if (garage != null) {
  //           acc[garage] = acc[garage] || { garage, totalPrice: 0 };

  //           acc[garage].totalPrice = String(
  //             Number(acc[garage].totalPrice) + Number(totalPrice)
  //           );
  //         }
  //         return acc;
  //       }, {})
  //     );
  //   garageTotal &&
  //     setGarageSale(
  //       garageTotal.sort((a, b) => b.totalPrice - a.totalPrice).slice(0, 5)
  //     );
  // };

  // useEffect(() => {
  //     garageRevenue()
  // }, [orders]);

  // ======================GARAGE SALE END===============================

  // ======================MATERIAL WISE SALE START===============================

  const [isViewMoreMaterialSale, setIsViewMoreMaterialSale] = useState(false);
  const [materialSale, setMaterialSale] = useState([]);

  const viewMoreMaterialSale = () => {
    setIsViewMoreMaterialSale(!isViewMoreMaterialSale);
  };

  const viewMoreMaterialWiseSale = () => {
    setMaterialSale(
      isViewMoreMaterialSale ? materailWiseSale : materailWiseSale.slice(0, 5)
    );
  };

  useEffect(() => {
    viewMoreMaterialWiseSale();
  }, [isViewMoreMaterialSale]);

  // ======================MATERIAL WISE SALE END===============================

  // ======================VENDOR WISE SALE START===============================

  const [isViewMoreVendorSale, setIsViewMoreVendorSale] = useState(false);
  const [VendorWiseSale, setVendorWiseSale] = useState([]);

  const viewMoreVendorSale = () => {
    setIsViewMoreVendorSale(!isViewMoreVendorSale);
  };

  const viewMoreVendorWiseSale = () => {
    setVendorWiseSale(
      isViewMoreVendorSale ? vendorWiseSale : vendorWiseSale.slice(0, 5)
    );
  };

  useEffect(() => {
    viewMoreVendorWiseSale();
  }, [isViewMoreVendorSale]);

  // Call revenue function after viewMoreGarageSale function call to get garage wise sale.

  // useEffect(() => {
  //   garageRevenue()
  // }, [isViewMoreGarageSale]);

  // const garageRevenue = () => {
  //   let garageTotal =
  //     orders &&
  //     Object.values(
  //       orders?.reduce((acc, { garage, totalPrice }) => {
  //         if (garage != null) {
  //           acc[garage] = acc[garage] || { garage, totalPrice: 0 };

  //           acc[garage].totalPrice = String(
  //             Number(acc[garage].totalPrice) + Number(totalPrice)
  //           );
  //         }
  //         return acc;
  //       }, {})
  //     );

  //   garageTotal &&
  //     setGarageSale(
  //       isViewMoreGarageSale
  //         ? garageTotal.sort((a, b) => b.totalPrice - a.totalPrice)
  //         : garageTotal.sort((a, b) => b.totalPrice - a.totalPrice).slice(0, 5)
  //     );
  // };

  // useEffect(() => {
  //   garageRevenue();
  // }, [orders]);

  // ======================GARAGE SALE END===============================

  //============================ VEHICLE TYPE SALE START=====================================

  const handleVehicleTypeSale = () => {
    setSaleByVehicleType([]);

    let customIndexes = [];
    const filteredYear = getUniqueYearsFromDate();

    let curYear = new Date().getFullYear();
    let curMonth = new Date().getMonth();

    filteredYear?.map((u) => {
      if (filteredDate != null) {
        let start = moment(
          filteredDate[0] && filteredDate[0]?.startDate
        ).format("YYYY-MM-DD");
        let end = moment(filteredDate[0] && filteredDate[0]?.endDate).format(
          "YYYY-MM-DD"
        );

        for (let i = start?.split("-")[1]; i <= end?.split("-")[1]; i++) {
          let mo = i < 10 && i.toString.length == 1 ? `0${i}` : i;

          customIndexes[`${u} ${mo}`] = {
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${u}`,
            vehicle: "",
          };
        }
      } else if (u == curYear) {
        curYear = curYear;
        curMonth = curMonth;
        for (let i = 1; i <= curMonth + 1; i++) {
          let mo = i < 10 ? `0${i}` : i;
          customIndexes[`${curYear} ${mo}`] = {
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${curYear}`,
            vehicle: "",
          };
        }
      } else if (u == 2021) {
        for (let i = 6; i <= 12; i++) {
          let mo = i < 10 ? `0${i}` : i;
          customIndexes[`${u} ${mo}`] = {
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${u}`,
            vehicle: "",
          };
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          let mo = i < 10 ? `0${i}` : i;
          customIndexes[`${u} ${mo}`] = {
            carsale: 0,
            bikesale: 0,
            month: `${generatedMonths[i - 1]} ${u}`,
            vehicle: "",
          };
        }
      }
    });

    const vehicleSale = Object.values(
      orders.reduce((acc, order) => {
        if (
          order?.completionDate != null &&
          order?.orderStatus?.status == "Complete"
        ) {
          if (order?.vehicleType && order?.vehicleType?.vehicleType == "Bike") {
            let yIdx = Number(order?.completionDate?.split("-")[0]);
            let mIdx = Number(order?.completionDate?.split("-")[1]);
            mIdx = mIdx < 10 ? `0${mIdx}` : mIdx;
            let idx = `${yIdx} ${mIdx}`;

            customIndexes[idx] = customIndexes[idx] || {
              month: `${generatedMonths[mIdx]} ${filteredYear[yIdx]}`,
              carsale: 0,
              bikesal: 0,
              vehicle: "",
            };

            customIndexes[idx].bikesale =
              customIndexes[idx].bikesale + order?.totalPrice;
            customIndexes[idx].vehicle = "Bike";
          } else {
            let yIdx = Number(order?.completionDate?.split("-")[0]);
            let mIdx = Number(order?.completionDate?.split("-")[1]);
            mIdx = mIdx < 10 ? `0${mIdx}` : mIdx;
            let idx = `${yIdx} ${mIdx}`;

            customIndexes[idx] = customIndexes[idx] || {
              month: `${generatedMonths[mIdx]} ${filteredYear[yIdx]}`,
              carsale: 0,
              bikesal: 0,
              vehicle: "",
            };

            customIndexes[idx].carsale =
              customIndexes[idx].carsale + order?.totalPrice;

            customIndexes[idx].vehicle = "Car";
          }
        }
        return customIndexes;
      }, {})
    );

    vehicleSale.length > 0 &&
      vehicleSale?.map((sale, index) => {
        setSaleByVehicleType((prev) => [
          ...prev,
          {
            month: sale?.month,
            carsale: sale?.carsale,
            bikesale: sale?.bikesale,
            vehicle: sale?.vehicle,
          },
        ]);
      });
  };

  useEffect(() => {
    handleVehicleTypeSale();
  }, [orders]);

  //============================ VEHICLE TYPE SALE END=======================================

  //============================ GOAL OVERVIEW START =====================================

  const [actualSale, setActualSale] = useState(0);

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }
  let numberOfMonths = null;

  let currentMonthTarget = null;

  const getCurrentMonthTarget = (curdate) => {
    const date = moment(new Date(curdate)).format("YYYY-MM-DD");

    const daysInMonth = new Date(
      date.split("-")[0],
      date.split("-")[1],
      0
    ).getDate();

    const monthTarget = MONTHLY_TARGET / daysInMonth;
    return monthTarget * date.split("-")[2];
  };

  if (filteredDate) {
    const start = filteredDate[0] && filteredDate[0]?.startDate;
    const end = filteredDate[0] && filteredDate[0]?.endDate;
    currentMonthTarget = getCurrentMonthTarget(end);

    numberOfMonths = Math.abs(
      getMonthDifference(
        new Date(
          start && new Date(start)?.getFullYear(),
          start && new Date(start)?.getMonth()
        ),
        new Date(
          end && new Date(end)?.getFullYear(),
          end && new Date(end)?.getMonth()
        )
      )
    );
  } else {
    const todaysDate = new Date();

    currentMonthTarget = getCurrentMonthTarget(todaysDate);
    numberOfMonths = Math.abs(
      getMonthDifference(new Date(), new Date(2021, 6, 0))
    );
  }

  const goal = MONTHLY_TARGET * numberOfMonths + Math.round(currentMonthTarget);

  let sale = (actualSale / goal) * 100;

  let goalOverviewValues = [
    { name: "Actual", value: Math.round(sale) },
    { name: "Goal", value: Math.round(100 - sale) },
  ];
  const COLORS = ["#7B8BD4", "#122164", "#FFBB28", "#FF8042"];
  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const findActualSale = () => {
    let garageTotal =
      orders &&
      orders?.reduce((acc, { completionDate, orderStatus, totalPrice }) => {
        if (completionDate != null && orderStatus == "Complete") {
          acc += totalPrice;
        }

        return acc;
      }, 0);
    garageTotal && setActualSale(garageTotal);
  };
  useEffect(() => {
    findActualSale();
  }, [orders]);

  //============================ GOAL OVERVIEW END =======================================

  //============================ Product In Graph Start=======================================

  const productInGraphData = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  //============================ Product In Graph end =======================================

  //============================ Product In Graph Start=======================================

  const productOutGraphData = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  //============================ Product In Graph end =======================================

  const props = {
    filteredDate,
    handleOpenDatePicker,
    handleDayFilterChange,
    comparison: {
      compareSections,
      compareSectionsView,
    },
    salesGraph: {
      salesHeading: "Sales",
      sales,
      salesDataKey,
    },
    graphFilters: {
      setYearToggle,
      YearToggle,
      setYearsToggle,
      YearsToggle,
    },
    graphFilterFunctions: {
      handleSalesYears,
      handleSalesMonths,
      handleSalesWeeks,
      handleSalesDays,
    },
    // latestOrdersReview: {
    //   latestReviewHeading: "Latest Orders Review",
    //   latestReview,
    //   latestOrdersReviewCarouselView,
    // },
    goalOverview: {
      goalHeading: "Goal Overview",
      goal,
      goalOverviewValues,
      actualSale,
      COLORS,
    },
    saleByVehicleType: {
      saleByVehicleType,
      saleByVehicleTypeHeading: "Vehicle Wise Sales",
    },
    saleByServiceType: {
      serviceSalesHeading: "Material Wise Sale",
      saleByServiceType: materialSale,
    },
    saleByGarage: {
      saleByGarage: VendorWiseSale,
      saleByGarageHeading: "Vendor Wise Sale",
    },
    dayFilter,
    grossProfit: {
      grossProfitHeading: "Gross Profit",
      grossProfitData,
    },
    renderCustomizedLabel,
    productIn: {
      productInGraphData,
      productInHeading: "Product In",
    },
    productOut: {
      productOutGraphData,
      productOutHeading: "Product Out",
    },
    viewMoreMaterialSale,
    viewMoreVendorSale,
    isViewMoreMaterialSale,
    isViewMoreVendorSale,
  };

  return (
    <>
      <DashboardComponent propsData={props} />
    </>
  );
};

export default InventoryDashboard;
