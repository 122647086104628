import React, { useState, useEffect } from "react";
import "./Login.css";
import { Box, TextField, Button, Grid, Typography } from "@mui/material";
import Routing from "../components/Route";
import { useDispatch, useSelector } from "react-redux";
import { AdminLogin, AdminLogout } from "../action";
import { getGarages } from "../services/garageServices/GarageServices";
import { userLogin } from "../services/authServices/Auth";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function Login() {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    usernameOrMobileNumber: "",
    password: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errors, setErrors] = useState({});

  const isLoggedData = useSelector((state) => {
    return state.AdminLogin.isLogged;
  });

  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setErrors({ message: "" });
  }, [loginDetails]);

  // admin login details
  const adminDetails = [
    {
      username: "admin",
      password: "Gati@12345",
    },
    {
      username: "parmeshwar",
      password: "Parmeshwar@123",
    },
  ];
  let localData = JSON.stringify(localStorage.getItem("loginDetails"));
  useEffect(() => {
    // getGarageNames();
    setErrors({
      message: "",
    });

    if (localData.isLoggedIn == false) {
      navigate("/");
    }
  }, [, localData]);

  const usersLogin = async (login) => {
    try {
      const response = await userLogin(login);
      const data = await response.data;
      const result = data.result;
      console.log("result", result);

      if (data.statusCode === 200) {
        const loginDetail = {
          isLogged: true,
          jwtToken: result.token,
          role: result.role,
        };

        setIsLoggedIn(loginDetail?.isLogged);

        localStorage.setItem("loginDetails", JSON.stringify(loginDetail));
        setLoginDetails({
          usernameOrMobileNumber: "",
          password: "",
        });
      }

      // response
    } catch (error) {
      console.log(error);
      const data = error?.response?.data;
      if (data?.statusCode == 400) {
        setErrors({
          message: "Username or Password is invalid.",
        });
      }
    }
  };

  const handelAdminSubmit = (e) => {
    e.preventDefault();
    usersLogin(loginDetails);
    // setLoginDetails({
    //   usernameOrMobileNumber: "",
    //   password: "",
    // });
  };

  const getLoginDetailsLS = JSON.parse(localStorage.getItem("loginDetails"));
  // const sessionData = sessionStorage.getItem("userLogin");
  // const userName = sessionStorage.getItem("userName");
  const isLogged = getLoginDetailsLS?.isLogged;
  const jwtToken = getLoginDetailsLS?.jwtToken;
  const role = getLoginDetailsLS?.role;

  useEffect(() => {
    dispatch(
      AdminLogout({
        isLogged,
        jwtToken,
        role,
      })
    );
    if (getLoginDetailsLS?.isLogged == false) {
      navigate("/");
    }
    setIsLoggedIn(isLogged);
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const viewPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {(isLoggedData || isLoggedIn) == true ? (
        <Routing />
      ) : (
        <Grid container spacing={1}>
          {/*------------image conatiner------------*/}
          <Grid item xs={12} md={6} sm={12}>
            <Box className="img-container">
              <img
                className="login-background"
                src={
                  process.env.PUBLIC_URL + "/Assets/login/loginbackgroung.png"
                }
              />
              <img
                className="gativan-logo"
                src={
                  process.env.PUBLIC_URL +
                  "/Assets/login/GativanFullLogoWhite.png"
                }
              />
              <Box className="social-media-logo">
                <Box className="facebook-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/login/Facebook.png"}
                  />
                </Box>
                <Box className="twitter-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/login/Twitter.png"}
                  />
                </Box>
                <Box className="instagram-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/Assets/login/Instagram.png"}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          {/*------------login form container-----*/}

          <Grid
            item
            xs={12}
            md={6}
            sm={12}
            className="login-form-container"
            sx={{ width: "100%" }}
          >
            <form
              name="login-form"
              onSubmit={handelAdminSubmit}
              style={{ width: "90%" }}
            >
              <Box className="login-form">
                <h4 style={{ textAlign: "center" }}>Admin Login</h4>
                <Box className="text-field-mobile">
                  <TextField
                    name="usernameOrMobileNumber"
                    value={loginDetails.usernameOrMobileNumber}
                    onChange={handleChange}
                    className="enter-mobile-no"
                    placeholder="Enter username"
                    autoComplete="off"
                  ></TextField>
                </Box>
                {/* <Box className="text-field-mobile">
                  <TextField
                    name="password"
                    value={loginDetails.password}
                    onChange={handleChange}
                    type="password"
                    className="enter-mobile-no"
                    placeholder="Enter  Password"
                    autoComplete="off"
                  ></TextField>
                </Box> */}

                <Box
                  sx={{ position: "relative" }}
                  className="text-field-mobile"
                >
                  <TextField
                    name="password"
                    value={loginDetails.password}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    className="enter-mobile-no"
                    placeholder="Enter  Password"
                    autoComplete="off"
                  />
                  <Box
                    // className={classes.viewPasswordBox}
                    sx={{ position: "absolute", top: "30%", right: "0%" }}
                  >
                    {showPassword ? (
                      <VisibilityIcon
                        sx={{ cursor: "pointer" }}
                        onClick={viewPassword}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: "pointer" }}
                        onClick={viewPassword}
                      />
                    )}
                  </Box>
                </Box>
                <Box sx={{ height: "25px" }}>
                  {errors?.message && (
                    <Typography sx={{ color: "red", mb: 1 }}>
                      {errors?.message}
                    </Typography>
                  )}
                </Box>
                {/* <Button className="confirm-btn" onClick={handelAdminSubmit}>
                  Login
                </Button> */}

                <Button className="confirm-btn" type="submit">
                  Login
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
}
