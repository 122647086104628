import React, { useState, useEffect, useMemo, useContext } from "react";
import Table from "../../components/tables/Table";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getOrders } from "../../services/orderServices/OrderServices";
import {
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import moment from "moment";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import httpCommon from "../../http-common";
import { CommonContextAPI } from "../../App";
import {
  getFranchiseSalesReport,
  getSalesReport,
} from "../../services/SalesReport";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const CustomToolbar = () => {
  const commonData = useContext(CommonContextAPI);
  const { clearAppliedFilters } = commonData;
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <GridToolbarQuickFilter />
      <div>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          // className={classes.clearButton}
          style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </GridToolbarContainer>
  );
};

const FranchiseSalesReport = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [franchiseSalesReport, setFranchiseSalesReport] = useState([]);
  const [bindFranchiseSalesReport, setBindFranchiseSalesReport] = useState([]);

  const [basicPrice, setBasicPrice] = useState(0);
  const [filteredBasicPrice, setFilteredBasicPrice] = useState(null);

  const [sale, setSale] = useState(0);
  const [filteredSale, setFilteredSale] = useState(null);

  const [count, setCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(null);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    defaultDate,
  } = commonData;

  const [filter, setFilter] = useState([]);

  const columns = [
    {
      field: "franchiseId",
      headerName: "Franchise ID",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "garageType",
      headerName: "Garage Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "pan",
      headerName: "PAN",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "dateOfPayment",
      headerName: "Date Of Payment",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "paymentDateTime",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "basicPrice",
      headerName: "Basic Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "sale",
      headerName: "Sale",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
  ];

  useMemo(() => {
    const totalBasicPrice = bindFranchiseSalesReport
      ?.map((i) => i.basicPrice)
      .reduce((a, b) => a + b, 0);
    setBasicPrice(totalBasicPrice);

    const totalSale = bindFranchiseSalesReport
      ?.map((i) => i.sale)
      .reduce((a, b) => a + b, 0);
    setSale(totalSale);

    setCount(bindFranchiseSalesReport?.length);
  }, [bindFranchiseSalesReport]);

  // Get all orders.
  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const getFranchiseSales = async () => {
    setFranchiseSalesReport([]);
    setBindFranchiseSalesReport([]);
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
      // params: { all: true },
      params: { from: startDate, to: endDate },
    };

    try {
      const request = await getFranchiseSalesReport(config);
      const response = await request.data;
      setFranchiseSalesReport(response?.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setFranchiseSalesReport([]);

    if (defaultDate || filteredDate) {
      getFranchiseSales();
      setLoading(true);
    }
  }, [, defaultDate, filteredDate]);

  useEffect(() => {
    setBindFranchiseSalesReport([]);
    franchiseSalesReport?.map((sale) => {
      const obj = {
        ...sale,
        customerName: sale?.garage?.garageName,
        mobileNumber: sale?.garage?.mobileNumber,
        garageType: sale?.garage?.garageType == 1 ? "Car" : "Bike",
        pan: sale?.garage?.pan,
        paymentMode: sale?.paymentMode?.mode,
        paymentDateTime: new Date(
          `${sale?.dateOfPayment} ${sale?.createdTime}`
        ),
      };

      setBindFranchiseSalesReport((prev) => [...prev, obj]);
    });
  }, [franchiseSalesReport]);

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("dateOfPayment");
  }, [filteredDate && filteredDate]);

  function FooterSection() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Count :{" "}
              <span className="grid-table-footer-right-value">
                {filteredCount ? filteredCount : count}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Basic Price :{" "}
              <span className="grid-table-footer-right-value">
                {filteredBasicPrice
                  ? filteredBasicPrice?.toFixed(2)
                  : basicPrice?.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Sale :{" "}
              <span className="grid-table-footer-right-value">
                {filteredSale ? filteredSale?.toFixed(2) : sale?.toFixed(2)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  const style = {
    "& .super-app-theme--false": {
      backgroundColor: "#D0342C",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#D0342C",
        color: "#fff",
      },
    },
  };

  return (
    <Box>
      <Table
        customStyle={{ ...DataGridStyle, ...style }}
        rows={bindFranchiseSalesReport}
        columns={columns}
        heading="Franchise Sales Report"
        pageSize={50}
        rowsPerPageOptions={[100]}
        initialState={{
          sorting: {
            sortModel: [{ field: "paymentDateTime", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              paymentDateTime: false,
            },
          },
        }}
        onStateChange={(state) => {
          setFilteredBasicPrice(null);
          setFilteredSale(null);
          setFilteredCount(null);
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [invoiceNumber, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(invoiceNumber);
            }
          }
          const res = bindFranchiseSalesReport.filter((item) =>
            visibleItems.includes(item.franchiseId)
          );
          const basicPrice = res
            ?.map((i) => i.basicPrice)
            .reduce((a, b) => a + b, 0);

          const sales = res?.map((i) => i.sale).reduce((a, b) => a + b, 0);

          setFilteredBasicPrice(basicPrice);
          setFilteredSale(sales);
          setFilteredCount(res?.length);
        }}
        filterModel={filterModel && filterModel}
        // total={totalsconst commonData = useContext(CommonContextAPI);}
        loading={loading}
        downloadXLS={() =>
          downloadXLS(filter.length > 0 ? filter : bindFranchiseSalesReport)
        }
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row?.franchiseId}
        getRowClassName={(params) => `super-app-theme--${params.row.isInvoice}`}
      />
    </Box>
  );
};

export default FranchiseSalesReport;
