import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Box,
  Grid,
  Button,
  Avatar,
  ButtonBase,
  CardHeader,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getServiceTags,
  addServiceTags,
  deleteTag,
  deleteServiceTags,
} from "../../services/SearchKeywordsServices/KeywordsServices";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    padding: "0px 1rem",
  },
  gridContainer: {
    [theme.breakpoints.up("md")]: {
      position: "relative",
      marginTop: "3rem",
      height: "auto",
    },
  },
  searchbarContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
  },
  inputInput: {
    fontSize: "1.2rem",
    padding: "0px 5px",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0.6rem 2rem !important",
    height: "auto",
    paddingTop: "3rem !important",
    marginTop: "0.6rem",
    [theme.breakpoints.down("md")]: {
      padding: "0px 1rem !important",
      paddingTop: "3rem !important",
    },
    [theme.breakpoints.up("md")]: {
      height: "93vh",
      padding: "0.6rem 1.5rem !important",
      paddingTop: "3rem !important",
    },
    [theme.breakpoints.only("md")]: {
      height: "93vh",
      padding: "0px 0px 0px 4px !important",
      paddingTop: "3rem !important",
      margin: "auto !important",
    },
    [theme.breakpoints.up("xl")]: {
      height: "93vh",
      paddingTop: "3rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
    },
  },
  fixedFilterBox: {
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      width: "18%",
      height: "auto",
      overflow: "auto",
      // padding: "1rem, 0px !important",
    },
    [theme.breakpoints.up("lg")]: {
      position: "fixed",
      width: "18.7%",
      height: "auto",
      overflow: "auto",
      // padding: "1rem, 0px !important",
    },
  },
  categoryList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.only("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  searchButton: {
    width: "100%",
    backgroundColor: "#122164",
    padding: "7px 10px",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      margin: "0.5rem 0px",
    },
  },
  filterButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0.5rem 0px",
    },
  },
  mainContainer: {
    marginTop: "2.5rem",
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid grey",
      minHeight: "90vh",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0rem !important",
      marginTop: "0.5rem",
    },
  },
  searchBoxWrapper: {
    display: "flex",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#E5E5E5",
    color: "black",
    boxShadow: "0px 0px 5px gray",
    marginLeft: 0,
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "350px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
    },
  },
  vehicleType: {
    margin: "1rem 0px",
  },
  vehicleTypesWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  serviceButtons: {
    margin: "0.5rem 0px",
    color: "#fff",
    fontSize: "0.9rem",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      margin: "0.5rem",
      fontSize: "0.8rem",
    },
    [theme.breakpoints.only("md")]: {
      width: "90%",
      fontSize: "0.766rem",
    },
  },
  filters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  filterItem: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0.5rem 0px",
    },
  },
  selectDropdown: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      paddingTop: "0.7rem !important",
      paddingLeft: "0.5rem !important",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
  },
  icons: {
    margin: "0px 0.5rem",
  },
  expandIcon: {
    fontSize: "2rem",
  },
  services: {
    width: "100%",
    padding: "2rem 1rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  categoryTitle: {
    fontSize: "1.5rem",
    margin: "0.5rem 0px",
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
  },
  serviceHeading: {
    marginLeft: "0.5rem",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  serviceRate: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  image: {
    width: "80px",
    height: "80px",
    padding: "0.3rem",
    borderRadius: "50px",
    boxShadow: "0px 0px 3px black",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  },
  AccordionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    "& .$MuiAccordionSummary-root": {
      margin: "1rem 0px",
    },
  },
  checkIcon: {
    color: "#51ae3a",
    marginRight: "0.5rem",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  serviceDetails: {
    padding: "1rem 0px",
    [theme.breakpoints.up("sm")]: {
      // width: "70%",
    },
  },
  "& li": {
    fontSize: "1rem",
  },
  serviceActivities: {
    [theme.breakpoints.up("sm")]: {
      // width: "70%",
    },
  },
  radioButton: {
    color: "#122164 !important",
  },
  textField: {
    "& .MuiInputBase-input": {
      padding: "auto",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // GET CONTEXT COMMON DATA
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const actions = [
  { action: "enable", icon: <CircleIcon sx={{ color: "green" }} /> },
  { action: "pause", icon: <PauseCircleIcon sx={{ color: "gray" }} /> },
  { action: "remove", icon: <CancelIcon sx={{ color: "red" }} /> },
];

function SearchKeywordsForms({ setOpenAddKeyword, selectedService }) {
  const classes = useStyles();
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Status",
      headerClassName: "super-app-theme--header-actions",
      editable: true,
      type: "singleSelect",
      // valueOptions: ["enable", "pause", "remove"],
      valueOptions: actions?.map((action) => action.action),
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <ExpandMoreIcon />
          </Tooltip>
        </Box>
      ),
      maxWidth: 30,
      minWidth: 30,
      flex: 1,
    },
    {
      field: "tagId",
      headerName: "Tag ID",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "tag",
      headerName: "Tags",
      headerClassName: "super-app-theme--header",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "finalUrl",
      headerName: "Final URL",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "clicks",
      headerName: "Clicks",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
  ];

  const [addKeywords, setAddKeywords] = useState(false);
  const [saveKeywords, setSaveKeywords] = useState({ keywords: "" });
  const [allServiceTags, setAllServiceTags] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  console.log("selectionModel", selectionModel);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSaveKeywords({ ...saveKeywords, [name]: value });
  };

  // ---------------Get all service tags
  const getAllServiceTags = async () => {
    const localDetails = JSON.parse(localStorage.getItem("loginDetails"));
    const config = {
      headers: {
        Authorization: `Bearer ${localDetails.jwtToken}`,
      },
    };
    try {
      const response = await getServiceTags(selectedService.serviceId, config);
      const result = await response.data;

      if (result.statusCode == 200) {
        setAllServiceTags(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllServiceTags();
  }, [, addKeywords]);

  // -------- Add new Service tags.

  const addNewServiceTags = async (e) => {
    e.preventDefault();
    const localDetails = JSON.parse(localStorage.getItem("loginDetails"));
    const postObj = {
      tag: saveKeywords.keywords,
      serviceId: selectedService.serviceId,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localDetails.jwtToken}`,
      },
    };

    try {
      const response = await addServiceTags(postObj, config);
      const result = await response.data;
      setAddKeywords(false);
      setSaveKeywords({ keywords: "" });
    } catch (error) {
      console.log(error);
    }
  };

  // ----------------- Delete Tag--------------

  const deleteServiceTag = async (tagId) => {
    const localDetails = JSON.parse(localStorage.getItem("loginDetails"));
    const config = {
      headers: {
        Authorization: `Bearer ${localDetails.jwtToken}`,
      },
    };
    try {
      if (selectionModel.length == allServiceTags.length) {
        const response = await deleteServiceTags(tagId, config);
        getAllServiceTags();
        setAllServiceTags([]);
      } else if (selectionModel.length > 0) {
        if (selectionModel[selectionModel.length - 1] == tagId) {
          const response = await deleteTag(tagId, config);
          getAllServiceTags();
        } else {
          const response = await deleteTag(tagId, config);
        }
      } else {
        setAllServiceTags([]);
        const response = await deleteTag(tagId, config);
        getAllServiceTags();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteTag = (params) => {
    if (params.field == "actions") {
      if (params.value == "remove") {
        deleteServiceTag(params.id);
      }
    }
  };

  const deleteMultiple = () => {
    if (selectionModel.length == allServiceTags.length) {
      deleteServiceTag(selectedService.serviceId);
    } else {
      selectionModel?.map((selected) => {
        deleteServiceTag(selected);
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography variant="h5">{selectedService.service}</Typography>
      </Box>
      <Box>
        <ButtonBase>
          <Avatar onClick={() => setAddKeywords(true)}>
            <AddIcon />
          </Avatar>
        </ButtonBase>
        <Button
          sx={{
            marginLeft: "1rem",
            backgroundColor: "#122164",
            "&:hover": { backgroundColor: "#122164" },
          }}
          variant="contained"
          onClick={() => setOpenAddKeyword(false)}
        >
          Go Back
        </Button>
      </Box>
      {addKeywords && (
        <Box sx={{ margin: "2rem 0px" }}>
          <Card sx={{ minWidth: 275, width: "50%", padding: "1rem" }}>
            <form onSubmit={addNewServiceTags}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Add Keywords</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      sx={{ "& .MuiInputBase-input": { px: 2 } }}
                      size="small"
                      autoFocus
                      label="Please Enter Tags"
                      fullWidth
                      variant="outlined"
                      id="keywords"
                      margin="dense"
                      name="keywords"
                      value={saveKeywords.keywords}
                      onChange={handleChange}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="finalUrl"
                    fullWidth
                    variant="outlined"
                    id="finalUrl"
                    margin="dense"
                    name="finalUrl"
                    value={saveKeywords.finalUrl}
                    onChange={handleChange}
                  />
                </Grid> */}
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  sx={{
                    backgroundColor: "#ef1313",
                    "&:hover": { backgroundColor: "#ef1313" },
                  }}
                  variant="contained"
                  onClick={() => setAddKeywords(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    backgroundColor: "#122164",
                    "&:hover": { backgroundColor: "#122164" },
                  }}
                  disabled={saveKeywords.keywords == "" ? true : false}
                  // onClick={addNewServiceTags}
                >
                  Save
                </Button>
              </CardActions>
            </form>
          </Card>
        </Box>
      )}
      {selectionModel.length > 1 && (
        <Box>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-filled-label">Edit</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={11}
              onChange={deleteMultiple}
            >
              {actions.map((action, index) => {
                return (
                  <MenuItem
                    value={action}
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>{action.icon}</span>
                    <span>{action.action}</span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      )}

      <Box sx={{ height: "80vh", width: "100%" }}>
        <DataGrid
          sx={{
            width: "100%",
            position: "relative",
            "&:hover": {
              cursor: "pointer",
            },
            "& .super-app-theme--header": {
              backgroundColor: "#122164",
              color: "#fff",
              textAlign: "center !important",
              "& .MuiSvgIcon-fontSizeSmall": {
                color: "#fff !important",
                visibility: "visible !important",
                display: "block !important",
              },
              "& .css-1durks0 .super-app-theme--header .MuiSvgIcon-fontSizeSmall":
                {
                  color: "#fff !important",
                  visibility: "visible !important",
                  display: "block !important",
                },
            },
            "& .MuiMenuItem-root": {
              backgroundColor: "black !important",
              color: "red !important",
            },
            "& .MuiPaper-root": {
              backgroundColor: "red !important",
            },
            "& .MuiMenu-list": {
              backgroundColor: "#123456 !important",
            },
            "& .css-r11z79-MuiDataGrid-root .MuiDataGrid-cell": {
              border:
                window.location.pathname == "/servicinggrossprofit" &&
                "none !important",
              outline:
                window.location.pathname == "/servicinggrossprofit" &&
                "none !important",
            },
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
              {
                backgroundColor: "#122164",
                color: "#ff1a1a !important",
                textAlign: "center !important",
                padding: "1rem 0.6rem !important",
                "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiSvgIcon-root":
                  {
                    color: "#fff",
                  },
              },
            "& .super-app-theme--header-actions": {
              backgroundColor: "#122164",
              color: "#122164",
              border: "none !important",
              outline: "none !important",
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
            },
          }}
          rows={allServiceTags && allServiceTags}
          columns={columns}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          onCellEditCommit={(params) => {
            handleDeleteTag(params);
          }}
          checkboxSelection
          pageSize={50}
          getRowId={(row) => row.tagId}
          components={{ Toolbar: QuickSearchToolbar }}
        />
      </Box>
    </Box>
  );
}

export default SearchKeywordsForms;
