import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import httpCommon from "../../http-common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import React from "react";
import { useSelector } from "react-redux";
import "./LeadCard.css";
import { useMediaQuery, useTheme } from "@material-ui/core";

const LeadCard = ({ lead, handleViewLeadCard }) => {
  const theme = useTheme();
  const belowTablets = useMediaQuery(theme.breakpoints.down("sm"));
  const LeadCardReducer = useSelector((state) => state.LeadCardReducer);
  const baseURL = httpCommon.defaults.baseURL;
  return (
    <Box className="card-container">
      {belowTablets && (
        <Typography
          className="back-to-view-card-link"
          onClick={() => handleViewLeadCard(false)}
        >
          <ArrowBackIcon /> Lead Services
        </Typography>
      )}
      <Box className="card-header-section">
        <Typography className="heading">Lead Card</Typography>
        <Typography className="logo">
          <img
            src={`${process.env.PUBLIC_URL}/Assets/logos/GativanFullLogoWhite.png`}
            alt="Gativan Logo"
          />
        </Typography>
      </Box>
      <Box className="user-section">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="grid-user-col">
            <Typography className="user-name">
              {lead?.leadName || "Customer Name: ---"}
            </Typography>
            <Typography className="user-value">
              {lead?.brand?.brand || "Brand: ---, "}{" "}
              {lead?.model?.model || "Model: ---"}
            </Typography>
            <Typography className="user-value">
              {lead?.vehicleNumber || "Vehicle Number: ---"}
            </Typography>
            <Typography className="user-label">Contact Number</Typography>
            <Typography className="user-value">
              {lead?.mobileNumber || "---"}
            </Typography>
            <Typography className="user-label">Customer Address</Typography>
            <Typography className="user-value">
              {lead?.city?.city || "---"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className="grid-vehicle-col">
            <img
              src={`${baseURL}${lead?.model?.imageUrl}`}
              alt="Vehicle Image"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="sub-header-section">
        <Typography className="sub-heading">Jobs</Typography>
        <Typography className="sub-logo">
          <img src={process.env.PUBLIC_URL + "/Assets/logos/logo.png"} alt="" />
        </Typography>
      </Box>
      <Box className="services-section">
        <Box className="services-list">
          {LeadCardReducer?.allServices?.length > 0 ? (
            LeadCardReducer?.allServices?.map((service) => {
              return (
                <Box key={service?.id}>
                  <Typography className="service-title">
                    {service?.service}
                  </Typography>
                  <Divider className="divider" />
                  <Box className="activity-wrapper">
                    {service?.activities?.map((activity, index) => {
                      return (
                        <Box className="activities-box" key={activity?.id}>
                          <Typography className="activities">
                            <span className="sr-no">{index + 1} </span>
                            {activity?.activity}
                          </Typography>
                          <CheckCircleIcon className="check-icon" />
                        </Box>
                      );
                    })}
                  </Box>
                  <Box className="sub-total-wrapper">
                    <Typography className="sub-total-heading">
                      Sub Total
                    </Typography>
                    <Typography className="sub-total-amount">
                      <CurrencyRupeeIcon className="rupee-icon" />{" "}
                      {service?.displayPrice}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography>No Service</Typography>
          )}
        </Box>
      </Box>
      <Box className="total-container">
        <Divider className="total-divider" />
        <Box className="total-wrapper">
          <Typography className="total-heading">Total</Typography>
          <Typography className="total-amount">
            <CurrencyRupeeIcon className="rupee-icon" />{" "}
            {LeadCardReducer?.cardTotal}
          </Typography>
        </Box>
      </Box>
      {belowTablets && (
        <Typography
          className="back-to-view-card-link"
          onClick={() => handleViewLeadCard(false)}
        >
          <ArrowBackIcon /> Lead Services
        </Typography>
      )}
    </Box>
  );
};

export default LeadCard;
