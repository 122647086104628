import Box from "@mui/material/Box";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { CommonContextAPI } from "../../App";
import Form from "../../components/forms/Form";
import {
  addGarage,
  garageById,
  garageTypesList,
  getGarages,
} from "../../services/garageServices/GarageServices";
import "./AddFranchise.css";
import {
  addFranchise,
  getPaymentModes,
} from "../../services/garageServices/FranchiseServices";

const location = [
  {
    name: "Pune",
    id: 1,
  },
  {
    name: "Banglore",
    id: 2,
  },
  {
    name: "Mumbai",
    id: 3,
  },
];

const entityTypes = [{ type: "Sole Proprietor" }, { type: "Partnership Firm" }];
const payoutBasedOn = [
  { basedOn: "Invoice Price" },
  { basedOn: "Taxable Amount" },
];

const AddFranchise = ({ setIsFranchiseAdded }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [garageTypes, setGarageTypes] = useState([]);
  const [franchiseDetails, setFranchiseDetails] = useState({});
  const [hideLabel, setHideLabel] = useState(false);
  const [startDate, setStartDate] = useState();
  const [garagesList, setGaragesList] = useState([]);
  const [paymentModesList, setPaymentModesList] = useState([]);

  // GET CONTEXT COMMON DATA
  const { handleCloseAddFranchise, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const userSelection = (fieldName, name, id) => {
    const obj = {
      name,
      id,
    };
    setFranchiseDetails({ ...franchiseDetails, [fieldName]: id });
  };

  const getAllGarages = async () => {
    try {
      const response = await getGarages();
      const data = await response.data;
      setGaragesList(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const getGaragesById = async (garageId) => {
    try {
      const response = await garageById(garageId);
      const data = await response.data;
      setFranchiseDetails({
        franchiseAmount: franchiseDetails?.franchiseAmount || "",
        dateOfPayment: franchiseDetails?.dateOfPayment || "",
        paymentMode: franchiseDetails?.paymentMode || {},
        ...data.result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Get vehicle types
  const getGarageTypes = async () => {
    try {
      const response = await garageTypesList();
      const result = await response.data;
      setGarageTypes(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  const paymentModes = async () => {
    try {
      const response = await getPaymentModes();
      const data = await response.data;
      setPaymentModesList(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (franchiseDetails?.garageId) {
      getGaragesById(franchiseDetails?.garageId);
    }
  }, [franchiseDetails?.garageId]);

  useEffect(() => {
    if (franchiseDetails?.garageName == undefined) {
      setFranchiseDetails({});
    }
  }, [franchiseDetails?.garageName]);

  useEffect(() => {
    getGarageTypes();
    getAllGarages();
    paymentModes();
  }, []);

  const handleDateChange = (date) => {
    setFranchiseDetails({
      ...franchiseDetails,
      dateOfPayment: moment(date).format("YYYY-MM-DD"),
    });
  };

  const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));

  const addNewFranchise = async () => {
    const postObj = {
      amount: franchiseDetails?.franchiseAmount,
      dateOfPayment: franchiseDetails?.dateOfPayment,
      paymentMode: paymentModesList?.find(
        (item) => item?.mode == franchiseDetails?.paymentMode
      ),
      garage: {
        ...franchiseDetails,
      },
    };

    const config = {
      headers: {
        Authorization: `Bearer ${loginDetails?.jwtToken}`,
      },
    };
    try {
      const response = await addFranchise(postObj, config);
      const data = await response.data;
      handleAlertOpen(data?.message, colors.success);
      handleCloseAddFranchise();
      setIsFranchiseAdded(true);
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors.error);
      console.log(error);
    }
  };

  const calender = () => {
    return (
      <Box
        sx={{
          "& .react-datepicker-wrapper": {
            "& input": {
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #b9b6b6",
              fontSize: "16px",
              zIndex: "-99999999999 !important",
            },
          },
          "& .react-datepicker__close-icon::after ": {
            backgroundColor: "#122164",
            color: "#fff",
            fontSize: "1rem",
            padding: "2px 5px",
          },
        }}
      >
        <DatePicker
          style={{
            padding: "10px !important",
            border: "2px solid gray !important",
            "& .react-datepicker__input-container": {
              backgroundColor: "red",
            },
          }}
          onFocus={() => setHideLabel(true)}
          onBlur={() => setHideLabel(false)}
          selected={startDate}
          onChange={(date) => {
            handleDateChange(date);
            setStartDate(date);
            setHideLabel(false);
          }}
          dateFormat="dd/MM/yyyy"
          isClearable
          placeholderText="Please select date!"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </Box>
    );
  };

  const renderVehicleTypes = () => {
    return (
      <Grid item sm={8}>
        <ul className="add-franchise-donate-now">
          {garageTypes &&
            garageTypes.map((types, index) => {
              return (
                <li key={index}>
                  <input
                    type="radio"
                    style={{ zIndex: -999999 }}
                    id={types.type}
                    name="garageType"
                    value={types.id}
                    // defaultChecked={
                    //   franchiseDetails?.garageType &&
                    //   types?.id == franchiseDetails?.garageType
                    //     ? true
                    //     : false
                    // }
                    defaultChecked={
                      franchiseDetails?.garageType &&
                      (types?.id == franchiseDetails?.garageType ? true : false)
                    }
                    onClick={() =>
                      userSelection("garageType", types.type, types.id)
                    }
                  />
                  <label for={types.type}>{types.type}</label>
                </li>
              );
            })}
        </ul>
      </Grid>
    );
  };

  const renderForm = [
    {
      label: "Garage Name",
      fields: [
        {
          label: "Garage Name",
          field: "Autocomplete",
          name: "garageName",
          id: "garageName",
          type: "text",
          data: garagesList,
          properties: { name: "garageName", id: "garageId" },
          // props: {
          //   hideLabel,
          // },
          postId: "garageId",
          grids: 12,
        },
      ],
    },
    {
      label: "Vehicle Type",
      fields: [
        {
          label: "Vehicle Type",
          field: "Toggles",
          name: "garageType",
          id: "garageType",
          data: garageTypes,
          properties: { name: "vehicleType", id: "id" },
          type: "text",
          render: renderVehicleTypes,
          grids: 12,
        },
      ],
    },
    {
      label: "Contact Number",
      fields: [
        {
          label: "Contact Number",
          field: "TextField",
          name: "mobileNumber",
          id: "mobileNumber",
          type: "number",
          grids: 12,
        },
      ],
    },

    {
      label: "GSTN",
      fields: [
        {
          label: "GSTN",
          field: "TextField",
          name: "gstn",
          id: "gstn",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "PAN",
      fields: [
        {
          label: "PAN",
          field: "TextField",
          name: "pan",
          id: "pan",
          type: "text",
          grids: 12,
        },
      ],
    },
    {
      label: "Entity Type",
      fields: [
        {
          label: "Entity Type",
          field: "SelectDropdown",
          name: "entityType",
          data: entityTypes,
          properties: { name: "type" },
          id: "entityType",
          type: "select",
          sendVal: true,
          grids: 12,
        },
      ],
    },
    {
      label: "Franchise Amount",
      fields: [
        {
          label: "Franchise Amount",
          field: "TextField",
          name: "franchiseAmount",
          id: "franchiseAmount",
          type: "number",
          grids: 12,
        },
      ],
    },
    {
      label: "Date Of Payment",
      fields: [
        {
          label: "Date Of Payment",
          field: "Calender",
          name: "dateOfPayment",
          id: "dateOfPayment",
          type: "date",
          grids: 12,
          render: calender,
          props: {
            setHideLabel,
            setStartDate,
            startDate,
          },
        },
      ],
    },
    {
      label: "Payment Mode",
      fields: [
        {
          label: "Payment Mode",
          field: "SelectDropdown",
          name: "paymentMode",
          data: paymentModesList,
          properties: { name: "mode" },
          id: "paymentMode",
          sendVal: true,
          type: "select",
          grids: 12,
          isPaymentMode: true,
        },
      ],
    },
  ];

  return (
    <div>
      <Form
        heading="Add Franchise"
        closeModal={handleCloseAddFranchise}
        isAdded={setIsFranchiseAdded}
        renderForm={renderForm}
        service={addGarage}
        state={franchiseDetails}
        setState={setFranchiseDetails}
        modalHeight="90%"
        submit={addNewFranchise}
      />
    </div>
  );
};

export default AddFranchise;
