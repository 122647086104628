import { Button, Modal } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function DeleteConfigModal({
  isRemoveConfigModal,
  setIsRemoveConfigModal,
}) {
  const handleClose = () => {
    setIsRemoveConfigModal();
  };
  const handleDiscard = () => {
    setIsRemoveConfigModal();
  };
  const handleConfirm = () => {
    setIsRemoveConfigModal();
  };
  return (
    <Modal
      open={isRemoveConfigModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <div
        style={{
          backgroundColor: "white",
          width: "350px",
          position: "absolute",
          left: "50%",
          top: "50%    ",
          transform: "translate(-50%, -50%)",
          borderRadius: "6px",
          padding: "10px 0px",
        }}
      >
        <HighlightOffIcon
          style={{
            color: "red",
            width: "100%",
            fontSize: "70px",
            fontWeight: "100",
          }}
        />
        <h4
          style={{
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "100",
            padding: "10px 0px",
          }}
        >
          Are You Sure?
        </h4>
        <p
          style={{ textAlign: "center", fontWeight: "900", padding: "5px 0px" }}
        >
          Do you really want to delete this record.
        </p>
        <p
          style={{ textAlign: "center", fontWeight: "900", padding: "5px 0px" }}
        >
          This record cannot be restore.
        </p>
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "space-between",
          }}
        >
          <Button
            className="button-custom"
            style={{ backgroundColor: "red" }}
            onClick={handleDiscard}
          >
            Cancel
          </Button>
          <Button
            className="button-custom"
            style={{ backgroundColor: "#122164" }}
            onClick={handleConfirm}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
