import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CircleIcon from "@mui/icons-material/Circle";

import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  getLeads,
  getLeadStatus,
  searchLeads,
  updateLeadStatus,
} from "../../services/leadServices/LeadServices";
import { useNavigate } from "react-router-dom";
import "../../modules/leads/Leads.css";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Table from "../../components/tables/Table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import httpCommon from "../../http-common";
import { CommonContextAPI } from "../../App";
import { GetSearchedLeads } from "../../action";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#000",
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);
  const searchData = useSelector((state) => state.LeadsReducer);
  const { clearAppliedFilters } = commonData;

  const dispatch = useDispatch();

  // const handleChange = (query) => {
  //   dispatch(GetSearchedLeads(query));
  // };

  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(GetSearchedLeads(value));
    setSearchQuery(value);
  };

  useEffect(() => {
    setSearchQuery(searchData?.searchQuery);
  }, [searchData]);

  const clear = () => {
    clearAppliedFilters();
    dispatch(GetSearchedLeads(""));
    setSearchQuery("");
  };

  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter
        className={classes.search}
        // quickFilterParser={(searchInput) => handleChange(searchInput)}
        quickFilterFormatter={(quickFilterValues) =>
          quickFilterValues.join(", ")
        }
        onChange={handleChange}
        value={searchQuery}
        debounceMs={200}
      />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          // onClick={clearAppliedFilters}
          onClick={clear}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const Leads = ({ isLeadAdded }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [leads, setLeads] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [leadCount, setLeadCount] = useState(0);
  const navigate = useNavigate();

  const commonURL = httpCommon.defaults.baseURL;
  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const searchData = useSelector((state) => state.LeadsReducer);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    getCachedData,
    storeDataInCache,
    openDatePicker,
    defaultDate,
  } = commonData;

  // get Leads
  const getAllLeads = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    let params = {
      from: startDate,
      to: endDate,
    };

    try {
      const cacheName = "leadsList";
      const url = `${commonURL}/leads`;
      let cachedData = await getCachedData(cacheName, url);
      if (cachedData) {
        setLeads(cachedData);
        setLoading(false);
      }
      // const response = await getLeads({ params });
      const response = await getLeads({ params });
      const result = await response.data;
      setLeads(result.result);
      storeDataInCache(cacheName, url, result.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // get Lead Status
  const getAllLeadStatus = async () => {
    try {
      const response = await getLeadStatus();
      const result = await response.data;
      setLeadStatus(result.result);
    } catch (error) {
      console.log(error);
    }
  };
  const addDateProperty = () => {
    let count = 0;
    setLeadCount(0);
    setLeadsData([]);

    searchData?.data?.length > 0 || searchData?.isSearching
      ? searchData?.data?.map((lead) => {
          count += 1;
          if (lead?.mobileNumber && lead?.mobileNumber?.length == 10) {
            setLeadsData((prev) => [
              ...prev,
              {
                ...lead,
                brand: lead?.brand?.brand,
                model: lead?.model?.model,
                variant: lead?.variant?.variantName,
                city: lead?.city?.city,
                remarks: lead?.remarks,
                vehicleType: lead?.vehicleType?.vehicleType,
                channel: lead?.channel?.channel,
                status: lead?.status ? lead?.status?.status : "Raw Lead",
                leadOwner: lead?.leadOwner?.first_name,
                createdDateTime: new Date(
                  `${lead?.createdDate} ${lead?.createdTime}`
                ),
              },
            ]);
          }
        })
      : leads?.length > 0 &&
        leads?.map((lead) => {
          count += 1;
          if (lead?.mobileNumber && lead?.mobileNumber?.length == 10) {
            setLeadsData((prev) => [
              ...prev,
              {
                ...lead,
                brand: lead?.brand?.brand,
                model: lead?.model?.model,
                variant: lead?.variant?.variantName,
                city: lead?.city?.city,
                remarks: lead?.remarks,
                vehicleType: lead?.vehicleType?.vehicleType,
                channel: lead?.channel?.channel,
                status: lead?.status ? lead?.status?.status : "Raw Lead",
                leadOwner: lead?.leadOwner?.first_name,
                createdDateTime: new Date(
                  `${lead?.createdDate} ${lead?.createdTime}`
                ),
              },
            ]);
          }
        });
    setLeadCount(count);
  };

  useMemo(() => {
    addDateProperty();
  }, [filteredDate, leads, searchData?.data]);

  // Update Lead status
  const convertLeadStatus = async (params) => {
    let status = leadStatus?.find(
      (status) => status?.status == params?.value && status?.statusId
    );
    try {
      const response = await updateLeadStatus(params?.id, status?.statusId);
      const result = await response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const [filter, setFilter] = useState([]);
  const getFilteredData = (data) => {
    setFilter(data);
  };

  // const getApplyFilterFnSameYear = (value) => {
  //   if (!value || value.length !== 3) {
  //     // If the value is not a 4 digit string, it can not be a year so applying this filter is useless
  //     return null;
  //   }
  //   // return (params) => {
  //   return value;
  //   // };
  // };

  const getApplyFilterFnSameYear = (value) => {
    if (!value || value.length !== 2 || !/\d{2}/.test(value)) {
      // If the value is not a 4 digit string, it can not be a year so applying this filter is useless
      return null;
    }
    return value;
  };

  const columns = [
    {
      field: "leadId",
      headerName: "Lead ID",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      // getApplyQuickFilterFn: getApplyFilterFnSameYear,
      flex: 1,
    },
    {
      field: "leadName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "channel",
      headerName: "Channel",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "leadOwner",
      headerName: "Lead Owner",
      headerClassName: "super-app-theme--header",
      minWidth: 70,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      filterable: true,
      editable: true,
      minWidth: 120,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "createdTime",
      headerName: "Time",
      headerClassName: "super-app-theme--header",
      filterable: true,
      editable: true,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "createdDateTime",
      headerName: "Visit Data & Time",
      headerClassName: "super-app-theme--header",
      filterable: true,
      editable: true,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "vehicleType",
      editable: true,
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 60,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Brand",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "variant",
      headerName: "Variant",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },

  



    ,
    {
      field: "status",
      headerName: "Stage",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: [...leadStatus.map((status) => status?.status)],
      renderCell: (params) => {
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>{params.row.status}</span>
            <ExpandMoreIcon />
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <CircleIcon
              style={{
                color:
                  params.row.status == "Raw Lead"
                    ? "#D0342C"
                    : params.row.status == "Enquiry"
                    ? "FEB100"
                    : params.row.status == "Qualified"
                    ? "#122164"
                    : params.row.status == "Converted"
                    ? "#51AE3A"
                    : params.row.status == "Dead"
                    ? "#000000"
                    : params.row.status == "Follow Up"
                    ? "#33C2F7"
                    : params.row.status == "Unanswered"
                    ? "#9C9596"
                    : "#fff",
              }}
            />
          }
          label="actions"
        />,
      ],
      minWidth: 60,
      flex: 1,
    },

    {
      field: "totalPrice",
      headerName: "Lead Value",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },

    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },

    {
      field: "remarks",
      headerName: "Remarks",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    



  ];

  useEffect(() => {
    if (filteredDate || defaultDate) {
      setLeads([]);
      setLeadsData([]);
      if (!searchData?.isSearching) {
        getAllLeads();
        setLoading(true);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }
      getAllLeadStatus();
    }
  }, [, isLeadAdded, filteredDate, defaultDate, searchData?.isSearching]);

  const [filtedDate, setFilteredDate] = useState("");

  const getDate = (date) => {
    setFilteredDate(date);
  };

  useEffect(() => {
    filteredDate && filterDateRenge && filterDateRenge("createdDate");
  }, [filteredDate && filteredDate]);

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Lead Count :{" "}
              <span className="grid-table-footer-right-value">{leadCount}</span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  // Get leads based on search.
  // const [query, setQuery] = useState("");

  // const getLeadsBySearch = async (query) => {
  //   const params = {
  //     query: query,
  //   };

  //   try {
  //     const response = await searchLeads({ params });
  //     const data = await response.data;
  //     setLeads(data.result);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const searchThrottle = (cb, delay) => {
  //   let flag = true;

  //   return function () {
  //     const context = this;
  //     let args = arguments;
  //     if (flag) {
  //       cb.apply(context, args);
  //       flag = false;

  //       setTimeout(() => {
  //         flag = true;
  //       }, delay);
  //     }
  //   };
  // };

  // let cbFunc = searchThrottle(getLeadsBySearch, 1000);
  // cbFunc(str);
  // useMemo(() => {
  //   if (searchQuery) {
  //     // let cbFunc = searchThrottle(getLeadsBySearch, 500);
  //     cbFunc(searchQuery);
  //   } else {
  //     getAllLeads();
  //   }
  // }, [searchQuery]);

  return (
    <>
      {/* <button onClick={() => cbFunc('Hello')}>Click</button> */}

      {/* <input onChange={(e) => cbFunc(e.target.value)} /> */}

      <Table
        customStyle={DataGridStyle}
        heading="Leads"
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              createdDateTime: false,
            },
          },
        }}
        classes={{
          // sortIcon: classes.sortIcon,
          menuIconButton: classes.menuIcon,
          iconSeparator: classes.iconSeparator,
        }}
        rows={leadsData && leadsData}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        onCellEditCommit={(params) => {
          convertLeadStatus(params);
        }}
        isCellEditable={(params) => {
          return params.value == "Converted" ? false : true;
        }}
        onCellClick={(params, event) => {
          if (!event.ignore) {
            if (params.field !== "status" && params.field !== "actions") {
              navigate(`/leads/leadsPanel/${params.row.leadId}/leadDetails`);
            }
          }
        }}
        onStateChange={(state) => {
          setLeadCount(0);
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [leadId, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(leadId);
            }
          }
          // if (visibleItems.length > 0) {
          const res = leadsData.filter((item) =>
            visibleItems.includes(item.leadId)
          );
          // setFilter(res);
          const count = res?.length > 0 ? res?.length : leadCount;
          setLeadCount(count);

          // }
        }}
        // onStateChange={(state) => {
        //   const visibleRows = state.filter.visibleRowsLookup;
        //   let visibleItems = [];
        //   for (const [orderId, value] of Object.entries(visibleRows)) {
        //     if (value === true) {
        //       visibleItems.push(orderId);
        //     }
        //   }
        //   const res = totalSale.filter((item) =>
        //     visibleItems.includes(item.orderId)
        //   );
        //   const basicprice = res
        //     .map((item) => item.basicPrice)
        //     .reduce((a, b) => a + b, 0);
        //   const CGST = res.map((item) => item.cgst).reduce((a, b) => a + b, 0);
        //   const SGST = res.map((item) => item.sgst).reduce((a, b) => a + b, 0);
        //   const totalprice = res
        //     .map((item) => item.totalPrice)
        //     .reduce((a, b) => a + b, 0);
        //   setBasicPrice(basicprice > 0 ? basicprice : bp);
        //   setCGST(CGST > 0 ? CGST : cgst);
        //   setSGST(SGST > 0 ? SGST : sgst);
        //   setTotalPrice(totalprice > 0 ? totalprice : tp);
        //   getFilteredData([...res]);
        // }}

        // downloadXLS={() => downloadXLS(filter.length > 0 ? filter : leadsData)}
        loading={searchData?.status == "LOADING" ? true : loading}
        filterModel={filterModel && filterModel}
        // onFilterModelChange={(model) => setFilterModel(model)}
        components={{
          Footer: FooterSection,
          Toolbar: QuickSearchToolbar,
        }}
        getDate={getDate}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        filterDateRenge={filterDateRenge}
        getRowId={(row) => row.leadId}
      />
    </>
  );
};

export default React.memo(Leads);
