import { Box, Divider, Typography } from "@mui/material";
import React, { useState, useEffect, useMemo, useContext } from "react";
import "./JobCard.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { jobCardContext } from "../OrderDetails";

const TermsAndConditionCars = [
  {
    title: "Service Warranty",
    body: [
      "One month/1000kms unconditional warranty across Periodic Services like Car Service, AC Service, etc.",
    ],
  },
  {
    title: "Paint Warranty",
    body: [
      "Gativan offers a Paint Warranty of up to 2 years. This Warranty is also applicable in case of defective Detailing Service (Coating, PPF etc.).",
    ],
  },
  {
    title: "Fitments Warranty ",
    body: [
      "If the fitments such as Brakes, Horns, Lights and Windshields are found to be defective, we will replace them free of cost.",
    ],
  },
  {
    title: "Manufacturing Warranty ",
    body: [
      "We pass on the Manufacturer's Warranty on all Spares Parts to our Customers.",
    ],
  },
  {
    title: "Terms and Conditions",
    body: [
      "Any issues faced post service/spare part Installation done at any workshop outside Gativan will not be covered.",
      "Scratches, Dents or Paint damage caused post service will not be covered.",
      "Short-circuit or damage due to aftermarket electrical fittings will not be covered.",
      "Vehicles will be driven by Gativan Garage employees at the owner's risk and responsibility for testing and or inspection purposes.",
      " is not responsible for loss or damage to vehicle (s)/aggregate(s) or article (s) left in vehicle arising from fire, theft or any accident or any other cause beyond.",
    ],
  },
];
const TermsAndConditionBikes = [
  {
    title:
      "During first start of bike/cold start every morning start it with kick as your bike was stand still.",
  },
  {
    title:
      "Ride daily to keep the engine working and battery from draining out.",
  },
  {
    title: "Service warranty will be only valid for 7 days after delivery.",
  },
  {
    title:
      "Please change your engine oil after 2-3 months or within 2000-3000 kms whichever comes soon.",
  },
  {
    title: "Only the jobs mentioned in the job card will be done.",
  },
  {
    title:
      "Do take all the material from car before giving for servicing. No complaints regarding missing parts will be entertained.",
  },
];

export default function JobCard() {
  const [TermsAndCondition, setTermsAndCondition] = useState([]);
  const [Discount, setDiscount] = useState();
  const [Price, setPrice] = useState(0);
  const [finalTotal, setFinalTotal] = useState();

  const {
    OrderInfo,
    updateObj,
    setUpdateObj,
    selectedMaterials,
    selectedServises,
    saveSelectedServises,
    nonCatalogueSaveServices,
    nonCatalogueService,
  } = useContext(jobCardContext);

  useEffect(() => {
    setDiscount(updateObj.discount);
  }, [updateObj?.discount]);

   //  calculate total price

  const servicesTotalPice = selectedServises.total;
  const BackEndServiceTotalPrice = saveSelectedServises.total;
  const NonCatTotalPrice = nonCatalogueService.total;
  const BackEndNonCatTotalPrice = nonCatalogueSaveServices.total;
  const addMaterialTotalPrice = selectedMaterials.total;

  useMemo(() => {
    setPrice(
      BackEndNonCatTotalPrice +
        NonCatTotalPrice +
        servicesTotalPice +
        BackEndServiceTotalPrice +
        addMaterialTotalPrice
    );
  }, [
    BackEndNonCatTotalPrice,
    NonCatTotalPrice,
    servicesTotalPice,
    BackEndServiceTotalPrice,
    addMaterialTotalPrice,
  ]);

  useMemo(() => {
    setFinalTotal(Price - Discount);
  }, [Discount, Price]);

  // update non catalogue jobs
  useMemo(() => {
    setUpdateObj({
      ...updateObj,
      nonCatalogueJobs: nonCatalogueService.result,
    });
  }, [nonCatalogueService]);

  // update services
  useMemo(() => {
    setUpdateObj({
      ...updateObj,
      servicesList: selectedServises.result?.map((i) => {
        let serviceId = i.serviceId;
        return { serviceId };
      }),
    });
  }, [selectedServises]);

  // -- check vehicle type then after show terms and condition

  useEffect(() => {
    const vehicle = OrderInfo?.vehicle?.vehicleType?.vehicleType?.toLowerCase();
    if (vehicle === "car") {
      setTermsAndCondition(TermsAndConditionCars);
    } else {
      setTermsAndCondition(TermsAndConditionBikes);
    }
  }, [OrderInfo?.vehicle]);

  return (
    <Box className="jobCardContainer">
      <Box className="addedJobs">
        <Box className="addedJobs-header">
          <Typography className="added-heading">Jobs</Typography>
          <Box className="added-header-logo">
            <img
              src={process.env.PUBLIC_URL + "/Assets/logos/logo.png"}
              alt=""
            />
          </Box>
        </Box>

        <Box className="addedjobs-Services">
          <Box className="addedJobs-jobs">
            {saveSelectedServises &&
              saveSelectedServises?.result?.map((item, index) => {
                const {
                  service,
                  actualPrice,
                  serviceId,
                  detailsList,
                  activities,
                  displayPrice,
                } = item;

                return (
                  <Box key={index}>
                    <Typography className="packageName">{service}</Typography>
                    {activities &&
                      activities?.map((item, index) => {
                        const { id, activity } = item;
                        return (
                          <Box
                            key={id}
                            mt={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography className="activites">
                              {index + 1} {activity}
                            </Typography>
                            <CheckCircleIcon className="checkIcon" />
                          </Box>
                        );
                      })}
                    <Box className="subPrice">
                      <Typography className="subTotal">Subtotal</Typography>
                      <Typography className="subTotal-price">
                        <CurrencyRupeeIcon className="subTotal-rupeeIcon" />
                        {displayPrice}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            {selectedServises &&
              selectedServises?.result?.map((item, index) => {
                const {
                  service,
                  actualPrice,
                  serviceId,
                  detailsList,
                  activities,
                  displayPrice,
                } = item;
                return (
                  <Box key={index}>
                    <Typography className="packageName">{service}</Typography>
                    {activities &&
                      activities?.map((item, index) => {
                        const { id, activity } = item;
                        return (
                          <Box
                            key={id}
                            mt={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography className="activites">
                              {index + 1} {activity}
                            </Typography>
                            <CheckCircleIcon className="checkIcon" />
                          </Box>
                        );
                      })}
                    <Box className="subPrice">
                      <Typography className="subTotal">Subtotal</Typography>
                      <Typography className="subTotal-price">
                        <CurrencyRupeeIcon className="subTotal-rupeeIcon" />
                        {/* {actualPrice} */}
                        {displayPrice}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          {/* Non Catalogues Jobs Show here */}
          <Box className="addedJobs-jobs">
            {nonCatalogueSaveServices &&
              nonCatalogueSaveServices?.result?.map((item, index) => {
                const { id, name, quantity, price, service_id } = item;
                return (
                  <Box key={index}>
                    <Box>
                      <Typography className="packageName">
                        {name}{" "}
                        <span style={{ color: "black", fontWeight: "200" }}>
                          ({quantity})
                        </span>{" "}
                      </Typography>
                    </Box>
                    <Box className="subPrice">
                      <Typography className="subTotal">Subtotal</Typography>
                      <Typography className="subTotal-price">
                        <CurrencyRupeeIcon className="subTotal-rupeeIcon" />
                        {price}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}

            {nonCatalogueService &&
              nonCatalogueService?.result?.map((item, index) => {
                const { id, name, quantity, price, service_id, activity } =
                  item;
                return (
                  <Box key={index}>
                    <Typography className="packageName">
                      {name}{" "}
                      <span style={{ color: "black", fontWeight: "600" }}>
                        ( {quantity} )
                      </span>{" "}
                    </Typography>

                    <Box className="subPrice">
                      <Typography className="subTotal">Subtotal</Typography>
                      <Typography className="subTotal-price">
                        <CurrencyRupeeIcon className="subTotal-rupeeIcon" />
                        {price}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          {/* Add material Jobs Show here */}
          <Box className="addedJobs-jobs">
            {selectedMaterials &&
              selectedMaterials?.result?.map((item, index) => {
                const { name, quantity, price } = item;
                return (
                  <Box key={index}>
                    <Typography className="packageName">
                      {name}
                      <span style={{ color: "black", fontWeight: "200" }}>
                        ({quantity})
                      </span>
                    </Typography>
                    <Box className="subPrice">
                      <Typography className="subTotal">Subtotal</Typography>
                      <Typography className="subTotal-price">
                        <CurrencyRupeeIcon className="subTotal-rupeeIcon" />
                        {price}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>

        <Box className="jobCart-totalPrice">
          <Box className="service-Total">
            <Typography className="jobCart-total">Total :</Typography>
            <Typography className="jobCart-totalPrice-price">
              <CurrencyRupeeIcon className="totalPrice-rupeeIcon" />
              {Price}
            </Typography>
          </Box>

          {Discount > 0 && (
            <Box>
              <Box className="discount">
                <Typography className="subTotal">Discount</Typography>
                <Typography className="subTotal-price">
                  {/* - <CurrencyRupeeIcon className="discount-rupeeIcon" /> */}
                  <span className="subStraction">-</span> {Discount}
                </Typography>
              </Box>
              <Divider className="total-divider" />
              <Box className="finalPrice-container">
                <Typography className="finaltotal-title">
                  Final Total :{" "}
                </Typography>
                <Typography className="jobCart-totalPrice-price">
                  <CurrencyRupeeIcon className="final-rupeeIcon" />
                  {finalTotal}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="termsAndConditionContainer">
        <Typography className="termsAndCondition-header">
          Terms & Conditions
        </Typography>
        <Box padding="2%">
          <ul className="termsAndCondition-conditions">
            {TermsAndCondition?.map((item, ind) => {
              const { title, body } = item;
              return (
                <Box key={ind}>
                  <li className="termsAndCondition-title">{title}</li>
                  {body?.map((list) => (
                    <li className="termsAndCondition-body">{list}</li>
                  ))}
                </Box>
              );
            })}
          </ul>
        </Box>
      </Box>
    </Box>
  );
}
