const user = {
  adminLogin: "",
  role: "",
};
export const AdminLogin = (state = user, action) => {
  switch (action.type) {
    case "AdminLogin":
      return (state = action.payload);

    case "AdminLogOut":
      return (state = action.payload);

    default:
      return state;
  }
};
