import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { getVehicleType } from "../../services/leadServices/LeadServices";
import { Grid } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  editGarage,
  addGarageBankAccountDetails,
  addGarageUpiAccount,
} from "../../services/garageServices/GarageServices";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import "./AddGarage.css";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "100%",
  },
  gridRow: {
    display: "flex",
    alignItems: "center",
  },
  garageTitle: {
    fontSize: "2rem",
  },
}));
const entityTypes = ["Sole proprietor", "Partnership Firm"];
const payoutBasedOn = ["Invoice Price", "Taxable Amount"];
const locations = [
  {
    city: "Pune",
    id: 1,
  },
  {
    city: "Banglore",
    id: 2,
  },
  {
    city: "Mumbai",
    id: 3,
  },
];

const PersonalInfo = ({ garageId, garageDetails, setGarageDetails }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  // GET CONTEXT COMMON DATA
  const { handleCloseGarage, handleAlertOpen, colors } =
    useContext(CommonContextAPI);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "gstn" || name == "pan") {
      setGarageDetails({ ...garageDetails, [name]: value?.toUpperCase() });
    } else if (name == "username") {
      setGarageDetails({ ...garageDetails, [name]: value });
    } else {
      setGarageDetails({
        ...garageDetails,
        [name]: value
          ?.split(" ")
          .map((ar) => ar.charAt(0).toUpperCase() + ar.slice(1))
          .join(" "),
      });
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name == "gstn" || name == "pan") {
  //     setPersonalDetails({ ...garageDetails, [name]: value?.toUpperCase() });
  //   } else if (name == "username") {
  //     setPersonalDetails({ ...garageDetails, [name]: value });
  //   } else {
  //     setPersonalDetails({
  //       ...garageDetails,
  //       [name]: value
  //         ?.split(" ")
  //         .map((ar) => ar.charAt(0).toUpperCase() + ar.slice(1))
  //         .join(" "),
  //     });
  //   }
  // };

  useEffect(() => {
    // setPersonalDetails(garageDetails);
  }, [, garageDetails]);

  const saveGarageDetails = async () => {
    const garageObject = {
      area: garageDetails?.area,
      city: garageDetails?.city,
      entityType: garageDetails?.entityType,
      garageName: garageDetails?.garageName,
      garageType: garageDetails?.garageType,
      garageId: garageDetails?.garageId,
      gstn: garageDetails?.gstn,
      landmark: garageDetails?.landmark,
      mobileNumber: garageDetails?.mobileNumber,
      pan: garageDetails?.pan,
      payoutPercentage: Number(garageDetails?.payoutPercentage),
      payoutBasedOn: garageDetails?.payoutBasedOn,
      pincode: garageDetails?.pincode,
      shopNumber: garageDetails?.shopNumber,
      tehsil: garageDetails?.shopNumber,
      district: garageDetails?.district,
      state: garageDetails?.state,
      country: garageDetails?.country,
    };

    // const garageObject = {
    //   area: garageDetails?.area,
    //   city: garageDetails?.city,
    //   entityType: garageDetails?.entityType,
    //   garageName: garageDetails?.garageName,
    //   garageType: garageDetails?.garageType,
    //   garageId: garageDetails?.garageId,
    //   gstn: garageDetails?.gstn,
    //   landmark: garageDetails?.landmark,
    //   mobileNumber: garageDetails?.mobileNumber,
    //   pan: garageDetails?.pan,
    //   payoutPercentage: Number(garageDetails?.payoutPercentage),
    //   payoutBasedOn: garageDetails?.payoutBasedOn,
    //   pincode: garageDetails?.pincode,
    //   shopNumber: garageDetails?.shopNumber,
    //   tehsil: garageDetails?.shopNumber,
    //   district: garageDetails?.district,
    //   state: garageDetails?.state,
    //   country: garageDetails?.country,
    // };
    const localData = JSON.parse(localStorage.getItem("loginDetails"));

    const config = {
      headers: {
        Authorization: `Bearer ${localData.jwtToken}`,
      },
    };
    try {
      const response = await editGarage(garageObject, config);
      const data = await response.data;
      handleAlertOpen(data?.message, colors?.success);
      navigate("/garageDatabase");
    } catch (error) {
      handleAlertOpen(error?.response?.data?.message, colors?.error);
      console.log("error", error);
    }
  };

  const discardDetails = () => {
    setGarageDetails({
      ...garageDetails,
      area: "",
      city: "",
      garageName: "",
      // garageId: "",
      gstn: "",
      landmark: "",
      // mobileNumber: "",
      pan: "",
      pincode: "",
      shopNumber: "",
      tehsil: "",
      district: "",
      state: "",
      country: "",
    });
    // setPersonalDetails(garageDetails);
  };

  return (
    <Box className={classes.container}>
      <Grid container spacing={1} className={classes.gridRow}>
        <Grid item xs={4}>
          <Typography className="lables">Garage Name</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            size="small"
            fullWidth
            margin="dense"
            variant="outlined"
            label="Garage Name"
            id="garageName"
            name="garageName"
            onChange={handleChange}
            value={garageDetails?.garageName}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Contact Number</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            size="small"
            autoComplete="off"
            fullWidth
            margin="dense"
            variant="outlined"
            label="+91  Enter 10 digit No."
            id="mobileNumber"
            inputProps={{ maxLength: 10 }}
            name="mobileNumber"
            onChange={handleChange}
            value={garageDetails?.mobileNumber}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">GSTN</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            size="small"
            fullWidth
            margin="dense"
            variant="outlined"
            label="GSTN"
            id="gstn"
            name="gstn"
            onChange={handleChange}
            value={garageDetails?.gstn}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">PAN</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            size="small"
            fullWidth
            margin="dense"
            variant="outlined"
            label="PAN"
            id="pan"
            name="pan"
            onChange={handleChange}
            value={garageDetails?.pan}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.gridRow} spacing={1}>
        <Grid item xs={4}>
          <Typography className="lables">Address</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="Shop Number"
                id="shopNumber"
                name="shopNumber"
                onChange={handleChange}
                value={garageDetails?.shopNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="Area"
                id="area"
                name="area"
                onChange={handleChange}
                value={garageDetails?.area}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ margin: "0.6rem 0" }}>
              <select
                name="city"
                onChange={handleChange}
                className="selectDropdown"
                label="City"
                style={{ marginTop: "0.6rem" }}
              >
                <option value={garageDetails && garageDetails?.city}>
                  {garageDetails && garageDetails?.city}
                </option>
                {locations &&
                  locations.map((location, index) => {
                    return (
                      <option value={location.city} key={location.id}>
                        {location.city}
                      </option>
                    );
                  })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="Pin Code"
                id="pincode"
                name="pincode"
                onChange={handleChange}
                value={garageDetails?.pincode}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                margin="dense"
                variant="outlined"
                label="Land Mark"
                id="landmark"
                name="landmark"
                onChange={handleChange}
                value={garageDetails?.landmark}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4} sm={4}></Grid>
        <Grid item xs={8} sm={6}>
          <div className="buttonGroup">
            <Button
              variant="contained"
              size="small"
              className="buttons"
              onClick={discardDetails}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              size="small"
              className="buttons"
              style={{ marginLeft: "1rem" }}
              onClick={saveGarageDetails}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalInfo;
