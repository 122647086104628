import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  getOrdersStatus,
  getOrders,
  getGarageOrders,
  searchOrders,
} from "../../services/orderServices/OrderServices";
import { getOrdersByGarageId } from "../../services/garageServices/GarageServices";
import {
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import clsx from "clsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "../../components/tables/Table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Box, Button } from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import httpCommon from "../../http-common";
import { CommonContextAPI } from "../../App";
import { SearchContext } from "../../components/Route";
import axios from "axios";
import { GetSearchedOrders } from "../../action";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);
  const searchData = useSelector((state) => state.OrdersReducer);
  const { clearAppliedFilters } = commonData;

  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(GetSearchedOrders(value));
    setSearchQuery(value);
  };

  useEffect(() => {
    setSearchQuery(searchData?.searchQuery);
  }, [searchData]);

  const clear = () => {
    clearAppliedFilters();
    dispatch(GetSearchedOrders(""));
    setSearchQuery("");
  };

  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter
        className={classes.search}
        // quickFilterParser={(searchInput) => handleChange(searchInput)}
        quickFilterFormatter={(quickFilterValues) =>
          quickFilterValues.join(", ")
        }
        onChange={handleChange}
        value={searchQuery}
        debounceMs={200}
      />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />

        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          // onClick={clearAppliedFilters}
          onClick={clear}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const Orders = () => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [local, setLocal] = useState([]);
  const navigate = useNavigate();
  let [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [filteredCount, setFilteredCount] = useState(0);

  // const [revenue, setRevenue] = useState(0);
  // const [filteredRevenue, setFilteredRevenue] = useState(0);

  const [generalOrders, setGeneralOrders] = useState([]);
  const [insuranceOrders, setInsuranceOrders] = useState([]);

  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [tatList, setTatList] = useState([]);
  const [tempTatList, setTempTatList] = useState([]);
  const [tatAverage, setTatAverage] = useState(null);

  const commonURL = httpCommon.defaults.baseURL;

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const searchData = useSelector((state) => state.OrdersReducer);

  const localData = JSON.parse(localStorage.getItem("loginDetails"));

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    setFilterModel,
    clearSelectedDate,
    getCachedData,
    storeDataInCache,
    handleAlertOpen,
    colors,
    orderType,
    setOrderType,
    currentOrders,
    setCurrentOrders,
    defaultDate,
  } = commonData;

  const countTat = (startDate, endDate) => {
    var dayObj = {};

    var datetime1 = moment(startDate);
    var datetime2 = moment(endDate);
    dayObj.hours = datetime2.diff(datetime1, "hours");
    dayObj.minutes = datetime2.diff(datetime1, "minutes") % 60;
    return dayObj;
  };

  const current = localStorage.getItem("currentOrders");
  useEffect(() => {
    if (!garageId) {
      if (current == "general") {
        setCurrentOrders("General");
        navigate("/orders/general");
      }

      if (current == "insurance") {
        setCurrentOrders("Insurance");
        navigate("/orders/insurance");
      }
    }
  }, [, current]);

  const ordersObject = (order) => {
    let diff = countTat(
      `${order?.order?.serviceBookingDate} ${order?.order?.serviceBookingTime}`,
      `${order?.order?.completionDate} ${order?.order?.completionTime}`
    );
    const tempNCJ = order?.nonCatalogueJobs?.toString();
    const tempService = order?.services?.toString();
    const orderObj = {
      customerName: `${
        order?.order?.userFirstName != null ? order?.order?.userFirstName : ""
      } ${
        order?.order?.userLastName != null ? order?.order?.userLastName : ""
      }`,
      bookingMode: order?.order?.mode,
      brand: order?.order?.brand,
      modelName: order?.order?.model,
      variantName: order?.order?.variant,
      // service: order?.services?.map((service) => service),
      service: tempService,
      // nonCatalogueJobs: order?.nonCatalogueJobs?.map((job) => job),
      nonCatalogueJobs: tempNCJ,
      deadReason: order?.deadReason,
      ksMaterial: "",
      serviceAdvisor: `${
        order?.order?.advisorFirstName ? order?.order?.advisorFirstName : ""
      } ${order?.order?.advisorLastName ? order?.order?.advisorLastName : ""}`,

      serviceBookingDate: order?.order?.serviceBookingDate,
      serviceBookingTime: order?.order?.serviceBookingTime,
      completionDate: order?.order?.completionDate,
      discount: order?.order?.discount ? order?.order?.discount : 0,
      totalPrice: order?.order?.totalPrice,
      sale:
        order?.order?.totalPrice +
        (order?.order?.discount ? order?.order?.discount : 0),
      orderStatus: order?.order?.status,
      vehicleType: order?.order?.vehicleType,
      garage: order?.order?.garageName,
      etd: order?.order?.etd,
      tat: order?.order?.tat,
      vehicleNumber: order?.order?.vehicleNumber,
      mobileNumber: order?.order?.userMobileNumber,
      address: order?.order?.area,
      location: order?.order?.city,
      slot: `${order?.order?.preferredServiceDate} ${order?.order?.preferredServiceTime}`,
      customerRemark: order?.order?.customerRemark,
      saRemark: order?.order?.saRemark,
      orderId: order?.order?.orderId,
      paymentId: order?.order?.paymentId,
      codeOfInsurance: order?.codeOfInsurance?.code,
    };

    return orderObj;
  };

  const ordersObject2 = (order) => {
    const orderObj = {
      customerName: `${
        order?.user?.first_name != null ? order?.user?.first_name : ""
      } ${order?.user?.last_name != null ? order?.user?.last_name : ""}`,
      bookingMode: order?.bookingMode?.mode,
      brand: order?.vehicle?.brand?.brand,
      modelName: order?.vehicle?.model?.model,
      variantName: order?.vehicle?.variant?.variantName,
      service: order?.services?.map((service) => service?.service),
      nonCatalogueJobs: order?.nonCatalogueJobs?.map((job) => job?.name),
      deadReason: order?.deadReason,
      ksMaterial: "",
      serviceAdvisor: `${
        order?.serviceAdvisor?.first_name
          ? order?.serviceAdvisor?.first_name
          : ""
      } ${
        order?.serviceAdvisor?.last_name ? order?.serviceAdvisor?.last_name : ""
      }`,
      serviceBookingDate: order?.serviceBookingDate,
      serviceBookingTime: order?.serviceBookingTime,
      completionDate: order?.completionDate,
      discount: order?.discount ? order?.discount : 0,
      totalPrice: order?.totalPrice,
      sale: order?.totalPrice + (order?.discount ? order?.discount : 0),
      orderStatus: order?.orderStatus?.status,
      vehicleType: order?.vehicle?.vehicleType?.vehicleType,
      garage: order?.garage?.garageName,
      etd: order?.etd,
      tat: order?.tat,

      vehicleNumber: order?.vehicle?.vehicleNumber,
      mobileNumber: order?.user?.mobile_number,
      address: `${
        order?.address?.houseNumber ? order?.address?.houseNumber : ""
      } ${order?.address?.area ? order?.address?.area : ""} ${
        order?.address?.city ? order?.address?.city : ""
      } ${order?.address?.pincode ? order?.address?.pincode : ""} ${
        order?.address?.landmark ? order?.address?.landmark : ""
      }`,
      location: order?.address?.city,
      slot: `${
        order?.preferredServiceDate ? order?.preferredServiceDate : ""
      } ${order?.preferredServiceTime ? order?.preferredServiceTime : ""}`,
      customerRemark: order?.customerRemark,
      saRemark: order?.saRemark,
      orderId: order?.orderId,
      paymentId: order?.paymentId,
      codeOfInsurance: order?.codeOfInsurance?.code,
    };

    return orderObj;
  };

  const parseNum = (str) => {
    return str?.replace(/[^.\d]/g, "");
  };

  const formatSeconds = (totalmins) => {
    if (Math.sign(totalmins) != -1) {
      var mins = totalmins % 60;
      var hours = Math.floor(totalmins / 60);
      var days = Math.floor(hours / 24);
      var hourss = hours % 24;
      return `${days}d ${hourss}h ${Math.round(mins)}m`;
    } else {
      var absTotal = Math.abs(totalmins);
      var mins = absTotal % 60;
      var hours = Math.floor(absTotal / 60);
      var days = Math.floor(hours / 24);
      var hourss = hours % 24;
      return `${days}d ${hourss}h ${Math.round(mins)}m`;
    }
  };

  useEffect(() => {
    setTatAverage(null);
    let total = {
      days: Number(0),
      hours: Number(0),
      minutes: Number(0),
    };

    tempTatList?.reduce((acc, tat) => {
      if (tat != undefined) {
        if (
          Number(parseNum(tat[0])) != NaN &&
          Number(parseNum(tat[1])) != NaN &&
          Number(parseNum(tat[2]))
        ) {
          total.days +=
            Number(parseNum(tat[0])) != NaN ? Number(parseNum(tat[0])) : 0;
          total.hours +=
            Number(parseNum(tat[1])) != NaN ? Number(parseNum(tat[1])) : 0;
          total.minutes +=
            Number(parseNum(tat[2])) != NaN ? Number(parseNum(tat[2])) : 0;
        }
      }
    }, {});
    const daysHours = total?.days * 24;
    const daysMinutes = daysHours * 60;
    const hoursMinutes = total?.hours * 60;
    const minutes = total?.minutes;

    const totalMinutes =
      Number(daysMinutes) + Number(hoursMinutes) + Number(minutes);

    const avg = formatSeconds(totalMinutes / tempTatList?.length);
    setTatAverage(avg);
  }, [tempTatList]);

  const [garageId, setGarageId] = useState("");

  let [searchParams] = useSearchParams();
  useMemo(() => setGarageId(searchParams.get("garageId")), [searchParams]);

  const garageOrdersById = async () => {
    setOrders([]);
    setLocal([]);
    const config = {
      headers: {
        Authorization: `Bearer ${localData?.jwtToken}`,
      },
      params: { all: true },
    };
    try {
      const response = await getOrdersByGarageId(garageId, config);
      const data = await response.data;
      setOrders(data?.result);
      setLoading(false);
    } catch (error) {
      handleAlertOpen(error?.message, colors.error);
    }
  };

  useEffect(() => {
    garageId && setLocal([]);
    garageId && setTatList([]);

    searchData?.data?.length > 0 || searchData?.isSearching
      ? searchData?.data?.map((order) => {
          const orderObj = ordersObject2(order);

          if (orderObj?.orderId?.length >= 15) {
            garageId &&
              setTatList((prev) => [...prev, orderObj?.tat?.split(" ")]);
            setLocal((prev) => [
              ...prev,
              {
                ...orderObj,
                sortByCreatedDateTime: new Date(
                  `${orderObj?.serviceBookingDate} ${orderObj?.serviceBookingTime}`
                ),
              },
            ]);
          }
        })
      : orders?.map((order) => {
          let diff = countTat(
            `${order?.order?.serviceBookingDate} ${order?.order?.serviceBookingTime}`,
            `${order?.order?.completionDate} ${order?.order?.completionTime}`
          );

          const orderObj = {
            customerName: `${
              order?.userFirstName != null ? order?.userFirstName : ""
            } ${order?.userLastName != null ? order?.userLastName : ""}`,
            bookingMode: order?.bookingMode?.mode,
            brand: order?.vehicle?.brand?.brand,
            modelName: order?.vehicle?.model?.model,
            variantName: order?.vehicle?.variant?.variantName,
            service: order?.services?.map((service) => service?.service),
            nonCatalogueJobs: order?.nonCatalogueJobs?.map((job) => job?.name),
            deadReason: order?.deadReason,
            ksMaterial: "",
            serviceAdvisor: `${
              order?.advisorFirstName ? order?.advisorFirstName : ""
            } ${order?.advisorLastName ? order?.advisorLastName : ""}`,
            serviceBookingDate: order?.serviceBookingDate,
            serviceBookingTime: order?.serviceBookingTime,
            completionDate: order?.completionDate,
            discount: order?.discount ? order?.discount : 0,
            totalPrice: order?.totalPrice,
            sale: order?.totalPrice + (order?.discount ? order?.discount : 0),
            orderStatus: order?.orderStatus?.status,
            vehicleType: order?.vehicle?.vehicleType?.vehicleType,
            garage: order?.garageName,
            etd: order?.etd,
            tat: order?.tat,

            vehicleNumber: order?.vehicle?.vehicleNumber,
            mobileNumber: order?.userMobileNumber,
            address: `${
              order?.address?.houseNumber ? order?.address?.houseNumber : ""
            } ${order?.address?.area ? order?.address?.area : ""} ${
              order?.address?.city ? order?.address?.city : ""
            } ${order?.address?.pincode ? order?.address?.pincode : ""} ${
              order?.address?.landmark ? order?.address?.landmark : ""
            }`,
            location: order?.address?.city,
            slot: `${
              order?.preferredServiceDate ? order?.preferredServiceDate : ""
            } ${
              order?.preferredServiceTime ? order?.preferredServiceTime : ""
            }`,
            customerRemark: order?.customerRemark,
            saRemark: order?.saRemark,
            orderId: order?.orderId,
            paymentId: order?.paymentId,
            codeOfInsurance: order?.codeOfInsurance?.code,
          };

          if (orderObj?.orderId?.length >= 15) {
            garageId &&
              setTatList((prev) => [...prev, orderObj?.tat?.split(" ")]);
            setLocal((prev) => [
              ...prev,
              {
                ...orderObj,
                sortByCreatedDateTime: new Date(
                  `${orderObj?.serviceBookingDate} ${orderObj?.serviceBookingTime}`
                ),
              },
            ]);
          }
        });
  }, [orders, filteredDate, searchData?.data]);

  useEffect(() => {
    !garageId && setLocal([]);
    !garageId && setTatList([]);
    {
      searchData?.data?.length > 0 || searchData?.isSearching
        ? searchData?.data.map((order, index) => {
            const orderObj = ordersObject2(order);
            if (orderObj?.orderId?.length >= 15) {
              !garageId &&
                setTatList((prev) => [...prev, orderObj?.tat?.split(" ")]);
              setLocal((prev) => [
                ...prev,
                {
                  ...orderObj,
                  sortByCreatedDateTime: new Date(
                    `${orderObj?.serviceBookingDate} ${orderObj?.serviceBookingTime}`
                  ),
                },
              ]);
            }
          })
        : orders &&
          orders.map((order, index) => {
            const orderObj = ordersObject(order);
            if (orderObj?.orderId?.length >= 15) {
              !garageId &&
                setTatList((prev) => [...prev, orderObj?.tat?.split(" ")]);
              setLocal((prev) => [
                ...prev,
                {
                  ...orderObj,
                  sortByCreatedDateTime: new Date(
                    `${orderObj?.serviceBookingDate} ${orderObj?.serviceBookingTime}`
                  ),
                },
              ]);
            }
          });
    }
  }, [orders, filteredDate, searchData?.data]);

  // Cache data using tanstack.

  const [load, setLoad] = useState(true);

  setTimeout(() => {
    setLoad(false);
  }, 2000);

  useEffect(() => {
    setLoading(true);
  }, [orderType]);

  const getOrdersList = async (orderType) => {
    let cacheName = null;
    let url = null;

    if (orderType === 1) {
      cacheName = "orders/generalOrders";
      url = `${commonURL}/orders?all=true&orderType=1`;

      let cachedData = await getCachedData(cacheName, url);
      if (cachedData) {
        // setOrders(cachedData);
        setGeneralOrders(cachedData);

        setLoading(false);
        // storeDataInCache(cacheName, url, result.result);
      }
    }

    if (orderType === 2) {
      cacheName = "orders/insuranceOrders";
      url = `${commonURL}/orders?all=true&orderType=2`;

      let cachedData = await getCachedData(cacheName, url);
      if (cachedData) {
        // setOrders(cachedData);
        setInsuranceOrders(cachedData);
        setLoading(false);
        // storeDataInCache(cacheName, url, result.result);
      }
    }

    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localData?.jwtToken}`,
        },
        params: {
          from: startDate,
          to: endDate,
          orderType: orderType,
        },
      };

      if (orderType === 1) {
        const response = await getOrders(config);
        const result = await response.data;
        // setOrders(result.result);
        setGeneralOrders(result.result);
        storeDataInCache(cacheName, url, result.result);
        setLoading(false);
      }

      if (orderType === 2) {
        const response = await getOrders(config);
        const result = await response.data;
        // setOrders(result.result);
        setInsuranceOrders(result.result);
        storeDataInCache(cacheName, url, result.result);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    if (orderType === 1) {
      setOrders(generalOrders);
    }
    if (orderType === 2) {
      setOrders(insuranceOrders);
    }
  }, [generalOrders, insuranceOrders]);

  useEffect(() => {
    if (filteredDate || defaultDate) {
      if (!garageId) {
        setOrders([]);
        setLocal([]);

        if (!searchData?.isSearching) {
          if (filteredDate || defaultDate) {
            getOrdersList(orderType);
            setLoading(true);
          }
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 100);
        }
        getAllOrderStatus();
      } else {
        garageId && !searchData?.isSearching && garageOrdersById();
      }
    }
    console.log("orderType", orderType);
  }, [garageId, filteredDate, defaultDate, orderType, searchData?.isSearching]);

  // get Lead Status
  const getAllOrderStatus = async () => {
    try {
      const response = await getOrdersStatus();
      const result = await response.data;
      setOrderStatus(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    orders?.map((order) => {
      let date = `${order?.order?.serviceBookingDate} ${order?.order?.serviceBookingTime}`;
    });
  }, [orders]);

  useEffect(() => {
    // setLocal(orders && orders);
    // getAllOrderStatus();
    setOrderType(1);
    // console.log("orderType", orderType);
    let current = localStorage.getItem("currentOrders");

    if (current == "") {
      localStorage.setItem("currentOrders", "general");
    }

    if (current == "insurance") {
      setOrderType(2);
    }
  }, []);

  const [columns, setColumns] = useState([]);

  let allColumns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "bookingMode",
      headerName: "Booking Mode",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "brand",
      headerName: "Vehicle Name",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "modelName",
      headerName: "Vehicle Model",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "variantName",
      headerName: "Vehicle Variant",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const value = params.formattedValue;
        const valueLoCa = params.formattedValue
          ?.replaceAll(" ", "")
          .toLowerCase();

        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 15%",
              width: "100%",
              height: "80%",
              color: valueLoCa === "approvalpending" ? "#000" : "white",
              backgroundColor:
                valueLoCa === "notstarted"
                  ? "#D0342C"
                  : valueLoCa === "jobcard"
                  ? "#122164"
                  : valueLoCa === "inspection"
                  ? "#FEB100"
                  : valueLoCa === "approved"
                  ? "#FEB100"
                  : valueLoCa === "delivered"
                  ? "#7B8BD4"
                  : valueLoCa === "complete"
                  ? "#51AE3A"
                  : valueLoCa === "invoice"
                  ? "#006B3E"
                  : valueLoCa === "lost"
                  ? "#000000"
                  : valueLoCa === "pickupcompleted"
                  ? "#DF764F"
                  : valueLoCa === "atworkshop"
                  ? "#F76833"
                  : valueLoCa === "estimatenotsent"
                  ? "#33F4F7"
                  : valueLoCa === "approvalpending"
                  ? "#E3E7FA"
                  : valueLoCa === "workinprogress"
                  ? "#246ED3"
                  : valueLoCa === "readyfordelivery"
                  ? "#7B8BD4"
                  : valueLoCa === "estimatesent"
                  ? "#33C2F7"
                  : valueLoCa === "unanswered"
                  ? "#9C9596"
                  : valueLoCa === "followup"
                  ? "#EA4630"
                  : "",
            }}
          >
            <span>{value}</span>
          </div>
        );
      },

      cellClassName: (params) =>
        clsx(
          "super-app",
          orderStatus.map((order) => {
            if (
              params.value?.replace(" ", "").toLowerCase() ===
              order?.status?.replace(" ", "").toLowerCase()
            ) {
              return params.value.replace(" ", "").toLowerCase();
            }
          })
        ),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "serviceAdvisor",
      headerName: "Service Advisor",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "serviceBookingDate",
      headerName: "Created Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "serviceBookingTime",
      headerName: "Created Time",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "sortByCreatedDateTime",
      headerName: "Filter By Order Id",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      filterable: true,
      flex: 1,
    },
    {
      field: "completionDate",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "discount",
      headerName: "Discount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "sale",
      headerName: "Sale",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "service",
      headerName: "Service",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      editable: true,
      flex: 1,
    },
    {
      field: "nonCatalogueJobs",
      headerName: "Non-Catalogue Jobs",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "ksMaterial",
      headerName: "KS Material",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "garage",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "etd",
      headerName: "ETD",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tat",
      headerName: "TAT",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "slot",
      headerName: "Slot",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "customerRemark",
      headerName: "Customer Remarks",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "saRemark",
      headerName: "Service Advisor Remarks",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "deadReason",
      headerName: "Dead Reason",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "deadDescription",
      headerName: "Dead Description",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "paymentId",
      headerName: "Payment Id",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "history",
      headerName: "History",
      headerClassName: "super-app-theme--header",
      minWidth: 160,
      renderCell: (params) => {
        return (
          <div
            className=""
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              padding: "0% 15%",
              width: "100%",
              height: "80%",
              color: "white",
              backgroundColor: "#7B8BD4",
            }}
          >
            <span>View Past Orders</span>
          </div>
        );
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    setColumns(allColumns);
  }, []);

  const [filter, setFilter] = useState([]);

  const getFilteredData = (data) => {
    data?.map((item) => {
      setTatList((prev) => [...prev, item?.tat?.split(" ")]);
    });
    setFilter([]);
    setFilter(data);
  };

  useMemo(() => {
    setTotal(0);
    setCount(0);

    local.map((item, i) => {
      setTotal((prev) => prev + item?.totalPrice);
      // if (item?.orderStatus?.toLowerCase() == "complete") {
      //   setRevenue((prev) => prev + item?.sale);
      // }
    });
    setCount(local?.length);
  }, [local]);

  function FooterSection() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Total Price :{" "}
              <span className="grid-table-footer-right-value">
                {filteredTotal ? filteredTotal?.toFixed(2) : total?.toFixed(2)}
                {/* {total && total.toFixed(2)} */}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Count :{" "}
              <span className="grid-table-footer-right-value">
                {filteredCount ? filteredCount : count}
              </span>
            </h5>
          </div>
          {tatAverage
            ?.split(" ")[0]
            .slice(0, tatAverage.split(" ")[0].length - 1) != "NaN" && (
            <div style={{ marginLeft: "10px" }}>
              <h5 className="grid-table-footer-right-label">
                TAT Average :{" "}
                <span className="grid-table-footer-right-value">
                  {tatAverage && tatAverage}
                </span>
              </h5>
            </div>
          )}
        </div>
        <PaginationTable />
      </div>
    );
  }

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("serviceBookingDate");
  }, [filteredDate && filteredDate]);

  const download = (list) => {
    const downloadData = list.map((data) => {
      return {
        ...data,
        // nonCatalogueJobs: data?.nonCatalogueJobs?.join(","),
        service: data?.service?.join(","),
      };
    });

    return downloadData;
  };

  return (
    <>
      <Table
        customStyle={DataGridStyle}
        heading="Orders"
        initialState={{
          sorting: {
            sortModel: [{ field: "sortByCreatedDateTime", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              sortByCreatedDateTime: false,
            },
          },
        }}
        classes={{
          sortIcon: classes.sortIcon,
          menuIconButton: classes.menuIcon,
          iconSeparator: classes.iconSeparator,
        }}
        rows={local && local}
        // rows={!isLoading && data?.data?.result}
        columns={columns}
        getRowHeight={() => "auto"}
        pageSize={50}
        rowsPerPageOptions={[100]}
        isCellEditable={(params) => {
          return params.value == "Converted" ? false : true;
        }}
        onCellClick={(params) => {
          if (params.field !== "service") {
            // navigate("/orders/orderdetails", { state:  params.row.orderId });
            navigate(
              `/orders/orderdetails/${currentOrders?.toLowerCase()}/${
                params.row.orderId
              }/estimate`
            );
          }
          if (params.field == "history") {
            navigate(`/orders/history/${params.row?.mobileNumber}`);
          }
        }}
        onStateChange={(state) => {
          // setDemo([]);
          // setFilteredTotal(0);
          let data = [];
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [orderId, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(orderId);
            }
          }

          const res = local?.filter((item) =>
            visibleItems.includes(item.orderId)
          );

          let temp = res.filter(
            (item, index, self) =>
              self.findIndex((s) => s.orderId == item.orderId) == index
          );

          temp?.map((item) => {
            data.push(item?.tat?.split(" "));
          });

          let totalprice = res
            ?.map((item) => item.totalPrice)
            ?.reduce((a, b) => a + b, 0);

          let rev = res
            ?.map(
              (item) =>
                item?.orderStatus?.toLowerCase() == "complete" && item.sale
            )
            ?.reduce((a, b) => a + b, 0);

          // setFilteredTotal(totalprice > 0 ? totalprice : total);
          setFilteredTotal(totalprice > 0 && totalprice);
          let len = res?.length;
          setFilteredCount(len > 0 && len);
          // setFilteredRevenue(rev > 0 && rev);
          // setTempTatList(totalprice > 0 ? data : tatList);
          // getFilteredData([...res]);
          // setFilter(res.length > 0 && res);
        }}
        onSelectionModelChange={(newSelection) => {
          // setSelection(newSelection);
          // console.log("selection", newSelection);
        }}
        filterModel={filterModel && filterModel}
        //  onFilterModelChange={(model) => setFilterModel(model)}
        downloadXLS={() =>
          downloadXLS(filter.length > 0 ? download(filter) : download(local))
        }
        // appliedFilters={appliedFilters && appliedFilters}
        // components={{ Toolbar: QuickSearchToolbar }}
        components={{
          Footer: FooterSection,
          Toolbar: QuickSearchToolbar,
        }}
        // components={{ Footer: FooterSection, Toolbar: CustomToolbar }}
        // total={totals}
        loading={searchData?.status == "LOADING" ? true : loading}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.orderId}
        // onPageChange={() => console.log()}
        sortModel={[{ field: "sortByCreatedDateTime", sort: "desc" }]}
      />
    </>
  );
};

export default Orders;
