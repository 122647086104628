import axios from "axios";
import { searchLeads } from "../services/leadServices/LeadServices";
import { searchOrders } from "../services/orderServices/OrderServices";

const LEADS_ACTION_TYPE = "SEARCH_LEADS";
const ORDERS_ACTION_TYPE = "SEARCH_ORDERS";

export const AdminLogin = (payload) => {
  return {
    type: "AdminLogin",
    payload: payload,
  };
};

export const AdminLogout = (payload) => {
  return {
    type: "AdminLogOut",
    payload: payload,
  };
};

export const DateRangeFilter = (payload) => {
  return {
    type: "DateRange",
    payload: payload,
  };
};

export const SelectInspection = (payload) => {
  return {
    type: "SelectInspection",
    payload: payload,
  };
};

export const GetAllOrders = (payload) => {
  return {
    type: "getAllOrders",
    payload: payload,
  };
};

export const GetAppConstants = (payload) => {
  return {
    type: "getAppConstants",
    payload: payload,
  };
};

export const CommonAppData = (payload) => {
  return {
    type: "getCommonData",
    payload: payload,
  };
};

export const StoreTableComponentData = (payload) => {
  return {
    type: "tableComponentData",
    payload: payload,
  };
};

export const ViewProfile = (payload) => {
  return {
    type: "ViewMyProfile",
    payload: payload,
  };
};

export const HideProfile = (payload) => {
  return {
    type: "HideMyProfile",
    payload: payload,
  };
};

export const StoreLeadCardServices = (payload) => {
  return {
    type: "LEAD_CARD",
    payload: payload,
  };
};

let cancelLeadsTokenSource = axios.CancelToken.source();

export const GetSearchedLeads = (query) => {
  return async (dispatch) => {
    if (!query) {
      dispatch({
        type: LEADS_ACTION_TYPE,
        payload: { data: [], isSearching: false, searchQuery: "", status: "" },
      });
      return;
    }

    dispatch({
      type: LEADS_ACTION_TYPE,
      payload: {
        data: [],
        isSearching: true,
        searchQuery: query,
        status: "LOADING",
      },
    });

    try {
      cancelLeadsTokenSource.cancel("New request initiated");

      cancelLeadsTokenSource = axios.CancelToken.source();

      const response = await searchLeads(query, {
        cancelToken: cancelLeadsTokenSource.token,
      });
      const data = await response.data;

      dispatch({
        type: LEADS_ACTION_TYPE,
        payload: {
          data: data.result,
          isSearching: true,
          searchQuery: query,
          status: "FULLFIELD",
        },
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was cancelled
        console.log("Request cancelled:", error.message);
      } else {
        // Handle other errors
        console.error("Error fetching data:", error);
        dispatch({
          type: LEADS_ACTION_TYPE,
          payload: {
            data: [],
            isSearching: true,
            searchQuery: query,
            status: "ERROR",
          },
        });
      }
    }
  };
};

let cancelOrdersTokenSource = axios.CancelToken.source();

export const GetSearchedOrders = (query) => {
  return async (dispatch) => {
    if (!query) {
      console.log("query", query);
      dispatch({
        type: ORDERS_ACTION_TYPE,
        payload: { data: [], isSearching: false, searchQuery: "", status: "" },
      });
      return;
    }

    dispatch({
      type: ORDERS_ACTION_TYPE,
      payload: {
        data: [],
        isSearching: true,
        searchQuery: query,
        status: "LOADING",
      },
    });

    try {
      cancelOrdersTokenSource.cancel("New request initiated");

      cancelOrdersTokenSource = axios.CancelToken.source();

      const response = await searchOrders(query, {
        cancelToken: cancelOrdersTokenSource.token,
      });
      const data = await response.data;

      dispatch({
        type: ORDERS_ACTION_TYPE,
        payload: {
          data: data.result || [],
          isSearching: true,
          searchQuery: query,
          status: "FULLFIELD",
        },
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was cancelled
        console.log("Request cancelled:", error.message);
      } else {
        // Handle other errors
        console.error("Error fetching data:", error);

        dispatch({
          type: ORDERS_ACTION_TYPE,
          payload: {
            data: [],
            isSearching: true,
            searchQuery: query,
            status: "ERROR",
          },
        });
      }
    }
  };
};

// GET YEARS

export const ABPYears = (payload) => {
  return {
    type: "SET_ABP_YEARS",
    payload: payload,
  };
};

export const selectedYearMonths = (payload) => {
  return {
    type: "SET_SELECTED_YEAR_DATA",
    payload: payload,
  };
};

export const selectedYear = (payload) => {
  return {
    type: "SET_SELECTED_YEAR",
    payload: payload,
  };
};

export const importExcel = (payload) => {
  return {
    type: "IMPORT_EXCEL",
    payload: payload,
  };
};


