import React, { useState, useEffect, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  Grid,
  Typography,
  Box,
  Link,
  TextField,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import TocIcon from "@mui/icons-material/Toc";
import AppsIcon from "@mui/icons-material/Apps";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CommonContextAPI } from "../../App";
import MonthPicker from "../dateFilter/MonthPicker";
import "./ModuleHeader.css";
import { importExcel, selectedYear } from "../../action";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  gridRow: {
    margin: "auto !important",
    width: "100% !important",
  },
  gridColumns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
  },
  dateGridColumns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  selectGridColumns: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  buttons: {
    backgroundColor: "#fff !important",
    color: "#000  !important",
    textDecoration: "none",
    padding: "0px",
    textTransform: "capitalize",
    width: "100%",
    height: "5vh",
    fontWeight: 600,
    fontSize: "16px",
  },

  orderTypeButton: {
    backgroundColor: "#7B8BD4 !important",
    color: "#000  !important",
    textDecoration: "none",
    padding: "5px",
    textTransform: "capitalize",
    width: "100%",
    // height: "4vh",
    height: "100%",
    fontWeight: 600,
    fontSize: "16px",
    color: "#fff !important",
  },
  abpLink: {
    padding: "5px",
    display: "flex",
    alignItems: "center",
    color: "#122164",
    fontSize: "1vw",
  },
  orderTypeActiveButtons: {
    backgroundColor: "#fff !important",
    color: "#000  !important",
    textDecoration: "none",
    padding: "5px",
    textTransform: "capitalize",
    width: "100%",
    // height: "4vh",
    height: "100%",
    fontWeight: 600,
    fontSize: "16px",
    // boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(209, 213, 219,1)",
  },
  importABPButton: {
    backgroundColor: "#7B8BD4 !important",
    color: "#000  !important",
    textDecoration: "none",
    padding: "5px",
    textTransform: "capitalize",
    // width: "100%",
    // height: "4vh",
    height: "100%",
    minWidth: "120px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#fff !important",
  },
  fileInput: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },

  fileLabel: {
    fontSize: "1rem",
    fontWeight: 700,
    color: "white",
    // backgroundColor: "black",
    display: "inline-block",
    cursor: "pointer",
  },

  leadDateTimeCol: {
    padding: "0px !important",
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between !important",
      alignItems: "center !important",
    },
  },
  icons: {
    fontSize: "2rem",

    [theme.breakpoints.up("md")]: {
      color: "#fff",
    },
  },
}));

let date = "";

const ModuleHeader = ({
  heading,
  downloadXLS,
  setTableView,
  handleOpenDatePicker,
  filteredDate,
  setFilteredDate,
}) => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [activeButton, setActiveButton] = useState(0);
  // const [currentOrders, setCurrentOrders] = useState("General");

  const dispatch = useDispatch();
  const { years: ABPYears } = useSelector((state) => state.ABPReducer);

  const classes = useStyles();
  const navigate = useNavigate();

  const commonData = useContext(CommonContextAPI);

  const {
    handleOpenAddMaterialModel,
    handleOpenAddProductModel,
    handleOpenAddVendorModel,
    handleOpenAddMaterialLibModel,
    handleOpenAddMaterialBrandModel,
    openDatePicker,
    getOrdersByOrderType,
    currentOrders,
    setCurrentOrders,
    downloadCMS,
    defaultDate,
    handleOpenMonthPicker,
    selectedMonth,
    approvedToggle,
    ordersGaragesToggle,
    setAddCompany,
    setAddInsuranceType,
    setAddServiceType,
    setAddPolicyType,
    setAddBroker,
    addFranchise,
    handleOpenAddFranchise,
    setAddFranchise,
    handleOpenAddCustomerIssue,
  } = commonData;

  const path = window.location.pathname;
  const pathname =
    path.lastIndexOf("/") == 0 ? path : path.slice(0, path.lastIndexOf("/"));

  // This state is used to hide date picker and display selected Month
  const [isGarageId, setIsGarageId] = useState(null);
  const [searchParams] = useSearchParams();

  useMemo(() => {
    setIsGarageId(searchParams.get("garageId"));
  }, [searchParams]);

  useEffect(() => {
    const current = localStorage.getItem("currentOrders");

    if (current == "general") {
      setActiveButton(0);
    }

    if (current == "insurance") {
      setActiveButton(1);
    }
  }, []);

  // const handleMonthSelect = () => {

  //   selectMonth && setSelectMonth();
  // };

  // If inventory page is activly loaded then return true.
  const isInventory =
    pathname == "/balancesummary" ||
    pathname == "/productin" ||
    pathname == "/productout";
  const isSalesReport2 = pathname == "/salesreport2";
  const isProductIn = pathname == "/productin";
  const isAdminConsole = path == "/adminConsole/users";
  const isVendors = pathname == "/vendors";
  const isMaterialLibrary = pathname == "/materiallibrary";
  const isMaterialBrands = pathname == "/materialbrands";
  const isOrders = pathname == "/orders";
  const isApprovedpayout = pathname == "/approvedpayout";
  const isMainDashboard = pathname == "/";
  const isInsuranceCompany = pathname == "/insurance_companies";
  const isInsuranceType = pathname == "/insurancetypes";
  const isServiceType = pathname == "/servicetype";
  const isPolicyType = pathname == "/policytype";
  const isBroker = pathname == "/brokers";
  const isCustomerService = pathname == "/customerservice";
  const isGarageFranchise = pathname == "/garagefranchise";
  const isABP = pathname == "/abp";
  // useEffect(() => {
  //   selectMonth && setOpenMonthPicker(false);
  //   isInventory && setFilteredDate(selectMonth);
  // }, [selectMonth]);

  const downloadSampleFormat = () => {
    alert("Download Sample Formate");
  };

  const selectAPBYear = (year) => {
    dispatch(selectedYear(year));
  };
  // dispatch(importExcel(parsedData));

  const importABP = (e) => {
    const rusult = new Promise((resolve, reject) => {
      const files = e.target.files;
      if (files && files[0]) {
        let file = files[0];

        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
          try {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const parsedData = XLSX.utils.sheet_to_json(ws, { header: 1 });

            let columns = parsedData[0]?.map(
              (col) =>
                `${col[0]?.toLowerCase()}${col?.slice(1).replace(" ", "")}`
            );
            const rows = parsedData?.slice(1).map((row, rowIndex) => {
              const obj = {};
              columns.forEach((col, colIndex) => {
                obj[col] = row[colIndex];
              });
              return obj;
            });

            resolve({ rows, columns });
          } catch (error) {
            reject(error);
          }
        };

        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      }
    });

    rusult
      .then((data) => dispatch(importExcel(data)))
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Grid container spacing={3} className={classes.gridRow}>
        <Grid
          item
          xs={12}
          sm={6}
          md={12}
          // lg={isInventory ? 4 : 3}
          lg={5}
          style={{
            padding: "0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant={isMobile ? "h5" : "h4"} className="titles">
            {isOrders && currentOrders} {heading}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
          className={classes.leadDateTimeCol}
          style={{ padding: "0px" }}
        >
          {isCustomerService ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {isMobile && filteredDate && (
                <Box sx={{ margin: "0.4rem 0px" }}>
                  <Typography variant="h6">{`${moment(
                    filteredDate[0]?.startDate
                  ).format("DD MMM YYYY")} - ${moment(
                    filteredDate[0]?.endDate
                  ).format("DD MMM YYYY")}`}</Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: isMobile ? "space-around" : "flex-end",
                  alignItems: "center",
                  margin: "0.4rem 0px",
                }}
              >
                <Box className="buttons-wrapper">
                  {isMobile ? (
                    <Typography onClick={handleOpenDatePicker}>
                      <CalendarMonthIcon className="icons" />
                    </Typography>
                  ) : (
                    <div className="buttons-box">
                      <Button
                        type="button"
                        variant="contained"
                        className="start-to-end-filter"
                        onClick={
                          isInventory
                            ? handleOpenMonthPicker
                            : handleOpenDatePicker
                        }
                      >
                        <CalendarTodayOutlinedIcon />
                        <h6 className="date-box">
                          {filteredDate
                            ? moment(filteredDate[0]?.startDate).format(
                                "D MMM"
                              ) +
                              " - " +
                              moment(filteredDate[0]?.endDate).format("D MMM")
                            : "Select Date"}
                        </h6>
                        <KeyboardArrowDownOutlinedIcon className="buttons-box-downArrow" />
                      </Button>
                    </div>
                  )}
                </Box>

                <Box className="buttons-wrapper">
                  {isMobile ? (
                    <Typography>
                      <SettingsIcon className="icons" />
                    </Typography>
                  ) : (
                    <div className="buttons-box">
                      <Button
                        type="button"
                        variant="contained"
                        className="settings-button"
                        onClick={handleOpenAddCustomerIssue}
                      >
                        Add Issue
                      </Button>
                    </div>
                  )}
                </Box>
                <Box className="buttons-wrapper">
                  {isMobile ? (
                    <Typography>
                      <SettingsIcon className="icons" />
                    </Typography>
                  ) : (
                    <div className="buttons-box">
                      <Button
                        type="button"
                        variant="contained"
                        className="settings-button"
                      >
                        <SettingsIcon />
                      </Button>
                    </div>
                  )}
                </Box>

                <Box className="buttons-wrapper">
                  {isMobile ? (
                    <Typography onClick={() => setTableView(false)}>
                      <AppsIcon className="icons" />
                    </Typography>
                  ) : (
                    <div className="buttons-box">
                      <Button
                        type="button"
                        variant="contained"
                        className="menu-icon"
                        onClick={() => setTableView(false)}
                      >
                        <AppsIcon />
                      </Button>
                    </div>
                  )}
                </Box>
                <Box className="buttons-wrapper">
                  {isMobile ? (
                    <Typography onClick={() => setTableView(true)}>
                      <TocIcon className="icons" />
                    </Typography>
                  ) : (
                    <div className="buttons-box">
                      <Button
                        type="button"
                        variant="contained"
                        className="list-icon"
                        onClick={() => setTableView(true)}
                      >
                        <TocIcon />
                      </Button>
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {isMobile && filteredDate && (
                <Box sx={{ margin: "0.4rem 0px" }}>
                  <Typography variant="h6">{`${moment(
                    filteredDate[0]?.startDate
                  ).format("DD MMM YYYY")} - ${moment(
                    filteredDate[0]?.endDate
                  ).format("DD MMM YYYY")}`}</Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: isMobile ? "space-around" : "flex-end",
                  alignItems: "flex-start",
                  margin: "0.4rem 0px",
                  gap: "5px",
                }}
              >
                {isApprovedpayout && (
                  <Box className="buttons-wrapper">
                    {isMobile ? (
                      <Typography sx={{ display: "flex" }}>
                        <PaymentIcon className="icons" />
                      </Typography>
                    ) : (
                      <div className="buttons-box">
                        {/* <div className="payNow-button">
                          <img
                            src={process.env.PUBLIC_URL + "/Assets/pay.png"}
                          />
                          <p>Pay Now</p>
                        </div> */}
                        <Button
                          type="button"
                          variant="contained"
                          className="payNow-button"
                        >
                          <img
                            src={process.env.PUBLIC_URL + "/Assets/pay.png"}
                          />
                          <p>Pay Now</p>
                        </Button>
                      </div>
                    )}
                  </Box>
                )}
                {}
                {(isSalesReport2 ||
                  isProductIn ||
                  isVendors ||
                  isMaterialLibrary ||
                  isMaterialBrands ||
                  isApprovedpayout ||
                  isApprovedpayout) && (
                  <>
                    {isMobile ? (
                      <Typography
                        onClick={
                          (isSalesReport2 && handleOpenAddMaterialModel) ||
                          (isProductIn && handleOpenAddProductModel) ||
                          (isVendors && handleOpenAddVendorModel) ||
                          (isMaterialLibrary &&
                            handleOpenAddMaterialLibModel) ||
                          (isMaterialBrands &&
                            handleOpenAddMaterialBrandModel) ||
                          (isApprovedpayout && downloadCMS)
                        }
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AddCircleIcon className="icons" />
                      </Typography>
                    ) : (
                      <div className="buttons-box">
                        <Button
                          type="button"
                          variant="contained"
                          className="add-form-button"
                          onClick={
                            (isSalesReport2 && handleOpenAddMaterialModel) ||
                            (isProductIn && handleOpenAddProductModel) ||
                            (isVendors && handleOpenAddVendorModel) ||
                            (isMaterialLibrary &&
                              handleOpenAddMaterialLibModel) ||
                            (isMaterialBrands &&
                              handleOpenAddMaterialBrandModel) ||
                            (isApprovedpayout && downloadCMS)
                          }
                        >
                          {(isSalesReport2 && "Add Material") ||
                            (isProductIn && "Add Product") ||
                            (isVendors && "Add Vendor") ||
                            (isMaterialLibrary && "Add Material") ||
                            (isMaterialBrands && "Add Brand") ||
                            (isApprovedpayout && "CMS File")}{" "}
                          {isApprovedpayout && <DownloadIcon />}
                        </Button>
                      </div>
                    )}
                  </>
                )}

                {isApprovedpayout && (
                  <>
                    <Box className="buttons-wrapper">
                      {isMobile ? (
                        <Typography onClick={() => approvedToggle("orders")}>
                          <SettingsIcon className="icons" />
                        </Typography>
                      ) : (
                        <div className="buttons-box">
                          <Button
                            // className="orders-cms-button"
                            className={
                              ordersGaragesToggle == "orders"
                                ? "active-button"
                                : "orders-cms-button"
                            }
                            type="button"
                            variant="contained"
                            onClick={() => approvedToggle("orders")}
                          >
                            <span>O</span>
                            {/* <SettingsIcon /> */}
                          </Button>
                        </div>
                      )}
                    </Box>

                    <Box className="buttons-wrapper">
                      {isMobile ? (
                        <Typography onClick={() => approvedToggle("garages")}>
                          <SettingsIcon className="icons" />
                        </Typography>
                      ) : (
                        <div className="buttons-box">
                          <Button
                            className={
                              ordersGaragesToggle == "garages"
                                ? "active-button"
                                : "orders-cms-button"
                            }
                            type="button"
                            variant="contained"
                            onClick={() => approvedToggle("garages")}
                          >
                            <span>G</span>
                            {/* <SettingsIcon /> */}
                          </Button>
                        </div>
                      )}
                    </Box>
                  </>
                )}

                {isInsuranceCompany && (
                  <Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={
                        activeButton == 0
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(0);
                        getOrdersByOrderType(1);
                        setAddCompany(true);
                      }}
                    >
                      Add Company
                    </Button>
                  </Box>
                )}

                {isInsuranceType && (
                  <Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={
                        activeButton == 0
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(0);
                        getOrdersByOrderType(1);
                        setAddInsuranceType(true);
                      }}
                    >
                      Add Insurance Type
                    </Button>
                  </Box>
                )}

                {isServiceType && (
                  <Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={
                        activeButton == 0
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(0);
                        getOrdersByOrderType(1);
                        setAddServiceType(true);
                      }}
                    >
                      Add Service Type
                    </Button>
                  </Box>
                )}

                {isPolicyType && (
                  <Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={
                        activeButton == 0
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(0);
                        getOrdersByOrderType(1);
                        setAddPolicyType(true);
                      }}
                    >
                      Add Poilicy Type
                    </Button>
                  </Box>
                )}

                {isBroker && (
                  <Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={
                        activeButton == 0
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(0);
                        getOrdersByOrderType(1);
                        setAddBroker(true);
                      }}
                    >
                      Add Broker
                    </Button>
                  </Box>
                )}

                {isGarageFranchise && (
                  <Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={classes?.orderTypeButton}
                      onClick={handleOpenAddFranchise}
                    >
                      Add Franchise
                    </Button>
                  </Box>
                )}

                {isABP && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-5px",
                    }}
                  >
                    <Link
                      href="/abp"
                      sx={{
                        padding: "5px",
                        display: "flex",
                        alignItems: "center",
                        color: "#122164",
                        fontSize: "1rem",
                        // width: "250px",
                      }}
                      // className={classes?.orderTypeButton}
                    >
                      {" "}
                      Download sample format for contact upload
                    </Link>
                    <Box>
                      <TextField
                        required
                        size="small"
                        label="Years"
                        fullWidth
                        select
                        variant="outlined"
                        id="model"
                        margin="dense"
                        name="model"
                        sx={{
                          maxWidth: "100px",
                          width: "150px",
                          marginLeft: "4px",
                          marginRight: "4px",
                        }}
                        // value={leadsData.model && leadsData.model.name}
                      >
                        {ABPYears.map((year) => (
                          <MenuItem
                            key={year}
                            value={year}
                            onClick={() => selectAPBYear(year)}
                          >
                            {year}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Button
                      sx={{ padding: "5px" }}
                      className={classes?.importABPButton}
                      // onClick={importABP}
                    >
                      {/* <input
                        style={{ visibility: "hidden" }}
                        type="file"
                        onChange={importABP}
                        size={60}
                      />
                      Import ABP */}

                      <input
                        type="file"
                        name="file"
                        id="file"
                        onChange={importABP}
                        className={classes.fileInput}
                      />
                      <label for="file" className={classes.fileLabel}>
                        Import ABP
                      </label>
                    </Button>
                  </Box>
                )}

                {isOrders && !isGarageId && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "250px",
                    }}
                  >
                    <Button
                      className={
                        activeButton == 0
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(0);
                        getOrdersByOrderType(1);
                        setCurrentOrders("General");
                        navigate("/orders/general");
                        localStorage.setItem("currentOrders", "general");
                      }}
                    >
                      General
                    </Button>
                    <Button
                      className={
                        activeButton == 1
                          ? classes.orderTypeButton
                          : classes.orderTypeActiveButtons
                      }
                      onClick={() => {
                        setActiveButton(1);
                        getOrdersByOrderType(2);
                        setCurrentOrders("Insurance");
                        navigate("/orders/insurance");
                        localStorage.setItem("currentOrders", "insurance");
                      }}
                    >
                      Insurance
                    </Button>
                  </Box>
                )}

                {!isABP && (
                  <Box className="buttons-wrapper">
                    {isMobile ? (
                      <Typography
                        onClick={
                          isInventory
                            ? handleOpenMonthPicker
                            : handleOpenDatePicker
                        }
                      >
                      <div className="buttons-box">
                        <Button
                          type="button"
                          variant="contained"
                          className="start-to-end-filter"
                          onClick={
                            isInventory
                              ? handleOpenMonthPicker
                              : handleOpenDatePicker
                          }
                        >
                        
                          <CalendarTodayOutlinedIcon />
                          <h5 className="date-box">
                            {!isInventory
                              ? filteredDate
                                ? moment(filteredDate[0]?.startDate).format(
                                    "D MMM"
                                  ) +
                                  " - " +
                                  moment(filteredDate[0]?.endDate).format(
                                    "D MMM"
                                  )
                                : defaultDate
                                ? moment(defaultDate[0]?.startDate).format(
                                    "D MMM"
                                  ) +
                                  " - " +
                                  moment(defaultDate[0]?.endDate).format(
                                    "D MMM"
                                  )
                                : "Select Date"
                              : selectedMonth
                              ? moment(selectedMonth).format("MMM YYYY")
                              : "Select Month"}
                          </h5>
                          <KeyboardArrowDownOutlinedIcon className="buttons-box-downArrow" />
                        </Button>
                      </div>

                        
                      </Typography>
                    ) : (
                      <div className="buttons-box">
                        <Button
                          type="button"
                          variant="contained"
                          className="start-to-end-filter"
                          onClick={
                            isInventory
                              ? handleOpenMonthPicker
                              : handleOpenDatePicker
                          }
                        >
                          <CalendarTodayOutlinedIcon />
                          <h6 className="date-box">
                            {!isInventory
                              ? filteredDate
                                ? moment(filteredDate[0]?.startDate).format(
                                    "D MMM"
                                  ) +
                                  " - " +
                                  moment(filteredDate[0]?.endDate).format(
                                    "D MMM"
                                  )
                                : defaultDate
                                ? moment(defaultDate[0]?.startDate).format(
                                    "D MMM"
                                  ) +
                                  " - " +
                                  moment(defaultDate[0]?.endDate).format(
                                    "D MMM"
                                  )
                                : "Select Date"
                              : selectedMonth
                              ? moment(selectedMonth).format("MMM YYYY")
                              : "Select Month"}
                          </h6>
                          <KeyboardArrowDownOutlinedIcon className="buttons-box-downArrow" />
                        </Button>
                      </div>
                    )}
                  </Box>
                )}

                {!isMainDashboard && (
                  <Box className="buttons-wrapper">
                    {isMobile ? (
                      <Typography>
                        <SettingsIcon className="icons" />
                      </Typography>
                    ) : (
                      <div className="buttons-box">
                        <Button
                          className="settings-button"
                          type="button"
                          variant="contained"
                        >
                          <SettingsIcon />
                        </Button>
                      </div>
                    )}
                  </Box>
                )}

                {isAdminConsole && (
                  <Box className="buttons-box">
                    <Button
                      className="add-user-button"
                      type="button"
                      variant="contained"
                      onClick={() => navigate("/adminConsole/users/addUser")}
                    >
                      Add User
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* {isOrders && !isGarageId && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0.5rem",
          }}
        >
          <Grid
            container
            spacing={3}
            // className={classes.gridRow}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={1.5}
              className={classes.selectGridColumns}
            >
              <Box sx={{ width: "100%" }}>
                <Button
                  className={
                    activeButton == 0
                      ? classes.orderTypeButton
                      : classes.orderTypeActiveButtons
                  }
                  onClick={() => {
                    setActiveButton(0);
                    getOrdersByOrderType(1);
                    setCurrentOrders("General");
                    navigate("/orders/general");
                  }}
                >
                  General
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              lg={1.5}
              className={classes.selectGridColumns}
            >
              <Box sx={{ width: "100%" }}> 
                <Button
                  className={
                    activeButton == 1
                      ? classes.orderTypeButton
                      : classes.orderTypeActiveButtons
                  }
                  onClick={() => {
                    setActiveButton(1);
                    getOrdersByOrderType(2);
                    setCurrentOrders("Insurance");
                    navigate("/orders/insurance");
                  }}
                >
                  Insurance
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
       */}
    </>
  );
};

export default ModuleHeader;

export { date };
