import React, { useContext, useEffect, useState } from "react";
import {
  getAllServiceTypes,
  getServiceTypeById,
  postServiceType,
} from "../../../services/Services";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import ModuleHeader from "../../../components/headers/ModuleHeader";
import { Box, Button } from "@mui/material";
import { DataGridStyle } from "../../../components/tables/DataGridStyles";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import PaginationTable from "../../../components/tables/Pagination";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteConfigModal from "../DeleteConfigModal";
import Form from "../../../components/forms/Form";
import { CommonContextAPI } from "../../../App";

const CustomToolbar = () => {
  const propsData = useSelector((state) => state?.CommonData);
  const { clearAppliedFilters } = propsData;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px",
      }}
    >
      <GridToolbarQuickFilter />
      <div sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          // className={classes.clearButton}
          style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
          onClick={clearAppliedFilters}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

const renderForm = [
  {
    label: "Insurance Type",
    fields: [
      {
        label: "Insurance Type",
        field: "TextField",
        name: "type",
        id: "type",
        type: "text",
        grids: 12,
      },
    ],
  },
];
export default function AllServiceTypes() {
  const [isRemoveConfigModal, setIsRemoveConfigModal] = useState(false);
  const [isEditServiceType, setIsEditServiceType] = useState(false);
  const [services, setServices] = useState([]);
  const [updateObj, setUpdateObj] = useState();
  const [isAdmin, setIsAdmin] = useState(true);
  const Navigate = useNavigate();

  const commonData = useContext(CommonContextAPI);

  const { addServiceType, setAddServiceType } = commonData;
  const AdminRole = useSelector((state) => state.AdminLogin.role);

  useEffect(() => {
    if (
      AdminRole.toLowerCase() == "super admin" ||
      AdminRole.toLowerCase() == "admin"
    ) {
      setIsAdmin(false);
    }
  }, [AdminRole]);
  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const getAllServices = async () => {
    try {
      const resquest = await getAllServiceTypes();
      const response = await resquest.data;
      setServices(response.result);
    } catch (Error) {
      console.log(Error);
    }
  };

  useEffect(() => {
    getAllServices();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      headerClassName: "super-app-theme--header",
      minWidth: 250,
      flex: 1,
    },

    {
      field: "type",
      headerName: "Service type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },

    {
      field: "edit",
      headerName: "Edit",
      headerClassName: "super-app-theme--header",
      editable: true,
      hide: isAdmin,
      Width: "10px",
      minWidth: 10,
      // flex: 1,
      renderCell: (params) => {
        let name = params?.row?.type?.split(" ").join("_");
        return (
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setIsEditServiceType(true);
              getInsuranceTypeInfo(params.id);
            }}
          >
            <EditIcon />
          </Box>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      headerClassName: "super-app-theme--header",
      editable: true,
      hide: isAdmin,
      minWidth: 10,
      renderCell: (par) => {
        return (
          <DeleteForeverIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              setIsRemoveConfigModal(true);
            }}
          />
        );
      },
    },
  ];

  // -----Edit Service types --------------------
  const getInsuranceTypeInfo = async (id) => {
    try {
      const request = await getServiceTypeById(id);
      const response = await request.data;
      setUpdateObj(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = () => {
    // Service type update api is not prasent
  };

  // --- Add Service Type -------------------

  const handleAddServiceType = async () => {
    try {
      const request = await postServiceType(updateObj);
      setIsEditServiceType(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className="comp-container">
      <ModuleHeader heading="Service Types" />
      <Box className="data-grid-container">
        {isRemoveConfigModal && (
          <DeleteConfigModal
            isRemoveConfigModal={isRemoveConfigModal}
            setIsRemoveConfigModal={setIsRemoveConfigModal}
          />
        )}
        {isEditServiceType && (
          <Form
            heading="Edit Service Type"
            closeModal={() => setIsEditServiceType(false)}
            renderForm={renderForm}
            state={updateObj}
            setState={setUpdateObj}
            submit={handleUpdate}
            isEdit={true}
          />
        )}

        {addServiceType && (
          <Form
            heading="Add Service Type"
            closeModal={() => setAddServiceType(false)}
            renderForm={renderForm}
            state={updateObj}
            setState={setUpdateObj}
            submit={handleAddServiceType}
          />
        )}
        <DataGrid
          columns={columns}
          rows={services}
          sx={DataGridStyle}
          components={{
            Footer: FooterSection,
            Toolbar: CustomToolbar,
          }}
          pageSize={50}
        />
      </Box>
    </Box>
  );
}
