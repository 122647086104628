import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import BasicInfo from "./BasicInfo";
import PersonalInfo from "./PersonalInfo";
import PaymentInfo from "./PaymentInfo";
import "./AddGarage.css";

import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  Toolbar,
  Divider,
  StepLabel,
  Step,
  Stepper,
  Button,
} from "@mui/material";
import { garageById } from "../../services/garageServices/GarageServices";

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "0rem 0rem 1rem",
  },
  profileHeading: {
    fontSize: "2rem",
    lineHeight: 2.2,
    fontWeight: 600,
    fontFamily: "AvenirBold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  contentWrapper: {
    margin: "2rem auto",
    padding: "1rem !important",
    background: "#ffffff",
    borderRadius: " 8px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stepperWrapper: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  actionWrapper: {
    width: "100%",
  },
  toolbar: {
    [theme.breakpoints.down("sm")]: {
      // maxHeight: "40px",
      display: "none",
    },
  },
}));

const steps = ["Basics", "Personal Info", "Payment Info"];
const EditGarageInfo = () => {
  const classes = useStyles();

  const [garageDetails, setGarageDetails] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [garageId, setGarageId] = useState("");

  let [searchParams] = useSearchParams();
  useMemo(() => setGarageId(searchParams.get("garageId")), [searchParams]);

  const getGaragesById = async (garageId) => {
    try {
      const response = await garageById(garageId);
      const data = await response.data;
      setGarageDetails(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGaragesById(garageId);
  }, [garageId]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box className={classes.mainContainer}>
      <Toolbar className={classes.toolbar} />
      <Box>
        <Box className={classes.headerWrapper}>
          <Typography className={classes.profileHeading}>
            {garageDetails?.garageName}'s Profile
          </Typography>
          <Box className={classes.stepperWrapper}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    onClick={() => setActiveStep(index)}
                    sx={{
                      cursor: "pointer",
                      "& .MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root Mui-completed css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root":
                        {
                          backgroundColor: "red",
                          color: "blue",
                        },
                    }}
                    disabled={false}
                  >
                    <StepLabel
                      sx={{
                        "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed":
                          { color: "#122164" },
                        "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                          {
                            // color: "#122164",
                            color: "green",
                          },
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          {/* {activeStep === steps.length ? (
            <Box>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Box>
          ) : ( */}
          <Box className={classes.actionWrapper}>
            <Box>
              {activeStep === 0 ? (
                <BasicInfo
                  garageId={garageDetails?.garageId}
                  garageDetails={garageDetails}
                  setGarageDetails={setGarageDetails}
                />
              ) : activeStep === 1 ? (
                <PersonalInfo
                  garageId={garageDetails?.garageId}
                  garageDetails={garageDetails}
                  setGarageDetails={setGarageDetails}
                />
              ) : (
                <PaymentInfo garageId={garageDetails?.garageId} />
              )}
            </Box>

            {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box> */}
          </Box>
          {/* )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default EditGarageInfo;
