import React, { useState, useEffect, useMemo, useContext, memo } from "react";
import Table from "../../components/tables/Table";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Grid, Box, TextField, Typography, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  getServicingGrossProfitReports,
  updateGrossProfitReport,
} from "../../services/grossProfitService/GrossProfitServices";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const ServicingGrossProfit = () => {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [bp, setBp] = useState(0);
  const [tp, setTp] = useState(0);
  const [ksMp, setksMp] = useState(0);
  const [gp, setGp] = useState(0);
  const [gprofit, setGProfit] = useState(0);
  const [gm, setGm] = useState(0);
  const [loading, setLoading] = useState(true);

  const [basicPrice, setBasicPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [
    gativanMaterialCostPriceWithoutTax,
    setGativanMaterialCostPriceWithoutTax,
  ] = useState(0);
  const [garagePayout, setGaragePayout] = useState(0);
  const [grossProfit, setGrossProfit] = useState(0);
  const [grossMargin, setGrossMargin] = useState(0);
  const [ordersObject, setOrdersObject] = useState([]);

  const [grossProfitReportList, setGrossProfitReportList] = useState([]);
  const [grossProfitCount, setGrossProfitCount] = useState(0);
  const [filteredGrossProfitCount, setFilteredGrossProfitCount] = useState(0);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    filterDateRenge,
    filterDateInRange,
    filterModel,
    defaultDate,
    handleAlertOpen,
    colors,
  } = commonData;

  useEffect(() => {
    setBp(0);
    setTp(0);
    setksMp(0);
    setGp(0);
    setGProfit(0);
    setGm(0);

    // let sum = 0;

    ordersObject.map((item, index) => {
      // sum += item.grossProfit;
      setBp((prev) => prev + item.basicPrice);
      setTp((prev) => prev + item.totalPrice);
      setksMp((prev) => prev + item.gativanMaterialCostPriceWithoutTax);
      setGp((prev) => prev + item.garagePayout);
      setGProfit((prev) => prev + item.grossProfit);
      setGm((prev) => prev + (item.grossMargin ? item.grossMargin : 0));
    });
  }, [ordersObject]);

  const grossProfitReports = async () => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0]?.endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0]?.startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0]?.endDate).format("YYYY-MM-DD");
    }

    let params = {
      from: startDate,
      to: endDate,
    };

    try {
      const response = await getServicingGrossProfitReports({ params });
      const data = await response.data;
      setGrossProfitReportList(data.result);
      setGrossProfitCount(data.result?.length);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateGrossProfitDetails = async (grossProfitDetails, message) => {
    try {
      const response = await updateGrossProfitReport(grossProfitDetails);
      const data = await response.data;
      handleAlertOpen(message, colors.success);
      grossProfitReports();
    } catch (error) {
      console.log(error);
      handleAlertOpen(error?.response?.data?.message, colors.error);
    }
  };

  useMemo(() => {
    setOrdersObject([]);
    grossProfitReportList?.map((grossProfit) => {
      const temp = {
        ...grossProfit,
        customerName: `${grossProfit?.order?.user?.first_name} ${grossProfit?.order?.user?.last_name}`,
        completedDate: grossProfit?.order?.completionDate,
        completedDateTime: new Date(
          `${grossProfit?.order?.completionDate} ${grossProfit?.order?.completionTime}`
        ),
        gpSource: grossProfit?.gpSource?.gpSource,
        totalPrice: Number(grossProfit?.order?.totalPrice),
        vehicleType: grossProfit?.order?.vehicle?.vehicleType?.vehicleType,
        garageName: grossProfit?.order?.garage?.garageName,
        discount: grossProfit?.order?.discount,
        taxableAmount: Math.round(grossProfit?.taxableAmount),
        grossMargin: grossProfit?.grossMargin
          ? Math.round(grossProfit?.grossMargin)
          : 0,
      };
      setOrdersObject((prev) => [...prev, temp]);
    });
  }, [grossProfitReportList]);

  useEffect(() => {
    grossProfitReports();
  }, [, filteredDate, defaultDate]);

  const updateMaterialPriceAndGST = (params) => {
    const gp = grossProfitReportList?.find(
      (report) => report.orderId == params.id
    );
    const value = params.value;
    let putObject;
    let msg;
    if (params?.field == "materialCostPrice") {
      putObject = {
        orderId: gp?.orderId,
        materialCostPrice: Number(value),
        materialGSTRate: gp.materialGSTRate || 0,
      };
      msg = "Material Cost Price Updated Successfully!";
    } else if (params?.field == "materialGSTRate") {
      putObject = {
        orderId: gp?.orderId,
        materialGSTRate: Number(value),
        materialCostPrice: gp.materialCostPrice || 0,
      };
      msg = "Material GST Rate Updated Successfully!";
    } else if (params?.field == "paymentReceived") {
      putObject = {
        orderId: gp?.orderId,
        paymentReceived: Number(value),
      };
      msg = "Received Payment Updated Successfully!";
    } else if (params?.field == "paymentLink") {
      putObject = {
        orderId: gp?.orderId,
        paymentLink: value || "",
      };
      msg = "Payment Link Updated Successfully!";
    } else {
      return false;
    }

    updateGrossProfitDetails(putObject, msg);
  };

  const columns = [
    {
      field: "orderId",
      headerName: "Order ID",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "gpSource",
      headerName: "GP Source",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "completedDate",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      filterable: true,
      editable: true,
      filterOperators: filterDateInRange,
      flex: 1,
    },
    {
      field: "completedDateTime",
      headerName: "Completed Date",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "brandingCost",
      headerName: "Branding Cost",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "basicPrice",
      headerName: "Basic_Price",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "totalPrice",
      headerName: "Total_Price",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "taxableAmount",
      headerName: "Taxable Amount",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "gativanMaterialInvoiceGST",
      headerName: "Gativan Material Invoice GST",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "materialCostPrice",
      headerName: "Material Cost Price",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      editable: true,
      flex: 1,
    },
    {
      field: "materialGSTRate",
      headerName: "Material GST Rate",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      editable: true,
      flex: 1,
    },
    {
      field: "garagePayout",
      headerName: "Garage Payout",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      // editable: true,
      flex: 1,
    },
    {
      field: "grossProfit",
      headerName: "Gross Profit",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "grossMargin",
      headerName: "Gross Margin",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "service",
      headerName: "Service",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "garageName",
      headerName: "Garage Name",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "gst",
      headerName: "GST",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "paymentLink",
      headerName: "Payment Link",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "paymentReceived",
      headerName: "Payment Received",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      editable: true,
      flex: 1,
    },
    {
      field: "discount",
      headerName: "Discount",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "gstCredits",
      headerName: "GST Credits",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
  ];

  const [filter, setFilter] = useState([]);
  const getFilteredData = (data) => {
    setFilter(data);
  };

  // Date filter model
  useMemo(() => {
    filteredDate && filterDateRenge && filterDateRenge("completedDate");
  }, [filteredDate && filteredDate]);

  const totals = (
    <Box className={classes.totalWrapper}>
      <Grid container spacing={!smallPhone && 1}>
        <Grid item xs={12} sm={2}>
          <Box className={classes.gridColumnOdd}>
            <span>Basic Price :</span>
            <span className={classes.totalCount}>
              {basicPrice ? basicPrice?.toFixed(2) : bp?.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box className={classes.gridColumnEven}>
            <span>Total Price :</span>
            <span className={classes.totalCount}>
              {totalPrice ? totalPrice.toFixed(2) : tp?.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box className={classes.gridColumnOdd}>
            <span> Material Price :</span>
            <span className={classes.totalCount}>
              {gativanMaterialCostPriceWithoutTax
                ? gativanMaterialCostPriceWithoutTax.toFixed(2)
                : ksMp.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box className={classes.gridColumnEven}>
            <span> Garage Payout :</span>
            <span className={classes.totalCount}>
              {garagePayout ? garagePayout?.toFixed(2) : gp.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box className={classes.gridColumnOdd}>
            <span> Gross Profit :</span>
            <span className={classes.totalCount}>
              {grossProfit ? grossProfit.toFixed(2) : gprofit.toFixed(2)}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box className={classes.gridColumnEven}>
            <span> Gross Margin :</span>
            <span className={classes.totalCount}>
              {grossMargin
                ? (grossMargin.toFixed(2) / grossProfitCount).toFixed(2)
                : gm}
            </span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
  function FooterSection() {
    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right">
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Basic Price :{" "}
              <span className="grid-table-footer-right-value">
                {basicPrice ? basicPrice?.toFixed(2) : bp?.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Total Price :{" "}
              <span className="grid-table-footer-right-value">
                {totalPrice ? totalPrice.toFixed(2) : tp?.toFixed(2)}
              </span>
            </h5>
          </div>

          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Material Price :{" "}
              <span className="grid-table-footer-right-value">
                {gativanMaterialCostPriceWithoutTax
                  ? gativanMaterialCostPriceWithoutTax.toFixed(2)
                  : ksMp.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Garage Payout :{" "}
              <span className="grid-table-footer-right-value">
                {garagePayout ? garagePayout?.toFixed(2) : gp.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Gross Profit :{" "}
              <span className="grid-table-footer-right-value">
                {grossProfit ? grossProfit.toFixed(2) : gprofit.toFixed(2)}
              </span>
            </h5>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h5 className="grid-table-footer-right-label">
              Gross Margin :{" "}
              {/* <span className="grid-table-footer-right-value">
                {grossMargin
                  ? (grossMargin.toFixed(2) / grossProfitCount).toFixed(2)
                  : gm}
              </span> */}
              <span className="grid-table-footer-right-value">
                {grossMargin
                  ? (
                      grossMargin /
                      (filteredGrossProfitCount
                        ? filteredGrossProfitCount
                        : grossProfitCount)
                    )?.toFixed(2)
                  : gm &&
                    (
                      gm /
                      (filteredGrossProfitCount > 0
                        ? filteredGrossProfitCount
                        : grossProfitCount)
                    )?.toFixed(2)}
              </span>
            </h5>
          </div>
        </div>
        <PaginationTable />
      </div>
    );
  }

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <Table
        customStyle={DataGridStyle}
        initialState={{
          sorting: {
            sortModel: [{ field: "completedDateTime", sort: "desc" }],
          },
          columns: {
            columnVisibilityModel: {
              completedDateTime: false,
            },
          },
        }}
        heading="Servicing Gross Profits"
        rows={ordersObject && ordersObject}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        onCellEditCommit={(params) => {
          if (
            params.field == "materialCostPrice" ||
            params.field == "materialGSTRate" ||
            params.field == "paymentReceived" ||
            params.field == "paymentLink"
          ) {
            updateMaterialPriceAndGST(params);
          }
        }}
        loading={loading}
        onStateChange={(state) => {
          let visibleItems = [];
          const visibleRows = state.filter.visibleRowsLookup;
          for (const [orderId, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(orderId);
            }
          }
          const res = ordersObject.filter((item) =>
            visibleItems.includes(item.orderId)
          );

          // l stands for local
          const lbp = res
            .map((item) => item.basicPrice)
            .reduce((a, b) => a + b, 0);

          const ltp = res
            .map((item) => item.totalPrice && Number(item.totalPrice))
            .reduce((a, b) => a + b, 0);

          const lksMp = res
            .map((item) => item.gativanMaterialCostPriceWithoutTax)
            .reduce((a, b) => a + b, 0);

          const lgp = res
            .map((item) => item.garagePayout)
            .reduce((a, b) => a + b, 0);

          const lgprofit = res
            .map((item) => item.grossProfit)
            .reduce((a, b) => a + b, 0);

          const lgm = res
            .map((item) => item.grossMargin)
            .reduce((a, b) => a + b, 0);
          // setFilter(res);
          setFilteredGrossProfitCount(res.length);
          setBasicPrice(lbp > 0 && lbp);
          setTotalPrice(ltp > 0 && ltp);
          setGativanMaterialCostPriceWithoutTax(lksMp > 0 && lksMp);
          setGaragePayout(lgp > 0 && lgp);
          setGrossProfit(lgprofit > 0 && lgprofit);
          setGrossMargin(lgm > 0 && lgm);
        }}
        // total={totals}
        filterModel={filterModel && filterModel}
        // downloadXLS={() =>
        //   downloadXLS(filter.length > 0 ? filter : ordersObject)
        // }
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        getRowId={(row) => row.orderId}
      />
    </div>
  );
};

export default React.memo(ServicingGrossProfit);
