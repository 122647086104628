import testHttp from "../../http-common";

// GET FRANCHISE LIST
export const getFranchise = (config) => {
  return testHttp.get("/franchise", config);
};

// POST NEW FRANCHISE
export const addFranchise = (franchiseDetails, config) => {
  return testHttp.post("/franchise", franchiseDetails, config);
};

// GET FRANCHISE BY ID
export const getFranchiseById = (franchiseId, config) => {
  return testHttp.get(`/franchise/${franchiseId}`, config);
};

// GET FRANCHISE BY ID
export const updateFranchise = (updateObj, config) => {
  return testHttp.put("/franchise/franchiseStatus", updateObj, config);
};

// UPDATE GARAGE FRANCHISE
export const franchiseStatuses = (config) => {
  return testHttp.get("/franchise/franchiseStatus", config);
};


// GET FRANCHISE PAYMENT MODE
export const getPaymentModes = () => {
  return testHttp.get("/paymentMode");
};
