import React, { useEffect, useMemo, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CategoryScale } from "chart.js";
import moment from "moment";
import "./Chart.css";

import {
  getGrossProfit,
  getOrdersalses,
} from "../../../services/dashboard/DashboardServices";
import { useSelector } from "react-redux";
import { CommonContextAPI } from "../../../App";
import LoaderSpinner from "../../loading/LoaderSpinner";

Chart.register(CategoryScale);

const grossProfiteOptions = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      anchor: "end",
      align: "top",
      offset: 5,
    },
    title: {
      display: true,
      text: "Gross Profit",
      font: {
        size: 20,
      },
      padding: {
        bottom: 20,
      },
      // color: "#000",
    },
  },
  elements: {
    line: {
      tension: 0.6,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        minTicksLimit: 0,
        maxTicksLimit: 10,
      },
    },
    y: {
      grid: {
        display: true,
        beginAtZero: true,
        max: 100,
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value) {
          // return value + "L";
          return value;
        },
        minTicksLimit: 0,
        maxTicksLimit: 5,
      },
    },
  },
};

export default function GrossProfitCharts({ filterButtons }) {
  const [grossProfit, setGrossProfit] = useState([]);
  const [grossProfitGroup, setGrossProfitGroup] = useState("week");
  const [isLoading, setIsLoading] = useState(true);

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);
  const { filteredDate, defaultDate } = commonData;

  const { activeButton, inactiveButton } = filterButtons;

  const filterDate = useSelector((state) => state?.DateRange_Reducer);

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const token = loginData.jwtToken;

  const grossProfiteData = {
    // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    labels: grossProfit?.map((i) => {
      let date = new Date(i.date);
      let prefixes = ["W1 ", "W2 ", "W3 ", "W4 ", "W5 "];
      let prefixesDeci = [1, 2, 3, 4, 5];

      if (grossProfitGroup === "week") {
        let wee;
        if (grossProfit.length <= 5) {
          wee =
            moment(i.date).format("MMM") +
            " " +
            prefixes[Math.floor(date.getDate() / 7)];
        } else if (grossProfit.length <= 10) {
          wee =
            prefixes[Math.floor(date.getDate() / 7)] +
            " " +
            moment(i.date).format("MMM");
        } else {
          wee =
            prefixes[Math.floor(date.getDate() / 7)] +
            " " +
            moment(i.date).format("MMM YY");
        }
        return wee;
      } else if (grossProfitGroup === "month") {
        let mon;
        if (grossProfit.length <= 12) {
          mon = moment(i.date).format("MMM YYYY");
        } else {
          mon = moment(i.date).format("MMM YY");
        }
        return mon;
      } else if (grossProfitGroup === "year") {
        return moment(i.date).format("YYYY");
      }
    }),
    datasets: [
      {
        // data: [2, 4, 3, 5, 2.6, 3, 15, 12],
        data: grossProfit.map((i) => i.amount),
        fill: true,
        borderWidth: 2,
        backgroundColor: "#E9EDFB",
        borderColor: "#122164",
      },
    ],
  };

  const getGrossProfitInfo = async (groupBy) => {
    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        from: startDate,
        to: endDate,
        groupBy: groupBy,
      },
    };
    try {
      const request = await getGrossProfit(config);
      const response = await request.data;
      setGrossProfit(response.result);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useMemo(() => {
    if (filteredDate || defaultDate) {
      getGrossProfitInfo(grossProfitGroup);
      setIsLoading(true);
    }
  }, [filteredDate, defaultDate, grossProfitGroup]);

  return (
    <div className="grossProfitSsection ">
      <div className="sales-header">
        <div className="sales-title-section">
          <h6 className="grossProfitnew">Gross Profit</h6>
        </div>
        <div className="filter-section">
          <button
            style={grossProfitGroup === "year" ? activeButton : inactiveButton}
            onClick={(e) => {
              setGrossProfitGroup("year");
              // handelMainFilterButtons(e);
            }}
          >
            Year
          </button>
          <button
            style={grossProfitGroup === "month" ? activeButton : inactiveButton}
            onClick={(e) => {
              setGrossProfitGroup("month");
              // handelMainFilterButtons(e);
            }}
          >
            Month
          </button>
          <button
            style={grossProfitGroup === "week" ? activeButton : inactiveButton}
            onClick={(e) => {
              setGrossProfitGroup("week");
              // handelMainFilterButtons(e)
            }}
          >
            Week
          </button>
        </div>
      </div>
      {isLoading ? (
          <LoaderSpinner />
      ) : (
        <div className="chartContainer">
          <Line
            data={grossProfiteData}
            plugins={[ChartDataLabels]}
            options={grossProfiteOptions}
          />
        </div>
      )}
    </div>
  );
}
