import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./AddressModel.css";

export default function AddressModel({
  userAddress,
  open,
  handleClose,
  updateObj,
  setUpdateObj,
  setUserAddress,
}) {
  const [address, setAddress] = useState();

  const handleDiscard = () => {
    handleClose();
  };

  const handleConfirm = async () => {
    setUserAddress(address);
    await setUpdateObj({ ...updateObj, address });
    handleClose();
  };

  const handleChandeAddress = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setAddress(userAddress);
  }, [userAddress]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <div className="address-model-container">
        <input
          className="address-field"
          name="houseNumber"
          defaultValue={address?.houseNumber}
          placeholder="House Number"
          onChange={handleChandeAddress}
        />
        <input
          className="address-field"
          name="area"
          defaultValue={address?.area}
          placeholder="Area"
          onChange={handleChandeAddress}
        />
        <input
          className="address-field"
          name="city"
          defaultValue={address?.city}
          placeholder="City"
          onChange={handleChandeAddress}
        />
        <input
          className="address-field"
          name="pincode"
          defaultValue={address?.pincode}
          placeholder="Pin Code"
          onChange={handleChandeAddress}
        />
        <div>
          <button
            className="address-model-conformbutton"
            onClick={handleDiscard}
          >
            Discard
          </button>
          <button
            className="address-model-conformbutton"
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
}
