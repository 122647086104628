import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/tables/Table";
import { Grid, Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import AddVendor from "./AddVendor";
import { getVendorsList } from "../../services/inventoryServices/InventoryServices";
import { DataGridStyle } from "../../components/tables/DataGridStyles";
import PaginationTable from "../../components/tables/Pagination";
import { CommonContextAPI } from "../../App";

const useStyles = makeStyles((theme) => ({
  totalWrapper: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "0%",
      width: "65%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: "0%",
      width: "75%",
    },
    [theme.breakpoints.down("sx")]: {
      backgroundColor: "#ff1a1a",
      height: "220px !important",
    },
    width: "100%",
    cursor: "default",
  },
  gridContainer: {
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
    },
  },
  gridColumnOdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#F8F8F8",
    color: "#000",
    fontSize: "1rem !important",
    cursor: "default",

    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  gridColumnEven: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    border: "1px solid black",
    borderRadius: "2px",
    backgroundColor: "#E3E7FA",
    color: "black",
    fontSize: "1rem !important",
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "0.2rem 0px !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px",
      flexDirection: "column",
    },
  },
  totalCount: {
    fontSize: "1rem",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#b1b1b5",
    },
  },
  search: {
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  clearButton: {
    backgroundColor: "#7B8BD4",
    "&:hover": { backgroundColor: "#7B8BD4" },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      width: "44px",
    },
  },
}));

const QuickSearchToolbar = () => {
  // GET CONTEXT COMMON DATA
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const commonData = useContext(CommonContextAPI);

  const { clearAppliedFilters } = commonData;
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
        justifyContent: "space-between",
        mb: 0.5,
      }}
    >
      <GridToolbarQuickFilter className={classes.search} />
      <Box sx={{ "& .MuiButtonBase-root": { minWidth: "32px" } }}>
        <GridToolbarExport />
        <Button
          variant="contained"
          size="small"
          className={classes.clearButton}
          onClick={clearAppliedFilters}
        >
          {isMobile ? " Clear" : " Clear Filters"}
        </Button>
      </Box>
    </Box>
  );
};

const Vendors = () => {
  const theme = useTheme();
  const smallPhone = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const [vendorsList, setVendorsList] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isVendorAdded, setIsVendorAdded] = useState(false);

  const [filter, setFilter] = useState([]);
  const getFilteredData = (data) => {
    setFilter(data);
  };

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    downloadXLS,
    handleOpenDatePicker,
    filteredDate,
    setFilteredDate,
    isOpenAddVendorModel,
  } = commonData;

  let colArr = [];

  if (filteredDate != undefined) {
    const year = new Date(filteredDate).getMonth();
    const selectedMonth = new Date(filteredDate).getMonth() + 1;

    let days = new Date(year, selectedMonth, 0).getDate();

    for (let i = 1; i <= days; i++) {
      colArr.push(
        `${i}-${moment(new Date(year, selectedMonth - 1)).format("MMM")}`
      );
    }
  } else {
    let date = new Date();
    let curYear = date.getFullYear();
    let curMonth = date.getMonth() + 1;
    let curDate = date.getDate();

    for (let i = 1; i <= curDate; i++) {
      colArr.push(
        `${i}-${moment(new Date(curYear, curMonth - 1)).format("MMM")}`
      );
    }
  }

  // Get Vendors List

  const getAllVendorsList = async () => {
    try {
      const response = await getVendorsList();
      const result = await response.data;
      setVendorsList(result.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setVendors([]);
    vendorsList?.map((vendor) => {
      setVendors((prev) => [
        ...prev,
        {
          ...vendor,
          address: `${vendor?.shopNumber} ${vendor?.area} ${vendor?.city} ${vendor?.pincode} ${vendor?.landMark}`,
        },
      ]);
    });
  }, [vendorsList]);

  useEffect(() => {
    getAllVendorsList();
  }, [, isVendorAdded]);

  const columns = [
    {
      field: "id",
      headerName: "Sr.No.",
      headerClassName: "super-app-theme--header",
      minWidth: 60,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Vendor Name",
      headerClassName: "super-app-theme--header",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "gstn",
      headerName: "GST Number",
      headerClassName: "super-app-theme--header",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "terms",
      headerName: "Terms",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "active",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
    },
  ];

  function FooterSection() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <div className="grid-table-footer">
        <div className="grid-table-footer-right"></div>
        <PaginationTable />
      </div>
    );
  }

  const CustomToolbar = () => {
    const { clearAppliedFilters } = commonData;
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <GridToolbarQuickFilter />
        <div>
          <GridToolbarExport />
          <Button
            variant="contained"
            size="small"
            // className={classes.clearButton}
            style={{ marginLeft: "10px", backgroundColor: "blue !importent" }}
            onClick={clearAppliedFilters}
          >
            Clear Filters
          </Button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div>
      <Table
        customStyle={DataGridStyle}
        heading="Vendors"
        rows={vendors && vendors}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[100]}
        downloadXLS={() =>
          downloadXLS(filter.length > 0 ? filter : vendorsList && vendorsList)
        }
        components={{ Footer: FooterSection, Toolbar: QuickSearchToolbar }}
        handleOpenDatePicker={handleOpenDatePicker}
        filteredDate={filteredDate}
        setFilteredDate={setFilteredDate}
      />

      {isOpenAddVendorModel && (
        <AddVendor setIsVendorAdded={setIsVendorAdded} />
      )}
    </div>
  );
};

export default Vendors;
