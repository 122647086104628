import testHttp from "../../http-common";

export const getAllUsers = () => {
  return testHttp.get("/user");
};

export const addUser = (userDetails) => {
  return testHttp.post("/user/add", userDetails);
};

export const getAllUserRoles = () => {
  return testHttp.get("/roles");
};

export const getUserProfile = (config) => {
  return testHttp.get("/user/me", config);
};

export const changeUserPassword = (pass, config) => {
  return testHttp.put("/user/changePassword", pass, config);
};

export const updateUserDetailsByAdmin = (userDetails) => {
  return testHttp.put("/user", userDetails);
};

// export const updateUserProfileDetails = (userDetails, config) => {
//   return testHttp.put("/user/me", userDetails, config);
// };

export const updateUserProfileDetails = (userDetails, config) => {
  return testHttp.put("/user/me", userDetails, config);
};

export const getUserDetailsById = (userId) => {
  return testHttp.get(`/user/${userId}`);
};
