export const GetOrders = (state = [], action) => {
  switch (action.type) {
    case "getAllOrders":
      return [ ...state, action.payload];
      break;

    default:
      return state;
  }
};


const initState = {
  data: [],
  isSearching: false,
  searchQuery: "",
  status: ""
};

export const OrdersReducer = (state = initState, action) => {
  switch (action.type) {
    case "SEARCH_ORDERS":
      return {
        data: action.payload.data,
        isSearching: action.payload.isSearching,
        searchQuery: action.payload.searchQuery,
        status: action.payload.status,
      };
    default:
      return state;
  }
};

