import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import "../../modules/leads/AddLead.css";
import { CommonContextAPI } from "../../App";
import { ArrivalModeContext } from "./LeadDetails";
import {
  getAllArrivalModes,
  getAllFollowUpReasons,
} from "../../services/leadServices/LeadServices";

const ArrivalModes = ({ propsData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [arrivalModes, setArrivalModes] = useState([]);
  const {
    openArrivalModal,
    closeArrivalModal,
    openLocationModal,
    closeLocationModal,
    openReasonsModal,
    closeReasonsModal,
    openGaragesModal,
    closeGaragesModal,
    closeConfirmationModal,
    openArrivalModes,
    openArrivalModeList,
    closeArrivalModeList,
    setSelectedMode,
    viewDetails,
  } = propsData;

  // GET CONTEXT COMMON DATA
  const { handleAlertOpen, colors } = useContext(CommonContextAPI);
  const arrivalModeContextData = useContext(ArrivalModeContext);
  const { setArrivalModeDetails, arrivalModeDetails } = arrivalModeContextData;

  const userSelection = (field, value) => {
    setSelectedMode(value?.mode);
    setArrivalModeDetails({
      ...arrivalModeDetails,
      // ["arrivalModeId"]: value?.id,
      [field]: value,
    });
    closeArrivalModeList();
    openArrivalModal();
  };

  // Get all Arrival Modes

  const getArrivalModes = async () => {
    try {
      const response = await getAllArrivalModes();
      const data = await response.data;
      setArrivalModes(data?.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArrivalModes();
  }, []);

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "all 500ms ease-in-out",
    width: isMobile ? "95%" : 400,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    // height: isMobile ? "80%" : 615,
    height: "auto",
    maxHeight: "90%",
    border: "none !important",
    // overflow: "auto !important",
  };

  return (
    <div>
      <Modal
        // closeTimeoutMS={500}
        open={true}
        onClose={closeArrivalModeList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              position: "absolute",
              top: 1,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={closeArrivalModeList}
            />
          </Box>

          <Box
            sx={{
              padding: "1rem 0",
            }}
          >
            <Typography variant="h6">Select Arrival Mode</Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              {/* <Box
                className="buttonGroup"
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {arrivalModes?.map((mode) => [
                  <>
                    <Button
                      variant="contained"
                      size="small"
                      className="buttons"
                      onClick={() => userSelection("arrivalMode", mode)}
                      sx={{
                        backgroundColor:
                          viewDetails?.arrivalMode?.id == mode?.id
                            ? "#122164 !important"
                            : "#7B8BD4 !important",
                      }}
                    >
                      {mode?.mode}
                    </Button>
                  </>,
                ])}
              </Box> */}
              <ul
                className="donate-now"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {arrivalModes &&
                  arrivalModes.map((mode, index) => {
                    return (
                      <Box key={index}>
                        <li>
                          <input
                            type="radio"
                            id={mode.id}
                            name="arrivalMode"
                            value={mode?.id || ""}
                            defaultChecked={
                              arrivalModeDetails?.arrivalMode?.mode ==
                              mode?.mode
                                ? true
                                : false
                            }
                            // defaultChecked={index == 1 ? true : false}
                            onClick={() => userSelection("arrivalMode", mode)}
                          />
                          <label htmlFor={mode?.mode}>{mode?.mode}</label>
                        </li>
                      </Box>
                    );
                  })}
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ArrivalModes;
