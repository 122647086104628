import React, { useState, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getOrderById } from "../../../services/Services";
import convertor from "number-to-words";
import "./GarageInvoice.css";
import GarageInvoices from "../../../components/pdf/garageInvoice/GarageInvoice";

export default function GarageInvoice() {
  const loaction = useLocation();
  const OrderId = loaction?.state.value;

  return (
    <GarageInvoices />
    // <Box className="garageInvoice-conatiner">
    //   <Box className="garageInvoiceHeader">
    //     <Typography className="garageInvoice-heading">GST Invoice</Typography>
    //     <Box className="garageInvoce-logo">
    //       <Box className="Gativanlogo">
    //         <img
    //           src={
    //             process.env.PUBLIC_URL +
    //             "/Assets/logos/GativanFullLogoWhite.png"
    //           }
    //           alt=""
    //         />
    //       </Box>
    //     </Box>
    //   </Box>

    //   <Box className="garageInvoice-userDetails">
    //     <Box className="garageInvoice-userInfo">
    //       <Box className="userInfo-userDetail">
    //         <Typography className="garageInvoice-label">
    //           Mr/Miss. costomer name
    //         </Typography>
    //         <Typography className="garageInvoice-field">
    //           Honda CBR 250RR
    //         </Typography>
    //         <Typography className="garageInvoice-field">
    //           MH 12 SP 8055
    //         </Typography>
    //         <Typography className="garageInvoice-label">
    //           Customer Contact
    //         </Typography>
    //         <Typography className="garageInvoice-field">1458866666</Typography>
    //         <Typography className="garageInvoice-label">
    //           Customer Address
    //         </Typography>
    //         <Typography className="garageInvoice-field">
    //           Customer Address
    //         </Typography>
    //       </Box>

    //       <Box className='userInfo-dateDetail'>
    //       <Typography className="garageInvoice-label">Order Date : </Typography>
    //       <Typography className="garageInvoice-field">05-01-2000</Typography>
    //       <Typography className="garageInvoice-label">Invoice Date : </Typography>
    //       <Typography className="garageInvoice-field">22-05-2005 </Typography>
    //       </Box>
    //     </Box>
    //     <Box className="garageInvoice-billingInfo"></Box>
    //   </Box>
    // </Box>
  );
}
