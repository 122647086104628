import testHttp from "../../http-common"; 

// Get Vendors List

export const getVendorsList = () => {
   return testHttp.get("/vendors");
}


export const addNewVendor = (vendorDetails) => {
    return testHttp.post("/vendors", vendorDetails);
}

export const getMaterialLib = () => {
    return testHttp.get("/products");
}

export const addMaterialLib = (materialDetails) => {
    return testHttp.post("/products", materialDetails);
}

export const getBrands = () => {
    return testHttp.get("/productBrand");
}

export const addBrand = (brandDetails) => {
    return testHttp.post("/productBrand", brandDetails);
}

export const saveInvoice = (invoiceDetails) => {
    return testHttp.post("/vendorInvoice", invoiceDetails);
}