import React, { useContext, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./DatePicker.css";
import moment from "moment";
import { DashboardContext } from "../../modules/dashboard/MainDashboard";
import { CommonContextAPI } from "../../App";
import { Box, Button, Modal, Typography } from "@mui/material";

export default function MonthPicker() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // GET CONTEXT COMMON DATA
  const commonData = useContext(CommonContextAPI);

  const {
    setFilteredDate,
    openDatePicker,
    filterDateRenge,
    setApplyFilter,
    handleCloseMonthPicker,
    handleOpenMonthPicker,
    selectedMonth,
    setSelectedMonth,
  } = commonData;

  const style = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 300,
    bgcolor: "background.paper",
    boxShadow: 20,
    p: isMobile ? 2 : 4,
    borderRadius: "8px",
    position: "absolute",
    m: isMobile ? 0 : 1,
    height: isMobile ? "90%" : 200,
    overflow: isMobile ? "auto" : "none",
    border: "none !important",
  };
  return (
    <Modal
      open={true}
      onClose={handleCloseMonthPicker}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box style={{ margin: "0rem" }}>
          <Box
            style={{
              position: "absolute",
              top: 0,
              right: "10px",
              margin: "0.2rem 0px",
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseMonthPicker}
            />
          </Box>
          <Box sx={{}}>
            <Typography variant="h5">Month Picker</Typography>
          </Box>
        </Box>
        <Box sx={{ margin: "1rem 0px" }}>
          <DatePicker
            fullwidth
            sx={{ width: "100%" }}
            className="picker"
            placeholderText="Select Month"
            selected={selectedMonth}
            onChange={(date) => setSelectedMonth(date)}
            dateFormat="MMM-yyyy"
            showMonthYearPicker
          />
        </Box>
        <Box>
          <Button
            size="small"
            variant="contained"
            onClick={handleCloseMonthPicker}
          >
            Select
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
