import { Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAllDeadReasons } from "../../services/Services";
import { padding } from "@mui/system";

export default function ResonModal({
  isReasonModal,
  setIsReasonModal,
  setReasonId,
}) {
  const [Reasons, setReasons] = useState([]);
  const [isHover, setIsHover] = useState();

  const handleDiscard = async () => {
    await setReasonId();
    setIsReasonModal(false);
  };

  const handleConfirm = () => {
    setIsReasonModal(false);
  };

  const handleClose = () => {
    setIsReasonModal(false);
  };

  const getReasons = async () => {
    try {
      const request = await getAllDeadReasons();
      const response = await request.data;
      setReasons(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReasons();
  }, []);
  return (
    <Modal
      open={isReasonModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <div
        style={{
          backgroundColor: "white",
          width: "350px",
          position: "absolute",
          left: "50%",
          top: "50%    ",
          transform: "translate(-50%, -50%)",
          borderRadius: "6px",
          padding: "10px 0px",
        }}
      >
        <h3 style={{ borderBottom: "1px solid  #C4C4C4", padding: "10px",fontSize:'20px',fontWeight:'800' }}>
          Select Reason
        </h3>
        {Reasons?.map((item) => {
          return (
            <div
              key={item.id}
              style={{
                borderBottom: "1px solid #C4C4C4",
                padding: "10px 5px",
                margin: "0px 10px",
                cursor: "pointer",
                backgroundColor: item.id === isHover ? " #C4C4C4" : "",
              }}
            >
              <h4
                onClick={() => {
                  setReasonId(item.id);
                  setIsHover(item.id);
                }}
              >
                {item.reason}
              </h4>
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "space-around",
          }}
        >
          <Button className="ins-modal-bottom-btns" onClick={handleDiscard}>
            Discard
          </Button>
          <Button className="ins-modal-bottom-btns" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}
