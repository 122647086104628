import React, { useContext, useEffect, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import Carousel from "react-multi-carousel";
import "./GoalAndReview.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CommonContextAPI } from "../../../App";
import moment from "moment";
import { getSaWiseSales, getServicesSales } from "../../../services/dashboard/DashboardServices";
import LoaderSpinner from "../../loading/LoaderSpinner";
import { Box, Typography } from "@mui/material";
import profile from "./profile.png"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const width = window.innerWidth;
var options = {
  responsive: true,
  // cutout: 50,
  cutout: width <= 1366 ? 40 : 50,
};
const data = {
  // labels: ["Red", "Blue"],
  datasets: [
    {
      // label: "# of Votes",
      data: [600000, 1000000],
      backgroundColor: ["rgba(54, 162, 235, 0.2)", "#122164"],
      borderColor: ["rgba(54, 162, 235, 1)", "#122164"],
      borderWidth: 0,
      borderRadius: 50,
      spacing: 5,
    },
  ],
};

// TOGGLE BETWEEN VIEW MORE AND VIEW LESS
// const handelViewMore = () => {
//   if (servicesSales?.length > 0) {
//     setviewMore((prev) => !prev);
//     if (!viewMore) {
//       setSortedServicesSales(servicesSales);
//     } else {
//       const services = servicesSales?.slice(0, 5);
//       setSortedServicesSales(services);
//     }
//   } else {
//     alert("No record to show!");
//   }
// };

// const getDataUsingFilters = (e) => {
//   const value = e.target.value;

//   const currentDate = moment();

//   const week = [
//     {
//       startDate: currentDate.clone().startOf("week").format("YYYY-MM-DD"),
//       endDate: currentDate.clone().endOf("week").format("YYYY-MM-DD"),
//     },
//   ];

//   const month = [
//     {
//       startDate: currentDate.clone().startOf("month").format("YYYY-MM-DD"),
//       endDate: currentDate.clone().endOf("month").format("YYYY-MM-DD"),
//     },
//   ];

//   const year = [
//     {
//       startDate: currentDate.clone().startOf("year").format("YYYY-MM-DD"),
//       endDate: currentDate.clone().endOf("year").format("YYYY-MM-DD"),
//     },
//   ];

//   let date;
//   switch (value) {
//     case "Today":
//       date = [
//         {
//           startDate: new Date(),
//           endDate: new Date(),
//         },
//       ];
//       break;

//     case "Week":
//       date = week;
//       break;

//     case "Month":
//       date = month;
//       break;
//     case "Year":
//       date = year;
//       break;

//     default:
//       date = filteredDate;
//   }

//   setviewMore(false);
//   setIsLoading(true);
//   getSericesSales(date);
// };



export default function GoalAndReview() {
  const [viewMore, setviewMore] = useState(false);
  const [servicesSales, setServicesSales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const commonData = useContext(CommonContextAPI);
  const [sortedServicesSales, setSortedServicesSales] = useState([]);
  const { filteredDate, defaultDate } = commonData;




  // GET SERVICES SALES
  const getSericesSales = async (filteredDate) => {
    const { jwtToken } = JSON.parse(localStorage.getItem("loginDetails"));

    let startDate;
    let endDate;

    if (filteredDate) {
      startDate =
        filteredDate && moment(filteredDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        filteredDate && moment(filteredDate[0].endDate).format("YYYY-MM-DD");
    } else {
      startDate =
        defaultDate && moment(defaultDate[0].startDate).format("YYYY-MM-DD");
      endDate =
        defaultDate && moment(defaultDate[0].endDate).format("YYYY-MM-DD");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      params: {
        from: startDate,
        to: endDate,
      },
    };
    try {
      const response = await getSaWiseSales(config);
      const data = await response.data;
      const result = data?.result?.sort((a, b) => b.sales - a.sales);
      setServicesSales(result);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  // DISPLAY 5 RECORDS ON FIRST RENDER
  useMemo(() => {
    const services = servicesSales?.slice(0, 5);
    setSortedServicesSales(services);
  }, [servicesSales]);

  // CALL SALES SERVICE API ON FILTERED DATE CHANGE OR FIRST TIME ON RENDER
  useEffect(() => {
    if (filteredDate || defaultDate) {
      setServicesSales([]);
      getSericesSales(filteredDate);
      setIsLoading(true);
    }
    // console.log("filteredDate", filteredDate);
  }, [filteredDate, defaultDate]);

  // TOGGLE BETWEEN VIEW MORE AND VIEW LESS
  const handelViewMore = () => {
    if (servicesSales?.length > 0) {
      setviewMore((prev) => !prev);
      if (!viewMore) {
        setSortedServicesSales(servicesSales);
      } else {
        const services = servicesSales?.slice(0, 5);
        setSortedServicesSales(services);
      }
    } else {
      alert("No record to show!");
    }
  };

  const getDataUsingFilters = (e) => {
    const value = e.target.value;

    const currentDate = moment();

    const week = [
      {
        startDate: currentDate.clone().startOf("week").format("YYYY-MM-DD"),
        endDate: currentDate.clone().endOf("week").format("YYYY-MM-DD"),
      },
    ];

    const month = [
      {
        startDate: currentDate.clone().startOf("month").format("YYYY-MM-DD"),
        endDate: currentDate.clone().endOf("month").format("YYYY-MM-DD"),
      },
    ];

    const year = [
      {
        startDate: currentDate.clone().startOf("year").format("YYYY-MM-DD"),
        endDate: currentDate.clone().endOf("year").format("YYYY-MM-DD"),
      },
    ];

    let date;
    switch (value) {
      case "Today":
        date = [
          {
            startDate: new Date(),
            endDate: new Date(),
          },
        ];
        break;

      case "Week":
        date = week;
        break;

      case "Month":
        date = month;
        break;
      case "Year":
        date = year;
        break;

      default:
        date = filteredDate;
    }

    setviewMore(false);
    setIsLoading(true);
    getSericesSales(date);
  };
  const ReviewData = [
    {
      id: 1,
      fName: "Shailesh",
      lName: "Jain",
      rating: 4,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse diam ultrices sollicitudin sapien neque lectus ullamcorper pharetra. Congue interdum sit placerat diam nunc metus nulla lectus et.",
    },
    {
      id: 2,
      fName: "Madhav",
      lName: "Jadhav",
      rating: 4.5,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse diam ultrices sollicitudin sapien neque lectus ullamcorper pharetra. Congue interdum sit placerat diam nunc metus nulla lectus et.",
    },
  ];
  return (
    <div>
      <div className="goalSection">
        <h5 className="header-section">Goal Overview</h5>
        <div className="tableAndChart-container">
          <div className="table">
            <div className="top-section">
              <h6>Sales Actual</h6>
            </div>
            <div className="top-section">
              <h6>600000</h6>
            </div>
            <div className="bottom-section">
              <h6>Target</h6>
            </div>
            <div className="bottom-section">
              <h6>1000000</h6>
            </div>
          </div>
          <div className="chartsection">
            <Doughnut data={data} options={options} />
          </div>
        </div>
      </div>

 <div className="serviceSale-section">
      <div className="sale-header">
        <h6 className="sale-title">SA Wise Sales</h6>
        <div className="">
        <select className="selectionButton" onChange={getDataUsingFilters}>
            <option value="">Filter</option>
            <option value="Today">Today</option>
            <option value="Week">Week</option>
            <option value="Month">Month</option>
            <option value="Year">Year</option>
          </select>
        </div>
      </div>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <div className="sele-service-section" >
          <div style={{ height: "140px", overflow: "auto" }}>
            {sortedServicesSales?.length > 0 ? (
              sortedServicesSales?.map((item) => {
                const {
                  serviceAdvisorID,
                  serviceAdvisor,
                  sales,
                  ordercount
                  
                } = item;
                return (
                  <div className="sa-servicecard" key={serviceAdvisorID}>


<div className="sa-image">
  <img
    src={profile}
    alt={profile}
    style={{ width: "100%", height: "100%" }}
  />
</div>

{/* 
<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  
  <h4 className="sa-service-name">
    {serviceAdvisor}
  </h4>

  <div className="sa-price-section">
    <p className="saorderValue">
      <CurrencyRupeeIcon className="rupeeIcon" />
      {sales}
    </p>
  </div>
</div> */}





<div>
                      <h4 className="sale-service-name">
                        {serviceAdvisor}
                      </h4>
                      <div className="orders-price-section">
                        <p>
                          {ordercount}
                 
                        </p>
                        <p className="orderValue">
                          {" "}
                          <CurrencyRupeeIcon className="rupeeIcon" />
                          {sales}
                        </p>
                      </div>
                    </div>
                  </div>
    




             
                );
              })
            ) : (
              <Box>
                <Typography variant="h6">SA Wise Sales not found.</Typography>
              </Box>
            )}
          </div>
          {sortedServicesSales?.length > 1 && (
            <div className="view-more-section">
              {viewMore ? (
                <h6 onClick={handelViewMore}>
                  View Less <ExpandLessIcon className="ExpandIcon" />
                </h6>
              ) : (
                <h6 onClick={handelViewMore}>
                  View More
                  <ExpandMoreIcon className="ExpandIcon" />
                </h6>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  





    </div>
  );
}
