import testHttp from "../../http-common";

//get Garage list
export const getGarages = () => {
  return testHttp.get("/garage");
};

//Add Garage
export const addGarage = (garageDetails, config) => {
  return testHttp.post("/garage", garageDetails, config);
};

export const editGarage = (garageDetails, config) => {
  return testHttp.put("/garage/update", garageDetails, config);
};

export const getGaragePayout = (from, to, level, config, groupData) => {
  return groupData
    ? testHttp.get(
        `/payouts?from=${from}&to=${to}&${level}&groupData=${groupData}`,
        config
      )
    : testHttp.get(`/payouts?from=${from}&to=${to}&${level}`, config);
};

export const getAllGaragePayouts = (level, config) => {
  return testHttp.get(`/payouts?all=true&${level}`, config);
};

export const getGaragePayoutStatus = (config) => {
  return testHttp.get("/payoutStatus", config);
};

export const updateGaragePayout = (updateObj, config) => {
  console.log("updateObj", updateObj);
  return testHttp.put("/payouts", updateObj, config);
};

export const getUPIByGarageId = (Id, config) => {
  return testHttp.get(`/upiAccounts/${Id}`, config);
};

export const getBankAccountByGarageId = (Id, config) => {
  return testHttp.get(`/bankAccounts/${Id}`, config);
};

export const addGarageBankAccountDetails = (accountDetails, config) => {
  return testHttp.post("/bankAccounts", accountDetails, config);
};

export const addGarageUpiAccount = (upiAccount, config) => {
  return testHttp.post("/upiAccounts", upiAccount, config);
};

export const getGarageBankAccountDetails = (garageId, config) => {
  return testHttp.get(`/bankAccounts/${garageId}`, config);
};

export const getGarageUPIAccountDetails = (garageId, config) => {
  return testHttp.get(`/upiAccounts/${garageId}`, config);
};

export const getCMSReport = (startDate, endDate, groupData, config) => {
  return testHttp.get(
    `/payouts/cmsReport?dateRange=true&from=${startDate}&to=${endDate}&groupData=${groupData}`,
    config
  );
};

export const getOrdersByGarageId = (garageId, config) => {
  return testHttp.get(`/garage/ordersByGarageId/${garageId}`, config);
};

export const updatePayoutCompletedDate = (id, value, config) => {
  return testHttp.put(`/payouts/date/${id}?newDate=${value}`, config);
};

export const garageTypesList = () => {
  return testHttp.get("/garage/types");
};

export const garageById = (garageId) => {
  return testHttp.get(`/garage/${garageId}`);
};

export const getGarageReceivables = (config) => {
  return testHttp.get("/garageReceivables", config);
};
