import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

const AdminConsole = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname == "/adminConsole") navigate("/adminConsole/dashboard");
  }, [, window.location.href]);

  return (
    <Box>
      <Outlet />
    </Box>
  );
};

export default AdminConsole;
