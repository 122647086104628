import testHttp from "../http-common";
export const getGarages = () => {
  return testHttp.get("/garage");
};
export const getGativanRepresentatives = () => {
  return testHttp.get("/user/representatives");
};
export const getCustomerAdvisor = () => {
  return testHttp.get("/user/serviceAdvisor");
};
export const getOrdersStatus = () => {
  return testHttp.get("/orderStatus");
};

export const putOrderStatus = (bookingId, statusId, reasonId) => {
  return testHttp.put(
    `/orders/updateStatus/${bookingId}/${statusId}${
      reasonId ? "?reason=" + reasonId : ""
    }`
  );
};
export const getOrderById = (orderId) => {
  return testHttp.get(`/orders/orderbyid/${orderId}`);
};
export const getBrandsByVehicle = (vehicleId) => {
  return testHttp.get(`/brand/${vehicleId}`);
};
export const getModelByVehicleAndBrand = (vehicleId, brandId) => {
  return testHttp.get(`/models/${vehicleId}/${brandId}`);
};
export const getVariants = (modelId, vehicleId) => {
  return testHttp.get(`/variant/${modelId}`);
};
export const getCatagoriesByVehicle = (vehicleId) => {
  return testHttp.get(`/serviceCategory/${vehicleId}`);
};
export const getServices = (catagoryId, modelId, variantId) => {
  return testHttp.get(
    `/serviceSubCategory/${catagoryId}/${modelId}/${variantId}`
  );
};
export const putOrder = (putObject) => {
  return testHttp.put(`/orders`, putObject);
};
export const deleteService = (bookingId) => {
  return testHttp.delete(`/bookedService/${bookingId}`);
};
export const deleteNonCatalogue = (Id) => {
  return testHttp.delete(`/nonCatalogueServices/delete/${Id}`);
};
export const putUserName = (data) => {
  return testHttp.put(`/user`, data);
};
export const getAllProducts = () => {
  return testHttp.get(`/products`);
};
export const postServiceType = (updateObj) => {
  return testHttp.get(`/serviceTypes`, updateObj);
};
export const getAllServiceTypes = () => {
  return testHttp.get(`/serviceTypes`);
};
export const getServiceTypeById = (id) => {
  return testHttp.get(`/serviceTypes/${id}`);
};
export const postInsuranceCompany = (updateObj) => {
  return testHttp.post(`/insuranceCompanies`, updateObj);
};
export const getAllInsuranceCompanies = () => {
  return testHttp.get("/insuranceCompanies");
};

export const getInsuranceCompaniesById = (id) => {
  return testHttp.get(`/insuranceCompanies/${id}`);
};
export const updateInsuranceCompany = (updateObj) => {
  return testHttp.put(`/insuranceCompanies`, updateObj);
};

export const getBrokersList = () => {
  return testHttp.get("/brokers");
};

export const postBroker = (details) => {
  return testHttp.post("/brokers", details);
};

export const getBrokerById = (id) => {
  return testHttp.get(`/brokers/${id}`);
};
export const updateBroker = (updateObj) => {
  return testHttp.put(`/brokers`, updateObj);
};

export const deleteBrokerInsuranceCompany = (brokerId, companyId) => {
  return testHttp.delete(`brokers/${brokerId}/${companyId}`);
};

export const postInsuranceType = (object) => {
  return testHttp.post("/insuranceTypes", object);
};
export const getAllInsuranceType = () => {
  return testHttp.get("/insuranceTypes");
};
export const getInsuranceTypeById = (id) => {
  return testHttp.get(`/insuranceTypes/${id}`);
};
export const PatchOdometerReading = (orderId, reading) => {
  return testHttp.patch(`/orderInventory/odometer/${orderId}/${reading}`);
};

export const getAllDeadReasons = () => {
  return testHttp.get(`/deadReasons`);
};
