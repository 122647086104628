import React, { useContext, useEffect, useMemo, useState } from "react";
import "./InsuranceTable.css";
import { Divider, Modal, Typography } from "@mui/material";
import { height } from "@mui/system";

import AddressModel from "./addressModel/AddressModel";
import {
  getBrandsByVehicle,
  getModelByVehicleAndBrand,
  getVariants,
} from "../../../services/Services";
import { jobCardContext } from "../OrderDetails";
import { Launch } from "@mui/icons-material";

export default function InsuranceTable({ orderDetails, header }) {
  const [open, setOpen] = React.useState(false);
  const [addressOpen, setAddressOpen] = React.useState(false);

  const [vehicleModelData, setVehicleModelData] = useState([]);
  const [estimate, setEstimate] = useState();
  const [vehicle, setVehicle] = useState();
  const [updateVehicleEst, setUpdateVehicleEst] = useState();
  const [sellerInfo, setSellerInfo] = useState();
  const [user, setUser] = useState();
  const [newUser, setNewUser] = useState();
  const [userAddress, setUserAddress] = useState();
  const [nonCatalogueJobs, setNonCatalogueJobs] = useState([]);

  const [isEstimate, setIsEstimate] = useState(false);
  const [estimateDiscountObj, setEstimateDiscountObj] = useState({});

  const context = useContext(jobCardContext);

  const {
    // orderDetails,
    nonCatalogueEstimate,
    updateUserName,
    setIsUpdateUser,
    setUpdateUserName,
    insuranceDetails,
    setInsuranceDetails,
    updateObj,
    setUpdateObj,
    updateVehicle,
    estimateDiscount,
    servicesList,
  } = context;

  useEffect(() => {
    const taxableAmount = estimateDiscount / 1.18;
    const cgst = (estimateDiscount - taxableAmount) / 2;
    const sgst = (estimateDiscount - taxableAmount) / 2;

    setEstimateDiscountObj({
      discount: -estimateDiscount,
      taxableAmount: -taxableAmount,
      cgstRate: -9,
      cgstAmount: -cgst,
      sgstRate: -9,
      sgstAmount: -sgst,
      total: -estimateDiscount,
    });
  }, [estimateDiscount]);

  useEffect(() => {
    setNonCatalogueJobs(nonCatalogueEstimate);
  }, [nonCatalogueEstimate]);

  useEffect(() => {
    if (header == "Estimate") {
      setIsEstimate(true);
    } else {
      setIsEstimate(false);
    }
  }, [, header]);

  useMemo(() => {
    isEstimate && setUserAddress(orderDetails?.address);
  }, [orderDetails, isEstimate]);

  useMemo(() => {
    isEstimate && setUser(updateUserName);
  }, [updateUserName, isEstimate]);

  //  update vehicle detailes
  useMemo(() => {
    vehicle &&
      setUpdateObj({
        ...updateObj,
        userVehicle: {
          vehicleType: vehicle?.vehicleType?.id,
          brand: vehicle?.brand?.brandId
            ? vehicle?.brand?.brandId
            : vehicle?.brand?.brand_Id,
          model: vehicle?.model?.model_id
            ? vehicle?.model?.model_id
            : vehicle?.model?.modelId,
          variant: vehicle?.variant?.variantId
            ? vehicle?.variant?.variantId
            : vehicle?.variant?.id,
          vehicleNumber: vehicle?.vehicleNumber,
          yearOfManufacture: vehicle?.yearOfManufacture,
        },
      });
  }, [vehicle]);

  useMemo(() => {
    let name = newUser?.name?.split(" ");
    let gstn = newUser?.gstn;
    name &&
      setUpdateUserName({
        ...updateUserName,
        firstName: name[0],
        lastName: name[1],
      });

    gstn &&
      setUpdateUserName({
        ...updateUserName,
        gstn: gstn,
      });
    if (name || gstn) {
      setIsUpdateUser(true);
    }
  }, [newUser]);

  useMemo(() => {
    isEstimate && setEstimate(orderDetails);
    isEstimate && setVehicle(orderDetails?.vehicle);
  }, [orderDetails, isEstimate]);

  const handleChangeBuyer = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleVehicle = (e) => {
    setUpdateVehicleEst({
      ...updateVehicleEst,
      [e.target.name]: e.target.value,
    });
  };

  useMemo(() => {
    if (insuranceDetails?.codeOfInsuranceId === 1) {
      setSellerInfo(orderDetails?.garage);
    } else {
      setSellerInfo({
        garageName: "K Studio Solutions Pvt. Ltd",
        gstn: "27AAGCK1784J1Z6",
        area: "Tower 2 World Trade center Kharadi ",
        city: "Pune",
        shopNumber: "Office No 802",
      });
    }
  }, [insuranceDetails, orderDetails]);

  const handleVehicleDetail = (data) => {
    if (data.brand) {
      setVehicle({ ...vehicle, brand: data });
    } else if (data.model) {
      setVehicle({ ...vehicle, model: data });
    } else {
      setVehicle({ ...vehicle, variant: data });
    }
    setOpen(false);
  };

  const getBrand = async () => {
    try {
      const request = await getBrandsByVehicle(vehicle?.vehicleType?.id);
      const response = await request.data;
      setVehicleModelData(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  const getModels = async () => {
    let bId = vehicle?.brand?.brandId
      ? vehicle?.brand?.brandId
      : vehicle?.brand?.brand_Id;
    try {
      const request = await getModelByVehicleAndBrand(
        vehicle?.vehicleType?.id,
        bId
      );
      const response = await request.data;
      setVehicleModelData(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getVariant = async () => {
    let mid = vehicle?.model?.model_id
      ? vehicle?.model?.model_id
      : vehicle?.model?.modelId;
    try {
      const request = await getVariants(mid);
      const response = await request.data;
      setVehicleModelData(response.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => setOpen(false);
  const handlAddressModelClose = () => {
    setAddressOpen(false);
  };

  // calculate grand total of non catalogues

  // Store grand total
  let grandTotal = 0;
  // Store gst total
  let grandGstTotal = 0;

  // the parts ,labour service types separates
  const Parts = nonCatalogueJobs?.filter((i) => i?.serviceType === "Parts");
  const Labour = nonCatalogueJobs?.filter((i) => i?.serviceType === "Labour");

  // calculate total amount of parts and labour
  let TotalParts = 0;
  let TotalLabours = 0;

  const vehicleModelHeading = `Select ${
    vehicleModelData?.[0]?.brand
      ? "Brand"
      : vehicleModelData?.[0]?.model
      ? "Model"
      : "Variant"
  }`;

  return (
    <div style={{ width: "100%", overflow: "auto", marginBottom: "15px" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <div
          style={{
            width: "30%",
            height: "50%",
            minHeight: "80px",
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            overflow: "auto",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">{vehicleModelHeading}</Typography>
            <Divider />
            {vehicleModelData?.map((item) => {
              let name;
              if (item.brand) {
                name = item.brand;
              } else if (item.model) {
                name = item.model;
              } else {
                name = item.variantName;
              }
              return (
                <h6
                  className="insurance-vehicle-item"
                  onClick={() => {
                    handleVehicleDetail(item);
                  }}
                >
                  {name}
                </h6>
              );
            })}
          </div>
        </div>
      </Modal>

      {addressOpen && (
        <AddressModel
          userAddress={userAddress}
          open={setAddressOpen}
          handleClose={handlAddressModelClose}
          updateObj={updateObj}
          setUpdateObj={setUpdateObj}
          setUserAddress={setUserAddress}
        />
      )}

      <div
        style={{ pointerEvents: header === "TAX INVOICE" ? "none" : "all" }}
        className="estimate-container"
      >
        <div>
          <div className="header-Logo">
            <img
              src={
                process.env.PUBLIC_URL + "/Assets/logos/GativanFullLogoBlue.png"
              }
              alt=""
            />
          </div>
          <table className="e-table">
            <thead>
              <tr>
                <th colSpan={4} className="e-table-header">
                  {header}
                </th>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{ width: "50%" }}
                  className="e-table-heading"
                >
                  BUYER DETAILS
                </th>
                {/* <th style={{ width: "30%" }} className="e-table-filed"></th> */}
                <th
                  colSpan={2}
                  style={{ width: "50%" }}
                  className="e-table-heading"
                >
                  {" "}
                  SELLER DETAILS
                </th>
                {/* <th style={{ width: "30%" }} className="e-table-filed"></th> */}
              </tr>
              <tr>
                <th className="e-table-heading">NAME:</th>
                <th className="e-table-filed">
                  {isEstimate ? (
                    user?.firstName !== undefined ? (
                      <input
                        type="text"
                        name="name"
                        defaultValue={user?.firstName + " " + user?.lastName}
                        className="e-table-inpute"
                        onChange={handleChangeBuyer}
                      />
                    ) : (
                      "loding..."
                    )
                  ) : (
                    orderDetails?.customerName
                  )}
                </th>
                <th className="e-table-heading">NAME:</th>
                <th className="e-table-filed">
                  {isEstimate
                    ? sellerInfo?.garageName
                    : orderDetails?.sellerName}
                </th>
              </tr>
              <tr>
                <th className="e-table-heading">GSTIN:</th>
                <th className="e-table-filed">
                  {isEstimate ? (
                    <input
                      type="text"
                      name="gstn"
                      defaultValue={user?.gstn}
                      className="e-table-inpute"
                      onChange={handleChangeBuyer}
                    />
                  ) : (
                    orderDetails?.customerGst
                  )}
                </th>
                <th className="e-table-heading">GSTIN:</th>
                <th className="e-table-filed">
                  {isEstimate ? sellerInfo?.gstn : orderDetails?.sellerGSTN}
                </th>
              </tr>
              <tr>
                <th className="e-table-heading">Address:</th>
                <th
                  className="e-table-filed"
                  onClick={() => setAddressOpen(true)}
                >
                  {isEstimate
                    ? `${userAddress?.houseNumber?.toLowerCase() || ""} ${
                        userAddress?.area?.toLowerCase() || ""
                      } ${userAddress?.city?.toLowerCase() || ""}`
                    : orderDetails?.customerAddress}
                </th>
                <th className="e-table-heading">Address:</th>
                <th className="e-table-filed">
                  {isEstimate
                    ? sellerInfo?.shopNumber +
                      " " +
                      sellerInfo?.area +
                      " " +
                      sellerInfo?.city
                    : orderDetails?.sellerAddress}
                </th>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  className="e-table-heading"
                  style={{ textAlign: "center" }}
                >
                  ORDER DETAILS
                </th>
              </tr>
              <tr>
                <th className="e-table-heading2">DATE:</th>
                <th className="e-table-filed">
                  {isEstimate
                    ? estimate?.arrivedAtWorkshopOn
                    : orderDetails?.invoiceDate}
                </th>
                <th className="e-table-heading2">Order Id:</th>
                <th className="e-table-filed">
                  {isEstimate ? estimate?.orderId : orderDetails?.orderId}
                </th>
              </tr>
              <tr>
                <th className="e-table-heading2">Year Of Manufacture:</th>
                <th className="e-table-filed">
                  {isEstimate ? (
                    <input
                      type="text"
                      defaultValue={vehicle?.yearOfManufacture}
                      className="e-table-inpute"
                      onChange={(e) => {
                        setVehicle({
                          ...vehicle,
                          yearOfManufacture: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    orderDetails?.yearOfManufacture
                  )}
                </th>
                <th className="e-table-heading2">Brand:</th>
                {isEstimate ? (
                  <th
                    className="e-table-filed"
                    onClick={async () => {
                      await getBrand();
                      setOpen(true);
                    }}
                  >
                    <input
                      type="text"
                      defaultValue={vehicle?.brand?.brand}
                      className="e-table-inpute"
                      name="brand"
                      onChange={handleVehicle}
                    />
                  </th>
                ) : (
                  <th className="e-table-filed">
                    {orderDetails?.vehicleBrand}
                  </th>
                )}
              </tr>
              <tr>
                <th className="e-table-heading2">ODOMETER READING:</th>
                <th className="e-table-filed">
                  {isEstimate ? (
                    <input
                      type="text"
                      className="e-table-inpute"
                      defaultValue={insuranceDetails?.odometer}
                      onChange={(e) =>
                        setInsuranceDetails({
                          ...insuranceDetails,
                          odometer: e.target.value,
                        })
                      }
                    />
                  ) : (
                    orderDetails?.odometer
                  )}
                </th>
                {isEstimate ? (
                  <>
                    <th className="e-table-heading2">Model:</th>
                    <th
                      className="e-table-filed"
                      onClick={async () => {
                        await getModels();
                        setOpen(true);
                      }}
                    >
                      <input
                        type="text"
                        defaultValue={vehicle?.model?.model}
                        className="e-table-inpute"
                        name="model"
                        onChange={handleVehicle}
                      />
                    </th>
                  </>
                ) : (
                  <>
                    <th className="e-table-heading2">Model:</th>
                    <th>
                      <tr>
                        <th className="e-table-filed" style={{ width: "40%" }}>
                          {orderDetails?.vehicleModel}
                        </th>
                        <th className="e-table-heading2">Variant:</th>
                        <th className="e-table-filed" style={{ width: "40%" }}>
                          {orderDetails?.vehicleVariant}
                        </th>
                      </tr>
                    </th>
                  </>
                )}
              </tr>
              <tr>
                <th className="e-table-heading2">SERVICE TYPE: </th>
                <th className="e-table-filed">Insurance Service</th>
                {isEstimate ? (
                  <>
                    <th className="e-table-heading2">VARIANT:</th>
                    <th
                      className="e-table-filed"
                      onClick={async () => {
                        await getVariant();
                        setOpen(true);
                      }}
                    >
                      <input
                        type="text"
                        defaultValue={vehicle?.variant?.variantName}
                        className="e-table-inpute"
                        name="variantName"
                        onChange={handleVehicle}
                      />
                    </th>
                  </>
                ) : (
                  <>
                    {" "}
                    <th className="e-table-heading2">VEHICLE NO:</th>
                    <th className="e-table-filed">
                      {isEstimate ? (
                        <input
                          type="text"
                          defaultValue={vehicle?.vehicleNumber}
                          className="e-table-inpute"
                          name="variantName"
                          onChange={(e) => {
                            setVehicle({
                              ...vehicle,
                              vehicleNumber: e.target.value,
                            });
                          }}
                        />
                      ) : (
                        orderDetails?.vehicleNumber
                      )}
                    </th>
                  </>
                )}
              </tr>
              <tr>
                <th className="e-table-heading2">CLAIM NO:</th>
                <th className="e-table-filed">
                  {isEstimate
                    ? insuranceDetails?.claimNo
                    : orderDetails?.claimNumber}
                </th>
                {isEstimate ? (
                  <>
                    <th className="e-table-heading2">VEHICLE NO:</th>
                    <th className="e-table-filed">
                      <input
                        type="text"
                        defaultValue={vehicle?.vehicleNumber}
                        className="e-table-inpute"
                        name="variantName"
                        onChange={(e) => {
                          setVehicle({
                            ...vehicle,
                            vehicleNumber: e.target.value,
                          });
                        }}
                      />
                    </th>
                  </>
                ) : (
                  <>
                    <th className="e-table-heading2">Invoice NO:</th>
                    <th className="e-table-filed">{orderDetails?.invoiceId}</th>
                  </>
                )}
              </tr>
            </thead>
          </table>

          <table className="estimate-service-table">
            <thead>
              <tr>
                <th rowSpan={2} className="e-table-heading">
                  Sr. No.
                </th>
                <th rowSpan={2} className="e-table-heading">
                  Description
                </th>
                <th rowSpan={2} className="e-table-heading">
                  HSN/SAC
                </th>
                <th rowSpan={2} className="e-table-heading">
                  Units
                </th>
                <th rowSpan={2} className="e-table-heading">
                  Discount
                </th>
                <th
                  rowSpan={2}
                  style={{ width: "10%" }}
                  className="e-table-heading"
                >
                  Taxable Amt.
                </th>
                <th colSpan={2} className="e-table-heading">
                  CGST
                </th>
                <th colSpan={2} className="e-table-heading">
                  SGST
                </th>
                <th rowSpan={2} className="e-table-heading">
                  Amount
                </th>
              </tr>
              <tr>
                <th className="e-table-heading">Rate</th>
                <th className="e-table-heading">Amt.</th>
                <th className="e-table-heading">Rate</th>
                <th className="e-table-heading">Amt.</th>
              </tr>
            </thead>
            <tbody>
              {isEstimate ? (
                <>
                  {Parts?.length > 0 && (
                    <>
                      <tr>
                        <th className="e-table-heading">A</th>
                        <th
                          colSpan={10}
                          className="e-table-heading"
                          style={{ textAlign: "center" }}
                        >
                          Parts
                        </th>
                      </tr>
                      {Parts?.map((ele, index) => {
                        const {
                          name,
                          hsn,
                          gst,
                          price,
                          quantity,
                          serviceType,
                          discount,
                        } = ele;
                        let taxableAmount = price / `1.${gst}`;
                        let totalGst = price - taxableAmount;
                        TotalParts += taxableAmount;
                        grandTotal += price;

                        grandGstTotal += totalGst;

                        return (
                          <tr>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {index + 1}
                            </td>
                            <td className="e-table-filed">{name}</td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {hsn}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {quantity}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {discount?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {taxableAmount?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gst / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {(totalGst / 2)?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gst / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {(totalGst / 2).toFixed(2)}{" "}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {price?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan={11} style={{ height: "5px" }}></th>
                      </tr>
                    </>
                  )}
                  {Labour?.length > 0 && (
                    <>
                      <tr>
                        <th
                          className="e-table-heading"
                          style={{ margin: "auto" }}
                        >
                          B
                        </th>
                        <th
                          colSpan={10}
                          className="e-table-heading"
                          style={{ textAlign: "center" }}
                        >
                          Labour
                        </th>
                      </tr>
                      {Labour?.map((ele, index) => {
                        const {
                          name,
                          hsn,
                          gst,
                          price,
                          quantity,
                          serviceType,
                          discount,
                        } = ele;
                        let taxableAmount = price / `1.${gst}`;
                        let totalGst = price - taxableAmount;
                        let srn = Parts?.length;
                        TotalLabours += taxableAmount;
                        grandTotal += price;
                        grandGstTotal += totalGst;
                        return (
                          <tr>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {srn + index + 1}
                            </td>
                            <td className="e-table-filed">{name}</td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {hsn}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {quantity}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {discount}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {taxableAmount?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gst / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {(totalGst / 2)?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gst / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {(totalGst / 2)?.toFixed(2)}{" "}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {price?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                        <th colSpan={11} style={{ height: "5px" }}></th>
                      </tr> */}
                    </>
                  )}

                  {servicesList?.length > 0 && (
                    <>
                      {Labour?.length == 0 && (
                        <tr>
                          <th
                            className="e-table-heading"
                            style={{ margin: "auto" }}
                          >
                            B
                          </th>
                          <th
                            colSpan={10}
                            className="e-table-heading"
                            style={{ textAlign: "center" }}
                          >
                            Labour
                          </th>
                        </tr>
                      )}

                      {servicesList?.map((ele, index) => {
                        const {
                          service,
                          hsn,
                          gst,
                          displayPrice,
                          quantity,
                          serviceType,
                          discount,
                        } = ele;
                        let taxableAmount = displayPrice / `1.${gst}`;

                        let totalGst = displayPrice - taxableAmount;
                        let srn = Parts?.length + Labour?.length;
                        TotalLabours += taxableAmount;
                        grandTotal += displayPrice;
                        grandGstTotal += totalGst;
                        return (
                          <tr>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {srn + index + 1}
                            </td>
                            <td className="e-table-filed">{service}</td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {hsn}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {quantity ? quantity : 1}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {discount > 0 || 0}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {taxableAmount?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gst / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {(totalGst / 2)?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gst / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {(totalGst / 2)?.toFixed(2)}{" "}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {displayPrice?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan={11} style={{ height: "5px" }}></th>
                      </tr>
                    </>
                  )}

                  {Math.abs(estimateDiscountObj?.discount) > 0 && (
                    <tr>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "center" }}
                      >
                        {Parts?.length +
                          Labour?.length +
                          servicesList?.length +
                          1}
                      </td>
                      <td className="e-table-filed">Discount</td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "center" }}
                      ></td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "center" }}
                      >
                        1
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "end" }}
                      >
                        {estimateDiscountObj?.discount?.toFixed(2)}
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "end" }}
                      >
                        {estimateDiscountObj?.taxableAmount?.toFixed(2)}
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "center" }}
                      >
                        {estimateDiscountObj?.cgstRate}%
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "end" }}
                      >
                        {estimateDiscountObj?.cgstAmount?.toFixed(2)}
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "center" }}
                      >
                        {estimateDiscountObj?.sgstRate}%
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "end" }}
                      >
                        {estimateDiscountObj?.sgstAmount?.toFixed(2)}
                      </td>
                      <td
                        className="e-table-filed"
                        style={{ textAlign: "end" }}
                      >
                        {estimateDiscountObj?.total?.toFixed(2)}
                      </td>
                    </tr>
                  )}
                </>
              ) : (
                <>
                  {orderDetails?.parts?.length > 0 && (
                    <>
                      <tr>
                        <th className="e-table-heading">A</th>
                        <th
                          colSpan={10}
                          className="e-table-heading"
                          style={{ textAlign: "center" }}
                        >
                          Parts
                        </th>
                      </tr>
                      {orderDetails?.parts?.map((ele, index) => {
                        const {
                          discount,
                          gstAmount,
                          gstRate,
                          hsn,
                          id,
                          invoiceid,
                          price,
                          qty,
                          service,
                          taxableAmount,
                          total,
                        } = ele;

                        return (
                          <tr>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {index + 1}
                            </td>
                            <td className="e-table-filed">{service}</td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {hsn}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {qty}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {discount}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {taxableAmount?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gstRate / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {gstAmount.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gstRate / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {gstAmount.toFixed(2)}{" "}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {total?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan={11} style={{ height: "5px" }}></th>
                      </tr>
                    </>
                  )}
                  {orderDetails?.labour?.length > 0 && (
                    <>
                      <tr>
                        <th className="e-table-heading">B</th>
                        <th
                          colSpan={10}
                          className="e-table-heading"
                          style={{ textAlign: "center" }}
                        >
                          Labour
                        </th>
                      </tr>
                      {orderDetails?.labour?.map((ele, index) => {
                        const {
                          discount,
                          gstAmount,
                          gstRate,
                          hsn,
                          id,
                          invoiceid,
                          price,
                          qty,
                          service,
                          taxableAmount,
                          total,
                        } = ele;

                        return (
                          <tr>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {index + 1}
                            </td>
                            <td className="e-table-filed">{service}</td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {hsn}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {qty}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {discount}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {taxableAmount?.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gstRate / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {gstAmount.toFixed(2)}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "center" }}
                            >
                              {gstRate / 2}%
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {gstAmount.toFixed(2)}{" "}
                            </td>
                            <td
                              className="e-table-filed"
                              style={{ textAlign: "end" }}
                            >
                              {total?.toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan={11} style={{ height: "5px" }}></th>
                      </tr>
                    </>
                  )}

                  {orderDetails?.items?.length > 0 &&
                    orderDetails?.items?.map((item, index) => {
                      return (
                        <tr>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "center" }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {item?.service}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "center" }}
                          ></td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "center" }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "end" }}
                          >
                            {item?.discount?.toFixed(2)}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "end" }}
                          >
                            {item?.taxableAmount?.toFixed(2)}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "center" }}
                          >
                            {item?.gstRate / 2}%
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "end" }}
                          >
                            {item?.gstAmount?.toFixed(2)}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "center" }}
                          >
                            {item?.gstRate / 2}%
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "end" }}
                          >
                            {item?.gstAmount.toFixed(2)}
                          </td>
                          <td
                            className="e-table-filed"
                            style={{ textAlign: "end" }}
                          >
                            {item?.total?.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th className="e-table-heading">PARTS SUBTOTAL </th>
                <th className="e-table-heading">LABOUR SUBTOTAL</th>
                <th className="e-table-heading">TOTAL CGST </th>
                <th className="e-table-heading">TOTAL SGST </th>
                <th className="e-table-heading">GRAND TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="e-table-filed" style={{ textAlign: "center" }}>
                  {isEstimate
                    ? TotalParts?.toFixed(2)
                    : orderDetails?.partsTaxableSubtotal?.toFixed(2)}
                </td>
                <td className="e-table-filed" style={{ textAlign: "center" }}>
                  {isEstimate
                    ? TotalLabours?.toFixed(2)
                    : orderDetails?.labourTaxableSubtotal?.toFixed(2)}
                </td>
                <td className="e-table-filed" style={{ textAlign: "center" }}>
                  {isEstimate
                    ? (
                        grandGstTotal / 2 -
                        Math.abs(estimateDiscountObj?.cgstAmount)
                      )?.toFixed(2)
                    : orderDetails?.cgst?.toFixed(2)}
                </td>
                <td className="e-table-filed" style={{ textAlign: "center" }}>
                  {isEstimate
                    ? (
                        grandGstTotal / 2 -
                        Math.abs(estimateDiscountObj?.sgstAmount)
                      )?.toFixed(2)
                    : orderDetails?.sgst?.toFixed(2)}
                </td>
                <td className="e-table-filed" style={{ textAlign: "center" }}>
                  {isEstimate
                    ? estimateDiscount
                      ? (grandTotal - estimateDiscount)?.toFixed(2)
                      : grandTotal?.toFixed(2)
                    : orderDetails?.totalAmount?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table>
          <tr>
            <th style={{ width: "50%" }}>
              <div
                style={{ textAlign: "start", height: "100%", padding: "10px" }}
              >
                <h5
                  className="titles"
                  style={{ fontSize: "10px", margin: "10px 0px" }}
                >
                  BANK DETAILS:
                </h5>
                <p style={{ fontSize: "12px" }}>
                  BANKING NAME: K Studio Solutions Pvt. Ltd.
                </p>
                <p style={{ fontSize: "12px" }}> BANK : KOTAK MAHINDRA BANK</p>
                <p style={{ fontSize: "12px" }}> ACCOUNT NO : 9412238971</p>
                <p style={{ fontSize: "12px" }}>IFSC : KKBK0001758</p>
              </div>
            </th>
            <th className="e-footer">
              <p className="ef-author">Authorised Signatory</p>
            </th>
          </tr>
        </table>
      </div>
    </div>
  );
}
