import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import Store from "./Store";
import Estimate from "./components/pdf/insurance/estimate";



const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
  <Provider store={Store}>
    <App />
    
  </Provider>
  // </StrictMode>
);
