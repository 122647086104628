import React, { createContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import Login from "./auth/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import useCommonDataHook from "./hooks/useCommonDataHook";

const queryClient = new QueryClient();

export const CommonContextAPI = createContext();

function App() {
  const { shareCommonData } = useCommonDataHook();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <CommonContextAPI.Provider value={shareCommonData}>
          <Login />
        </CommonContextAPI.Provider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
