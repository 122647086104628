import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import fontURL1 from "../../../assets/fonts/AvenirNextLTPro-Bold.otf";
import fontURL2 from "../../../assets/fonts/AvenirNextLTPro-Regular.otf";
import fontURL3 from "../../../assets/fonts/AvenirNextLTPro-Demi.otf";

Font.register({ family: "AvenirBold", src: fontURL1 });
Font.register({ family: "AvenirRegular", src: fontURL2 });
Font.register({ family: "AvenirDemi", src: fontURL3 });

const borderColor = "#6a7086";
const styles = StyleSheet.create({
  tableHeader: {
    flexDirection: "row",
    borderColor: borderColor,
    borderTopWidth: 1,
    borderBottomWidth: "0.5px",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    height: "25px",
    // flexGrow: 1,
    fontSize: "9px",
  },
  service: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: "5px",
  },
  hsn: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: "5px",
  },
  qty: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: "5px",
  },
  price: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: "5px",
  },
  discount: {
    width: "8%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: "5px",
  },
  taxableAmount: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    height: "100%",
    textAlign: "center",
    justifyContent: "center",
  },
  cgstSection: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    justifyContent: "center",
    height: "100%",
  },
  cgst: {
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    // fontSize: "8px",
    height: "50%",
    justifyContent: "center",
  },
  cgst_bottomSec: {
    flexDirection: "row",
    height: "50%",
  },
  cgst_Rate: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // fontSize: "8px",
    width: "40%",
    height: "100%",
    justifyContent: "center",
  },
  cgst_Amount: {
    // fontSize: "8px",
    width: "60%",
    height: "100%",
    justifyContent: "center",
  },
  total: {
    width: "9%",
    // fontSize: "8px",
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: "5px",
  },
});

export default function Tableheader() {
  return (
    <View style={styles.tableHeader}>
      <View style={styles.service}>
        <Text>Service</Text>
      </View>
      <View style={styles.hsn}>
        <Text>HSN Code</Text>
      </View>
      <View style={styles.qty}>
        <Text>Qty</Text>
      </View>
      <View style={styles.price}>
        <Text>Price</Text>
      </View>
      <View style={styles.discount}>
        <Text>Discount</Text>
      </View>
      <View style={styles.taxableAmount}>
        <Text>Taxable Amount</Text>
      </View>
      <View style={styles.cgstSection}>
        <View style={styles.cgst}>
          <Text>CGST</Text>
        </View>
        <View style={styles.cgst_bottomSec}>
          <View style={styles.cgst_Rate}>
            <Text>Rate</Text>
          </View>
          <View style={styles.cgst_Amount}>
            <Text>Amounts</Text>
          </View>
        </View>
      </View>
      <View style={styles.cgstSection}>
        <View style={styles.cgst}>
          <Text>SGST</Text>
        </View>
        <View style={styles.cgst_bottomSec}>
          <View style={styles.cgst_Rate}>
            <Text>Rate</Text>
          </View>
          <View style={styles.cgst_Amount}>
            <Text>Amounts</Text>
          </View>
        </View>
      </View>
      <View style={styles.total}>
        <Text>Total</Text>
      </View>
    </View>
  );
}
