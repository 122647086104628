import testHttp from "../../http-common"; 

export const getServiceTags = (serviceId, config) => {
  return testHttp.get(`/serviceTags/service/${serviceId}`, config);
};

export const addServiceTags = (tags, config) => {
  return testHttp.post("/serviceTags", tags, config);
};

export const deleteTag = (tagId, config) => {
  return testHttp.delete(`/serviceTags/${tagId}`, config);
};

export const deleteServiceTags = (serviceId, config) => {
  return testHttp.delete(`/serviceTags/deleteByServiceId/${serviceId}`, config);
};
