import { applyMiddleware, legacy_createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
 
import rootReducer from './reducer/index'

const Middleware =[thunk]

const Store =legacy_createStore(rootReducer,composeWithDevTools(applyMiddleware(...Middleware)))

export default Store